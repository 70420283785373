// @flow
import React from 'react';
// Styles
import './ConfirmationPage.css';
// Images
import character from '../images/confirmation-character-icon.svg'
// Other
import { phone } from '../../common';

type Props = {
  orderNumber: number,
  userEmail: string,
};

const ConfirmationPage = (props: Props) => {

  return (
    <div className='confirmation-page-container'>
      <div className="confirmation-page-content">
      <div className="confirmation-page-box confirmation-page-title-box" >Seu pedido <span>#{props.orderNumber}</span> foi recebido com sucesso!</div>
      <div className="confirmation-page-box confirmation-page-description-box">
        Em breve você receberá um email no {props.userEmail} com os dados desse pedido. Para garantir a sua segurança e a de nossos outros clientes esse pedido irá passar por uma avaliação de crédito e um documento de identidade será requerido para receber o produto. O nome que aparecerá na sua fatura do cartão de crédito será "Pag*Bigdelivery". Caso tenha dúvidas, estamos disponíveis pelo<span> WhatsApp no {phone.asStringFull}</span>
      </div>
      <div className="confirmation-page-box confirmation-page-image-box"><img className='confirmation-page-image' src={character} alt="character"/></div>
      <div className="confirmation-page-box confirmation-page-navigation-button-box"> <a href="/"className='confirmation-page-go-to-navigation-button'>  CONTINUAR NAVEGANDO</a></div>
      <div className="confirmation-page-box confirmation-page-orders-button-box"><a href="/minha-conta/pedidos" className='confirmation-page-go-to-orders-button'>VER MEUS PEDIDOS</a></div>
      </div>
    </div>
  );
};

export default ConfirmationPage;
