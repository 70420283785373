// @flow
import React, { useState } from 'react';
// Components
import { Button, FormControlInput, PageTitleWithGoBack } from '../../common';
// Images
import lock from '../images/lock.svg'
// Styles
import './PasswordRecovery.css';

type State = {
  password: string,
  passwordConfirmation: string,
  // Errors
  passwordError: string,
  passwordConfirmationError: string,
};

type Props = {
  isLoading: boolean,
  errors: {},
  onSubmitRecoverPassword: (
    password: string,
    passwordConfirmation: string,
  ) => void,
};

const defaultFormValue: State = {
  password: '',
  passwordConfirmation: '',
  passwordError: '',
  passwordConfirmationError: '',
}
const PasswordRecovery = ({ isLoading, errors, onSubmitRecoverPassword }: Props) => {

  const [formValue, setFormValue] = useState(defaultFormValue)

  // Validates the form on submission. If there are no validation error dispatches action.
  const validateUserInput = () => {

    const { password, passwordConfirmation } = formValue
    let noValidationErrors = true;

    if (password === '') {
      // Password is missing or invalid?
      setFormValue((prevFormValue) => ({ ...prevFormValue, passwordError: 'Campo é obrigatório' }))
      noValidationErrors = false;
    } else if (password.length < 6) {
      setFormValue((prevFormValue) => ({ ...prevFormValue, passwordError: 'Senha muito curta. Mínimo de 6 caracteres' }))
      noValidationErrors = false;
    } else if (password.length > 50) {
      setFormValue((prevFormValue) => ({ ...prevFormValue, passwordError: 'Senha muito longa. Máx de 50 caracteres' }))
      noValidationErrors = false;
    } else {
      setFormValue((prevFormValue) => ({ ...prevFormValue, passwordError: '' }))
    }

    if (passwordConfirmation === '') {
      // Confirmation password is mandatory
      setFormValue((prevFormValue) => ({ ...prevFormValue, passwordConfirmationError: 'Campo é obrigatório' }))
      noValidationErrors = false;
    } else if (passwordConfirmation !== password) {
      // Confirmation password has to match password
      setFormValue((prevFormValue) => ({ ...prevFormValue, passwordConfirmationError: 'Senha e confirmação não são iguais' }))
      noValidationErrors = false;
    } else {
      setFormValue((prevFormValue) => ({ ...prevFormValue, passwordConfirmationError: '' }))
    }

    if (noValidationErrors) {
      onSubmitRecoverPassword(password, passwordConfirmation);
    } else {
      console.error('Validation error on registration form.');
    }
  }

  return (
    <section className="password-recovery-main">
      <PageTitleWithGoBack title="Voltar" />
      <div className='password-recovery-header'>
        <h3 className='password-recovery-title'>Resetar Senha</h3>
      </div>
      <div className='password-recovery-container'>
        <div className="password-recovery-box">
          <div className='password-recovery-row'>
            <img className="password-recovery-image" src={lock} alt="Cadeado" />
          </div>
          <p className='password-recovery-text'>
            Por favor escolha sua nova senha
          </p>
          <form
            onSubmit={e => {
              e.preventDefault();
              validateUserInput();
            }}
            className='password-recovery-form'
          >
            <FormControlInput
              label="Nova senha"
              type="password"
              value={formValue.password}
              handleChangeText={value => {
                setFormValue((prevFormValue) => ({ ...prevFormValue, password: value }))

              }}
              handleFocus={value => {
                setFormValue((prevFormValue) => ({ ...prevFormValue, password: value }))
              }}
              errorMessage={formValue.passwordError}
            />
            <FormControlInput
              label="Confirme a nova senha"
              type="password"
              value={formValue.passwordConfirmation}
              handleChangeText={value => {
                setFormValue((prevFormValue) => ({ ...prevFormValue, passwordConfirmation: value }))
              }}
              handleFocus={value => {
                setFormValue((prevFormValue) => ({ ...prevFormValue, passwordConfirmation: value }))
              }}
              errorMessage={formValue.passwordConfirmationError}
            />
            <Button
              text="Mudar a senha"
              icon=""
              buttonType="button-secondary"
              loading={isLoading}
            />
          </form>
        </div>
      </div>

    </section>
  );
}

export default PasswordRecovery;
