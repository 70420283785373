// @flow
import { createAction } from 'redux-actions';
import qs from 'qs';
import history from '../../history';
// Types
import type { ReduxState } from '../types';
import type { Dispatch } from '../../types';

export const updateSearchState = createAction('UPDATE_SEARCH_STATE');

export function createURL(state: ReduxState) {
  return `?${qs.stringify(state)}`;
}
function searchStateToUrl(pathname, searchState) {
  if (searchState) {
    return `${pathname}${createURL(searchState)}`;
  } else {
    return `${pathname}`;
  }
}

export function updateSearch(searchState: ReduxState) {
  return (dispatch: Dispatch) => {
    history.push(searchStateToUrl('/busca', searchState), searchState);
    dispatch(updateSearchState(searchState));
  };
}

// When dispatched this action causes the reducer to return to its initial state
export const clearSearchParams = createAction('CLEAR_SEARCH_PARAMS');

export function clearSearch() {
  return (dispatch: Dispatch) => {
    dispatch(clearSearchParams());
  };
}
