import firstFrameImage from '../images/black-friday-banner-first-frame-image.png';
import secondFrameImage from '../images/black-friday-banner-second-frame-image.png';
import thirdFrameImage from '../images/black-friday-banner-third-frame-image.jpeg';
import fourthFrameImage from '../images/black-friday-banner-fourth-frame-image.png';

export const blackFridayData = [
  {
    question: 'Quando ocorrerá a Black Friday no Big Delivery?',
    answer: (
      <>
        Nossa <strong>Black Friday</strong> começará no{' '}
        <strong>dia 22 de novembro</strong> e vai até o dia{' '}
        <strong>25 de novembro.</strong>
      </>
    ),
  },
  {
    question:
      'Quais são os principais benefícios do Big Delivery durante a Black Friday?',
    answer: `Durante a Black Friday, você pode desfrutar de descontos incríveis em nossos produtos,
    frete grátis para pedidos acima de R$150, ofertas exclusivas de kits,
    oportunidades de ganhar cashback, entre outros.`,
  },
  {
    question: 'Como faço para ganhar cashback nas compras de Black Friday?',
    answer: `Para ganhar cashback, basta fazer compras durante a Black Friday e acumular automaticamente
    uma porcentagem do valor gasto em sua conta Big Club. Depois, você pode usar
    esse cashback para economizar em futuras compras.`,
  },
  {
    question: 'O que é o programa Big Club e como ele funciona?',
    answer: `O Big Club é nosso programa de fidelidade. Com ele, você ganha recompensas,
    incluindo cashback, ao fazer compras e indicar amigos para o Big Delivery.
    Quanto mais você compra e indica, mais benefícios você acumula.`,
  },
  {
    question: 'Como faço para receber minha entrega em horário agendado?',
    answer: `Durante a Black Friday, você pode agendar a entrega para o
    horário que mais lhe convém. Basta escolher a data e horário desejados
    durante o processo de compra.`,
  },
  {
    question: 'Posso personalizar meus cortes de carne durante a Black Friday?',
    answer: `Sim, você pode personalizar seus cortes de carne na Black Friday,
    escolhendo as opções que mais lhe agradam para garantir que sua compra
    seja perfeita.`,
  },
  {
    question: 'Como faço para ganhar frete grátis na Black Friday?',
    answer: `Durante a Black Friday, oferecemos frete grátis para pedidos acima de R$150,
    permitindo que você economize ainda mais nas suas compras.`,
  },
  {
    question:
      'Qual é o prazo para troca de produtos comprados durante a Black Friday?',
    answer: `Você pode aproveitar nosso prazo de troca de até 7 dias para qualquer
    produto adquirido durante a Black Friday, garantindo que sua
    satisfação seja prioridade.`,
  },
];

export const framesData = [
  {
    image: firstFrameImage,
    title: 'Personalize o seu corte como quiser!',
    description:
      'Transforme seu churrasco em algo único! No Big Delivery, personalize seus cortes de carne de acordo com sua preferência.',
  },
  {
    image: secondFrameImage,
    title: 'Como funciona o Cashback no BigClub?',
    description:
      'No BigClub, suas compras valem ainda mais! Com o Cashback, você recebe uma porcentagem do valor gasto de volta.',
  },
  {
    image: thirdFrameImage,
    title: 'Frete grátis em pedidos acima de 150 reais',
    description:
      'Economize com entregas gratuitas! Faça pedidos acima de R$150, tenha a vantagem do frete grátis.',
  },
  {
    image: fourthFrameImage,
    title: 'Receba o pedido no horário que se adapta à sua rotina',
    description:
      'Não se preocupe com o horário da entrega! No Big Delivery, agende a entrega para o momento que melhor se encaixa na sua rotina.',
  },
];
