// @flow
import React from 'react';
import { camelizeKeys } from 'humps';
// Connectors
// Images
import AddToBlend from '../images/addtoblend.png';
// Components
import { ProgressiveImageLoader, PriceWithUnit } from '../../common';
// import { ItemSelector } from '../containers/ItemSelector';
// Types
import type { ProductSearchHit } from '../../products/types';
// Styles
import './ProductHit.css';

type Props = {
  hit: ProductSearchHit,
  isItemInBlend: (variantId: number | string) => boolean,
  AddItemToBlendTemplate: (variantId: number, quantity: number) => void,
};

const ProductHit = ({
  hit,
  isItemInBlend,
  AddItemToBlendTemplate,
  addingBlendTemplateItemsLoading,
}: Props) => {
  const camelizedHit = camelizeKeys(hit);

  return (
    <div>
      <div className="product-hit-main">
        <div className="product-hit-content">
          <div className="product-hit-image">
            <ProgressiveImageLoader
              selectedImage={camelizedHit.images[0]}
              imageToShow={'small'}
            />
          </div>

          <div className="product-hit-description">
            <div className="product-hit-name">{camelizedHit.name}</div>{' '}
            <div className="product-hit-info">
              <div className="product-hit-manufacturer">
                {camelizedHit.manufacturer}
              </div>
              <PriceWithUnit
                listPrice={camelizedHit.listPrice}
                price={camelizedHit.price}
                unit={camelizedHit.unitForQuantity}
              />
              {!addingBlendTemplateItemsLoading(camelizedHit.id) ? (
                <div className="product-hit-selector">
                  {camelizedHit.inStock ? (
                    !isItemInBlend(camelizedHit.id) ? (
                      <button
                        className="product-hit-add-to-blend-image"
                        src={AddToBlend}
                        alt=""
                        onClick={() =>
                          AddItemToBlendTemplate(
                            camelizedHit.id,
                            camelizedHit.defaultQuantity,
                          )
                        }
                      >
                        ADICIONAR
                      </button>
                    ) : (
                      <button className="added-button" disabled={true}>
                        ADICIONADO
                      </button>
                    )
                  ) : (
                    'FORA DE ESTOQUE'
                  )}
                </div>
              ) : (
                <div className="product-hit-selector">
                  <div className="product-hit-add-loader">
                    <i className="fa fa-spinner fa-pulse" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default ProductHit;
