// @flow
import React, { useState } from 'react';
// Styles
import './FaqHit.css';
// assets
import chevronDown from '../images/chevron-down.svg';
import chevronUp from '../images/chevron-up.svg';

type Props = {
  question: string,
  answer: string,
};

const FaqHit = ({ question, answer }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="faq-hit-main" onClick={toggleCollapse}>
      <div className="faq-hit-header">
        <h3>{question}</h3>
        <div className="faq-hit-header-line" />
        <button className="faq-hit-header-button">
          {isCollapsed ? (
            <img src={chevronDown} alt="Seta para baixo" />
          ) : (
            <img src={chevronUp} alt="Seta para cima" />
          )}
        </button>
      </div>
      {isCollapsed && <p className="faq-hit-fade-in">{answer}</p>}
    </div>
  );
};

export default FaqHit;
