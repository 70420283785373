// @flow
import { useEffect } from 'react';
import { connect } from 'react-redux';
// Router
import { Redirect, withRouter } from 'react-router-dom';
// Actions
import { fetchFeatures, fetchBigClubSettings } from '../actions';
import { featuresConfigSelector } from '../selectors';
// Types
import { FeaturesConfig } from '../types';

type Props = {
  getFeatures: () => void,
  getBigClubSettings: () => void,
  featuresConfig: FeaturesConfig,
};

const Preferences = ({
  getFeatures,
  getBigClubSettings,
  featuresConfig,
  location,
}: Props) => {
  useEffect(() => {
    getFeatures();
    getBigClubSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    location.pathname.includes('bigclub') &&
    featuresConfig &&
    !featuresConfig.bigclubFeature
  ) {
    return <Redirect to="/" />;
  }

  return null;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getFeatures: () => dispatch(fetchFeatures()),
    getBigClubSettings: () => dispatch(fetchBigClubSettings()),
  };
};
const mapStateToProps = (state, ownProps) => {
  return {
    featuresConfig: featuresConfigSelector(state),
  };
};

const connector: Connector<RouterProps, ReduxProps> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default withRouter(connector(Preferences));
