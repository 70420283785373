// @flow
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './ItemListImage.css';

// Components
import { ProgressiveImageLoader } from '../../common';

type Props = {
  variant: any,
};

class ItemListImage extends Component {
  props: Props;
  render() {
    return (
      <div className="image-list-control">
        <div className="image-list-main">
          <Link to={`/produtos/${this.props.variant.slug}/`}>
            <ProgressiveImageLoader
              selectedImage={this.props.variant.images[0]}
              imageToShow={'product'}
            />
          </Link>
        </div>
      </div>
    );
  }
}

export default ItemListImage;
