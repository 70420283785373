// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';

// Components
import PaginatedScroller from '../../components/PaginatedScroller';
// Actions
import { findFrequentlyPurchasedTogetherProducts } from '../../actions';
// Selectors
import {
  frequentlyPurchasedTogetherProductsSelector,
  frequentlyPurchasedTogetherProductsLoadingSelector,
  frequentlyPurchasedTogetherProductLastPageSelector,
} from '../../selectors';
// Types
import type { Product } from '../../types';

type Props = {
  products: Product[],
  isLoading: boolean,
  findProducts: () => void,
  isLastPage: boolean,
  variantId: number,
};

const ProductFrequentlyPurchasedTogetherScroller = (props: Props) => {
  const currentPage = 1;

  const { findProducts } = props;
  useEffect(() => {
    findProducts(props.variantId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.variantId]);

  return (
    <PaginatedScroller
      title="Frequentemente comprados juntos"
      products={props.products}
      isLoading={props.isLoading && currentPage === 1}
      isLoadingPage={props.isLoading}
      // commit for not request new pages
      //requestNewPage={requestNewPage.bind(this)}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    products: frequentlyPurchasedTogetherProductsSelector(state),
    isLoading: frequentlyPurchasedTogetherProductsLoadingSelector(state),
    isLastPage: frequentlyPurchasedTogetherProductLastPageSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    findProducts: (variantId, page, perPage) => {
      dispatch(
        findFrequentlyPurchasedTogetherProducts(variantId, page, perPage),
      );
    },
  };
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default connector(ProductFrequentlyPurchasedTogetherScroller);
