// @flow
import React, { useState } from 'react';
// Styles
import './BlogHero.css';
// Components
import ModalVideo from 'react-modal-video'
// Images
import playIcon from '../images/playIcon.svg'

const BlogHero = () => {
  const [isOpen, setIsOpen] = useState(false)
  return (
  <span className="blog-hero-main">
    <div className='blog-hero-container'>
        <button onClick={() => { setIsOpen(true) }} className='blog-hero-play'>
          <img src={playIcon} alt={'play icon'}></img>
        </button>
      <ModalVideo channel='vimeo' background autoplay loop isOpen={isOpen} videoId="776786562" onClose={() => setIsOpen(false)} />
        <h1 className="blog-hero-h1">O seu Açougue Virtual</h1>
        <h2 className="blog-hero-h2">Conheça mais sobre o açougue que revolucionou a forma como você compra carne!</h2>
    </div>
  </span >
    )
};


export default BlogHero;

