import React, { Component } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  currentPageInURL: number,
  pagesInfo: {
    currentPage: number,
    numberOfPages: number,
  },
  getPastOrders: (page: number, perPage: number) => void,
};

class Pagination extends Component {
  props: Props;

  search = page => {
    return `?page=${page}`;
  };

  render() {
    // We limit the maximum number of pages a user can navigate to 10
    const numberOfPages = Math.min(this.props.pagesInfo.numberOfPages, 10);

    // Create an empty array wih the correct length so that we can iterate over it
    const pageNumberArray = [...Array(numberOfPages)];

    return (
      <div className="my-account-page-pagination">
        <ul className="ais-Pagination-list">
          <li className="ais-Pagination-item ais-Pagination-item--page">
            <Link
              className={
                this.props.pagesInfo.currentPage === 1
                  ? 'ais-Pagination-link ais-Pagination-link--selected'
                  : 'ais-Pagination-link'
              }
              to={{
                search: this.search(1),
              }}
            >
              «
            </Link>
          </li>

          <li className="ais-Pagination-item ais-Pagination-item--page">
            <Link
              className={
                this.props.pagesInfo.currentPage === 1
                  ? 'ais-Pagination-link ais-Pagination-link--selected'
                  : 'ais-Pagination-link'
              }
              to={{
                search: this.search(
                  Math.min(this.props.pagesInfo.currentPage - 1, 1),
                ),
              }}
            >
              ‹
            </Link>
          </li>
          {pageNumberArray.map((_, ind) => {
            const page = ind + 1;
            return (
              <li
                key={ind}
                className="ais-Pagination-item ais-Pagination-item--page"
              >
                <Link
                  className={
                    this.props.pagesInfo.currentPage === page
                      ? 'ais-Pagination-link ais-Pagination-link--selected'
                      : 'ais-Pagination-link'
                  }
                  to={{
                    search: this.search(page),
                  }}
                >
                  {page}
                </Link>
              </li>
            );
          })}

          <li className="ais-Pagination-item ais-Pagination-item--page">
            <Link
              className={
                this.props.pagesInfo.currentPage === numberOfPages
                  ? 'ais-Pagination-link ais-Pagination-link--selected'
                  : 'ais-Pagination-link'
              }
              to={{
                search: this.search(
                  Math.min(this.props.pagesInfo.currentPage + 1, numberOfPages),
                ),
              }}
            >
              ›
            </Link>
          </li>
          <li className="ais-Pagination-item ais-Pagination-item--page">
            <Link
              className={
                this.props.pagesInfo.currentPage === numberOfPages
                  ? 'ais-Pagination-link ais-Pagination-link--selected'
                  : 'ais-Pagination-link'
              }
              to={{
                search: this.search(numberOfPages),
              }}
            >
              »
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

export default Pagination;
