//@flow
import React from 'react';
// Styles
import './Checkbox.css';

type Props = {
  tooltip: string,
  checked: boolean,
  onChange: (value: any) => void,
  ref: any,
  label: string,
};

const Checkbox = (props: Props) => {
  return (
    <div className="checkbox-div" title={props.tooltip}>
      <label>
        <input
          type="checkbox"
          checked={props.checked}
          onChange={props.onChange}
          ref={props.ref}
        />
        {props.label}
      </label>
    </div>
  );
};

export default Checkbox;
