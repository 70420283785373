// Components
import BlackFridayBanner from '../components/BlackFridayBanner';
import BlackFridayFrequentlyQuestions from '../components/BlackFridayFrequentlyQuestions';
import BlackFridayBaseBanner from '../components/BlackFridayBaseBanner';
import BlackFridayProductSlider from '../components/BlackFridayProductSlider';
import {
  ProductPromotionScroller,
  ProductRecommendedForUserScroller,
} from '../../products';
// Style
import './BlackFridayLandingPage.css';

const BlackFridayLandingPage = () => {
  return (
    <div className="black-friday-landing-page-main-container">
      <BlackFridayBanner />
      <ProductPromotionScroller title="Queridinhas da black" />
      <BlackFridayProductSlider />
      <ProductRecommendedForUserScroller title="Você também pode gostar!" />
      <BlackFridayFrequentlyQuestions />
      <BlackFridayBaseBanner />
    </div>
  );
};

export default BlackFridayLandingPage;
