// Images
import bigclubFreeShipping from '../images/bigclubFreesShipping.svg';
import bigclubTruck from '../images/bigclubTruck.svg';
import bigclubCustomize from '../images/bigclubCustomize.svg';
import bigclubMail from '../images/bigclubMail.svg';
import bigclubYourTime from '../images/bigclubYourTime.svg';
import bigclubSecurity from '../images/bigclubSecurity.svg';

const bigclubData = {
  benefits: [
    {
      img: bigclubFreeShipping,
      title: 'Frete grátis',
      text: 'em pedidos acima de 150 reais.',
    },
    {
      img: bigclubTruck,
      title: 'Satisfação garantida!',
      text: 'troque seus produtos em até 7 dias.',
    },
    {
      img: bigclubCustomize,
      title: 'Personlize o seu corte de carne',
      text: 'de acordo com suas preferências.',
    },
    {
      img: bigclubMail,
      title: 'Ganhe 10 reais de crédito!',
      text: 'Ao convidar um amigo',
    },
    {
      img: bigclubYourTime,
      title: 'Receba o pedido no horário',
      text: 'que se adapta à sua rotina.',
    },
    {
      img: bigclubSecurity,
      title: 'Sua segurança é a nossa prioridade.',
      text: 'Conte com nossos certificados de segurança.',
    },
  ],
  faqQuestions: [
    {
      title: 'Como funciona o Cashback no BigClub?',
      body: `O Cashback no BigClub é um benefício exclusivo que <b>retorna 3% do valor de suas compras acima de R$200 como saldo para descontos em futuras aquisições.</b>`,
      isOpen: false,
    },
    {
      title: 'Como participar do BigClub?',
      body: 'Para resgatar o Cashback acumulado, <b>basta realizar uma nova compra acima de R$200</b>, e o valor correspondente ao <b>Cashback será debitado na sua conta após 7 dias da conclusão do pedido.</b>',
      isOpen: false,
    },
    {
      title: 'Como participar do programa "Convide e Ganhe" no BigClub?',
      body: 'Para participar, <b>convide amigos, familiares ou conhecidos para se cadastrarem no Bigdelivery usando o seu código exclusivo de indicação.</b>',
      isOpen: false,
    },
    {
      title: 'Como ganhar com o "Convide e Ganhe"?',
      body: 'Logo após indicar alguém e essa pessoa efetuar sua primeira compra, <b>você ganha R$10 de crédito BigClub</b>, que pode ser utilizados como <b>desconto em compras futuras.</b>',
      isOpen: false,
    },
    {
      title: 'Existe limite para indicar amigos no "Convide e Ganhe"?',
      body: 'Não, não há limite! <b>Quanto mais amigos você indicar, mais benefícios poderá acumular.</b> Aproveite e convide todos a fazerem parte do <b>BigClub!</b>',
      isOpen: false,
    },
  ],
};

export default bigclubData;
