// @flow
import React, { useEffect, useState } from 'react';
// Components
import Recipe from '../../recipes/components/Recipe';
// Styles
import './MainProductDetails.css';
//Analytics
import * as Analytics from '../../common/analytics';
// Types
import type { Product } from '../types';
export type Props = {
  product: Product,
  recipe: any,
};

const ProductDescription = ({ description }) => {
  return <p>{description}</p>;
};

const ProductRecipe = ({ recipes, product }) => {
  useEffect(() => {
    Analytics.logTappedShowFullRecipe(
      product,
      window.location.pathname,
      'visit',
    );
    return () => {
      Analytics.logTappedMinimizeRecipe(
        product,
        window.location.pathname,
        'visit',
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div>
        {recipes.map((recipe, key) => (
          <Recipe key={key} recipe={recipe} />
        ))}
      </div>
    </div>
  );
};

const MainProductDetails = ({ product, recipes }: Props) => {
  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const buildTabs = () => {
    const tabItems = [];
    if (product.description) {
      tabItems.push({
        title: 'Descrição do produto',
        TabContent: () => (
          <ProductDescription description={product.description} />
        ),
      });
    }
    if (recipes && recipes.length && product) {
      tabItems.push({
        title: 'Dicas e receitas',
        TabContent: () => <ProductRecipe product={product} recipes={recipes} />,
      });
    }
    setTabs(tabItems);
  };

  useEffect(() => {
    if (product) buildTabs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product?.id]);

  // TODO: Make sure this works when we have products with descriptions again...
  return (
    <div className="main-product-details-main">
      <div className="main-product-details-tab-container">
        <div className="main-product-details-tab-title-container">
          {tabs.map((tab, i) => (
            <h5
              className={`main-product-details-tab-title-item ${
                selectedTabIndex === i
                  ? 'main-product-details-tab-title-item-selected'
                  : ''
              }`}
              onClick={() => setSelectedTabIndex(i)}
              key={i}
            >
              {tab.title}
            </h5>
          ))}
        </div>
        <div className="main-product-details-tab-content-container">
          {tabs.map(
            ({ TabContent }, i) =>
              selectedTabIndex === i && <TabContent key={i} />,
          )}
        </div>
      </div>
    </div>
  );
};

export default MainProductDetails;
