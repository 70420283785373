// @flow
import React, { useEffect } from 'react';
// Components
import LandingFAQ from '../components/LandingFAQ';
import LandingIncreasingUserCounter from '../components/LandingIncreasingUserCounter';
import LandingProductList from '../components/LandingProductList';
// Styles
import './Pet.css';
// Data
import petData from '../data/petData';
// Connectors
import { displayVariantsByPageAndSectionConnector } from '../../products';
// Types
import { Product } from '../../products';
// Images
import dogAndCat from '../images/dog+gato.png';

type Props = {
  findDisplayVariantsByPageAndSection: (
    page: string,
    section: string,
  ) => Product[],
  productByPageAndSection: (page: string, section: string) => Product[],
};

const Pet = ({
  productByPageAndSection,
  findDisplayVariantsByPageAndSection,
}: Props) => {
  const sliderProductsVariants = productByPageAndSection('pet', 'slider');
  const productsListVariants = productByPageAndSection('pet', 'product_list');
  useEffect(() => {
    findDisplayVariantsByPageAndSection('pet');
    //eslint-disable-next-line
  }, []);

  return (
    <div className="pet-content">
      <LandingIncreasingUserCounter />
      <section className="pet-banner-container">
        <div className="pet-banner">
          <p className="pet-banner-text">
            Ofereça uma <span>dieta balanceada</span> para seu pet
          </p>
        </div>
      </section>
      <section className="pet-benefits  pet-gap-alt">
        <h1 className="pet-benefits-title">
          Os benefícios de uma dieta à base de
          <span> proteínas in natura </span>
          para seu cão ou gato
        </h1>
        <div className="pet-benefits-grid">
          {petData.petBenefits.map((benefit, index) => {
            return (
              <div className="pet-benefits-grid-item" key={index}>
                <img
                  className="pet-benefits-grid-item-img"
                  src={benefit.img}
                  alt=""
                />
                <p className="pet-benefits-grid-item-text">{benefit.text}</p>
              </div>
            );
          })}
        </div>
        <img
          src={dogAndCat}
          alt="Cão e gato"
          className="pet-benefits-row-img"
        />
      </section>
      <section className="pet-comment-section pet-gap">
        <p className="pet-comment-section-text">
          Qualquer cão ou gato pode optar pela alimentação natural à base de
          carne, mas é importante o acompanhamento próximo de um Veterinário ou
          Zootecnista especializado, principalmente para a orientação do tutor
          sobre melhores práticas.
        </p>
        <div className="pet-comment">
          <img
            src={petData.comment.img}
            alt="Comentário"
            className="pet-comment-img"
          />
          <h4 className="pet-comment-title">{petData.comment.title}</h4>
          <p className="pet-comment-text">{petData.comment.text}</p>
          <span className="pet-comment-author">{petData.comment.author}</span>
        </div>
      </section>
      <section className="pet-banner-container">
        <div className="pet-banner-alt">
          <p className="pet-banner-text">
            Ganhe mais tempo para brincar com seu pet
          </p>
        </div>
      </section>
      <section className="pet-icon-column pet-gap-alt">
        {petData.petColumnIcons.map((icon, index) => {
          return (
            <div className="pet-icon-column-item" key={index}>
              <img
                src={icon.img}
                alt={icon.alt}
                className="pet-icon-column-item-img"
              />
              <p className="pet-icon-column-item-text">{icon.text}</p>
            </div>
          );
        })}
      </section>
      <LandingProductList
        sliderProductsVariants={sliderProductsVariants}
        productsVariants={productsListVariants}
        currentLanding="pet"
        title="O melhor para o seu pet:"
      />
      <LandingFAQ faqQuestions={petData.faqQuestions} currentLanding="pet" />
    </div>
  );
};
export default displayVariantsByPageAndSectionConnector(Pet);
