import { camelizeKeys } from 'humps';
import { normalize } from 'normalizr';
import { authGet, authPost } from '../../api';
import * as schema from './schema.js';

const Home = {
  async fetchBanners() {
    const response = await authGet(`/v2/banners`);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.banners);
    return normalized;
  },
  async postNewsLetterEmail(email) {
    const response = await authPost(
      `/newsletter/sign?email=${email.toLowerCase()}`,
    );
    const data = await response;
    return data;
  },
  async getScheduledDiscounts() {
    const response = await authGet(
      '/v2/scheduled_discounts?q[frontend_visible_eq]=true&per_page=1',
    );
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.scheduledDiscounts);
    return normalized;
  },
};

export default Home;
