// Styles
import './KitsFooterBanner.css';
// Images
import video from '../images/kits-lp-video.mp4';

const KitsFooterBanner = () => {
  return (
    <div className="kits-footer-banner-container">
      <div className="kits-footer-banner-content-container">
        <div className="kits-footer-banner-text-container">
          <h1 className="kits-footer-banner-title">
            Não perca tempo e aproveite todos os kits do bigdelivery!
          </h1>
          <p className="kits-footer-banner-text">
            No BigDelivery, cada mordida é feita à sua medida.
          </p>
        </div>
        <div className="kits-footer-banner-link">
          <a href="#kits-product-banner" alt="Torne-se um associado">
            Descubra já!
          </a>
        </div>
      </div>
      <video
        className="kits-footer-banner-video"
        autoPlay={true}
        muted={true}
        loop={true}
      >
        <source src={video} type="video/mp4"></source>
        <source src={video} type="video/ogg"></source>
        Seu navegador não suporta este vídeo.
      </video>
    </div>
  );
};
export default KitsFooterBanner;
