// @flow
import React, { Component } from 'react';
import _ from 'lodash';
// Composers
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// Components
import { Helmet } from 'react-helmet';
import BlogRecentPosts from '../components/BlogRecentPosts';
// Selectors
import {
  currentPostLoadingSelector,
  getPostBySlugSelector,
  postsBlogSelector,
} from '../selectors';
/// Actions
import { findPost, findPostsBlog } from '../actions';
/// Types
import type { ContextRouter } from 'react-router-dom';
import type { Connector } from 'react-redux';
import type { Dispatch } from '../../types';
import type { Post } from '../types';
// Styles
import './BlogPostPage.css';
import { PageLoader } from '../../common';

type RouterProps = ContextRouter;

type ReduxProps = {
  post: Post,
  postsBlog: Post[],
  postsNews: Post[],
  loading: boolean,
};

type Props = RouterProps & ReduxProps;

class BlogPostPage extends Component {
  props: Props;

  componentDidMount() {
    const slug = this.props.match.params.slug;
    this.props.findPost(slug);
    this.props.findPostsBlog();
  }

  render() {
    const pageTitle =
      (this.props.post ? `${_.capitalize(this.props.post.title)} - ` : '') +
      'Bigdelivery';

    if (this.props.loading && !this.props.post) {
      return <PageLoader></PageLoader>;
    }

    if (!this.props.loading && (this.props.post === null) | undefined) {
      return (
        <div className="blog-post-page-error">
          <h3>
            Houve um erro ao tentar acessar essa postagem
            <span role="img" aria-label="emoji de tristeza">
              {' '}
              😥
            </span>
          </h3>
          <Link to="/blog" className="blog-post-page-error-button">
            Voltar para página anterior
          </Link>
        </div>
      );
    }

    return (
      <div>
        <Helmet>
          <title>{pageTitle}</title>

          <meta
            name="description"
            content={
              this.props.post
                ? this.props.post.description
                : 'Descubra tudo sobre carnes e como prepará-las das melhores maneiras em nosso blog!'
            }
          />
          <meta property="og:title" content={pageTitle} />
          <meta
            name="og:description"
            content={
              this.props.post
                ? this.props.post.description
                : 'Descubra tudo sobre carnes e como prepará-las das melhores maneiras em nosso blog!'
            }
          />
          {this.props.post ? (
            <meta
              property="og:url"
              content={`${process.env.PUBLIC_URL}/blog/${this.props.post.slug}`}
            ></meta>
          ) : null}
          <meta property="og:type" content="website"></meta>
          <meta
            property="og:image"
            content={`${process.env.PUBLIC_URL}/white_logo_on_red_background.jpg`}
          />
          <meta property="og:image:type" content="image/jpg" />
          <meta property="og:image:alt" content="Bigdelivery logo" />
        </Helmet>

        <div className="blog-post-page-main">
          <div
            className="blog-post-page-hero"
            style={{
              backgroundImage: `linear-gradient(180deg, rgba(206, 60, 37, 0) 0%, rgba(206, 60, 37, 0.1) 100%) ,url(${this.props.post.desktopHeaderImageUrl})`,
            }}
          ></div>
          <div className="blog-post-page-content">
            <h2>{this.props.post.title}</h2>
            <div
              className="blog-post-page-Innerhtml"
              dangerouslySetInnerHTML={{ __html: this.props.post.content.body }}
            ></div>
          </div>
        </div>

        <BlogRecentPosts
          onPostPage
          posts={this.props.postsBlog}
        ></BlogRecentPosts>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    post: getPostBySlugSelector(state, ownProps.match.params.slug),
    postsBlog: postsBlogSelector(state),
    loading: currentPostLoadingSelector(state),
  };
};
const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    findPost: postId => {
      dispatch(findPost(postId));
    },
    findPostsBlog: () => {
      dispatch(findPostsBlog());
    },
  };
};
const connector: Connector<RouterProps, ReduxProps> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default withRouter(connector(BlogPostPage));
