// @flow
import React, { useState } from 'react';
// Styles
import './QuestionsAndAnswers.css';
// Assets
import expandIcon from '../images/expand-plus-icon.svg';
import expandLessIcon from '../images/close-minus-icon.svg';

const QuestionsAndAnswers = ({ question, answer }) => {
  const [showAnswer, setShowAnswer] = useState(false);

  const changeShowContainer = () => {
    setShowAnswer(prevState => !prevState);
  };

  return (
    <div
      className={
        showAnswer
          ? 'answers-main-container-open'
          : 'answers-main-container-closed'
      }
    >
      <div className="answers-question-container">
        <button className="answers-open-button" onClick={changeShowContainer}>
          <img
            src={showAnswer ? expandLessIcon : expandIcon}
            alt="Seta de expansão de texto"
          />
          <p
            className={
              showAnswer
                ? 'answers-question-text-open'
                : 'answers-question-text-closed'
            }
          >
            {question}
          </p>
        </button>
      </div>
      {showAnswer && (
        <div className="answers-answer-container">
          <p>{answer}</p>
        </div>
      )}
    </div>
  );
};

export default QuestionsAndAnswers;
