// @flow
import React, { useEffect, useState } from 'react';
// Helpers
import { camelizeKeys } from 'humps';
// Components
import VisibilitySensor from 'react-visibility-sensor';
import { ProductPrice, ProgressiveImageLoader } from '../../../common';
import ProductListFavoriteButton from '../../containers/ProductListFavoriteButton';
// Analytics
import * as Analytics from '../../../common/analytics';
// Styles
import './WishlistProductSquare.css';
// Types
import type { Hit } from '../../../types';

type Props = {
  hit: Hit,
};

const WishlistProductSquare = ({ hit }: Props) => {
  const camelizedHit: Hit = camelizeKeys(hit);
  const [productVisibility, setProductVisibility] = useState(false);
  const { id, name, price, listPrice, manufacturer, unitForQuantity, images } =
    camelizedHit;

  useEffect(() => {
    if (productVisibility) {
      Analytics.logProductImpression(
        camelizedHit,
        window.location.pathname,
        'wishlist-product-visibility',
      );
    }
    // eslint-disable-next-line
  }, [productVisibility]);
  return (
    <VisibilitySensor
      onChange={isVisible => {
        setProductVisibility(isVisible);
      }}
    >
      <div className="wishlist-product-square">
        <ProductListFavoriteButton variantId={id} />
        <ProgressiveImageLoader
          imageToShow="product"
          selectedImage={images[0]}
        />
        <section className="wishlist-product-square-info">
          <p className="wishlist-product-square-variant">{manufacturer}</p>
          <h3 title={name} className="wishlist-product-square-name">
            {name}
          </h3>
        </section>
        <section className="wishlist-product-square-shopping">
          <ProductPrice
            productListPrice={listPrice}
            productUnit={unitForQuantity}
            productPrice={price}
          />
        </section>
      </div>
    </VisibilitySensor>
  );
};

export default WishlistProductSquare;
