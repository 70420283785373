// @flow
import React from 'react';
// Styles
import './CashbackCard.css';
// Components
import { Link } from 'react-router-dom';
// Lodash
import _ from 'lodash';
// Helpers
import { getTimeFormatL, priceConverter } from '../../common';
// Data
import infoCardsBigClub from '../data/infoCardsBigClub';
// Types
import type { Cashback } from '../types';
import type { BigClubCashbackSettings } from '../../preferences/types';

type Props = {
  cashbackOrders: Cashback[],
  cashbackInformation: any,
  isSigned: boolean,
  cashbackSettings: BigClubCashbackSettings,
};

const CashbackCard = (props: Props) => {
  const sortedCashbackOrders = _.orderBy(
    props.cashbackOrders,
    ['createdAt'],
    ['desc'],
  );

  const cashbackEarnPercentage = Number(
    props.cashbackSettings?.cashbackEarnPercentage,
  ).toFixed();

  return (
    <div className="cashback-card-container">
      <section
        className={`cashback-card-text-section ${
          !props.isSigned ? 'cashback-card-text-section-offline' : ''
        }`}
      >
        <h1>
          <span>Ganhe </span>Cashback <span>em Suas Compras!</span>
        </h1>
        <p>
          Aproveite o benefício de cashback e receba {cashbackEarnPercentage}%
          do valor de suas compras de volta em créditos!
        </p>
      </section>
      {props.isSigned && (
        <section className="cashback-card-balance-section">
          <div className="cashback-card-balance-section-current">
            <p className="cashback-card-balance-section-description">
              Saldo atual
            </p>
            <h1>
              {props.cashbackInformation?.totalAvailableCashbackCredit
                ? priceConverter(
                    props.cashbackInformation.totalAvailableCashbackCredit,
                  )
                : priceConverter(0)}
            </h1>
          </div>
        </section>
      )}
      {sortedCashbackOrders.length > 0 && (
        <section className="cashback-card-table-section">
          <div className="cashback-card-table-section-details">
            <p className="cashback-card-table-section-details-description">
              Seus últimos pedidos
            </p>
          </div>

          <div className="cashback-card-table-wrapper">
            <table className="cashback-card-table-section-table">
              <thead className="cashback-card-table-section-table-head">
                <tr>
                  <th>Data</th>
                  <th>Cashback</th>
                  <th>Status</th>
                  <th>Valor do Pedido</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="cashback-card-table-section-table-body">
                {sortedCashbackOrders.map(c => (
                  <tr key={c.id}>
                    <td>{getTimeFormatL(c.createdAt)}</td>
                    <td>{priceConverter(c.amount)}</td>
                    <td status={c.pending ? 'pending' : 'completed'}>
                      {c.pending ? 'Pendente' : 'Concluído'}
                    </td>
                    <td>{priceConverter(c.orderTotal)}</td>
                    <td>
                      <Link to={'/minha-conta/pedidos/' + c.orderNumber}>
                        Confira <span>seu pedido</span>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
      )}

      <section
        className={`cashback-card-info-section ${
          !props.isSigned ? 'cashback-card-info-section-offline' : ''
        }`}
      >
        {infoCardsBigClub.map((i, index) => (
          <div key={index} className="cashback-card-info-section-item">
            <img src={i.img} alt={i.alt} />
            <div>{i.text}</div>
          </div>
        ))}
      </section>
      <p className="cashback-card-read-rules">
        <Link to="/politicas-de-uso-bigclub">Ler as regras do Cashback</Link>
      </p>
    </div>
  );
};

export default CashbackCard;
