// @flow
import React, { useEffect, useState } from 'react';
// Composers
import { currentOrderNumberConnector } from '../../current-order';
// API
import Common from '../api';
// Styles
import './Alert.css';
// Other
import { phone } from '../../common';

const pingingInterval = 30000;

const Alert = ({ currentOrderNumber }) => {
  const [numberGoodPings, setNumberGoodPings] = useState(10);

  useEffect(() => {
    const ping = async () => {
      try {
        await Common.ping();
        setNumberGoodPings(numberGoodPings + 1);
      } catch (err) {
        setNumberGoodPings(0);
      }
    };
    const timerId = setInterval(ping, pingingInterval);

    return () => clearInterval(timerId);
  });

  if (numberGoodPings < 2) {
    return (
      <div className="alert-container">
        <div className="alert-main">
          Oops! Não estamos conseguindo conectar à internet. Se estiver com
          dificuldades em usar nosso site, ligue para {phone.asStringFull} e
          continuaremos o seu pedido #{currentOrderNumber} de onde você parou.
        </div>
      </div>
    );
  }

  return null;
};

export default currentOrderNumberConnector(Alert);
