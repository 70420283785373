// @flow
import React from 'react';
// Styles
import './LandingBanner.css';
// TYPE PROPS
type Props = {
  bannerImage?: string,
  bannerRichText?: string,
  textPosition?: 'top' | 'left' | 'right' | 'bottom',
};

const LandingBanner = ({
  bannerImage,
  bannerRichText,
  textPosition,
}: Props) => {
  return (
    <div
      className={`landing-banner landing-banner-text-on-${textPosition}`}
      style={{ 'background-image': `url(${bannerImage})` }}
    >
      <p
        className={'landing-banner-text'}
        dangerouslySetInnerHTML={{ __html: bannerRichText }}
      ></p>
    </div>
  );
};

export default LandingBanner;
