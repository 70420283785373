// @flow
import type { ActionType } from 'redux-actions';
import { handleActions } from 'redux-actions';
// Actions
import * as actions from '../actions';
// Types
import type { Reducer } from 'redux';
import type { Preferences } from '../types';

const initialStatePreferences: Preferences = {
  loadingFeatures: false,
  loadingBigClubSettings: false,
  error: null,
  features: null,
  bigClubSettings: null,
};

const reducers: Reducer<Preferences, ActionType<*>> = handleActions(
  {
    [actions.getFeaturesRequest]: (state, action) => ({
      ...state,
      loadingFeatures: true,
    }),
    [actions.getFeaturesSuccess]: (state, action) => ({
      ...state,
      ...action.payload.entities.features,
      loadingFeatures: false,
    }),
    [actions.getFeaturesFailure]: (state, action) => ({
      ...state,
      loadingFeatures: false,
      error: action.payload.error,
    }),
    [actions.getBigClubSettingsRequest]: (state, action) => ({
      ...state,
      loadingBigClubSettings: true,
    }),
    [actions.getBigClubSettingsSuccess]: (state, action) => ({
      ...state,
      loadingBigClubSettings: false,
      ...action.payload.entities.bigClubSettings,
    }),
    [actions.getBigClubSettingsFailure]: (state, action) => ({
      ...state,
      loadingBigClubSettings: false,
      error: action.payload.error,
    }),
  },
  initialStatePreferences,
);

export default reducers;
