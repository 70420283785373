import React from 'react';
// Styles
import './RadioButton.css';

type Props = {
  isSelected: Boolean,
  onSelect: () => void,
  text: String,
};

const RadioButton = (props: Props) => {
  return (
    <div className="radio-button-container" onClick={props.onSelect}>
      <div
        className={`radio-button-circle ${props.isSelected ? 'selected' : ''}`}
      ></div>
      <p className="radio-button-text">{props.text}</p>
    </div>
  );
};
export default RadioButton;
