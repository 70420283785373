import React from 'react';
// Redux related
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';
// Selectors
import * as selectors from '../selectors';
// Common imports
import { scaleAndFormatPrice, ProgressiveImageLoader } from '../../common';
// Styles
import './BarbecueLineItem.css';
// types
import type { Product } from '../../products';

type Props = {
  variant: Product,
  desiredQuantity: number,
};

const BarbecueLineItem = ({ variant, desiredQuantity }: Props) => {
  return (
    <div className="barbecue-line-item-container">
      <div className="barbecue-line-item-content">
        <div className="product-image">
          <ProgressiveImageLoader
            selectedImage={variant.images[0]}
            imageToShow={'product'}
          />
        </div>
        <div className="product-info">
          <div className="product-name text center">{variant.name}</div>
          <div className="product-manufacturer text center">
            {variant.manufacturer}
          </div>
        </div>
        <div className="product-price text">
          {`${scaleAndFormatPrice(variant.price, variant.unitForQuantity)}`}
        </div>
        <div className="product-quantity-container center">
          <div className="product-quantity text">
            {variant.unitForQuantity === 'g'
              ? (desiredQuantity / 1000).toLocaleString('pt-br', {
                  currency: 'BRL',
                }) + ' Kg'
              : desiredQuantity + ` ${variant.unitForQuantity}`}
          </div>
        </div>
      </div>
      <div className="barbecue-line-item-end-line"></div>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    desiredQuantity: selectors.barbecueSelectedVariantQuantity(
      state,
      ownProps.variant.id,
    ),
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};
const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default connector(BarbecueLineItem);
