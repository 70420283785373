// @flow
import React from 'react';
// Styles
import './SearchPage.css';
// Components
import {
  Configure,
  Pagination,
  Hits,
  Stats,
  connectStateResults,
} from 'react-instantsearch-dom';
import SearchNoResult from './SearchNoResult';
import SearchResultHorizontal from './SearchResultHorizontal';
import { ColorRow, PageLoader, useScreenWidth } from '../../common';
import ViewLine from './ViewLine';
import RefinementMenu from './RefinementMenu';
import SearchResultSquare from './SearchResultSquare';

type Props = {
  query: string,
  searchResults: any,
  searching: boolean,
};

const SearchPage = ({ query = '', searchResults, searching }: Props) => {
  const screenWidth = useScreenWidth();
  // Get a substring of the query so that we can display it
  if (query !== undefined) {
    if (query.length > 15) {
      query = query.substring(0, 15) + '...';
    }
  }

  // Determine if we have search results
  const noResults = searchResults ? searchResults.nbHits === 0 : false;

  return (
    <div className="search-page-main">
      <ColorRow color="grey" text={`RESULTADOS DA PESQUISA`} />

      <Configure query={query} />

      {searching ? <PageLoader text="Pesquisando..." /> : null}

      <div
        className={`search-page-container ${
          searching ? 'search-page-hidden' : ''
        }`}
      >
        <RefinementMenu />
        <div className="search-page-hits">
          <div className="search-page-hit-data">
            Resultados para "{query}"{' '}
            <span className="pull-right">
              <Stats
                translate={(name, n, ms) => n + ' resultados em ' + ms + ' ms'}
              />
            </span>
          </div>
          <ViewLine />

          <div>
            {noResults ? <SearchNoResult /> : null}
            {screenWidth <= 1000 ? (
              <section className="search-page-grid">
                <Hits hitComponent={SearchResultSquare} />
              </section>
            ) : (
              <Hits hitComponent={SearchResultHorizontal} />
            )}
          </div>

          <Pagination showLast={true} />
        </div>
      </div>
    </div>
  );
};

export default connectStateResults(SearchPage);
