// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Selectors
import {
  userAddressesSelector,
  userIdSelector,
  gettingUserAddressesLoadingSelector,
  deletingUserAddressesLoadingByIdSelector,
  editingUserAddressesLoadingSelector,
  addingUserAddressesLoadingSelector,
} from '../selectors';
// Actions
import {
  getAddresses,
  newAddress,
  updateAddress,
  deleteAddress,
} from '../actions';
// Types
import type { Address } from '../types';
import type { Dispatch } from '../../types';

type OwnProps = {
  addresses: Address[],
  getAddresses: () => void,
  onSubmitNewAddress: (address: Address) => void,
  onSubmitUpdateAddress: (address: Address) => void,
  onSubmitDeleteAddress: (addressId: number) => void,
};

/*
  COMPONENT BEHAVIOR DESCRIPTION
  If there is at least one address in the address list display the address list.
  If there are no addresses go to the add address
  If user selects to edit an address go to the same page as add new address but prefill
*/

function userAddressConnector(WrappedComponent: ReactClass<any>) {
  const mapStateToProps = (state, ownProps) => {
    return {
      addresses: userAddressesSelector(state),
      userId: userIdSelector(state),
      deleteByIdLoading: (id: number) =>
        deletingUserAddressesLoadingByIdSelector(state, id),
      editingLoading: editingUserAddressesLoadingSelector(state),
      addingLoading: addingUserAddressesLoadingSelector(state),
      addressesLoading: gettingUserAddressesLoadingSelector(state),
    };
  };

  const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
    return {
      getAddresses: () => {
        dispatch(getAddresses());
      },
      onSubmitNewAddress: address => {
        dispatch(newAddress(address));
      },
      onSubmitUpdateAddress: address => {
        dispatch(updateAddress(address));
      },
      onSubmitDeleteAddress: addressId => {
        dispatch(deleteAddress(addressId));
      },
    };
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class UserAddressConnector extends Component {
      constructor(props: OwnProps) {
        super(props);
        if (props.userId) {
          props.getAddresses();
        }
      }

      UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.props.userId && nextProps.userId) {
          this.props.getAddresses();
        }
      }

      render() {
        return (
          <WrappedComponent
            {...this.props}
            addresses={this.props.addresses}
            newAddress={this.props.onSubmitNewAddress}
            updateAddress={this.props.onSubmitUpdateAddress}
            deleteAddress={this.props.onSubmitDeleteAddress}
            addressesDeleteByIdLoading={this.props.deleteByIdLoading}
            addressesEditingLoading={this.props.editingLoading}
            addressesAddingLoading={this.props.addingLoading}
            addressesLoading={this.props.addressesLoading}
          />
        );
      }
    },
  );
}

export default userAddressConnector;
