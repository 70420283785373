// @flow
import React from 'react';
// Styles
import './BlogFeaturedPosts.css';
// Components
import { Link } from 'react-router-dom';

type Props = {
  posts: Post[];
}

const maxNumberOfFeaturedPosts = 2;

const BlogFeaturedPosts = (props): Props => {
  
  if (props.loading && props.posts.length === 0) {
    return (
      <div className='d-flex justify-content-center align-items-center p-3'>
        <div className="loading ">
        <i
          className="fa fa-spinner fa-pulse fa-4x loading-icon"
          aria-hidden="true"
        />
        </div>
      </div>
    )
  }

  return (
  <div className="blog-featured-posts-main">
    <h2>Destaques</h2>
    <div className='blog-featured-posts-row'>
      {props.posts.slice(0,maxNumberOfFeaturedPosts).map(post => (
        <Link key={post.slug} to={`/blog/${post.slug}`} className='blog-featured-posts-anchor-card'>
          <div className='blog-featured-posts-card'>
            <img src={post.thumbnailImageUrl} alt={post.title}></img>
            <h3>{post.title}</h3>
            <p>{post.description}</p>
          </div>
        </Link>))}
    </div>
  </div >
  );
};

export default BlogFeaturedPosts;

