// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Selectors

// Actions

// Components
import MyAccountPageContainer from '../components/myAccount/MyAccountPageContainer';
import { PastOrdersIndex } from '../../past-orders';
// Styles
import './MyAccountOrdersPage.css';

// Types

type Props = {};

class MyAccountOrdersPage extends Component {
  props: Props;

  render() {
    return (
      <MyAccountPageContainer>
        <PastOrdersIndex />
      </MyAccountPageContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(MyAccountOrdersPage);
