import React, { useState } from 'react';

// Components and Containers
import BarbecueForm from '../containers/BarbecueForm';
import BarbecueCart from '../containers/BarbecueCart';
import { ColorRow } from '../../common';

// Styles
import './BarbecueCalculator.css';

const BarbecueCalculator = () => {
  const [barbecueCreated, setBarbecueCreated] = useState(false);

  return (
    <div className="barbecue-calculator-container">
      <ColorRow color="orange" text="CHURRASCÔMETRO" />
      <div className="barbecue-calculator-header">
        <h1 className="header-title">Big Churrasco</h1>
        <h4 className="header-subtitle">O seu melhor churrasco começa aqui!</h4>
      </div>
      <div className="barbecue-calculator-content">
        <BarbecueForm
          barbecueCreated={barbecueCreated}
          setBarbecueCreated={setBarbecueCreated}
        />
        <BarbecueCart
          barbecueCreated={barbecueCreated}
          setBarbecueCreated={setBarbecueCreated}
        />
      </div>
    </div>
  );
};

export default BarbecueCalculator;
