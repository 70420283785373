// @flow
import React, { useEffect, useState } from 'react';
// Components
import {
  Button,
  Checkbox,
  validationHelpers,
  FormControlInput,
} from '../../common';
import { Link } from 'react-router-dom';
// Styles
import './LoginBox.css';

type Props = {
  isLoading: boolean,
  errors: {
    badUsername: boolean,
    badPassword: boolean,
  },
  onSubmitLogin: () => void,
};

type FormValue = {
  email: string,
  password: string,
  rememberMe: boolean,
};

const defaultFormValue: FormValue = {
  email: '',
  password: '',
  rememberMe: false,
};

type FormErrors = {
  passwordError: string,
  emailError: string,
};

const defaultFormErrors: FormErrors = {
  passwordError: '',
  emailError: '',
};

const LoginBox = ({ isLoading, errors, onSubmitLogin }: Props) => {
  const [formValue, setFormValue] = useState(defaultFormValue);
  const [formErrors, setFormErrors] = useState(defaultFormErrors);

  const validateUserInput = () => {
    const { email, password } = formValue;
    if (email) {
      setFormErrors(prevFormErrors => ({
        ...prevFormErrors,
        emailError: validationHelpers.user.email(email),
      }));
    }
    if (password) {
      setFormErrors(prevFormErrors => ({
        ...prevFormErrors,
        passwordError: validationHelpers.user.password(password),
      }));
    }
  };

  useEffect(() => {
    if (!isLoading) {
      if (errors.badUsername || errors.badPassword) {
        setFormErrors(prevFormErrors => ({
          ...prevFormErrors,
          emailError: 'Email ou senha incorretos',
        }));
      }
    }
    // eslint-disable-next-line
  }, [isLoading]);

  const onChangeText = (target: string, value: string) => {
    setFormValue(prevFormValue => ({ ...prevFormValue, [target]: value }));
  };

  return (
    <div className="login-box-main login-box-fade-in">
      <form
        onSubmit={e => {
          const { email, password, rememberMe } = formValue;
          e.preventDefault();
          onSubmitLogin(email, password, rememberMe);
        }}
      >
        <FormControlInput
          value={formValue.email}
          label="Email"
          errorMessage={formErrors.emailError}
          autocomplete="email"
          handleChangeText={value => onChangeText('email', value)}
          handleFocus={value => onChangeText('email', value)}
          handleBlur={() => validateUserInput()}
        />
        <FormControlInput
          label="Senha"
          value={formValue.password}
          errorMessage={formErrors.passwordError}
          type="password"
          handleChangeText={value => onChangeText('password', value)}
          handleFocus={value => onChangeText('password', value)}
          handleBlur={() => validateUserInput()}
        />
        <div className="login-box-actions">
          <div className="login-box-forgot-password">
            <Link to="/esqueci-senha/">Esqueci a senha</Link>
          </div>
          <Checkbox
            className="login-box-remember-me"
            value={formValue.rememberMe}
            tooltip="Reduz o número de vezes que você terá de fazer login neste
                   dispositivo.&#10;Por questões de segurança, utilize apenas em dispositivos pessoais."
            onChange={e =>
              setFormValue(prevFormValue => ({
                ...prevFormValue,
                rememberMe: e.target.checked,
              }))
            }
            label={<span>Mantenha-me conectado</span>}
          />
        </div>
        <Button
          text="ENTRAR"
          icon=""
          buttonType="button-secondary"
          loading={isLoading}
          disabled={!formValue.email || !formValue.password}
        />
      </form>
    </div>
  );
};

export default LoginBox;
