// Images
import butcherCheck from '../images/butcherCheck.svg';
import butcherBalance from '../images/butcherBalance.svg';
import butcherSchedule from '../images/butcherSchedule.svg';
import roast from '../images/iconRoast.svg';
import stew from '../images/iconStew.svg';
import baked from '../images/iconBaked.svg';
import grilled from '../images/iconGrilled.png';
import fried from '../images/iconFried.png';
import broth from '../images/iconBroth.png';

const butcherData = {
  sliderSelectOptions: [
    {
      label: 'Assado',
      value: 'butcher_roast',
      img: roast,
    },
    {
      label: 'Cozido',
      value: 'butcher_baked',
      img: baked,
    },
    {
      label: 'Ensopado',
      value: 'butcher_stew',
      img: stew,
    },
    {
      label: 'Grelhado',
      value: 'butcher_grilled',
      img: grilled,
    },
    {
      label: 'Frito',
      value: 'butcher_fried',
      img: fried,
    },
    {
      label: 'Caldo',
      value: 'butcher_broth',
      img: broth,
    },
  ],
  butcherGridItems: [
    {
      img: butcherCheck,
      title: 'Você escolhe como serão feitos os cortes',
      text: 'Moído, bifes, cubos, tiras... <span>do jeito que você precisa</span>',
    },
    {
      img: butcherBalance,
      title: 'Carne limpa e porcionada para o seu dia a dia',
      text: 'Prepare a <span>quantidade ideal</span> de proteína limpa para suas refeições',
    },
    {
      img: butcherSchedule,
      title: 'Receba onde precisar quando puder',
      text: 'Churrasco para o fim de semana? ou <span>garantir a dieta do mês?</span>',
    },
  ],
  faqQuestions: [
    {
      title: 'Qual a diferença do Bigdelivery para um açougue convencional?',
      body: 'Nós entregamos o produto no conforto do seu lar, pré-cortado da maneira que você desejar (cubos, bifes, tiras, moído e muitos outros) porcionado da forma que você quiser (pacotes a vácuo de 500g, 1kg ou mais)',
      isOpen: false,
    },
    {
      title: 'Como funciona o Bigdelivery?',
      body: 'Você realiza um pedido em nosso app ou site, um de nossos açougueiros coleta a peça solicitada, remove a gordura conforme a sua escolha e então pesa o produto. Isso mesmo, só pesamos a peça após a remoção das gorduras, ou seja mais carne para você :)',
      isOpen: false,
    },
    {
      title: 'Como o Bigdelivery melhora o meu dia-a-dia?',
      body: 'A extrema praticidade que o Bigdelivery oferece é essencial no mundo moderno, você não vai mais precisar se preocupar em cortar suas peças de carne, ou ter que ir fisicamente a um açougue compra-las, agende um pedido no Bigdelivery e experimente o futuro!',
      isOpen: false,
    },
    {
      title: 'O Bigdelivery pode me ajuda a fazer um churrasco?',
      body: 'Claro, por aqui somos fãs de carteirinha de churrasco, além de termos opções de cortes específicas para churrasco (corte para grelha e para espeto) também temos uma ferramenta perfeita para quem não sabe muito bem como montar um churrasco, confira nosso Churrascômetro.',
      isOpen: false,
    },
    {
      title: 'O Bigdelivery só trabalha com carnes bovinas?',
      body: 'Não, também trabalhamos com praticamente todas as categorias de proteína animal, pescados, aves, caprinos e ovinos. Também oferecemos diversos produtos e acessórios que auxiliam o seu preparo!',
      isOpen: false,
    },
  ],
};

export default butcherData;
