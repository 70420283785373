// @flow
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './SearchResultImage.css';

// Components
import { ProgressiveImageLoader } from '../../common';

type Props = {
  hit: any,
};

class SearchResultImage extends Component {
  props: Props;
  render() {
    return (
      <div className="search-result-image-main">
        <Link to={`/produtos/${this.props.hit.slug}/`}>
          <ProgressiveImageLoader
            selectedImage={this.props.hit.images[0]}
            imageToShow={'product'}
          />
        </Link>
      </div>
    );
  }
}

export default SearchResultImage;
