import { authGet } from '../../api';

const Common = {
  async ping() {
    const response = await authGet(`/health_check`);
    await response;
  },
};

export default Common;
