// @flow
import React from 'react';
// Styles
import './InfoCards.css';
import Slider from 'react-slick';
//Types
import type { Settings } from 'react-slick';

type InfoCard = {
  title: string,
  text: string,
  img: string,
};

type Props = {
  cards: InfoCard[],
};

const { innerWidth } = window;

const InfoCards = ({ cards }: Props) => {
  const settings: Settings = {
    dots: false,
    infinite: innerWidth < 750,
    autoplay: innerWidth < 750,
    variableWidth: true,
    arrows: false,
    swipe: innerWidth < 750,
  };

  return (
    <Slider {...settings} className="info-cards">
      {cards.map(({ title, img, text }) => (
        <div className="info-card" key={title}>
          <img src={img} alt={title} />
          <div className="info-card-content">
            <h6 className="info-card-content-title">
              {title}
              <span>{text}</span>
            </h6>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default InfoCards;
