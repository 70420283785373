// @flow
import React from 'react';
// Styles
import './LandingTitleSubtitleSection.css';
// TYPE PROPS
type Props = {
  backgroundColor: string,
  sectionTitle?: string,
  sectionSubTitle?: string,
};

const LandingTitleSubtitleSection = ({
  backgroundColor,
  sectionTitle,
  sectionSubTitle,
}: Props) => {
  return (
    <div
      className="landing-simple-section"
      style={{
        'background-color': backgroundColor,
      }}
    >
      <h1
        className="landing-simple-section-title"
        dangerouslySetInnerHTML={{ __html: sectionTitle }}
      ></h1>
      <h3
        className="landing-simple-section-subtitle"
        dangerouslySetInnerHTML={{ __html: sectionSubTitle }}
      ></h3>
    </div>
  );
};

export default LandingTitleSubtitleSection;
