// @flow
import React from 'react';
// Components
import { Link } from 'react-router-dom';
// Style
import './HamburgerMenu.css';
// Composers
import { userLoggedInConnector } from '../../user';

type Props = {
  loggedIn: boolean,
  userId: number,
  bigClubEnabled: boolean,
  onNavigate: () => void,
};

const HamburgerMenu = (props: Props) => {
  const bigClubUrl = !!props.userId ? '/bigclub' : '/lp/bigclub';

  return (
    <div className="hamburger-menu-main">
      {props.bigClubEnabled && (
        <Link
          to={bigClubUrl}
          onClick={props.onNavigate}
          className="hamburger-bigclub-mobile-main"
        >
          <div className="hamburger-bigclub-mobile-content">
            <p>
              <b>Big</b>Club
            </p>
            <div />
            <span>
              O nosso{' '}
              <b>
                programa
                <br /> de fidelidade
              </b>
            </span>
          </div>
        </Link>
      )}
      <Link className="hamburger-menu-link" onClick={props.onNavigate} to="/">
        Início
      </Link>
      <Link
        className="hamburger-menu-link"
        onClick={props.onNavigate}
        to="/departamentos/"
      >
        Departamentos
      </Link>
      <Link
        className="hamburger-menu-link"
        onClick={props.onNavigate}
        to="/minha-conta/informacoes/"
      >
        Minha conta
      </Link>
      {props.loggedIn ? (
        <Link
          className="hamburger-menu-link"
          onClick={props.onNavigate}
          to="/blend/meus-blends/"
        >
          Meus blends
        </Link>
      ) : (
        <Link
          className="hamburger-menu-link"
          onClick={props.onNavigate}
          to="/blend/"
        >
          Blends
        </Link>
      )}
      <Link
        className="hamburger-menu-link"
        onClick={props.onNavigate}
        to="/bigchurrasco"
      >
        Churrascômetro
      </Link>
      <Link
        className="hamburger-menu-link"
        onClick={props.onNavigate}
        to="/sobre/"
      >
        Sobre nós
      </Link>
      <Link
        className="hamburger-menu-link"
        onClick={props.onNavigate}
        to="/trabalhe/"
      >
        Trabalhe conosco
      </Link>
      <Link
        className="hamburger-menu-link"
        onClick={props.onNavigate}
        to="/perguntas/"
      >
        Perguntas frequentes
      </Link>
      {!props.loggedIn ? (
        <Link
          className="hamburger-menu-link"
          onClick={props.onNavigate}
          to="/login/"
        >
          Fazer login
        </Link>
      ) : (
        <Link
          className="hamburger-menu-link"
          onClick={props.onNavigate}
          to="/logout/"
        >
          Sair da conta
        </Link>
      )}
    </div>
  );
};

export default userLoggedInConnector(HamburgerMenu);
