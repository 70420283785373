// @flow
import React, { Component } from 'react';
// Components
import MyAccountNavigator from './MyAccountNavigator';
import UnauthenticatedRedirect from '../../containers/UnauthenticatedRedirect';
import { Helmet } from 'react-helmet';
// Styles
import './MyAccountPageContainer.css';

type Props = {
  children: any,
};

class MyAccountPageContainer extends Component {
  props: Props;

  render() {
    return (
      <UnauthenticatedRedirect>
        <div className="my-account-info-page-main">
          <Helmet>
            <title>Minha Conta - Bigdelivery, Seu açougue virtual</title>
          </Helmet>
          <div className="my-account-page-container-content">
            <MyAccountNavigator />
            <div className="my-account-page-container">
              {this.props.children}
            </div>
          </div>
        </div>
      </UnauthenticatedRedirect>
    );
  }
}

export default MyAccountPageContainer;
