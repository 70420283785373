// @flow
import React from 'react';
// Styles
import './KitsProductCard.css';
// Types
import { Product } from '../../products';
// Components
import { ProductPrice, ProgressiveImageLoader } from '../../common';
import CookingRecommedationBox from '../../current-order/components/CookingRecommedationBox';
import GoToCheckoutButton from './GoToCheckoutButton';
//Helpers
import { scaleConverter } from '../../common';

type Props = {
  sliderProductsVariants: Product[],
  currentLanding: string,
};

const KitsProductCard = ({
  sliderProductsVariants = [],
  productsVariants = [],
  currentLanding,
}: Props) => {
  const calculateProductDiscount = (listPrice, price) => {
    if (listPrice > price) {
      const discountPercentage = ((listPrice - price) / listPrice) * 100;
      return Math.round(discountPercentage);
    }
    return null;
  };

  if (sliderProductsVariants.length <= 0) {
    return null;
  }

  const product: Product = sliderProductsVariants[11];
  const price = scaleConverter(product.price);
  const listPrice = scaleConverter(product.listPrice);
  const productOnPromotion = listPrice > price;
  const productDiscount = calculateProductDiscount(listPrice, price);

  return (
    <div className="kits-product-card-main-container">
      <div className="kits-product-card-individual-product-container">
        <div className="kits-product-card-image-container">
          {productOnPromotion && productDiscount >= 5 && (
            <div className="kits-product-card-discout-square">
              <p>{Math.round(productDiscount)}%</p>
              <span>OFF</span>
            </div>
          )}
          <ProgressiveImageLoader
            selectedImage={product.images[0]}
            imageToShow={'large'}
          />
        </div>
      </div>
      <div className="kits-product-card-product-details">
        <div className="kits-product-card-product-text">
          <p className="kits-product-card-product-manufacturer">
            {product.manufacturer}
          </p>
          <h4 className="kits-product-card-product-name">{product.name}</h4>
        </div>
        <div className="kits-product-card-product-lower-content">
          <div className="kits-product-card-icons-container">
            <div className="kits-product-card-icons-content">
              <CookingRecommedationBox
                cookingMethods={product.cookingRecommendations}
              />
            </div>
          </div>
          <div className="kits-product-card-buy-container">
            <ProductPrice
              productListPrice={product.listPrice}
              productPrice={product.price}
              productUnit={product.unitForQuantity}
            />
            <GoToCheckoutButton
              showNotifyMeButton={false}
              productId={product.id}
              slug={product.slug}
              unitForQuantity={product.unitForQuantity}
              defaultQuantity={product.defaultQuantity}
              incrementQuantity={product.incrementQuantity}
              inStock={product.inStock}
              discontinued={product.discontinued}
              shouldNotifyUser={product.shouldNotifyUser}
              analyticsEventType={'landingPage'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default KitsProductCard;
