// @flow
import { schema } from 'normalizr';

export const product = new schema.Entity('product');
export const products = new schema.Array(product);

export const displayVariant = new schema.Entity('displayVariants', {
  variant: product,
});
export const displayVariants = new schema.Array(displayVariant);
