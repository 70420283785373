// @flow
import { handleActions } from 'redux-actions';
import type { Reducer } from 'redux';
import type { ActionType } from 'redux-actions';
import { combineReducers } from 'redux';
// Actions
import * as actions from '../actions';
// Types
import type { StoreState } from '../types/StoreState';

const popups: Reducer<StoreState[], ActionType<*>> = handleActions(
  {
    [actions.getPopups]: {
      next: (state, action) => action.payload.entities.popups,
    },
    [actions.postShowedPopup]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.popups,
      }),
    },
  },
  {},
);

const reducers = combineReducers({
  popups,
});

export default reducers;
