// @flow
import { createAction } from 'redux-actions';
// API
import Order from '../api';
// Selectors

// Types
import type { Dispatch, State } from '../../types';
// Logger
import { logAPIException } from '../../logHelper';

/******************
 *** GET ORDERS ***
 ******************/
export const fetchPastOrdersRequest = createAction('FETCH_PAST_ORDERS_REQUEST');
export const fetchPastOrders = createAction('FETCH_PAST_ORDERS');

export function getPastOrders(page: number, perPage: number) {
  return async (dispatch: Dispatch, getState: () => State) => {
    try {
      dispatch(fetchPastOrdersRequest());

      const response = await Order.getIndex(page, perPage);
      dispatch(fetchPastOrders(response));
    } catch (err) {
      logAPIException(err);
      dispatch(fetchPastOrders(err));
    }
  };
}

/*******************
 *** GET TRACKER ***
 *******************/
export const fetchTrackerRequest = createAction('FETCH_TRACKER_REQUEST');
export const fetchTracker = createAction('FETCH_TRACKER');

export function getTracker(orderNumber: string) {
  return async (dispatch: Dispatch, getState: () => State) => {
    try {
      dispatch(fetchTrackerRequest());

      const response = await Order.getTracker(orderNumber);
      dispatch(fetchTracker(response));
    } catch (err) {
      logAPIException(err);
      dispatch(fetchTracker(err));
    }
  };
}

/****************************
 *** GET SINGLE PAST ORDER ***
 ****************************/
export const fetchPastOrderRequest = createAction('FETCH_PAST_ORDER_REQUEST');
export const fetchPastOrder = createAction('FETCH_PAST_ORDER');

export function getPastOrder(orderNumber: string, orderToken: ?string = null) {
  return async (dispatch: Dispatch, getState: () => State) => {
    try {
      dispatch(fetchPastOrderRequest());

      const response = await Order.getPastOrder(orderNumber, orderToken);
      dispatch(fetchPastOrder(response));
    } catch (err) {
      logAPIException(err);
      dispatch(fetchPastOrder(err));
    }
  };
}
