import { combineActions, handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import type { Reducer } from 'redux';
// Actions
import * as actions from '../actions';
// Types
import type { Action } from '../../types';
import type { StoreState } from '../types';

const indexBlog: Reducer<string, Action> = handleActions(
  {
    [actions.fetchPostsBlog]: {
      next: (state, action) => action.payload.result,
      throw: (state, action) => state,
    },
  },
  [],
);
const indexNews: Reducer<string, Action> = handleActions(
  {
    [actions.fetchPostsNews]: {
      next: (state, action) => action.payload.result,
      throw: (state, action) => state,
    },
  },
  [],
);
const indexPinned: Reducer<string, Action> = handleActions(
  {
    [actions.fetchPinnedPosts]: {
      next: (state, action) => action.payload.result,
      throw: (state, action) => state,
    },
  },
  [],
);

const posts: Reducer<{ [string]: any }, Action> = handleActions(
  { 
     [combineActions(actions.fetchPostsBlog, actions.fetchPostsNews, actions.fetchPinnedPosts, actions.fetchPost)]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.post,
      }),
      throw: (state, action) => state,
    },
  },
  {},
);

//Loading
const initialLoadingState = {
  current: false,
  index: false,
};

const loading: Reducer<string, Action> = handleActions(
  {
    [actions.fetchPostsBlog]: (state, action) => ({ ...state, index: false }),
    [actions.fetchPostsBlogRequest]: (state, action) => ({
      ...state,
      index: true,
    }),
    [actions.fetchPostsNews]: (state, action) => ({ ...state, index: false }),
    [actions.fetchPostsNewsRequest]: (state, action) => ({
      ...state,
      index: true,
    }),
    [actions.fetchPinnedPosts]: (state, action) => ({ ...state, index: false }),
    [actions.fetchPinnedPostsRequest]: (state, action) => ({
      ...state,
      index: true,
    }),
    [actions.fetchPost]: (state, action) => ({ ...state, current: false }),
    [actions.fetchPostRequest]: (state, action) => ({ ...state, current: true }),
  },
  initialLoadingState,
);

const reducer: Reducer<StoreState, Action> = combineReducers({
  posts,
  indexBlog,
  indexNews,
  indexPinned,
  loading,
});

export default reducer;
