// @flow
import React from 'react';
// Composers
// Components
import ProductHit from '../components/ProductHit';
import {
  InstantSearch,
  Hits,
  SearchBox,
  connectMenu,
  Configure,
  connectStateResults,
} from 'react-instantsearch-dom';
// Styles
import './ProductSearch.css';
// Types

type Props = {
  blendTemplateId: number | string,
  isItemInBlend: (variantId: number | string) => boolean,
  AddItemToBlendTemplate: (variantId: number, quantity: number) => void,
};

const VirtualMenu = connectMenu(() => null);

const Content = connectStateResults(
  ({ searchState, searchResults, children }) =>
    searchResults && searchResults.nbHits !== 0 ? (
      <div>{children}</div>
    ) : (
      <div className="no-results">
        Nenhum resultado encontrado para "{searchState.query}"
      </div>
    ),
);

class ProductSearch extends React.PureComponent {
  props: Props;

  render() {
    return (
      <div className="product-search-main">
        <InstantSearch
          appId={process.env.REACT_APP_ALGOLIA_APP_ID}
          apiKey={process.env.REACT_APP_ALGOLIA_API_KEY}
          indexName={
            process.env.REACT_APP_ALGOLIA_PRODUCT_INDEX ||
            'product_sales_production'
          }
        >
          <div className="search-header">
            <Configure hitsPerPage={9} />

            <VirtualMenu
              attribute="option_values.name"
              defaultRefinement="moido"
            />

            <SearchBox //searchbox
              translations={{
                placeholder: 'Procurar produtos',
              }}
            />
          </div>
          <div className="product-search-hits">
            <Content>
              <Hits
                hitComponent={props =>
                  ProductHit({
                    ...props,
                    blendTemplateId: this.props.blendTemplateId,
                    isItemInBlend: this.props.isItemInBlend,
                    AddItemToBlendTemplate: this.props.AddItemToBlendTemplate,
                    addingBlendTemplateItemsLoading: this.props
                      .addingBlendTemplateItemsLoading,
                  })
                }
              />
            </Content>
          </div>
        </InstantSearch>
      </div>
    );
  }
}

export default ProductSearch;
