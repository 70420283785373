// @flow
import React, { useEffect, useState } from 'react';
// Components
import Slider from 'react-slick';
import LandingFAQ from '../components/LandingFAQ';
import { ProductSquare } from '../../products';
import { ProductPrice, ProgressiveImageLoader } from '../../common';
import { AddToCartButton } from '../../current-order';
// Data
import butcherData from '../data/butcherData';
// Connectors
import { displayVariantsByPageAndSectionConnector } from '../../products';
// Images
import bigDeliveryApp from '../images/salesApp.png';
import mainImage from '../images/butcherMainImage.svg';
import downloadAtPlayStore from '../images/downloadAtPlayStore.jpg';
import downloadAtAppleStore from '../images/downloadAtAppleStore.jpg';
import butcherBanner from '../images/butcherBanner.jpg';
// Styles
import './Butcher.css';
// Analytics
import { logLandingSliderSlid } from '../../common/analytics';

type Props = {
  findDisplayVariantsByPageAndSection: (
    page: string,
    section: string,
  ) => Product[],
  productByPageAndSection: (page: string, section: string) => Product[],
};

const randomFilterOptionIndex = Math.floor(
  Math.random() * (butcherData.sliderSelectOptions.length - 0) + 0,
);

const Butcher = ({
  productByPageAndSection,
  findDisplayVariantsByPageAndSection,
}: Props) => {
  const [filterOption, setFilterOption] = useState(
    butcherData.sliderSelectOptions[randomFilterOptionIndex],
  );

  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    vertical: false,
    lazyLoad: true,
    arrows: false,
  };

  const sliderProductsVariants = productByPageAndSection(
    filterOption ? filterOption.value : 'butcher',
    'slider',
  );
  const productsListVariants = productByPageAndSection(
    'butcher_list',
    'product_list',
  );

  const onSelectFilter = event => {
    const result = butcherData.sliderSelectOptions.find(
      op => op.value.toLowerCase() === event.target.value.toLowerCase(),
    );
    setFilterOption(result ? result : { value: null });
  };

  if (filterOption) {
    findDisplayVariantsByPageAndSection(filterOption.value);
  }

  const onSlideChange = () => {
    logLandingSliderSlid('butcher');
  };

  return (
    <div className="butcher-landing">
      <main className="butcher-main butcher-gap">
        <div className="butcher-main-content">
          <img
            src={mainImage}
            className="butcher-main-img"
            alt="Açougue, só que virtual."
          />
          <p className="butcher-main-text">
            Uma nova experiência em comprar seus cortes preferidos, seja para o
            dia a dia, ou aquele churrascão de respeito!
          </p>
          <a href="#products">
            <button className="butcher-main-button">
              Descubra o seu corte!
            </button>
          </a>
        </div>
      </main>
      <section className="butcher-banner-section">
        <img
          src={butcherBanner}
          className="butcher-banner-img"
          alt="Foto de carnes"
        />
      </section>

      <section className="butcher-primary-section butcher-gap">
        <h1 className="butcher-primary-section-title">
          Tudo para <span>facilitar o seu dia a dia</span>
        </h1>
        <div className="butcher-primary-section-grid">
          {butcherData.butcherGridItems.map(item => (
            <div className="butcher-primary-section-grid-item" key={item.title}>
              <img
                src={item.img}
                className="butcher-primary-section-grid-item-img"
                alt={item.alt}
              />
              <h4 className="butcher-primary-section-grid-item-title">
                {item.title}
              </h4>
              <p
                className="butcher-primary-section-grid-item-text"
                dangerouslySetInnerHTML={{ __html: item.text }}
              ></p>
            </div>
          ))}
        </div>
      </section>
      <section className="butcher-product-list-container" id="products">
        <h3 className="butcher-product-list-title">
          Faça a <span>sua escolha</span>
        </h3>
        <div className="butcher-slider-section-row">
          <p className="butcher-slider-section-text">
            <span>Escolha o tipo de preparo</span> que você quer fazer e
            encontre o corte ideal para suas receitas!
          </p>
          <div className="butcher-slider-select-container">
            {filterOption && filterOption.value && (
              <img src={filterOption.img} alt={filterOption.value} />
            )}
            <select
              onChange={onSelectFilter}
              className="butcher-slider-section-select"
              value={filterOption.value}
            >
              {butcherData.sliderSelectOptions.map((selectOp, index) => (
                <option value={selectOp.value} key={index}>
                  {selectOp.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        {sliderProductsVariants.length > 0 && (
          <Slider {...settings} afterChange={onSlideChange}>
            {sliderProductsVariants.map((sliderProduct, index) => (
              <div className="butcher-product-list-slider-wrapper" key={index}>
                <div className="butcher-product-list-slider-meal-card">
                  <ProgressiveImageLoader
                    selectedImage={sliderProduct.images[0]}
                    imageToShow={'large'}
                  />
                  <div className="butcher-product-list-slider-meal-card-content">
                    <h4 className="butcher-product-list-slider-meal-card-title">
                      {sliderProduct.name}
                    </h4>
                    <p
                      className="butcher-product-list-slider-meal-card-text"
                      dangerouslySetInnerHTML={{
                        __html: sliderProduct.description,
                      }}
                    ></p>
                    <div className="butcher-product-list-slider-meal-card-actions">
                      <ProductPrice
                        productListPrice={sliderProduct.listPrice}
                        productPrice={sliderProduct.price}
                        productUnit={sliderProduct.unitForQuantity}
                      />
                      <AddToCartButton
                        showNotifyMeButton={false}
                        productId={sliderProduct.id}
                        slug={sliderProduct.slug}
                        unitForQuantity={sliderProduct.unitForQuantity}
                        defaultQuantity={sliderProduct.defaultQuantity}
                        incrementQuantity={sliderProduct.incrementQuantity}
                        inStock={sliderProduct.inStock}
                        discontinued={sliderProduct.discontinued}
                        shouldNotifyUser={sliderProduct.shouldNotifyUser}
                        analyticsEventType={'landingPage'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        )}
      </section>
      <section className="butcher-secondary-section butcher-gap-alt">
        <div className="butcher-secondary-section-content">
          <h3 className="butcher-secondary-section-content-title">
            Baixe <span>nosso app</span>
          </h3>
          <p className="butcher-secondary-section-content-text">
            Acompanhe todas as promoções da <b>palma da sua mão</b>
          </p>
          <a
            href="https://play.google.com/store/apps/details?id=com.bigdelivery.salesapp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={downloadAtPlayStore}
              className="butcher-secondary-section-content-img"
              alt="App disponível na playstore"
            />
          </a>
          <a
            href="https://apps.apple.com/br/app/bigdelivery/id1521057804"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={downloadAtAppleStore}
              className="butcher-secondary-section-content-img"
              alt="App disponível na app store"
            />
          </a>
        </div>
        <img
          className="butcher-secondary-section-content-img-alt"
          src={bigDeliveryApp}
          alt="App do BigDelivery"
        />
      </section>
      <section className="butcher-product-section butcher-gap">
        <h3 className="butcher-product-section-title">
          Outros cortes que <span>você vai amar</span>
        </h3>
        <div className="butcher-product-list">
          {productsListVariants.map((productVariant, index) => {
            const productVariantName =
              productVariant.name.length < 40
                ? productVariant.name
                : productVariant.name.substring(0, 38) + '...';
            return (
              <ProductSquare
                key={index}
                productId={productVariant.id}
                productImage={productVariant.images[0]}
                productName={productVariantName}
                productManufacturer={productVariant.manufacturer}
                productListPrice={productVariant.listPrice}
                productPrice={productVariant.price}
                productUnit={productVariant.unitForQuantity}
                productSlug={productVariant.slug}
                parentProductId={productVariant.productId}
                productTotalOnHand={productVariant.totalOnHand}
                defaultQuantity={productVariant.defaultQuantity}
                incrementQuantity={productVariant.incrementQuantity}
                inStock={productVariant.inStock}
                discontinued={productVariant.discontinued}
                analyticsViewSource={'landingPage'}
              />
            );
          })}
        </div>
      </section>
      <div className="butcher-faq-questions">
        <LandingFAQ
          faqQuestions={butcherData.faqQuestions}
          currentLanding="butcher"
        />
      </div>
    </div>
  );
};
export default displayVariantsByPageAndSectionConnector(Butcher);
