import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Styles
import './PeriodicGetter.css';

const PeriodicGetter = ({ loading, countdownStartValue, handleGet }) => {
  const defaultCountdownStartValue = 60;
  const [count, setCount] = useState(
    countdownStartValue || defaultCountdownStartValue,
  );

  useEffect(() => {
    const timer = setTimeout(() => setCount(count - 1), 1000);
    if (count === 0) {
      handleGet();
      setCount(countdownStartValue || defaultCountdownStartValue);
    }
    return () => clearTimeout(timer);
  }, [count, countdownStartValue, handleGet]);

  return (
    <div className="periodic-getter-phrase">
      {loading ? `Atualizando...` : `Atualizando em ${count} s`}
    </div>
  );
};

PeriodicGetter.propTypes = {
  loading: PropTypes.bool,
  countdownStartValue: PropTypes.number,
  handleGet: PropTypes.func.isRequired,
};

export default PeriodicGetter;
