//@flow
import React from 'react';
//Styles
import './ShippingAndExchangePage.css';
import { PageTitleWithGoBack } from '../../common';
import { Helmet } from 'react-helmet';

const ShippingAndExchangePage = () => {
  return (
    <div className="shipping-and-exchange-container">
      <Helmet>
        <title>Frete e Trocas - Bigdelivery, Seu açougue virtual</title>
      </Helmet>
      <PageTitleWithGoBack title="Frete e Trocas" />
      <article className="shipping-and-exchange-article">
        <h4 className="shipping-and-exchange-article-title">Frete</h4>
        <p className="shipping-and-exchange-article-text">
          <b>Não cobramos frete para pedidos acima de R$150</b>, desde que o
          pedido seja feito com antecedência de 24 horas.
          <br /> Caso o valor do pedido não atinja o mínimo de 150 reais, será
          cobrado um <b>frete fixo de 10 reais.</b>
        </p>

        <p className="shipping-and-exchange-article-text">
          Oferecemos também a opção de frete expresso pela <b>Mottu</b>, com
          valor cobrado pela plataforma.
        </p>
      </article>
      <article className="shipping-and-exchange-article">
        <h4 className="shipping-and-exchange-article-title">Trocas</h4>
        <p className="shipping-and-exchange-article-text">
          Caso você não esteja totalmente satisfeito com o seu pedido,{' '}
          <b>
            temos uma política de trocas que visa garantir a sua satisfação.
          </b>
        </p>
        <p className="shipping-and-exchange-article-text">
          O sistema de trocas funciona{' '}
          <b>no máximo até 7 dias após o recebimento do produto.</b> Para
          efetuar a troca, é necessário que o cliente nos informe o estado do
          produto, a insatisfação em relação ao produto (qual foi o problema? o
          que houve com o pedido?) e tire uma foto ou vídeo do produto.
        </p>
        <p className="shipping-and-exchange-article-text">
          <b>As trocas são realizadas normalmente no mesmo dia</b> ou no próximo
          dia, após avaliação da equipe BigDelivery.
        </p>
        <p className="shipping-and-exchange-article-text">
          Contamos com a sua compreensão e colaboração para oferecermos um
          serviço cada vez melhor.
        </p>
      </article>
    </div>
  );
};

export default ShippingAndExchangePage;
