// @flow
import { camelizeKeys } from 'humps';
import { normalize } from 'normalizr';
// Schema
import * as schema from './schema';
// API
import { authGet, authPost } from '../../api';
// Analytics
import { getDeviceId } from '../../common/analytics';

// HEADERS CONFIGURATION
const headers = new Headers();
headers.append('X-DEVICE-ID', getDeviceId());

const Popups = {
  async fetchPopups() {
    const response = await authGet(`/v2/popups`, null, null, headers);
    const data = await response.json().then(b => {
      return camelizeKeys(b);
    });
    const normalized = normalize(data, schema.popups);
    return normalized;
  },
  async postShowedPopup(id: number) {
    const body = {};
    const response = await authPost(
      `/v2/popups/${id}/views`,
      body,
      null,
      headers,
    );
    const data = await response.json().then(r => {
      return camelizeKeys(r);
    });
    const normalized = normalize(data, schema.popups);
    return normalized;
  },
};

export default Popups;
