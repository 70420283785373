// @flow
import { createAction } from 'redux-actions';
import qs from 'qs';
import history from '../../history';
// Types
import type { Dispatch } from '../../types';
import type { Address, ProductList } from '../types';
// Apis
import User from '../api';
import { updateToken } from '../api/session';
import { isAuthenticated } from '../../api';
// Selectors
import { userIdSelector } from '../selectors';
// Logger
import { logAPIException } from '../../logHelper';

// Register
export const registerUserRequest = createAction('REGISTER_USER_REQUEST');
export const registerUser = createAction('REGISTER_USER');

export function register(
  firstName: string,
  lastName: string,
  cpf: string,
  email: string,
  password: string,
  birthdate: Date,
  referralCode: string,
  recaptchaToken: string,
  rememberMe: boolean,
) {
  return async (dispatch: Dispatch) => {
    dispatch(registerUserRequest());
    try {
      const userResponse = await User.register(
        firstName,
        lastName,
        cpf,
        email,
        password,
        birthdate,
        referralCode,
        recaptchaToken,
      );
      dispatch(registerUser(userResponse));
      // Since we succeeded to register the user lets log him in
      // TODO: Is this the best/correct way to do this?
      dispatch(login(email, password, rememberMe));
    } catch (err) {
      logAPIException(err);
      dispatch(registerUser(err));
    }
  };
}

// Login
export const loginUserRequest = createAction('LOGIN_USER_REQUEST');
export const loginUser = createAction('LOGIN_USER');

export function login(email: string, password: string, rememberMe: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch(loginUserRequest());
    try {
      const userResponse = await User.login(email, password, rememberMe);
      dispatch(loginUser(userResponse));
    } catch (err) {
      logAPIException(err);
      dispatch(loginUser(err));
    }
  };
}

// Logout user
export const logoutUserRequest = createAction('LOGOUT_USER_REQUEST');
export const logoutUser = createAction('LOGOUT_USER');

export function logout() {
  return async (dispatch: Dispatch) => {
    dispatch(logoutUserRequest());
    try {
      await User.logout();
      dispatch(logoutUser());
      window.location.reload(false);
    } catch (err) {
      // If there's an error logging out, still remove tokens
      dispatch(logoutUser());
    }
  };
}

// Validate token
export const validateUserRequest = createAction('VALIDATE_USER_REQUEST');
export const validateUser = createAction('VALIDATE_USER');

export function validate() {
  return async (dispatch: Dispatch) => {
    // No need to attempt to verify if a token is valid if there is no token
    if (!(await isAuthenticated())) {
      return;
    }
    dispatch(validateUserRequest());
    try {
      const userResponse = await User.validateToken();
      dispatch(validateUser(userResponse));
    } catch (err) {
      dispatch(validateUser(err));
    }
  };
}

// Get invited users
export const fetchInvitedUsersRequest = createAction(
  'GET_INVITED_USERS_REQUEST',
);
export const fetchInvitedUsers = createAction('GET_INVITED_USERS');

export function getInvitedUsers(userId) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchInvitedUsersRequest());
    try {
      const userResponse = await User.getInvitedUsers(userId);
      dispatch(fetchInvitedUsers(userResponse));
    } catch (err) {
      dispatch(fetchInvitedUsers(err));
    }
  };
}

// Get cashbacks
export const fetchCashbackOrdersRequest = createAction(
  'GET_CASHBACK_ORDERS_REQUEST',
);
export const fetchCashbackOrders = createAction('GET_CASHBACKS_ORDERS');

export function getCashbackOrders(userId) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchCashbackOrdersRequest());
    try {
      const userResponse = await User.getCashbackOrders(userId);
      dispatch(fetchCashbackOrders(userResponse));
    } catch (err) {
      dispatch(fetchCashbackOrders(err));
    }
  };
}

// Update Information
export const updateUserInformationRequest = createAction(
  'UPDATE_USER_INFORMATION_REQUEST',
);
export const updateUserInformation = createAction('UPDATE_USER_INFORMATION');

export function updateInformation(
  firstName: string,
  lastName: string,
  cpf: string,
  email: string,
) {
  return async (dispatch: Dispatch, getStore) => {
    dispatch(updateUserInformationRequest());
    try {
      const response = await User.updateInformation(
        firstName,
        lastName,
        cpf,
        email,
      );
      dispatch(updateUserInformation(response));
    } catch (err) {
      logAPIException(err);
      dispatch(updateUserInformation(err));
    }
  };
}

// Update Password
export const updateUserPasswordRequest = createAction(
  'UPDATE_USER_PASSWORD_REQUEST',
);
export const updateUserPassword = createAction('UPDATE_USER_PASSWORD');

export function updatePassword(
  oldPassword: string,
  newPassword: string,
  newPasswordConfirmation: string,
) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(updateUserPasswordRequest());
    try {
      const userId = userIdSelector(getState());
      const response = await User.updatePassword(
        userId,
        oldPassword,
        newPassword,
        newPasswordConfirmation,
      );
      dispatch(updateUserPassword(response));
      // TODO: Should remember the customer preference for being remembered or not.
      updateToken(response.token, { rememberMe: false });
      window.location.reload();
    } catch (err) {
      logAPIException(err);
      dispatch(updateUserPassword(err));
    }
  };
}

// Get addresses
export const getUserAddressesRequest = createAction(
  'GET_USER_ADRESSES_REQUEST',
);
export const getUserAddresses = createAction('GET_USER_ADRESSES');

export function getAddresses() {
  return async (dispatch: Dispatch) => {
    dispatch(getUserAddressesRequest());
    try {
      const addresses = await User.getAddresses();
      dispatch(getUserAddresses(addresses));
    } catch (err) {
      logAPIException(err);
      dispatch(getUserAddresses(err));
    }
  };
}

// New address
export const newUserAddressRequest = createAction('NEW_USER_ADDRESS_REQUEST');
export const newUserAddress = createAction('NEW_USER_ADDRESS');

export function newAddress(address: Address) {
  return async (dispatch: Dispatch) => {
    dispatch(newUserAddressRequest());
    try {
      const response = await User.newAddress(address);
      dispatch(newUserAddress(response));
    } catch (err) {
      dispatch(newUserAddress(err));
    }
  };
}

// Update address
export const updateUserAddressRequest = createAction(
  'UPDATE_USER_ADDRESS_REQUEST',
);
export const updateUserAddress = createAction('UPDATE_USER_ADDRESS');

export function updateAddress(address: Address) {
  return async (dispatch: Dispatch) => {
    dispatch(updateUserAddressRequest());
    try {
      const response = await User.updateAddress(address);
      dispatch(updateUserAddress(response));
    } catch (err) {
      dispatch(updateUserAddress(err));
    }
  };
}

// Delete address
export const deleteUserAddressRequest = createAction(
  'DELETE_USER_ADDRESS_REQUEST',
);
export const deleteUserAddress = createAction('DELETE_USER_ADDRESS');

export function deleteAddress(addressId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(deleteUserAddressRequest(addressId));
    try {
      await User.deleteAddress(addressId);
      dispatch(deleteUserAddress(addressId));
    } catch (err) {
      dispatch(deleteUserAddress(err));
    }
  };
}

// Get credit Cards
export const getUserCreditCardsRequest = createAction(
  'GET_USER_CREDIT_CARDS_REQUEST',
);
export const getUserCreditCards = createAction('GET_USER_CREDIT_CARDS');

export function getCreditCards() {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(getUserCreditCardsRequest());
    try {
      const userId = userIdSelector(getState());
      const addresses = await User.getCreditCards(userId);
      dispatch(getUserCreditCards(addresses));
    } catch (err) {
      logAPIException(err);
      dispatch(getUserCreditCards(err));
    }
  };
}

// Delete CredidCard
export const deleteUserCreditCardRequest = createAction(
  'DELETE_USER_CREDIT_CARD_REQUEST',
);
export const deleteUserCreditCard = createAction('DELETE_CREDIT_CARD_ADDRESS');

export function deleteCreditCard(creditCardId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(deleteUserCreditCardRequest(creditCardId));
    try {
      await User.deleteCreditCard(creditCardId);
      dispatch(deleteUserCreditCard(creditCardId));
    } catch (err) {
      dispatch(deleteUserCreditCard(err));
    }
  };
}

// PUT to reset the user password
export const putUserResetPasswordRequest = createAction(
  'PUT_USER_RESET_PASSWORD_REQUEST',
);
export const putUserResetPassword = createAction('PUT_USER_RESET_PASSWORD');

export function putResetPassword(
  password: string,
  passwordConfirmation: string,
  token: string,
  email: string,
) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(putUserResetPasswordRequest());
    try {
      const response = await User.putResetPassword(
        password,
        passwordConfirmation,
        token,
        email,
      );
      dispatch(putUserResetPassword(response));
    } catch (err) {
      logAPIException(err);
      dispatch(putUserResetPassword(err));
    }
  };
}

// POST to request a password reset email
export const postUserForgotPasswordRequest = createAction(
  'POST_USER_FORGOT_PASSWORD_REQUEST',
);
export const postUserForgotPassword = createAction('POST_USER_FORGOT_PASSWORD');

export function postForgotPassword(email: string) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(postUserForgotPasswordRequest());
    try {
      const response = await User.postForgotPassword(email);
      dispatch(postUserForgotPassword(response));
    } catch (err) {
      logAPIException(err);
      dispatch(postUserForgotPassword(err));
    }
  };
}

// GET to fetch user product lists
export const getUserProductListsRequest = createAction(
  'GET_USER_PRODUCT_LISTS_REQUEST',
);

export const getUserProductLists = createAction('GET_USER_PRODUCT_LISTS');

export function getProductLists() {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(getUserProductListsRequest());
    try {
      const response = await User.getProductLists();
      dispatch(getUserProductLists(response));
    } catch (err) {
      logAPIException(err);
      dispatch(getUserProductLists(err));
    }
  };
}

// POST  create user product lists
export const postUserProductListRequest = createAction(
  'POST_USER_PRODUCT_LIST_REQUEST',
);

export const postUserProductList = createAction('POST_USER_PRODUCT_LIST');

export function postProductList(name: string, items: ProductList['items']) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(postUserProductListRequest());
    try {
      const mappedItems = items.map(item => ({
        variant_id: item.variantId,
      }));
      const response = await User.postProductList(name, mappedItems);
      dispatch(postUserProductList(response));
    } catch (err) {
      logAPIException(err);
      dispatch(postUserProductList(err));
    }
  };
}

// Delete user product list
export const deleteUserProductListRequest = createAction(
  'DELETE_USER_PRODUCT_LIST_REQUEST',
);

export const deleteUserProductList = createAction('DELETE_USER_PRODUCT_LIST');

export function deleteProductListById(listId: number) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(deleteUserProductListRequest());
    try {
      const response = await User.deleteProductList(listId);
      dispatch(deleteUserProductList(response));
    } catch (err) {
      logAPIException(err);
      dispatch(deleteUserProductList(err));
    }
  };
}

// Get product list items
export const getUserProductListItemsRequest = createAction(
  'GET_USER_PRODUCT_LIST_ITEMS_REQUEST',
);

export const getUserProductListItems = createAction(
  'GET_USER_PRODUCT_LIST_ITEMS',
);

export function getProductListItemsByListId(
  listId?: number,
  page: number = 1,
  perPage: number = 9999,
) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(getUserProductListItemsRequest());
    try {
      const response = await User.getProductListItems(listId, page, perPage);
      dispatch(getUserProductListItems(response));
    } catch (err) {
      logAPIException(err);
      dispatch(getUserProductListItems(err));
    }
  };
}

// Post product list items
export const postUserProductListItemsRequest = createAction(
  'POST_USER_PRODUCT_LIST_ITEMS_REQUEST',
);

export const postUserProductListItems = createAction(
  'POST_USER_PRODUCT_LIST_ITEMS',
);

export function postUserProductListItem(variantId: number, listId: number) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(postUserProductListItemsRequest());
    try {
      const response = await User.postProductListItem(variantId, listId);
      dispatch(postUserProductListItems(response));
    } catch (err) {
      logAPIException(err);
      dispatch(postUserProductListItems(err));
    }
  };
}

// Delete product list item
export const deleteUserProductListItemRequest = createAction(
  'DELETE_USER_PRODUCT_LIST_ITEM_REQUEST',
);

export const deleteUserProductListItem = createAction(
  'DELETE_USER_PRODUCT_LIST_ITEM',
);

export function deleteProductListItemsByListItemId(listItemId: number) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(deleteUserProductListItemRequest());
    try {
      const response = await User.deleteProductListItem(listItemId);
      dispatch(deleteUserProductListItem(response));
    } catch (err) {
      logAPIException(err);
      dispatch(deleteUserProductListItem(err));
    }
  };
}

//update product list selected items
export const updateSelectedProductListItemsState = createAction(
  'UPDATE_SELECTED_PRODUCT_LIST_ITEMS_STATE',
);

export function updateSelectedProductListItems(variantId: number) {
  return (dispatch: Dispatch) => {
    dispatch(updateSelectedProductListItemsState(variantId));
  };
}

// clear product list selected items
export const clearSelectedProductListItemsState = createAction(
  'CLEAR_SELECTED_PRODUCT_LIST_ITEMS_STATE',
);

export function clearSelectedProductListItems() {
  return (dispatch: Dispatch) => {
    dispatch(clearSelectedProductListItemsState());
  };
}

// Get wishlist
export const getUserWishlistRequest = createAction('GET_USER_WISHLIST_REQUEST');

export const getUserWishlist = createAction('GET_USER_WISHLIST');

export function getWishlist() {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(getUserWishlistRequest());
    try {
      const response = await User.getWishlist();
      dispatch(getUserWishlist(response));
    } catch (err) {
      logAPIException(err);
      dispatch(getUserWishlist(err));
    }
  };
}

// Get wishlist products
export const getUserWishlistProductsRequest = createAction(
  'GET_USER_WISHLIST_PRODUCTS_REQUEST',
);

export const getUserWishlistProducts = createAction(
  'GET_USER_WISHLIST_PRODUCTS',
);

export function getWishlistProducts(page: number = 1, perPage: number = 30) {
  return async (dispatch: Dispatch, getState: () => state) => {
    dispatch(getUserWishlistProductsRequest());
    try {
      const response = await User.getWishlistProductItems(page, perPage);
      dispatch(getUserWishlistProducts(response));
    } catch (err) {
      logAPIException(err);
      dispatch(getUserWishlistProducts(err));
    }
  };
}

// Post wishlist
export const postUserWishlistRequest = createAction(
  'POST_USER_WISHLIST_REQUEST',
);

export const postUserWishlist = createAction('POST_USER_WISHLIST');

export function postUserWishListItem(variantId: number, quantity: number) {
  return async (dispatch: Dispatch, getState: () => state) => {
    dispatch(postUserWishlistRequest());
    try {
      const response = await User.postWishlistItem(variantId, quantity);
      dispatch(postUserWishlist(response));
    } catch (err) {
      logAPIException(err);
      dispatch(postUserWishlist(err));
    }
  };
}

// Delete wishlist list item
export const deleteUserWishlistItemRequest = createAction(
  'DELETE_USER_WISHLIST_ITEM_REQUEST',
);

export const deleteUserWishlistItem = createAction('DELETE_USER_WISHLIST_ITEM');

export function deleteWishListItemByItemId(itemId: number) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(deleteUserWishlistItemRequest());
    try {
      const response = await User.deleteProductListItem(itemId);
      dispatch(deleteUserWishlistItem(response));
    } catch (err) {
      logAPIException(err);
      dispatch(deleteUserWishlistItem(err));
    }
  };
}
// PUT add product list to cart

export const addUserProductListToCartRequest = createAction(
  'ADD_USER_PRODUCT_LIST_TO_CART_REQUEST',
);

export const addUserProductListToCart = createAction(
  'ADD_USER_PRODUCT_LIST_TO_CART',
);

export function addUserProductListToCartByOrderNumberAndListId(
  orderNumber: number,
  listId: number,
) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(addUserProductListToCartRequest());
    try {
      const response = await User.putAddProductListToCart(orderNumber, listId);
      dispatch(addUserProductListToCart(response));
    } catch (err) {
      logAPIException(err);
      dispatch(addUserProductListToCart(err));
    }
  };
}

// SEARCH Related
export const updateSearchState = createAction('UPDATE_SEARCH_STATE');

export function createURL(state: ReduxState) {
  return `?${qs.stringify(state)}`;
}
function searchStateToUrl(pathname, searchState) {
  if (searchState) {
    return `${pathname}${createURL(searchState)}`;
  } else {
    return `${pathname}`;
  }
}

export function updateSearch(
  searchState: ReduxState,
  route: '/lista-de-desejos' | '/lista-de-produto' = '/lista-de-desejos',
) {
  return (dispatch: Dispatch) => {
    history.push(searchStateToUrl(route, searchState), searchState);
    dispatch(updateSearchState(searchState));
  };
}

// When dispatched this action causes the reducer to return to its initial state
export const clearSearchParams = createAction('CLEAR_SEARCH_PARAMS');

export function clearSearch() {
  return (dispatch: Dispatch) => {
    dispatch(clearSearchParams());
  };
}
