import React from 'react';
// Styles
import './ControlledModal.css';

type Props = {
  title: string,
  imageUrl?: string,
  text?: string,
  handleAction?: () => void,
  handleClose?: () => void,
  isOpen?: boolean,
  buttonText?: string,
  imageAlt?: string,
  buttonNavigateToUrl?: string,
};

const ControlledModal = ({
  title,
  imageUrl,
  text,
  isOpen,
  buttonNavigateToUrl,
  buttonText,
  handleClose,
  handleAction,
  imageAlt,
}: Props) => {
  if (!isOpen) return null;
  return (
    <section className="controlled-modal-backdrop">
      <div className="controlled-modal">
        <div className={'controlled-modal-card controlled-modal-zoomIn'}>
          <div className="controlled-modal-section-end">
            <button
              className="controlled-modal-btn-close"
              onClick={handleClose}
            >
              <i className="fa fa-times"></i>
            </button>
          </div>
          <div className="controlled-modal-content">
            <div className="controlled-modal-header">
              <h3 className="controlled-modal-title">{title}</h3>
            </div>
            <img
              className="controlled-modal-card-image"
              src={imageUrl}
              alt={imageAlt}
            />
            <p className="controlled-modal-text">{text}</p>
            <a
              className="controlled-modal-btn-wrapper"
              href={buttonNavigateToUrl}
            >
              <button className="controlled-modal-btn" onClick={handleAction}>
                {buttonText}
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ControlledModal;
