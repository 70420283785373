// @flow
import React from 'react';
// Styles
import './TermsOfUsagePage.css';
// Components
import { PageTitleWithGoBack } from '../../common';
import { Helmet } from 'react-helmet';

const TermsOfUsagePage = () => {
  return (
    <div className="terms-main">
      <Helmet>
        <title>Termos de Uso - Bigdelivery, Seu açougue virtual</title>
      </Helmet>

      <PageTitleWithGoBack title="Termos de Uso e Privacidade" />
      <div className="terms-main-text">
        <p>
          Por favor, leia com atenção os termos e condições. Ao se cadastrar no
          www.bigdelivery.com.br você ESTÁ DE ACORDO COM AS CONDIÇÕES E TERMOS
          do Website. Note que a recusa destes Termos do Website impedirá que
          você faça pedidos de produtos do nosso Website.
        </p>

        <p>
          <b>1. SERVIÇOS OFERECIDOS</b>
        </p>

        <p>
          <b>1.1.</b> Este TERMO se aplica para regular o uso do serviço
          oferecido pela BIG DELIVERY ECOMMERCE DE ALIMENTOS LTDA. aos USUÁRIOS,
          qual seja, possibilitar a escolha, pelos USUÁRIOS, de CARNES, cujo
          fornecedor é única e exclusivamente a BIG DELIVERY ECOMMERCE DE
          ALIMENTOS LTDA. e, via on-line, efetivar solicitações para aquisição
          (e entrega em domicílio e retirada no local) de gêneros alimentícios
          fornecidos pelo BIG DELIVERY ECOMMERCE DE ALIMENTOS LTDA., de acordo
          com as opções de cortes de carne disponibilizados, sendo possível,
          igualmente, aos USUÁRIOS, a efetivação do pagamento do preço dos
          produtos via on-line.
        </p>

        <p>
          <b>1.2.</b> O serviço de compras online com disponibilização de
          delivery consiste, portanto, em aproximar, através do nosso site, os
          USUÁRIOS e a BIG DELIVERY ECOMMERCE DE ALIMENTOS LTDA., possibilitando
          que os USUÁRIOS encaminhem, ao BIG DELIVERY ECOMMERCE DE ALIMENTOS
          LTDA., pedidos de entrega de gêneros alimentícios, bem como, sendo
          essa a opção dos USUÁRIOS, permitindo o recebimento on-line de
          pagamento do preço dos produtos entregues ao USUÁRIOS pela BIG
          DELIVERY ECOMMERCE DE ALIMENTOS LTDA., ou de mediante cartão de
          crédito e/ou débito.
        </p>

        <p>
          <b>1.3.</b> Desde logo fica esclarecido ao USUÁRIO - o qual se declara
          ciente - que o serviço oferecido pela BIG DELIVERY ECOMMERCE DE
          ALIMENTOS LTDA. se relaciona apenas à 'intermediação (com opção de
          pagamento on-line) para comercialização de produtos alimentícios, não
          abarcando preparo, embalagem, disponibilização e entrega física (via
          motoboy ou outros meios) dos produtos, sendo esses quatro itens de
          responsabilidade integral do BIG DELIVERY ECOMMERCE DE ALIMENTOS LTDA,
          a quem deverão ser direcionados quaisquer reclamos acerca de problemas
          decorrentes de vício, defeito ou inexecução da feitura, preparo e
          entrega de produtos alimentícios.
        </p>

        <p>
          <b>1.4.</b> As compras realizadas pelos USUÁRIOS se darão por
          estimativa, admitindo-se como aceitas eventuais diferenças não
          superiores ou inferiores a 10% (dez por cento).
        </p>

        <p>
          <b>1.5.</b> Na hipótese de pagamento online, o USUÁRIO, de imediato,
          autoriza a BIG DELIVERY ECOMMERCE DE ALIMENTOS LTDA. à efetuar as
          devidas operações junto a OPERADORA DE CARTÃO DE CRÉDITO, para fins de
          adequação do preço final, em face de eventuais diferenças do peso
          obtido com o peso estimado. 1.6. Na hipótese de pagamento presencial,
          mediante uso de cartões de crédito ou débito, o USUÁRIO, em
          conformidade com o previsto no item 1.4., não se oporá a aceitação do
          produto, caso observado o limite de 10% (dez por cento).
        </p>

        <p>
          <b>2. CADASTRO</b>
        </p>

        <p>
          <b>2.1</b> O USUÁRIO, para utilizar os serviços acima descritos,
          deverá ter capacidade jurídica para atos civis e deverá,
          necessariamente, prestar as informações exigidas no CADASTRO,
          assumindo integralmente a responsabilidade (inclusive cível e
          criminal) pela exatidão e veracidade das informações fornecidas no
          CADASTRO, que poderá ser verificado, a qualquer momento, pela BIG
          DELIVERY ECOMMERCE DE ALIMENTOS LTDA.
        </p>

        <p>
          <b>2.1.1.</b> Em caso de informações incorretas, inverídicas ou não
          confirmadas, bem assim na hipótese da negativa em corrigi-las ou
          enviar documentação que comprove a correção, a BIG DELIVERY ECOMMERCE
          DE ALIMENTOS LTDA se reserva o direito de não concluir o cadastramento
          em curso ou, ainda, de bloquear o cadastro já existente, impedindo o
          USUÁRIO de utilizar os serviços on-line até que, a critério da BIG
          DELIVERY ECOMMERCE DE ALIMENTOS LTDA, a situação de anomalia esteja
          regularizada. A COSTA WENSE ALIMENTOS LTDA se reserva ao direito de
          impedir, a seu critério, novos CADASTROS, ou cancelar os já efetuados,
          em caso de ser detectada anomalia que, em sua análise, seja revestida
          de gravidade ou demonstre tentativa deliberada de burlar as regras
          aqui descritas, obrigatórias para todos os USUÁRIOS. Também agirá a
          BIG DELIVERY ECOMMERCE DE ALIMENTOS LTDA de tal forma caso verifique
          descumprimento, pelo USUÁRIO, de qualquer obrigação prevista no
          presente TERMO.
        </p>

        <p>
          <b>2.2.</b> Efetuado, com sucesso, o CADASTRO, o USUÁRIO terá acesso
          aos serviços por meio de login e senha, dados esses que se compromete
          a não divulgar a terceiros, ficando sob sua exclusiva responsabilidade
          qualquer solicitação de serviço que seja feita com o uso de login e
          senha de sua titularidade.
        </p>

        <p>
          <b>3. OBRIGAÇÕES DO USUÁRIO</b>
        </p>

        <p>
          <b>3.1.</b> Efetuado com sucesso o CADASTRO do USUÁRIO, este se obriga
          a não divulgar a terceiros login e senha de acesso, nem permitir o uso
          de tais informações por terceiros, responsabilizando-se pelas
          consequências do uso de login e senha de sua titularidade.
        </p>

        <p>
          <b>3.2.</b> É obrigação do USUÁRIO fornecer informações cadastrais
          totalmente verídicas e exatas, responsabilizando-se exclusiva e
          integralmente (em todas as searas jurídicas) por todo o conteúdo por
          si informado no item CADASTRO, mantendo atualizado e confirmado o
          endereço para entrega dos produtos encomendados.
        </p>

        <p>
          <b>3.3.</b> O USUÁRIO se obriga, também, a pagar integralmente o preço
          dos produtos por si solicitados ou encomendados ao BIG DELIVERY
          ECOMMERCE DE ALIMENTOS LTDA e efetivamente a si entregues, seja pela
          modalidade on-line, seja por qualquer outra forma, diretamente ao
          portador dos produtos encomendados por meio deste site (dinheiro,
          cheque, tickets, etc.).
        </p>

        <p>
          <b>3.4.</b> O USUÁRIO que seja menor de 18 (dezoito) anos de idade
          está ciente de que não poderá encomendar e adquirir, em qualquer
          hipótese, produtos alcoólicos, responsabilizando-se pela correta
          informação de sua idade no item CADASTRO.
        </p>

        <p>
          <b>3.5.</b> O USUÁRIO concorda com o uso das informações de avaliações
          e feedbacks dos serviços pela BIG DELIVERY ECOMMERCE DE ALIMENTOS
          LTDA.
        </p>

        <p>
          <b>3.6.</b> O USUÁRIO concorda com o registro de seu histórico de
          navegação do aplicativo, quando em uso.
        </p>

        <p>
          <b>4. OBRIGAÇÕES DA BIG DELIVERY ECOMMERCE DE ALIMENTOS LTDA</b>
        </p>

        <p>
          <b>4.1.</b> Disponibilizar no site www.bigdelivery.com.br e
          aplicativos Android e iOS desenvolvidos pela Avantsoft Soluções de
          Software LTDA em espaço virtual que permita ao USUÁRIO devidamente
          cadastrado efetivar pedidos de compra de gêneros alimentícios
          anunciados e comercializados pelo BIG DELIVERY ECOMMERCE DE ALIMENTOS
          LTDA e, também, disponibilizar ao USUÁRIO meios de pagamento do preço
          dos produtos on-line.
        </p>

        <p>
          <b>4.2.</b> Proteger, por meio de armazenamento em servidores ou
          quaisquer outros meios magnéticos de alta segurança, a
          confidencialidade de todas as informações e cadastros relativos aos
          USUÁRIOS, assim como valores atinentes às operações financeiras
          advindas da operacionalização dos serviços previstos no presente
          TERMO. Contudo, não responderá pela reparação de prejuízos que possam
          ser derivados de apreensão e cooptação de dados por parte de terceiros
          que, rompendo os sistemas de segurança, consigam acessar essas
          informações.
        </p>

        <p>
          <b>4.3.</b> A BIG DELIVERY ECOMMERCE DE ALIMENTOS LTDA. é obrigada a
          coletar e proteger algumas informações pessoais e financeiras dos
          usuários, como nome, endereço de e-mail, ID do usuário (obrigatórias),
          com a possibilidade opcional de fornecer outras informações como
          número de telefone e endereço. Além disso, é obrigatório coletar o
          histórico de compras, enquanto as informações de pagamento são
          opcionais. Quanto à comunicação, a empresa deve obrigatoriamente
          coletar e-mails e tem a opção de coletar SMS, MMS e mensagens de
          outros aplicativos in-app. Em relação às atividades do aplicativo, é
          necessário coletar interações e histórico in-app, assim como
          dispositivos ou outros IDs. Todas essas informações servirão para
          análise, prevenção de fraudes, segurança, conformidade, estratégias de
          marketing, personalização, gerenciamento de contas e aprimoramento do
          app.
        </p>

        <p>
          <b>5. MODIFICAÇÕES DESTE TERMO</b>
        </p>
        <p>
          <b>5.1.</b> O presente TERMO DE USO poderá, a qualquer tempo, ter seu
          conteúdo, ou parte dele, modificados para adequações e inserções, tudo
          com vistas ao aprimoramento dos serviços disponibilizados.
        </p>
        <p>
          <b>5.2.</b> As novas condições entrarão em vigência assim que sua
          veiculada no site, sendo possível ao USUÁRIO manifestar oposição a
          quaisquer dos termos modificados, desde que o faça por escrito,
          através do email sac@bigdelivery.com.br, o que gerará o cancelamento
          de seu CADASTRO.
        </p>

        <p>
          <b>6. CANAL DE COMUNICAÇÃO</b>
        </p>

        <p>
          <b>6.1.</b> Para estabelecer contato entre a BIG DELIVERY ECOMMERCE DE
          ALIMENTOS LTDA e o USUÁRIO fica disponibilizado o endereço eletrônico
          deste link, sendo certo que o USUÁRIO se obriga, igualmente, a manter
          em seu cadastro endereço eletrônico atual, por intermédio do qual se
          farão as comunicações a ele dirigidas pela BIG DELIVERY ECOMMERCE DE
          ALIMENTOS LTDA desde logo emprestando-se validade jurídica e
          efetividade a esse meio eletrônico de troca de informações recíprocas.
        </p>

        <p>
          <b>7. ACEITAÇÃO DO TERMO DE USO</b>
        </p>

        <p>
          <b>7.1</b> O USUÁRIO declara ter lido, entendido e que aceita todas as
          regras, condições e obrigações estabelecidas no presente TERMO.
        </p>

        <p>
          <b>8. DO HORÁRIO DE ENTREGA E DA ÁREA DE EXECUÇÃO</b>
        </p>

        <p>
          <b>8.1.</b> A área de atuação da BIG DELIVERY ECOMMERCE DE ALIMENTOS
          LTDA., limita-se aos municípios de Salvador e Lauro de Freitas, ambos
          no Estado da Bahia, e, temporariamente, restrita aos bairros:
          Especificados no site.
        </p>

        <p>
          <b>8.2.</b> O horário para recebimento de pedidos poderá ocorrer a
          qualquer tempo, contudo o envio/entrega, na hipótese de delivery,
          observará os horários e escalas de entrega previamente assinalados no
          site ou no aplicativo.
        </p>

        <p>
          <b>8.3.</b> Os usuários poderão retirar, direta e pessoalmente, os
          produtos adquiridos na sede da BIG DELIVERY ECOMMERCE DE ALIMENTOS
          LTDA., situada na Rua Santo Antônio, n. 50, Bairro: Portão, Lauro de
          Freitas – Bahia, sendo que ficará limitando ao horário de
          funcionamento comercial da loja, a saber: segunda-feira à sábado das
          07:00 às 19:00; domingos e feriados das 07:00 às 12:00.
        </p>

        <p>
          <b>9. FORO DE ELEIÇÃO</b>
        </p>

        <p>
          <b>9.1</b> As partes elegem como competente para dirimir eventuais
          controvérsias que venham a surgir da interpretação e do cumprimento do
          presente TERMO o foro da Comarca de Salvador/BA.
        </p>

        <p>
          <b>10.1.</b> A cópia integral deste termo de uso e condições o está
          disponível no seguinte site: www.bigdelivery.com.br/termos © Copyright
          2017 - Avansoft - Todos os direitos reservados Avansoft Soluções de
          Tecnologia Ltda.
        </p>
      </div>
    </div>
  );
};

export default TermsOfUsagePage;
