// @flow
import React, { useEffect, useState } from 'react';
//Components
import { Link } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import { ProductPrice, ProgressiveImageLoader } from '../../common';
import { AddToCartButton } from '../../current-order';
//Types
import type { ProductImage } from '../../types/ProductImage';
//Styles
import './ProductSquare.css';
//Analytics
import * as Analytics from '../../common/analytics';
//Helpers
import { scaleConverter } from '../../common';

type Props = {
  productImage: ?ProductImage,
  productName: string,
  productManufacturer: string,
  productListPrice: number,
  productPrice: number,
  productUnit: string,
  productSlug: string,
  productId: number,
  defaultQuantity: number,
  incrementQuantity: number,
  inStock: boolean,
  discontinued: boolean,
};

const ProductSquare = ({
  productSlug,
  productImage,
  productName,
  productPrice,
  productListPrice,
  productManufacturer,
  productUnit,
  productId,
  parentProductId,
  productTotalOnHand,
  defaultQuantity,
  inStock,
  discontinued,
  incrementQuantity,
  analyticsViewSource,
}: Props) => {
  const [visibility, setVisibility] = useState(false);

  const price = scaleConverter(productPrice);
  const listPrice = scaleConverter(productListPrice);

  const productOnPromotion = listPrice > price;
  const productDiscount = productOnPromotion
    ? ((listPrice - price) / listPrice) * 100
    : null;

  useEffect(() => {
    if (visibility) {
      Analytics.logProductImpression(
        {
          variantId: productId,
          productId: parentProductId,
          name: productName,
          price: productPrice,
          manufacturer: productManufacturer,
          unitForQuantity: productUnit,
          totalOnHand: productTotalOnHand,
          listPrice: productListPrice,
          inStock: inStock,
          discontinued: discontinued,
          images: productImage ? [productImage] : [],
          incrementQuantity: incrementQuantity,
          defaultQuantity: defaultQuantity,
        },
        window.location.pathname,
        analyticsViewSource,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibility]);

  return (
    <VisibilitySensor
      onChange={isVisible => {
        setVisibility(isVisible);
      }}
    >
      <div className="product-sqaure-main">
        <section className="product-square-image-section">
          <Link
            className="product-square-link"
            to={`/produtos/${productSlug}/`}
          >
            {productOnPromotion && productDiscount >= 5 && (
              <div className="product-square-image-section-discount">
                <p>{Math.round(productDiscount)}%</p>
                <span>OFF</span>
              </div>
            )}
            <ProgressiveImageLoader
              selectedImage={productImage}
              imageToShow={'product'}
            />
          </Link>
        </section>

        <section className="product-square-info-section">
          <p className="product-square-variant">{productManufacturer}</p>
          <Link
            className="product-square-link"
            to={`/produtos/${productSlug}/`}
          >
            <h3 title={productName} className="product-square-title">
              {productName}
            </h3>
          </Link>
        </section>

        <section className="product-square-shopping-section">
          <ProductPrice
            productListPrice={productListPrice}
            productPrice={productPrice}
            productUnit={productUnit}
          />

          <div className="product-square-button">
            <AddToCartButton
              productId={productId}
              unitForQuantity={productUnit}
              defaultQuantity={defaultQuantity}
              incrementQuantity={incrementQuantity}
              inStock={inStock}
              discontinued={discontinued}
              // USE ANALYTICS
              analyticsEventType={'impression'}
              analyticsViewSource={analyticsViewSource}
            />
          </div>
        </section>
      </div>
    </VisibilitySensor>
  );
};

export default ProductSquare;
