// @flow
import { createSelector } from 'reselect';
import _ from 'lodash';
import type { StoreState } from '../types';

export const localStateSelector = (state: any): StoreState => {
  return state.blends;
};

////////////////////////// BLEND TEMPLATE RELATED ///////////////////////////

// Returns the ID of the newly created blend template
export const newlyCreatedBlendTemplateIdSelector = createSelector(
  localStateSelector,
  (state: StoreState) => state.newlyCreatedBlendTemplateId,
);

// All blend templates
export const blendTemplatesSelector = createSelector(
  localStateSelector,
  (state: StoreState) =>
    _.without(
      state.currentBlendTemplateIdList.map(id => state.blendTemplates[id]),
      undefined,
    ),
);

// A specific blend template
export const getBlendTemplateByIdSelector = (
  state: StoreState,
  blendTemplateId: number | string,
) => {
  const localState = localStateSelector(state);
  return localState.blendTemplates[blendTemplateId] || null;
};

// Returns the template to which a blendTemplateItem belongs
// Takes a blend template item ID and returns the template to which the item belongs
export const getBlendTemplateByBlendTemplateItemIdSelector = (
  state: StoreState,
  blendTemplateItemId: number,
) => {
  const localState = localStateSelector(state);
  const blendTemplateAsArray = _.values(localState.blendTemplates);

  return _.find(blendTemplateAsArray, template =>
    _.includes(template.items, blendTemplateItemId),
  );
};

/////////////////////////// BLEND TEMPLATE ITEM RELATED //////////////////////////

// Returns the item with the given id
export const getBlendTemplateItemByIdSelector = (
  state: StoreState,
  id: string | number,
) => {
  const localState = localStateSelector(state);

  return localState.blendTemplateItems[id] || null;
};

// Return the items that belong to a given mixTemplate. NOTE: These are not the lineItems!
export const getBlendTemplateItemsByBlendTemplateIdSelector = (
  state: StoreState,
  templateId: string | number,
) => {
  const localState = localStateSelector(state);
  const blendTemplate = getBlendTemplateByIdSelector(state, templateId);

  if (!blendTemplate) return []; // In case blendTemplate is null the next part would throw an error

  return _.without(
    blendTemplate.items.map(id => localState.blendTemplateItems[id]),
    undefined,
  );
};

// Returns the item that belong to the template and variant specified. Only one!
export const getBlendTemplateItemByBlendTemplateIdAndVariantIdSelector = (
  state: StoreState,
  templateId: number | string,
  variantId: number | string, // Not the slug!
) => {
  const blendItems = getBlendTemplateItemsByBlendTemplateIdSelector(
    state,
    templateId,
  );
  return _.find(blendItems, item => item.variant.id === variantId);
};

export const getBlendItemsSelector = createSelector(
  localStateSelector,
  (state: StoreState) => state.blendItems,
);

/////////////////////////////// BLEND RELATED /////////////////////////////
// Returns all blends (MANY) with matching orderNumber
export const blendsByOrderNumberSelector = (
  state: StoreState,
  orderNumber: string,
) => {
  const localState = localStateSelector(state);

  // Array with all the blends
  const blends = _.without(
    localState.currentBlendList.map(id => localState.blends[id]),
    undefined,
  );

  return _.filter(blends, blend => blend.orderNumber === orderNumber);
};

// Returns a blend (ONE) matching the orderNumber and blend ID provided.
export const blendByOrderNumberAndBlendIdSelector = (
  state: StoreState,
  orderNumber: string,
  blendId: string,
) => {
  // Array with all the blends belonging to the give order
  const blends = blendsByOrderNumberSelector(state, orderNumber);

  return _.find(blends, { id: blendId });
};

// Returns a blend (ONE) matching the orderNumber and template ID provided.
export const blendByOrderNumberAndTemplateIdSelector = (
  state: StoreState,
  orderNumber: string,
  templateId: string,
) => {
  // Array with all the blends belonging to the give order
  const blends = blendsByOrderNumberSelector(state, orderNumber);

  return _.find(blends, { templateId });
};

//////////////////////////// LOADING STATE RELATED //////////////////////////
///////////// BLEND TEMPLATE RELATED ////////////
export const gettingBlendTemplatesLoadingSelector = createSelector(
  localStateSelector,
  state => state.loading.gettingBlendTemplates,
);

// A specific blend template
export const gettingBlendTemplateLoadingSelector = createSelector(
  localStateSelector,
  state => state.loading.gettingBlendTemplate,
);

// General loader for the blend template POST
export const creatingBlendTemplateLoadingSelector = createSelector(
  localStateSelector,
  state => state.loading.creatingBlendTemplate,
);

// Deleting blend template general loader
// TODO: This should be changed to a by ID loader
export const deletingBlendTemplateLoadingSelector = createSelector(
  localStateSelector,
  state => state.loading.deletingBlendTemplate,
);

/////////////// BLEND RELATED ///////////////
export const addingBlendsLoadingSelector = createSelector(
  localStateSelector,
  state => state.addingBlendsLoading,
);

// Note that this selector uses a product ID. Whilst the changing selector uses the itemId.
export const addingBlendLoadingSelector = (
  state: any,
  blendTemplateId: number,
) => {
  const index = addingBlendsLoadingSelector(state).indexOf(blendTemplateId);
  return index > -1;
};

// Internal use selector. Returns list of of blends that are loading due to PUT
const changingBlendsLoadingStateSelector = createSelector(
  localStateSelector,
  state => state.changingBlendsLoading,
);

// Note that this selector uses a product ID. Whilst the changing selector uses the itemId.
export const changingBlendLoadingSelector = (state: any, blendId: number) => {
  const index = changingBlendsLoadingStateSelector(state).indexOf(blendId);
  return index > -1;
};

/////////////// BLEND TEMPLATE ITEM RELATED ///////////////

export const changingBlendTemplateItemsLoadingSelector = createSelector(
  localStateSelector,
  state => state.changingBlendTemplateItemsLoading,
);

// We are allowing this selector to receive null so that it can be used in the mapStateToProps where the item may be undefined
export const changingBlendTemplateItemLoadingSelector = (
  state: any,
  itemId: ?number,
) => {
  const index = changingBlendTemplateItemsLoadingSelector(state).indexOf(
    itemId,
  );
  return index > -1;
};

// Internal use selector. Returns list of items that are being added.
const addingItemsLoadingStateSelector = createSelector(
  localStateSelector,
  state => state.addingBlendTemplateItemsLoading,
);

// Note that this selector uses a product ID. Whilst the changing selector uses the itemId.
export const addingItemLoadingSelector = (state: any, productId: number) => {
  const index = addingItemsLoadingStateSelector(state).indexOf(productId);
  return index > -1;
};
