// @flow
import React from 'react';
// Styles
import './InfoCards.css';

type InfoCard = {
  title: string,
  text: string,
  img: string,
};

type Props = {
  cards: InfoCard[],
};

const InfoCards = ({ cards }: Props) => {
  return (
    <div className="product-info-cards">
      {cards.map(({ title, img, text }) => (
        <div className="product-info-card" key={title}>
          <img src={img} alt={title} />
          <div className="product-info-card-content">
            <h6 className="product-info-card-content-title">
              {title}
              <span>{text}</span>
            </h6>
          </div>
        </div>
      ))}
    </div>
  );
};

export default InfoCards;
