import { handleActions, combineActions } from 'redux-actions';
import { combineReducers } from 'redux';
// Actions
import {
  fetchRecipesByProduct,
  fetchRecipesByProductRequest,
} from '../actions';

import type { Reducer } from 'redux';
import type { Action } from '../../types';
import type { State } from '../types';

const recipes: Reducer<{ [string]: any }, Action> = handleActions(
  {
    [combineActions(fetchRecipesByProduct)]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.recipes,
      }),
      throw: (state, action) => state,
    },
  },
  [],
);

//Loading
const initialLoadingState = {
  recipes: false,
};

const loading: Reducer<string, Action> = handleActions(
  {
    [fetchRecipesByProduct]: (state, action) => ({ ...state, recipes: false }),
    [fetchRecipesByProductRequest]: (state, action) => ({
      ...state,
      recipes: true,
    }),
  },
  initialLoadingState,
);

const reducer: Reducer<State, Action> = combineReducers({
  recipes,
  loading,
});

export default reducer;
