// @flow
import React, { useEffect, useState } from 'react';
import { camelizeKeys } from 'humps';
// Components
import VisibilitySensor from 'react-visibility-sensor';
import { Link } from 'react-router-dom';
import { ProductPrice, ProgressiveImageLoader } from '../../common';
import { AddToCartButton } from '../../current-order';
// Styles
import './SearchResultSquare.css';
// Types
import type { Hit } from '../types';
// Analytics
import * as Analytics from '../../common/analytics';

type Props = {
  hit: Hit,
};

const SearchResultSquare = ({ hit }: Props) => {
  const camelizedHit: Hit = camelizeKeys(hit);
  const {
    id,
    slug,
    name,
    price,
    listPrice,
    manufacturer,
    unitForQuantity,
    images,
    defaultQuantity,
    incrementQuantity,
    inStock,
  } = camelizedHit;
  const [productVisibility, setProductVisibility] = useState(false);

  useEffect(() => {
    if (productVisibility) {
      Analytics.logProductImpression(
        camelizedHit,
        window.location.pathname,
        'search result square',
      );
    }
    // eslint-disable-next-line
  }, [productVisibility]);

  return (
    <VisibilitySensor
      onChange={isVisible => {
        setProductVisibility(isVisible);
      }}
    >
      <div className="search-result-square-main">
        <section className="search-result-square-image-section">
          <Link className="search-result-square-link" to={`/produtos/${slug}/`}>
            <ProgressiveImageLoader
              selectedImage={images[0]}
              imageToShow={'product'}
            />
          </Link>
        </section>

        <section className="search-result-square-info-section">
          <p className="search-result-square-variant">{manufacturer}</p>
          <Link className="search-result-square-link" to={`/produtos/${slug}/`}>
            <h3 title={name} className="search-result-square-title">
              {name}
            </h3>
          </Link>
        </section>

        <section className="search-result-square-shopping-section">
          <ProductPrice
            productListPrice={listPrice}
            productPrice={price}
            productUnit={unitForQuantity}
          />

          <div className="search-result-square-button">
            <AddToCartButton
              productId={id}
              unitForQuantity={unitForQuantity}
              defaultQuantity={defaultQuantity}
              incrementQuantity={incrementQuantity}
              inStock={inStock}
              // USE ANALYTICS
              analyticsEventType={'impression'}
              analyticsViewSource={'search result square'}
            />
          </div>
        </section>
      </div>
    </VisibilitySensor>
  );
};

export default SearchResultSquare;
