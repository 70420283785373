// @flow
import React, { Component } from 'react';
// Components
import { InstantSearch } from 'react-instantsearch-dom';
import UnconnectedSearchBar from '../components/UnconnectedSearchBar';
// Composers
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// Selectors
import { searchStateSelector } from '../selectors';
// Actions
import { updateSearch, createURL } from '../actions';
// Styles
import './SearchBar.css';
// Types
import type { Connector } from 'react-redux';
import type { ReduxState } from '../types';
import type { Dispatch } from '../../types';

/**
 * TYPE DEFINITIONS
 */
// TODO: Make this ESLint unecessary
/*eslint-disable */
type ReduxProps = {
  searchState: ReduxState,
  updateSearch: (searchState: ReduxState) => void,
};
/*eslint-enable */
type OwnProps = {};
type Props = {
  //prettier-ignore
  /* :: ...ReduxProps, */
  //prettier-ignore
  /* :: ...OwnProps */
};
type State = {
  currentQuery: string,
};

/**
 * COMPONENT DEFINITION
 */
class SearchBar extends Component {
  props: Props;
  state: State;

  render() {
    const SearchBar = UnconnectedSearchBar;
    return (
      <section className="search-bar-main" role="search">
        <InstantSearch
          appId={process.env.REACT_APP_ALGOLIA_APP_ID}
          apiKey={process.env.REACT_APP_ALGOLIA_API_KEY}
          indexName={
            process.env.REACT_APP_ALGOLIA_PRODUCT_INDEX ||
            'product_sales_production'
          }
          searchState={this.props.searchState}
          onSearchStateChange={this.props.updateSearch.bind(this)}
          createURL={createURL}
        >
          <SearchBar />
        </InstantSearch>
      </section>
    );
  }
}

/**
 * CONNECTOR SETUP
 */
const mapStateToProps = (state, ownProps) => {
  return {
    searchState: searchStateSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    updateSearch: searchState => {
      dispatch(updateSearch(searchState));
    },
  };
};

const connector: Connector<OwnProps, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default withRouter(connector(SearchBar));
