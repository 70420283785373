// @flow
import React from 'react';
// Styles
import './SearchInput.css';
// Images
import searchIcon from '../images/searchIcon.svg';

type Props = {
  value: string,
  handleChange: (value: string) => void,
  placeholder: string,
  handleSearch: () => void,
  handleBlur: () => void,
  maxLength?: number,
};

const SearchInput = ({
  value,
  placeholder = 'O que você está procurando?',
  handleSearch,
  handleChange,
  handleBlur,
  maxLength = 100,
}: Props) => {
  return (
    <div className="search-wrapper">
      <input
        className="search-input"
        type="text"
        placeholder={placeholder}
        value={value}
        name="search"
        maxLength={maxLength}
        onBlur={handleBlur}
        onChange={event => handleChange(event.target.value)}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
          }
        }}
      />
      <button className="search-button" onClick={handleSearch}>
        <img alt="Ícone de busca" src={searchIcon} />
      </button>
    </div>
  );
};

export default SearchInput;
