// @flow
import React from 'react';
// Styles
import './MyAccountEvaluations.css';
// Components
import MyAccountEvaluationsItem from './MyAccountEvaluationsItem';

const MyAccountEvaluations = () => {
  return (
    <section className="my-account-evaluations">
      <h1 className="my-account-evaluations-title">Avaliar Produtos</h1>
      <MyAccountEvaluationsItem />
      <MyAccountEvaluationsItem />
      <MyAccountEvaluationsItem />
    </section>
  );
};

export default MyAccountEvaluations;
