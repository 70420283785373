// @flow
import './SearchPage.css';
import React, { Component } from 'react';
import qs from 'qs';
// Components
import { InstantSearch } from 'react-instantsearch-dom';
import UnconnectedSearchPage from '../components/SearchPage';
import { Helmet } from 'react-helmet';
// Composers
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// Actions
import {
  updateSearchState,
  updateSearch,
  createURL,
  clearSearch,
} from '../actions';
// Types
import type { Connector } from 'react-redux';
import type { Dispatch } from '../../types';

/*eslint-disable */
// TODO: Figure out how to impeded EsLint from throwing a warning here without disabling
type ReduxProps = {
  searchState: any,
  updateSearch: (string, any) => void,
  updateSearchState: any => void,
};
/*eslint-enable */
type RouterProps = {
  location: any,
  history: any,
};
type Props = {
  location: {},
  history: any,
  searchState: any,
  updateSearch: (string, any) => void,
  updateSearchState: any => void,
};

type State = {
  searchState: any,
};

class SearchPage extends Component {
  props: Props;
  state: State;

  constructor(props: Props) {
    super(props);
    const searchState: any = qs.parse(props.location.search.slice(1));

    props.updateSearchState(searchState);
  }

  onSearchStateChange(nextSearchState) {
    this.props.updateSearch(nextSearchState);
  }

  // If the user is leaving this page we want to clear any parameters from the previous search to avoid issues with faceting
  componentWillUnmount() {
    this.props.clearSearch();
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Pesquisa - Bigdelivery, Seu açougue virtual</title>
        </Helmet>

        <InstantSearch
          appId={process.env.REACT_APP_ALGOLIA_APP_ID}
          apiKey={process.env.REACT_APP_ALGOLIA_API_KEY}
          indexName={
            process.env.REACT_APP_ALGOLIA_PRODUCT_INDEX ||
            'product_sales_production'
          }
          searchState={this.props.searchState}
          onSearchStateChange={this.onSearchStateChange.bind(this)}
          createURL={createURL}
        >
          <UnconnectedSearchPage {...this.props.searchState} />
        </InstantSearch>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    searchState: state.search,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    updateSearch: searchState => {
      dispatch(updateSearch(searchState));
    },
    updateSearchState: searchState => {
      dispatch(updateSearchState(searchState));
    },
    clearSearch: () => {
      dispatch(clearSearch());
    },
  };
};

const connector: Connector<RouterProps, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default withRouter(connector(SearchPage));
