// @flow
import React from 'react';
import { Link } from 'react-router-dom';
// Styles
import './Button.css';

type Props = {
  color: string,
};

const Button = (props: Props) => {
  return (
    <Link to={'/bigchurrasco/meuchurrasco'}>
      <button className={`barbecue-button ${props.color}`}>CRIE O SEU!</button>
    </Link>
  );
};
export default Button;
