// @flow
import React, { useEffect } from 'react';
//Helpers
import { useScreenTimeLogger } from '../../common';
//Components
import { Link } from 'react-router-dom';
import BigClubBanner from '../components/BigClubBanner';
import CashbackCard from '../components/CashbackCard';
import ReferralCard from '../components/ReferralCard';
//Styles
import './BigClubPage.css';
//Selectors
import {
  bigClubSettingsSelector,
  featuresConfigSelector,
} from '../../preferences/selectors';
import {
  cashbackInformationSelector,
  cashbacksSelector,
  invitedUsersSelector,
  referralInformationSelector,
  userIdSelector,
} from '../selectors';
//Actions
import { getCashbackOrders, getInvitedUsers } from '../actions';
//Composers
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
// Analytics
import * as Analytics from '../../common/analytics';
//Types
import type { FeaturesConfig, BigClubSettings } from '../../preferences/types';

import type { Cashback, InvitedUser } from '../types';

type Props = {
  userId: number,
  referralInformation: any,
  cashbackInformation: any,
  invitedUsers: InvitedUser[],
  getCashbackOrders: (userId: number) => void,
  getInvitedUsers: (userId: number) => void,
  cashbackOrders: Cashback[],
  featuresConfig: FeaturesConfig,
  bigClubSettings: BigClubSettings,
};

const BigClubPage = (props: Props) => {
  useScreenTimeLogger(props.location.pathname)
  useEffect(() => {
    if (props.userId) {
      props.getInvitedUsers(props.userId);
      props.getCashbackOrders(props.userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userId]);

  useEffect(() => {
    // USE ANALYTICS
    Analytics.logUserOpenedBigClubPage();
  }, []);

  if (props.featuresConfig && !props.featuresConfig.bigclubFeature) {
    return <Redirect to="/" />;
  }

  const cashbackSettings = props.bigClubSettings?.cashback;

  return (
    <div className="big-club-container">
      <BigClubBanner />
      {!props.userId && (
        <div className="big-club-offline">
          <h1 className="big-club-offline-title">
            Comece a aproveitar
            <br /> os benefícios agora!
          </h1>
          <Link to="/login" className="big-club-offline-signin">
            Entrar na conta
          </Link>
        </div>
      )}
      <div
        className={`big-club-cards ${!props.userId ? 'big-club-cards-offline' : ''
          }`}
      >
        {props.featuresConfig?.cashbackFeature && (
          <CashbackCard
            isSigned={props.userId}
            cashbackOrders={props.cashbackOrders}
            cashbackInformation={props.cashbackInformation}
            cashbackSettings={cashbackSettings}
          />
        )}
        <ReferralCard
          isSigned={props.userId}
          referralInformation={props.referralInformation}
          invitedUsers={props.invitedUsers}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userId: userIdSelector(state),
    referralInformation: referralInformationSelector(state),
    cashbackInformation: cashbackInformationSelector(state),
    invitedUsers: invitedUsersSelector(state),
    cashbackOrders: cashbacksSelector(state),
    featuresConfig: featuresConfigSelector(state),
    bigClubSettings: bigClubSettingsSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, stateProps) => {
  return {
    getInvitedUsers: (userId: number) => {
      dispatch(getInvitedUsers(userId));
    },

    getCashbackOrders: (userId: number) => dispatch(getCashbackOrders(userId)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BigClubPage),
);
