// @flow
import React, { useState } from 'react';
// Components
import { ConfirmationModal } from '../../../common';
// Types
import type { CreditCard } from '../../types';
// Styles
import './CreditCardOption.css';
// Images
import cardImageDefault from '../../images/credit-card.png';
import cardImageAmericanExpress from '../../images/credit-card-american-express.png';
import cardImageDiscover from '../../images/credit-card-discover.png';
import cardImageMaster from '../../images/credit-card-master.png';
import cardImageVisa from '../../images/credit-card-visa.png';
import trashIcon from '../../../user/images/trash-icon.svg';
// Analytics
import * as Analytics from '../../../common/analytics';

export type Props = {
  creditCard: CreditCard,
  active: boolean,
  cardHasExpired: boolean,
  deleteCreditCard: () => void,
};

const cardImagesMap = {
  visa: cardImageVisa,
  master: cardImageMaster,
  american_express: cardImageAmericanExpress,
  discover: cardImageDiscover,
};

const CreditCardOption = (props: Props) => {
  const name = props.creditCard.name.toUpperCase();
  const cardImage = cardImagesMap[props.creditCard.ccType] || cardImageDefault;

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const toggleConfirmModal = e => {
    e.stopPropagation();
    setShowConfirmModal(!showConfirmModal);
  };

  const handleDelete = () => {
    const creditCardId = props.creditCard.id;
    Analytics.logDeleteCreditCard(creditCardId);
    props.deleteCreditCard(creditCardId);
  };

  return (
    <div className="credit-card-option-container">
      <div
        className={`credit-card-option-main ${
          props.cardHasExpired
            ? 'credit-card-expired'
            : props.active
            ? 'credit-card-selected'
            : ''
        }`}
      >
        <div className="credit-cart-option-information-container">
          <img className="credit-card-option-image" alt="" src={cardImage} />
          <div className="credit-card-option-information">
            <p>{name}</p>
            <p>**** {props.creditCard.lastDigits}</p>
          </div>
        </div>

        {!props.deletingLoading ? (
          <button
            className="credit-card-option-trash"
            onClick={e => {
              e.stopPropagation();
              toggleConfirmModal(e);
            }}
          >
            <img src={trashIcon} alt="Apagar endereço"></img>
            Apagar
          </button>
        ) : (
          <i
            className="credit-card-spinner fa fa-spinner fa-pulse fa-2x"
            aria-hidden="true"
          />
        )}
      </div>
      <ConfirmationModal
        isOpen={showConfirmModal}
        text="O cartão será deletado. Deseja continuar?"
        onClose={toggleConfirmModal}
        onConfirm={handleDelete}
      />
    </div>
  );
};

export default CreditCardOption;
