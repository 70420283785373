// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Selectors
import { blendsByOrderNumberSelector } from '../selectors';
// Actions
import { getBlends } from '../actions';
// Types
import type { Dispatch } from '../../types';

type OwnProps = {
  currentOrderNumber: ?string,
};

function blendsListConnector(WrappedComponent: ReactClass<any>) {
  const mapStateToProps = (state, ownProps) => {
    return {
      blendsByOrderNumber: (orderNumber: string) =>
        blendsByOrderNumberSelector(state, orderNumber),
    };
  };

  const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
    return {
      getBlends: (orderNumber: string) => {
        dispatch(getBlends(orderNumber));
      },
    };
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class blendsListConnector extends Component {
      props: OwnProps;
      render() {
        return (
          <WrappedComponent
            {...this.props}
            blendsByOrderNumber={this.props.blendsByOrderNumber}
            getBlends={this.props.getBlends}
          />
        );
      }
    },
  );
}

export default blendsListConnector;
