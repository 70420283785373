// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
// Styles
import './OrderTracker.css';
// Common
import { GoogleStaticMapsImage } from '../../common';
import { orderTrackerStates, orderTrackerStateName } from '../commonHelpers';
// Components
import OrderTrackerMensageState from './OrderTrackerMensageState';
import PeriodicGetter from './PeriodicGetter';
//Selectors
import {
  trackerByOrderNumber,
  trackingLoadingSelector,
} from '../../past-orders/selectors';
// Actions
import { getTracker } from '../../past-orders/actions';

type Props = {
  order: any,
  loading: boolean,
  trackingLoading: boolean,
  tracker: any,
  getOrderTracker: (orderNumber: string) => void,
};

const BarTracker = ({ step }) => (
  <ul className="order-progress">
    {Object.keys(orderTrackerStates).map((value, index) => (
      <li
        key={index}
        className={` ${
          index <= Object.keys(orderTrackerStates).indexOf(step) ? 'active' : ''
        } ${
          index === Object.keys(orderTrackerStates).indexOf(step)
            ? 'order-progress-last-one-valid-animation-line'
            : ''
        }${
          index === Object.keys(orderTrackerStates).indexOf(step) + 1
            ? 'order-progress-last-one-valid-animation-marker'
            : ''
        }`}
      >
        {value}
      </li>
    ))}
  </ul>
);

const OrderTracker = ({
  order,
  loading,
  trackingLoading,
  tracker,
  getOrderTracker,
}: Props) => {
  const [step, setStep] = useState(
    orderTrackerStateName(order.fulfillmentState, order.deliveryState),
  );
  useEffect(() => {
    getOrderTracker(order.number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.number]);

  // By updating the step here we can benefit from the auto updates of the traker in the periodic getter
  useEffect(() => {
    if (tracker.fulfillmentState && tracker.visitState) {
      setStep(
        orderTrackerStateName(tracker.fulfillmentState, tracker.visitState),
      );
    }
  }, [tracker.fulfillmentState, tracker.visitState]);

  if (loading || step === 'cancelado') return null;

  if (step === 'payment_denied' || step === 'error') {
    return <OrderTrackerMensageState orderState={step} />;
  }

  const driver = tracker.driver;
  const vehicle = tracker.vehicle;

  return (
    <>
      <p className="order-tracker-info-header">{`Informações do pedido #${order.number}`}</p>
      <PeriodicGetter
        countdownStartValue={15}
        handleGet={() => {
          getOrderTracker(order.number);
        }}
        loading={trackingLoading}
      />
      <div className="order-tracker-main">
        {(!(order.deliveryState === 'failed') && <BarTracker step={step} />) ||
          null}

        {/* Show the map if we are in the process of delivering */}
        {step === 'a caminho' ? (
          <div className="order-tracker-shipment-information">
            <GoogleStaticMapsImage
              className={`order-tracker-shipment-information-map-image${
                !driver && !vehicle ? '-only-in-page' : ''
              }`}
              size={!driver && !vehicle ? '420x80' : '420x180'}
              address={{
                ...order.shipAddress,
                addressLine1: order.shipAddress.address1,
                addressLine2: order.shipAddress.address2,
              }}
              driverLocation={
                driver && driver.locations
                  ? _.sortBy(driver.locations, ['clientTime'], ['desc'])[0]
                  : null
              }
            />

            <div className="order-tracker-shipment-information-driver">
              {driver && vehicle ? (
                <div>
                  <p className="order-tracker-shipment-information-driver-header">
                    Nome do Entregador:
                  </p>
                  <b>{driver.name}</b>
                  <br />
                  <p className="order-tracker-shipment-information-driver-header">
                    Placa:
                  </p>
                  <b>{vehicle.licensePlate}</b>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    tracker: trackerByOrderNumber(state, ownProps.order.number),
    trackingLoading: trackingLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    getOrderTracker: (orderNumber: string) => {
      dispatch(getTracker(orderNumber));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(OrderTracker);
