// @flow
import React, { useEffect, useRef, useState } from 'react';
// Components
import {
  Button,
  validationHelpers,
  FormControlInput,
  PageTitleWithGoBack,
} from '../../common';
// Images
import emailImage from '../images/email.svg';
// Styles
import './ForgotPassword.css';



type Props = {
  isLoading: boolean,
  errors: {
    base?: string,
    email?: string,
  },
  onSubmitForgotPassword: (
    email: string,
  ) => void,
  successMessage: string
};

const PasswordRecovery = ({ isLoading, errors, onSubmitForgotPassword, successMessage }: Props) => {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(null)
  const isLoadingRef = useRef(null)
  // Validates the form on submission. If there are no validation error dispatches action.
  const validateUserInput = () => {
    let noValidationErrors = true;
    if (email === '') {
      // Email is missing
      setEmailError('Campo é obrigatório')
      noValidationErrors = false;
    } else if (
      !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email,
      )
    ) {
      // If there isn't an @ then its not an email
      setEmailError('Email inválido')
      noValidationErrors = false;
    } else {
      setEmailError('')
    }
    if (noValidationErrors) {
      onSubmitForgotPassword(email);
    } else {
      console.error('Validation error on registration form.');
    }
  }
  useEffect(() => {
    isLoadingRef.current = isLoading
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (!isLoadingRef.current && !isLoading && errors.base) {
      if (errors.base) {
        setEmailError(errors.base)
      }
    }
    // eslint-disable-next-line
  }, [isLoading])

  return (
    <section className="forgot-password-main">
      <PageTitleWithGoBack title='Voltar' />
      <div className='forgot-password-header'>
        <h3 className='forgot-password-title'>
          Esqueceu a senha?
        </h3>
      </div>
      <div className='forgot-password-container'>
        <div className="forgot-password-box">
          <div className='forgot-password-content'>
            <img src={emailImage} alt="Email" className='forgot-password-image' />
            {successMessage ? <p className='forgot-password-text'>{successMessage}</p> : <p className='forgot-password-text'>Por favor digite o e-mail
              utilizado no cadastro.</p>}
          </div>
          {!successMessage && (
            <form
              onSubmit={e => {
                e.preventDefault();
                validateUserInput();
              }}
              className='forgot-password-form'
            >

              <FormControlInput
                label="Email"
                value={email}
                handleChangeText={value => setEmail(value)}
                handleFocus={value => setEmail(value)}
                handleBlur={() => {
                  const error = validationHelpers.user.email(email)
                  setEmailError(error)
                }}
                errorMessage={emailError}
              />

              <Button
                text="Enviar email"
                icon=""
                buttonType="button-secondary"
                loading={isLoading}
              />
            </form>
          )}
        </div>
      </div>

    </section>
  );
}

export default PasswordRecovery;
