// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';

// Components
import Scroller from '../components/Scroller';
// Actions
import { findMultipleProducts } from '../actions';
// Selectors
import { productsByIdsSelector, loadingByIdsSelector } from '../selectors';
// Types
import type { Product } from '../../types';

type OwnProps = {
  productIds: number[],
  title: string,
  acessedSubdepartment: String,
  subdepartmentSlug: String,
  subdepartmentLink: String,
};

type Props = OwnProps & {
  isLoading: boolean,
  products: Product[],
  findMultipleProducts: (number[]) => void,
};

class SubdepartmentScroller extends Component {
  props: Props;

  constructor(props: Props) {
    super(props);
    // Request products to display in scroller
    if (props.productIds.length !== 0) {
      props.findMultipleProducts(props.productIds);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.productIds.length !== 0 &&
      prevProps.productIds.length === 0
    ) {
      this.props.findMultipleProducts(this.props.productIds);
    }
  }

  render() {
    return (
      <Scroller
        title={this.props.title}
        slug={this.props.subdepartmentLink}
        products={this.props.products}
        isLoading={this.props.isLoading}
        scrollable={
          this.props.acessedSubdepartment === this.props.subdepartmentSlug
        } //category send the last element on http request user
      />
    );
  }
}

const mapStateToProps = (state: mixed, ownProps: OwnProps) => {
  return {
    products: productsByIdsSelector(state, ownProps.productIds),
    isLoading: loadingByIdsSelector(state, ownProps.productIds),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  return {
    findMultipleProducts: (slug: string) => {
      dispatch(findMultipleProducts(ownProps.productIds));
    },
  };
};

const connector: Connector<OwnProps, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default connector(SubdepartmentScroller);
