// @flow
import React from 'react';
import { Link } from 'react-router-dom';
// Styles
import './LoggedOutOptions.css';
// Assets
import userIcon from '../images/user.svg';

const LoggedOutOptions = () => {
  return (
    <div className="logged-out-options-main">
      <Link to="/login/" className="logged-out-link">
        <div className="logged-out-container">
          <img
            src={userIcon}
            className="logged-out-container-img"
            alt="Ícone usuário"
          />
          <div className="logged-out-content">
            <h3 className="logged-out-content-title">Bem vindo!</h3>
            <p className="logged-out-content-text">Acesse sua conta</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default LoggedOutOptions;
