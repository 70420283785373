import { authGet, authPost } from '../../api';
import * as schema from './schema';
import { camelizeKeys } from 'humps';
import { normalize } from 'normalizr';

const Barbecue = {
  async getBarbecueCategories() {
    const response = await authGet(`/barbecue/calculator_categories`);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.calculatorCategories);
    return normalized;
  },
  async getBarbecueOptionValues() {
    const response = await authGet(`/barbecue/option_values`);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.calculatorOptionsValue);
    return normalized;
  },
  async postBarbecue(
    totalDesiredQuantity: number,
    preferredOptionValueId: number,
    desiredCategories: Array<number>,
    fatPercentage: number,
  ) {
    const body = {
      total_desired_quantity: totalDesiredQuantity,
      preferred_option_value_id: preferredOptionValueId,
      desired_categories_attributes: desiredCategories.map((categoryId) => ({ category_id: categoryId })),
      fat_percentage: fatPercentage,
    };
    const response = await authPost(`/barbecue/barbecues/`, body);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.barbecue);
    return normalized;
  },
};
export default Barbecue;
