// @flow
import React from 'react';
//Components
import { PageLoader, PageTitleWithGoBack } from '../../common';
import DepartmentHit from './DepartmentHit';
// Types
import type { Department } from '../types';

import './DepartmentsIndexPage.css';

type Props = {
  departments: Department[],
  loading: boolean,
};

const DepartmentsIndexPage = (props: Props) => {
  if (props.loading && props.departments.length === 0) {
    return (
      <div>
        <PageTitleWithGoBack title="Departamentos" />
        <PageLoader />
      </div>
    );
  }

  return (
    <div className="departments-index-main">
      <PageTitleWithGoBack title="Departamentos" />

      <div className="departments-index-flex-container">
        {props.departments.map((department, ind) => (
          <DepartmentHit
            key={ind}
            id={department.id}
            title={department.name}
            categories={department.subdepartments}
            image={department.imageUrl}
          />
        ))}
      </div>
    </div>
  );
};

export default DepartmentsIndexPage;
