//@flow
import React, { useState } from 'react';
import _ from 'lodash';
// Components
import DeliveryPickerOption from './DeliveryPickerOption';
// Types
import { ShippingRate } from '../../types/ShippingRate';
//Analitycs
import * as Analytics from '../../../common/analytics';
//Styles
import './DeliveryPicker.css';
import 'slick-carousel/slick/slick.css';

interface DeliveryPickerProps {
  onSelect: (id: string | null) => void;
  selected: string | null;
  shippingRates: ShippingRate[];
}

const days = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'];
const months = [
  'JAN',
  'FEV',
  'MAR',
  'ABR',
  'MAIO',
  'JUN',
  'JUL',
  'AGO',
  'SET',
  'OUT',
  'NOV',
  'DEZ',
];

const DeliveryPicker = ({
  onSelect,
  selected,
  shippingRates,
}: DeliveryPickerProps) => {
  const sortedShippingRates = _.sortBy(
    shippingRates,
    item => new Date(item.deliveryWindowStart),
  );
  // Since we always have less than 30 available days it is safe to filter based on the day alone
  // If we ever extend to more than 1 month worth of days we should consider a different attribute
  const [selectedDay, setSelectedDay] = useState(
    new Date(sortedShippingRates[0].deliveryWindowStart).getDate(),
  );

  const getAvailableDays = () => {
    const dates = sortedShippingRates.map(
      rate => new Date(rate.deliveryWindowStart),
    );

    return _.uniqBy(dates, date => date.getDate());
  };

  const shouldDisableDeliveryOption = (deliveriesRemaining: number) => {
    return deliveriesRemaining !== null && deliveriesRemaining === 0;
  };

  const availableDays = getAvailableDays();
  return (
    <div className="delivery-picker-main">
      <div className="delivery-picker-section">
        {availableDays.map((item, index) => {
          const isSelected = selectedDay === item.getDate();
          return (
            <div
              key={index}
              className={`delivery-picker-day ${isSelected && 'selected'}`}
              onClick={ev => {
                onSelect(null);
                setSelectedDay(item.getDate());
              }}
            >
              <h6
                className={`delivery-picker-day-title ${
                  isSelected && 'selected'
                }`}
              >
                {new Date().getDate() === item.getDate()
                  ? 'HOJE'
                  : `${days[item.getDay()]}`}
              </h6>
              <h6
                className={`delivery-picker-day-text ${
                  isSelected && 'selected'
                }`}
              >{`${item.getDate()} ${months[item.getMonth()]}`}</h6>
            </div>
          );
        })}
      </div>
      <section className="delivery-picker-section-column">
        {_.filter(
          sortedShippingRates,
          item => selectedDay === new Date(item.deliveryWindowStart).getDate(),
        ).map((rate, index) => (
          <div
            className={`delivery-picker-option-container ${
              rate.deliveriesRemaining === 0
                ? 'delivery-picker-option-container-disabled'
                : ''
            } ${
              rate.deliveriesRemaining === 0 &&
              'delivery-picker-option-container-full'
            }`}
            onClick={() => {
              if (rate.deliveriesRemaining === 0) return;
              Analytics.logSelectedDelivery(
                rate.deliveriesRemaining,
                rate.deliveryWindowStart,
                rate.deliveryWindowEnd,
              );
              onSelect(rate);
            }}
            key={rate.id}
            disabled={shouldDisableDeliveryOption(rate.deliveriesRemaining)}
          >
            <DeliveryPickerOption
              itemPosition={index}
              selected={selected && rate.id === selected.id}
              shippingRate={rate}
            />
          </div>
        ))}
      </section>
    </div>
  );
};

export default DeliveryPicker;
