// @flow
import React from 'react';
// Components
import UnconnectedWishlistSearchInput from './UnconnectedWishlistSearchInput';
import SearchResultSquare from '../../../search/components/SearchResultSquare';
import { Hits, connectStateResults } from 'react-instantsearch-dom';
// Styles
import './WishlistProductModal.css';

type Props = {
  isOpen: boolean,
  handleClose: () => void,
  query: string,
  searching: boolean,
  searchResults: any,
};

const WishlistProductModal = ({
  isOpen,
  handleClose,
  query,
  searchResults,
  searching,
}: Props) => {
  const hasResults = searchResults?.hits?.length > 0;
  if (!isOpen) return null;
  return (
    <div className="wishlist-product-modal-backdrop">
      <div className="wishlist-product-modal">
        <span className="wishlist-product-modal-close" onClick={handleClose}>
          &times;
        </span>
        <h2 className="wishlist-product-modal-title">Favoritar produtos</h2>
        <UnconnectedWishlistSearchInput />
        <section className="wishlist-product-modal-grid">
          {!hasResults && (
            <h3 className="wishlist-product-modal-subtitle">
              Nenhum item foi encontrado. :(
            </h3>
          )}
          <Hits hitComponent={SearchResultSquare} />
        </section>
      </div>
    </div>
  );
};

export default connectStateResults(WishlistProductModal);
