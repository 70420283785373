// @flow
import _ from 'lodash';
import moment from 'moment';

// Takes a string and return a truncated version
export const truncateString = (originalString: string, maxLength: number) => {
  if (originalString && originalString.length > maxLength) {
    return originalString.substr(0, maxLength - 1) + '...';
  }

  return originalString;
};

// Takes in unitForQuantity and returns displayable units
export const unitConverter = (unitForQuantity: string) => {
  return unitForQuantity === 'g' ? ' kg' : ' un';
};

// Converts quantity into locale specific value taking into account quantity
export const quantityConverter = (
  quantity: number,
  unitForQuantity: string,
) => {
  if (quantity == null) {
    return '- -';
  }
  if (quantity < 1000) {
    return `${quantity} ${unitForQuantity}`;
  }
  return (
    parseFloat(
      unitForQuantity === 'g' ? quantity / 1000 : quantity,
    ).toLocaleString('pt-br') + unitConverter(unitForQuantity)
  );
};

// Converts scale into locale specific value taking into account quantity
export const scaleConverter = (quantity: number, unitForQuantity: string) => {
  if (!quantity) return -1;
  return parseFloat(unitForQuantity === 'g' ? quantity * 1000 : quantity);
};

// Converts price into locale based string
export const priceConverter = (price: number) => {
  return `${parseFloat(price).toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  })}`;
};

export const portioningString = (item: any) => {
  if (item.variant.unitForQuantity === 'g' && item.portioningSize) {
    const quantity = item.quantity;
    const portioningSize = item.portioningSize;
    const portionCount = parseInt(quantity / portioningSize, 10);
    const portionResidue = quantity % portioningSize;

    return `(${portionCount}x ${portioningSize}g${
      portionResidue ? `, 1x ${parseInt(portionResidue, 10)}g` : ''
    })`;
  } else {
    return '';
  }
};

export const estimateNumberOfPiecesForProduct = (
  weight: number,
  maxWeightPerPiece: number,
): number => Math.ceil(weight / maxWeightPerPiece);

export const scaleAndFormatPrice = (
  price: number,
  unitForQuantity: 'g' | 'un',
) => {
  return (
    parseFloat(unitForQuantity === 'g' ? price * 1000 : price).toLocaleString(
      'pt-br',
      {
        style: 'currency',
        currency: 'BRL',
      },
    ) +
    '/' +
    (unitForQuantity === 'g' ? 'Kg' : 'un')
  );
};
//order Tracker States
export const orderTrackerStates = {
  recebido: ['payment_evaluating', 'pending'],
  preparando: [
    'preparation_queue',
    'preparation',
    'packaging_queue',
    'packaging',
  ],
  pronto: ['ready'],
  'a caminho': ['planned', 'in_progress'],
  entregue: ['success', 'completed'],
};

export const orderTrackerStateName = (fulfillmentState, deliveryState) => {
  let step;
  if (fulfillmentState === 'canceled') {
    step = 'Cancelado';
    return step; // If fulfillmentState is canceled the tracker should always show canceled
  }

  if (fulfillmentState === 'error' || deliveryState === 'error') {
    step = 'Erro';
    return step; // If order failed tracker should always show failed
  }

  if (deliveryState === 'failed') {
    step = 'Falha';
    return step;
  }

  if (fulfillmentState === 'payment_denied') {
    step = 'Pagamento negado';
  } else if (_.includes(orderTrackerStates['recebido'], fulfillmentState)) {
    step = 'Recebido';
  } else if (_.includes(orderTrackerStates['preparando'], fulfillmentState)) {
    step = 'Preparando';
  } else if (_.includes(orderTrackerStates['pronto'], fulfillmentState)) {
    step = 'Pronto';
  } else {
    step = 'Erro';
  }

  if (_.includes(orderTrackerStates['a caminho'], deliveryState)) {
    step = 'A Caminho';
  } else if (_.includes(orderTrackerStates['entregue'], deliveryState)) {
    step = 'Entregue';
  }

  return step;
};

export const formatBirthdate = (value: string) => {
  if (!value) return '';
  value = value.replace(/[^\d]/g, '');
  const regex = /^(\d{0,2})(\d{0,2})(\d{0,4})$/g;
  return value.replace(regex, (regex, $1, $2, $3) =>
    [$1, $2, $3].filter(group => !!group).join('/'),
  );
};

export const cpfMask = value => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const removeSpecialCharacters = value => {
  return value.replace(/[^a-zA-Z0-9 ]/g, '');
};

export const copyToClipboard = value => {
  navigator.clipboard.writeText(value);
};

export const validateEmail = email => {
  if (!email) return false;
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );
};

export const formatPhoneNumber = value => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1)$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
    .replace(/(-\d{4})\d+?$/, '$1');
};

export const formatDateAsStringWithOption = (
  dateString = '',
  formatString = 'DD/MM/YYYY [às] HH:mm',
) => {
  if (!dateString) return '';
  return moment(dateString).format(formatString);
};

export const formatZipcode = (cep: string): string => {
  const cleanedZipcode = cep.replace(/\D/g, '');
  if (cleanedZipcode.length !== 8) {
    return cleanedZipcode;
  }
  const formattedZipcode =
    cleanedZipcode.slice(0, 5) + '-' + cleanedZipcode.slice(5);

  return formattedZipcode;
};

export const scrollToTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

export const reloadPage = () => {
  window.location.reload();
};
