// @flow
import { camelizeKeys, decamelizeKeys } from 'humps';
import _ from 'lodash';
import { normalize } from 'normalizr';
import { authDelete, authGet, authPost, authPut } from '../../api';
import {
  getFirstReferringParams,
  getLatestReferringParams,
} from '../../common/branch';
import type {
  Address,
  CreditCard,
  OrderNumber,
  PaymentSource,
} from '../../types';
import * as schema from './schema';

const Order = {
  async mine() {
    const response = await authGet('/v2/orders/mine');
    const data = await response
      .json()
      .then((b: { [string]: any }) => camelizeKeys(b));
    const normalized = normalize(data.orders, schema.orders);
    return normalized;
  },

  async create(lineItems: { variantId: number, quantity: number }[] = []) {
    const body = {
      order: {
        line_items: lineItems.map(l => ({
          variant_id: l.variantId,
          quantity: l.quantity,
        })),
      },
    };
    const response = await authPost('/api/v1/orders.json', body);
    const data = await response.json().then(b => camelizeKeys(b));

    if (data.email == null) {
      // This is a guest checkout
      window.localStorage.setItem(this.keys.GUEST_TOKEN, data.token);
      window.localStorage.setItem(this.keys.GUEST_NUMBER, data.number);
    }

    const normalized = normalize(data, schema.order);
    return normalized;
  },

  async get(number: OrderNumber) {
    const response = await authGet(`/api/v1/orders/${number}.json`);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.order);
    return normalized;
  },

  async activeCart() {
    const response = await authGet('/api/v1/orders/current', {}, r => {
      if (r.status === 401) throw new Error(this.errors.NOT_LOGGED_IN);
      else throw new Error(r.status);
    });
    if (response.status === 204) {
      throw new Error(this.errors.NOT_LOGGED_IN);
    }
    const data = await response.json().then(body => camelizeKeys(body));
    return normalize(data, schema.order);
  },

  async activeGuestCart() {
    const guestToken = window.localStorage.getItem(this.keys.GUEST_TOKEN);
    const guestNumber = window.localStorage.getItem(this.keys.GUEST_NUMBER);
    if (guestToken && guestNumber) {
      const response = await authGet(
        `/api/v1/orders/${guestNumber}?order_token=${guestToken}`,
      );
      const data = await response.json().then(body => camelizeKeys(body));
      return normalize(data, schema.order);
    } else {
      throw new Error(this.errors.NO_ACTIVE_CART);
    }
  },

  async activeCheckout() {
    const response = await authPost('/api/v1/checkouts/current/restart');
    const data = await response.json();
    const normalized = normalize(data, schema.order);
    return normalized;
  },

  async transitionToState(number: string, nextState: string) {
    const response = await authPut(
      `/api/v1/checkouts/${number}?state=${nextState}`,
    );
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.order);
    return normalized;
  },

  async transitionStepToState(number: string, targetState: string) {
    const response = await authPut(
      `/api/v1/checkouts/${number}?step_to_state=${targetState}`,
    );
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.order);
    return normalized;
  },

  async createLineItem(
    number: OrderNumber,
    variantId: number,
    quantity: number,
  ) {
    const guestToken = window.localStorage.getItem(this.keys.GUEST_TOKEN);
    const body = {
      line_item: {
        variant_id: variantId,
        quantity: quantity,
      },
    };
    const response = await authPost(
      `/api/v1/orders/${number}/line_items.json?order_token=${guestToken}`,
      body,
    );
    const data = await response.json();
    const normalized = normalize(data, schema.lineItem);
    return normalized;
  },

  async updateLineItem(
    number: OrderNumber,
    id: number,
    quantity: number,
    observation: string,
    portioning: number,
  ) {
    const guestToken = window.localStorage.getItem(this.keys.GUEST_TOKEN);
    const body = {
      line_item: _.omitBy(
        {
          observation: observation,
          quantity: quantity,
          portioning_size: portioning,
        },
        e => e === null || e === undefined,
      ),
    };

    const response = await authPut(
      `/api/v1/orders/${number}/line_items/${id}?order_token=${guestToken}`,
      body,
    );
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.lineItem);
    return normalized;
  },

  async deleteLineItem(number: OrderNumber, id: number) {
    const guestToken = window.localStorage.getItem(this.keys.GUEST_TOKEN);
    await authDelete(
      `/api/v1/orders/${number}/line_items/${id}?order_token=${guestToken}`,
    );
    return;
  },

  async getPromotionsByUser(userId: number) {
    const response = await authGet(`/api/v1/promotions?by_eligible=true`);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data.promotions, schema.promotions);
    return normalized;
  },

  // Advances to the next state
  // For delivery -> payment, it auto-selects a delivery date
  async nextState(number: OrderNumber) {
    const response = await authPut(`/api/v1/checkouts/${number}/next`);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.order);
    return normalized;
  },

  // First we get the current order number
  async addressState(number: OrderNumber) {
    const order = await authPut(`/api/v1/checkouts/${number}?state=cart`)
      .then(r => r.json())
      .then(b => camelizeKeys(b));
    // Normalize
    return normalize(order, schema.order);
  },

  // Adds billing and shipping address to an order
  // Returns shipments object
  async addAddresses(
    number: OrderNumber,
    billAddress: Address,
    shipAddress: Address,
  ) {
    const body = {
      state: 'address',
      order: {
        bill_address_attributes: {
          base_address_id: billAddress.id,
          firstname: billAddress.receiverName,
          lastname: '', // TODO: Figure out discrepancy b/w receiverName and first/last names
          address1: billAddress.addressLine1,
          address2: billAddress.addressLine2,
          neighborhood: billAddress.neighborhood,
          reference: billAddress.reference,
          additional_information: billAddress.additionalInformation,
          city: billAddress.city,
          phone: billAddress.telephone,
          zipcode: billAddress.zipcode,
          state_id: 1, // TODO: Implement logic for states other than Bahia
          country_id: 1, // 1 is id for Brazil
          cnpj: billAddress.cnpj,
          ie_company: billAddress.ieCompany,
          company: billAddress.company,
          residence_type: billAddress.residenceType,
          residence_number: billAddress.residenceNumber,
          residence_data: billAddress.residenceData,
        },
        ship_address_attributes: {
          base_address_id: shipAddress.id,
          firstname: shipAddress.receiverName,
          lastname: '', // TODO: Figure out discrepancy b/w receiverName and first/last names
          address1: shipAddress.addressLine1,
          address2: shipAddress.addressLine2,
          neighborhood: shipAddress.neighborhood,
          reference: shipAddress.reference,
          additional_information: shipAddress.additionalInformation,
          city: shipAddress.city,
          phone: shipAddress.telephone,
          zipcode: shipAddress.zipcode,
          latitude: shipAddress.latitude,
          longitude: shipAddress.longitude,
          state_id: 1, // TODO: Implement logic for states other than Bahia
          country_id: 1, // 1 is id for Brazil
          cnpj: shipAddress.cnpj,
          ie_company: shipAddress.ieCompany,
          company: shipAddress.company,
          delivery_cost: shipAddress.deliveryCost,
          residence_type: shipAddress.residenceType,
          residence_data: shipAddress.residenceData,
          residence_number: shipAddress.residenceNumber,
        },
      },
    };
    const response = await authPut(`/api/v1/checkouts/${number}.json`, body);
    const data = await response.json();
    const normalized = normalize(data, schema.order);
    return normalized;
  },

  async selectShipping(
    number: OrderNumber,
    shippingRateId: number,
    shipmentId: number,
  ) {
    const body = {
      state: 'delivery',
      order: {
        shipments_attributes: {
          '0': {
            selected_shipping_rate_id: shippingRateId,
            id: shipmentId,
          },
        },
      },
    };
    const response = await authPut(`/api/v1/checkouts/${number}.json`, body);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.order);
    return normalized;
  },

  async userCreditCards() {
    // Two step process: first get the user id then his cards
    try {
      const usersData = await authGet(`/api/v1/users/`)
        .then(r => r.json())
        .then(b => camelizeKeys(b));
      const userId = usersData.users[0].id;
      const response = await authGet(`/api/v1/users/${userId}/credit_cards`);
      const data = await response
        .json()
        .then(b => camelizeKeys(b.credit_cards));
      const normalized = normalize(data, [schema.creditCard]);
      return normalized;
    } catch (err) {
      if (err.message === 401) {
        throw this.errors.NOT_LOGGED_IN;
      }
      throw err;
    }
  },

  async newPayment(
    number: OrderNumber,
    paymentMethodId: number,
    paymentSource: PaymentSource | CreditCard,
    bigClubCreditAmount: number,
  ) {
    const body = {
      state: 'payment',
      order: {
        bigclub_credit_amount: bigClubCreditAmount,
        payments_attributes: [
          {
            payment_method_id: paymentMethodId,
            card_brand_id: paymentSource.cardBrandId,
          },
        ],
        latest_branch_params: await getLatestReferringParams(), // params from last open
        first_branch_params: await getFirstReferringParams(), // params from original install
      },
      payment_source: {
        [paymentMethodId]: decamelizeKeys(paymentSource),
      },
    };
    await authPut(`/api/v1/checkouts/${number}`, body);
    const confirmResponse = await authPut(`/api/v1/checkouts/${number}`);
    const data = await confirmResponse.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.order);
    return normalized;
  },

  async newPromotion(number: OrderNumber, couponCode: string) {
    const body = {
      state: 'delivery',
      order: {
        coupon_codes: [couponCode],
      },
    };
    const confirmResponse = await authPut(`/api/v1/checkouts/${number}`, body);
    const data = await confirmResponse.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.order);
    return normalized;
  },
  // TODO: This API request makes two requests. Is there a better way?
  async existingCard(
    number: OrderNumber,
    cardId: number,
    bigClubCreditAmount: number,
  ) {
    const body = {
      state: 'payment',
      order: {
        bigclub_credit_amount: bigClubCreditAmount,
        existing_card: cardId,
        latest_branch_params: await getLatestReferringParams(), // params from last open
        first_branch_params: await getFirstReferringParams(), // params from original install
      },
    };

    const paymentResponse = await authPut(`/api/v1/checkouts/${number}`, body);
    let data = await paymentResponse.json().then(b => camelizeKeys(b));

    // Only make the second request if the order state is not "complete"
    if (data['state'] !== 'complete') {
      const confirmResponse = await authPut(`/api/v1/checkouts/${number}`);
      data = await confirmResponse.json().then(b => camelizeKeys(b));
    }

    const normalized = normalize(data, schema.order);
    return normalized;
  },

  async paymentMethods(number: OrderNumber) {
    const response = await authGet(`/orders/${number}/payments/new`);
    const data = await response.json().then(d => camelizeKeys(d));
    const normalized = normalize(data, schema.order);
    return normalized;
  },

  async selectExistingCard(number: OrderNumber) {},

  handleErrors(messages: { [code: number]: string }) {
    return (response: Response) => {
      if (response.status === 401) {
      } else if (response.status === 422) {
      }
    };
  },

  errors: {
    NO_ACTIVE_CART: 'No active cart order.',
    NOT_LOGGED_IN: "You're not logged in.",
    UNPROCESSABLE_ENTITY: 'Houve um problema processando',
  },

  keys: {
    GUEST_TOKEN: 'order-guest-token',
    GUEST_NUMBER: 'order-guest-number',
  },

  async copyOrder(orderNumber: string, orderNumberFrom: string) {
    const body = {
      from: orderNumberFrom,
    };
    const response = await authPut(`/api/v1/orders/${orderNumber}/copy`, body);
    const data = await response.json().then(b => camelizeKeys(b));

    const normalized = normalize(data, schema.order);
    return normalized;
  },
};

export default Order;
