// @flow
import React from 'react';
// styles
import './PageTitleWithGoBack.css';
// Hooks
import { useHistory } from 'react-router-dom';
// Assets
import arrowLeft from '../images/arrowLeft.svg';

type Props = {
  title: string,
};

const PageTitleWithGoBack = ({ title }: Props) => {
  const history = useHistory();

  const onClickGoBack = () => {
    history.goBack();
  };

  return (
    <div className="page-title-with-go-back-wrapper">
      <button className="page-title-with-go-back-arrow" onClick={onClickGoBack}>
        <img src={arrowLeft} alt="Seta para esquerda" />
      </button>
      <h1 className="page-title-with-go-back-title">{title}</h1>
    </div>
  );
};

export default PageTitleWithGoBack;
