// @flow
import React from 'react';
import type { UserAddress } from '../../../user';
// Components
import {
  MyAccountAddressEditor as AddressEditor,
  MyAccountAddressItem as AddressItem,
} from '../../../user';
import { PageLoader } from '../../../common';
// Styles
import './AddressPicker.css';

export type Props = {
  editing: number | 'new' | false,
  addresses: UserAddress[],
  selected: ?UserAddress,
  loading: boolean,
  editingLoading: boolean,
  addressesLoading: boolean,
  pickAddress: (address: UserAddress) => void,
  editAddress: (address: UserAddress) => void,
  updateAddress: (address: UserAddress) => void,
  deleteAddress: (address: UserAddress) => void,
  deletingLoading: (id: number) => boolean,
};

const AddressPicker = (props: Props) => {
  // In case the addresses are still loading
  if (props.addressesLoading) {
    return <PageLoader />;
  }

  return (
    <div className="address-picker-main">
      <div className="address-picker-content">
        {props.addresses.map(address => {
          if (props.editing === address.id) {
            return (
              <AddressEditor
                key={address.id}
                address={address}
                loading={props.editingLoading}
                onSubmitAddress={editedAddress =>
                  props.updateAddress(editedAddress)
                }
              />
            );
          }
          return (
            <AddressItem
              key={address.id}
              address={address}
              isSelected={
                props.selected ? props.selected.id === address.id : false
              }
              deletingLoading={props.deletingLoading(address.id)}
              onClickSelect={() => props.pickAddress(address)}
              onClickEdit={() => props.editAddress(address)}
              onClickDelete={() => {
                props.deleteAddress(address);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AddressPicker;
