// @flow
import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import * as actions from '../actions';

// BANNERS
const banners = handleActions(
  {
    [actions.getBanners]: {
      next: (state, action) => action.payload.entities.banners,
      throw: (state, action) => state,
    },
  },
  {},
);

const postNewsLetterEmail = handleActions(
  {
    [actions.storeNewsLetterEmail]: {
      next: (state, action) => state,
      throw: (state, action) => state,
    },
  },
  null,
);

const scheduledDiscounts = handleActions(
  {
    [actions.getScheduledDiscountsSuccess]: {
      next: (state, action) => action.payload.entities.scheduledDiscounts,
      throw: (state, action) => state,
    },
  },
  {},
);

// LOADING
const initialLoadingState = {
  banners: false,
  loadingNewsLetterEmail: false,
  scheduledDiscounts: false,
};

const loading = handleActions(
  {
    [actions.getBannersRequest]: (state, action) => ({
      ...state,
      banners: true,
    }),
    [actions.getBanners]: (state, action) => ({
      ...state,
      banners: false,
    }),
    [actions.postNewsLetterEmailRequest]: (state, action) => ({
      ...state,
      loadingNewsLetterEmail: true,
    }),
    [actions.postNewsLetterEmail]: (state, action) => ({
      ...state,
      loadingNewsLetterEmail: false,
    }),
    [actions.getScheduledDiscountsRequest]: (state, action) => ({
      ...state,
      scheduledDiscounts: true,
    }),
    [actions.getScheduledDiscountsSuccess]: (state, action) => ({
      ...state,
      scheduledDiscounts: false,
    }),
    [actions.getScheduledDiscountsFailure]: (state, action) => ({
      ...state,
      scheduledDiscounts: false,
      error: action.payload.error,
    }),
  },
  initialLoadingState,
);

const reducers = combineReducers({
  banners,
  postNewsLetterEmail,
  scheduledDiscounts,
  loading,
});

export default reducers;
