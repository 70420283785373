// @flow
import React, { useState, useEffect } from 'react';
// Styles
import './LandingIncreasingUserCounter.css';

const milisecondsSinceFirstDay =
  new Date().getTime() - new Date('05/01/2023').getTime();
const milisecondsPerDay = 86400000;
const averageUsersPerDay = 50;
const startCountUp =
  20000 + (milisecondsSinceFirstDay / milisecondsPerDay) * averageUsersPerDay;

const LandingIncreasingUserCounter = () => {
  const [usersQuantity, setUsersQuantity] = useState(startCountUp);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (usersQuantity < 60000) setUsersQuantity(usersQuantity + 1);
    }, Math.random() * 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, [usersQuantity]);

  return (
    <div className="landing-increasing-user-counter">
      <span className="landing-increasing-user-counter-people-and-counting-text">
        Já somos
      </span>
      <div className="landing-increasing-user-counter-contUp">
        {usersQuantity.toFixed()}
      </div>
      <div className="landing-increasing-user-counter-people-and-counting ">
        <span className="landing-increasing-user-counter-people-and-counting-text">
          ADEPTOS
        </span>
        <sub className="landing-increasing-user-counter-people-and-counting-text-sub">
          ( E contando )
        </sub>
      </div>
    </div>
  );
};

export default LandingIncreasingUserCounter;
