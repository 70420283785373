// @flow
import { createAction } from 'redux-actions';
import Posts from '../api';
import type { Dispatch } from '../../types';

/****************************
 *** FETCH ONE POST BY ID***
 ***************************/

export const fetchPost = createAction('FETCH_POST');
export const fetchPostRequest = createAction('FETCH_POST_REQUEST');

export function findPost(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchPostRequest());
    try {
      const postResponse = await Posts.get(id);
      dispatch(fetchPost(postResponse));
    } catch (err) {
      dispatch(fetchPost(err));
    }
  };
}


/****************************
 *** FETCH POSTS BLOG ***
 ***************************/

export const fetchPostsBlog = createAction('FETCH_POSTS_BLOG');
export const fetchPostsBlogRequest = createAction('FETCH_POSTS_BLOG_REQUEST');

export function findPostsBlog() {
  return async (dispatch: Dispatch) => {
    dispatch(fetchPostsBlogRequest());
    try {
      const postResponse = await Posts.getByCategory('blog');
      dispatch(fetchPostsBlog(postResponse));
    } catch (err) {
      dispatch(fetchPostsBlog(err));
    }
  };
}
/****************************
 *** FETCH POSTS NEWS ***
 ***************************/

export const fetchPostsNews = createAction('FETCH_POSTS_NEWS');
export const fetchPostsNewsRequest = createAction('FETCH_POSTS_NEWS_REQUEST');

export function findPostsNews() {
  return async (dispatch: Dispatch) => {
    dispatch(fetchPostsNewsRequest());
    try {
      const postResponse = await Posts.getByCategory('news');
      dispatch(fetchPostsNews(postResponse));
    } catch (err) {
      dispatch(fetchPostsNews(err));
    }
  };
}

/****************************
 *** FETCH PINNED POSTS***
 ***************************/

export const fetchPinnedPosts = createAction('FETCH_PINNED_POSTS');
export const fetchPinnedPostsRequest = createAction('FETCH_PINNED_POSTS_REQUEST');

export function findPinnedPosts() {
  return async (dispatch: Dispatch) => {
    dispatch(fetchPinnedPostsRequest());
    try {
      const postResponse = await Posts.getPinnedPosts();
      dispatch(fetchPinnedPosts(postResponse));
    } catch (err) {
      dispatch(fetchPinnedPosts(err));
    }
  };
}