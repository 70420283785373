// @flow
import React from 'react';
// Components
import { FreeShippingNotice } from '../../common';
import BigClubNotice from './BigClubNotice';
// Assets
import cartIcon from '../images/cart-icon.svg';
// Styles
import './CartPanel.css';

type CartPanelProps = {
  numberOfItems: number,
  totalCartPrice: number,
  bigClubInfo?: BigClubInfo,
  minPriceToEarnCashback?: number,
  shouldDisplayFreeShippingNotice: boolean,
  shouldDisplayBigClubNotice?: boolean,
};

const CartPanel = ({
  numberOfItems,
  totalCartPrice,
  bigClubInfo,
  minPriceToEarnCashback,
  shouldDisplayFreeShippingNotice,
  shouldDisplayBigClubNotice,
}: CartPanelProps) => {
  return (
    <div className="cart-panel-container">
      <div className="cart-panel-header-container">
        <div className="cart-panel-cart-items-quantity">
          <img src={cartIcon} alt="Cart-Icon"></img>
          <h1>Carrinho ({numberOfItems})</h1>
        </div>
      </div>
      <div className="cart-panel-freightage-container">
        {/* <PromotionNotice totalCartPrice={totalCartPrice} /> */}
      </div>
      {shouldDisplayBigClubNotice && (
        <div className="cart-panel-cashback-container">
          <BigClubNotice
            totalCartPrice={totalCartPrice}
            bigClubInfo={bigClubInfo}
            minPriceToEarnCashback={minPriceToEarnCashback}
          />
        </div>
      )}
      <div className="cart-panel-freightage-container">
        <FreeShippingNotice
          currentPrice={totalCartPrice}
          shouldDisplay={totalCartPrice}
        />
      </div>
    </div>
  );
};

export default CartPanel;
