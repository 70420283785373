// @flow
import React from 'react';
// Styles
import './FormControlInput.css';

type Props = {
  value: string | number,
  type?: string,
  label?: string,
  errorMessage?: string,
  handleChangeText: (value: string | number) => void,
  handleBlur?: () => void,
  handleFocus?: () => void,
  isRequired?: boolean,
  maxLength?: string | number,
  disabled?: boolean,
  autocomplete: string,
  variant: 'rounded' | 'default',
};

const FormControlInput = ({
  type = 'text',
  value = '',
  label,
  errorMessage = 'Esse campo é obrigatório*',
  handleChangeText,
  handleBlur,
  handleFocus,
  isRequired = false,
  maxLength,
  disabled,
  autocomplete,
  variant = 'default',
}: Props) => {
  return (
    <div className="form-control-input-wrapper">
      <input
        className={`form-control-input ${
          errorMessage && variant === 'default'
            ? 'form-control-input-invalid'
            : ''
        } ${variant === 'rounded' ? 'form-control-input-rounded' : ''} ${
          errorMessage && variant === 'rounded'
            ? 'form-control-input-rounded-invalid'
            : ''
        }`}
        type={type}
        value={value}
        required
        autoComplete={autocomplete}
        name={label}
        id={label}
        onChange={event => handleChangeText(event.target.value)}
        onBlur={handleBlur}
        onFocus={event => {
          handleFocus(event.target.value);
        }}
        maxLength={maxLength}
        disabled={disabled}
      />
      <label
        className={`form-control-input-label ${
          variant === 'rounded' ? 'form-control-input-rounded-label' : ''
        }`}
        htmlFor={label}
      >
        {label}
        {isRequired && <span> *</span>}
      </label>
      {!!errorMessage && (
        <span className="form-control-input-message form-control-input-message-animation">
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default FormControlInput;
