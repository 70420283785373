// @flow
import { createAction } from 'redux-actions';
import Departments from '../api';
import type { Dispatch } from '../../types';

/*****************************
 *** FETCH ALL DEPARTMENTS ***
 ****************************/

export const fetchDepartmentsIndex = createAction('FETCH_DEPARTMENTS_INDEX');
export const fetchDepartmentsIndexRequest = createAction('FETCH_DEPARTMENTS_INDEX_REQUEST');

export function findDepartmentsIndex() {
  return async (dispatch: Dispatch) => {
    dispatch(fetchDepartmentsIndexRequest());
    try {
      const departmentsResponse = await Departments.getIndex();
      dispatch(fetchDepartmentsIndex(departmentsResponse));
    } catch (err) {
      dispatch(fetchDepartmentsIndex(err));
    }
  };
}

/****************************
 *** FETCH ONE DEPARTMENT ***
 ***************************/

export const fetchDepartment = createAction('FETCH_DEPARTMENT');
export const fetchDepartmentRequest = createAction('FETCH_DEPARTMENT_REQUEST');

export function findDepartment(slug: string) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchDepartmentRequest());
    try {
      const departmentResponse = await Departments.get(slug);
      dispatch(fetchDepartment(departmentResponse));
    } catch (err) {
      dispatch(fetchDepartment(err));
    }
  };
}