// Styles
import './KitsDivisor.css';

const KitsDivisor = () => {
  return (
    <div className="kits-divisor-container">
      <h1 className="kits-divisor-text">
        Fique por dentro e não perca essa oportunidade!
      </h1>
    </div>
  );
};
export default KitsDivisor;
