import React from 'react';
import { connect } from 'react-redux';
// Helpers
import { priceConverter } from '../../common';
// Styles
import './TotalAvailableStoreCredit.css';
// Types
import type { Connector } from 'react-redux';
// Selectors
import { totalAvailableStoreCreditSelector } from '../selectors';

type Props = {
    totalAvailableStoreCredit: number,
};

const TotalAvailableStoreCredit = ({ totalAvailableStoreCredit }: Props) => {
    return totalAvailableStoreCredit && totalAvailableStoreCredit !== '0.0' ? (<div className="total-available-store-credit-main">
        {`Você tem ${priceConverter(totalAvailableStoreCredit)} de crédito!`}
        <div className="description">Aplicaremos automaticamente no pedido durante o checkout.</div>
    </div>) : ''
};

const mapStateToProps = (state, ownProps) => {
    return {
        totalAvailableStoreCredit: totalAvailableStoreCreditSelector(state),
    };
};

const connector: Connector<{}, Props> = connect(mapStateToProps);
export default connector(TotalAvailableStoreCredit);