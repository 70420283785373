// @flow
import React from 'react';
// Types
import type {
  LineItem,
  BigClubInfo,
  OrderAddress,
  OrderPayment,
  OrderDelivery,
} from '../types';
// Styles
import './CartPreview.css';
// Helpers
import { priceConverter } from '../../common';
//Components
import { Button } from '../../common';
import DeliverySummary from './delivery/DeliverySummary';
import AddressSummary from './address/AddressSummary';
import PaymentSummary from './payment/PaymentSummary';
import CartItemPreview from './CartItemPreview';
// Assets
import paymentIcon from '../images/paymentIcon.svg';
import calendarIcon from '../images/calendarIcon.svg';
import addressIcon from '../images/addressIcon.svg';

type CartPreviewResumeProps = {
  handleClick: () => void,
  imgUrl: string,
  alt: string,
  children: ReactNode,
};

const CartPreviewResumeItem = ({
  handleClick,
  imgUrl,
  alt,
  children,
}: CartPreviewResumeProps) => {
  return (
    <div className="card-preview-resume-item">
      <div className="card-preview-resume-item-content">
        <img className="card-preview-resume-item-img" alt={alt} src={imgUrl} />
        {children}
      </div>
      <button className="card-preview-resume-item-button" onClick={handleClick}>
        Trocar
      </button>
    </div>
  );
};

type Props = {
  items: LineItem[],
  orderNumber: string,
  totalPrice: string,
  itemTotal: string,
  adjustmentTotal: number,
  shipTotal: string,
  availableStoreCredits: string,
  appliedStoreCredits: string,
  canSubmit: boolean,
  loading: boolean, // Transitioning
  blendsByOrderNumber: orderNumber => void,
  onSubmit: () => void,
  bigClubCreditAmount: number,
  useBigClubCreditAmount: boolean,
  bigClubInfo: BigClubInfo,
  transitionToPayment: () => void,
  transitionToAddress: () => void,
  transitionToDelivery: () => void,
  orderPayment: OrderPayment,
  orderDelivery: OrderDelivery,
  orderShippingAddress: OrderAddress,
};

const CartPreview = (props: Props) => {
  const appliedOrAppliableStoreCredits = Math.max(
    Math.min(props.availableStoreCredits, props.totalPrice),
    props.appliedStoreCredits,
  );
  const isAppliableBigClubCredit =
    (props.useBigClubCredit || props.bigClubInfo?.applied > 0) &&
    props.bigClubInfo?.available > 0;

  return (
    <section className="card-preview">
      <div className="card-preview-cart-items-container">
        <h3 className="card-preview-title">Resumo do pedido</h3>
        <div className="card-preview-cart-items-container-scroll">
          {props.items &&
            props.items.map((item, index) => (
              <CartItemPreview key={index} item={item} />
            ))}
        </div>
      </div>
      <div className="card-preview-divisor" />
      <div className="card-preview-info">
        <p className="card-preview-info-text">Preço estimado</p>
        <span className="card-preview-info-text-alt">
          {priceConverter(props.itemTotal)}
        </span>
      </div>

      <div className="card-preview-info">
        <p className="card-preview-info-text">Taxa de entrega</p>
        <span className="card-preview-info-text-alt">
          {priceConverter(props.shipTotal)}
        </span>
      </div>

      {Number(props.adjustmentTotal) > 0 && (
        <div className="card-preview-info">
          <p className="card-preview-info-text">Desconto</p>
          <span className="card-preview-info-text-alt">
            {priceConverter(parseFloat(Math.abs(props.adjustmentTotal)))}
          </span>
        </div>
      )}

      <div className="card-preview-info">
        <p className="card-preview-info-text">
          {isAppliableBigClubCredit ||
          Number(appliedOrAppliableStoreCredits) > 0
            ? 'Subtotal'
            : 'Total'}
        </p>
        <span className="card-preview-info-text-alt card-preview-info-text-orange">
          {priceConverter(parseFloat(Math.abs(props.totalPrice)))}
        </span>
      </div>

      {isAppliableBigClubCredit && props.bigClubCreditAmount > 0 ? (
        <>
          <div className="card-preview-info">
            <p className="card-preview-info-text">Créditos BigClub</p>
            <span className="card-preview-info-text-alt">
              {priceConverter(parseFloat(Math.abs(props.bigClubCreditAmount)))}
            </span>
          </div>
          <div className="card-preview-info">
            <p className="card-preview-info-text">Total</p>
            <span className="card-preview-info-text-alt card-preview-info-text-orange ">
              {priceConverter(
                parseFloat(
                  Math.abs(props.totalPrice - props.bigClubCreditAmount),
                ),
              )}
            </span>
          </div>
        </>
      ) : appliedOrAppliableStoreCredits &&
        Number(appliedOrAppliableStoreCredits) > 0 ? (
        <>
          <div className="card-preview-info">
            <p className="card-preview-info-text">Crédito</p>
            <span className="card-preview-info-text-alt">
              {priceConverter(
                parseFloat(Math.abs(appliedOrAppliableStoreCredits)),
              )}
            </span>
          </div>
          <div className="card-preview-info">
            <p className="card-preview-info-text">Total</p>
            <span className="card-preview-info-text-alt">
              {priceConverter(
                parseFloat(
                  Math.abs(props.totalPrice - appliedOrAppliableStoreCredits),
                ),
              )}
            </span>
          </div>
        </>
      ) : null}

      <div className="card-preview-divisor" />
      <section className="card-preview-resume">
        {props.orderShippingAddress && (
          <CartPreviewResumeItem
            imgUrl={addressIcon}
            alt="Ícone de endereço"
            children={<AddressSummary address={props.orderShippingAddress} />}
            handleClick={props.transitionToAddress}
          />
        )}
        {props.orderDelivery && (
          <CartPreviewResumeItem
            imgUrl={calendarIcon}
            alt="Ícone de calendário"
            children={<DeliverySummary delivery={props.orderDelivery} />}
            handleClick={props.transitionToDelivery}
          />
        )}
        {props.orderPayment && (
          <CartPreviewResumeItem
            imgUrl={paymentIcon}
            children={<PaymentSummary payment={props.orderPayment} />}
            alt="Ícone de pagamento"
            handleClick={props.transitionToPayment}
          />
        )}
      </section>
    </section>
  );
};

export default CartPreview;
