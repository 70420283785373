// @flow
import React from 'react';
import { connect } from 'react-redux';
// Styles
import './ProductListFavoriteButton.css';
// Assets
import heartFavorite from '../images/heart.svg';
import heartFavoriteOutline from '../images/heartOutline.svg';
// Selectors
import { userProductListItemSelector } from '../selectors';
// Types
import type { ProductListItem } from '../types';
// Actions
import { updateSelectedProductListItems } from '../actions';

type OwnProps = {
  variantId: string,
};

type Props = {
  isFavorite: boolean,
  updateSelectedProductListItems: (variantId: number) => void,
  item: ProductListItem,
  variantId: number,
};

const ProductListFavoriteButton = ({
  isFavorite = false,
  updateSelectedProductListItems,
  variantId,
}: Props) => {
  const onFavorite = () => {
    updateSelectedProductListItems(variantId);
  };
  return (
    <button
      className={`product-list-favorite-button ${
        isFavorite ? 'is-favorite' : ''
      }`}
      onClick={onFavorite}
    >
      <span role="img" aria-label="heart">
        {isFavorite ? (
          <img src={heartFavorite} alt="coração favoritado" />
        ) : (
          <img src={heartFavoriteOutline} alt="coração não favoritado" />
        )}
      </span>
    </button>
  );
};

const mapStateToProps = (state, ownProps: OwnProps) => {
  const item = userProductListItemSelector(state, ownProps.variantId);
  const isFavorite = !!item;
  return {
    isFavorite,
    item,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  return {
    updateSelectedProductListItems: variantId => {
      dispatch(updateSelectedProductListItems(variantId));
    },
  };
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default connector(ProductListFavoriteButton);
