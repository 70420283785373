// @flow
import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
// Selectors
import '../selectors';
// Actions
import '../actions';
// Components
import { userCreditCardsConnector } from '../../user';
import { PageLoader } from '../../common';
import CreditCardOption from '../components/payment/CreditCardOption';
// Types
import type { CreditCard } from '../types';
// Styles
import './CreditCardList.css';

type Props = {
  onClick: (card: CreditCard) => void,
  selectedCardId: ?number,
  // From connector
  creditCards: CreditCard[],
  creditCardsLoading: boolean,
};

class CreditCardList extends Component {
  constructor(props: Props) {
    super(props);

    // If we already have cards the one with the largest ID should be selected by default
    if (!_.isEmpty(props.creditCards)) {
      props.onClick(_.maxBy(props.creditCards, 'id'));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.creditCards, nextProps.creditCards)) {
      this.props.onClick(_.maxBy(nextProps.creditCards, 'id'));
    }
  }

  render() {
    if (this.props.creditCardsLoading) return <PageLoader />;

    // If there are no cards we should not show option for saved cards
    if (!this.props.creditCards || this.props.creditCards.length === 0) {
      return null;
    }

    return (
      <div>
        <h3 className="credit-card-list-title">Cartões salvos</h3>
        {this.props.creditCards
          ? this.props.creditCards.map(c => {
              const formattedYear = c.year < 2000 ? `20${c.year}` : `${c.year}`;
              const cardHasExpired = moment()
                .year(formattedYear)
                .month(c.month - 1)
                .endOf('month')
                .isBefore(moment(), 'month');

              return (
                <div
                  key={c.id}
                  onClick={() => !cardHasExpired && this.props.onClick(c)}
                  disabled={cardHasExpired}
                >
                  <CreditCardOption
                    creditCard={c}
                    active={c.id === this.props.selectedCardId}
                    cardHasExpired={cardHasExpired}
                    deleteCreditCard={this.props.deleteCreditCard}
                    deletingLoading={this.props.deletingLoading}
                  />
                </div>
              );
            })
          : null}
        {this.props.creditCards.length === 0 ? (
          <div className="credit-card-list-title">
            Nenhum cartão de crédito salvo.
          </div>
        ) : null}
      </div>
    );
  }
}

export default userCreditCardsConnector(CreditCardList);
