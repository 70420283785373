// @flow
import React, { useState } from 'react';
import './MyAccountPassword.css';
// Components
import {
  validationHelpers,
  InputFieldErrorMessage,
  FormButton,
  PageLoader,
} from '../../../common';
import { Link } from 'react-router-dom';
// assets
import close_icon from '../../images/close-icon.svg';

type Props = {
  updatingLoading: boolean,
  error: boolean,
  onSubmit: (
    oldPassword: string,
    newPassword: string,
    newPasswordConfirmation: string,
  ) => void,
  onClose: () => void,
};

const PasswordStrengthTextAndColor = ({ passwordStrength }) => {
  if (passwordStrength === 'strong') {
    return (
      <span className="account-password-strength-text">
        Força da senha:{' '}
        <span className="account-password-strength-strong"> Forte </span>{' '}
      </span>
    );
  } else if (passwordStrength === 'average') {
    return (
      <span className="account-password-strength-text">
        Força da senha:{' '}
        <span className="account-password-strength-medium"> Média </span>{' '}
      </span>
    );
  } else if (passwordStrength === 'weak') {
    return (
      <span className="account-password-strength-text">
        Força da senha:{' '}
        <span className="account-password-strength-poor"> Fraca </span>{' '}
      </span>
    );
  } else {
    return <span> </span>;
  }
};

const MyAccountPassword = (props: Props) => {
  const [passwordStrength, setPasswordStrength] = useState<string>('');
  const [badOldPassword, setBadOldPassword] = useState<string>('');
  const [badPassword, setBadPassword] = useState<string>('');
  const [passwordConfirmationError, setPasswordConfirmationError] =
    useState<string>('');

  // Sets passwordStrength based on the characteristics of the password
  const calculatePasswordStrength = (password: string) => {
    if (password === '') setPasswordStrength('');
    else if (password.length < 8) setPasswordStrength('weak');
    else if (/^[a-zA-Z0-9- ]*$/.test(password) === false)
      setPasswordStrength('strong');
    else if (password.length > 15) setPasswordStrength('strong');
    else setPasswordStrength('average');
  };

  // Validates the form on submission. If there are no validation errors, dispatches the action.
  const validateUserInput = (
    event: any,
    oldPassword: string,
    password: string,
    confirmationPassword: string,
  ) => {
    event.preventDefault();

    let noValidationErrors: boolean = true;

    const badOldPassword = validationHelpers.user.password(oldPassword);
    setBadOldPassword(badOldPassword);
    if (badOldPassword) {
      noValidationErrors = false;
    }

    const badPassword = validationHelpers.user.password(password);
    setBadPassword(badPassword);
    if (badPassword) {
      noValidationErrors = false;
    }

    const confirmationError = validationHelpers.user.passwordConfirmation(
      password,
      confirmationPassword,
    );
    setPasswordConfirmationError(confirmationError);
    if (confirmationError) {
      noValidationErrors = false;
    }

    if (noValidationErrors) {
      props.onSubmit(oldPassword, password, confirmationPassword);
    } else {
      console.error('Validation error on registration form.');
    }
  };

  if (props.loading) {
    return <PageLoader />;
  }

  return (
    <div className="my-account-password-main">
      <div className="my-account-password-header">
        <h3>Trocar Senha</h3>
        <button
          className="my-account-password-close-button"
          onClick={props.onClose}
        >
          <img src={close_icon} alt="Icone Para Fechar"></img>
        </button>
      </div>
      <form
        onSubmit={e => {
          e.preventDefault();
          validateUserInput(
            e,
            e.target.oldPassword.value,
            e.target.newPassword.value,
            e.target.newPasswordConfirmation.value,
          );
        }}
      >
        <div className="my-account-password-form">
          <InputFieldErrorMessage message={badOldPassword} />
          <input
            name="oldPassword"
            type="password"
            className="my-account-password-large-input"
            placeholder="Digite sua senha atual"
          />

          {passwordStrength !== '' ? (
            <PasswordStrengthTextAndColor passwordStrength={passwordStrength} />
          ) : null}

          <InputFieldErrorMessage message={badPassword} />
          <input
            name="newPassword"
            type="password"
            className="my-account-password-large-input"
            placeholder="Digite sua nova senha"
            onChange={e => calculatePasswordStrength(e.target.value)}
          />

          <InputFieldErrorMessage message={passwordConfirmationError} />
          <input
            name="newPasswordConfirmation"
            type="password"
            className="my-account-password-large-input"
            placeholder="Digite a senha novamente"
          />
        </div>
        <div className="my-account-password-submit-content">
          <div className="my-account-password-forgot-password">
            <Link to="/esqueci-senha/">Esqueci a senha</Link>
          </div>
          <div className="my-account-password-submit-button">
            <FormButton
              text={'Salvar senha'}
              icon=""
              buttonType={'form-button-quarternary'}
              loading={props.updatingLoading}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default MyAccountPassword;
