// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';
// Selector
import { indexDepartmentsSelector } from '../selectors';
// Actions
import { findDepartmentsIndex } from '../actions';
// Components
import NavList from '../components/NavList';
// Types
import type { Department } from '../types';

type Props = {
  departments: Department[],
  getDepartments: () => void,
};

class DepartmentsNavList extends Component {
  constructor(props: Props) {
    super(props);

    if (props.departments.length === 0) {
      props.getDepartments();
    }
  }
  render() {
    return <NavList departments={this.props.departments} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    departments: indexDepartmentsSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    getDepartments: () => {
      dispatch(findDepartmentsIndex());
    },
  };
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default connector(DepartmentsNavList);
