// @flow
import React, { Component } from 'react';
//Styles
import './AddAfterExistsButton.css';

type Props = {
  currentQuantity: number,
  unitForQuantity: 'g' | 'un',
  loading: boolean,
  disableDecreaseQuantity?: boolean,
  disableIncrementQuantity?: boolean,
  decreaseQuantity: () => void,
  increaseQuantity: () => void,
};

class AddAfterExistsButton extends Component {
  props: Props;
  render() {
    return (
      <div className="add-after-exists-button-main">
        <button
          onClick={this.props.decreaseQuantity}
          disabled={this.props.disableDecreaseQuantity}
        >
          <i className="fa fa-minus" aria-hidden="true"></i>
        </button>
        <div className="add-after-exists-button-content">
          {this.props.unitForQuantity === 'g' ? (
            <>
              <p className="add-after-exists-button-content-value">
                {(this.props.currentQuantity / 1000).toLocaleString('pt-br', {
                  currency: 'BRL',
                })}
              </p>
              <span className="add-after-exists-button-content-variant">
                Kg
              </span>
            </>
          ) : (
            <>
              <p className="add-after-exists-button-content-value">
                {this.props.currentQuantity}
              </p>
              <span className="add-after-exists-button-content-variant">
                un
              </span>
            </>
          )}
        </div>
        <button
          onClick={this.props.increaseQuantity}
          disabled={this.props.disableIncrementQuantity}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
        </button>
      </div>
    );
  }
}

AddAfterExistsButton.defaultProps = {
  disableDecreaseQuantity: false,
  disableIncrementQuantity: false,
};

export default AddAfterExistsButton;
