// @flow
import { combineReducers } from 'redux';
import advertising from '../advertising';
import barbecue from '../barbecue';
import blends from '../blend';
import posts from '../blog';
import order from '../current-order';
import departments from '../departments';
import home from '../home';
import pastOrders from '../past-orders';
import preferences from '../preferences';
import products from '../products';
import recipes from '../recipes';
import { reducer as search } from '../search';
import user from '../user';
const rootReducer = combineReducers({
  products,
  order,
  user,
  search,
  pastOrders,
  recipes,
  departments,
  posts,
  blends,
  barbecue,
  home,
  advertising,
  preferences,
});

export default rootReducer;
