// @flow
import { handleActions, combineActions } from 'redux-actions';
import { updateSearchState, clearSearchParams } from '../actions';

// If the query has changed we should erase the refinement list as not doing so will cause no results to be returned.
export default handleActions(
  {
    [combineActions(updateSearchState)]: {
      next: (state, action) => {
        if (state.query && state.query !== action.payload.query) {
          const {
            refinementList,
            ...payloadWithoutRefinementList
          } = action.payload;
          return payloadWithoutRefinementList;
        }
        // With refinements
        return action.payload;
      },
      throw: (state, action) => state,
    },
    // Resets the reducer to its initialState
    [clearSearchParams]: {
      next: (state, action) => {
        return {};
      },
      throw: (state, action) => state,
    },
  },
  {},
);
