// @flow
import { schema } from 'normalizr';

// Items that belong to a blend template
export const blendTemplateItem = new schema.Entity('blendTemplateItems');
export const blendTemplateItems = new schema.Array(blendTemplateItem);

// Blend Template
export const blendTemplate = new schema.Entity('blendTemplates', {
  items: [blendTemplateItem],
});
export const blendTemplates = new schema.Array(blendTemplate);

// Blend
export const blend = new schema.Entity('blends');
export const blends = new schema.Array(blend);
