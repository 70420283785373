// @flow
import React from 'react';
import _ from 'lodash';
// Helpers
import { priceConverter } from '../../common';
// Style
import './OrderTotal.css';

type Props = {
  lineItems: LineItem[],
  shipTotal: number,
  adjustmentTotal: string,
  availableStoreCredits: string,
  appliedStoreCredits: string,
  appliedBigClubCredits: number,
  fulfillmentState: string,
  producedAmount: string,
  itemTotal: number,
};

const OrderTotal = (props: Props) => {
  const lineItemTotal =
    props.fulfillmentState !== 'ready' ? props.itemTotal : props.producedAmount;

  const finalOrderTotalBasedOnWeighedItems =
    parseFloat(lineItemTotal) +
    parseFloat(props.shipTotal) +
    parseFloat(props.adjustmentTotal) -
    parseFloat(props.appliedStoreCredits) -
    parseFloat(props.appliedBigClubCredits);

  return (
    <div className="order-total-main">
      <hr className="order-total-line" />
      <div className="order-total-main-container">
        <div>
          <p>Total dos itens:</p>
          <p>Custo de entrega:</p>
          {(Number(props.adjustmentTotal) && <p>Descontos:</p>) || null}
          {(Number(props.appliedStoreCredits) && <p>Crédito:</p>) || null}
          {(Number(props.appliedBigClubCredits) && <p>Crédito BigClub:</p>) ||
            null}
          <p className="order-total-final-price">{`Subtotal${
            props.fulfillmentState !== 'ready' ? ' (estimado)' : ''
          }:`}</p>
        </div>

        <div>
          <h3>{priceConverter(lineItemTotal)}</h3>
          <h3
            className={!Number(props.shipTotal) ? 'order-total-green-text' : ''}
          >
            {Number(props.shipTotal)
              ? priceConverter(props.shipTotal)
              : 'Grátis'}
          </h3>
          {(Number(props.adjustmentTotal) && (
            <h3 className="order-total-green-text ">
              {priceConverter(props.adjustmentTotal)}
            </h3>
          )) ||
            null}
          {(Number(props.appliedStoreCredits) && (
            <h3 className="order-total-green-text ">
              - {priceConverter(props.appliedStoreCredits)}
            </h3>
          )) ||
            null}
          {(Number(props.appliedBigClubCredits) && (
            <h3 className="order-total-green-text ">
              - {priceConverter(props.appliedBigClubCredits)}
            </h3>
          )) ||
            null}
          <h3 className="order-total-final-price">
            {priceConverter(_.max([finalOrderTotalBasedOnWeighedItems, 0.0]))}
          </h3>
        </div>
      </div>
      {props.fulfillmentState !== 'ready' && (
        <p className="order-total-alert-weigh">
          * Atualizaremos o total do seu pedido, assim que todos os itens forem
          pesados.
        </p>
      )}
    </div>
  );
};

export default OrderTotal;
