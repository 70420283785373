import banrisul from '../images/banrisul.svg';
import elo from '../images/elo.svg';
import goodcard from '../images/goodCard.svg';
import hipercard from '../images/hipercard.svg';
import mastercard from '../images/mastercard.svg';
import visa from '../images/visa.svg';

export const payments = [
  {
    src: visa,
    alt: 'visa',
  },
  {
    src: elo,
    alt: 'elo',
  },
  {
    src: banrisul,
    alt: 'banrisul',
  },
  {
    src: hipercard,
    alt: 'hipercard',
  },
  {
    src: mastercard,
    alt: 'mastercard',
  },
  {
    src: goodcard,
    alt: 'goodcard',
  },
];
