// @flow
import React from 'react';

import { Link } from 'react-router-dom'

import './SimpleLogo.css';

import defaultLogo from '../images/LogoHorizontal.png';

type Props = {
  logo: string,
};

const SimpleLogo = ({logo}: Props) => {
  return(
    <Link to="" className="simple-logo-main"><img className="simple-logo-image" alt="Big Delivery Simple Logo" src={logo ? logo : defaultLogo} /></Link>
  );
}

export default SimpleLogo;
