//@flow
import React from 'react';
//Components
import { Link } from 'react-router-dom';
//Styles
import './DepartmentCards.css';
//Types
import type { Department } from '../../departments/types';

type Props = {
  departmentsData: Array<Department>,
};

const DepartmentCards = ({ departmentsData = [] }: Props) => {
  const departments = departmentsData.slice(0, 5).sort(d => d.position);

  return (
    <div className="department-cards-container">
      {departments.length > 0 && (
        <div className="department-cards-wrapper">
          {departments.map((d, i) => (
            <Link
              to={`/departamentos/${d.id}/`}
              key={i}
              className="department-cards-item"
            >
              <img
                className="department-cards-item-img"
                src={d.imageUrl}
                alt={d.name}
                draggable={false}
              />
              <p className="department-cards-item-description">
                {d.name.toLowerCase()}
              </p>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default DepartmentCards;
