// @flow
import _ from 'lodash';
import React from 'react';
// Components
import { Link } from 'react-router-dom';
// Types
import type { SubDepartment } from '../types';
// Styles
import './DepartmentHit.css';

type Props = {
  id: number,
  title: string,
  image: string,
  categories: SubDepartment[],
};

const DepartmentHit = (props: Props) => {
  const title =
    props.title[0].toUpperCase() + props.title.slice(1).toLowerCase();

  const categories = props.categories.slice(0, 4);

  return (
    <div className="department-hit-main">
      <Link to={`/departamentos/${props.id}/`}>
        <div className="department-hit-image-container">
          {props.image === null ? (
            <div className="department-placeholder" />
          ) : (
            <img
              className="department-hit-image"
              src={props.image}
              alt={`Departamento de ${_.lowerCase(props.title)}`}
            />
          )}
        </div>
      </Link>
      <div className="department-hit-text-group">
        <Link
          className="department-hit-title"
          to={`/departamentos/${props.id}/`}
        >
          <h3>{title}</h3>
        </Link>
        <hr className="department-hit-divisor" />
        {categories.map((category, i) => (
          <Link
            key={i}
            className="department-hit-entries"
            to={`/departamentos/${props.id}/${category.slug}/`}
          >
            {_.capitalize(category.name)}
          </Link>
        ))}
        {props.categories.length > 4 && (
          <div className="deparment-hit-see-more">
            <Link to={`/departamentos/${props.id}/`}> Ver mais &gt;</Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default DepartmentHit;
