// FROM https://gist.github.com/juliocesar/926500
function isSupported() {
  var item = 'localStoragePollyfill';
  try {
    localStorage.setItem(item, item);
    localStorage.removeItem(item);
    sessionStorage.setItem(item, item);
    sessionStorage.removeItem(item);
    return true;
  } catch (e) {
    return false;
  }
}

if (!isSupported()) {
  try {
    Storage.prototype._data = {};

    Storage.prototype.setItem = function(id, val) {
      return (this._data[id] = String(val));
    };

    Storage.prototype.getItem = function(id) {
      return this._data.hasOwnProperty(id) ? this._data[id] : undefined;
    };

    Storage.prototype.removeItem = function(id) {
      return delete this._data[id];
    };

    Storage.prototype.clear = function() {
      return (this._data = {});
    };
  } catch (e) {
    console.log('localStorage pollyfill error');
  }
}

// If still not supported
if (!isSupported()) {
  window.localStorage = {
    _data: {},
    setItem: function(id, val) {
      return (this._data[id] = val);
    },
    getItem: function(id) {
      return this._data[id];
    },
    removeItem: function(id) {
      return delete this._data[id];
    },
    clear: function() {
      return (this._data = {});
    },
  };

  window.sessionStorage = {
    _data: {},
    setItem: function(id, val) {
      return (this._data[id] = val);
    },
    getItem: function(id) {
      return this._data[id];
    },
    removeItem: function(id) {
      return delete this._data[id];
    },
    clear: function() {
      return (this._data = {});
    },
  };
}
