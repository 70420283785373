import { schema } from 'normalizr';

export const product = new schema.Entity('products');
export const selectedVariant = new schema.Entity('selectedVariants', {
  variant: product,
});
export const calculatorCategory = new schema.Entity('calculatorCategories');
export const calculatorCategories = new schema.Array(calculatorCategory);
export const calculatorOptionValue = new schema.Entity(
  'calculatorOptionValues',
);
export const calculatorOptionsValue = new schema.Array(calculatorOptionValue);
export const barbecue = new schema.Entity('barbecues', {
  desiredCategories: [calculatorCategory],
  selectedVariants: [selectedVariant],
});
export const barbecues = new schema.Array(barbecue);
