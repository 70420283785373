// @flow
import React from 'react';
// Styles
import './ContactUs.css';
// Images
import PorkStamp from '../images/porkOnSkate.png';
// Components
import { Helmet } from 'react-helmet';
import WhatsappButton from '../../common/components/WhatsappButton';
// Other
import { PageTitleWithGoBack, phone } from '../../common';
// Assets
import butchersImage from '../images/butchersImage.png';
import facebook from '../images/facebook.svg';
import instagram from '../images/instagram.svg';
import linkedin from '../images/linkedin.svg';
import tiktok from '../images/tiktok.svg';

const socialIcons = [
  {
    src: instagram,
    alt: 'instagram bigdelivery',
    link: ' https://www.instagram.com/bigdeliverybr',
  },
  {
    src: facebook,
    alt: 'facebook bigdelivery',
    link: 'https://www.facebook.com/bigdeliverybr',
  },
  {
    src: tiktok,
    alt: 'tiktok bigdelivery',
    link: 'https://www.tiktok.com/@big.delivery',
  },
  {
    src: linkedin,
    alt: 'linkedin bigdelivery',
    link: 'https://www.linkedin.com/company/bigdelivery',
  },
];

const ContactUs = () => {
  return (
    <div className="contact-us-main">
      <Helmet>
        <title>Contato - Bigdelivery - Seu açougue virtual</title>
        <meta
          property="og:title"
          content="Contato - Bigdelivery - Seu açougue virtual"
        />
        <meta
          name="description"
          content={`Para central de relacionamento e vendas ligue para ${phone.asStringFull}.`}
        />
        <meta
          name="og:description"
          content={`Para central de relacionamento e vendas ligue para ${phone.asStringFull}.`}
        />
        <meta property="og:image" content={PorkStamp} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:alt" content="Porco no skate." />
      </Helmet>
      <PageTitleWithGoBack backTo="/" title="Entre em Contato" />
      <div className="contact-us-main-content">
        <div className="contact-us-left-column">
          <div className="contact-us-left-column-item">
            <h3>Central de relacionamento e vendas</h3>
            <p>
              Para qualquer dúvida, sugestão ou reclamação, nossa equipe de
              atendimento está à disposição através do telefone{' '}
              <a href={`tel:${phone.e164}`}>{phone.asStringFull}</a>.
            </p>
          </div>
          <div className="contact-us-left-column-item">
            <h3>E-mail</h3>
            <p>
              Caso prefira entrar em contato por e-mail, envie sua mensagem para{' '}
              <a
                href="mailto:sac@bigdelivery.com.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                sac@bigdelivery.com.br
              </a>
              . Responderemos o mais breve possível.
            </p>
          </div>
          <div className="contact-us-left-column-item">
            <h3>Redes sociais</h3>
            <p>
              Não esqueça de nos seguir nas redes sociais para ficar por dentro
              das novidades e promoções.
            </p>
            <div className="contact-us-lef-column-socials">
              {socialIcons.map(({ link, alt, src }) => (
                <a href={link} target="_blank" rel="noopener noreferrer">
                  <img alt={alt} src={src} />
                </a>
              ))}
            </div>
          </div>
          <div className="contact-us-left-column-item-alt">
            <WhatsappButton />
          </div>
        </div>
        <img
          src={butchersImage}
          alt="Açougueiros do BigDelivery"
          className="contact-us-image"
        />
      </div>
    </div>
  );
};

export default ContactUs;
