// @flow
import React, { useEffect } from 'react';
import _ from 'lodash';
// Components
import { PageLoader } from '../../../common';
import CartBigClubPreview from '../../../current-order/components/CartBigClubPreview';
import { NavLink } from 'react-router-dom';
import AddressItem from './MyAccountAddressCard';
// Types
import { BigClubInfo } from '../../../current-order/types';
import type { Address } from '../types';
import type { Order } from '../../../types';
// Styles
import './MyAccountOverview.css';
// Assets
import user_icon from '../../images/user-icon.svg';
import pencil_icon from '../../images/pencil-icon.svg';
// Helpers
import {
  priceConverter,
  formatDateAsStringWithOption,
  orderTrackerStateName,
} from '../../../common';

type Props = {
  information: {
    firstName: string,
    lastName: string,
    cpf: string,
    email: string,
  },
  loading: boolean,
  editingLoading: boolean,
  onSubmitUpdate: (
    firstName: string,
    lastName: string,
    cpf: string,
    email: string,
  ) => void,
  bigClubInfo?: BigClubInfo,
  order: Order,
  addresses: Address[],
  getPastOrder: (orderNumber: string) => void,
};

const MyAccountOverview = (props: Props) => {
  const { firstName, lastName, email } = props.information;
  useEffect(() => {
    props.getPastOrder(props.order.number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const payment = _.last(props.order.payments);
  if (props.loading) {
    return <PageLoader />;
  }

  const trackerState = orderTrackerStateName(
    props.order.fulfillmentState,
    props.order.deliveryState,
  );

  return (
    <div>
      <div className="my-account-overview-main-container">
        <div className="my-account-overview-top-content">
          <div className="my-account-overview-user-information-container">
            <div className="my-account-overview-user-information-content">
              <h4>
                <strong>
                  Bem Vindo, {firstName} {lastName}
                </strong>
              </h4>
              <p className="my-account-overview-user-email-container">
                <img src={user_icon} alt="Icone de usuário"></img>
                {email}
              </p>
            </div>
            <NavLink to="/minha-conta/informacoes">
              <img src={pencil_icon} alt="Botão para seção de Meus Dados"></img>
            </NavLink>
          </div>
          <CartBigClubPreview
            bigClubInfo={props.bigClubInfo}
            className="cat-page-cart-big-club-preview-small-screen"
          />
        </div>

        <div className="my-account-overview-low-content">
          <h4>Resumo do último pedido</h4>
          <hr></hr>
          <div className="my-account-overview-order-resume-container">
            <div className="my-account-overview-order-number">
              <p>Número do pedido</p>
              <h6>#{props.order.number}</h6>
            </div>
            <div className="my-account-overview-order-state">
              <p>Status</p>
              <h6>{trackerState}</h6>
            </div>
            <div className="my-account-overview-order-date">
              <p>Data e Horário</p>
              <h6>{formatDateAsStringWithOption(props.order.createdAt)}</h6>
            </div>
            <div className="my-account-overview-order-payment-method">
              <p>Forma de Pagamento</p>
              {payment && (
                <div className="past-order-information-sub-container">
                  {payment.paymentMethod.id === 1 ? (
                    <div className="past-order-information-payment-method">
                      <h6>{payment.paymentMethod.name} </h6>
                      <p>XXX XXX XXX {payment.source.lastDigits} </p>
                      <p>{payment.source.name} </p>
                    </div>
                  ) : (
                    <div className="past-order-information-payment-method">
                      <h6>{payment.paymentMethod.name} </h6>
                      {Number(props.order.appliedStoreCredits) > 0 &&
                        payment.paymentMethod.name !== 'Crédito' && (
                          <p>
                            Crédito{' '}
                            {priceConverter(props.order.appliedStoreCredits)}
                          </p>
                        )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <hr></hr>
          <div className="my-account-overview-order-address-container">
            <h4>Endereço</h4>
            <AddressItem
              key={props.order.shipAddress.id}
              address={props.order.shipAddress}
              showActions={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccountOverview;
