// @flow
import React from 'react';
// Styles
import './PaymentSummary.css';
// Types
import { OrderPayment } from '../../types';

type Props = {
  payment: OrderPayment,
};

const PaymentSummary = (props: Props) => {
  const { paymentSource, paymentMethod } = props.payment;
  return (
    <div className="payment-summary-main">
      <p>{paymentMethod?.name}</p>
      {paymentSource && (
        <p>
          {paymentSource?.ccType?.toUpperCase()} {paymentSource?.lastDigits}
        </p>
      )}
    </div>
  );
};
export default PaymentSummary;
