// @flow
import React from 'react';
// Connector
import { withRouter } from 'react-router-dom';
// Styles
import './WishlistCover.css';
// Assets
import penIcon from '../../images/penIcon.svg';
import trashIcon from '../../images/trashIcon.svg';
// Components
import { Link } from 'react-router-dom';
import { Button, ProgressiveImageLoader } from '../../../common';
// Helpers
import { priceConverter } from '../../../common';
// Types
import type { ProductListResume } from '../../types';
import type { ProductImage } from '../../../types';
// Analytics
import { logUserTappedCopyCustomList } from '../../../common/analytics';

type Props = {
  id: number,
  name: string,
  totalItems: number,
  thumbnails: ProductImage,
  totalItemsInPromotion: number,
  totalPrice: string | number,
  totalListPrice: string | number,
  itemsResume: ProductListResume['itemsResume'][],
  handleDelete: (listId: number) => void,
  addProductListToCart: (orderNumber: number, listId: number) => void,
  currentOrderNumber: string,
  addingProductListToCart: boolean,
  history: object,
};

const WishlistCover = ({
  id,
  name,
  totalItems,
  thumbnails,
  totalItemsInPromotion,
  totalPrice,
  totalListPrice,
  itemsResume,
  handleDelete,
  addProductListToCart,
  currentOrderNumber,
  addingProductListToCart,
  history,
}: Props) => {
  const listPrice = priceConverter(totalListPrice);
  const price = priceConverter(totalPrice);
  const isInPromotion = listPrice > price;

  const slicedItemsResume = itemsResume.slice(0, 2);
  return (
    <div className="wishlist-cover-wrapper">
      <button
        className="wishlist-action-header-button-alt"
        onClick={() => {
          handleDelete(id);
        }}
      >
        <img src={trashIcon} alt="Deletar lista" />
      </button>
      <Link
        className="wishlist-cover-row"
        to={`/lista-personalizada/${name}/${id}`}
      >
        <div className="wishlist-cover-image">
          <ProgressiveImageLoader
            selectedImage={thumbnails[0]}
            imageToShow="product"
          />
        </div>
        <section className="wishlist-cover">
          <div className="wishlist-cover-header">
            <div className="wishlist-cover-header-button">
              <h3 className="wishlist-cover-header-title">{name}</h3>
              <img src={penIcon} alt="Ícone de caneta" />
            </div>
          </div>
          <div className="wishlist-cover-info">
            <p className="wishlist-cover-info-description">
              {totalItems} produtos
            </p>
            {!!totalItemsInPromotion && (
              <div className="wishlist-cover-info-badge">
                <span className="wishlist-cover-info-badge-text">
                  {totalItemsInPromotion}{' '}
                  {totalItemsInPromotion > 1 ? 'produtos' : 'produto'} em
                  promoção
                </span>
              </div>
            )}
          </div>
          <div className="wishlist-resume">
            <span className="wishlist-resume-title">Contém nessa lista:</span>
            <ul className="wishlist-resume-list">
              {slicedItemsResume.map(item => (
                <li
                  className={`wishlist-resume-list-item wishlist-resume-list-item-promotion`}
                  key={item.id}
                >
                  {item.variantName}
                </li>
              ))}
            </ul>
          </div>
        </section>
      </Link>
      <section className="wishlist-action">
        <div className="wishlist-action-header">
          <button
            className="wishlist-action-header-button"
            onClick={() => {
              handleDelete(id);
            }}
          >
            <img src={trashIcon} alt="Deletar lista" />
          </button>
        </div>
        <div className="wishlist-action-info">
          <span className="wishlist-action-info-small-text">
            Preço total da lista
          </span>
          <span
            className={`wishlist-action-info-big-text ${
              isInPromotion ? 'wishlist-action-info-promotion-text' : ''
            }`}
          >
            {price}
          </span>
          {isInPromotion && (
            <span className="wishlist-action-info-promotion-text-price">
              {listPrice}
            </span>
          )}
        </div>
        <Button
          text="Adiconar ao carrinho"
          disabled={addingProductListToCart}
          loading={addingProductListToCart}
          onClick={event => {
            event.stopPropagation();
            logUserTappedCopyCustomList(name);
            addProductListToCart(currentOrderNumber, id);
          }}
          buttonType="button-secondary"
        />
      </section>
    </div>
  );
};

export default withRouter(WishlistCover);
