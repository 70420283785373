// Styles
import './BlackFridayFrequentlyQuestions.css';
// Components
import QuestionsAndAnswers from './QuestionsAndAnswers.js';

type FaqItem = {
  question: string,
  answer: string,
};

type Props = {
  data: FaqItem[],
};

const Faq = ({ data }: Props) => {
  return (
    <div className="black-friday-questions-main-container">
      <h1 className="black-friday-quetions-text">
        Dúvidas{' '}
        <span className="black-friday-questions-orange-text">frequentes</span>
      </h1>

      {data.map((faq, index) => (
        <QuestionsAndAnswers
          key={index}
          question={faq.question}
          answer={faq.answer}
        />
      ))}
    </div>
  );
};
export default Faq;
