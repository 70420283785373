// @flow
import React, { useEffect } from 'react';
// Connectors
import { displayVariantsByPageAndSectionConnector } from '../../products';
// Components
import ChristmasProductList from './ChristmasProductList';
// Types
import type { Product } from '../../products/types/index';

type Props = {
  findDisplayVariantsByPageAndSection: (
    page: string,
    section: string,
  ) => Product[],
  productByPageAndSection: (page: string, section: string) => Product[],
};

const ChristmasProductSlider = ({
  productByPageAndSection,
  findDisplayVariantsByPageAndSection,
}: Props) => {
  const sliderProductsVariants = productByPageAndSection(
    'merry_christmas',
    'slider',
  );
  const productsListVariants = productByPageAndSection(
    'merry_christmas',
    'product_list',
  );
  useEffect(() => {
    findDisplayVariantsByPageAndSection('merry_christmas');
    //eslint-disable-next-line
  }, []);

  return (
    <div id="christmas-product-slider">
      <ChristmasProductList
        title="OS MAIS PEDIDOS :"
        sliderProductsVariants={sliderProductsVariants}
        productsVariants={productsListVariants}
        currentLanding="merry_christmas"
      />
    </div>
  );
};
export default displayVariantsByPageAndSectionConnector(ChristmasProductSlider);
