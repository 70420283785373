//@flow
import React from 'react';
//Components
import { Link } from 'react-router-dom';
import OptionBox from './OptionBox';
//Styles
import './OptionsSelector.css';
//Types
import type { ProductOptions } from '../types';

type Props = {
  options: ProductOptions[],
  productPrice: string,
  productUnit: string,
};

const OptionsSelector = (props: Props) => {
  return (
    <div className="option-main">
      {props.options.map((option, i) => (
        <div className="option-wrapper" key={i}>
          <h4 className="option-name">{option.presentationName}</h4>
          <div className="option-container">
            {option.values.map((value, i) => (
              <Link
                key={i}
                className="option-link"
                to={`/produtos/${String(value.slug)}/`}
              >
                <OptionBox
                  productPrice={props.productPrice}
                  productUnit={props.productUnit}
                  optionPrice={value.price}
                  text={value.presentationName}
                  isSelected={value.selected}
                />
              </Link>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default OptionsSelector;
