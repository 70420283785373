// @flow
import React from 'react';
// Components
import PriceInformationNotice from '../../common/components/PriceInformationNotice';
import AddToCartButton from '../containers/AddToCartButton';
import BuyPrice from '../containers/BuyPrice';
import InfoCards from '../../products/components/InfoCards';
import CookingRecommedationBox from './CookingRecommedationBox';
import OptionsSelector from '../../products/components/OptionsSelector';
// Styles
import './BuyBox.css';
// Types
import type { Product } from '../../products/types';
// Data
import { infoCards } from '../../products/data/infoCardsData';
// Assets
import QuestionMarkIcon from '../images/question-mark.svg';

export type Props = {
  product: Product,
};

const BuyBox = ({ product }: Props) => {
  const productOnPromotion =
    parseFloat(product.listPrice) > parseFloat(product.price);

  return (
    <div className="buy-box-main">
      <div className="buy-box-title">
        <p className="buy-box-manufacturer">{product.manufacturer}</p>
        <h1 className="buy-box-product-name">{product.name}</h1>
      </div>
      <section className="buy-box-section">
        <CookingRecommedationBox
          cookingMethods={product.cookingRecommendations}
        />
        <OptionsSelector
          options={product.options}
          productPrice={product.price}
          productUnit={product.unitForQuantity}
        />

        <InfoCards cards={infoCards} />
        <div className="buy-box-section-price-container">
          <div className="buy-box-section-price-container-action">
            <AddToCartButton
              showNotifyMeButton={true}
              productId={product.id}
              slug={product.slug}
              unitForQuantity={product.unitForQuantity}
              defaultQuantity={product.defaultQuantity}
              incrementQuantity={product.incrementQuantity}
              inStock={product.inStock}
              discontinued={product.discontinued}
              shouldNotifyUser={product.shouldNotifyUser}
              analyticsEventType={'visit'} // USE ANALYTICS
            />

            {product.unitForQuantity !== 'un' && (
              <p className="buy-box-min-quantity">
                Quantidade mínima: &nbsp;
                {product.unitForQuantity === 'g'
                  ? (product.minimumQuantity / 1000).toLocaleString('pt-br', {
                      currency: 'BRL',
                    }) + ' Kg'
                  : product.minimumQuantity}
              </p>
            )}
          </div>
          <div className="buy-box-sub-container">
            <p className="buy-box-sub-container-title">Preço Estimado</p>
            <div className="buy-box-sub-container-wrapper">
              <img
                src={QuestionMarkIcon}
                alt="entenda o preco"
                className="buy-box-question-mark"
              />
              <div className="buy-box-understand-the-price">
                Entenda o preço
              </div>
              <div className="buy-box-price-content">
                <PriceInformationNotice />
              </div>
            </div>

            <BuyPrice
              productId={product.id}
              listPrice={product.listPrice}
              price={product.price}
              unit={product.unitForQuantity}
              showPromo={productOnPromotion}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default BuyBox;
