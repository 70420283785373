import React from 'react';
// Images
import ChickenStamp from '../images/ChickenInSpace.png';
import { LogoHorizontal } from '../../header';
// Helpers
import { logException } from '../../logHelper';
// Connectors
import { currentOrderNumberConnector } from '../../current-order';
import { IntercomAPI } from 'react-intercom';
// Styles
import './TopLevelErrorBoundary.css';
// Other
import { phone } from '../constants';

type Props = {
  currentOrderNumber: string,
};

class ErrorBoundary extends React.Component {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    logException(error, info);
  }

  render() {
    if (this.state.hasError) {
      // Open Intercom with an error message ready
      IntercomAPI(
        'showNewMessage',
        `Algo deu errado com o site. Gostaria de continuar o meu pedido ${
          this.props.currentOrderNumber ? this.props.currentOrderNumber : null
        }. [Clique na seta para enviar]`,
      );
      // You can render any custom fallback UI
      return (
        <div className="top-level-error-boundary-main">
          <div>
            <div className="top-level-error-boundary-logo-container">
              <img
                className="top-level-error-boundary-logo-image top-level-error-boundary-logo-animate"
                src={LogoHorizontal}
                alt=""
              />
            </div>
          </div>
          <div className="top-level-error-boundary-loader">
            <div className="top-level-error-boundary-sub-loader">
              <div className="top-level-error-boundary-loader-container">
                <img
                  className="top-level-error-boundary-loader-animate top-level-error-boundary-loader-image"
                  src={ChickenStamp}
                  alt=""
                />
              </div>
              <h3 className="top-level-error-boundary-loader-text">
                Ah, não! Algo deu errado. Por favor, tente novamente.
              </h3>
              <h3 className="top-level-error-boundary-loader-text">
                Caso o erro se repita, entre em contato no {phone.asStringFull}{' '}
                ou sac@bigdelivery.com.br.
              </h3>
              <h3 className="top-level-error-boundary-loader-text">
                Nossa equipe técnica foi notificada desse erro e iremos
                investigar.{' '}
                {this.props.currentOrderNumber
                  ? `Número do pedido: ${this.props.currentOrderNumber}.`
                  : null}
              </h3>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default currentOrderNumberConnector(ErrorBoundary);
