// @flow
import React, { useState } from 'react';
//Styles
import './RemoveProductButton.css';
// Types
import { LineItem } from '../../current-order/types';

type Props = {
  loading: boolean,
  clickable?: boolean,
  handleRemoveItem: (lineItemId: number) => void,
  item: LineItem,
};

const RemoveProductButton = ({
  loading,
  clickable,
  handleRemoveItem,
  item,
}: Props) => {
  const [isClickable, setIsClickable] = useState(
    clickable !== undefined ? clickable : true,
  );

  return (
    <>
      {loading ? (
        <div className="remove-product-button">
          <i className="fa fa-spinner fa-pulse" />
        </div>
      ) : (
        <button
          className="remove-product-button"
          onClick={() => {
            if (isClickable) {
              handleRemoveItem();
            }
          }}
        >
          <div className="remove-product-button-text">
            <p>Remover</p>
          </div>
        </button>
      )}
    </>
  );
};

export default RemoveProductButton;
