// @flow
import React, { useEffect, useState } from 'react';
// Hooks
import { useSessionStorage } from '../../common';
// Styles
import './RefreshModal.css';
// Components
import Button from './Button';

const RefreshModal = () => {
  const [initialMoment, setInitialMoment] = useSessionStorage(
    'refresh_moment',
    Date.now().toString(),
  );
  const [countdown, setCountdown] = useState(() => {
    const sixHoursInSeconds = 60 * 60 * 6;

    const remainingSeconds = !!initialMoment
      ? Math.max(
          sixHoursInSeconds - (Date.now() - parseInt(initialMoment)) / 1000,
          0,
        )
      : sixHoursInSeconds;
    return remainingSeconds;
  });

  const onRefresh = () => {
    setInitialMoment(Date.now().toString());
    window.location.reload();
  };

  useEffect(() => {
    const updateCountdown = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);

    return () => {
      clearInterval(updateCountdown);
      setInitialMoment('');
    };
    // eslint-disable-next-line
  }, []);

  if (countdown > 0) return null;

  return (
    <div className="refresh-modal-wrapper">
      <div className="refresh-modal-container">
        <div className="refresh-modal-content">
          <h3 className="refresh-modal-content-title">
            Algumas informações podem estar desatualizadas
          </h3>
          <p className="refresh-modal-content-text">
            Para assegurar que você esteja visualizando os dados mais recentes,
            recomendamos recarregar a página. Isso garantirá uma experiência
            atualizada e precisa.
          </p>
          <Button
            text="Recarregar página"
            onClick={onRefresh}
            buttonType="button-secondary"
          />
        </div>
      </div>
    </div>
  );
};

export default RefreshModal;
