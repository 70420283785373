// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// Router
import { withRouter } from 'react-router-dom';
// Components
import { ControlledModal } from '../../common';
// Types
import { IPopup } from '../types/popup';
// Selectors
import { popupByPathSelector } from '../selectors';
// Actions
import { fetchAllPopups, postMarkPopupAsSeen } from '../actions';
// Analytics
import {
  logAdvertisingPopupDismissed,
  logAdvertisingPopupSeen,
  logAdvertisingPopupTapped,
} from '../../common/analytics';

type Props = {
  getPopups: () => void,
  location: object,
  postMarkPopupAsSeen: (id: number) => void,
  currentPopup: IPopup,
};

const AdvertisingPopup = ({
  getPopups,
  postMarkPopupAsSeen,
  currentPopup,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAction = () => {
    logAdvertisingPopupTapped();
    setIsModalOpen(false);
  };

  const onDismiss = () => {
    logAdvertisingPopupDismissed();
    setIsModalOpen(false);
  };

  useEffect(() => {
    getPopups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Whenever the selector returns a new popup to display we should show it, mark the previous popup as seen and send analytics
  useEffect(() => {
    if (!currentPopup.id) return;
    setIsModalOpen(true);
    logAdvertisingPopupSeen();
    postMarkPopupAsSeen(currentPopup.id);
    //eslint-disable-next-line
  }, [currentPopup.id]);

  return (
    <ControlledModal
      title={currentPopup.title}
      text={currentPopup.text}
      handleClose={onDismiss}
      isOpen={isModalOpen}
      buttonNavigateToUrl={currentPopup.desktopNavigateToUrl}
      imageUrl={currentPopup.imageUrl}
      imageAlt={currentPopup.imageAlt}
      handleAction={handleAction}
      buttonText={currentPopup.buttonText}
    />
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getPopups: () => dispatch(fetchAllPopups()),
    postMarkPopupAsSeen: id => dispatch(postMarkPopupAsSeen(id)),
  };
};
const mapStateToProps = (state, ownProps) => {
  return {
    currentPopup: popupByPathSelector(state, ownProps.location.pathname),
  };
};

const connector: Connector<RouterProps, ReduxProps> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default withRouter(connector(AdvertisingPopup));
