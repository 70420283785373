// @flow
import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
// Actions
import {
  fetchDepartmentsIndex,
  fetchDepartmentsIndexRequest,
  fetchDepartment,
  fetchDepartmentRequest,
} from '../actions';
import type { Reducer } from 'redux';
import type { Action } from '../../types';
import type { StoreState } from '../types';

// Current department Reference
const current: Reducer<string, Action> = handleActions(
  {
    [fetchDepartmentRequest]: {
      next: (state, action) => action.payload,
    },
    [fetchDepartment]: {
      next: (state, action) => action.payload.result,
      throw: (state, action) => state,
    },
  },
  0,
);

// Departments index reference
const index: Reducer<string, Action> = handleActions(
  {
    [fetchDepartmentsIndex]: {
      next: (state, action) => action.payload.result,
      throw: (state, action) => state,
    },
  },
  [],
);

// All departments saved
const departments: Reducer<{ [string]: any }, Action> = handleActions(
  {
    [fetchDepartmentsIndex]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.department,
      }),
      throw: (state, action) => state,
    },
    [fetchDepartment]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.department,
      }),
      throw: (state, action) => state,
    },
  },
  {},
);

// Loading
const initialLoadingState = {
  current: false,
  index: false,
};
const loading: Reducer<string, Action> = handleActions(
  {
    [fetchDepartmentsIndex]: (state, action) => ({ ...state, index: false }),
    [fetchDepartmentsIndexRequest]: (state, action) => ({
      ...state,
      index: true,
    }),
    [fetchDepartment]: (state, action) => ({ ...state, current: false }),
    [fetchDepartmentRequest]: (state, action) => ({ ...state, current: true }),
  },
  initialLoadingState,
);

const reducer: Reducer<StoreState, Action> = combineReducers({
  departments, // Keeps all departments
  // References to the departments
  current,
  index,
  // Loading
  loading,
});

export default reducer;
