// @flow
import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import 'moment/locale/pt-br';
import { getTimeFormat3l } from '../../common';
import { orderTrackerStateName } from '../../common/commonHelpers';
// Components
import { Link } from 'react-router-dom';
// Selectors
import { copyingOrderLoadingSelector } from '../../current-order/selectors';
//Conectors
// TODO: FIX THIS IMPORT!! For some reason cannot import from index.js
import currentOrderNumberConnector from '../../current-order/connectors/currentOrderNumberConnector';
// Actions
import { copyingOrder } from '../../current-order/actions';
// Styles
import './OrderLineItem.css';

type Props = {
  orderNumber: number,
  fulfillmentState: string,
  deliveryState: string,
  orderDate: string,
  orderQuantity: number,
  copyingOrderLoading: boolean,
};

class OrderLineItem extends Component {
  props: Props;

  handleClick = (orderNumber, orderNumberFrom) => {
    const conf = window.confirm(
      `Itens do pedido ${orderNumberFrom} serão adicionados ao seu carrinho (pedido ${orderNumber}). Deseja continuar?`,
    );

    if (conf) {
      this.props.copyingOrder(orderNumber, orderNumberFrom);
    }
  };

  render() {
    return (
      <div className="order-line-item-main">
        <section className="order-line-item-section">
          <span className="order-line-item-section-title">
            Número do pedido
          </span>
          <h6 className="order-line-item-section-text">
            #{this.props.orderNumber}
          </h6>
        </section>

        <section className="order-line-item-section">
          <span className="order-line-item-section-title">Status</span>
          <h6 className="order-line-item-section-text order-line-item-section-text-alt">
            {_.capitalize(
              orderTrackerStateName(
                this.props.fulfillmentState,
                this.props.deliveryState,
              ),
            )}
          </h6>
        </section>
        <section className="order-line-item-section">
          <span className="order-line-item-section-title">Data e horário</span>
          <h6 className="order-line-item-section-text">
            {this.props.orderDate
              ? getTimeFormat3l(this.props.orderDate)
              : '--'}
          </h6>
        </section>
        <section className="order-line-item-section">
          <span className="order-line-item-section-title">Produtos</span>
          <h6 className="order-line-item-section-text">
            {' '}
            {this.props.orderQuantity}{' '}
          </h6>
        </section>
        <section className="order-line-item-main-actions">
          <Link
            to={`/minha-conta/pedidos/${this.props.orderNumber}/`}
            className="order-line-order-see-more-button"
          >
            Ver mais
          </Link>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    copyingOrderLoading: copyingOrderLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    copyingOrder: (orderNumber: string, orderNumberFrom: string) => {
      dispatch(copyingOrder(orderNumber, orderNumberFrom));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default currentOrderNumberConnector(connector(OrderLineItem));
