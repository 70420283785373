// @flow
import React, { useState } from 'react';
// Components
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
// Styles
import './InputWithAddressAutocomplete.css';
// Helpers
import _ from 'lodash';
import { logException } from '../../logHelper';
// Types
import type { Address } from '../../types';

type Props = {
  value: string,
  onChange: (value: string) => void,
  onAddressSelect: (address: Address) => void,
  errorMessage: string,
};

const centerPointSalvador = { lat: -12.870492, lng: -38.4124 };
// Create a bounding box with sides ~20km away from the center point
const coordinatesTolerance = 0.3;
const defaultBounds = {
  north: centerPointSalvador.lat + coordinatesTolerance,
  south: centerPointSalvador.lat - coordinatesTolerance,
  east: centerPointSalvador.lng + coordinatesTolerance,
  west: centerPointSalvador.lng - coordinatesTolerance,
};

const getAddressDetails = (placesService, placeId) =>
  new Promise((resolve, reject) => {
    // Since this may be used in important locations add a protection against taking too long
    setTimeout(() => {
      resolve({});
    }, 1500);
    try {
      placesService.getDetails(
        {
          placeId: placeId,
        },
        placeDetails => {
          const address = {};

          placeDetails.address_components.forEach(element => {
            if (_.find(element.types, item => item === 'street_number')) {
              address.addressNumber = element.long_name;
            } else if (_.find(element.types, item => item === 'route')) {
              address.addressLine1 = `${element.long_name}${
                address.addressNumber ? ', ' + address.addressNumber : ''
              }`;
            } else if (
              _.find(element.types, item => item === 'sublocality') ||
              _.find(element.types, item => item === 'sublocality_level_1')
            ) {
              address.neighborhood = element.long_name;
            } else if (
              _.find(
                element.types,
                item => item === 'administrative_area_level_2',
              )
            ) {
              address.city = element.long_name;
            } else if (_.find(element.types, item => item === 'postal_code')) {
              address.zipcode = element.long_name.replace(/\D/g, '');
            }
          });

          resolve(address);
        },
      );
    } catch (e) {
      logException(e, null, 'warning');
      resolve({});
    }
  });

const InputWithAddressAutocomplete = ({
  value,
  onChange,
  onAddressSelect,
  errorMessage,
}: Props) => {
  const [showPlacesList, setShowPlacesList] = useState(false);
  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey: process.env.REACT_APP_GCP_API_KEY,
      options: {
        types: ['address'],
        bounds: defaultBounds,
        strictBounds: true,
        componentRestrictions: { country: 'br' },
      },
      debounce: 250,
    });

  const onPlaceSelect = async placeDescription => {
    if (!placeDescription) return;

    const address = await getAddressDetails(
      placesService,
      placeDescription.place_id,
    );

    setShowPlacesList(false);
    onAddressSelect(address);
  };

  const onInputFieldChange = event => {
    const value = event.target.value;

    getPlacePredictions({ input: value });
    setShowPlacesList(true);
    onChange(value);
  };

  return (
    <div className="input-with-address-autocomplete-container">
      <input
        value={value}
        type="text"
        options={{
          types: ['address'],
          bounds: defaultBounds,
          strictBounds: true,
          componentRestrictions: { country: 'br' },
        }}
        name="address"
        id="address"
        className="input-with-address-autocomplete"
        placeholder="Endereço, ex.: R. Altair Nogueira, 123"
        onChange={onInputFieldChange}
        // The delay here is needed so that the onBlur does not remove the placePrediction list before user is able to trigger onCLick
        onBlur={() => _.delay(() => setShowPlacesList(false), 250)}
      />

      <div className={'input-with-address-autocomplete-list'}>
        {showPlacesList && placePredictions.length
          ? placePredictions.map((placeDescription, index) => (
              <div
                key={index}
                className="input-with-address-autocomplete-list-item"
                onClick={() => onPlaceSelect(placeDescription)}
              >
                {placeDescription.description}
              </div>
            ))
          : null}
      </div>
      {!!errorMessage && (
        <span className="input-with-address-autocomplete-error">
          {errorMessage}
        </span>
      )}
    </div>
  );
};
export default InputWithAddressAutocomplete;
