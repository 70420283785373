// @flow
import { camelizeKeys } from 'humps';
import { schema } from 'normalizr';

export const address = new schema.Entity('address');
export const addresses = new schema.Array(address);

export const creditCard = new schema.Entity('creditCards');
export const creditCards = new schema.Array(creditCard);

export const allowedPaymentMethod = new schema.Entity('allowedPaymentMethods');
export const allowedPaymentMethods = new schema.Array(allowedPaymentMethod);

export const user = new schema.Entity(
  'user',
  {
    addresses: [address],
    creditCards: [creditCard],
    allowedPaymentMethods: [allowedPaymentMethod],
  },
  { idAttribute: 'email' },
);

export const invitedUser = new schema.Entity('invitedUsers');
export const invitedUsers = new schema.Array(invitedUser);

export const cashback = new schema.Entity('cashbacks');
export const cashbacks = new schema.Array(cashback);

export const productList = new schema.Entity('productList', {
  idAttribute: 'id',
});
export const productLists = new schema.Array(productList);

export const productListItem = new schema.Entity('productListsItems');
export const productListItems = new schema.Array(productListItem);

export const wishlist = new schema.Entity('wishlist');

export const order = new schema.Entity(
  'orders',
  {
    lineItems: [],
    paymentMethods: [],
    shipments: [],
    creditCards: [],
  },
  { idAttribute: 'number', processStrategy: entity => camelizeKeys(entity) },
);

export const orders = new schema.Array(order);
