import amplitude from 'amplitude-js';
import branch from 'branch-sdk';
// Logger
import { logMessage, logException } from '../logHelper';
// Types
import type { UserAddress } from '../../../user';
import type { LineItem } from '../current-order/types';

// Init amplitude
amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_ANALYTICS_API_KEY);

branch.init(process.env.REACT_APP_BRANCH_KEY, (err, data) => {
  if (err) {
    logMessage('Branch error', err, 'warning');
  }
});

export const setUserProperties = user => {
  try {
    amplitude.setUserId(user.id ? user.id : null);

    if (user.id) {
      branch.setIdentity(user.id);
      amplitude.getInstance().setUserProperties({
        name: user.name,
        created_at: user.createdAt,
        is_business: user.isBusiness,
        email: user.email,
      });
    }
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logEvent = (eventName, eventProperties) => {
  try {
    amplitude.getInstance().logEvent(eventName, mergeAppInfo(eventProperties));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

//////////////////////////////////////////////////////////////////
//////////////////////// SEARCH RELATED //////////////////////////
//////////////////////////////////////////////////////////////////

export const logPriceSliderUsed = () => {
  try {
    amplitude.getInstance().logEvent('price slider used', mergeAppInfo({}));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

//////////////////////////////////////////////////////////////////
//////////////////////// SCREEN RELATED //////////////////////////
//////////////////////////////////////////////////////////////////

export const logTimeSpentOnPage = (
  path: string | undefined,
  query: string,
  timeOnCurrentPageInSeconds: number,
  backgroundTime: number,
) => {
  try {
    if (!path || timeOnCurrentPageInSeconds <= 0) return;
    logCustomEventToAmplitude('time spent on page', {
      path,
      query,
      timeOnCurrentPageInSeconds,
      backgroundTime,
    });
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

//////////////////////////////////////////////////////////////////
//////////////////////// PRODUCTS RELATED ////////////////////////
//////////////////////////////////////////////////////////////////

export const logProductView = (product, path, viewSource: ?string) => {
  try {
    amplitude
      .getInstance()
      .logEvent('viewed product', productProperties(product, path, viewSource));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logProductImpression = (product, path, viewSource: ?string) => {
  try {
    amplitude
      .getInstance()
      .logEvent(
        'impression product',
        productProperties(product, path, viewSource),
      );
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logTappedShowFullRecipe = (product, path, viewSource: ?string) => {
  try {
    amplitude
      .getInstance()
      .logEvent(
        'tapped show full recipe',
        productProperties(product, path, viewSource),
      );
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logTappedMinimizeRecipe = (product, path, viewSource: ?string) => {
  try {
    amplitude
      .getInstance()
      .logEvent(
        'Tapped minimize recipe',
        productProperties(product, path, viewSource),
      );
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

const productProperties = (product, path, viewSource) => {
  return mergeAppInfo({
    variant_id: product.id,
    product_id: product.productId,
    name: product.name,
    price: product.price,
    manufacturer: product.manufacturer,
    unit_for_quantity: product.unitForQuantity,
    total_on_hand: product.totalOnHand,
    list_price: product.listPrice,
    view_source: viewSource ? viewSource.toLocaleLowerCase() : null,
    in_stock: product.inStock,
    has_images: product.images.length > 0,
    increment_quantity: product.incrementQuantity,
    default_quantity: product.defaultQuantity,
    path: path,
  });
};

//////////////////////////////////////////////////////////////////
//////////////////////// HOMEPAGE RELATED ////////////////////////
//////////////////////////////////////////////////////////////////

export const logTappedBanner = (bannerName: String) => {
  try {
    amplitude
      .getInstance()
      .logEvent('tapped banner', mergeAppInfo({ bannerName: bannerName }));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logTappedNavList = (selectedItem: String) => {
  try {
    amplitude
      .getInstance()
      .logEvent(
        'tapped nav-list',
        mergeAppInfo({ selectedItem: selectedItem }),
      );
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

//////////////////////////////////////////////////////////////////
//////////////////////// CHECKOUT RELATED ////////////////////////
//////////////////////////////////////////////////////////////////

export const logViewCart = () => {
  try {
    logCustomEventToAmplitude('viewed cart', {});
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logTappedCheckout = items => {
  try {
    amplitude
      .getInstance()
      .logEvent('tapped checkout', mergeAppInfo({ items: items }));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logSelectedAddress = (address: UserAddress) => {
  try {
    amplitude
      .getInstance()
      .logEvent('selected address', mergeAppInfo({ address: address }));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logEditAddress = (address: UserAddress) => {
  try {
    amplitude
      .getInstance()
      .logEvent('edited address', mergeAppInfo({ address: address }));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logDeleteAddress = (deletedAddress: UserAddress) => {
  try {
    amplitude.getInstance().logEvent('deleted address', mergeAppInfo({}));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logTappedAddNewAddress = () => {
  try {
    amplitude
      .getInstance()
      .logEvent('tapped add new address', mergeAppInfo({}));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logSelectedAddressCity = city => {
  try {
    amplitude
      .getInstance()
      .logEvent('tapped address city dropdown', mergeAppInfo({ city: city }));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logConfirmedAddress = () => {
  try {
    amplitude.getInstance().logEvent('confirmed address', mergeAppInfo({}));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logSelectedDelivery = (
  deliveriesRemaining: Number,
  deliveryWindowStart: Date,
  deliveryWindowEnd: Date,
) => {
  try {
    amplitude.getInstance().logEvent(
      'selected delivery',
      mergeAppInfo({
        deliveriesRemaining: deliveriesRemaining,
        deliveryWindowStart: deliveryWindowStart,
        deliveryWindowEnd: deliveryWindowEnd,
      }),
    );
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logConfirmedDelivery = () => {
  try {
    amplitude.getInstance().logEvent('confirmed delivery', mergeAppInfo({}));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logAddNewCard = card => {
  try {
    amplitude
      .getInstance()
      .logEvent('added new card', mergeAppInfo({ card: card }));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logDeleteCreditCard = (creditCardId: number) => {
  try {
    amplitude
      .getInstance()
      .logEvent(
        'deleted credit card',
        mergeAppInfo({ creditCardId: creditCardId }),
      );
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logCompletedOrder = () => {
  try {
    amplitude.getInstance().logEvent('tapped complete order', mergeAppInfo({}));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

//////////////////////////////////////////////////////////////////
//////////////////////// BARBECUE RELATED ////////////////////////
//////////////////////////////////////////////////////////////////

export const logCreatedBarbecue = (
  amountOfAdults: Number,
  amountOfChildren: Number,
  selectedOptionValue: Number,
) => {
  try {
    amplitude.getInstance().logEvent(
      'created barbecue',
      mergeAppInfo({
        amountOfAdults: amountOfAdults,
        amountOfChildren: amountOfChildren,
        selectedOptionValue: selectedOptionValue,
      }),
    );
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logAddedBarbecueToCart = () => {
  try {
    amplitude
      .getInstance()
      .logEvent('added barbecue to cart', mergeAppInfo({}));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

//////////////////////////////////////////////////////////////////
///////////////////////// BLENDS RELATED /////////////////////////
//////////////////////////////////////////////////////////////////

export const logTappedCreateBlend = () => {
  try {
    amplitude.getInstance().logEvent('tapped create blend', mergeAppInfo({}));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logSavedBlend = blend => {
  try {
    amplitude
      .getInstance()
      .logEvent('saved blend blend', mergeAppInfo({ blend: blend }));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logAddedBlendToCart = blendId => {
  try {
    amplitude
      .getInstance()
      .logEvent('added blend to cart', mergeAppInfo({ blendId: blendId }));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

//////////////////////////////////////////////////////////////////
/////////////////////// LINE ITEM RELATED ///////////////////////
//////////////////////////////////////////////////////////////////

export const logLineItemView = (lineItem, path, viewSource: ?string) => {
  try {
    amplitude
      .getInstance()
      .logEvent(
        'viewed line item',
        lineItemProperties(lineItem, path, viewSource),
      );
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logLineItemAdd = (lineItem, path, viewSource: ?string) => {
  try {
    amplitude
      .getInstance()
      .logEvent(
        'added line item',
        lineItemProperties(lineItem, path, viewSource),
      );
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logLineItemRemove = (lineItem, path, viewSource: ?string) => {
  try {
    amplitude
      .getInstance()
      .logEvent(
        'removed line item',
        lineItemProperties(lineItem, path, viewSource),
      );
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

//////////////////////////////////////////////////////////////////
/////////////////////////// USER RELATED /////////////////////////
//////////////////////////////////////////////////////////////////

export const logRegisterUser = (email: string) => {
  try {
    logCustomEventToAmplitude('registered user', { email });
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logLogin = () => {
  try {
    logCustomEventToAmplitude('logged user', { method: 'email' });
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

//////////////////////////////////////////////////////////////////
//////////////////////// REFERRAL RELATED ///////////////////////
//////////////////////////////////////////////////////////////////

export const logUserCopiedReferralCode = (referralCode?: string) => {
  try {
    logCustomEventToAmplitude('copied referral', { referralCode });
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logUserSharedReferralLink = () => {
  try {
    logCustomEventToAmplitude('shared referral', {});
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logUserOpenedReferralPage = () => {
  try {
    logCustomEventToAmplitude('opened referral', {});
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

//////////////////////////////////////////////////////////////////
///////////////////////////// ADVERTISING RELATED //////////////////////////////
//////////////////////////////////////////////////////////////////

export const logAdvertisingPopupSeen = () => {
  try {
    logCustomEventToAmplitude('advertising popup seen', {});
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logAdvertisingPopupDismissed = () => {
  try {
    logCustomEventToAmplitude('advertising popup dismissed', {});
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logAdvertisingPopupTapped = () => {
  try {
    logCustomEventToAmplitude('advertising popup tapped', {});
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

//////////////////////////////////////////////////////////////////
///////////////////////////// LANDING PAGES /////////////////////
//////////////////////////////////////////////////////////////////

export const logLandingPageFaqToggle = (landingPage: string) => {
  try {
    logCustomEventToAmplitude('landing page faq toggle', { landingPage });
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logLandingSliderSlid = (landingPage: string) => {
  try {
    logCustomEventToAmplitude('landing slider slid', { landingPage });
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

//////////////////////////////////////////////////////////////////
///////////////////////////// UTILS //////////////////////////////
//////////////////////////////////////////////////////////////////

export const logCustomEventToAmplitude = async (
  eventTitle,
  eventProperties,
) => {
  amplitude.getInstance().logEvent(eventTitle, mergeAppInfo(eventProperties));
};

// TODO: [referral] log referral events to branch as 'SHARE' type

const lineItemProperties = (lineItem, path, viewSource) => {
  return mergeAppInfo({
    variant_id: lineItem.variant.id,
    product_id: lineItem.variant.productId,
    name: lineItem.variant.name,
    portioning_size: lineItem.portioningSize,
    price: lineItem.price,
    quantity: lineItem.quantity,
    total: lineItem.total,
    order_id: lineItem.orderId,
    mix_id: lineItem.mixId,
    manufacturer: lineItem.variant.manufacturer,
    unit_for_quantity: lineItem.variant.unitForQuantity,
    total_on_hand: lineItem.variant.totalOnHand,
    list_price: lineItem.variant.listPrice,
    adjustment_total: lineItem.adjustmentTotal,
    pre_tax_amount: lineItem.preTaxAmount,
    in_stock: lineItem.variant.inStock,
    view_source: viewSource ? viewSource.toLowerCase() : null,
    has_images: lineItem.variant.images.length > 0,
    increment_quantity: lineItem.variant.incrementQuantity,
    default_quantity: lineItem.variant.defaultQuantity,
    observation_present: !!lineItem.observation,
    created_at: lineItem.createdAt,
    updated_at: lineItem.updatedAt,
    path: path,
  });
};

const mergeAppInfo = (properties: Object) => {
  const appInfo = {
    app_version: process.env.REACT_APP_BUILD_COMMIT,
    app_source: 'sales_frontend',
  };

  return Object.assign({}, properties, appInfo);
};

export const getDeviceId = () => {
  return amplitude.getInstance().options.deviceId;
};

//////////////////////////////////////////////////////////////////
///////////////////////////// BIGCLUB RELATED ////////////////////
//////////////////////////////////////////////////////////////////

export const logUserReachedMinPriceToEarnCashback = () => {
  try {
    logCustomEventToAmplitude('user reached min price to earn cashback', {});
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logUserAppliedCashback = () => {
  try {
    logCustomEventToAmplitude('user applied cashback', {});
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logUserOpenedBigClubPage = () => {
  try {
    logCustomEventToAmplitude('opened bigclub', {});
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logUserOpenedChristmasLandingPage = () => {
  try {
    logCustomEventToAmplitude('opened christmas landing page', {});
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logUserOpenedKitsLandingPage = () => {
  try {
    logCustomEventToAmplitude('opened kits landing page', {});
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logUserOpenedBigClubLandingPage = () => {
  try {
    logCustomEventToAmplitude('opened bigclub landing page', {});
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

//////////////////////////////////////////////////////////////////
//////////////////////// MEASUREMENT RELATED /////////////////////
//////////////////////////////////////////////////////////////////

export const logUserScreenTime = (screenName: string, elapsedTime: number) => {
  try {
    logCustomEventToAmplitude('user time on page', { screenName, elapsedTime });
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

//////////////////////////////////////////////////////////////////
//////////////////////// REPEAT LAST PURCHASE RELATED ////////////
//////////////////////////////////////////////////////////////////

export const logUserTappedRepeatLastPurchase = (
  orderNumber: string,
  items: LineItem[],
) => {
  try {
    logCustomEventToAmplitude(
      'user tapped repeat last purchase',
      mergeAppInfo({
        orderNumber,
        items,
      }),
    );
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

//////////////////////////////////////////////////////////////////
//////////////////////// WISHLIST  RELATED ///////////////////////
//////////////////////////////////////////////////////////////////

export const logUserTappedFavoriteProduct = (
  productId: number,
  productName: string,
) => {
  try {
    logCustomEventToAmplitude(
      'user tapped favorite product',
      mergeAppInfo({
        productId,
        productName,
      }),
    );
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logUserTappedUnFavoriteProduct = (
  productId: number,
  productName: string,
) => {
  try {
    logCustomEventToAmplitude(
      'user tapped unfavorite product',
      mergeAppInfo({
        productId,
        productName,
      }),
    );
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logUserOpenedWishlistPage = () => {
  try {
    logCustomEventToAmplitude('user opened wishlist page', {});
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logUserOpenedWishlistProductListPage = (listName: string) => {
  try {
    logCustomEventToAmplitude('user opened wishlist product list page', {
      listName,
    });
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logUserOpenedWishlistCustomListPage = () => {
  try {
    logCustomEventToAmplitude('user opened wishlist custom list page', {});
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logUserTappedCopyCustomList = (listName: string) => {
  try {
    logCustomEventToAmplitude('user tapped copy custom list', {
      listName,
    });
  } catch (e) {
    console.error(e);
    logException(e);
  }
};
