// @flow
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { FormControlInput, validationHelpers } from '../../common';
// Components
import { Button, Checkbox } from '../../common';
import {
  getLatestReferringParams,
  getFirstReferringParams,
} from '../../common/branch';
import { Link } from 'react-router-dom';
import Reaptcha from 'reaptcha';
// Helpers
import {
  formatBirthdate,
  cpfMask,
  removeSpecialCharacters,
} from '../../common';
import moment from 'moment';
// Styles
import './RegisterBox.css';

type FormValue = {
  fullName: string,
  firstName: string,
  lastName: string,
  cpf: string,
  email: string,
  referralCode: string,
  password: string,
  birthdate: string,
  phoneNumber: string,
  acceptedTerms: boolean,
  rememberMe: boolean,
};

type FormErrors = {
  passwordStrength: string,
  birthdateError: string,
  firstNameError: string,
  lastNameError: string,
  cpfError: string,
  emailError: string,
  passwordError: string,
  acceptedTermsError: string,
};

const defaultFormValue: FormValue = {
  firstName: '',
  lastName: '',
  cpf: '',
  email: '',
  referralCode: '',
  password: '',
  birthdate: '',
  acceptedTerms: false,
  rememberMe: false,
};
const defaultFormErrors: FormErrors = {
  passwordStrength: '',
  birthdateError: '',
  firstNameError: '',
  lastNameError: '',
  cpfError: '',
  emailError: '',
  passwordError: '',
  acceptedTermsError: '',
};
/*
    Returns the "Password String Text and Color" based on the value of passwordStrength
    Price:
        PasswordStrenth 'Forte': Shows PasswordStrenth in Green
        PasswordStrenth 'Médio': Shows PasswordStrenth in Orange
        PasswordStrenth 'Fraca': Shows PasswordStrenth in Red
        All else: Shows no text
    Also places the correct class for styling
*/
const PasswordStrengthTextAndColor = ({ passwordStrength }) => {
  if (passwordStrength === 'strong') {
    return (
      <span className="password-strength-text">
        Força da senha:{' '}
        <span className="password-strength-strong"> Forte </span>{' '}
      </span>
    );
  } else if (passwordStrength === 'average') {
    return (
      <span className="password-strength-text">
        Força da senha:{' '}
        <span className="password-strength-medium"> Média </span>{' '}
      </span>
    );
  } else if (passwordStrength === 'weak') {
    return (
      <span className="password-strength-text">
        Força da senha: <span className="password-strength-poor"> Fraca </span>{' '}
      </span>
    );
  } else {
    return <span> </span>;
  }
};

type Props = {
  isLoading: boolean,
  onSubmitRegister: (
    firstName: string,
    lastName: string,
    cpf: string,
    email: string,
    password: string,
    birthdate: Date,
    referralCode: string,
    rememberMe?: boolean,
  ) => void,
  errors: { base?: string[] },
};

const RegisterBox = ({ isLoading, onSubmitRegister, errors }: Props) => {
  const [formValue, setFormValue] = useState<FormValue>(defaultFormValue);
  const [formErrors, setFormErrors] = useState<FormErrors>(defaultFormErrors);
  const [showReferralCodeInput, setShowReferralCodeInput] = useState(false);
  const captchaRef = useRef(null);
  const isLoadingRef = useRef(null);

  useEffect(() => {
    // Try to get inviter referral code from branch params
    getLatestReferringParams(true).then(
      params =>
        params.referral_code &&
        this.setState({
          referralCode: params.referral_code,
          shouldDisableReferralInput: true,
          showReferralCodeInput: true,
        }),
    );

    getFirstReferringParams(true).then(
      params =>
        params.referral_code &&
        this.setState({
          referralCode: params.referral_code,
          shouldDisableReferralInput: true,
          showReferralCodeInput: true,
        }),
    );
    isLoadingRef.current = isLoading;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isLoadingRef.current && !isLoading && !_.isEmpty(errors)) {
      if (errors.base) {
        setFormErrors(prevFormErrors => ({
          ...prevFormErrors,
          emailError: errors.base[0],
        }));
      }
    }
    // eslint-disable-next-line
  }, [isLoading]);

  const validationErrorExists = () => {
    return Object.keys(formErrors).some(error => {
      if (error === 'passwordStrength') return false;
      return !!formErrors[error];
    });
  };

  const isFormInvalid = validationErrorExists();

  // Sets this.state.passwordStrength based on the characteristics of the password
  const calculatePasswordStrength = (password: string) => {
    if (password === '') {
      setFormErrors(prevFormErrors => ({
        ...prevFormErrors,
        passwordStrength: '',
      }));
    } else if (password.length < 8) {
      setFormErrors(prevFormErrors => ({
        ...prevFormErrors,
        passwordStrength: 'weak',
      }));
    } else if (/^[a-zA-Z0-9- ]*$/.test(password) === false) {
      setFormErrors(prevFormErrors => ({
        ...prevFormErrors,
        passwordStrength: 'strong',
      }));
    } else if (password.length > 15) {
      setFormErrors(prevFormErrors => ({
        ...prevFormErrors,
        passwordStrength: 'strong',
      }));
    } else {
      setFormErrors(prevFormErrors => ({
        ...prevFormErrors,
        passwordStrength: 'average',
      }));
    }
  };

  // Validates the form on submission. If there are no validation error dispatches action.
  const validateUserInput = () => {
    let noValidationErrors = !validationErrorExists();
    const acceptedTermsError = validationHelpers.user.acceptedTerms(
      formValue.acceptedTerms,
    );
    setFormErrors(prevFormErrors => ({
      ...prevFormErrors,
      acceptedTermsError,
    }));
    if (acceptedTermsError) {
      noValidationErrors = false;
    }
    if (noValidationErrors) {
      captchaRef.current.execute();
    } else {
      console.error('Validation error on registration form.');
    }
  };

  const changeBirthdate = (date: string) => {
    const formattedDate = formatBirthdate(date);
    onChangeText('birthdate', formattedDate);
  };

  const onVerify = recaptchaToken => {
    const birthdateAsDate = moment(formValue.birthdate, 'DD/MM/YYYY').toDate();
    onSubmitRegister(
      formValue.firstName,
      formValue.lastName,
      removeSpecialCharacters(formValue.cpf),
      formValue.email,
      formValue.password,
      birthdateAsDate,
      formValue.referralCode,
      recaptchaToken,
      formValue.rememberMe,
    );
    captchaRef.current.reset();
  };

  const toggleReferralInput = e => {
    e.preventDefault();
    setShowReferralCodeInput(prevState => !prevState);
  };

  const onChangeText = (target, value) => {
    setFormValue(prevFormValue => ({ ...prevFormValue, [target]: value }));
    setFormErrors(prevFormErrors => ({
      ...prevFormErrors,
      [`${target}Error`]: '',
    }));
  };

  return (
    <div className="register-box-main register-box-fade-in">
      <form
        onSubmit={e => {
          e.preventDefault();
          validateUserInput();
        }}
      >
        <FormControlInput
          label="Nome"
          value={formValue.firstName}
          handleChangeText={value => onChangeText('firstName', value)}
          handleFocus={value => onChangeText('firstName', value)}
          handleBlur={() =>
            setFormErrors(prevFormErrors => ({
              ...prevFormErrors,
              firstNameError: validationHelpers.user.firstName(
                formValue.firstName,
              ),
            }))
          }
          errorMessage={formErrors.firstNameError}
        />
        <FormControlInput
          label="Sobrenome"
          value={formValue.lastName}
          handleChangeText={value => onChangeText('lastName', value)}
          handleFocus={value => onChangeText('lastName', value)}
          handleBlur={() =>
            setFormErrors(prevFormErrors => ({
              ...prevFormErrors,
              lastNameError: validationHelpers.user.lastName(
                formValue.lastName,
              ),
            }))
          }
          errorMessage={formErrors.lastNameError}
        />

        <FormControlInput
          label="Email"
          value={formValue.email}
          handleChangeText={value => onChangeText('email', value)}
          handleFocus={value => onChangeText('email', value)}
          handleBlur={() => {
            setFormErrors(prevFormErrors => ({
              ...prevFormErrors,
              emailError: validationHelpers.user.email(formValue.email),
            }));
          }}
          errorMessage={formErrors.emailError}
        />
        <FormControlInput
          label="CPF"
          value={formValue.cpf}
          handleChangeText={value => onChangeText('cpf', cpfMask(value))}
          handleFocus={value => onChangeText('cpf', cpfMask(value))}
          handleBlur={() =>
            setFormErrors(prevFormErrors => ({
              ...prevFormErrors,
              cpfError: validationHelpers.user.cpf(formValue.cpf),
            }))
          }
          errorMessage={formErrors.cpfError}
        />
        {formErrors.passwordStrength && (
          <PasswordStrengthTextAndColor
            passwordStrength={formErrors.passwordStrength}
          />
        )}
        <FormControlInput
          label="Senha"
          type="password"
          value={formValue.password}
          handleChangeText={value => {
            onChangeText('password', value);
            calculatePasswordStrength(value);
          }}
          handleFocus={value => {
            onChangeText('password', value);
            calculatePasswordStrength(value);
          }}
          handleBlur={() =>
            setFormErrors(prevFormErrors => ({
              ...prevFormErrors,
              passwordError: validationHelpers.user.password(
                formValue.password,
              ),
            }))
          }
          errorMessage={formErrors.passwordError}
        />
        <FormControlInput
          label="Data de nascimento"
          maxLength="10"
          value={formValue.birthdate}
          handleChangeText={value => changeBirthdate(value)}
          handleFocus={value => changeBirthdate(value)}
          handleBlur={() => {
            setFormErrors(prevFormErrors => ({
              ...prevFormErrors,
              birthdateError: validationHelpers.user.birthdate(
                formValue.birthdate,
              ),
            }));
          }}
          errorMessage={formErrors.birthdateError}
        />
        <button
          className="register-box-referral-button"
          onClick={toggleReferralInput}
        >
          <span>Foi Indicado por um amigo?</span>
          <i
            className={`fa fa-chevron-${showReferralCodeInput ? 'up' : 'down'}`}
            aria-hidden="true"
          ></i>
        </button>
        {showReferralCodeInput && (
          <div className="register-box-fade-in">
            <FormControlInput
              label="Código de indicação"
              value={formValue.referralCode}
              handleChangeText={value => onChangeText('referralCode', value)}
              handleFocus={value => onChangeText('referralCode', value)}
              handleBlur={() => {
                setFormErrors(prevFormErrors => ({
                  ...prevFormErrors,
                  referralCodeError: validationHelpers.user.referralCode(
                    formValue.referralCode,
                  ),
                }));
              }}
              errorMessage={formErrors.referralCodeError}
            />
          </div>
        )}
        <div className="register-box-terms-of-use">
          <Checkbox
            className="register-box-terms-of-use"
            value={formValue.acceptedTerms}
            onChange={e =>
              setFormValue(prevFormValue => ({
                ...prevFormValue,
                acceptedTerms: e.target.checked,
              }))
            }
            label={
              <span>
                Li e aceito os <Link to="/termos/">termos de uso</Link> e as{' '}
                <Link to="/termos/">políticas de privacidade</Link>
              </span>
            }
          />
        </div>
        <div className="register-box-terms-of-use">
          <Checkbox
            className="register-box-terms-of-use"
            value={formValue.rememberMe}
            onChange={e =>
              setFormValue(prevFormValue => ({
                ...prevFormValue,
                rememberMe: e.target.checked,
              }))
            }
            label={<span>Mantenha-me conectado</span>}
          />
        </div>
        <Reaptcha
          ref={captchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onVerify={onVerify}
          size="invisible"
          badge="bottomleft"
          hl="pt-BR"
        />
        <Button
          type="submit"
          text="CADASTRAR"
          icon=""
          disabled={isFormInvalid}
          buttonType="button-secondary"
          loading={isLoading}
        />
      </form>
    </div>
  );
};

export default RegisterBox;
