// @flow
import { createAction } from 'redux-actions';
// Types
import type { Dispatch } from '../../types';
// Api
import Popups from '../api';
// Logger
import { logAPIException } from '../../logHelper';

export const getPopupsRequest = createAction('GET_POPUPS_REQUEST');
export const getPopups = createAction('GET_POPUPS', response => response);

export function fetchAllPopups() {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getPopupsRequest());
      const response = await Popups.fetchPopups();
      dispatch(getPopups(response));
    } catch (err) {
      logAPIException(err);
      dispatch(getPopups(err));
    }
  };
}

export const postShowedPopupRequest = createAction('POST_SHOWED_POPUP_REQUEST');
export const postShowedPopup = createAction(
  'POST_SHOWED_POPUP',
  response => response,
);

export function postMarkPopupAsSeen(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(postShowedPopupRequest());
      const response = await Popups.postShowedPopup(id);
      dispatch(postShowedPopup(response));
    } catch (err) {
      logAPIException(err);
      dispatch(postShowedPopup(err));
    }
  };
}
