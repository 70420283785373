// @flow
import React from 'react';
// Styles
import './QuantitySelector.css';

type Props = {
  handleAmountOfPeopleChange: (changeStatus: Array<string>) => void,
  quantity: number,
  title: string,
};
const QuantitySelector = ({
  handleAmountOfPeopleChange,
  quantity,
  title,
}: Props) => {
  return (
    <div
      className={`barbecue-quantity-selector ${
        title === 'Adultos' ? 'adult' : 'child'
      }`}
    >
      <div className="selector-quantity-text">{title}</div>
      <div className="selector-quantity">
        <button
          className="barbecue-quantity-button-down"
          onClick={() => {
            if (quantity > 0) {
              handleAmountOfPeopleChange(-1);
            }
          }}
        >
          <i className="barbecue-quantity fa fa-minus"></i>
        </button>
        <div
          className={`${
            title === 'Adultos' ? 'adult' : 'child'
          }-quantity-container`}
        >
          <div className="selector-quantity-text">{quantity}</div>
        </div>
        <button
          className="barbecue-quantity-button-up"
          onClick={() => {
            handleAmountOfPeopleChange(1);
          }}
        >
          <i className="barbecue-quantity fa fa-plus"></i>
        </button>
      </div>
    </div>
  );
};

export default QuantitySelector;
