// @flow
import React from 'react';
// Styles
import './ChristmasProductList.css';
// Components
import Slider from 'react-slick';
import { AddToCartButton } from '../../current-order';
import { ProductPrice, ProgressiveImageLoader } from '../../common';
import { SlickSliderArrow } from '../../common';
import CookingRecommedationBox from '../../current-order/components/CookingRecommedationBox';
// Types
import { Product } from '../../products';
// Analytics
import { logLandingSliderSlid } from '../../common/analytics';
// Assests
import meatIcon from '../images/christmas-slide-meat-icon.svg';
import arrowIcon from '../images/arrow-icon.svg';
import ChristmasLights from '../images/christmas-light.svg';
import SnowBorderTopLeft from '../images/snow-border-top-left.svg';
import Ornament from '../images/christmas-ornament.svg';
import SnowBorderBottomRight from '../images/snow-border-bottom-right.svg';
//Helpers
import { scaleConverter } from '../../common';

type Props = {
  sliderProductsVariants: Product[],
  currentLanding: string,
};

const ChristmasProductList = ({
  sliderProductsVariants = [],
  productsVariants = [],
  currentLanding,
}: Props) => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    vertical: false,
    lazyLoad: true,
    arrows: true,
    nextArrow: (
      <SlickSliderArrow
        containerStyle="landing-product-list-arrow-container"
        direction="right"
        iconLeftStyle="fa fa-caret"
        iconRightStyle="fa-4x"
        disabledClassName="landing-product-list-scroller-arrow-disabled"
        arrowScrollerClassName="landing-product-list-scroller-arrow  scroller-arrow"
      />
    ),
    prevArrow: (
      <SlickSliderArrow
        containerStyle="landing-product-list-arrow-container"
        direction="left"
        iconLeftStyle="fa fa-caret"
        iconRightStyle="fa-4x"
        disabledClassName="landing-product-list-scroller-arrow-disabled"
        arrowScrollerClassName="landing-product-list-scroller-arrow  scroller-arrow"
      />
    ),
  };

  const sliderRef = React.createRef();

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const calculateProductDiscount = (listPrice, price) => {
    if (listPrice > price) {
      const discountPercentage = ((listPrice - price) / listPrice) * 100;
      return Math.round(discountPercentage);
    }
    return null;
  };

  const onSlideChange = index => {
    logLandingSliderSlid(currentLanding);
  };

  if (sliderProductsVariants.length <= 0) {
    return null;
  }

  return (
    <div className="christmas-product-list-main-container">
      <img src={ChristmasLights} alt="Luzes de natal"></img>
      <img src={SnowBorderTopLeft} alt="Borda de neve"></img>
      <div className="christmas-product-list-content">
        {sliderProductsVariants.length > 0 && (
          <Slider {...settings} afterChange={onSlideChange} ref={sliderRef}>
            {sliderProductsVariants.map((sliderProduct, index) => {
              const price = scaleConverter(sliderProduct.price);
              const listPrice = scaleConverter(sliderProduct.listPrice);
              const productOnPromotion = listPrice > price;
              const productDiscount = calculateProductDiscount(
                listPrice,
                price,
              );
              return (
                <div
                  className="christmas-product-list-item-content-container"
                  key={index}
                >
                  <div className="christmas-product-list-individual-product-container">
                    <div className="christmas-product-list-image-container">
                      {productOnPromotion && productDiscount >= 5 && (
                        <div className="christmas-product-discout-square">
                          <p>{Math.round(productDiscount)}%</p>
                          <span>OFF</span>
                        </div>
                      )}
                      <ProgressiveImageLoader
                        selectedImage={sliderProduct.images[0]}
                        imageToShow={'large'}
                      />
                    </div>
                    <div className="christmas-product-list-product-details">
                      <div className="christmas-product-list-product-text">
                        <p className="christmas-product-list-product-manufacturer">
                          {sliderProduct.manufacturer}
                        </p>
                        <h4 className="">{sliderProduct.name}</h4>
                      </div>
                      <div className="christmas-product-list-product-lower-content">
                        <div className="christmas-product-list-icons-container">
                          <div className="christmas-product-list-icons-content">
                            <CookingRecommedationBox
                              cookingMethods={
                                sliderProduct.cookingRecommendations
                              }
                            />
                          </div>
                        </div>
                        <div className="christmas-product-list-buy-container">
                          <ProductPrice
                            productListPrice={sliderProduct.listPrice}
                            productPrice={sliderProduct.price}
                            productUnit={sliderProduct.unitForQuantity}
                          />
                          <AddToCartButton
                            showNotifyMeButton={false}
                            productId={sliderProduct.id}
                            slug={sliderProduct.slug}
                            unitForQuantity={sliderProduct.unitForQuantity}
                            defaultQuantity={sliderProduct.defaultQuantity}
                            incrementQuantity={sliderProduct.incrementQuantity}
                            inStock={sliderProduct.inStock}
                            discontinued={sliderProduct.discontinued}
                            shouldNotifyUser={sliderProduct.shouldNotifyUser}
                            analyticsEventType={'landingPage'}
                          />
                          <img
                            className="christmas-product-list-arrow-icon"
                            src={arrowIcon}
                            alt="Icone de seta"
                            onClick={goToNextSlide}
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        )}
      </div>
      <img src={Ornament} alt="Decoração de natal"></img>
      <img src={SnowBorderBottomRight} alt="Borda de neve"></img>
    </div>
  );
};

export default ChristmasProductList;
