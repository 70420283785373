// @flow
import React from 'react';
import './OutOfStockNotice.css';

const OutOfStockNotice = ({ shouldDisplay }) => {
  if (!shouldDisplay) return null;
  return (
    <>
      <div className="out-of-stock-notice-main">
        <i class="fa fa-info-circle" aria-hidden="true"></i>
        Um ou mais itens do seu carrinho saíram de estoque ou foram
        descontinuados. <span className="out-of-stock-notice-white-text-span">Remova-os antes de continuar</span>
      </div>
    </>
  );
};

export default OutOfStockNotice;
