// @flow
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './CartChangeNotice.css';
// Types
import type { SimpleLineItem } from '../types';
import { ProgressiveImageLoader } from '../../common';

type Props = {
  cartChanges: SimpleLineItem[],
  onDismiss: () => void,
};

type State = {
  noticePosition: string,
  arrowPosition: string,
};

class CartChangeNotice extends Component {
  props: Props;
  state: State;
  handleScroll: () => void;
  constructor(props: Props) {
    super(props);

    this.state = {
      noticePosition: '',
      arrowPosition: '',
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (event: any) => {
    let scrollTop = window.pageYOffset;
    if (scrollTop < 40) {
      this.setState({
        noticePosition: '',
        arrowPosition: '',
      });
    }

    if (scrollTop > 40) {
      this.setState({
        noticePosition: 'notice-to-top',
        arrowPosition: 'arrow-to-top',
      });
    }
  };

  render() {
    return (
      <div className="cart-change-main">
        <div className={'arrow-up ' + this.state.arrowPosition} />
        <div
          className={
            'cart-change-notice-container ' + this.state.noticePosition
          }
        >
          <div className="cart-change-notice-close-icon">
            <i
              className="fa fa-times"
              onClick={this.props.onDismiss}
              aria-hidden="true"
            />
          </div>
          {this.props.cartChanges.map((c, ind) => {
            if (ind < 3) {
              return (
                <div key={ind} className="cart-change-notice-content">
                  <div className="cart-change-notice-control">
                    <div className="cart-change-notice-image">
                      <ProgressiveImageLoader
                        selectedImage={
                          c.images.length !== 0 ? c.images[0] : null
                        }
                        imageToShow={'small'}
                      />
                    </div>
                  </div>

                  <h3>{c.name}</h3>

                  <p>
                    {c.unitForQuantity === 'g'
                      ? (c.quantity / 1000).toLocaleString('pt-br', {
                          currency: 'BRL',
                        }) + ' Kg'
                      : c.quantity}
                  </p>
                </div>
              );
            } else {
              return null;
            }
          })}
          <Link className="cart-change-notice-link" to="/carrinho/">
            Ir para o carrinho
          </Link>
        </div>
      </div>
    );
  }
}

export default CartChangeNotice;
