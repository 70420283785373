// @flow
import React, { Component } from 'react';
// Components
import ChickenStamp from '../../search/images/chicken_magnify_glass.png';
// Styles
import './NoProduct.css';

class NoProduct extends Component {
  props: Props;
  render() {
    return (
      <div className="no-product-main">
        <div className="no-product-sub-container">
          <div className="no-product-container">
            <img className="no-product-image" src={ChickenStamp} alt="" />
          </div>
          <h3 className="no-product-text">Esse produto não existe...</h3>
        </div>
      </div>
    );
  }
}

export default NoProduct;
