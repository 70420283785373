// @flow
import { createAction } from 'redux-actions';
// API
import Product from '../api';
// Types
import type { Dispatch } from '../../types';
// Logger
import { logAPIException } from '../../logHelper';
// Helpers
import { copyToClipboard } from '../../common';

/***********************
 *** FETCH A PRODUCT ***
 ***********************/

export const fetchProduct = createAction('FETCH_PRODUCT');
export const fetchProductRequest = createAction('FETCH_PRODUCT_REQUEST');

export function findProduct(slug: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchProductRequest(slug));
    try {
      const productResponse = await Product.get(slug);
      dispatch(fetchProduct(productResponse));
    } catch (err) {
      dispatch(fetchProduct(err));
      logAPIException(err);
    }
  };
}

/**************************************
 *** NOTIFY USER WHEN STOCK RENEWED ***
 **************************************/

export const putNotifyMeWhenProductStockIsRenewed = createAction(
  'PUT_NOTIFY_ME_WHEN_PRODUCT_STOCK_IS_RENEWED',
);
export const putNotifyMeWhenProductStockIsRenewedRequest = createAction(
  'PUT_NOTIFY_ME_WHEN_PRODUCT_STOCK_IS_RENEWED_REQUEST',
);

export function notifyMeWhenStockRenewed(productId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(putNotifyMeWhenProductStockIsRenewedRequest(productId));
    try {
      const productResponse = await Product.putNotifyMe(productId);
      dispatch(putNotifyMeWhenProductStockIsRenewed(productResponse));
    } catch (err) {
      dispatch(putNotifyMeWhenProductStockIsRenewed(err));
      logAPIException(err);
    }
  };
}

/****************************************
 *** UNNOTIFY USER WHEN STOCK RENEWED ***
 ****************************************/

export const putUnnotifyMeWhenProductStockIsRenewed = createAction(
  'PUT_UNNOTIFY_ME_WHEN_PRODUCT_STOCK_IS_RENEWED',
);
export const putUnnotifyMeWhenProductStockIsRenewedRequest = createAction(
  'PUT_UNNOTIFY_ME_WHEN_PRODUCT_STOCK_IS_RENEWED_REQUEST',
);

export function unnotifyMeWhenStockRenewed(productId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(putUnnotifyMeWhenProductStockIsRenewedRequest(productId));
    try {
      const productResponse = await Product.putUnnotifyMe(productId);
      dispatch(putUnnotifyMeWhenProductStockIsRenewed(productResponse));
    } catch (err) {
      dispatch(putUnnotifyMeWhenProductStockIsRenewed(err));
      logAPIException(err);
    }
  };
}

/************************************
 *** FETCH PRODUCTS FOR SCROLLERS ***
 ************************************/
export const fetchProductsRequest = createAction('FETCH_PRODUCTS_REQUEST');
export const fetchProducts = createAction('FETCH_PRODUCTS');

export function findMultipleProducts(ids: number[]) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchProductsRequest());
    try {
      const response = await Product.byIds(ids);
      dispatch(fetchProducts(response));
    } catch (err) {
      dispatch(fetchProducts(err));
      logAPIException(err);
    }
  };
}

export const fetchRecentlyViewedProducts = createAction(
  'FETCH_RECENTLY_VIEWED_PRODUCTS',
);
export const fetchRecentlyViewedProductsRequest = createAction(
  'FETCH_RECENTLY_VIEWED_PRODUCTS_REQUEST',
);

export function findRecentlyViewedProducts() {
  return async (dispatch: Dispatch) => {
    dispatch(fetchRecentlyViewedProductsRequest());
    try {
      const productResponse = await Product.getRecentlyViewedProductsRequest();
      dispatch(fetchRecentlyViewedProducts(productResponse));
    } catch (err) {
      dispatch(fetchRecentlyViewedProducts(err));
      logAPIException(err);
    }
  };
}

export const fetchRelatedProducts = createAction('FETCH_RELATED_PRODUCTS');
export const fetchRelatedProductsRequest = createAction(
  'FETCH_RELATED_PRODUCTS_REQUEST',
);

export function findRelatedProducts(slug: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchRelatedProductsRequest());
    try {
      const productResponse = await Product.getRelatedProductsRequest(slug);
      dispatch(fetchRelatedProducts(productResponse));
    } catch (err) {
      dispatch(fetchRelatedProducts(err));
      logAPIException(err);
    }
  };
}

export const fetchPromotionProducts = createAction('FETCH_PROMOTION_PRODUCTS');
export const fetchPromotionProductsRequest = createAction(
  'FETCH_PROMOTION_PRODUCTS_REQUEST',
);

export function findPromotionProducts(page, perPage) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchPromotionProductsRequest());
    try {
      const productResponse = await Product.getPromotionProductsRequest(
        page,
        perPage,
      );
      dispatch(fetchPromotionProducts(productResponse));
    } catch (err) {
      dispatch(fetchPromotionProducts(err));
      logAPIException(err);
    }
  };
}

export const clearPromotionProducts = createAction('CLEAR_PROMOTION_PRODUCTS');

export const fetchRecommendedForUserProducts = createAction(
  'FETCH_RECOMMENDED_FOR_USER_PRODUCTS',
);
export const fetchRecommendedForUserProductsRequest = createAction(
  'FETCH_RECOMMENDED_FOR_USER_PRODUCTS_REQUEST',
);

export function findRecommendedForUserProducts(page, perPage) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchRecommendedForUserProductsRequest());
    try {
      const productResponse =
        await Product.getProductsRecommendedForUserRequest(page, perPage);
      dispatch(fetchRecommendedForUserProducts(productResponse));
    } catch (err) {
      dispatch(fetchRecommendedForUserProducts(err));
      logAPIException(err);
    }
  };
}

export const clearStaffSelectedProducts = createAction(
  'CLEAR_STAFF_SELECTED_PRODUCTS',
);

export const fetchMostSoldProducts = createAction('FETCH_MOST_SOLD_PRODUCTS');
export const fetchMostSoldProductsRequest = createAction(
  'FETCH_MOST_SOLD_PRODUCTS_REQUEST',
);

export function findMostSoldProducts(page, perPage) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchMostSoldProductsRequest());
    try {
      const productResponse = await Product.getMostSoldProductsRequest(
        page,
        perPage,
      );
      dispatch(fetchMostSoldProducts(productResponse));
    } catch (err) {
      dispatch(fetchMostSoldProducts(err));
      logAPIException(err);
    }
  };
}

export const clearMostSoldProducts = createAction('CLEAR_MOST_SOLD_PRODUCTS');

export const fetchFrequentlyPurchasedTogetherProducts = createAction(
  'FETCH_FREQUENTLY_PURCHASED_TOGETHER_PRODUCTS',
);
export const fetchFrequentlyPurchasedTogetherProductsRequest = createAction(
  'FETCH_FREQUENTLY_PURCHASED_TOGETHER_PRODUCTS_REQUEST',
);

export function findFrequentlyPurchasedTogetherProducts(
  variantId,
  page,
  perPage,
) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchFrequentlyPurchasedTogetherProductsRequest());
    try {
      const productResponse =
        await Product.getFrequentlyPurchasedTogetherProductsRequest(
          variantId,
          page,
          perPage,
        );
      dispatch(fetchFrequentlyPurchasedTogetherProducts(productResponse));
    } catch (err) {
      dispatch(fetchFrequentlyPurchasedTogetherProducts(err));
      logAPIException(err);
    }
  };
}

export const fetchDisplayVariantsByPageAndSection = createAction(
  'FETCH_DISPLAY_VARIANTS_BY_PAGE_AND_SECTION',
);

export function findDisplayVariantsByPageAndSection(
  page: string,
  section: string,
) {
  return async (dispatch: Dispatch) => {
    try {
      const displayVariantsByPageAndSectionResponse =
        await Product.getDisplayVariantsByPageAndSection(page, section);
      dispatch(
        fetchDisplayVariantsByPageAndSection(
          displayVariantsByPageAndSectionResponse,
        ),
      );
    } catch (err) {
      dispatch(fetchDisplayVariantsByPageAndSection(err));
      logAPIException(err);
    }
  };
}

/***********************
 *** CREATE A PRODUCT LINK ***
 ***********************/
export const postProductLinkByVariantIdRequest = createAction(
  'POST_PRODUCT_LINK_BY_VARIANT_ID_REQUEST',
);

export const postProductLinkByVariantId = createAction(
  'POST_PRODUCT_LINK_BY_VARIANT_ID',
  response => response,
);

export const copyProductLinkByVariantIdAndUserId = (
  variantId: number,
  userId: number,
) => {
  return async (dispatch: Dispatch) => {
    dispatch(postProductLinkByVariantIdRequest());
    try {
      const response = await Product.postCreateProductLinkByVariantId(
        variantId,
      );

      const displayVariants = response.entities.displayVariants;
      const { deepLinkUserVariants } = displayVariants[variantId];
      const productWithLink = deepLinkUserVariants.find(
        dp => dp.userId === userId,
      );
      copyToClipboard(productWithLink.deepLinkUrl);
      dispatch(postProductLinkByVariantId(response));
    } catch (err) {
      dispatch(postProductLinkByVariantId()(err, variantId));
      logAPIException(err);
    }
  };
};
