// @flow
import React, { useEffect, useRef, useState } from 'react';
// Redux
import { connect } from 'react-redux';
// Styles
import './WishlistCustomListPage.css';
// Analytics
import * as Analytics from '../../common/analytics';
// Components
import WishlistContainer from './WishlistContainer';
import { SearchInput } from '../../common';
import WishlistCover from '../components/wishlist/WishlistCover';
import ProductListModal from './ProductListModal';
import UnauthenticatedRedirect from './UnauthenticatedRedirect';
import EmptyList from '../components/wishlist/EmptyList';
// Images
import listIcon from '../images/list-icon.svg';
// Actions
import {
  addUserProductListToCartByOrderNumberAndListId,
  deleteProductListById,
  getProductLists,
} from '../actions';
import { findOrCreateOrder } from '../../current-order/actions';
// Selectors
import {
  productListingLoadingSelector,
  userAddingProductListToCartLoadingSelector,
  userProductListResultSelector,
} from '../selectors';
import { currentOrderSelector } from '../../current-order/selectors';
// Types
import type { ProductList } from '../types';
import type { Order } from '../../types';

type Props = {
  getProductLists: () => void,
  result: ProductList['result'],
  deleteProductList: (listId: number) => void,
  currentOrderNumber: Order,
  addProductListToCart: (orderNumber: number, listId: number) => void,
  addingProductListToCart: boolean,
  productListing: boolean,
};

const WishlistCustomListPage = ({
  getProductLists,
  result,
  deleteProductList,
  currentOrder,
  addProductListToCart,
  addingProductListToCart,
  productListing,
}: Props) => {
  const countRef = useRef(result.count);
  const [showModal, setShowModal] = useState(false);
  const productLists = result.productLists || [];
  const hasProductLists = productLists.length > 0;
  const currentOrderNumber = currentOrder?.number;
  const [searchText, setSearchText] = useState('');
  const [filteredResults, setFilteredResults] = useState(productLists);

  const handleSearchList = () => {
    const results = productLists.filter(
      product =>
        product.name
          .toLowerCase()
          .trim()
          .indexOf(searchText.toLowerCase().trim()) > -1,
    );
    setFilteredResults(results);
  };

  const handleToggleModal = () => {
    setShowModal(prevState => !prevState);
  };

  useEffect(() => {
    countRef.current = result.count;
    Analytics.logUserOpenedWishlistCustomListPage();
    getProductLists();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (countRef.current !== result.count) {
      setFilteredResults(result.productLists);
      countRef.current = result.count;
    }
    // eslint-disable-next-line
  }, [result.count]);
  return (
    <UnauthenticatedRedirect>
      <WishlistContainer title="Listas Personalizadas">
        <section className="wishlist-content">
          <div className="wishlist-content-list">
            <div className="wishlist-content-list-row">
              <SearchInput
                value={searchText}
                handleChange={setSearchText}
                handleSearch={handleSearchList}
              />
              <button
                className="wishlist-content-list-button"
                onClick={handleToggleModal}
              >
                <img src={listIcon} alt="Lista" />
                <span>Criar lista</span>
              </button>
            </div>
            {filteredResults.length > 0 &&
              filteredResults.map((productList, index) => (
                <WishlistCover
                  id={productList.id}
                  key={index}
                  thumbnails={
                    !!productList.thumbnails && productList.thumbnails
                  }
                  name={productList.name}
                  itemsResume={productList.itemsResume}
                  totalItems={productList.totalItems}
                  totalItemsInPromotion={productList.totalItemsInPromotion}
                  totalListPrice={productList.totalListPrice}
                  totalPrice={productList.totalPrice}
                  handleDelete={deleteProductList}
                  addProductListToCart={addProductListToCart}
                  currentOrderNumber={currentOrderNumber}
                  addingProductListToCart={addingProductListToCart}
                />
              ))}
            {filteredResults.length <= 0 && productLists.length > 0 && (
              <>
                <h3 className="wishlist-content-title">
                  Nenhuma lista foi encontrado com base na pesquisa...
                </h3>
              </>
            )}
            {!hasProductLists && !productListing && (
              <EmptyList
                title="Ops! parece que você não tem nenhuma lista criada."
                showAction
                handleClick={handleToggleModal}
              />
            )}
          </div>
        </section>
        <ProductListModal
          showModal={showModal}
          handleCloseModal={handleToggleModal}
        />
      </WishlistContainer>
    </UnauthenticatedRedirect>
  );
};

const mapStateToProps = state => {
  return {
    currentOrder: currentOrderSelector(state),
    result: userProductListResultSelector(state),
    addingProductListToCart: userAddingProductListToCartLoadingSelector(state),
    productListing: productListingLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: {}) => {
  return {
    getProductLists: () => {
      dispatch(getProductLists());
    },
    deleteProductList: async (listId: number) => {
      await dispatch(deleteProductListById(listId));
      dispatch(getProductLists());
    },
    addProductListToCart: async (orderNumber: number, listId: number) => {
      await dispatch(
        addUserProductListToCartByOrderNumberAndListId(orderNumber, listId),
      );
      dispatch(findOrCreateOrder());
    },
  };
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default connector(WishlistCustomListPage);
