import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
//Helpers
import {
  getTimeFormatL,
  ProgressiveImageLoader,
  truncateString,
} from '../../common';
//Images
import EmptyBlend from '../images/blend_box.png';
// Components
import { Link } from 'react-router-dom';
import AddBlendToCartButton from '../containers/AddBlendToCartButton';
import BuyPrice from './BlendBuyPrice';
//Selector
import { deletingBlendTemplateLoadingSelector } from '../selectors';
// Style
import './BlendTemplateListItem.css';
import { deleteBlendTemplate } from '../actions';
// Types
import type {
  BlendTemplate,
  BlendTemplateItem as BlendTemplateItemType,
  Blend,
} from '../types';

type Props = {
  id: number,
  blendTemplate: BlendTemplate,
  blendTemplateItems: BlendTemplateItemType[],
  blend: Blend,
  clickable: boolean,
};

/**
 * Each template shown in the BlendsIndexPage (and other locations) is defined here.
 * Blends that are complete appear with the option of adding to cart or deleting.
 * Blends that are NOT complete appear as editable
 */
export class BlendTemplateListItem extends Component {
  props: Props;

  render() {
    const quantity = this.props.blendTemplate.defaultQuantity / 1000;

    // Sort the items in the blend template by quantity so that the item with the most quantity comes first
    const sortedItems = _.sortBy(
      this.props.blendTemplateItems,
      item => item.quantity,
    );

    // Create array with images from all items together
    const images = [].concat(...sortedItems.map(item => item.variant.images));

    return (
      <div>
        <div className="blend-hit">
          {/* If we have no items we should show the empty box else the image of the first item that has images sorted by amount in blend */}
          {_.isEmpty(this.props.blendTemplate.items) ? (
            <img
              className="blend-hit-box-image"
              src={EmptyBlend}
              alt="Nenhum item no blend"
            />
          ) : (
            <div className="blend-hit-box-image">
              <ProgressiveImageLoader
                selectedImage={images[0] || null}
                imageToShow={'product'}
              />
            </div>
          )}

          <div className="blend-hit-info">
            <h5>{truncateString(this.props.blendTemplate.name, 24)}</h5>
            {this.props.blendTemplate.completedAt ? (
              <p>{`Montado ${getTimeFormatL(
                this.props.blendTemplate.completedAt,
              )}`}</p>
            ) : (
              <p>Em aberto</p>
            )}
            <span>
              {`Quantidade padrão: ${quantity.toLocaleString('pt-br')}Kg`}{' '}
            </span>
            <p>{this.props.blendTemplate.observation}</p>
          </div>

          {/* Show appropriate actions depending on blend being complete or not */}
          {this.props.blendTemplate.completedAt ? (
            <div className="blend-actions">
              {/* TODO: This price should show the price/kg when not in cart or the total with a smaller price/kg under when the blend is in the cart. Similar to how product prices work */}

              <BuyPrice
                blend={this.props.blend}
                listPrice={this.props.blendTemplate.price}
                price={this.props.blendTemplate.price}
                unit={'g'}
              />
              <div className="blend-hit-quantity">
                <AddBlendToCartButton
                  blendTemplateId={this.props.blendTemplate.id}
                  unitForQuantity={'g'}
                  defaultQuantity={this.props.blendTemplate.defaultQuantity}
                  incrementQuantity={this.props.blendTemplate.incrementQuantity}
                  blend={this.props.blend}
                  clickable={this.props.clickable}
                />
                {this.props.blend ? (
                  this.props.blend.quantity === this.props.blend.minQuantity ? (
                    <span>
                      {`Mínimo: ${(
                        this.props.blend.minQuantity / 1000
                      ).toLocaleString('pt-br', {
                        currency: 'BRL',
                      })} Kg`}
                    </span>
                  ) : null
                ) : null}
              </div>

              {/* TODO: There should be a way to delete the template after it is complete. However, the button should not appear if the mix belonging to this template is currently in the cart. Else the person might delete the template while attempting to remove it from the cart. */}
            </div>
          ) : (
            <div className="blend-edit-actions">
              <Link
                className="fa fa-edit blend-template-edit"
                aria-hidden="true"
                to={`/blend/calculadora/${this.props.blendTemplate.id}/`}
              />
              {!this.props.deletingLoading ? (
                <button
                  className="fa fa-trash-o blend-template-trash"
                  aria-hidden="true"
                  onClick={() =>
                    this.props.deleteBlend(this.props.blendTemplate.id)
                  }
                  disabled={this.props.deletingLoading}
                />
              ) : (
                <div className="trash-loader">
                  <i className="fa fa-spinner fa-2x fa-pulse" />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    deletingLoading: deletingBlendTemplateLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    deleteBlend: blendId => {
      dispatch(deleteBlendTemplate(blendId));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(BlendTemplateListItem);
