import branch from 'branch-sdk';
import { logException, logMessage } from '../logHelper';

// Attempts to keep the same signature as branch.getLatestReferringParams() from their ReactNative library
export const getLatestReferringParams = () =>
  new Promise((resolve, reject) => {
    // Since this may be used in important locations (such as new user registration) add a protection against taking too long
    setTimeout(() => {
      resolve({ promise_timeout: true });
    }, 1500);
    try {
      branch.data((err, data) => {
        if (err) {
          logMessage('Branch error', err);
          resolve({ error: true }); // We should not throw errors since it could impede a user from registering
        } else {
          resolve(data.data_parsed);
        }
      });
    } catch (e) {
      logException(e, null, 'warning');
      resolve({ error: true });
    }
  });

// Attempts to keep the same signature as branch.getFirstReferringParams() from their ReactNative library
export const getFirstReferringParams = () =>
  new Promise((resolve, reject) => {
    // Since this may be used in important locations (such as new user registration) add a protection against taking too long
    setTimeout(() => {
      resolve({ promise_timeout: true });
    }, 1500);
    try {
      branch.first((err, data) => {
        if (err) {
          logMessage('Branch error', err);
          resolve({ error: true }); // We should not throw errors since it could impede a user from registering
        } else {
          resolve(data.data_parsed);
        }
      });
    } catch (e) {
      logException(e, null, 'warning');
      resolve({ error: true });
    }
  });
