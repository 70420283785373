// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
// Selectors
import {
  gettingOrderLoadingSelector,
  itemCountSelector,
  itemTotalSumSelector,
  itemsSelector,
  itemsWithBlendsSelector,
  lineItemTotalByUnitSelector,
  removingItemLoadingSelector,
  currentOrderBigClubInfoSelector,
} from '../selectors';
import {
  bigClubSettingsSelector,
  featuresConfigSelector,
} from '../../preferences/selectors';
// Actions
import { deleteItem, findOrCreateOrder, initiateCheckout } from '../actions';
// Components
import { Helmet } from 'react-helmet';
import Cart from '../components/Cart';
// Types
import type { FeaturesConfig, BigClubSettings } from '../../preferences/types';
import type { Dispatch } from '../../types';
import type { LineItem } from '../types';
// Analytics
import * as Analytics from '../../common/analytics';

type Props = {
  items: LineItem[],
  itemsWithBlendId: LineItem[],
  totalPrice: number,
  totalKg: number,
  totalUnit: number,
  loadingOrder: boolean,
  handleChangeQuantity: (lineItemId: number, quantity: number) => void,
  handleRemoveProduct: (lineItemId: number) => void,
  handleInitiateCheckout: () => void,
  featuresConfig: FeaturesConfig,
  bigClubSettings: BigClubSettings,
};

const CartPage = (props: Props) => {
  useEffect(() => {
    props.findOrCreateOrder();
    Analytics.logViewCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cashbackSettings = props.bigClubSettings?.cashback;

  return (
    <div>
      <Helmet>
        <title>Meu Carrinho - Bigdelivery - Seu açougue virtual</title>
        <meta
          property="og:title"
          content="Meu Carrinho - Bigdelivery - Seu açougue virtual"
        />
        <meta
          name="og:description"
          content="Carne fresca e de qualidade na porta da sua casa, na sua hora e do seu jeito."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/white_logo_on_red_background.jpg`}
        />
        <meta property="og:image:type" content="image/jpg" />
        <meta property="og:image:alt" content="Bigdelivery logo" />
      </Helmet>
      <Cart
        items={props.items}
        blends={props.itemsWithBlendId}
        totalPrice={props.totalPrice}
        numberOfItems={props.itemCount} // TODO: Won't this throw a length of undefined error? When there are no items.
        totalKg={props.totalKg}
        totalUnit={props.totalUnit}
        handleInitiateCheckout={props.initiateCheckout}
        loading={props.loadingOrder}
        handleRemoveItem={props.handleRemoveItem}
        removingItemLoading={props.removingItemLoading}
        featuresConfig={props.featuresConfig}
        cashbackSettings={cashbackSettings}
        bigClubInfo={props.bigClubInfo}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    items: itemsSelector(state),
    itemsWithBlendId: itemsWithBlendsSelector(state),
    itemCount: itemCountSelector(state),
    totalPrice: itemTotalSumSelector(state),
    totalKg: lineItemTotalByUnitSelector(state, 'g'),
    totalUnit: lineItemTotalByUnitSelector(state, 'un'),
    loadingOrder: gettingOrderLoadingSelector(state),
    removingItemLoading: removingItemLoadingSelector(state),
    featuresConfig: featuresConfigSelector(state),
    bigClubSettings: bigClubSettingsSelector(state),
    bigClubInfo: currentOrderBigClubInfoSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    findOrCreateOrder: () => {
      dispatch(findOrCreateOrder());
    },
    initiateCheckout: () => {
      dispatch(initiateCheckout());
    },
    handleRemoveItem: (lineItemId: number) => {
      dispatch(deleteItem(lineItemId));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(CartPage);
