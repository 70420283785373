// @flow
import React, { Component } from 'react';

import './PriceInformationNotice.css';

class PriceInformationNotice extends Component {
  render() {
    return (
      <div className={`price-information-notice-main`}>
        <div className="price-information-notice-container">
          <div className="price-information-notice-content">
            <p>
              Faremos o possível para que o peso pedido seja igual ao entregue.
              {' '}
              Porém, variações na carne e cortes podem ocasionar em pesos diferentes do selecionado.
              {' '}
              Caso isso aconteça, você pagará o preço de acordo com peso e não pelo valor estimado.
              Ex: Se o pedido foi de 1,5 Kg mas pesamos 1,35 Kg, cobraremos apenas pelos 1,35 Kg.
              {' '}
              O mesmo ocorre caso o peso exceda o pedido.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default PriceInformationNotice;
