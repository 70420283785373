// @flow
import { handleActions, combineActions } from 'redux-actions';
import { combineReducers } from 'redux';
import _ from 'lodash';
// Actions
import * as actions from '../actions';
// Types
import type { Reducer } from 'redux';
import type { Action } from 'redux-actions';

/**
 * Main objects related to blends:
 *    blendTemplate - Top level entity from which blends are created.
 *    blendTemplateItem - Specified how much of each variant is part of a blend in its default quantity.
 *    blend - Created from a blendTemplate. lineItems are associated to it.
 */

// Keeps track of the ID of the blend template created from the last POST request to create a new blend template
const newlyCreatedBlendTemplateId = handleActions(
  {
    [actions.newBlendTemplate]: {
      next: (state, action) => action.payload.result,
      throw: (state, action) => state,
    },
    [actions.newBlendTemplateRequest]: {
      next: (state, action) => null,
    },
  },
  null,
);

// Blends index reference
const currentBlendTemplateIdList: Reducer<string, Action> = handleActions(
  {
    [combineActions(actions.fetchBlendTemplates)]: {
      next: (state, action) => action.payload.result,
      throw: (state, action) => state,
    },
    [combineActions(actions.fetchBlendTemplate, actions.newBlendTemplate)]: {
      next: (state, action) => _.uniq([...state, action.payload.result]),
      throw: (state, action) => state,
    },
    [actions.removeBlendTemplate]: {
      next: (state, action) =>
        _.filter(state, id => id !== action.payload.result),
      throw: (state, action) => state,
    },
  },
  [],
);

const blendTemplates: Reducer<string, Action> = handleActions(
  {
    [combineActions(
      actions.fetchBlendTemplates,
      actions.fetchBlendTemplate,
      actions.newBlendTemplate,
      actions.changeBlendTemplate,
    )]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.blendTemplates,
      }),
      throw: (state, action) => state,
    },
    [actions.removeBlendTemplate]: {
      next: (state, action) => _.omit(state, action.payload),
      throw: (state, action) => state,
    },
  },
  {},
);

// Items that belong to a blend template. NOTE: These are NOT the same as the line items.
const blendTemplateItems: Reducer<string, Action> = handleActions(
  {
    [combineActions(
      actions.fetchBlendTemplate,
      actions.fetchBlendTemplates,
      actions.changeBlendTemplate,
    )]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.blendTemplateItems,
      }),
      throw: (state, action) => state,
    },
    [actions.changeBlendTemplateItem]: {
      next: (state, action) => ({
        ...state,
        [action.payload.itemId]: {
          ...state[action.payload.itemId],
          quantity: action.payload.quantity,
        },
      }),
    },
    [actions.removeItemFromBlendTemplate]: {
      next: (state, action) => _.omit(state, action.payload.itemId),
      throw: (state, action) => state,
    },
  },
  {},
);

// Blends index reference
const currentBlendList: Reducer<string, Action> = handleActions(
  {
    [combineActions(actions.addBlendtoCart, actions.changeBlend)]: {
      next: (state, action) => _.uniq([...state, action.payload.result]),
      throw: (state, action) => state,
    },
    [combineActions(actions.fetchBlends)]: {
      next: (state, action) => _.uniq([...state, ...action.payload.result]),
      throw: (state, action) => state,
    },
    [actions.removeBlend]: {
      next: (state, action) =>
        _.filter(state, id => id !== action.payload.blendId),
      throw: (state, action) => state,
    },
  },
  [],
);

const blends: Reducer<string, Action> = handleActions(
  {
    [combineActions(actions.addBlendtoCart, actions.fetchBlends)]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.blends,
      }),
      throw: (state, action) => state,
    },
    [actions.changeBlend]: {
      next: (state, action) => ({
        ...state,
        [action.payload.blendId]: {
          ...state[action.payload.blendId],
          quantity: action.payload.quantity,
        },
      }),
    },
    [actions.removeBlend]: {
      next: (state, action) => _.omit(state, action.payload.blendId),
      throw: (state, action) => state,
    },
  },
  {},
);

//////////////////////////// LOADING RELATED ////////////////////////

///////////////// BLEND LOADING ///////////////////////
const addingBlendsLoading = handleActions(
  {
    [actions.addBlendtoCart]: {
      next: (state, action) => {
        // Remove if element exists in the array
        return _.filter(state, o => o !== action.payload.blendTemplateId);
      },
      throw: (state, action) => [],
    },
    [actions.addBlendtoCartRequest]: (state, action) => {
      // Only add blend if it is not in the array
      if (state.indexOf(action.payload.blendTemplateId) === -1) {
        return [...state, action.payload.blendTemplateId];
      }
      return state;
    },
  },
  [],
);

const changingBlendsLoading = handleActions(
  {
    [combineActions(actions.changeBlend, actions.removeBlend)]: (
      state,
      action,
    ) => {
      if (action.meta && !action.meta.isOptimistic) {
        // Remove if element exists in the array
        return _.filter(state, o => o !== action.payload.blendId);
      } else {
        return state;
      }
    },
    // [actions.removeBlend]: {
    //   next: (state, action) =>
    //     _.filter(state, o => o !== action.payload.blendId),
    // },
    [combineActions(actions.changeBlendRequest, actions.removeBlendRequest)]: (
      state,
      action,
    ) => {
      // Only add item if it is not in the array
      if (state.indexOf(action.payload.blendId) === -1) {
        return [...state, action.payload.blendId];
      }
      return state;
    },
  },
  [],
);

/////////////////BLEND TEMPLATE ITEM /////////////////////////
const addingBlendTemplateItemsLoading = handleActions(
  {
    [actions.addItemToBlendTemplate]: {
      next: (state, action) => {
        // Remove if element exists in the array
        return _.filter(state, o => o !== action.payload.variant.id);
      },
      throw: (state, action) => [],
    },
    [actions.addItemToBlendTemplateRequest]: (state, action) => {
      // Only add blend if it is not in the array
      if (state.indexOf(action.payload.itemId) === -1) {
        return [...state, action.payload.itemId];
      }
      return state;
    },
  },
  [],
);
// NOTE: This loader is used by both changeQuantity and delete actions
const changingBlendTemplateItemsLoading = handleActions(
  {
    [combineActions(
      actions.changeBlendTemplateItem,
      actions.removeItemFromBlendTemplate,
    )]: (state, action) => {
      if (action.meta && !action.meta.isOptimistic) {
        // Remove if element exists in the array
        return _.filter(state, o => o !== action.payload.itemId);
      } else {
        return state;
      }
    },
    // [actions.removeItemFromBlendTemplate]: {
    //   next: (state, action) =>
    //     _.filter(state, o => o !== action.payload.itemId),
    // },
    [combineActions(
      actions.changeBlendTemplateItemRequest,
      actions.removeItemFromBlendTemplateRequest,
    )]: (state, action) => {
      // Only add item if it is not in the array
      if (state.indexOf(action.payload.itemId) === -1) {
        return [...state, action.payload.itemId];
      }
      return state;
    },
  },
  [],
);

const initialLoadingState = {
  gettingBlendTemplates: false, // Many
  gettingBlendTemplate: false, // Single
  updatingBlendTemplate: false,
  deletingBlendTemplate: false,
  creatingBlendTemplate: false,

  changingItem: false,
  deletingItem: false,
  addingItem: false,

  gettingBlends: false,
  addingBlend: false,
};
const loading: Reducer<string, Action> = handleActions(
  {
    //////////////////////// BLEND TEMPLATE RELATED ////////////////////
    // Getting all blend templates
    [actions.fetchBlendTemplatesRequest]: (state, action) => ({
      ...state,
      gettingBlendTemplates: true,
    }),
    [actions.fetchBlendTemplates]: (state, action) => ({
      ...state,
      gettingBlendTemplates: false,
    }),
    // Getting a specific blend template
    [actions.fetchBlendTemplateRequest]: (state, action) => ({
      ...state,
      gettingBlendTemplate: true,
    }),
    [actions.fetchBlendTemplate]: (state, action) => ({
      ...state,
      gettingBlendTemplate: false,
    }),
    // Create a new blend template
    [actions.newBlendTemplateRequest]: (state, action) => ({
      ...state,
      creatingBlendTemplate: true,
    }),
    [actions.newBlendTemplate]: (state, action) => ({
      ...state,
      creatingBlendTemplate: false,
    }),
    // Update blend template
    [actions.changeBlendTemplateRequest]: (state, action) => ({
      ...state,
      updatingBlendTemplate: true,
    }),
    [actions.changeBlendTemplate]: (state, action) => ({
      ...state,
      updatingBlendTemplate: false,
    }),
    // Removing a specific blend template
    [actions.removeBlendTemplateRequest]: (state, action) => ({
      ...state,
      deletingBlendTemplate: true,
    }),
    [actions.removeBlendTemplate]: (state, action) => ({
      ...state,
      deletingBlendTemplate: false,
    }),
    //////////////////////// BLEND TEMPLATE ITEM RELATED /////////////////
    // Creating a blend template item based on a given variant and quantity
    [actions.addItemToBlendTemplateRequest]: (state, action) => ({
      ...state,
      addingItem: true,
    }),
    [actions.addItemToBlendTemplate]: (state, action) => ({
      ...state,
      addingItem: false,
    }),
    ///////////////////// BLEND RELATED /////////////////////
    // Getting templates (many)
    [actions.fetchBlendsRequest]: (state, action) => ({
      ...state,
      gettingBlend: true,
    }),
    [actions.fetchBlends]: (state, action) => ({
      ...state,
      gettingBlend: false,
    }),
    // Creating a new blend (which also adds to a specific order)
    [actions.addBlendtoCartRequest]: (state, action) => ({
      ...state,
      addingBlend: true,
    }),
    [actions.addBlendtoCart]: (state, action) => ({
      ...state,
      addingBlend: false,
    }),
    // Deleting blend (removes from a given order)
    [actions.removeBlendRequest]: (state, action) => ({
      ...state,
      deletingBlend: true,
    }),
    [actions.removeBlend]: (state, action) => ({
      ...state,
      deletingBlend: false,
    }),
  },
  initialLoadingState,
);

const reducers = combineReducers({
  newlyCreatedBlendTemplateId,
  blendTemplates,
  currentBlendTemplateIdList,

  blendTemplateItems,

  currentBlendList,
  blends,

  addingBlendTemplateItemsLoading,
  changingBlendTemplateItemsLoading,
  addingBlendsLoading,
  changingBlendsLoading,
  loading,
});

export default reducers;
