// @flow
import React from 'react';
// Styles
import './OrderTrackerMensageState.css';
//Other
import { phone } from '../../common';

type Props = {
  orderState: string,
};

const OrderTrackerMensageState = ({ orderState }: Props) => {
  if (orderState === 'error')
    return (
      <div className="error-on-order-text">
        <hr className="error-on-order-line" />
        <p>Opa! Encontramos um problema com seu pedido</p>
        <p>Já estamos trabalhando para resolve-lo</p>
        <p>Caso precisemos de sua ajuda, entraremos em contato</p>
        <hr className="error-on-order-line" />
      </div>
    );
  else if (orderState === 'payment_denied') {
    return (
      <div className="error-on-order-text">
        <hr className="error-on-order-line" />
        <p>Opa! Encontramos um problema com seu pedido</p>
        <p>Estamos tendo problemas para aceitar o seu método de pagamento</p>
        <p>Entre em contato com a nossa central pelo número</p>{' '}
        <span className="error-telephone">{phone.asStringFull}</span>
        <span className="error-telephone-mobile">
          <a href={`tel:${phone.e164}`}>{phone.asStringFull}</a>
        </span>
        <hr className="error-on-order-line" />
      </div>
    );
  }
};

export default OrderTrackerMensageState;
