// @flow
import React, { useEffect } from 'react';
// Components
import { SearchBar } from '../../search';
import { CurrentUserIndicator } from '../../user';
import { CartIndicator } from '../../current-order';
import { BigClubIndicator } from '../../user';
import { Link, useLocation } from 'react-router-dom';
import { RepeatLastPurchase } from '../../common';
// Styles
import './TopRow.css';
// Assets
import logoHorizontal from '../images/logoHorizontal.svg';
// Helpers
import { useSessionStorage } from '../../common';

type Props = {
  orderNumber: string,
  order: Order[],
  orders: Order[],
  prevOrderItems: LineItem[],
  currentOrderItems: LineItem[],
  getPastOrder: (orderNumber: string) => void,
  copyingOrder: (
    currentOrderNumber: string,
    previousOrderNumber: string,
  ) => void,
  currentOrderNumber: string,
  previousOrderNumber: string,
  loggedIn: boolean,
  loggingInLoading: boolean,
};

const TopRow = (props: Props) => {
  const location = useLocation();
  const shouldClosePopup =
    !props.loggingInLoading &&
    props.loggedIn &&
    props.currentOrderItems?.length > 0;

  const [showPopup, setShowPopup] = useSessionStorage('showPopup', true);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    if ((location.pathname !== '/' && showPopup) || shouldClosePopup) {
      handleClosePopup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, props.loggedIn, props.loggingInLoading]);

  return (
    <div className="toprow-main">
      <SearchBar />
      <Link to="/" className="toprow-main-container">
        <img
          className="toprow-main-logo"
          src={logoHorizontal}
          alt="logo BigDelivery"
        />
      </Link>
      <CurrentUserIndicator />
      <CartIndicator />
      <BigClubIndicator />
      {showPopup && window.location.pathname === '/' && !shouldClosePopup && (
        <RepeatLastPurchase
          orderNumber={props.orderNumber}
          order={props.order}
          orders={props.orders}
          getPastOrder={props.getPastOrder}
          copyingOrder={props.copyingOrder}
          currentOrderNumber={props.currentOrderNumber}
          previousOrderNumber={props.previousOrderNumber}
          copyingOrderLoading={props.copyingOrderLoading}
          setShowPopup={setShowPopup}
          showPopup={showPopup}
          prevOrderItems={props.prevOrderItems}
          currentOrderItems={props.currentOrderItems}
        />
      )}
    </div>
  );
};

export default TopRow;
