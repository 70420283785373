import React, { useEffect, useState } from 'react';
// Styles
import './CountdownTimer.css';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';
// Components
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';

type Props = {
  title?: string,
  description?: string,
  countdownDate: Date,
  backgroundImage?: string,
};

const CountdownTimer = ({
  title,
  description,
  countdownDate,
  backgroundImage,
}: Props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const labelStyle = () => ({
    color: 'white',
    fontWeight: 600,
    fontSize: windowWidth < 500 ? '14px' : windowWidth < 600 ? '18px' : '24px',
  });

  const getWidth = windowWidth => {
    if (windowWidth < 500) return '30px';
    if (windowWidth < 600) return '40px';
    if (windowWidth < 1368) return '50px';
    return '70px';
  };

  const digitBlockStyle = () => ({
    background: 'white',
    color: '#CE3C25',
    fontWeight: 700,
    fontSize: windowWidth < 500 ? '30px' : windowWidth < 600 ? '40px' : '60px',
    width: getWidth(windowWidth),
    height: windowWidth < 500 ? '70px' : '100px',
    boxShadow: '0 0 10px #CE3C25',
    border: '1px solid red',
  });

  const separatorStyle = () => ({
    color: 'white',
    size: windowWidth < 600 ? '4px' : '6px',
  });

  const dividerStyle = () => ({
    color: '#CE3C25',
    height: windowWidth < 600 ? '2px' : '4px',
  });

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section
      className="countdown-wrapper"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <h1 className="countdown-title">{title}</h1>
      <div className="countedown-inner-wrapper">
        <h3 className="countdown-description">{description}</h3>
        <FlipClockCountdown
          to={countdownDate}
          showLabels={true}
          renderMap={[true, true, true, true]}
          labels={['Dias', 'Horas', 'Minutos', 'Segundos']}
          labelStyle={labelStyle()}
          digitBlockStyle={digitBlockStyle()}
          separatorStyle={separatorStyle()}
          dividerStyle={dividerStyle()}
        />
      </div>
    </section>
  );
};

export default CountdownTimer;
