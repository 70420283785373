// @flow
import React, { useEffect } from 'react';
// Components
import { Helmet } from 'react-helmet';
import Button from '../components/Button';
import { UnauthenticatedRedirect } from '../../user';
// Helpers
import { priceConverter } from '../../common';
// Analytics
import * as Analytics from '../../common/analytics';
// Composers
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// Selectors
import {
    userIdSelector,
    invitedUsersSelector,
    referralInformationSelector
} from '../selectors';
// Types
import type { ContextRouter } from 'react-router-dom';
// Styles
import './ReferralPage.css';
import ReferralInvitedUsersList from '../components/ReferralInvitedUsersList';
import { logException } from '../../logHelper';
import { getInvitedUsers } from '../actions';

type RouterProps = ContextRouter;
type Props = {
    userId: number,
    referralInformation: any,
    invitedUsers: any,
} & RouterProps;

const ReferralPage = (props: Props) => {
    useEffect(() => {
        if (props.userId) {
            props.getInvitedUsers(props.userId);
            // USE ANALYTICS
            Analytics.logUserOpenedReferralPage();
        }
    }, [props.userId]);

    const copyReferralToClipboard = () => {
        navigator.clipboard.writeText(props.referralInfo.referralCode);
    };

    const copyBranchLinkToClipboard = () => {
        navigator.clipboard.writeText(props.referralInfo.branchLink);
        showShareButtonCopyTooltip();
    };

    const handleClickOnCopyToClipboard = (e) => {
        e.preventDefault();
        copyReferralToClipboard();
        document.getElementById('tooltiptext').innerHTML = 'Copiado!';
        Analytics.logUserCopiedReferralCode(props.referralInfo.referralCode);

        return false;
    }

    const handleOnMouseOutOnCopyToClipboard = () => {
        document.getElementById('tooltiptext').innerHTML = 'Copiar p/ área de transferência';
    };

    const handleOnClickShare = (e) => {
        e.preventDefault();
        if (navigator.share) {
            try {
                navigator.share({
                    title: 'Bigdelivery - Comprar carne nunca foi tão fácil',
                    text: 'Você recebeu uma indicação para conhecer o Bigdelivery. Experimente a \
                           nova forma de pedir carnes, sem filas, sem engarrafamentos, receba seu \
                           pedido sem sair de casa!',
                    url: props.referralInfo.branchLink
                });
            } catch (err) {
                logException(err)
                copyBranchLinkToClipboard();
            }
        } else {
            copyBranchLinkToClipboard();
        }
        Analytics.logUserSharedReferralLink();
    }

    const handleOnMouseOutOnShareButton = () => {
        const shareSpan = document.getElementById('share-tooltiptext');
        shareSpan.innerHTML = '';
        shareSpan.style.display = 'none';
    };

    const showShareButtonCopyTooltip = () => {
        const shareSpan = document.getElementById('share-tooltiptext');
        shareSpan.innerHTML = 'Copiado!';
        shareSpan.style.display = 'inherit';
    }

    return (
        <UnauthenticatedRedirect>
            <div>
                <Helmet>
                    <title>Convide seus amigos!</title>
                    <meta
                        property="og:title"
                        content="Convide seus amigos!"
                    />
                    <meta
                        name="description"
                        content="Convide seus amigos e ganhe R$ 10,00."
                    />
                    <meta
                        name="og:description"
                        content="Convide seus amigos e ganhe R$ 10,00."
                    />
                    <meta
                        property="og:image"
                        content={`${process.env.PUBLIC_URL}/white_logo_on_red_background.jpg`}
                    />
                    <meta property="og:image:type" content="image/jpg" />
                    <meta property="og:image:alt" content="Bigdelivery logo" />
                </Helmet>
                <div className="referral-page">
                    <div className="referral-banner" />
                    <div className="referral-description">
                        <div className="phrase">Com o Bigdelivery você e seus amigos não vão perder mais nenhum segundo com suas receitas ou churrascos, é só temperar e&nbsp;brasa!</div>
                    </div>
                    <div className='referral-code-content'>
                        <div className='title'>SEU CÓDIGO É</div>
                        <div className='value'>
                            <div className="referral-tooltip">
                                <a href="#" className="referral-copy-button"
                                    onClick={handleClickOnCopyToClipboard}
                                    onMouseOut={handleOnMouseOutOnCopyToClipboard}>
                                    <span id="referral-code">{props.referralInfo.referralCode}</span>
                                    <span id="tooltiptext" className="tooltiptext">Copiar p/ área de transferência</span>
                                </a>
                            </div>
                        </div>
                        <div className={`share-button ${navigator.share ? '' : 'referral-tooltip'}`}>
                            <Button
                                text={navigator.share ? "Compartilhar" : "Copiar link"}
                                color="white"
                                icon={navigator.share ? "fa fa-share-alt" : null}
                                onClick={handleOnClickShare}
                                onMouseOut={handleOnMouseOutOnShareButton}
                            >
                                <span id="share-tooltiptext" className="tooltiptext"></span>
                            </Button>
                        </div>
                        <div className="referral-share-content">
                            Convide seus amigos! Quando criarem uma conta usando o seu código e receberem o&nbsp;primeiro&nbsp;pedido,&nbsp;você
                            <div className="prize">GANHARÁ R$10,00</div>
                        </div>
                        {props.referralInfo.totalReferralEarnings ? <div className="referral-total-earnings">
                            Você já ganhou&nbsp;{priceConverter(props.referralInfo.totalReferralEarnings)}
                        </div> : ''}

                        <ReferralInvitedUsersList invitedUsers={props.invitedUsers} />

                        <div className="referral-prize-explanation">Os R$10,00 serão destinados ao <b>Saldo</b> da sua conta e automaticamente aplicados no seu próximo pedido do Bigdelivery!</div>
                    </div>
                </div>

            </div>
        </UnauthenticatedRedirect>);
}

const mapStateToProps = state => {
    return {
        userId: userIdSelector(state),
        referralInfo: referralInformationSelector(state),
        invitedUsers: invitedUsersSelector(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, stateProps) => {
    return {
        getInvitedUsers: (userId: number) => {
            dispatch(getInvitedUsers(userId));
        },
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ReferralPage),
);
