// @flow
import React from 'react';
import { Link } from 'react-router-dom';
// Components
import { ProgressiveImageLoader } from '../../../common';
// Styles
import './MyAccountEvaluationsItem.css';

const MyAccountEvaluationsItem = () => {
  return (
    <div className="my-account-evaluations-item">
      <ProgressiveImageLoader />
      <div className="my-account-evaluations-item-row">
        <div className="my-account-evaluations-item-row-info">
          <span>Nome do produto</span>
          <h5>Hamburguer de Costela Bovina 180G</h5>
        </div>
        <div className="my-account-evaluations-item-row-info">
          <span>Número do pedido</span>
          <h5>#R2D2D3</h5>
        </div>
        <div className="my-account-evaluations-item-row-info">
          <span>Data da compra</span>
          <h5>23/10/2023 às 21:45</h5>
        </div>
      </div>
      <Link className="my-account-evaluations-item-link">Avaliar Produto</Link>
    </div>
  );
};

export default MyAccountEvaluationsItem;
