import { createAction } from 'redux-actions';
// API
import Barbecue from '../api';
// Types
import type { Dispatch } from '../../types';
// Logger
import { logAPIException } from '../../logHelper';

export const getBarbecueCategories = createAction('GET_BARBECUE_CATEGORIES');
export const getBarbecueCategoriesRequest = createAction(
  'GET_BARBECUE_CATEGORIES_REQUEST',
);
export function fetchBarbecueCategories() {
  return async (dispatch: Dispatch) => {
    dispatch(getBarbecueCategoriesRequest());
    try {
      const response = await Barbecue.getBarbecueCategories();
      dispatch(getBarbecueCategories(response));
    } catch (err) {
      dispatch(getBarbecueCategories(err));
      logAPIException(err);
    }
  };
}

export const getBarbecueOptionValues = createAction(
  'GET_BARBECUE_OPTION_VALUES',
);
export const getBarbecueOptionValuesRequest = createAction(
  'GET_BARBECUE_OPTION_VALUES_REQUEST',
);

export function fetchOptionValues() {
  return async (dispatch: Dispatch) => {
    dispatch(getBarbecueOptionValuesRequest());
    try {
      const response = await Barbecue.getBarbecueOptionValues();
      dispatch(getBarbecueOptionValues(response));
    } catch (err) {
      dispatch(getBarbecueOptionValues(err));
      logAPIException(err);
    }
  };
}

export const postBarbecue = createAction('POST_BARBECUE');
export const postBarbecueRequest = createAction('POST_BARBECUE_REQUEST');

export function createBarbecue(
  totalDesiredQuantity: number,
  preferredOptionValueId: Array<number>,
  desiredCategories: Array<number>,
  fatPercentage: number,
) {
  return async (dispatch: Dispatch) => {
    dispatch(postBarbecueRequest());
    try {
      const response = await Barbecue.postBarbecue(
        totalDesiredQuantity,
        preferredOptionValueId,
        desiredCategories,
        fatPercentage,
      );
      dispatch(postBarbecue(response));
    } catch (err) {
      dispatch(postBarbecue(err));
      logAPIException(err);
    }
  };
}
