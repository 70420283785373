// @flow
import React, { useEffect, useState } from 'react';
// Components
import {
  validationHelpers,
  cityDropDownHelper,
  InputWithAddressAutocomplete,
  FormControlInput,
  Button,
  formatPhoneNumber,
  formatZipcode,
  RadioButton,
} from '../../../common';
// Types
import type { Address } from '../../../types';
// Analytics
import * as Analytics from '../../../common/analytics';
// Style
import './AddressForm.css';

type Props = {
  address: ?Address,
  loading: boolean,
  onSubmitAddress: (address: Address) => void,
  onCancel: () => void,
};

type FormValues = {
  receiverName: string,
  addressLine1: string,
  addressLine2: string,
  city: string,
  zipcode: string,
  neighborhood: string,
  telephone: string,
  cnpj: string,
  ieCompany: string,
  company: string,
  isCompanyAddress: boolean,
  reference: string,
  residenceNumber: string,
  residenceType: string,
  residenceData: string,
};

type FormErrors = {
  receiverNameError: string,
  addressLine1Error: string,
  addressLine2Error: string,
  cityError: string,
  zipcodeError: string,
  neighborhoodError: string,
  telephoneError: string,
  referenceError: string,
  cnpjError: string,
  ieCompanyError: string,
  companyError: string,
  residenceNumberError: string,
  residenceTypeError: string,
  residenceDataError: string,
};

const initialFormValues = {
  receiverName: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  zipcode: '',
  neighborhood: '',
  telephone: '',
  reference: '',
  cnpj: '',
  ieCompany: '',
  company: '',
  isCompanyAddress: false,
  residenceNumber: '',
  residenceType: '',
  residenceData: '',
};

const initialErrorsValues = {
  receiverNameError: '',
  addressLine1Error: '',
  addressLine2Error: '',
  cityError: '',
  zipcodeError: '',
  neighborhoodError: '',
  telephoneError: '',
  referenceError: '',
  cnpjError: '',
  ieCompanyError: '',
  companyError: '',
  residenceNumberError: '',
  residenceTypeError: '',
  residenceDataError: '',
};

const AddressForm = (props: Props) => {
  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);
  const [formErrors, setFormErrors] = useState<FormErrors>(initialErrorsValues);
  const isFormValuesInvalid = Object.keys(formErrors).some(
    error => !!formErrors[error],
  );

  const loadFormValues = () => {
    if (props.address.id) {
      setFormValues(() => ({
        receiverName: !!props.address ? props.address.receiverName : '',
        addressLine1: !!props.address ? props.address.addressLine1 : '',
        addressLine2: !!props.address ? props.address.addressLine2 : '',
        city: !!props.address
          ? props.address.city
          : cityDropDownHelper.cities[0],
        zipcode: !!props.address ? props.address.zipcode : '',
        neighborhood: !!props.address ? props.address.neighborhood : '',
        telephone: !!props.address ? props.address.telephone : '',
        reference: !!props.address ? props.address.reference : '',
        residenceNumber: !!props.address ? props.address.residenceNumber : '',
        residenceType: !!props.address ? props.address.residenceType : '',
        residenceData: !!props.address ? props.address.residenceData : '',
        cnpj: !!props.address ? props.address.cnpj : '',
        ieCompany: !!props.address ? props.address.ieCompany || '' : '',
        company: !!props.address ? props.address.company : '',
        isCompanyAddress: !!props.address ? props.address.cnpj : false,
        id: props.address ? props.address.id : null,
      }));
    } else {
      setFormValues(preValues => ({
        ...preValues,
        city: cityDropDownHelper.cities[0],
      }));
    }
  };

  const handleFormChange = (target: string, value: string) => {
    setFormValues(prevValues => ({ ...prevValues, [target]: value }));
    setFormErrors(prevErrors => ({ ...prevErrors, [`${target}Error`]: '' }));
  };

  const handleSelectAddress = (address: Address) => {
    setFormValues(prevValues => ({ ...prevValues, ...address }));
    const addressLine1Error = validationHelpers.address.addressLine1(address);
    setFormErrors(prevErrors => ({
      ...prevErrors,
      addressLine1Error,
    }));
  };

  const handleToggleIsCompanyAddress = () => {
    if (formValues.isCompanyAddress) {
      return setFormValues(prevValues => ({
        ...prevValues,
        isCompanyAddress: false,
        company: '',
        ieCompany: '',
        cnpj: '',
      }));
    }
    setFormValues(prevValues => ({ ...prevValues, isCompanyAddress: true }));
  };

  const handleToggleResidenceType = () => {
    setFormValues(prevValues => {
      if (prevValues.residenceType === 'apartment') {
        return {
          ...prevValues,
          residenceType: 'house',
          residenceNumber: null,
          residenceData: null,
        };
      } else {
        return {
          ...prevValues,
          residenceType: 'apartment',
        };
      }
    });
  };

  const validateUserInput = () => {
    const receiverNameError = validationHelpers.address.name(
      formValues.receiverName,
    );

    const addressLine1Error = validationHelpers.address.addressLine1(
      formValues.addressLine1,
    );
    const addressLine2Error = validationHelpers.address.addressLine1(
      formValues.addressLine2,
    );
    const cityError = validationHelpers.address.city(formValues.city);
    const zipcodeError = validationHelpers.address.zipcode(formValues.zipcode);
    const neighborhoodError = validationHelpers.address.neighborhood(
      formValues.neighborhood,
    );
    const telephoneError = validationHelpers.address.telephone(
      formValues.telephone,
    );
    const referenceError = validationHelpers.address.pointOfReference(
      formValues.reference,
    );
    const cnpjError = formValues.isCompanyAddress
      ? validationHelpers.address.cnpj(formValues.cnpj)
      : '';
    const ieCompanyError = formValues.isCompanyAddress
      ? validationHelpers.address.ieCompany(formValues.ieCompany)
      : '';
    const companyError = formValues.isCompanyAddress
      ? validationHelpers.address.company(formValues.company)
      : '';
    const residenceNumberError = formValues.residenceNumber
      ? validationHelpers.address.residenceNumber(formValues.residenceNumber)
      : '';
    const residenceTypeError = formValues.residenceType
      ? validationHelpers.address.residenceType(formValues.residenceType)
      : '';
    const residenceDataError = formValues.residenceData
      ? validationHelpers.address.residenceData(formValues.residenceData)
      : '';

    const errors = {
      receiverNameError,
      addressLine1Error,
      addressLine2Error,
      cityError,
      zipcodeError,
      neighborhoodError,
      telephoneError,
      referenceError,
      cnpjError,
      ieCompanyError,
      companyError,
      residenceNumberError,
      residenceTypeError,
      residenceDataError,
    };

    const isInvalid = Object.keys(errors).some(value => !!errors[value]);
    setFormErrors(errors);
    return isInvalid;
  };

  const handleSubmit = () => {
    const isInvalid = validateUserInput();
    if (isInvalid) return;
    props.onSubmitAddress(formValues);
  };

  const handleCancel = () => {
    props.onCancel();
  };

  useEffect(() => {
    loadFormValues();
    // eslint-disable-next-line
  }, [props.address?.id]);

  return (
    <form
      className="address-form"
      onSubmit={event => {
        event.preventDefault();
      }}
    >
      <section className="address-form-info">
        <h3 className="address-form-title">Informações do destinatário</h3>
        <div className="address-form-info-row">
          <FormControlInput
            label="Nome do destinatário"
            variant="rounded"
            handleChangeText={value => handleFormChange('receiverName', value)}
            handleFocus={value => handleFormChange('receiverName', value)}
            value={formValues.receiverName}
            handleBlur={() => {
              setFormErrors(prevValues => ({
                ...prevValues,
                receiverNameError: validationHelpers.address.name(
                  formValues.receiverName,
                ),
              }));
            }}
            errorMessage={formErrors.receiverNameError}
          />
          <FormControlInput
            label="Telefone"
            variant="rounded"
            handleChangeText={value => handleFormChange('telephone', value)}
            handleFocus={value => handleFormChange('telephone', value)}
            value={formatPhoneNumber(formValues.telephone)}
            handleBlur={() => {
              setFormErrors(prevValues => ({
                ...prevValues,
                telephoneError: validationHelpers.address.telephone(
                  formValues.telephone,
                ),
              }));
            }}
            errorMessage={formErrors.telephoneError}
          />
        </div>
      </section>
      <section className="address-form-info">
        <h3 className="address-form-title">Local de entrega</h3>
        <div className="address-form-info-row-start">
          <RadioButton
            text="Residencial"
            isSelected={!formValues.isCompanyAddress}
            onSelect={() => handleToggleIsCompanyAddress()}
          />

          <RadioButton
            text="Comercial"
            isSelected={formValues.isCompanyAddress}
            onSelect={() => handleToggleIsCompanyAddress()}
          />
        </div>
        <div className="address-form-info-row-start">
          <RadioButton
            text="Apartamento"
            isSelected={formValues.residenceType === 'apartment'}
            onSelect={() => handleToggleResidenceType()}
          />
          <RadioButton
            text="Casa"
            isSelected={
              !formValues.residenceType || formValues.residenceType === 'house'
            }
            onSelect={() => handleToggleResidenceType()}
          />
        </div>
        <div className="address-form-info-row">
          <InputWithAddressAutocomplete
            value={formValues.addressLine1}
            onChange={value => {
              setFormValues(prevValues => ({
                ...prevValues,
                addressLine1: value,
              }));
              const addressLine1Error =
                validationHelpers.address.addressLine1(value);
              setFormErrors(prevErrors => ({
                ...prevErrors,
                addressLine1Error,
              }));
            }}
            errorMessage={formErrors.addressLine1Error}
            onAddressSelect={placeDetails => handleSelectAddress(placeDetails)}
          />

          <FormControlInput
            label="Bairro"
            variant="rounded"
            handleChangeText={value => handleFormChange('neighborhood', value)}
            handleFocus={value => handleFormChange('neighborhood', value)}
            value={formValues.neighborhood}
            handleBlur={() => {
              const neighborhoodError = validationHelpers.address.neighborhood(
                formValues.neighborhood,
              );
              setFormErrors(prevValues => ({
                ...prevValues,
                neighborhoodError,
              }));
            }}
            errorMessage={formErrors.neighborhoodError}
          />
        </div>
        <div className="address-form-info-row">
          <FormControlInput
            label="CEP"
            variant="rounded"
            handleChangeText={value => handleFormChange('zipcode', value)}
            handleFocus={value => handleFormChange('zipcode', value)}
            value={formatZipcode(formValues.zipcode)}
            maxLength={9}
            handleBlur={() => {
              const zipcodeError = validationHelpers.address.zipcode(
                formValues.zipcode,
              );
              setFormErrors(prevValues => ({
                ...prevValues,
                zipcodeError,
              }));
            }}
            errorMessage={formErrors.zipcodeError}
          />
          <div className="address-form-select-dropdown-container">
            <select
              className="address-form-select-dropdown"
              onChange={e => {
                Analytics.logSelectedAddressCity(e.target.value);
                setFormValues(prevValues => ({
                  ...prevValues,
                  city: e.target.value,
                }));
                const cityError = validationHelpers.address.city(
                  e.target.value,
                );
                setFormErrors(prevErrors => ({
                  ...prevErrors,
                  cityError,
                }));
              }}
              value={formValues.city}
            >
              {cityDropDownHelper.cities.map((city, i) => {
                return (
                  <option key={i} value={city}>
                    {city}
                  </option>
                );
              })}
            </select>
            {formErrors.cityError && (
              <span className="address-form-select-dropdown-error address-form-select-dropdown-error-animation">
                {formErrors.cityError}
              </span>
            )}
          </div>
        </div>
        {formValues.residenceType === 'apartment' && (
          <div className="address-form-info-row">
            <FormControlInput
              label="Número do Apartamento"
              variant="rounded"
              handleChangeText={value =>
                handleFormChange('residenceNumber', value)
              }
              handleFocus={value => handleFormChange('residenceNumber', value)}
              value={formValues.residenceNumber}
              handleBlur={() => {
                const residenceNumberError =
                  validationHelpers.address.residenceNumber(
                    formValues.residenceNumber,
                  );
                setFormErrors(prevValues => ({
                  ...prevValues,
                  residenceNumberError,
                }));
              }}
              errorMessage={formErrors.residenceNumberError}
            />
            <FormControlInput
              label="Bloco"
              variant="rounded"
              handleChangeText={value =>
                handleFormChange('residenceData', value)
              }
              handleFocus={value => handleFormChange('residenceData', value)}
              value={formValues.residenceData}
              handleBlur={() => {
                const residenceDataError =
                  validationHelpers.address.residenceData(
                    formValues.residenceData,
                  );
                setFormErrors(prevValues => ({
                  ...prevValues,
                  residenceDataError,
                }));
              }}
              errorMessage={formErrors.residenceDataError}
            />
          </div>
        )}

        <div className="address-form-info-row">
          <FormControlInput
            label="Complemento"
            variant="rounded"
            handleChangeText={value => handleFormChange('addressLine2', value)}
            handleFocus={value => handleFormChange('addressLine2', value)}
            value={formValues.addressLine2}
            handleBlur={() => {
              const addressLine2Error = validationHelpers.address.addressLine2(
                formValues.addressLine2,
              );
              setFormErrors(prevValues => ({
                ...prevValues,
                addressLine2Error,
              }));
            }}
            errorMessage={formErrors.addressLine2Error}
          />
          <FormControlInput
            label="Ponto de referência"
            variant="rounded"
            handleChangeText={value => handleFormChange('reference', value)}
            handleFocus={value => handleFormChange('reference', value)}
            value={formValues.reference}
            handleBlur={() => {
              const referenceError = validationHelpers.address.pointOfReference(
                formValues.reference,
              );
              setFormErrors(prevValues => ({
                ...prevValues,
                referenceError,
              }));
            }}
            errorMessage={formErrors.referenceError}
          />
        </div>
      </section>
      {formValues.isCompanyAddress && (
        <section className="address-form-info">
          <h3 className="address-form-title">Dados da empresa</h3>
          <div className="address-form-info-row">
            <FormControlInput
              label="Nome da empresa"
              variant="rounded"
              handleChangeText={value => handleFormChange('company', value)}
              handleFocus={value => handleFormChange('company', value)}
              value={formValues.company}
              handleBlur={() => {
                const companyError = validationHelpers.address.company(
                  formValues.company,
                );
                setFormErrors(prevValues => ({
                  ...prevValues,
                  companyError,
                }));
              }}
              errorMessage={formErrors.companyError}
            />
            <FormControlInput
              label="CNPJ"
              variant="rounded"
              handleChangeText={value => handleFormChange('cnpj', value)}
              handleFocus={value => handleFormChange('cnpj', value)}
              value={formValues.cnpj}
              handleBlur={() => {
                const cnpjError = validationHelpers.address.cnpj(
                  formValues.cnpj,
                );
                setFormErrors(prevValues => ({
                  ...prevValues,
                  cnpjError,
                }));
              }}
              errorMessage={formErrors.cnpjError}
            />
          </div>
          <div className="address-form-info-row">
            <FormControlInput
              label="Inscrição estadual"
              variant="rounded"
              handleChangeText={value => handleFormChange('ieCompany', value)}
              handleFocus={value => handleFormChange('ieCompany', value)}
              value={formValues.ieCompany}
              handleBlur={() => {
                const ieCompanyError = validationHelpers.address.ieCompany(
                  formValues.ieCompany,
                );
                setFormErrors(prevValues => ({
                  ...prevValues,
                  ieCompanyError,
                }));
              }}
              errorMessage={formErrors.ieCompanyError}
            />
          </div>
        </section>
      )}
      <div className="address-form-actions">
        <Button
          type="submit"
          text="Salvar endereço"
          icon=""
          loading={props.loading}
          onClick={handleSubmit}
          disabled={isFormValuesInvalid}
          buttonType="button-primary"
        />
        <Button
          text="Cancelar"
          icon=""
          onClick={handleCancel}
          buttonType="button-secondary"
        />
      </div>
    </form>
  );
};

export default AddressForm;
