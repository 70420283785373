//@flow
import React from 'react';
//Styles
import './OptionBox.css';
//Helpers
import { scaleConverter } from '../../common';

type Props = {
  imgSrc: string,
  text: string,
  isSelected: boolean,
  optionPrice: string,
  productPrice: string,
  productUnit: string,
};

const OptionBox = (props: Props) => {
  const selected = props.isSelected ? 'option-box-selected' : '';

  const productPrice = scaleConverter(props.productPrice, props.productUnit);
  const optionPrice = scaleConverter(props.optionPrice, props.productUnit);

  const priceDifference = optionPrice - productPrice;
  const formattedPriceDifference = Math.abs(priceDifference)
    .toFixed(2)
    .toLocaleString('pt-br');

  return (
    <div className={`option-box-main ${selected}`}>
      <div className="option-box-text">{props.text}</div>
      <div className="option-box-variant-value">
        R$ {priceDifference >= 0 ? '+' : '-'} {formattedPriceDifference}
      </div>
    </div>
  );
};

export default OptionBox;
