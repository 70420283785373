// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Actions
import { getPastOrder } from '../actions';
// Selectors
import {
  pastOrderItemsById,
  pastOrderById,
  gettingOrderLoadingSelector,
} from '../selectors';
// Types
import type { Dispatch } from '../../types';

function pastOrdersConnector(WrappedComponent: ReactClass<any>) {
  const mapStateToProps = (state, ownProps) => {
    return {
      pastOrderById: (id: string) => pastOrderById(state, id),
      pastOrderItemsById: (id: string) => pastOrderItemsById(state, id),
      orderLoading: gettingOrderLoadingSelector(state),
    };
  };

  const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
    return {
      getPastOrder: (orderId: string, orderToken: string) => {
        dispatch(getPastOrder(orderId, orderToken));
      },
    };
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class pastOrdersConnector extends Component {
      props: OwnProps;
      render() {
        return (
          <WrappedComponent
            {...this.props}
            getPastOrder={this.props.getPastOrder}
            pastOrderItemsById={this.props.pastOrderItemsById}
            pastOrderById={this.props.pastOrderById}
            orderLoading={this.props.orderLoading}
          />
        );
      }
    },
  );
}

export default pastOrdersConnector;
