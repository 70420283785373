//@flow
import React, { useState } from 'react';
//Styles
import './ReferralCard.css';
//Components
import { Link } from 'react-router-dom';
//Helpers
import { priceConverter, copyToClipboard, getTimeFormat2L } from '../../common';
//Types
import type { InvitedUser } from '../types';
// Analytics
import * as Analytics from '../../common/analytics';

type Props = {
  referralInformation: any,
  invitedUsers: InvitedUser[],
  isSigned: boolean,
};

const ReferralCard = (props: Props) => {
  const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);

  return (
    <div
      className={`referral-card-container ${
        !props.isSigned ? 'referral-card-container-offline' : ''
      }`}
    >
      <section className="referral-card-text-section">
        <div
          className={`referral-card-text-section-title ${
            !props.referralInformation.referralCode
              ? 'referral-card-text-section-title-offline'
              : ''
          }`}
        >
          <h1>Convide seus amigos</h1>
          <p>
            Quando criarem uma conta usando o seu código e receberem o primeiro
            pedido, seu <b>crédito de R$10</b> será liberado!
          </p>
        </div>
        {props.referralInformation.referralCode && (
          <div
            className="referral-card-text-section-clipboard"
            onClick={() => {
              if (props.referralInformation.branchLink) {
                copyToClipboard(props.referralInformation.branchLink);
                setIsCopiedToClipboard(true);
                Analytics.logUserCopiedReferralCode(
                  props.referralInfo.referralCode,
                );
                setTimeout(() => {
                  setIsCopiedToClipboard(false);
                }, 4000);
              }
            }}
          >
            <h1>{props.referralInformation.referralCode}</h1>
            <span>{isCopiedToClipboard ? 'Copiado' : 'Copiar'}</span>
          </div>
        )}
      </section>
      {props.isSigned && (
        <section className="referral-card-balance-section">
          <div className="referral-card-balance-section-current">
            <p className="referral-card-balance-section-description">
              Total acumulado com convites
            </p>
            <h1>
              {props.referralInformation?.totalReferralEarnings
                ? priceConverter(
                    props.referralInformation.totalReferralEarnings,
                  )
                : priceConverter(0)}
            </h1>
          </div>
        </section>
      )}
      {props.invitedUsers && props.invitedUsers.length > 0 && (
        <section className="referral-card-list-section">
          <div className="referral-card-list-section-details">
            <p className="referral-card-list-section-details-description">
              Amigos que você já convidou
            </p>
          </div>
          <section className="referral-card-list-section-content">
            {props.invitedUsers.map(i => (
              <div
                key={i.id}
                className={`referral-card-list-section-content-item ${
                  i.referralStatus === 'Concluído'
                    ? 'referral-card-list-section-content-item-completed'
                    : ''
                } ${
                  i.referralStatus === 'Aguardando entrega'
                    ? 'referral-card-list-section-content-item-awaiting-delivery'
                    : ''
                }`}
              >
                <div className="referral-card-list-section-content-item-description">
                  <span>{getTimeFormat2L(i.createdAt)}</span>
                  <span>
                    {i.firstName} {i.lastName[0]}.
                  </span>
                </div>
                <span className="referral-card-list-section-content-item-status">
                  {i.referralStatus}
                </span>
              </div>
            ))}
          </section>
        </section>
      )}

      <p className="referral-card-read-rules">
        <Link to="/politicas-de-uso-bigclub">
          Ler as regras do convide e ganhe
        </Link>
      </p>
    </div>
  );
};

export default ReferralCard;
