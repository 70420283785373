// @flow
import React, { Component } from 'react';
//Styles
import './ProductPrice.css';
//Helpers
import { priceConverter, scaleConverter } from '../../common';

type Props = {
  productListPrice: number,
  productPrice: number,
  productUnit: string,
};

class ProductPrice extends Component {
  props: Props;
  render() {
    const price = scaleConverter(
      this.props.productPrice,
      this.props.productUnit,
    );
    const listPrice = scaleConverter(
      this.props.productListPrice,
      this.props.productUnit,
    );
    const productOnPromotion = listPrice > price;

    return (
      <div
        className={`product-price-main ${
          productOnPromotion ? 'product-price-promotion' : ''
        }`}
      >
        <p>
          Preço /
          {this.props.productUnit === 'g' || this.props.productUnit === 'kg'
            ? 'Kg'
            : 'un'}
        </p>
        <h5>{priceConverter(price)}</h5>
        {productOnPromotion && <h6>{priceConverter(listPrice)}</h6>}
      </div>
    );
  }
}

export default ProductPrice;
