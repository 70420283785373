// @flow
import React from 'react';
//Styles
import './BigClubBanner.css';

const BigClubBanner = () => (
  <div className="big-club-banner-content">
    <div className="big-club-banner-description">
      <h1>BigClub</h1>
      <div />
      <p>
        O nosso{' '}
        <b>
          programa
          <br /> de fidelidade
        </b>
      </p>
    </div>
  </div>
);

export default BigClubBanner;
