// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Actions
import { findDisplayVariantsByPageAndSection } from '../actions';
import { productByPageAndSectionSelector } from '../selectors';
// Types
import type { Dispatch } from '../../types';
import { Product } from '../types';

type OwnProps = {
  findDisplayVariantsByPageAndSection: (
    page: string,
    section: string,
  ) => Product[],
  productByPageAndSection: Product[],
};

const displayVariantsByPageAndSectionConnector = (
  WrappedComponent: ReactClass<any>,
) => {
  const mapStateToProps = (state, ownProps) => {
    return {
      productByPageAndSection: (page, section) =>
        productByPageAndSectionSelector(state, page, section),
    };
  };

  const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
    return {
      findDisplayVariantsByPageAndSection: (page, section) => {
        dispatch(findDisplayVariantsByPageAndSection(page, section));
      },
    };
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class currentOrderNumberConnector extends Component {
      props: OwnProps;
      render() {
        return (
          <WrappedComponent
            {...this.props}
            findDisplayVariantsByPageAndSection={
              this.props.findDisplayVariantsByPageAndSection
            }
            productByPageAndSection={this.props.productByPageAndSection}
          />
        );
      }
    },
  );
};

export default displayVariantsByPageAndSectionConnector;
