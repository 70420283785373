// @flow
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
// Composers
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// Components
import UnconnectedDepartmentPage from '../components/DepartmentPage';
// Selectors
import {
  currentDepartmentSelector,
  currentDepartmentLoadingSelector,
} from '../selectors';
// Actions
import { findDepartment } from '../actions';
// Types
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router-dom';
import type { Dispatch } from '../../types';
import type { Department } from '../types';

type RouterProps = ContextRouter;
type ReduxProps = {
  department: Department,
  loading: boolean,
  findDepartment: (slug: string | number) => void,
};

type Props = RouterProps & ReduxProps;

class DepartmentPage extends Component {
  props: Props;

  constructor(props: Props) {
    super(props);
    const slug = props.match.params.id;
    props.findDepartment(slug);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Required so this component updates when navigating from one department to the next
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.props.findDepartment(nextProps.match.params.id);
    }
  }

  render() {
    const pageTitle =
      (this.props.department
        ? `${_.capitalize(this.props.department.name)} - `
        : '') + 'Bigdelivery - Seu açougue virtual';
    const hasImage = !!(
      this.props.department && this.props.department.imageUrl
    );
    return (
      <div>
        <Helmet>
          <title>{pageTitle}</title>
          <meta property="og:title" content={pageTitle} />
          <meta
            name="description"
            content={`Encontre aqui produtos do departamento de ${
              this.props.department && this.props.department.name
            }`}
          />
          <meta
            name="og:description"
            content="Carne fresca e de qualidade na porta da sua casa, na sua hora e do seu jeito."
          />
        </Helmet>
        {hasImage && (
          <Helmet>
            <meta
              property="og:image"
              content={this.props.department.imageUrl}
            />
            <meta property="og:image:type" content="image/jpeg" />
            <meta property="og:image:width" content="190" />
            <meta property="og:image:height" content="60" />
            <meta property="og:image:alt" content="Imagem to departamento" />
          </Helmet>
        )}

        <UnconnectedDepartmentPage
          department={this.props.department}
          loading={this.props.loading}
          acessedSubdepartment={this.props.match.params.subdepartment}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    department: currentDepartmentSelector(state),
    loading: currentDepartmentLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    findDepartment: (slug) => {
      dispatch(findDepartment(slug));
    },
  };
};

const connector: Connector<RouterProps, ReduxProps> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default withRouter(connector(DepartmentPage));
