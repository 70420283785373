//@flow
import React from 'react';
// Styles
import './NewsLetter.css';
// Assets
import inboxIcon from '../images/forward_to_inbox.svg';
import thumbUp from '../images/thumb_up_alt.svg';
import check from '../images/check.svg';
import closeX from '../images/closeX.svg';
// Helpers
import { validateEmail } from '../../common';
// Components
import Reaptcha from 'reaptcha';

type Props = {
  postNewsLetterEmail: (email: string) => void,
  loadingNewsLetterEmail: boolean,
};

class NewsLetter extends React.Component {
  constructor(props: Props) {
    super();
    this.props = props;
    this.captcha = null;
    this.state = {
      userEmail: '',
      subscribed: false,
      isEmailInvalid: false,
    };
  }

  onVerify = async reaptchaToken => {
    this.props.postNewsLetterEmail(this.state.userEmail);
    this.setState(() => ({
      subscribed: true,
      isEmailInvalid: false,
    }));
    this.captcha.reset();
  };
  componentDidUpdate(prevProps: Props, nextProps: Props) {
    if (validateEmail(this.state.userEmail) && this.state.isEmailInvalid) {
      this.setState(() => ({
        isEmailInvalid: false,
      }));
    }
  }
  render() {
    const { userEmail, subscribed, isEmailInvalid } = this.state;
    const { loadingNewsLetterEmail } = this.props;
    return (
      <form
        className="newsletter-wrapper"
        data-type="subscription"
        onSubmit={e => {
          e.preventDefault();
          if (validateEmail(userEmail)) {
            this.captcha.execute();
          } else {
            this.setState({
              isEmailInvalid: true,
            });
          }
        }}
      >
        <div className="newsletter-row">
          <img className="newsletter-icon" src={inboxIcon} alt="Inbox" />
          <div className="newsletter-content">
            <h3 className="newsletter-content-title">Fique por dentro</h3>
            <p className="newsletter-content-text">
              de todas as novidades e promoções
            </p>
          </div>
        </div>

        <div className="news-letter-input-wrapper">
          <input
            className="newsletter-input"
            type="email"
            placeholder="Digite o seu email"
            onChange={e => {
              this.setState(() => ({
                userEmail: e.target.value,
              }));
            }}
            value={userEmail}
            id="EMAIL"
            name="EMAIL"
          />
          <Reaptcha
            ref={e => (this.captcha = e)}
            onVerify={this.onVerify}
            size="invisible"
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            badge="bottomleft"
            hl="pt-BR"
          />
          <button
            type="submit"
            disabled={loadingNewsLetterEmail}
            className={`newsletter-button`}
          >
            {!isEmailInvalid ? (
              <img
                className="newsletter-icon-thumbs"
                src={!subscribed ? thumbUp : check}
                alt={!subscribed ? 'Legal' : 'Check'}
              />
            ) : (
              <img
                className="newsletter-icon-thumbs"
                src={closeX}
                alt={'Email inválido'}
              />
            )}
          </button>
        </div>
      </form>
    );
  }
}

export default NewsLetter;
