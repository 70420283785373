// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Selectors
import { emailSelector } from '../selectors';
// Actions

// Types
import type { Dispatch } from '../../types';

type OwnProps = {
  email: string,
};

function userEmailConnector(WrappedComponent: ReactClass<any>) {
  const mapStateToProps = (state, ownProps) => {
    return {
      email: emailSelector(state),
    };
  };

  const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
    return {};
  };

  return connect(mapStateToProps, mapDispatchToProps)(
    class userEmailConnector extends Component {
      props: OwnProps;
      render() {
        return (
          <WrappedComponent
            {...this.props}
            email={this.props.email}
          />
        );
      }
    },
  );
}

export default userEmailConnector;