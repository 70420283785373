// Components
import { Link } from 'react-router-dom';
// styles
import './ChristmasBaseBanner.css';
// assets
import LunchVideo from '../images/christmas-page-video.mp4';
import SnowBorderTopLeft from '../images/snow-border-top-left.svg';
import SnowBorderBottomLeft from '../images/snow-border-bottom-left.svg';
import SnowBorderBottomRight from '../images/snow-border-bottom-right.svg';

const ChristmasBaseBanner = () => {
  return (
    <div className="christmas-base-banner-main-container">
      <div className="christmas-base-banner-top-content">
        <div class="christmas-base-banner-top-content-left">
          <video autoPlay={true} muted={true} loop={true}>
            <source src={LunchVideo} type="video/mp4"></source>
            <source src={LunchVideo} type="video/ogg"></source>
            Seu navegador não suporta este vídeo.
          </video>
        </div>
        <div class="christmas-base-banner-top-content-right">
          <div class="christmas-base-banner-top-content-top">
            <div className="christmas-base-banner-frame-images"></div>
            <div className="christmas-base-banner-top-content-top-text">
              <h6>Seu churrasco do fim de ano é com carne de qualidade!</h6>
              <p>
                Do açougue para sua porta! Com o BigDelivery, tenha as melhores
                carnes sem sair de casa. Entrega rápida para uma ceia sem
                preocupações
              </p>
            </div>
          </div>
          <div class="christmas-base-banner-top-content-bottom">
            <div class="christmas-base-banner-top-content-bottom-left">
              <div className="christmas-base-banner-frame-images"></div>
              <div className="christmas-base-banner-top-content-bottom-left-text">
                <h6>Encontre seu corte e seu estilo no BigDelivery</h6>
                <p>
                  Personalize suas carnes para um churrasco único. Escolha o
                  tamanho e a espessura que desejar. No BigDelivery, cada
                  mordida é feita à sua medida.
                </p>
              </div>
            </div>
            <div class="christmas-base-banner-top-content-bottom-right">
              <div className="christmas-base-banner-top-content-bottom-right-text">
                <h6>Limpeza Garantida</h6>
                <p>Carne Limpa e Sabor Puro</p>
              </div>
              <div className="christmas-base-banner-frame-images"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="christmas-base-banner-lower-container">
        <img src={SnowBorderTopLeft} alt="Borda de neve"></img>
        <img src={SnowBorderBottomLeft} alt="Borda de neve"></img>
        <div className="christmas-base-banner-lower-content">
          <div className="christmas-base-banner-text-lower-content-text">
            <h1>
              Não perca tempo e garanta as ofertas imperdíveis do {''}
              <span>NATAL BIG!</span>
            </h1>
            <div className="christmas-base-banner-lower-links">
              <Link
                to={'/departamentos/12/'}
                className="christmas-base-banner-link"
              >
                Aproveite as ofertas
              </Link>
            </div>
          </div>
        </div>
        <img src={SnowBorderBottomRight} alt="Borda de neve"></img>
      </div>
    </div>
  );
};
export default ChristmasBaseBanner;
