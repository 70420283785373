// @flow
import _ from 'lodash';
import { createSelector } from 'reselect';
// Types
import type { StoreState } from '../types/StoreState';
import type { Wishlist, ProductList } from '../types';

export const thisStateSelector = (state: any): StoreState => state.user;

// User information, id, etc
export const userIdSelector = createSelector(
  thisStateSelector,
  (state: StoreState): ?number => state.information.id || null,
);

export const loggedInSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.loggedIn || false,
);

export const firstNameSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.information.firstName || '',
);

export const totalAvailableStoreCreditSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.information.totalAvailableStoreCredit || 0.0,
);

const invitedUsersMapSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.invitedUsers,
);

export const invitedUsersSelector = (state: StoreState) => {
  const invitedUserMap = invitedUsersMapSelector(state);

  return Object.values(invitedUserMap);
};

export const invitedUserByIdSelector = (state: StoreState, id: ?number) => {
  const invitedUserMap = invitedUsersMapSelector(state);

  return invitedUserMap[id] || null;
};

const cashbacksMapSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.cashbacks,
);

export const cashbacksSelector = (state: StoreState) => {
  const cashbackMap = cashbacksMapSelector(state);

  return Object.values(cashbackMap);
};

export const cashbackByIdSelector = (state: StoreState, id: ?number) => {
  const cashbackMap = cashbacksMapSelector(state);

  return cashbackMap[id] || null;
};

export const referralInformationSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => ({
    branchLink: state.information.branchLink || '',
    referralCode: state.information.referralCode || '',
    totalReferralEarnings: state.information.totalReferralEarnings || '',
  }),
);

export const cashbackInformationSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => ({
    totalUsedCashbackCredit: state.information.totalUsedCashbackCredit,
    totalAvailableCashbackCredit:
      state.information.totalAvailableCashbackCredit,
  }),
);

export const bigClubInformationSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => ({
    totalAvailableBigclubCredit: state.information.totalAvailableBigclubCredit,
  }),
);

export const emailSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.information.email || '',
);

export const editableInformationSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => ({
    firstName: state.information.firstName || '',
    lastName: state.information.lastName || '',
    cpf: state.information.cpf || '',
    email: state.information.email || '',
    userHash: state.information.intercomHash || '',
    phone: state.information.phone || '',
  }),
);

// USE ANALYTICS
export const analyticsInformationSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => ({
    id: state.information.id || null,
    name: `${state.information.firstName} ${state.information.lastName}`,
    email: state.information.email || '',
    isBusiness: !!_.find(state.addresses, a => a.cnpj && a.cnpj !== ''),
    createdAt: state.information.createdAt || '',
  }),
);
// USE ANALYTICS

// Credit Cards
const creditCardsMapSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.creditCards,
);

export const creditCardsSelector = createSelector(
  thisStateSelector,
  creditCardsMapSelector,
  (state: StoreState, creditCardMap) =>
    state.information.creditCards
      ? _.filter(
          state.information.creditCards.map(id => creditCardMap[id]),
          o => o !== undefined,
        )
      : [],
);

// Allowed Payment Methods
const allowedPaymentMethodsMapSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.allowedPaymentMethods,
);

export const allowedPaymentMethodsSelector = createSelector(
  thisStateSelector,
  allowedPaymentMethodsMapSelector,
  (state: StoreState, allowedPaymentMethodsMap) =>
    state.information.allowedPaymentMethods
      ? _.filter(
          state.information.allowedPaymentMethods.map(
            id => allowedPaymentMethodsMap[id],
          ),
          o => o !== undefined,
        )
      : [],
);

// General Loading Related
export const loggingInLoadingSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.loading.loggingIn,
);

export const registeringLoadingSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.loading.registering,
);

export const validatingUserLoadingSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.loading.validating,
);

export const editingUserInformationLoadingSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.loading.editingInformation,
);

export const gettingUserCreditCardsLoadingSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.loading.gettingCreditCards,
);

export const forgotPasswordLoadingSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.loading.forgotPassword,
);

export const resetPasswordLoadingSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.loading.resetPassword,
);

// Address loading related
export const gettingUserAddressesLoadingSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.loading.gettingAddresses,
);

export const editingUserAddressesLoadingSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.loading.editingAddress,
);

export const addingUserAddressesLoadingSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.loading.addingAddress,
);

export const deletingUserAddressesLoadingSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.loading.deletingAddress,
);

export const deletingUserAddressesLoadingByIdSelector = (
  state: any,
  id: number,
) => {
  const index = deletingUserAddressesLoadingSelector(state).indexOf(id);
  return index > -1;
};

export const deletingdUserCreditCardLoadingSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.loading.deletingCreditCard,
);

export const updatingUserPasswordLoadingSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.loading.updatingPassword,
);

// ProductList loading related
export const productListingLoadingSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.loading.productListing,
);
// Wishlist loading related
export const wishlistLoadingSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.loading.wishlistLoading,
);

export const wishlistLoadingProductItemSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.loading.wishlistLoadingProductItem,
);

// Error Related
export const loginErrorsSelector = createSelector(thisStateSelector, state => ({
  badUsername: state.errors.wrongLoginUsername,
  badPassword: state.errors.wrongLoginPassword,
}));

export const updatePasswordError = createSelector(
  thisStateSelector,
  state => state.errors.wrongUpdatePassword,
);

export const registrationErrorsSelector = createSelector(
  thisStateSelector,
  state => state.errors.registration,
);

export const forgotPasswordErrorsSelector = createSelector(
  thisStateSelector,
  state => state.errors.forgotPassword,
);

export const resetPasswordErrorsSelector = createSelector(
  thisStateSelector,
  state => state.errors.resetPassword,
);

// Address Related
export const addressMapSelector = createSelector(
  thisStateSelector,
  state => state.addresses,
);

export const userAddressesSelector = createSelector(
  thisStateSelector,
  addressMapSelector,
  (state, addresses) =>
    state.information.addresses
      ? _.filter(
          state.information.addresses.map(id => addresses[id]),
          o => o !== undefined,
        )
      : [],
);

// Wishlist  related
export const userWishlistSelector = createSelector(thisStateSelector, state => {
  const result: Wishlist = state.wishlist;
  return !!result ? result : null;
});

export const userWishlistItemSelector = (state, variantId: number) => {
  const wishlist: Wishlist = thisStateSelector(state).wishlist;
  const result = _.find(wishlist.items, item => item.variantId === variantId);
  return result ? result : null;
};

// Product List  related

export const userProductListItemsSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => {
    const items: ProductList['items'] = state.productList.items;
    return items;
  },
);

export const userProductListItemSelector = (
  state: StoreState,
  variantId: number,
) => {
  const items = thisStateSelector(state).productList.items;
  const result = _.find(items, item => item.variantId === variantId);
  return result ? result : null;
};

export const userProductListResultSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => {
    const result = state.productList.result;
    return result ? result : {};
  },
);

export const userDeletingProductListLoadingSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.loading.deletingProductList,
);

export const userAddingProductListToCartLoadingSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.loading.addingProductListToCart,
);

export const userProductListResultItemsSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.productList.resultItems,
);
