// @flow
import React, { Component } from 'react';
import { SearchBox } from 'react-instantsearch-dom';
import './UnconnectedSearchBar.css';

type Props = {};

class UnconnectedSearchBar extends Component {
  props: Props;

  render() {
    return (
      <div>
        <SearchBox
          className="one"
          searchAsYouType={false}
          translations={{ placeholder: 'O que está procurando?' }}
        />
      </div>
    );
  }
}

export default UnconnectedSearchBar;
