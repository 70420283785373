// Images
import schedule from '../images/schedule.svg';
import cuttingBoard from '../images/tabuadecorte.svg';
import comment from '../images/comments.jpg';
import callCenter from '../images/callCenter.svg';
import meat from '../images/meatAlt.svg';
import parisBistro from '../images/parisBistro.jpg';
import redBurgerNBar from '../images/redBurgerNBar.jpg';

const restaurantData = {
  restaurantColumnIcons: [
    {
      img: callCenter,
      alt: 'Call center',
      text: 'Compre pelo nosso call center',
    },
    {
      img: schedule,
      alt: 'Calendário',
      text: 'Entrega agendada',
    },
    {
      img: cuttingBoard,
      alt: 'Tábua de corte',
      text: 'Você escolhe como serão feitos os cortes',
    },
    {
      img: meat,
      alt: 'Carne de qualidade',
      text: 'Carne limpa e maciça, pronta para o preparo dos pratos',
    },
  ],
  restaurantRowImages: [
    {
      img: parisBistro,
      alt: 'Paris bistro',
    },
    {
      img: redBurgerNBar,
      alt: 'Red Burger N Bar',
    },
  ],
  faqQuestions: [
    {
      title: 'Há uma burocracia maior ao realizar um pedido para restaurantes?',
      body: 'De forma alguma. Ao finalizar o seu pedido, você verá uma caixinha para marcar como empresa. Nesse momento, haverá o espaço para colocar o CNPJ e razão social para emissão da nota fiscal. Pronto, simples assim!',
      isOpen: false,
    },
    {
      title:
        'Meu restaurante é bastante frequentado e, por isso, tenho uma alta demanda. O Bigdelivery consegue suprir minhas necessidades?',
      body: 'Com certeza. Aqui, você seleciona o que e quanto quiser e recebe o seu pedido no horário que você considerar o mais ideal. Neste formato, seguimos atuando com vários restaurantes de Salvador.',
      isOpen: false,
    },
    {
      title:
        'Preciso receber as carnes limpas e cortadas em formatos específicos. O Bigdelivery cobra a mais por esse serviço?',
      body: 'Não! O nosso objetivo é te entregar qualidade e praticidade, por isso, faz parte do nosso processo garantir que tudo saia como você precisa.',
      isOpen: false,
    },
  ],
  comment: {
    img: comment,
    title: '"O que nossos clientes dizem"',
    text: '"Com o bigdelivery ficou muito mais fácil gerir as coisas por aqui, antes eu tinha dois funcionários só para realizar o processo de compra, moagem e boleamento, agora eu só peço no bigdelivery e já recebo meu blend pronto pra uso!"',
    author: 'Cliente BigDelivery',
  },
};

export default restaurantData;
