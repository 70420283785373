// @flow
import React from 'react';
// Composers
import { connect } from 'react-redux';
// Components
import {
  ProgressiveImageLoader,
  AddAfterExistsButton,
  priceConverter,
} from '../../common';
// Selectors
import { changingBlendTemplateItemLoadingSelector } from '../selectors';
// Actions
import { deleteBlendTemplateItem, updateBlendTemplateItem } from '../actions';
// Styles
import './BlendTemplateItem.css';
// Types
import type { BlendTemplateItem as BlendTemplateItemType } from '../types';

type Props = {
  item: BlendTemplateItemType,
  totalBlendQuantity: number,
  blendTemplateId: number,
  changingLoading: boolean,
  deleteBlendTemplateItem: (itemId: number) => void,
  handleIncreaseQuantity: () => void,
  handleDecreaseQuantity: () => void,
};

/**
 * An item that belongs to a blend template. Can be removed or have its quantity changed.
 */

class BlendTemplateItem extends React.Component {
  props: Props;

  render() {
    return (
      <div>
        <div className="blend-line-item-conteiner">
          <div className="blend-line-item-main">
            <div className="blend-line-item-content">
              <div className="blend-line-item-image">
                <ProgressiveImageLoader
                  selectedImage={
                    this.props.item.variant.images !== undefined
                      ? this.props.item.variant.images[0]
                      : null
                  }
                  imageToShow={'small'}
                />
              </div>

              <div className="blend-line-item-description">
                <p className="blend-line-item-name">{this.props.item.name}</p>
                <p className="blend-line-item-manufacturer">
                  {this.props.item.variant.manufacturer}
                </p>
              </div>

              <div className="blend-line-item-price">
                {`${priceConverter(this.props.item.variant.price * 1000)}/Kg`}
              </div>

              <div className="blend-line-item-quantity">
                <AddAfterExistsButton
                  unitForQuantity={this.props.item.variant.unitForQuantity}
                  currentQuantity={this.props.item.quantity}
                  increaseQuantity={this.props.handleIncreaseQuantity}
                  decreaseQuantity={this.props.handleDecreaseQuantity}
                />

                {this.props.item.quantity ===
                this.props.item.variant.minimumQuantity ? (
                  <span>
                    {`Mínimo: ${(
                      this.props.item.variant.minimumQuantity / 1000
                    ).toLocaleString('pt-br', {
                      currency: 'BRL',
                    })} Kg`}
                  </span>
                ) : null}
              </div>

              <div className="blend-line-item-percentage">
                {`${Math.round(
                  (this.props.item.quantity / this.props.totalBlendQuantity) *
                    100,
                )}%`}
              </div>

              {!this.props.changingLoading ? (
                <button
                  className="fa fa-trash-o trash-image"
                  aria-hidden="true"
                  onClick={() =>
                    this.props.deleteBlendTemplateItem(this.props.item.id)
                  }
                  disabled={this.props.changingLoading}
                />
              ) : (
                <div className="trash-loader">
                  <i className="fa fa-spinner fa-pulse" />
                </div>
              )}
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps: Props) => {
  return {
    changingLoading: changingBlendTemplateItemLoadingSelector(
      state,
      ownProps.item.id,
    ),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: Props,
  stateProps,
) => {
  return {
    deleteBlendTemplateItem: (itemId: number) => {
      dispatch(deleteBlendTemplateItem(ownProps.blendTemplateId, itemId));
    },

    handleIncreaseQuantity: () => {
      dispatch(
        updateBlendTemplateItem(
          ownProps.blendTemplateId,
          ownProps.item.id,
          ownProps.item.variant.incrementQuantity,
        ),
      );
    },
    handleDecreaseQuantity: () => {
      dispatch(
        updateBlendTemplateItem(
          ownProps.blendTemplateId,
          ownProps.item.id,
          -ownProps.item.variant.incrementQuantity,
        ),
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BlendTemplateItem);
