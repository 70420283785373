// @flow
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
// Components
import { SearchBar } from '../../search';
import BottomRow from '../components/BottomRow';
import DownloadApp from '../components/DownloadApp';
import HamburgerMenu from '../components/HamburgerMenu';
import Logo from '../components/Logo';
import TopRow from '../components/TopRow';
// Styles
import './Header.css';
// Redux
import { connect } from 'react-redux';
// Selectors
import { featuresConfigSelector } from '../../preferences/selectors';
import {
  loggedInSelector,
  loggingInLoadingSelector,
  userIdSelector,
} from '../../user/selectors';
import {
  currentOrderSelector,
  currentOrderLineItemsSelector,
  ordersListSelector,
} from '../../past-orders/selectors/index';
import {
  copyingOrderLoadingSelector,
  itemsSelector,
} from '../../current-order/selectors/index';
// Actions
import { getPastOrder } from '../../past-orders/actions/index';
import { copyingOrder } from '../../current-order/actions';
// Connectors
import currentOrderNumberConnector from '../../current-order/connectors/currentOrderNumberConnector';
// Types
import type { FeaturesConfig } from '../../preferences/types';

type Props = {
  userId: number,
  featuresConfig: FeaturesConfig,
  loggedIn: boolean,
  loggingInLoading: boolean,
  prevOrderItems: LineItem[],
  currentOrderItems: LineItem[],
};

const Header = (props: Props) => {
  const [showDownloadApp, setShowDownloadApp] = useState(true);
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
  return (
    <div>
      {showDownloadApp && (
        <div className="header-download-app">
          <DownloadApp handleClose={() => setShowDownloadApp(false)} />
        </div>
      )}

      <div className="header-main">
        <Logo />
        <div
          className={`header-btn-mobile ${
            showHamburgerMenu ? 'header-btn-mobile-active' : ''
          }`}
          onClick={() => setShowHamburgerMenu(!showHamburgerMenu)}
        >
          <div className="header-hamburger-bar1"></div>
          <div className="header-hamburger-bar2"></div>
          <div className="header-hamburger-bar3"></div>
        </div>
        <div className="header-rows">
          <TopRow
            orderNumber={props.orderNumber}
            order={props.order}
            orders={props.orders}
            prevOrderItems={props.prevOrderItems}
            getPastOrder={props.getPastOrder}
            copyingOrder={props.copyingOrder}
            currentOrderNumber={props.currentOrderNumber}
            previousOrderNumber={props.previousOrderNumber}
            copyingOrderLoading={props.copyingOrderLoading}
            currentOrderItems={props.currentOrderItems}
            loggedIn={props.loggedIn}
            loggingInLoading={props.logginInLoading}
          />
          <BottomRow
            userId={props.userId}
            bigClubEnabled={props.featuresConfig?.bigclubFeature}
          />
        </div>
      </div>
      <div
        className={`header-hamburger-menu ${
          showHamburgerMenu ? 'header-hamburger-menu-active' : ''
        }`}
      >
        <HamburgerMenu
          onNavigate={() => setShowHamburgerMenu(false)}
          userId={props.userId}
          bigClubEnabled={props.featuresConfig?.bigclubFeature}
        />
      </div>
      <div className="header-search-mobile">
        <SearchBar />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userId: userIdSelector(state),
    featuresConfig: featuresConfigSelector(state),
    order: currentOrderSelector(state),
    prevOrderItems: currentOrderLineItemsSelector(state),
    orders: ordersListSelector(state),
    copyingOrderLoading: copyingOrderLoadingSelector(state),
    currentOrderItems: itemsSelector(state),
    loggedIn: loggedInSelector(state),
    logginInLoading: loggingInLoadingSelector(state),
  };
};

const mapDispatchToProps = dispatch => ({
  getPastOrder: orderNumber => dispatch(getPastOrder(orderNumber)),
  copyingOrder: async (currentOrderNumber, previousOrderNumber) =>
    await dispatch(copyingOrder(currentOrderNumber, previousOrderNumber)),
});

export default withRouter(
  currentOrderNumberConnector(
    connect(mapStateToProps, mapDispatchToProps)(Header),
  ),
);
