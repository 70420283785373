// @flow
import React, { useEffect } from 'react';
// Components
import LandingBanner from '../components/LandingBanner';
import LandingIncreasingUserCounter from '../components/LandingIncreasingUserCounter';
import LandingTitleSubtitleSection from '../components/LandingTitleSubtitleSection';
import LandingFAQ from '../components/LandingFAQ';
import LandingProductList from '../components/LandingProductList';
// Images
import bannerImage from '../images/hamburguerShopBanner.jpg';
import schedule from '../images/schedule.svg';
import callCenter from '../images/callCenter.png';
import pan from '../images/pan.svg';
import fraldinha from '../images/fraldinha.jpg';
import maminha from '../images/maminha.jpg';
import picanha from '../images/picanha.jpg';
import meatPlate from '../images/meatPlate.jpg';
import comments from '../images/comments.jpg';
import shoppingCart from '../images/shoppingCart.jpg';
import blend from '../images/blend.jpg';
// Data
import burgerShopData from '../data/burgerShopData';
// Connectors
import { displayVariantsByPageAndSectionConnector } from '../../products';
// Styles
import './BurgerShop.css';

type Props = {
  findDisplayVariantsByPageAndSection: (
    page: string,
    section: string,
  ) => Product[],
  productByPageAndSection: (page: string, section: string) => Product[],
};

const BurgerShop = ({
  productByPageAndSection,
  findDisplayVariantsByPageAndSection,
}: Props) => {
  const sliderProductsVariants = productByPageAndSection(
    'hamburger_shop',
    'slider',
  );
  const productsListVariants = productByPageAndSection(
    'hamburger_shop',
    'product_list',
  );
  useEffect(() => {
    findDisplayVariantsByPageAndSection('hamburger_shop');
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <LandingIncreasingUserCounter />
      <LandingBanner
        bannerRichText={burgerShopData.firstBannerRichText}
        bannerImage={bannerImage}
      />
      <LandingTitleSubtitleSection
        backgroundColor={burgerShopData.perfectBlend.backgroundColor}
        sectionTitle={burgerShopData.perfectBlend.sectionTitle}
        sectionSubTitle={burgerShopData.perfectBlend.sectionSubTitle}
      />
      <section className="burger-shop-grid-column">
        <div className="burger-shop-grid-item">
          <span className="burger-shop-grid-item-text-number">1</span>
          <p className="burger-shop-grid-item-text">
            Escolha as carnes de sua preferência
          </p>
          <div className="burger-shop-meat-combo">
            <img src={fraldinha} alt="Fraldinha" />
            +
            <img src={maminha} alt="Maminha" />
            +
            <img src={picanha} alt="Picanha" />
          </div>
          <div className="burger-shop-grid-item-line"></div>
        </div>
        <div className="burger-shop-grid-item">
          <span className="burger-shop-grid-item-text-number">2</span>
          <p className="burger-shop-grid-item-text">
            Indique quantos kg de cada carne você quer
          </p>
          <img
            className="burger-shop-grid-item-img"
            src={meatPlate}
            alt="Carne de hamburger"
          />
          <div className="burger-shop-grid-item-line"></div>
        </div>
        <div className="burger-shop-grid-item">
          <span className="burger-shop-grid-item-text-number">3</span>

          <p className="burger-shop-grid-item-text">
            Salve o seu blend e quando quiser repita o mesmo pedido
          </p>
          <img
            className="burger-shop-grid-item-img"
            src={blend}
            alt="blend de carne"
          />
          <div className="burger-shop-grid-item-line"></div>
        </div>
        <div className="burger-shop-grid-item">
          <span className="burger-shop-grid-item-text-number">4</span>

          <p className="burger-shop-grid-item-text">
            Mandou pro carrinho?, Agora aguarda um pouco que já já o seu pedido
            chega
          </p>
          <img
            className="burger-shop-grid-item-img-alt"
            src={shoppingCart}
            alt="Carrinho de compras"
          />
          <div className="burger-shop-grid-item-line"></div>
        </div>
        <div className="burger-shop-grid-item-alt">
          <img
            src={comments}
            alt="Comentário"
            className="burger-shop-grid-item-img-comments"
          />
          <h4 className="burger-shop-grid-item-title-comments">
            O que nossos clientes dizem
          </h4>
          <p className="burger-shop-grid-item-text-comments">
            {burgerShopData.comment}
          </p>
          <span className="burger-shop-grid-item-client-comments">
            Cliente BigDelivery
          </span>
        </div>
      </section>
      <div className="burger-shop-banner-alt">
        <h3 className="burger-shop-banner-alt-text">
          Transforme sua hamburgueria em <span>referência </span>de qualidade!
        </h3>
      </div>
      <section className="burger-shop-container">
        <div className="burger-shop-icon-column">
          <div className="burger-shop-icon-column-item">
            <img
              src={callCenter}
              alt="Ilustração atendente"
              className="burger-shop-icon-column-item-img"
            />
            <p className="burger-shop-icon-column-item-text">
              Compre pelo nosso call center
            </p>
          </div>
          <div className="burger-shop-icon-column-item">
            <img
              src={schedule}
              alt="Ilustração calendário"
              className="burger-shop-icon-column-item-img"
            />
            <p className="burger-shop-icon-column-item-text">
              Entrega agendada
            </p>
          </div>
          <div className="burger-shop-icon-column-item">
            <img
              src={pan}
              alt="Ilustração panela"
              className="burger-shop-icon-column-item-img"
            />
            <p className="burger-shop-icon-column-item-text">
              Carne pronta para o preparo
            </p>
          </div>
        </div>
      </section>
      <LandingProductList
        title="OS MAIS PEDIDOS :"
        sliderProductsVariants={sliderProductsVariants}
        productsVariants={productsListVariants}
        currentLanding="hamburger_shop"
      />
      <LandingFAQ
        faqQuestions={burgerShopData.faqQuestions}
        currentLanding="hamburger_shop"
      />
    </div>
  );
};
export default displayVariantsByPageAndSectionConnector(BurgerShop);
