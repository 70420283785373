// @flow
import React from 'react';
import { Link } from 'react-router-dom';
// Styles
import './LoggedInOptions.css';
// Assets
import userIcon from '../images/user.svg';

type Props = {
  name: string,
};

const LoggedInOptions = ({ name }: Props) => {
  return (
    <div className="dropdown">
      <Link to="/minha-conta/informacoes/">
        <button
          className="dropbtn"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img src={userIcon} className="dropbtn-img" alt="Ícone usuário" />
          <div className="dropbtn-text">
            <strong>{name.length < 15 ? name : 'Bem vindo!'}</strong>
            <p>Acesse sua conta</p>
          </div>
        </button>
      </Link>
    </div>
  );
};

export default LoggedInOptions;
