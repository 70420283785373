// @flow
import React from 'react';
// Components
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
// Analytics
import * as Analytics from '../../common/analytics';
// Styles
import './ImageCarousel.css';

type Props = {
  images: {
    src: string,
    link: string,
    alt: string,
  }[],
};

const ImageCarousel = (props: Props) => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    swipe: true,
    vertical: false,
    lazyLoad: true, // Loads one image at a time starting with the slide that appears first
  };

  return (
    <div className="image-carousel-wrapper">
      <Slider {...settings}>
        {props.images.map((img, ind) => {
          const isExternalLink = img.link.includes('https');
          if (isExternalLink) {
            return (
              <a
                href={img.link}
                key={ind}
                target="_blank"
                data-index={ind}
                onClick={() => Analytics.logTappedBanner(img.alt)}
                rel="noreferrer noopener"
              >
                <img
                  className="image-carousel-img"
                  src={img.src}
                  alt={img.alt}
                />
              </a>
            );
          }
          return (
            <Link
              to={img.link}
              key={ind}
              data-index={ind}
              onClick={() => Analytics.logTappedBanner(img.alt)}
            >
              <img className="image-carousel-img" src={img.src} alt={img.alt} />
            </Link>
          );
        })}
      </Slider>
    </div>
  );
};

export default ImageCarousel;
