// @flow
import React, { useState } from 'react';
// Components
import { InstantSearch } from 'react-instantsearch-dom';
import WishlistProductModal from '../components/wishlist/WishlistProductModal';

type Props = {
  isOpen: boolean,
  handleClose: () => void,
};

const WishlistModal = ({ isOpen, handleClose }: Props) => {
  const [searchState, setSearchState] = useState({ query: '' });
  return (
    <InstantSearch
      appId={process.env.REACT_APP_ALGOLIA_APP_ID}
      apiKey={process.env.REACT_APP_ALGOLIA_API_KEY}
      indexName={
        process.env.REACT_APP_ALGOLIA_PRODUCT_INDEX ||
        'product_sales_production'
      }
      searchState={searchState}
      onSearchStateChange={setSearchState}
    >
      <WishlistProductModal isOpen={isOpen} handleClose={handleClose} />
    </InstantSearch>
  );
};

export default WishlistModal;
