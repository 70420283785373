// @flow
import React, { Component } from 'react';
// Helper functions
import { getTimeWindow } from '../../../common';
// Styles
import './DeliverySummary.css';
// Types
import type { ShippingRate } from '../../types';

type Props = {
  delivery: ShippingRate,
};

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

class DeliverySummary extends Component {
  props: Props;

  date(deliveryWindow: Date) {
    let month = months[deliveryWindow.getMonth()];
    let day = deliveryWindow.getDate();
    return (
      <div className="delivery-summary-date">
        <div>
          {month} {day}
        </div>
      </div>
    );
  }

  render() {
    // console.log(this.props.delivery)
    return (
      <div className="delivery-summary-main">
        <div className="delivery-summary-details">
          {this.date(this.props.delivery.deliveryWindowStart)}
          {' →  '}
          {getTimeWindow(
            this.props.delivery.deliveryWindowStart,
            this.props.delivery.deliveryWindowEnd,
          )}
        </div>
        <div className="delivery-summary-cost">
          {this.props.delivery.name} {this.props.delivery.displayCost}
        </div>
      </div>
    );
  }
}
export default DeliverySummary;
