// @flow
import React, { useEffect } from 'react';
import { usePrevious } from '../../common';
// Composers
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// Components
import UnconnectedProductPage from '../components/ProductPage';
// Selectors
import { recipesByProductIdSelector } from '../../recipes/selectors';
import { userIdSelector } from '../../user/selectors';
import {
  currentProductLoadingSelector,
  currentProductSelector,
  loadingShareableLinkSelector,
} from '../selectors';
// Actions
import { findRecipesByProduct } from '../../recipes/actions';
import { copyProductLinkByVariantIdAndUserId, findProduct } from '../actions';
// Types
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router-dom';
import type { Dispatch } from '../../types';
import type { Product } from '../types';

type RouterProps = ContextRouter;
type ReduxProps = {
  product: Product,
  loading: boolean,
  findProduct: (slug: string | number) => void,
  copyProductLinkByVariantIdAndUserId: (
    variantId: number,
    userId: number,
    slug: string,
  ) => void,
  userId: number,
  loadingShareableLink: boolean,
};

type Props = RouterProps & ReduxProps;

const ProductPage = ({
  match,
  product,
  recipes,
  findProduct,
  getRecipesByProduct,
  loading,
  copyProductLinkByVariantIdAndUserId,
  userId,
  loadingShareableLink,
}) => {
  const slug = match.params.id;
  const previousSlug = usePrevious(slug);
  useEffect(() => {
    if (previousSlug !== slug) {
      findProduct(slug);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    if (product && product.productId) {
      getRecipesByProduct(product.productId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product?.id]);
  return (
    <UnconnectedProductPage
      product={product}
      match={match}
      recipes={recipes((product && product.productId) || null)}
      loading={loading}
      copyProductLinkByVariantIdAndUserId={copyProductLinkByVariantIdAndUserId}
      userId={userId}
      loadingShareableLink={loadingShareableLink}
    />
  );
};

const mapStateToProps = (state, ownProps: Props) => {
  return {
    product: currentProductSelector(state),
    loading: currentProductLoadingSelector(state),
    recipes: productId => recipesByProductIdSelector(state, productId),
    userId: userIdSelector(state),
    loadingShareableLink: loadingShareableLinkSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    findProduct: slug => {
      dispatch(findProduct(slug));
    },
    getRecipesByProduct: id => {
      dispatch(findRecipesByProduct(id));
    },
    copyProductLinkByVariantIdAndUserId: async (variantId, userId, slug) => {
      await dispatch(copyProductLinkByVariantIdAndUserId(variantId, userId));
      dispatch(findProduct(slug));
    },
  };
};

const connector: Connector<RouterProps, ReduxProps> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default withRouter(connector(ProductPage));
