// @flow
import React from 'react';
// Styles
import './BigClubPreview.css';
import 'rc-slider/assets/index.css';
// Assets
import bigClubImg from '../images/big-club.svg';
// Helpers
import { priceConverter } from '../../common';
// Components
import Slider from 'rc-slider';
import { Link } from 'react-router-dom';
// Types
import type { BigClubInfo } from '../types';
import type { BigClubSettings } from '../../preferences/types';

type Props = {
  applied: number,
  availableBigclubCredit: number,
  handleApplyBigClubCredit: (bigClubCreditAmount: number) => void,
  bigClubCreditAmount: number,
  bigClubInfo: BigClubInfo,
  setUseBigClubCredit(value: boolean): void,
  useBigClubCredit: boolean,
  bigClubSettings: BigClubSettings,
};

const BigClubPreview = ({
  handleApplyBigClubCredit,
  bigClubInfo,
  bigClubCreditAmount,
  setUseBigClubCredit,
  useBigClubCredit,
  bigClubSettings,
}: Props) => {
  const handleToggleCashback = (checked: boolean) => {
    setUseBigClubCredit(checked);
  };
  if (!bigClubInfo?.canUse) return null;

  const bigclubMaxUsePercentage = Number(
    bigClubSettings?.general.bigclubMaxUsePercentage || 0,
  ).toFixed();

  return (
    <section className="big-club-preview">
      <div className="big-club-preview-row">
        <img
          className="big-club-preview-row-image"
          src={bigClubImg}
          alt="BigClub, o nosso programa de fidelidade"
        />
        <div className="big-club-preview-row-content">
          <p className="big-club-preview-row-content-text">Saldo BigClub</p>
          <h6 className="big-club-preview-row-content-wallet">
            {priceConverter(bigClubInfo?.available ? bigClubInfo.available : 0)}
          </h6>
        </div>
      </div>
      <div
        className={`big-club-preview-header ${
          !useBigClubCredit ? 'big-club-preview-header-border-none' : ''
        }`}
      >
        <h4 className="big-club-preview-header-title">
          Deseja utilizar <span>o saldo?</span>
        </h4>
        <label className="big-club-preview-header-switch">
          <input
            checked={useBigClubCredit}
            type="checkbox"
            onChange={event => {
              handleToggleCashback(event.target.checked);
              if (!event.target.checked) handleApplyBigClubCredit(0);
            }}
          />
          <span className="big-club-preview-header-switch-slider-round"></span>
        </label>
      </div>
      {useBigClubCredit && (
        <div className="big-club-slider-row">
          <Slider
            trackStyle={{ backgroundColor: '#eb5b1e' }}
            dotStyle={{ borderColor: 'orange' }}
            handleStyle={{
              borderColor: '#eb5b1e',
              boxShadow: '0 0 5px tint(#eb5b1e, 20%)',
            }}
            onChange={value => {
              handleApplyBigClubCredit(value);
            }}
            defaultValue={0}
            max={
              bigClubInfo?.requirements?.maxValue
                ? bigClubInfo?.requirements?.maxValue
                : 0
            }
          />
          <h6 className="big-club-slider-row-text big-club-preview-row-content-text-flex">
            {priceConverter(bigClubCreditAmount)}
          </h6>
        </div>
      )}
      <div className="big-club-preview-info">
        <span className="big-club-preview-info-text">
          Use até {bigclubMaxUsePercentage}% do seu Saldo BigClub
        </span>
        <Link
          className="big-club-preview-info-rules"
          to="/politicas-de-uso-bigclub"
        >
          Ler Regras
        </Link>
      </div>
    </section>
  );
};

export default BigClubPreview;
