// @flow
import React from 'react';
// Styles
import './CartBigClubPreview.css';
// Assets
import bigClubImg from '../images/big-club.svg';
// Helpers
import { priceConverter } from '../../common';
// Types
import type { BigClubInfo } from '../types';

type Props = {
  bigClubInfo: BigClubInfo,
};

const CartBigClubPreview = ({ bigClubInfo }: Props) => {
  return (
    <div className="cart-big-club-preview-container">
      {bigClubInfo && (
        <section className="cart-big-club-preview">
          <div className="cart-big-club-preview-row">
            <div className="cart-big-club-preview-row-content">
              <p className="cart-big-club-preview-row-content-text">
                Saldo BigClub
              </p>
              <h6 className="cart-big-club-preview-row-content-wallet">
                {priceConverter(
                  bigClubInfo?.available ? bigClubInfo.available : 0,
                )}
              </h6>
            </div>
            <img
              className="cart-big-club-preview-row-image"
              src={bigClubImg}
              alt="BigClub, o nosso programa de fidelidade"
            />
          </div>
        </section>
      )}
    </div>
  );
};

export default CartBigClubPreview;
