// @flow
import React from 'react';
// Styles
import './AboutUsPage.css';
// Components
import { Helmet } from 'react-helmet';
import { PageTitleWithGoBack, Video } from '../../common';
import Lottie from 'lottie-react';
// Assets
import rocket from '../images/rocket.json';
import target from '../images/target.json';
import lamp from '../images/lamp.json';

const AboutUsPage = () => {
  return (
    <div className="about-us-wrapper">
      <Helmet>
        <title>Sobre Nós - Bigdelivery - Seu açougue virtual</title>
        <meta
          property="og:image"
          content="https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F639809393_1280x720.webp&amp;src1=https%3A%2F%2Ff.vimeocdn.com%2Fimages_v6%2Fshare%2Fplay_icon_overlay.png"
        />
        <meta
          property="og:image:secure_url"
          content="https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F639809393_1280x720.webp&amp;src1=https%3A%2F%2Ff.vimeocdn.com%2Fimages_v6%2Fshare%2Fplay_icon_overlay.png"
        />
        <meta property="og:image:type" content="image/jpg" />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="720" />
        <meta
          property="og:video:url"
          content="https://player.vimeo.com/video/221520060?autoplay=1"
        />
        <meta
          property="og:video:secure_url"
          content="https://player.vimeo.com/video/221520060?autoplay=1"
        />
        <meta property="og:video:type" content="text/html" />
        <meta property="og:video:width" content="1280" />
        <meta property="og:video:height" content="720" />
      </Helmet>

      <PageTitleWithGoBack title="Quem somos" />
      <div className="about-us-video-row">
        <iframe
          title="Video"
          src="https://player.vimeo.com/video/221520060"
          className="about-us-video"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </div>

      <div className="about-us-text-column">
        <p>
          Há 35 anos no mercado de açougue convencional na Bahia, nos sentíamos
          praticamente completos. Porém, um pequeno fator sempre nos incomodou:
          as filas. Descobrimos então, que a melhor forma de acabar com elas é
          invertendo a lógica do negócio, ao invés do cliente ir até o açougue,
          o açougue vai até o cliente. Simples assim!
        </p>
        <p>
          Nasceu então o Bigdelivery, startup que desde 2017 oferece a
          comodidade que você sempre esperou. Atendemos mais de 200 empresas e
          milhares de consumidores finais com quase 1 milhão de pedidos
          entregues. Todos com cortes e porcionamentos personalizados, e com
          carne fresca de qualidade.
        </p>
        <p>
          Contamos com distribuidores exclusivos da Linha Big Carnes e uma
          seleção minuciosa de produtos terceirizados. Alinhando diversidade e
          preços competitivos. Além de uma frota de entregadores próprios. Tudo
          para que seu pedido chegue até vocês com rapidez e comodidade. Chega a
          ser difícil de acreditar, mas deixe-nos te impressionar ainda mais,
          basta fazer o primeiro pedido!
        </p>
      </div>
      <section className="about-us-row">
        <div className="about-us-row-item">
          <Lottie animationData={rocket} className="about-us-row-animation" />

          <h3>Missão</h3>
          <p>
            Praticidade no dia a dia, do principiante na cozinha até de
            renomados chefs de cozinhas e hamburguerias com alto padrão, através
            da elaboração, manipulação e entrega de alimentos com foco no padrão
            de qualidade elevado.
          </p>
        </div>

        <div className="about-us-row-item">
          <Lottie animationData={target} className="about-us-row-animation" />

          <h3>Visão</h3>
          <p>
            Autenticidade, inovação e flexibilidade constante para melhor
            atender aos nossos clientes.
          </p>
        </div>
        <div className="about-us-row-item">
          <Lottie animationData={lamp} className="about-us-row-animation" />
          <h3>Valores da Empresa</h3>
          <p>
            A paixão em trabalhar com pessoas, o prazer em satisfazê-las com a
            qualidade superior dos nossos produtos e a eficiência,
            comprometimento, respeito e transparência da nossa equipe, são
            princípios que teremos afinco em defendê-los.
          </p>
        </div>
      </section>
    </div>
  );
};

export default AboutUsPage;
