// @flow
import React from 'react';
import './Video.css';

type Props = {
  videoUrl: string,
};

const Video = (props: Props) => (
  <div className="video-player-container">
    {!props.videoUrl.includes('vimeo') ? (
      <video title="Video" className="video" src={props.videoUrl} controls />
    ) : (
      <iframe
        title="Video"
        src={props.videoUrl}
        className="video"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    )}
  </div>
);
export default Video;
