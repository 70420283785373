// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import _ from 'lodash';
import qs from 'qs';
// Selectors
import {
  ordersListSelector,
  gettingOrdersLoadingSelector,
  pagesInfoSelector,
} from '../selectors';
// Actions
import { getPastOrders } from '../actions';
// Components
import OrderLineItem from '../components/OrderLineItem';
import Pagination from '../components/Pagination';
import { PageLoader } from '../../common';
import NoOrders from '../components/NoOrders';
// Styles
import './PastOrdersIndex.css';

// Types
import type { Dispatch } from '../../types';
import type { Order } from '../types';

type Props = {
  orders: Order[],
  loading: boolean,
};

class PastOrdersIndex extends Component {
  constructor(props: Props) {
    super(props);

    // Get Params from URL if available. Else, default.
    const currentPage = this.getCurrentPageFromURL();

    // Get past orders
    this.props.getPastOrders(currentPage, 10);
  }

  getCurrentPageFromURL = () => {
    const parsedURL = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    // If we can successfully parse an Int we should return that else we will return 1
    return parseInt(parsedURL.page, 10) || 1;
  };

  componentDidUpdate(prevProps) {
    // If our current page has changed we should redo the getPageIndex request
    if (this.props.location.search !== prevProps.location.search) {
      // Get Params from URL if available. Else, default.
      const currentPage = this.getCurrentPageFromURL();
      // Get past orders
      this.props.getPastOrders(currentPage, 10);
    }
  }

  render() {
    // Parse the URL string to get the current page
    const currentPage = this.getCurrentPageFromURL();

    // If the page in the URL exceeds the maximum number (or is <1) of pages we should redirect to initial page
    if (this.props.pagesInfo.numberOfPages) {
      if (currentPage > this.props.pagesInfo.numberOfPages || currentPage < 1) {
        return <Redirect to={{ search: '' }} />;
      }
    }

    // In case we are loading we need to show the loader.
    // NOTE: Before there was also a check for an empty order in which the loader would only show if there were no orders
    //       but since we have pagination using the previous condition would result in undersired behavior (wrong orders shown while loading).
    if (this.props.loading) {
      return <PageLoader />;
    }

    // If we don't have any orders or any valid orders
    if (_.isEmpty(this.props.orders)) {
      return <NoOrders />;
    }
    return (
      <div className="past-order-index-container">
        <h1 className="past-order-index-title">Meus pedidos</h1>
        {this.props.orders.map(o => (
          <OrderLineItem
            key={o.number}
            orderNumber={o.number}
            fulfillmentState={o.fulfillmentState}
            deliveryState={o.deliveryState}
            orderDate={o.completedAt}
            orderQuantity={o.lineItems.length}
          />
        ))}

        <Pagination
          currentPageInURL={currentPage}
          pagesInfo={this.props.pagesInfo}
          getPastOrders={this.props.getPastOrders}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    orders: ordersListSelector(state),
    loading: gettingOrdersLoadingSelector(state),
    pagesInfo: pagesInfoSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    getPastOrders: (page: number, perPage: number) => {
      dispatch(getPastOrders(page, perPage));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(PastOrdersIndex));
