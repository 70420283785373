import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { usePrevious } from '../customHooks/usePrevious';
// Analytics
import * as Analytics from '../analytics';

const TimeOnPageProfiler = () => {
  const startTimeOfPageRef = useRef(new Date().getTime());
  const latestBackgroundEventStartTimeRef = useRef(null);
  const totalBackgroundTimeRef = useRef(0);

  const location = useLocation();
  const previousSearchParams = usePrevious(location.search);
  const previousPath = usePrevious(location.pathname);

  useEffect(() => {
    // Whenever the location or route changes we should send the analytics event and restart the timers
    const currentTime = new Date().getTime();
    const totalTimeOfPageOnForeground =
      currentTime - startTimeOfPageRef.current - totalBackgroundTimeRef.current;

    // Avoid sending on first render or if route did not change
    if (
      previousPath !== location.pathname ||
      previousSearchParams !== location.search
    ) {
      Analytics.logTimeSpentOnPage(
        previousPath,
        previousSearchParams,
        totalTimeOfPageOnForeground / 1000,
        totalBackgroundTimeRef.current / 1000,
      );
    }

    startTimeOfPageRef.current = new Date().getTime();
    latestBackgroundEventStartTimeRef.current = null;
    totalBackgroundTimeRef.current = 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, location.pathname]);

  useEffect(() => {
    const onVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        latestBackgroundEventStartTimeRef.current = new Date().getTime();
      } else if (document.visibilityState === 'visible') {
        if (!latestBackgroundEventStartTimeRef.current) return;

        totalBackgroundTimeRef.current +=
          new Date().getTime() - latestBackgroundEventStartTimeRef.current;
      }
    };

    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, []);

  return <></>;
};

export default TimeOnPageProfiler;
