// @flow
import React, { useState } from 'react';
import _ from 'lodash';
// Components
import ReactCreditCard from 'react-credit-cards';
import { FormControlInput } from '../../../common';
// Types
import type { PaymentSourceCreditCard } from '../../types';
// Styles
import 'react-credit-cards/lib/styles-compiled.css';
import './CreditCardEditor.css';

type Props = {
  card: PaymentSourceCreditCard,
  validationErrors: {
    name: string,
    number: string,
    verificationValue: string,
    year: string,
    month: string,
  },
  onUpdate: PaymentSourceCreditCard => void,
};

type State = {
  focused: null | 'name' | 'number' | 'expiry' | 'cvc',
  monthWithYear: string,
  issuer: string,
};

const CreditCardEditor = (props: Props) => {
  const [editorState, setEditorState] = useState<State>({
    focused: null,
    monthWithYear: null,
    issuer: '',
  });

  const maskCreditCardNumber = (value: string) => {
    if (!value) return '';
    let newValue;

    switch (editorState.issuer) {
      case 'amex':
        newValue = `${value.slice(0, 4)} ${value.slice(4, 10)} ${value.slice(
          10,
          15,
        )}`;
        break;
      case 'dinersclub':
        newValue = `${value.slice(0, 4)} ${value.slice(4, 10)} ${value.slice(
          10,
          14,
        )}`;
        break;
      default:
        newValue = `${value.slice(0, 4)} ${value.slice(4, 8)} ${value.slice(
          8,
          12,
        )} ${value.slice(12, 19)}`;
        break;
    }
    return newValue.trim();
  };

  const maskMonthWithYear = (value: string) => {
    if (!value) return '';
    const regex = /^(\d{0,2})(\d{0,2})$/g;
    return value.replace(regex, (regex, $1, $2) =>
      [$1, $2].filter(group => !!group).join('/'),
    );
  };

  const updateValue = (name, value) => {
    let newValue = value;
    // Different fields may require different types of clean up or parsing of the value. As such, we apply custom logic to fields based on their names.
    if (name !== 'name') newValue = newValue.replace(/[^\d]/g, '');
    switch (name) {
      case 'monthWithYear':
        setEditorState({
          monthWithYear: value,
        });
        const splitValue = value.split('/');
        const month = splitValue[0];
        const year = splitValue[1] ? splitValue[1] : '';
        props.onUpdate({ ...props.card, month, year });
        break;
      default:
        props.onUpdate({
          ...props.card,
          [name]: newValue,
        });
        break;
    }
  };

  const expiry =
    _.padStart(props.card.month, 2, '0') + _.padStart(props.card.year, 2, '0');
  const maskedCardNumber = maskCreditCardNumber(props.card.number);
  const maskedMonthWithYear = maskMonthWithYear(editorState.monthWithYear);
  const maxLengthCVC =
    editorState.issuer === 'amex' || editorState.issuer === 'discovery' ? 4 : 3;
  const validationMonthWithYear =
    props.validationErrors.month || props.validationErrors.year;

  return (
    <div className="credit-card-editor-main">
      <form className="credit-card-editor-form">
        <div className="credit-card-editor-form-main">
          <FormControlInput
            type="text"
            variant="rounded"
            label="Nome do titular do cartão"
            value={props.card.name}
            name="name"
            handleChangeText={value => updateValue('name', value)}
            errorMessage={props.validationErrors.name}
            handleFocus={() =>
              setEditorState(prevValues => ({
                ...prevValues,
                focused: 'name',
              }))
            }
            handleBlur={() =>
              setEditorState(prevValues => ({
                ...prevValues,
                focused: null,
              }))
            }
          />
          <FormControlInput
            type="text"
            variant="rounded"
            label="Número de cartão"
            name="number"
            value={maskedCardNumber}
            handleChangeText={value => updateValue('number', value)}
            errorMessage={props.validationErrors.number}
            maxLength={19}
            inputMode="numeric"
            handleFocus={() =>
              setEditorState(prevValues => ({
                ...prevValues,
                focused: 'number',
              }))
            }
            handleBlur={() =>
              setEditorState(prevValues => ({
                ...prevValues,
                focused: null,
              }))
            }
          />
          <FormControlInput
            type="text"
            variant="rounded"
            name="monthWithYear"
            label="Mês/Ano de vencimento"
            value={maskedMonthWithYear}
            handleChangeText={value => updateValue('monthWithYear', value)}
            errorMessage={validationMonthWithYear}
            maxLength={5}
            handleFocus={() =>
              setEditorState(prevValues => ({
                ...prevValues,
                focused: 'expiry',
              }))
            }
            handleBlur={() =>
              setEditorState(prevValues => ({
                ...prevValues,
                focused: null,
              }))
            }
          />
          <FormControlInput
            type="text"
            variant="rounded"
            label="Código de segurança (CVC)"
            name="verificationValue"
            value={props.card.verificationValue}
            handleChangeText={value => updateValue('verificationValue', value)}
            maxLength={maxLengthCVC}
            errorMessage={props.validationErrors.verificationValue}
            handleFocus={() =>
              setEditorState(prevValues => ({
                ...prevValues,
                focused: 'cvc',
              }))
            }
            handleBlur={() =>
              setEditorState(prevValues => ({
                ...prevValues,
                focused: null,
              }))
            }
          />
        </div>
      </form>

      <div className="credit-card-editor-display">
        <ReactCreditCard
          className="credit-card-display"
          name={props.card.name || ''}
          number={props.card.number || ''}
          expiry={expiry}
          cvc={props.card.verificationValue || ''}
          focused={editorState.focused || ''}
          namePlaceholder="NOME COMPLETO"
          callback={(type, isValid) => setEditorState({ issuer: type.issuer })}
        />
      </div>
    </div>
  );
};
export default CreditCardEditor;
