// @flow
import React from 'react';
// Analytics
import * as Analytics from '../../../common/analytics';
// Styles
import './MyAccountAddressAddNewButton.css';
// Assets
import plus_icon from '../../images/plus-icon.svg';
import return_arrow from '../../images/return-arrow.svg';

type Props = {
  isEditing: boolean,
  onClick: () => void,
};

const MyAccountAddressAddNewButton = (props: Props) => {
  return (
    <div
      className="my-account-address-add-new-button-main"
      onClick={() => {
        Analytics.logTappedAddNewAddress();
        props.onClick();
      }}
    >
      <div className="my-account-address-add-new-button-content">
        {props.isEditing ? (
          <div className="my-account-address-add-new-button-return-button">
            <img src={return_arrow} alt="Voltar para aba de endereços" />
            <div className="my-account-address-add-return-text">Voltar</div>
          </div>
        ) : (
          <div className="my-account-address-add-new-button-create-new-address">
            <img src={plus_icon} alt="Adicionar novo endereço" />
            <div className="my-account-address-add-new-text">
              Adicionar endereço
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyAccountAddressAddNewButton;
