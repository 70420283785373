// @flow
import React from 'react';
//Components
import { Link } from 'react-router-dom';
import { PageLoader, PageTitleWithGoBack } from '../../common';
import { SubdepartmentScroller } from '../../products';
// Types
import type { Department } from '../types';
// Styles
import './DepartmentPage.css';
//Helpers
import { useScreenTimeLogger } from '../../common';

export type Props = {
  department: Department,
  loading: boolean,
  acessedSubdepartment: String,
};

const DepartmentPage = (props: Props) => {
  useScreenTimeLogger(window.location.pathname)
  const department = props.department;

  if (props.loading)
    return (
      <div className="department-page-main">
        <PageTitleWithGoBack title="" />
        <PageLoader />
      </div>
    );

  if (!department)
    return (
      <div className="department-not-found">
        <PageTitleWithGoBack title="" />
        <h3>Não foi possível carregar esse departamento.</h3>
        <Link to="/">Voltar ao início</Link>
      </div>
    );

  return (
    <div className="department-page-main">
      <PageTitleWithGoBack title={department.name} />
      {department.subdepartments.map((subdepartment, ind) => (
        <SubdepartmentScroller
          key={subdepartment.id}
          title={subdepartment.name}
          productIds={subdepartment.products}
          acessedSubdepartment={props.acessedSubdepartment}
          subdepartmentSlug={subdepartment.slug}
          subdepartmentLink={`/departamentos/${department.id}/${subdepartment.slug}/`}
        />
      ))}
    </div>
  );
};

export default DepartmentPage;
