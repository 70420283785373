// @flow
import React from 'react';
// Components
import { currentOrderNumberConnector } from '../../current-order';
//Others
import { phone } from '../../common';
// Styles
import './WhatsappButton.css';
// Assets
import whatsappIcon from '../images/whatsappIcon.svg';

type Props = {
  currentOrderNumber: string,
};

const WhatsappButton = ({ currentOrderNumber }: Props) => {
  const whatsAppmessage = `Olá. Gostaria de ter mais informações sobre ${
    currentOrderNumber ? `o meu pedido ${currentOrderNumber}` : 'a BigDelivery'
  }`;
  return (
    <a
      href={`https://wa.me/${phone.whatsAppNumber}?text=${whatsAppmessage}`}
      className="whatsapp-button-wrapper"
      target="_blank"
      rel="noopener noreferrer"
    >
      <button>
        <span>Entre em contato pelo Whatsapp</span>
        <img src={whatsappIcon} alt="Whatsapp" />
      </button>
    </a>
  );
};

export default currentOrderNumberConnector(WhatsappButton);
