// @flow

// import HomePage from './HomePage';
import { Home } from './home';
import { BarbecueCalculator, BarbecueCalculatorLanding } from './barbecue';
import { BlogHomePage, BlogPostPage } from './blog';
import { CartPage, CheckoutPage, ConfirmationPage } from './current-order';
import { LoginPage, LogoutPage, BigClubPage } from './user';
import { ProductPage } from './products';
import { SearchPage } from './search';
// TODO remove
import { PixPage } from './current-order';

// import RecipePage from './RecipePage';
import {
  MyAccountInformationPage,
  MyAccountOrdersPage,
  MyAccountOrderPage,
  MyAccountAddressesPage,
  MyAccountPasswordPage,
  PasswordRecoveryPage,
  ForgotPasswordPage,
  MyAccountPaymentsPage,
  MyAccountOverviewPage,
  MyAccountEvaluationsPage,
} from './user';
import { DepartmentPage, DepartmentsIndexPage } from './departments';
import { BlendLadingPage, BlendCalculatorPage, BlendsIndexPage } from './blend';
import {
  AboutUsPage,
  TermsOfUsagePage,
  WorkWithUsPage,
  ContactUs,
  FaqPage,
  ShippingAndExchangePage,
  BigClubRulesPage,
} from './static';
// import LandingPages
import {
  SelfCare,
  BurgerShop,
  Healthy,
  Barbecue,
  Pet,
  Homemade,
  Butcher,
  Restaurant,
  BigCustomer,
  BigClub,
  BlackFridayLandingPage,
  ChristmasLandingPage,
  KitsLandingPage,
} from './landing-pages';

const routes = [
  {
    path: '/',
    component: Home,
    exact: true,
  },
  {
    path: '/bigchurrasco',
    component: BarbecueCalculatorLanding,
    exact: true,
  },
  {
    path: '/bigchurrasco/meuchurrasco',
    component: BarbecueCalculator,
  },
  {
    path: '/carrinho',
    component: CartPage,
  },
  {
    path: '/produtos/:id',
    component: ProductPage,
  },
  {
    path: '/login',
    component: LoginPage,
  },
  {
    path: '/logout',
    component: LogoutPage,
  },
  {
    path: '/esqueci-senha',
    component: ForgotPasswordPage,
  },
  {
    path: '/resetar-senha',
    component: PasswordRecoveryPage,
  },
  {
    path: '/checkout',
    component: CheckoutPage,
  },
  {
    path: '/busca',
    component: SearchPage,
  },
  // {
  //   path: '/receitas/:id',
  //   component: RecipePage,
  //   navigate: actions.routerNavigatedToRecipePage,
  // },
  {
    path: '/minha-conta/visao-geral',
    component: MyAccountOverviewPage,
  },
  {
    path: '/minha-conta/informacoes',
    component: MyAccountInformationPage,
  },
  {
    path: '/minha-conta/pedidos/:orderNumber',
    component: MyAccountOrderPage,
  },
  {
    path: '/minha-conta/pedidos',
    component: MyAccountOrdersPage,
  },
  {
    path: '/minha-conta/enderecos',
    component: MyAccountAddressesPage,
  },
  {
    path: '/minha-conta/senha',
    component: MyAccountPasswordPage,
  },
  {
    path: '/minha-conta/cartoes',
    component: MyAccountPaymentsPage,
  },
  {
    path: '/minha-conta/avaliacoes',
    component: MyAccountEvaluationsPage,
  },
  {
    path: '/departamentos',
    component: DepartmentsIndexPage,
    exact: true,
  },
  {
    path: '/departamentos/:id/:subdepartment?',
    component: DepartmentPage,
  },
  {
    path: '/confirmacao/:id?',
    component: ConfirmationPage,
  },
  {
    path: '/pix-payment/:id?',
    component: PixPage,
  },
  // Blend pages
  {
    path: '/blend',
    component: BlendLadingPage,
    exact: true,
  },
  {
    path: '/blend/calculadora/:id',
    component: BlendCalculatorPage,
  },
  {
    path: '/blend/meus-blends',
    component: BlendsIndexPage,
  },
  // Static pages
  {
    path: '/sobre',
    component: AboutUsPage,
  },
  {
    path: '/trabalhe',
    component: WorkWithUsPage,
  },
  {
    path: '/contato',
    component: ContactUs,
  },
  {
    path: '/termos',
    component: TermsOfUsagePage,
  },
  {
    path: '/perguntas',
    component: FaqPage,
  },
  {
    path: '/frete-e-trocas',
    component: ShippingAndExchangePage,
  },
  {
    path: '/politicas-de-uso-bigclub',
    component: BigClubRulesPage,
  },
  {
    path: '/blog',
    component: BlogHomePage,
    exact: true,
  },
  {
    path: '/blog/:slug',
    component: BlogPostPage,
  },
  {
    path: '/lp/christmas',
    component: ChristmasLandingPage,
  },
  {
    path: '/lp/blackfriday',
    component: BlackFridayLandingPage,
  },
  {
    path: '/lp/kit-churrasco',
    component: KitsLandingPage,
  },
  {
    path: '/lp/autocuidado',
    component: SelfCare,
  },
  {
    path: '/lp/hamburgueria',
    component: BurgerShop,
  },
  {
    path: '/lp/saudavel',
    component: Healthy,
  },
  {
    path: '/lp/churrasco',
    component: Barbecue,
  },
  {
    path: '/lp/pet',
    component: Pet,
  },
  {
    path: '/lp/do-lar',
    component: Homemade,
  },
  {
    path: '/lp/restaurante',
    component: Restaurant,
  },
  {
    path: '/lp/acougue-virtual',
    component: Butcher,
  },
  {
    path: '/lp/clientebig',
    component: BigCustomer,
  },
  {
    path: '/lp/bigclub',
    component: BigClub,
  },
  // BigClub
  {
    path: '/bigclub',
    component: BigClubPage,
  },
];

export default routes;
