// @flow
import React, { Component } from 'react';

import './ViewLine.css';

class ViewLine extends Component {
  render() {

    return (
      <div className="view-line-main">
        <div className="view-line-display" />
      </div>
    );
  }
}

export default ViewLine;