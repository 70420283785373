// @flow
import React from 'react';
import { Link } from 'react-router-dom';
// Images
import CoverBanner from '../images/blend_cover.jpg';
import Food from '../images/food.png';
import MeatPlate from '../images/meat_plate.png';
import Divisory from '../images/divisory.png';
import Cart from '../images/cart.png';
import Cupim from '../images/cupim.png';
import File from '../images/file.png';
import Fraldinha from '../images/fraldinha.png';
import Cupim2 from '../images/cupim_percentage.png';
import File2 from '../images/file_percentage.png';
import Fraldinha2 from '../images/fraldinha_percentage.png';
import Burger from '../images/burger.jpg';
// Styles
import './BlendLadingPage.css';
// Components
import { Helmet } from 'react-helmet';
import BlendTemplateItem from '../components/BlendTemplateListItem';

const BlendLadingPage = () => {
  return (
    <div className="blend-lading-page-main">
      <Helmet>
        <title>O Blend Perfeito - Bigdelivery - Seu açougue virtual</title>
        <meta
          property="og:title"
          content="O Blend Perfeito - Bigdelivery - Seu açougue virtual"
        />
        <meta
          name="description"
          content="É VOCÊ QUEM MANDA. Diga como, quanto e quando quer, e a gente entrega. Simples assim."
        />
        <meta
          name="og:description"
          content="É VOCÊ QUEM MANDA. Diga como, quanto e quando quer, e a gente entrega. Simples assim."
        />
        <meta property="og:image" content={MeatPlate} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="642" />
        <meta property="og:image:height" content="637" />
        <meta property="og:image:alt" content="Blend de 3 produtos" />
      </Helmet>

      <Link to="/blend/meus-blends/">
        <img className="blend-lading-full-image" src={CoverBanner} alt="" />
      </Link>
      <div className="blend-lading-rectangle-1">
        <img className="food-image" src={Food} alt="" />
        <div className="rectangle-text">
          <p className="text-size-large">É VOCÊ QUEM&nbsp;MANDA.</p>
          <p className="text-size-small">
            Diga como, quanto e quando quer, e a gente entrega. Simples&nbsp;assim.
          </p>
        </div>
      </div>
      <div className="blend-lading-rectangle-2">
        <img className="meat-image" src={MeatPlate} alt="" />
        <div className="rectangle-text-2">
          <p className="text-size-large">A RECEITA DO&nbsp;SUCESSO</p>
          <p className="text-size-small">
            Qual a mistura que vai deixar seu cliente ou sua família convencida
            que você é o rei ou rainha da cozinha? Adicione e misture produtos
            Bigdelivery® para um blend que só você sabe&nbsp;fazer.
          </p>
        </div>
      </div>
      <img className="divisory-image-top" src={Divisory} alt="" />
      <div className="blend-lading-rectangle-3">
        <span className="number-image-1">1</span>
        <div className="rectangle-text-3">
          <p className="text-size-large">ESCOLHA OS&nbsp;PRODUTOS</p>
          <p className="text-size-small">
            Pesquise as carnes que gosta e adicione ao seu&nbsp;blend.
          </p>
        </div>
        <div className="combo-image-1">
          <img className="cupim-image-1" src={Cupim} alt="" />
          <i className="fa fa-plus plus-orange" />
          <img className="file-image-1" src={File} alt="" />
          <i className="fa fa-plus plus-orange" />
          <img className="fraldinha-image-1" src={Fraldinha} alt="" />
        </div>
      </div>
      <img className="divisory-image-bottom" src={Divisory} alt="" />
      <div className="blend-lading-rectangle-4">
        <span className="number-image-2">2</span>
        <div className="rectangle-text-4">
          <p className="text-size-large">INDIQUE QUANTO&nbsp;QUER</p>
          <p className="text-size-small">
            Quanto kgs de cada carne você quer? Trabalhamos com&nbsp;números!
          </p>
        </div>
        <div className="combo-image-2">
          <img className="cupim-image-2" src={Cupim2} alt="" />
          <i className="fa fa-plus fa-1x plus-white" />
          <img className="file-image-2" src={File2} alt="" />
          <i className="fa fa-plus fa-1x plus-white" />
          <img className="fraldinha-image-2" src={Fraldinha2} alt="" />
        </div>
      </div>
      <img className="divisory-image-top" src={Divisory} alt="" />
      <div className="blend-lading-rectangle-5">
        <span className="number-image-3">3</span>
        <img className="burger-image" src={Burger} alt="" />
        <div className="rectangle-text-5">
          <p className="text-size-large">SALVE SEU&nbsp;BLEND</p>
          <p className="text-size-small">
            Guarde seu blends e faça o mesmo pedido quantas vezes&nbsp;quiser.{' '}
          </p>
        </div>
        <div className="blends">
          <div className="blend-1">
            <BlendTemplateItem
              blendTemplate={{
                name: 'Blend do Ano!',
                observation: 'Detalhes de como preparar!',
                completedAt: '2019-02-18T15:46:19.000-03:00',
                defaultQuantity: 2900,
                price: 0.0326,
                amount: 32.29,
                items: [],
              }}
              clickable={false}
            />
          </div>
          <div className="blend-2">
            <BlendTemplateItem
              blendTemplate={{
                name: 'Blend Sensacional Y',
                observation: 'Informações adicionais do cliente',
                completedAt: '2019-02-23T15:46:19.000-03:00',
                defaultQuantity: 2300,
                price: 0.0269,
                amount: 25.57,
                items: [],
              }}
              clickable={false}
            />
          </div>
        </div>
      </div>
      <div>
        <img className="divisory-image-bottom" src={Divisory} alt="" />
      </div>
      <div className="blend-lading-rectangle-6">
        <img className="cart-image" src={Cart} alt="" />
        <div className="rectangle-text-6">
          <p className="text-size-large">
            AÍ É SÓ MANDAR PRO CARRINHO E&nbsp;ESPERAR.
          </p>
          <p className="text-size-small">
            Mas não muito, porque chega&nbsp;rápido.{' '}
          </p>
        </div>

        <Link to="/blend/meus-blends/">
          <button className="blend-button">ADICIONAR NOVO BLEND</button>
        </Link>
      </div>
    </div>
  );
};

export default BlendLadingPage;
