// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import _ from 'lodash';
// Components
import { ColorRow, PageLoader } from '../../common';
import { Helmet } from 'react-helmet';
import { UnauthenticatedRedirect } from '../../user';
import UnconnectedBlendCalculatorPage from '../components/BlendCalculatorPage';
// Images
import BlendBanner from '../images/blend_banner.png';
// Actions
import * as actions from '../actions';
// Selectors
import * as selectors from '../selectors';

type Props = {};

type State = {
  redirectTo: ?number,
};

export class BlendCalculatorPage extends Component {
  state: State;
  constructor(props: Props) {
    super(props);

    this.state = {
      redirectTo: null,
    };
  }

  componentDidMount() {
    // If :id is "new" we will request creation of new blend.
    // If :id is a number we will request that specific blend.
    // TODO: If :id is something else we should show an error message or navigate user to another link?
    const urlId = this.props.match.params.id;
    if (urlId === 'new') {
      this.props.createBlendTemplate();
    } else if (!isNaN(urlId) && urlId != null) {
      this.props.getBlendTemplate(urlId);
    } else {
      console.error('TODO: We need to deal with this error!');
    }
  }

  componentDidUpdate(prevProps: Props) {
    // If our :id is "new" and we receive new props we should check if we have a new blendTemplate and navigate to it
    const UrlId = this.props.match.params.id;
    if (UrlId == 'new' && this.props.newlyCreatedBlendTemplateId) {
      this.setState({
        redirectTo: this.props.newlyCreatedBlendTemplateId,
      });
    } else if (this.state.redirectTo) {
      // If we already redirected we should not redirect again
      this.setState({
        redirectTo: null,
      });
    }
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={`/blend/calculadora/${this.state.redirectTo}`} />;
    }

    // If the blend template is completed redirect to my blends page since we cannot edit it
    // We need to wait for the component to load and ensure that blend_template is not null
    if (
      !this.props.loading &&
      this.props.blendTemplate &&
      this.props.blendTemplate.completedAt
    ) {
      return <Redirect to="/blend/meus-blends" />;
    }

    return (
      <UnauthenticatedRedirect>
        <Helmet>
          <title>Calculadora de Blend - Bigdelivery, Seu açougue virtual</title>
        </Helmet>

        <ColorRow color="orange" text="CALCULADORA DE BLEND" />

        <div>
          <img
            className="blend-calculator-banner-image"
            src={BlendBanner}
            alt=""
          />
        </div>

        {this.props.loading && _.isEmpty(this.props.blendTemplate) ? (
          <PageLoader />
        ) : this.props.blendTemplate ? (
          <UnconnectedBlendCalculatorPage
            blendTemplate={this.props.blendTemplate}
            blendTemplateItems={this.props.blendItems}
            updateBlendTemplate={this.props.updateBlendTemplate}
            createBlendItem={this.props.createBlendLineItem}
            isItemInBlend={variantId =>
              !_.isEmpty(this.props.blendItemByVariantId(variantId))
            }
            AddItemToBlendTemplate={(variantId, quantity) =>
              this.props.createBlendTemplateItem(
                this.props.blendTemplate.id,
                variantId,
                quantity,
              )
            }
            loading={this.props.loading}
            addingBlendTemplateItemsLoading={
              this.props.addingBlendTemplateItemsLoading
            }
          />
        ) : (
          'Não conseguimos encontrar o template para esse blend em nossos bancos de dados.'
        )}
      </UnauthenticatedRedirect>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    newlyCreatedBlendTemplateId: selectors.newlyCreatedBlendTemplateIdSelector(
      state,
    ),
    blendTemplate: selectors.getBlendTemplateByIdSelector(
      state,
      ownProps.match.params.id,
    ),
    blendItems: selectors.getBlendTemplateItemsByBlendTemplateIdSelector(
      state,
      ownProps.match.params.id,
    ),
    blendItemByVariantId: variantId =>
      selectors.getBlendTemplateItemByBlendTemplateIdAndVariantIdSelector(
        state,
        ownProps.match.params.id,
        variantId,
      ),
    loading:
      selectors.creatingBlendTemplateLoadingSelector(state) ||
      selectors.gettingBlendTemplateLoadingSelector(state),
    addingBlendTemplateItemsLoading: itemId =>
      selectors.addingItemLoadingSelector(state, itemId),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    ////// BLEND TEMPLATE RELATED //////
    getBlendTemplate: (id: number | string) => {
      dispatch(actions.getBlendTemplate(id));
    },
    createBlendTemplate: () => {
      dispatch(actions.createBlendTemplate());
    },
    updateBlendTemplate: (
      blendId: number,
      name: string,
      observation: string,
      compleate: boolean,
      fatPercentage: number,
    ) => {
      dispatch(
        actions.updateBlendTemplate(
          blendId,
          name,
          observation,
          compleate,
          fatPercentage,
        ),
      );
    },
    /////////// OTHER ////////////
    createBlendTemplateItem: (
      templateId: number,
      variantId: number,
      quantity: number,
    ) => {
      dispatch(
        actions.createBlendTemplateItem(templateId, variantId, quantity),
      );
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(BlendCalculatorPage));
