// @flow
import React from 'react';
// Components
import ImageGallery from './ImageGallery';
import BuyBox from '../../current-order/components/BuyBox';
// Types
import type { Product } from '../types';
//Styles
import './MainProductContent.css';
//Helpers
import { scaleConverter } from '../../common';

export type Props = {
  product: Product,
  userId: number,
  isCopiedProductLink: boolean,
  loadingShareableLink: boolean,
  generateProductLink: () => void,
};

const MainProductContent = ({
  product,
  userId,
  isCopiedProductLink,
  loadingShareableLink,
  generateProductLink,
}: Props) => {
  const price = scaleConverter(product.price);
  const listPrice = scaleConverter(product.listPrice);

  const productOnPromotion = listPrice > price;
  const productDiscount = productOnPromotion
    ? ((listPrice - price) / listPrice) * 100
    : null;

  return (
    <div className="main-product-container">
      {/* TODO: Add loading state */}
      <ImageGallery
        productName={product.name}
        images={product.images}
        userId={userId}
        productDiscount={productDiscount}
        generateProductLink={generateProductLink}
        isCopiedProductLink={isCopiedProductLink}
        loadingShareableLink={loadingShareableLink}
        isLoading={false}
        productId={product.id}
      />
      <BuyBox product={product} />
    </div>
  );
};

export default MainProductContent;
