// @flow
import React, { Component } from 'react';

import './CartIndicatorButton.css';

type Props = {
  quantity: number,
  loading: boolean,
};

class CartIndicatorButton extends Component {
  props: Props;
  render() {
    const { quantity, loading } = this.props;
    return (
      <div className="cart-indicator-button-container">
        <div className="cart-indicator-button-icon" aria-label="Carrinho" />
        {quantity <= 0 && (
          <div className="cart-indicator-item">
            <strong className="cart-indicator-item-title">Carrinho</strong>
            <p className="cart-indicator-item-text">Está vazio :(</p>
          </div>
        )}
        {quantity > 0 && (
          <div className="cart-indicator-item">
            <strong className="cart-indicator-item-title">Carrinho</strong>
            <div className="cart-indicator-item-text-alt">
              {!loading ? (
                <span className="cart-indicator-item-text-alt-quantity">
                  {Number(quantity) < 10 ? '0' + quantity : quantity}
                </span>
              ) : (
                <i className="fa fa-spinner fa-pulse fa-1x fa-fw cart-indicator-spinner" />
              )}
              <span>Produtos</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default CartIndicatorButton;
