// @flow
import React, { useEffect } from 'react';
// Components
import LandingBanner from '../components/LandingBanner';
import LandingFAQ from '../components/LandingFAQ';
import LandingIncreasingUserCounter from '../components/LandingIncreasingUserCounter';
import LandingProductList from '../components/LandingProductList';
// Styles
import './SelfCare.css';
// Images
import bannerImage from '../images/selfCareBanner.jpg';
import clickOnCart from '../images/clickOnCart.png';
import scheduleAlt from '../images/scheduleAlt.svg';
import cuttingBoard from '../images/cuttingBoard.png';
import meat from '../images/meat.png';
import illustrationCookChef from '../images/illustrationCookChef.svg';
// Data
import selfCareData from '../data/selfcareData';
// Connectors
import { displayVariantsByPageAndSectionConnector } from '../../products';
// Types
import { Product } from '../../products';

type Props = {
  findDisplayVariantsByPageAndSection: (
    page: string,
    section: string,
  ) => Product[],
  productByPageAndSection: (page: string, section: string) => Product[],
};

const SelfCare = ({
  productByPageAndSection,
  findDisplayVariantsByPageAndSection,
}: Props) => {
  const sliderProductsVariants = productByPageAndSection('selfcare', 'slider');
  const productsListVariants = productByPageAndSection(
    'selfcare',
    'product_list',
  );
  useEffect(() => {
    findDisplayVariantsByPageAndSection('selfcare');
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <LandingIncreasingUserCounter />
      <LandingBanner
        bannerRichText={selfCareData.bannerRichText}
        bannerImage={bannerImage}
      />
      <section className="landing-encourage-section">
        <h2 className="landing-encourage-title">
          estimule a criatividade na cozinha!
        </h2>
        <p className="landing-encourage-paragraph">
          Tire um tempo para você e prepare uma receita que te agrada.
        </p>
        <img
          className="landing-encourage-illustration"
          src={illustrationCookChef}
          alt="Ilustração chefe de cozinha meditando"
        />
      </section>
      <div className="landing-everything-to-facilitate-container">
        <h3 className="landing-everything-to-facilitate-title">
          Tudo para facilitar o seu dia a dia
        </h3>
        <div className="landing-everything-to-facilitate-column">
          <div className="landing-everything-to-facilitate-item">
            <img
              src={clickOnCart}
              className="landing-everything-to-facilitate-item-img"
              alt="Clique no carrinho"
            />
            <p className="landing-everything-to-facilitate-item-text">
              Compre de qualquer lugar, <strong>receba em casa</strong>
            </p>
          </div>
          <div className="landing-everything-to-facilitate-item">
            <img
              src={scheduleAlt}
              className="landing-everything-to-facilitate-item-img"
              alt="Agenda"
            />
            <p className="landing-everything-to-facilitate-item-text-alt">
              Entrega <strong>agendada</strong>
            </p>
          </div>
          <div className="landing-everything-to-facilitate-item">
            <img
              src={cuttingBoard}
              className="landing-everything-to-facilitate-item-img"
              alt="Ilustração mesa de corte"
            />
            <p className="landing-everything-to-facilitate-item-text">
              <strong>Você escolhe</strong> como serão feitos{' '}
              <strong>os cortes</strong>
            </p>
          </div>
          <div className="landing-everything-to-facilitate-item">
            <img
              src={meat}
              className="landing-everything-to-facilitate-item-img"
              alt="Ilustração carne"
            />
            <p className="landing-everything-to-facilitate-item-text">
              Carne <strong>limpa e porcionada</strong>, pronta para o preparo
              dos pratos
            </p>
          </div>
        </div>
      </div>
      <LandingProductList
        sliderProductsVariants={sliderProductsVariants}
        productsVariants={productsListVariants}
        currentLanding="selfcare"
        title="Faça a sua escolha"
      />
      <LandingFAQ
        faqQuestions={selfCareData.faqQuestions}
        currentLanding="selfcare"
      />
    </div>
  );
};
export default displayVariantsByPageAndSectionConnector(SelfCare);
