// @flow
import React, { useEffect, useState } from 'react';
import { Configure, Hits, connectStateResults } from 'react-instantsearch-dom';
// Components
import { Button, FormControlInput } from '../../../common';
import WishlistProductSquare from './WishlistProductSquare';
import UnconnectedWishlistSearchInput from './UnconnectedWishlistSearchInput';
// Styles
import './WishlistCreate.css';
// Types
import type { ProductList } from '../../types';

type Props = {
  isOpen: boolean,
  handleClose: () => void,
  query: string,
  searchResults: any,
  searching: boolean,
  handleSubmit: (name: string, items: ProductList['items']) => void,
  selectedProductListItems: ProductList['items'],
};

type FormErros = {
  name: string,
};

type FormValues = {
  name: string,
};
const defaultValues: FormValues = {
  name: '',
};

const defaultErrors: FormErros = {
  name: '',
};

const WishListCreate = ({
  isOpen = false,
  handleClose,
  query = '',
  searching,
  searchResults,
  handleSubmit,
  selectedProductListItems = [],
}: Props) => {
  const [formValues, setFormValues] = useState(defaultValues);
  const [formErrors, setFormErrors] = useState(defaultErrors);

  const isInvalid = !formValues.name || selectedProductListItems.length <= 0;
  const hasResults = searchResults?.hits?.length > 0;

  const onSubmit = () => {
    handleSubmit(formValues.name, selectedProductListItems);
  };

  useEffect(() => {
    setFormValues(defaultValues);
    setFormErrors(defaultErrors);
    // eslint-disable-next-line
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="wishlist-create-backdrop">
      <Configure hitsPerPage={12} />
      <div className="wishlist-create-modal">
        <span className="wishlist-create-modal-close" onClick={handleClose}>
          &times;
        </span>
        <h1 className="wishlist-create-modal-title">Criar uma lista</h1>
        <section className="wishlist-create-modal-row">
          <FormControlInput
            variant="rounded"
            label="Nome da lista"
            value={formValues.name}
            maxLength={60}
            handleChangeText={value => {
              setFormValues(prevValue => ({ ...prevValue, name: value }));
            }}
            handleBlur={() => {
              if (!formValues.name) {
                return setFormErrors(errors => ({
                  ...errors,
                  name: 'É necessário informar o nome da lista',
                }));
              }
              setFormErrors(errors => ({
                ...errors,
                name: '',
              }));
            }}
            errorMessage={formErrors.name}
          />
        </section>
        <UnconnectedWishlistSearchInput />

        <section className="wishlist-create-modal-grid">
          {!hasResults && (
            <h3 className="wishlist-create-modal-subtitle">
              Nenhum item foi encontrado. :(
            </h3>
          )}
          <Hits hitComponent={WishlistProductSquare} />
        </section>
        <Button
          text="Salvar"
          buttonType="button-primary"
          onClick={onSubmit}
          disabled={isInvalid}
        />
      </div>
    </div>
  );
};

export default connectStateResults(WishListCreate);
