const burgerShopData = {
  firstBannerRichText: 'TENHA O <span>MELHOR</span> HAMBÚRGUER DA CIDADE',
  secondBannerRichText:
    'TRANSFORME SUA HAMBURGUERIA EM REFERÊNCIA DE QUALIDADE!',
  perfectBlend: {
    backgroundColor: '#fc6c2c',
    sectionTitle: 'MONTE O SEU <span>BLEND PERFEITO</span>',
    sectionSubTitle:
      'Conte com uma enorme variedade de carnes para hambúrgueres e blend incríveis',
  },
  faqQuestions: [
    {
      title: 'Como o Bigdelivery ajuda a potencializar minha hamburgueria?',
      body: 'Um lanche bem feito e de qualidade é facilmente percebido pelos clientes e no Bigdelivery atuamos para proporcionar apenas o melhor. Pode confiar!',
      isOpen: false,
    },
    {
      title:
        'Recebo muitos pedidos e o tempo é valioso. De que forma o Bigdelivery me auxilia nesta questão?',
      body: 'Agendar o melhor horário para a entrega dos seus pedidos e receber as carnes prontas para o preparo dos lanches, te leva a uma enorme otimização do seu tempo. Assim, você pode se dedicar a outros aspectos da sua hamburgueria.',
      isOpen: false,
    },
    {
      title: 'Há um limite para montar os meus blends?',
      body: 'Não mesmo! Aqui, você tem a liberdade para usar sua criatividade e desenvolver quantas combinações desejar. Teste novos sabores e surpreenda seus clientes!',
      isOpen: false,
    },
  ],
  comment:
    '"Com o bigdelivery ficou muito mais fácil gerir as coisas por aqui, antes eu tinha dois funcionários só para realizar o processo de compra, moagem e boleamento, agora eu só peço no bigdelivery e já recebo meu blend pronto pra uso!"',
};
export default burgerShopData;
