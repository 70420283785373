// Styles
import './KitsPresentationCard.css';

type Props = {
  title: string,
  text: string,
  image: string,
  isInverted: boolean,
};

const KitsPresentationCard = ({ title, text, image, isInverted }: Props) => {
  return (
    <div
      className={
        isInverted
          ? 'kits-presentation-card-main-container-inverted'
          : 'kits-presentation-card-main-container'
      }
    >
      <div className="kits-presentation-card-text-container">
        <h1 className="kits-presentation-card-title">{title}</h1>
        <p className="kits-presentation-card-text">{text}</p>
        <a
          href="#kits-product-banner"
          alt="Veja mais"
          className="kits-presentation-card-link"
          rel="noreferrer"
        >
          Descubra já!
        </a>
      </div>
      <div className="kits-presentation-card-image" alt={title}>
        <img src={image} alt={title}></img>
      </div>
    </div>
  );
};
export default KitsPresentationCard;
