// API
import Home from '../api';
// Redux Related
import { createAction } from 'redux-actions';
// Types
import type { Dispatch } from '../../types';
// Logger
import { logAPIException } from '../../logHelper';

export const getBannersRequest = createAction('GET_BANNERS_REQUEST');
export const getBanners = createAction('GET_BANNERS');

export function fetchAllBanners() {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getBannersRequest());
      const response = await Home.fetchBanners();
      dispatch(getBanners(response));
    } catch (err) {
      logAPIException(err);
      dispatch(getBanners(err));
    }
  };
}

export const postNewsLetterEmailRequest = createAction(
  'POST_NEWS_LETTER_EMAIL_REQUEST',
);
export const postNewsLetterEmail = createAction('POST_NEWS_LETTER_EMAIL');

export function storeNewsLetterEmail(email) {
  return async (dispacth: Dispatch) => {
    try {
      dispacth(postNewsLetterEmailRequest());
      const response = await Home.postNewsLetterEmail(email);
      dispacth(postNewsLetterEmail(response));
    } catch (err) {
      logAPIException(err);
      dispacth(postNewsLetterEmail(err));
    }
  };
}

///////////////// SCHEDULED PROMOTION RELATED

export const getScheduledDiscountsRequest = createAction(
  'GET_SCHEDULED_DISCOUNT_REQUEST',
);

export const getScheduledDiscountsSuccess = createAction(
  'GET_SCHEDULED_DISCOUNT_SUCCESS',
  response => response,
);

export const getScheduledDiscountsFailure = createAction(
  'GET_SCHEDULED_DISCOUNT_FAILURE',
  err => err,
);

export const getScheduledDiscounts = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getScheduledDiscountsRequest());
    try {
      const response = await Home.getScheduledDiscounts();
      dispatch(getScheduledDiscountsSuccess(response));
    } catch (err) {
      dispatch(getScheduledDiscountsFailure(err));
    }
  };
};
