// @flow
import React, { Component } from 'react';
// Compose
import { currentOrderNumberConnector } from './current-order';
//Others
import { phone } from './common';

import './WhatsappFloatButton.css';

class WhatsappFloatButton extends Component {
  props: Props;
  render() {
    const whatsAppmessage = `Olá. Gostaria de ter mais informações sobre ${
      this.props.currentOrderNumber
        ? `o meu pedido ${this.props.currentOrderNumber}`
        : 'a BigDelivery'
    }`;
    return (
      <a
        href={`https://wa.me/${phone.whatsAppNumber}?text=${whatsAppmessage}`}
        className="whatsapp-button-main"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-whatsapp whatsapp-button-i-content"></i>
      </a>
    );
  }
}

export default currentOrderNumberConnector(WhatsappFloatButton);
