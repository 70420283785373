import React from 'react';
// Styles
import './CardBrandOptionBox.css';

type Props = {
  text: String,
  isSelected: Boolean,
  onSelect: () => void,
  iconUrl: string,
};
const CardBrandOptionBox = (props: Props) => {
  return (
    <div
      className={`
        checkout-card-brand-option-box-content ${
          props.isSelected ? 'selected' : ''
        }`}
      onClick={props.onSelect}
    >
      {props.iconUrl && (
        <img
          className="checkout-card-brand-option-box-image"
          src={props.iconUrl}
          alt="icon"
        />
      )}
      <p className="checkout-card-brand-option-box-text">{props.text}</p>
    </div>
  );
};
export default CardBrandOptionBox;
