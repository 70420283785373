// @flow
import React, { useEffect, useRef } from 'react';
// Connector
import { withRouter, type ContextRouter } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import qs from 'qs';
// Analytics
import * as Analytics from '../../common/analytics';
// Components
import WishlistContainer from './WishlistContainer';
import UnauthenticatedRedirect from './UnauthenticatedRedirect';
import ProductListItemSquare from '../components/wishlist/ProductListItemSquare';
// Actions
import {
  clearSearch,
  deleteProductListItemsByListItemId,
  getProductListItemsByListId,
  updateSearch,
} from '../actions';
// Selectors
import {
  loggedInSelector,
  userProductListResultItemsSelector,
} from '../selectors';
// Types
import type { ProductList } from '../types';
// Styles
import './WishlistProductListPage.css';

type Props = {
  getProductListItems: (listId: number) => void,
  resultItems: ProductList['resultItems'],
  deleteProductListItemsByListItemId: (itemId: number, listId: number) => void,
  updateSearchState: (
    searchState: { page: number, perPage: number },
    route: string,
  ) => void,
  clearSearchState: () => void,
  loggedIn: boolean,
} & ContextRouter;

const WishlistProductListPage = ({
  location,
  history,
  match,
  getProductListItems,
  resultItems,
  deleteProductListItemsByListItemId,
  updateSearchState,
  clearSearchState,
  loggedIn,
}: Props) => {
  const searchRef = useRef(null);
  const searchState = qs.parse(location.search.slice(1));
  const productListId = match.params.id;
  const title = match.params.name;
  const route = `/lista-personalizada/${title}/${productListId}`;
  const currentPage = resultItems.currentPage;
  const pages = resultItems.pages;
  const PAGE_NUMBERS = [...Array(pages + 1).keys()].slice(1);

  const productListItems: ProductList['resultItems']['productListsItems'] =
    resultItems.productListItems || [];

  const handlePage = (value: 'next' | 'previous') => {
    if (value === 'next' && currentPage < pages) {
      const nextPage = currentPage + 1;
      return updateSearchState({ page: nextPage, perPage: 30 }, route);
    }
    const previousPage = currentPage - 1;
    return updateSearchState({ page: previousPage, perPage: 30 }, route);
  };

  const handleClickNumber = (value: number) => {
    if (value > 0 && value <= pages) {
      return updateSearchState({ page: value, perPage: 30 }, route);
    }
  };

  useEffect(() => {
    if (productListId && loggedIn) {
      Analytics.logUserOpenedWishlistProductListPage(title);
      updateSearchState({ page: 1, perPage: 30 }, route);
      searchRef.current = location?.search;
      getProductListItems(productListId, searchState.page, searchState.perPage);
    }
    return () => {
      clearSearchState();
    };
    // eslint-disable-next-line
  }, [productListId, loggedIn]);

  useEffect(() => {
    if (loggedIn && searchRef.current !== location.search) {
      getProductListItems(productListId, searchState.page, searchState.perPage);
      searchRef.current = location?.search;
    }
    // eslint-disable-next-line
  }, [location.search]);

  return (
    <UnauthenticatedRedirect>
      <WishlistContainer title={title}>
        <section className="wishlist-product-list-page-wrapper">
          <div className="wishlist-product-list-page-grid">
            {productListItems.length > 0 &&
              productListItems.map((productListItem, index) => (
                <ProductListItemSquare
                  key={index}
                  itemId={productListItem.id}
                  productId={productListItem.variant.id}
                  productImage={
                    productListItem.variant.images.length > 0 &&
                    productListItem.variant.images[0]
                  }
                  productManufacturer={productListItem.variant.manufacturer}
                  productPrice={productListItem.variant.price}
                  productListPrice={productListItem.variant.listPrice}
                  productSlug={productListItem.variant.slug}
                  productName={productListItem.variant.name}
                  productUnit={productListItem.variant.unitForQuantity}
                  productTotalOnHand={productListItem.variant.totalOnHand}
                  defaultQuantity={productListItem.variant.defaultQuantity}
                  inStock={productListItem.variant.inStock}
                  discontinued={productListItem.variant.discontinued}
                  incrementQuantity={productListItem.variant.incrementQuantity}
                  analyticsViewSource="product list square"
                  handleFavorite={itemId => {
                    deleteProductListItemsByListItemId(itemId, productListId);
                  }}
                />
              ))}
          </div>
          <div className="wishlist-product-list-page-pagination">
            <button
              disabled={currentPage === 1}
              onClick={() => {
                handlePage('previous');
              }}
              className="wishlist-product-list-page-pagination-button"
            >
              <i className="fa fa-chevron-left" />
            </button>
            {PAGE_NUMBERS.map(pageNumber => (
              <button
                key={pageNumber}
                onClick={() => handleClickNumber(pageNumber)}
                className={`wishlist-product-list-page-pagination-button ${
                  Number(currentPage) === Number(pageNumber) && 'selected'
                }`}
              >
                {pageNumber}
              </button>
            ))}
            <button
              disabled={currentPage === pages}
              onClick={() => {
                handlePage('next');
              }}
              className="wishlist-product-list-page-pagination-button"
            >
              <i className="fa fa-chevron-right" />
            </button>
          </div>
        </section>
      </WishlistContainer>
    </UnauthenticatedRedirect>
  );
};

const mapStateToProps = state => {
  return {
    resultItems: userProductListResultItemsSelector(state),
    loggedIn: loggedInSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: {}) => {
  return {
    getProductListItems: (
      listId: number,
      page: number = 1,
      perPage: number = 30,
    ) => {
      dispatch(getProductListItemsByListId(listId, page, perPage));
    },
    deleteProductListItemsByListItemId: async (
      itemId: number,
      listId: number,
    ) => {
      await dispatch(deleteProductListItemsByListItemId(itemId));
      dispatch(getProductListItemsByListId(listId));
    },
    updateSearchState: (
      searchState: { page: number, perPage: number },
      route: string,
    ) => {
      dispatch(updateSearch(searchState, route));
    },
    clearSearchState: () => dispatch(clearSearch()),
  };
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default withRouter(connector(WishlistProductListPage));
