// @flow
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { CPF } from 'cpf_cnpj';
// Components
import {
  FormButton,
  PageLoader,
  validationHelpers,
  FormControlInput,
} from '../../../common';
import MyAccountAddressesPage from '../../containers/MyAccountAddressesPage';
import MyAccountPasswordPage from '../../containers/MyAccountPasswordPage';
// Styles
import './MyAccountInformation.css';
// assets
import rotate_icon from '../../images/rotate-icon.svg';
// Helpers
import { useDeepCompareEffect } from '../../../common';

type Props = {
  information: {
    firstName: string,
    lastName: string,
    cpf: string,
    email: string,
  },
  loading: boolean,
  editingLoading: boolean,
  onSubmitUpdate: (
    firstName: string,
    lastName: string,
    cpf: string,
    email: string,
  ) => void,
};

const MyAccountInformation = (props: Props) => {
  const [accountForm, setAccountForm] = useState({
    firstName: props.information ? props.information.firstName : '',
    lastName: props.information ? props.information.lastName : '',
    cpf: props.information ? props.information.cpf : '',
    email: props.information ? props.information.email : '',

    firstNameError: '',
    lastNameError: '',
    cpfError: '',
    emailError: '',
  });
  const [isPasswordPopupOpen, setPasswordPopupOpen] = useState(false);

  const openPasswordPopup = () => {
    setPasswordPopupOpen(true);
  };

  const closePasswordPopup = () => {
    setPasswordPopupOpen(false);
  };

  useDeepCompareEffect(() => {
    // If the user information has changed we should update our accountForm
    if (!_.isEqual(props.information, accountForm)) {
      setAccountForm({
        firstName: props.information ? props.information.firstName : '',
        lastName: props.information ? props.information.lastName : '',
        cpf: props.information ? props.information.cpf : '',
        email: props.information ? props.information.email : '',
      });
    }
    // eslint-disable-next-line
  }, [props.information]);

  // Validates the form on submission. If there are no validation error dispatches action.
  const validateUserInput = () => {
    let noValidationErrors = true;

    const firstNameError = validationHelpers.user.firstName(
      accountForm.firstName,
    );
    setAccountForm({ ...accountForm, firstNameError });
    if (firstNameError) {
      noValidationErrors = false;
    }

    const lastNameError = validationHelpers.user.lastName(accountForm.lastName);
    setAccountForm({ ...accountForm, lastNameError });
    if (lastNameError) {
      noValidationErrors = false;
    }

    const cpfError = validationHelpers.user.cpf(accountForm.cpf);
    setAccountForm({ ...accountForm, cpfError });
    if (cpfError) {
      noValidationErrors = false;
    }

    const emailError = validationHelpers.user.email(accountForm.email);
    setAccountForm({ ...accountForm, emailError });
    if (emailError) {
      noValidationErrors = false;
    }

    if (noValidationErrors) {
      props.onSubmitUpdate(
        accountForm.firstName,
        accountForm.lastName,
        accountForm.cpf,
        accountForm.email,
      );
    } else {
      console.error('Validation error on user editing form.');
    }
  };

  if (props.loading) {
    return <PageLoader />;
  }

  return (
    <div>
      {isPasswordPopupOpen && (
        <div className="my-account-information-password-popup-overlay">
          <div className="my-account-information-password-popup">
            <MyAccountPasswordPage onClose={closePasswordPopup} />
          </div>
        </div>
      )}
      <div className="my-account-information-main-container">
        <div className="my-account-information">
          <div className="my-account-information-header-container">
            <div className="my-account-information-header-text-content">
              <h3>Meu Perfil</h3>
              <h6>Gerenciar e proteger sua conta</h6>
            </div>
            <button
              className="my-account-information-change-password-button"
              onClick={openPasswordPopup}
            >
              <img src={rotate_icon} alt="Icone de Troca"></img>
              Trocar senha
            </button>
          </div>
          <hr></hr>
          <form
            onSubmit={e => {
              e.preventDefault();
              validateUserInput();
            }}
          >
            <div className="my-account-information-content">
              <div className="my-account-information-content-row">
                <FormControlInput
                  variant="rounded"
                  placeholder="Nome"
                  value={accountForm.firstName}
                  onChange={e =>
                    setAccountForm({
                      ...accountForm,
                      firstName: e.target.value,
                    })
                  }
                  errorMessage={accountForm.firstNameError || ''}
                />

                <FormControlInput
                  variant="rounded"
                  placeholder="Sobrenome"
                  value={accountForm.lastName}
                  onChange={e =>
                    setAccountForm({ ...accountForm, lastName: e.target.value })
                  }
                  errorMessage={accountForm.lastNameError || ''}
                />

                <FormControlInput
                  variant="rounded"
                  className="my-account-information-medium-input"
                  placeholder="CPF (apenas números)"
                  value={`***.***.${CPF.format(accountForm.cpf).substring(
                    8,
                    14,
                  )}`}
                  onChange={e =>
                    setAccountForm({ ...accountForm, cpf: e.target.value })
                  }
                  errorMessage={accountForm.cpfError || ''}
                  disabled
                />
              </div>
              <FormControlInput
                type="text"
                variant="rounded"
                className="my-account-information-medium-input"
                placeholder="Email"
                value={accountForm.email}
                onChange={e =>
                  setAccountForm({ ...accountForm, email: e.target.value })
                }
                isRequired={false}
                disabled
                errorMessage={accountForm.emailError || ''}
              />
            </div>
            <div className="my-account-information-submit-content">
              <div className="my-account-information-submit-button">
                <FormButton
                  text="Salvar"
                  icon=""
                  buttonType={'form-button-quarternary'}
                  loading={props.editingLoading}
                />
              </div>
            </div>
          </form>
        </div>
        <MyAccountAddressesPage />
      </div>
    </div>
  );
};

export default MyAccountInformation;
