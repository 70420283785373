// @flow
import React, { useState } from 'react';
import { slice } from 'lodash';
// Styles
import './BlogRecentPosts.css';
// Components
import { Link } from 'react-router-dom';

type Props = {
  posts: Post[];
}

const POSTS_PER_PAGE = 4

const BlogRecentPosts = (props): Props => {
  const [maxNumberOfPosts, setMaxNumberOfPosts] = useState(4);

  const numberOfAvailablePosts = props.posts.length;
  const allPostsShown = maxNumberOfPosts >= numberOfAvailablePosts
  const paginatedPosts = slice(props.posts, 0, maxNumberOfPosts);

  const loadMore = () => {
    setMaxNumberOfPosts(maxNumberOfPosts + POSTS_PER_PAGE);
  };
  
  if (props.loading && props.posts.length === 0) {
    return (
      <div className='d-flex justify-content-center align-items-center p-3'>
        <div className="loading ">
        <i
          className="fa fa-spinner fa-pulse fa-4x loading-icon"
          aria-hidden="true"
        />
        </div>
      </div>
    )
  }
  return (
    <div className="blog-last-posts-main">
      {props.title ? 
        <h2>Últimas postagens</h2>
      : null
      }
      <div className='blog-last-posts-grid'>
      {paginatedPosts.map((post: any) => (
        <Link key={post.slug} to={`/blog/${post.slug}`} className='blog-last-posts-anchor-card'>
          <img src={post.thumbnailImageUrl} alt={post.title}></img>
          <h3>{post.title}</h3>
          <p>{post.description}</p>
        </Link>))}
      </div>
      {allPostsShown ? (
      <></>
      ) : (
      <button onClick={loadMore} className='blog-last-posts-load-more'>Carregar mais</button>
      )}
    </div>
    )
};

export default BlogRecentPosts;

