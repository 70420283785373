// @flow
import React, { Component } from 'react';
import './ProgressiveImageLoader.css';
import ProgressiveImage from 'react-progressive-image';
// Types
import type { ProductImage } from '../../types/ProductImage';
import noImage from '../images/noImageChicken.png';

type Props = {
  selectedImage: ?ProductImage,
  imageToShow: 'small' | 'product' | 'large',
};

type State = {
  imageHasLoaded: ?boolean,
};

class ProgressiveImageLoader extends Component {
  props: Props;
  state: State;

  render() {
    const imageSrc =
      this.props.selectedImage &&
      this.props.selectedImage[`${this.props.imageToShow}Url`];

    // If we don't have an image show the placeholder
    if (!imageSrc) {
      return (
        <img
          src={noImage}
          alt="Produto sem foto"
          className="progressive-image-loader-final-img"
        />
      );
    }

    return (
      <ProgressiveImage
        src={imageSrc}
        placeholder={
          this.props.selectedImage && this.props.selectedImage.miniUrl
        }
      >
        {(src, loading) => (
          <img
            src={src}
            alt={this.props.selectedImage && this.props.selectedImage.alt}
            className={
              loading
                ? 'progressive-image-loader-mini-img'
                : 'progressive-image-loader-final-img'
            }
          />
        )}
      </ProgressiveImage>
    );
  }
}

export default ProgressiveImageLoader;
