// Images
import meat from '../images/meat.png';
import schedule from '../images/scheduleAlt.svg';
import shoppingCart from '../images/carrinhocliqueAlt.svg';
import cuttingBoard from '../images/tabuadecorteAlt.svg';

const homemadeData = {
  homemadeColumnIcons: [
    {
      img: shoppingCart,
      alt: 'Carrinho de compras',
      text: 'Compre de qualquer lugar, receba em casa',
    },
    {
      img: schedule,
      alt: 'Calendário',
      text: 'Entrega agendada',
    },
    {
      img: cuttingBoard,
      alt: 'Tábua de corte',
      text: 'Em cubos ou moída?, Você escolhe',
    },
    {
      img: meat,
      alt: 'Carne',
      text: 'Carne limpa e maciça, pronta para o preparo dos pratos',
    },
  ],
  faqQuestions: [
    {
      title: 'Preciso pagar um valor a mais para receber a carne limpa?',
      body: 'Não! Esse serviço é oferecido por nós para poder oferecer mais qualidade de vida e praticidade na sua rotina, por um custo-benefício justo.',
      isOpen: false,
    },
    {
      title: 'Como funciona a entrega agendada?',
      body: 'Para facilitar sua vida, ajustamos juntos a melhor dada para a sua entrega, garantindo que você esteja disponível para receber seus produtos.',
      isOpen: false,
    },
    {
      title: 'Preciso da carne com urgência. Posso ir buscar?',
      body: 'Não, mas contamos com um serviço de entrega expressa, permitindo que o seu produto esteja em suas mãos no mesmo dia que a compra for fechada!',
      isOpen: false,
    },
    {
      title: 'Vocês aceitam devolução de produtos?',
      body: 'Sim! Possuímos uma política de troca caso algum produto seja enviado fora do padrão, errado, ou diferente do pedido.',
      isOpen: false,
    },
  ],
};

export default homemadeData;
