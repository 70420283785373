import React from 'react';
// Styles
import './PaymentOptionBox.css';

type Props = {
  text: String,
  isSelected: Boolean,
  iconSize: 'small' | 'large',
  onSelect: () => void,
  iconUrl: string,
};

const PaymentOptionBox = (props: Props) => {
  return (
    <div
      className={`
        checkout-option-box-content ${props.isSelected ? 'selected' : ''}`}
      onClick={props.onSelect}
    >
      {props.iconUrl && (
        <img
          className={`checkout-option-box-image-${props.iconSize}`}
          src={props.iconUrl}
          alt="icon"
        />
      )}
      <p className="checkout-option-box-text">{props.text}</p>
    </div>
  );
};
export default PaymentOptionBox;
