// @flow
import React, { Component } from 'react';
// Composers
import { connect } from 'react-redux';
// Components
import { Helmet } from 'react-helmet';
import BlogHero from '../components/BlogHero';
import BlogPromotionalFeatures from '../components/BlogPromotionalFeatures';
import BlogRecentPosts from '../components/BlogRecentPosts';
import BlogNews from '../components/BlogNews';
import BlogFeaturedPosts from '../components/BlogFeaturedPosts';
// Selectors
import { currentPostsIndexLoadingSelector, postsBlogSelector, postsNewsSelector, pinnedPostsSelector }  from '../selectors';
/// Actions
import { findPostsBlog, findPostsNews, findPinnedPosts } from '../actions';
/// Types
import type { Dispatch } from '../../types';
import type { Post } from '../types';
// Styles
import './BlogHomePage.css';

type Props = {
  posts: Post[],
  postsBlog: Post[],
  postsNews: Post[],
  pinnedPosts: Post[],
  loading: boolean,
};

class BlogHomePage extends Component {
  props: Props;

  componentDidMount() {
    this.props.findPinnedPosts(); 
    this.props.findPostsNews();
    this.props.findPostsBlog();
  }
  
  render() {
    return (
      <div>
        <Helmet>
          <title>Blog Bigdelivery, Seu açougue virtual</title>
          <meta
            name="description"
            content="Descubra tudo sobre carnes e como prepará-las das melhores maneiras em nosso blog!"
          />
          <meta property="og:url" content={`${process.env.PUBLIC_URL}/blog`}></meta>
          <meta property="og:type" content="website"></meta>
          <meta property="og:title" content="Blog do Bigdelivery - Seu açougue virtual" />
          <meta
            name="og:description"
            content="Descubra tudo sobre carnes e como prepará-las das melhores maneiras em nosso blog!"
          />
          <meta
            property="og:image"
            content={`${process.env.PUBLIC_URL}/white_logo_on_red_background.jpg`}
          />
          <meta property="og:image:type" content="image/jpg" />
          <meta property="og:image:alt" content="Bigdelivery logo" />
        </Helmet>

        <BlogHero></BlogHero>

        <BlogPromotionalFeatures></BlogPromotionalFeatures>

        <BlogFeaturedPosts loading={this.props.loading} posts={this.props.pinnedPosts}></BlogFeaturedPosts>

        <BlogNews loading={this.props.loading} posts={this.props.postsNews}></BlogNews>
        
        <BlogRecentPosts loading={this.props.loading}  title posts={this.props.postsBlog}></BlogRecentPosts>
         
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    postsBlog: postsBlogSelector(state),
    postsNews: postsNewsSelector(state),
    pinnedPosts: pinnedPostsSelector(state),
    loading: currentPostsIndexLoadingSelector(state)
  };
};

const mapDispatchToProps = (dispatch : Dispatch, ownProps) => {
  return {
    findPostsBlog: () => {
      dispatch(findPostsBlog());
    },
    findPostsNews: () => {
      dispatch(findPostsNews());
    },
    findPinnedPosts: () => {
      dispatch(findPinnedPosts());
    },
    
  };
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default connector(BlogHomePage);
