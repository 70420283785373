// @flow
import React from 'react';
import { CNPJ } from 'cpf_cnpj';
import _ from 'lodash';
// Helpers
import { truncateString } from '../../../common';
// Analytics
import * as Analytics from '../../../common/analytics';
// Assets
import address_icon from '../../images/address_icon.svg';
// Styles
import './MyAccountAddressCard.css';

type Props = {
  address: [],
  isSelected?: boolean,
  deletingLoading: boolean,
  onClickSelect?: () => void,
  onClickEdit: () => void,
  onClickDelete: () => void,
  showActions?: boolean,
};

const MyAccountAddressCard = (props: Props) => {
  const name = truncateString(_.upperCase(props.address.fullName), 29);
  const company = truncateString(_.upperCase(props.address.company || ''), 29);
  const address1 = truncateString(
    _.upperCase(props.address.address1 || ''),
    29,
  );
  const address2 = truncateString(
    _.upperCase(props.address.address2 || ''),
    29,
  );

  return (
    <div
      className={`my-account-address-card-main
      ${props.isSelected ? 'selected' : ''}
      `}
      onClick={() => {
        if (props.onClickSelect) {
          Analytics.logSelectedAddress(props.address);
          props.onClickSelect();
        } else console.log('Action no permitted!');
      }}
    >
      <div className="my-account-address-card-main-content">
        <img src={address_icon} alt="Endereço"></img>
        <div
          className={`my-account-address-card-main-content-info ${
            props.isSelected ? 'selected' : ''
          }`}
        >
          <p>{company}</p>
          <p>{name}</p>
          <p>{address1}</p>
          <p>{address2}</p>
          <p>
            {_.upperCase(props.address.city)}{' '}
            {_.upperCase(props.address.neighborhood)}{' '}
            {_.upperCase(props.address.zipcode)}
          </p>
          {props.address.cnpj ? (
            <p>CNPJ: {CNPJ.format(props.address.cnpj)}</p>
          ) : null}
          {props.address.ieCompany ? (
            <p>
              IE:{' '}
              {props.address.ieCompany.slice(
                0,
                props.address.ieCompany.length - 2,
              ) +
                '-' +
                props.address.ieCompany.slice(-2)}
            </p>
          ) : null}
        </div>
      </div>
      {props.showActions && (
        <div className="my-account-address-card-main-actions">
          <button
            className="my-account-address-card-main-action"
            onClick={e => {
              e.stopPropagation();
              props.onClickEdit();
            }}
          >
            <i className="fa fa-edit "></i>
          </button>
          <button
            className="my-account-address-card-main-action"
            onClick={e => {
              e.stopPropagation();
              props.onClickDelete();
            }}
          >
            <i className="fa fa-trash "></i>
            Apagar
          </button>
        </div>
      )}
    </div>
  );
};
export default MyAccountAddressCard;
