// @flow
import React from 'react';
// Styles
import './PanelCard.css';

type Props = {
  children: ReactNode,
  number: number,
  title: string,
  handleClick: () => void,
  isOpen: boolean,
  isCompleted: boolean,
  isLoading: boolean,
};

const PanelCard = ({
  children,
  number,
  title,
  handleClick,
  isOpen,
  isCompleted,
  isLoading,
}: Props) => {
  return (
    <div
      className={`panel-card ${isOpen ? 'open' : ''} ${
        !isOpen && isCompleted ? 'completed' : ''
      }`}
    >
      <div
        className={`panel-card-header ${isOpen ? 'open' : ''} ${
          !isOpen && isCompleted ? 'completed' : ''
        }`}
        onClick={handleClick}
      >
        <div className="panel-card-header-info">
          <div
            className={`panel-card-header-step  ${isOpen ? 'open' : ''} ${
              !isOpen && isCompleted ? 'completed' : ''
            }`}
          >
            <span>{number}</span>
          </div>
          <h2
            className={`panel-card-header-title ${isOpen ? 'open' : ''} ${
              !isOpen && isCompleted ? 'completed' : ''
            }`}
          >
            {title}
          </h2>
        </div>
        <div className="panel-card-header-actions">
          {isLoading && !isOpen ? (
            <div
              className="spinner-border text-light spinner-style"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <button className="panel-card-header-button" onClick={handleClick}>
              <i className="fa fa-edit " />
            </button>
          )}
        </div>
      </div>
      <div
        className={`panel-card-body ${isOpen ? 'panel-card-body-open' : ''}`}
      >
        {children}
      </div>
    </div>
  );
};

export default PanelCard;
