import wholeMeatIcon from '../images/wholeMeat.svg';
import grillMeatIcon from '../images/grillMeat.svg';
import meatSkewerIcon from '../images/meatSkewer.svg';

const cutData = [
  {
    id: 1,
    title: 'Inteiro',
    icon: wholeMeatIcon,
    value: 'inteiro',
  },
  {
    id: 10,
    title: 'Grelha',
    icon: grillMeatIcon,
    value: 'grelha',
  },
  {
    id: 11,
    title: 'Espeto',
    icon: meatSkewerIcon,
    value: 'espeto',
  },
];

export default cutData;
