// @flow
import React, { useEffect, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { Link } from 'react-router-dom';
import './SearchResultHorizontal.css';
// Components
import SearchResultImage from './SearchResultImage';
import SearchResultHorizontalBottomRow from './SearchResultHorizontalBottomRow';
import Tooltip from './Tooltip';
// Analytics
import * as Analytics from '../../common/analytics';
// Helpers
import { camelizeKeys } from 'humps';
// Types
import type { Hit } from '../types';

type Props = {
  hit: Hit,
};

const SearchResultHorizontal = ({ hit }: Props) => {
  // USE ANALYTICS
  const [visibility, setVisibility] = useState(false);
  const camelizedHit: Hit = camelizeKeys(hit);

  useEffect(() => {
    if (visibility) {
      Analytics.logProductImpression(
        camelizedHit,
        window.location.pathname,
        'search result',
      );
    }
    // eslint-disable-next-line
  }, [visibility]);
  // USE ANALYTICS

  return (
    <VisibilitySensor
      onChange={isVisible => {
        setVisibility(isVisible);
      }}
    >
      <section className="search-result-hit-horizontal-main">
        <SearchResultImage hit={camelizedHit} />

        <div className="search-result-hit-container">
          <div className="search-result-hit-container-text">
            <span className="search-result-hit-manufacture">
              {camelizedHit.manufacturer}
            </span>
            <Link to={`/produtos/${hit.slug}/`}>
              <h3 className="search-result-hit-horizontal-title">
                {camelizedHit.name}
              </h3>
            </Link>
            {camelizedHit.cookingRecommendations.length > 0 && (
              <div className="search-result-hit-cooking-methods">
                <span>Ideal para:</span>
                {camelizedHit.cookingRecommendations.map((cr, index) => (
                  <Tooltip key={index} content={cr.name}>
                    <img src={cr.bigUrl} alt={cr.name} />
                  </Tooltip>
                ))}
              </div>
            )}
          </div>

          <SearchResultHorizontalBottomRow
            productId={camelizedHit.id}
            manufacturer={camelizedHit.manufacturer}
            listPrice={camelizedHit.listPrice}
            price={camelizedHit.price}
            cookingMethods={camelizedHit.cookingRecommendations}
            unit={camelizedHit.unitForQuantity}
            defaultQuantity={camelizedHit.defaultQuantity}
            incrementQuantity={camelizedHit.incrementQuantity}
            inStock={camelizedHit.inStock}
          />
        </div>
      </section>
    </VisibilitySensor>
  );
};

export default SearchResultHorizontal;
