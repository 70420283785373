// @flow
import React, { Component } from 'react';

import './FormButton.css';

type Props = {
  text: string,
  icon: string,
  buttonType: string,
  loading: boolean,
};

class FormButton extends Component {
  props: Props;
  render() {
    const text = this.props.text;

    return (
      <button
        className={`form-button-main ${this.props.buttonType}`}
        type="submit"
        disabled={this.props.loading}
      >
        {this.props.icon !== ''
          ? <div className="row form-button-row">
            <img className="form-button-icon" alt="" src={this.props.icon} />
            {' '}
            &nbsp;
              {' '}
            {this.props.text}
          </div>
          : <div className="row form-button-row">
            {this.props.loading
              ? <i
                className="fa fa-spinner fa-pulse fa-1x"
                aria-hidden="true"
              />
              : <div />}
            <span className="form-button-just-text">{text}</span>
          </div>}
      </button>
    );
  }
}

export default FormButton;
