// Code based on https://github.com/kentcdodds/use-deep-compare-effect
import { useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';

type UseEffectParams = Parameters<typeof useEffect>;
type EffectCallback = UseEffectParams[0];
type DependencyList = UseEffectParams[1];
type UseEffectReturn = ReturnType<typeof useEffect>;

function checkDeps(deps: DependencyList) {
  if (!deps || !deps.length) {
    throw new Error(
      'useDeepCompareEffect should not be used with no dependencies. Use useEffect instead.',
    );
  }
  if (deps.every(isPrimitive)) {
    throw new Error(
      'useDeepCompareEffect should not be used with dependencies that are all primitive values. Use useEffect instead.',
    );
  }
}

//check if it is primitive value
function isPrimitive(val: unknown) {
  return val == null || /^[sbn]/.test(typeof val);
}

//"value" the value to be memoized (usually a dependency list)
//return a memoized version of the value as long as it remains deeply equal
export function useDeepCompareMemoize(value: T) {
  const ref = useRef(value);
  const signalRef = useRef(0);

  if (!_.isEqual(value, ref.current)) {
    ref.current = value;
    signalRef.current += 1;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => ref.current, [signalRef.current]);
}

function useDeepCompareEffect(
  callback: EffectCallback,
  dependencies: DependencyList,
): UseEffectReturn {
  if (process.env.NODE_ENV !== 'production') {
    checkDeps(dependencies);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(callback, useDeepCompareMemoize(dependencies));
}

export default useDeepCompareEffect;
