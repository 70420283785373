// Images
import meats from '../images/barbecueMeats.svg';
import knifeAndTable from '../images/barbecueKnifeAndTable.svg';
import schedule from '../images/barbecueSchedule.svg';
import addToCart from '../images/barbecueAddToCart.svg';
import client from '../images/client.png';

const barbecueData = {
  benefits: [
    {
      img: meats,
      title: 'Escolha as carnes',
      alt: 'Escolha as carnes',
    },
    {
      img: knifeAndTable,
      title: 'Selecione os cortes',
      alt: 'Selecione os cortes',
    },
    {
      img: addToCart,
      title: 'Peça no site ou no aplicativo',
      alt: 'Peça no site ou no aplicativo',
    },
    {
      img: schedule,
      title: 'Agende a entrega',
      alt: 'Agenda',
    },
  ],
  productSections: [
    {
      paragraph: 'A queridinha do churrasco:',
      title: 'PICANHA',
      description:
        'Um corte nobre e suculento. A gordura presente na picanha é responsável por dar sabor e maciez à carne, resultando em um churrasco memorável.',
      variant: 'barbecue_rump_cap',
    },

    {
      paragraph: 'A suculenta acessível:',
      title: 'Miolo da Alcatra',
      description:
        'Um corte macio e suculento. É uma opção mais acessível em comparação com outros cortes nobres, mas sem comprometer o sabor.',
      variant: 'barbecue_ rump_core',
    },

    {
      paragraph: 'A macia e saborosa:',
      title: 'Maminha',
      description:
        'Um corte muito apreciado no churrasco. Possui uma bela camada de gordura que ajuda a manter a umidade e o sabor da carne.',
      variant: 'barbecue_rump_skirt',
    },
    {
      paragraph: 'O que derrete na boca:',
      title: 'Cupim',
      description:
        'Um corte macio e suculento. É uma opção mais acessível em comparação com outros cortes nobres, mas sem comprometer o sabor.',
      variant: 'barbecue_hump_steak',
    },
    {
      paragraph: 'O que não pode faltar',
      title: 'Variedades',
      description:
        'Adicione mais sabores e texturas diferentes ao seu churrasco, aumentando a diversidade gastronômica do evento e agradando a diferentes gostos.',
      variant: 'barbecue_varieties',
    },
    {
      paragraph: 'O que facilita a vida:',
      title: 'KITS',
      description:
        'Para quem busca a conveniência e segurança de contar com kits criados por especialistas quando o assunto é churrasco.',
      variant: 'barbecue_kits',
    },
  ],
  faqQuestions: [
    {
      title:
        'Tenho dúvidas sobre como organizar o meu churrasco. Como saber a quantidade de carnes ideal?',
      body: 'Não se preocupe, no Churrascômetro, em nosso site, você insere informações, como a quantidade de pessoas que vai estar presente, tipos de cortes e complementos de preferência, e a gente calcula a quantidade e te indica os produtos ideais para ter aquele churrasco de respeito!',
      isOpen: false,
    },
    {
      title: 'Quais são as vantagens de comprar no Bigdelivery?',
      body: 'Além de ter várias opções de carnes, você pode selecionar os cortes de sua preferência. Garantimos uma carne saborosa, sem gorduras excessivas, do jeitinho que você pediu. Sem contar, a praticidade de comprar sem sair de casa. Você agenda o horário, e a gente leva o pedido até você.',
      isOpen: false,
    },
    {
      title: 'A carne do Bigdelivery é limpa e de confiança?',
      body: 'O nosso trabalho segue todos os procedimentos necessários para que você receba uma carne de primeira qualidade. Da produção à entrega, cuidamos de tudo para que você tenha a melhor experiência de compra e se delicie com uma carne suculenta e sem gorduras excessivas.',
      isOpen: false,
    },
    {
      title: 'Posso agendar a entrega?',
      body: 'Sim! Ao finalizar o pedido, você poderá agendar o dia e o horário da entrega, conforme a sua disponibilidade.',
      isOpen: false,
    },
  ],
  comments: [
    {
      text: '“Eu adoro churrasco, mas não tenho paciência para enfrentar filas em açougues. Com o BigDelivery, posso comprar tudo o que preciso sem sair de casa!”',
      author: {
        name: 'Maria Silva',
        img: client,
        status: 'Cliente Satisfeita',
      },
    },
    {
      text: '“Comprar no BigDelivery foi a melhor escolha que fiz para o meu churrasco! As carnes são de primeira qualidade e o atendimento é impecável!”',
      author: {
        name: 'Maria Silva',
        img: client,
        status: 'Cliente Satisfeito',
      },
    },
  ],
};
export default barbecueData;
