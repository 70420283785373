/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo  } from 'react';
import { Redirect } from 'react-router-dom';
// Components
import { ColorRow } from '../../common';
import { UserValidate } from '../../user';
// Selectors
import { currentOrderPaymentSelector } from '../selectors';
// Redux
import { connect } from 'react-redux';
// Styles
import './PixPage.css';
// Assets
import phoneIcon from '../images/phone-icon.svg';
import dataFrameIcon from '../images/data-frame-icon.svg';
import piggyBankIcon from '../images/piggy-bank-icon.svg';
import character from '../images/Character.svg'
import clockIcon from '../images/clock-icon.svg'
import copyPastIcon from '../images/copy-past-icon.svg';
//Connectors
import { userEmailConnector } from '../../user';
import { blendsListConnector } from '../../blend';
import { pastOrdersConnector } from '../../past-orders';
// Helpers
import {  copyToClipboard } from '../../common/commonHelpers'
// Other
import qs from 'qs';

type Props = {
  // From email connector
  email: string,

  // From blends connector
  blendsByOrderNumber: (orderNumber: string) => void,
  getBlends: (orderNumber: string) => void,

  // From past order connector
  getPastOrder: (orderId: string, orderToken: ?string) => void,
  pastOrderById: (orderId: string) => void,
  pastOrderItemsById: (orderId: string) => LineItem[],
  orderLoading: boolean,

  // From selectors
  items: LineItem[],
  orderPayment: PaymentMethod,
};

const calculateQrCodeExpiration = (createdAt) => {
  if(!createdAt) return '';
  const oneDay = 24 * 60 * 60 * 1000; 
  const createdAtDate = new Date(createdAt);
  const currentDate = new Date();
  const difference = currentDate - createdAtDate;

  if (difference > oneDay) {
    return 'Esse QR code está expirado';
  } else {
    const timeLeft = oneDay - difference;
    const hoursLeft = Math.floor(timeLeft / (60 * 60 * 1000));
    return `Esse QR code expira em ${hoursLeft} horas`;
  }
};

const PixPage = (props: Props) => {
  const [displayCopiedMessage, setDisplayCopiedMessage] = useState(false);

  const orderNumber =
    props.match.params.id || qs.parse(props.location.search.substr(1)).id || '';

  const orderToken =
    props.match.params.order_token ||
    qs.parse(props.location.search.substr(1)).order_token ||
    null;

  const order = props.pastOrderById(orderNumber);
  const orderPayment =
    order && order.payments && order.payments[order.payments.length - 1];

  const remainingTime = useMemo(() => {
    return calculateQrCodeExpiration(orderPayment?.createdAt);
  }, [orderPayment?.createdAt]);

  const items = props.pastOrderItemsById(orderNumber);
  const blends = props.blendsByOrderNumber(orderNumber);

  useEffect(() => {
    props.getPastOrder(orderNumber, orderToken);
    props.getBlends(orderNumber);
    const interval = setInterval(() => {
      props.getPastOrder(orderNumber, orderToken);
    }, 5000);
    return () => clearInterval(interval);
  }, []); // TODO: We are overwriting ESLint to avoid having exhaustive deps here. Fix this to follow best practices.

  if (orderPayment && orderPayment.state === 'completed') {
    return <Redirect to={`/confirmacao?id=${orderNumber}`} />;
  }

  // We the order was not paid via PIX we should redirect
  if (orderPayment && orderPayment.sourceType !== 'PixPayment') {
    return <Redirect to={`/confirmacao?id=${orderNumber}`} />;
  }

  if (props.orderLoading && !order) {
    return (
      <div className="pix-page-loading">
        <i
          className="fa fa-spinner fa-pulse fa-5x search-results-page-spinner"
          aria-hidden="true"
        />
        <p>Carregando...</p>
      </div>
    );
  }

  if (!orderPayment || !order || (!items && !blends)) {
    return (
      <>
        <ColorRow color="red" text="AGUARDANDO PAGAMENTO - PIX" />
        <div className="pix-page-payment-not-found-container">
          <div className="pix-page-payment-not-found-text">
            Oops, pagamento não encontrado...
          </div>
          <img
            className="pix-page-payment-not-found-image"
            src={require('../../common/images/chicken_magnify_glass.png')}
            alt="pix not found"
          />
        </div>
      </>
    );
  }

  return (
    <>
      <UserValidate />
      <div className='pix-page-main-container'>
        <div className='pix-page-text-orderNumber'>
          <h3>O código do seu pedido é:</h3>
          <h1>#{orderNumber}</h1>
        </div>
        <div className='pix-page-qrCode-payment'>
          <div className="pix-page-image-container">
              <img
                src={`data:image/png;base64,${orderPayment.pixQrcodeBase64}`}
                alt="codigo-qr-pix"
                className="pix-page-image"
              />
          </div>
          <div className='pix-page-payment-instructions-container'>
            <div className='pix-page-payment-instructions'>
              <h2>Como pagar seu PIX</h2>
              <div className='pix-page-payment-first-instruction'>
                <img
                  src={phoneIcon}
                  alt='phoneIcon'
                />
                <p>Utilize o aplicativo do seu banco copiando o código PIX ou escaneando o QR-Code.</p>
              </div>
              <div className='pix-page-payment-second-instruction' >
              <img
                  src={dataFrameIcon}
                  alt='phoneIcon'
                />
                <p>Confirme os dados de pagamento e o valor do seu pedido.</p>
              </div>
              <div className='pix-page-payment-third-instruction'>
              <img
                  src={piggyBankIcon}
                  alt='phoneIcon'
                />
                <p>Seu pagamento será processado e debitado do valor disponível em sua conta-corrente.</p>
              </div>
            </div>
            <div className='pix-page-character-icon'>
                <img
                  src={character}
                  alt='character'
                />
            </div>
          </div>
          <div className='pix-page-warnings'>
            <div className='pix-page-warnings-expiration-time'>
              <img
                src={clockIcon}
                alt='clockIcon'
              />
              <div>
                <h2>{remainingTime}</h2>
                <p>Ao realizar o pagamento você será informado por email</p>
              </div>
            </div>
            <div 
              className='pix-page-warnings-copy-code'
              onClick={() => {
                  copyToClipboard(orderPayment.pixCopyAndPaste);
                  setDisplayCopiedMessage(true);
                  setTimeout(() => setDisplayCopiedMessage(false), 3000);
              }}
            >
              <img
                  src={copyPastIcon}
                  alt='copyPastIcon'
              />
              <div className='pix-page-copy-past-code'>
                  <h2>Copie e cole o seu código PIX</h2>
                  <div className="pix-page-pix-code">
                      <h6 className="">
                          {orderPayment.pixCopyAndPaste.substring(0, 25) + '... '}
                      </h6>
                      <p className="pix-page-text pix-code-copied">
                          {displayCopiedMessage && 'Copiado!'}
                      </p>
                  </div>
              </div>
          </div>
          </div>
        </div>
        
        <div className='pix-page-description'>
          <p>Escaneie o <span className='pix-page-black-text'>QR Code</span> ou copie o <span className='pix-page-black-text'>código PIX</span> . Abra o APP da instituição que você possui o PIX cadastrado e realize o pagamento. Em breve você <span className='pix-page-black-text '>receberá um email</span> no juryscleitin@gmail.com com os dados desse pedido. Para garantir a sua segurança e a de nossos outros clientes esse pedido irá passar por uma <span className='pix-page-black-text'>avaliação de crédito</span> e um <span className='pix-page-black-text'>documento de identidade</span> será requerido para <span className='pix-page-black-text'>receber o produto</span>. O nome que aparecerá na sua fatura do cartão de crédito será <span className='pix-page-black-text'>"Pag*Bigdelivery"</span>. Caso tenha dúvidas, estamos disponíveis pelo <span className='pix-page-green-text'>WhatsApp no (71) 3500-3100</span></p>
        </div>

        <div className='pix-page-buttons'>
          <a href='/' className='pix-page-go-to-navigation-button'>
            CONTINUAR NAVEGANDO
          </a>
          <a href='/minha-conta/pedidos' className='pix-page-go-to-orders-button'> 
            VER MEUS PEDIDOS
          </a>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state, ownProps: {}) => {
  return {
    orderPayment: currentOrderPaymentSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({});

const connector: Connector<OwnProps, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default pastOrdersConnector(
  blendsListConnector(userEmailConnector(connector(PixPage))),
);
