// @flow
import React, { Component } from 'react';

import './MiniColorRow.css';

type Props = {
  text: string,
  color: string,
};


class MiniColorRow extends Component {
  props: Props;
  render() {
    let selectedColor = ""
    if (this.props.color === "red") {
      selectedColor = "mini-color-row-red"
    } else if (this.props.color === "black") {
      selectedColor = "mini-color-row-black"
    } else if (this.props.color === "grey") {
      selectedColor = "mini-color-row-grey"
    }

    return (
      <div className={`mini-color-row-main ${selectedColor}`}>{this.props.text}</div>
    );
  }
}

export default MiniColorRow;