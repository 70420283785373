// @flow
import React from 'react';
import { CNPJ } from 'cpf_cnpj';
// Styles
import './AddressSummary.css';
// Types
import type { OrderAddress } from '../../types';

type Props = {
  address: OrderAddress,
};

const AddressSummary = (props: Props) => {
  const address = props.address;
  return (
    <div className="address-summary-main">
      {address.company ? <p>{address.company}</p> : null}
      <p>{address.fullName}</p>
      <p>
        {address.address1}, {address.address2.substring(0, 10)}
        {address.address2.length >= 15 && '...'}
      </p>
      <p>
        {address.city} - {address.zipcode}
      </p>
      {address.cnpj ? <p>CNPJ: {CNPJ.format(address.cnpj)}</p> : null}
      {address.ieCompany ? (
        <p>
          IE:{' '}
          {address.ieCompany.slice(0, address.ieCompany.length - 2) +
            '-' +
            address.ieCompany.slice(-2)}
        </p>
      ) : null}
    </div>
  );
};
export default AddressSummary;
