// @flow
import React from 'react';
// Styles
import './ChristmasBanner.css';
// Assets
import arrow from '../images/black-friday-banner-arrow.svg';
import ArrowRight from '../images/arrow-right.svg';
import ChristmasImage from '../images/christmas-banner-image.png';
import SnowBorderTopLeft from '../images/snow-border-top-left.svg';
import SnowBorderTopRight from '../images/christmasBanner-top-right-snow.svg';
// Data
import { framesData } from '../data/ChristmasData';

const ChristmasBanner = () => {
  return (
    <div className="christmas-banner-main-container">
      <div className="christmas-banner-top-content">
        <div className="christmas-banner-top-content-text">
          <h1 className="christmas-banner-header-title">
            Economize no{' '}
            <span className="christmas-banner-bold-text">Natal do BIG</span>
          </h1>
          <p className="christmas-banner-header-text">
            Mais de 35 anos de experiência, oferecemos carne de qualidade,
            descontos incríveis e conveniência. Junte-se a milhares de clientes
            agora!
          </p>
          <a
            id="scrollToSlider"
            href="#christmas-product-slider"
            className="christmas-banner-home-link"
          >
            Aproveite as ofertas agora!
            <img src={ArrowRight} alt="Ir para a página Inicial"></img>
          </a>
        </div>
        <div className="christmas-banner-top-content-image">
          <img src={ChristmasImage} alt="Árvore de natal"></img>
        </div>
      </div>

      <div className="christmas-banner-low-container">
        <img src={SnowBorderTopLeft} alt="Borda de neve"></img>
        <img src={SnowBorderTopRight} alt="Borda de neve"></img>
        <div className="christmas-banner-low-content">
          <div className="christmas-banner-low-image-container">
            <img src={arrow} alt="Icone de Seta"></img>
          </div>
          <div className="christmas-banner-low-content">
            {framesData.map(({ image, title, description }, index) => (
              <div key={index} className="christmas-banner-frames">
                <img src={image} alt={`Frame ${index + 1}`} />
                <div>
                  <h1>{title}</h1>
                  <p>{description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChristmasBanner;
