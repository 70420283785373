// @flow
import React from 'react';
// Styles
import './BlackFridayBanner.css';
// Assets
import arrow from '../images/black-friday-banner-arrow.svg';
// Data
import { framesData } from '../data/blackFridayData';

const BlackFridayBanner = () => {
  return (
    <div className="black-friday-banner-main-container">
      <div className="black-friday-banner-top-content">
        <div className="black-friday-banner-top-content-text">
          <h1 className="black-friday-banner-header-title">
            Economize na{' '}
            <span className="black-friday-banner-bold-text">Black Friday!</span>
          </h1>
          <p className="black-friday-banner-header-text">
            Com mais de 35 anos de experiência, oferecemos carne de qualidade,
            descontos incríveis e conveniência. Junte-se a milhares de clientes
            agora!
          </p>
        </div>
      </div>
      <div className="black-friday-banner-low-container">
        <div className="black-friday-banner-low-image-container">
          <img src={arrow} alt="Icone de Seta"></img>
        </div>
        <div className="black-friday-banner-low-content">
          {framesData.map(({ image, title, description }, index) => (
            <div key={index} className="black-friday-banner-frames">
              <img src={image} alt={`Frame ${index + 1}`} />
              <div>
                <h1>{title}</h1>
                <p>{description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlackFridayBanner;
