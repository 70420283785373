// @flow
import { schema } from 'normalizr';
import { camelizeKeys } from 'humps';

export const lineItem = new schema.Entity(
  'lineItems',
  {},
  { processStrategy: entity => camelizeKeys(entity) },
);

export const promotion = new schema.Entity('promotions');

export const paymentMethod = new schema.Entity('paymentMethods');

export const shippingMethod = new schema.Entity('shippingMethods');

export const shippingRate = new schema.Entity(
  'shippingRates',
  {},
  {
    processStrategy: entity => ({
      ...entity,
      deliveryWindowStart: new Date(entity.deliveryWindowStart),
      deliveryWindowEnd: new Date(entity.deliveryWindowEnd),
    }),
  },
);

export const creditCard = new schema.Entity('creditCards');

export const shipment = new schema.Entity('shipments', {
  shippingRates: [shippingRate],
  selectedShippingRate: shippingRate,
  shippingMethods: [shippingMethod],
});

export const order = new schema.Entity(
  'orders',
  {
    lineItems: [lineItem],
    paymentMethods: [paymentMethod],
    shipments: [shipment],
    creditCards: [creditCard],
  },
  { idAttribute: 'number', processStrategy: entity => camelizeKeys(entity) },
);

export const orders = new schema.Array(order);
export const promotions = new schema.Array(promotion);
