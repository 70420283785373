// @flow
import { authGet } from '../../api';
import * as schema from './schema';
import { camelizeKeys } from 'humps';
import { normalize } from 'normalizr';

type PostsType = {
  entities: {
    [id: string]: Post
  },
  result: [string]
}

const Posts = {

  // Multiple by category
  async getByCategory(category: string): Promise<PostsType> {
    const response = await authGet(`/blog/articles?q[category_eq]=${category}`);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.posts);
    return normalized;
  },
  // Multiple pinned
  async getPinnedPosts(): Promise<PostsType> {
    const response = await authGet(`/blog/articles?q[pinned_eq]=true`);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.posts);
    return normalized;
  },
  // Single by Id
  async get(id: string): Promise<Post> {
    const response = await authGet(`/blog/articles/${id}`);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.post);
    return normalized;
  },
};

export default Posts;
