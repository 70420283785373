// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Selectors
import {
  creditCardsSelector,
  userIdSelector,
  gettingUserCreditCardsLoadingSelector,
  deletingdUserCreditCardLoadingSelector,
} from '../selectors';
// Actions
import { getCreditCards, deleteCreditCard } from '../actions';
// Types
import type { CreditCard } from '../types';
import type { Dispatch } from '../../types';

type OwnProps = {
  creditCards: CreditCard[],
  userId: number,
  creditCardsLoading: boolean,
  deletingLoading: boolean,
  getCreditCards: () => void,
};

function userCreditCardsConnector(WrappedComponent: ReactClass<any>) {
  const mapStateToProps = (state, ownProps) => {
    return {
      creditCards: creditCardsSelector(state),
      userId: userIdSelector(state),
      creditCardsLoading: gettingUserCreditCardsLoadingSelector(state),
      deletingLoading: deletingdUserCreditCardLoadingSelector(state),
    };
  };

  const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
    return {
      getCreditCards: () => {
        dispatch(getCreditCards());
      },
      deleteCreditCard: (creditCardId: number) => {
        dispatch(deleteCreditCard(creditCardId));
      },
    };
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class userCreditCardsConnector extends Component {
      constructor(props: OwnProps) {
        super(props);
        if (props.userId) {
          props.getCreditCards();
        }
      }

      UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.props.userId && nextProps.userId) {
          this.props.getCreditCards();
        }
      }

      render() {
        return (
          <WrappedComponent
            {...this.props}
            creditCards={this.props.creditCards}
            creditCardsLoading={this.props.creditCardsLoading}
            deletingLoading={this.props.deletingLoading}
          />
        );
      }
    },
  );
}

export default userCreditCardsConnector;
