// @flow
import React from 'react';
//Styles
import './OptionValue.css';

type Props = {
  handleOptionValueClick: () => void,
  isSelected: boolean,
  optionValue: {
    id: number,
    imageUrl: string,
    name: string,
  },
};

const OptionValue = ({
  handleOptionValueClick,
  isSelected,
  optionValue,
}: Props) => {
  const selected = isSelected ? 'selected' : '';

  return (
    <div className="barbecue-option-value-container">
      <div
        className={`barbecue-option-value-content ${selected}`}
        onClick={() => {
          handleOptionValueClick(optionValue.id);
        }}
      >
        <img
          className="barbecue-option-value-image"
          src={optionValue.imageUrl}
        />
        <div className="text">{optionValue.name}</div>
      </div>
    </div>
  );
};

export default OptionValue;
