// @flow
import React, { Component } from 'react';
// Components
import InputFieldErrorMessage from './InputFieldErrorMessage';
// Styles
import './InputWithValidation.css';

type Props = {
  value: ?string,
  validationError: string,
  validator: (value: string) => string,
};

class InputWithValidation extends Component {
  props: Props;

  render() {
    const { value, onChange, validationError, className, ...inputParams } =
      this.props;
    const classList = `${className || ''} ${
      validationError && 'input-with-validation-error'
    }`;

    return (
      <div className="input-with-validation-main">
        <InputFieldErrorMessage message={validationError} />
        <input
          {...inputParams}
          className={classList}
          value={value ? value : ''}
          onChange={onChange}
        />
      </div>
    );
  }
}

export default InputWithValidation;
