// @flow
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
// Types
import type { LineItem } from '../../current-order/types';
import type { Blend } from '../../blend/types';
// Components
import { ItemListImage } from '../../common';
// Helpers
import { quantityConverter, scaleAndFormatPrice } from '../../common';
//Connectors
import blendsListConnector from '../../blend/connectors/blendsListConnector';
// Style
import './LineItemList.css';

type Props = {
  lineItems: LineItem[],
  orderNumber: String,
  getBlends: (orderNumber: number) => void,
  blendsByOrderNumber: (orderNumber: number) => Blend[],
};

const mix = (blends, mixId) => {
  return _.filter(blends, function (o) {
    return o.id === mixId;
  }).pop();
};

const LineItemList = ({
  items = [],
  orderNumber,
  getBlends,
  blendsByOrderNumber,
}: Props) => {
  const [showObservation, setShowObservation] = useState(false);
  const hasLineItems = items.length > 0;

  useEffect(() => {
    if (orderNumber) {
      getBlends(orderNumber);
    }
    // eslint-disable-next-line
  }, [orderNumber]);

  const blends = blendsByOrderNumber(orderNumber);

  const handleToggleShowObservation = () => {
    setShowObservation(prevValue => !prevValue);
  };

  return (
    <section className="line-item-list-main">
      <h3 className="line-item-list-main-title">Produtos</h3>
      {hasLineItems &&
        items.map((lineItem: LineItem, index) => (
          <div className="line-item-list-item" key={index}>
            <ItemListImage variant={lineItem.variant} />
            <div className="line-item-list-column">
              <div className="line-item-list-item-content">
                <span>{lineItem.variant.manufacturer}</span>
                <h4>{lineItem.variant.name}</h4>
                {mix(blends, lineItem.mixId)
                  ? ` - ${mix(blends, lineItem.mixId).name}`
                  : ''}
              </div>
              <div className="line-item-list-row">
                <span className="line-item-list-row-text">
                  Quantidade do pedido
                </span>
                <div className="line-item-list-row-info">
                  {quantityConverter(
                    lineItem.quantity,
                    lineItem.variant.unitForQuantity,
                  )}
                </div>
              </div>
              <div className="line-item-list-row">
                <span className="line-item-list-row-text">
                  Quantidade pesada
                </span>
                <div className="line-item-list-row-info">
                  {quantityConverter(
                    lineItem.producedQuantity,
                    lineItem.variant.unitForQuantity,
                  )}
                </div>
              </div>
              <div className="line-item-list-row">
                <span className="line-item-list-row-text">Preço estimado</span>
                <span className="line-item-list-row-info-price">
                  {scaleAndFormatPrice(
                    lineItem.price,
                    lineItem.variant.unitForQuantity,
                  )}
                </span>
              </div>
              {lineItem.observation && (
                <>
                  <button
                    className="line-item-list-button"
                    onClick={handleToggleShowObservation}
                  >
                    Observação
                    <i
                      class={`fa fa-chevron-${showObservation ? 'up' : 'down'}`}
                    ></i>
                  </button>
                  {showObservation && (
                    <textarea
                      disabled
                      contentEditable={false}
                      value={lineItem.observation}
                      className="line-item-list-observation"
                    />
                  )}
                </>
              )}
            </div>
          </div>
        ))}
    </section>
  );
};

export default blendsListConnector(LineItemList);
