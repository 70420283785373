// @flow
import React from 'react';
// Components
import { Helmet } from 'react-helmet';
// Images
import Banner from '../images/banner.png';
// Styles
import './WorkWithUsPage.css';

// Components
import { PageTitleWithGoBack } from '../../common';

const WorkWithUsPage = () => {
  return (
    <div className="work-with-us-main">
      <Helmet>
        <title>Trabalhe Conosco - Bigdelivery, Seu açougue virtual</title>
      </Helmet>

      <PageTitleWithGoBack title="Gostaria de trabalhar conosco?" />

      <div className="work-with-us-wrapper">
        <div className="work-with-us-text">
          <h3>
            <b> Somos uma empresa em crescimento</b> e estamos{' '}
            <b>sempre em busca de talentos</b> para se juntarem a nossa equipe.
          </h3>
          <p>
            Envie seu currículo para o email rh@bigdelivery.com.br para
            participar de nosso processo de seleção.
          </p>
          <a href="mailto: rh@bigdelivery.com.br">
            Enviar email{' '}
            <i className="fa fa-paper-plane-o" aria-hidden="true"></i>
          </a>
        </div>
        <img className="work-with-us-image" src={Banner} alt="" />
      </div>
    </div>
  );
};

export default WorkWithUsPage;
