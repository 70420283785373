// @flow
import React from 'react';
import { Link } from 'react-router-dom';
// Analytics
import * as Analytics from '../../common/analytics';
//Composers
import { userLoggedInConnector } from '../../user';

import './NavList.css';

import type { Department } from '../types';

type Props = {
  departments: Department[],
};

const NavList = (props: Props) => {
  // We should only show up to 5 departments
  const departments = props.departments.slice(0, 5);

  return (
    <nav className="nav-list-main">
      {departments.map((d, ind) => (
        <Link
          onClick={() => Analytics.logTappedNavList(d.name.toLowerCase())}
          to={`/departamentos/${d.id}/`}
          key={ind}
        >
          {d.name
            ? d.name.charAt(0).toUpperCase() + d.name.slice(1).toLowerCase()
            : null}
        </Link>
      ))}
      <h3 className="nav-list-blend">
        {props.loggedIn ? (
          <Link
            onClick={() => Analytics.logTappedNavList('meus blends')}
            to="/blend/meus-blends/"
          >
            Meus blends
          </Link>
        ) : (
          <Link
            onClick={() => Analytics.logTappedNavList('blends')}
            to="/blend/"
          >
            Blends
          </Link>
        )}
      </h3>
      <h3 className="nav-list-barbecue-calculator">
        <Link
          onClick={() => Analytics.logTappedNavList('churrascometro')}
          to="/bigchurrasco/"
        >
          Churrascômetro
        </Link>
      </h3>
      <h3 className="nav-list-departments">
        <Link
          onClick={() => Analytics.logTappedNavList('departamentos')}
          to="/departamentos/"
        >
          Departamentos
        </Link>
      </h3>
      {/*TODO: Implement Recipes later <h3 className="nav-list-recipes"><Link to="/receitas">RECEITAS</Link></h3>*/}
    </nav>
  );
};
export default userLoggedInConnector(NavList);
