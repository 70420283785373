// Images
import meat from '../images/black-friday-banner-first-frame-image.png';
import cashBack from '../images/black-friday-banner-second-frame-image.png';
import freeShipment from '../images/black-friday-banner-third-frame-image.jpeg';
import clock from '../images/black-friday-banner-fourth-frame-image.png';
import barbecueKitImage from '../images/barbecue-kit-image.png';
import barbecuePreparation from '../images/barbecue-preparation-image.png';

export const framesData = [
  {
    image: meat,
    title: 'Tudo que você precisa em um só lugar!',
    description:
      'Descubra a praticidade e a conveniência de encontrar tudo o que precisa em um único lugar com nossos kits especialmente elaborados.',
  },
  {
    image: cashBack,
    title: 'Como funciona o Cashback no BigClub?',
    description:
      'No BigClub, suas compras valem ainda mais! Com o Cashback, você recebe uma porcentagem do valor gasto de volta.',
  },
  {
    image: freeShipment,
    title: 'Frete grátis em pedidos acima de 150 reais',
    description:
      'Economize com entregas gratuitas! Faça pedidos acima de R$150, tenha a vantagem do frete grátis.',
  },
  {
    image: clock,
    title: 'Receba o pedido no horário que se adapta à sua rotina',
    description:
      'Não se preocupe com o horário da entrega! No Big Delivery, agende a entrega para o momento que melhor se encaixa na sua rotina.',
  },
];

export const barbecueKitData = {
  title: `Domine o Churrasco com Nossos Kits Especiais!`,
  text: `Aos amantes de churrasco, apresentamos o Kit Tradicional Big Carnes,
  uma seleção cuidadosa dos cortes mais suculentos e sab orosos, pensada especialmente
  para elevar sua experiência na grelha a outro nível.`,
  image: barbecueKitImage,
};

export const barbecuePreparationData = {
  title: `A Experiência Completa do Churrasco ao seu Alcance!`,
  text: `Por que se contentar com cortes convencionais quando você pode ter um Kit Tradicional?
  Comprar um kit no Big Delivery é mais do que simplesmente adquirir carne; é garantir uma experiência
  completa de churrasco, do início ao fim.`,
  image: barbecuePreparation,
};

export const kitsFaqData = [
  {
    question: `Quais tipos de kits estão disponíveis?`,
    answer: `Oferecemos uma variedade de kits, incluindo o Kit Dia a Dia, Kit Almôndegas, Kit Burguês e Kit Fique Big,
    cada um com sua seleção exclusiva de cortes de carne e opções de personalização.`,
  },
  {
    question: `Como faço para escolher o kit certo para mim?`,
    answer: `Explore nossa seleção de kits e escolha o que melhor atende às suas necessidades e preferências.
    Se tiver dúvidas, entre em contato conosco para obter assistência personalizada.`,
  },
  {
    question: `Qual é o processo de entrega dos kits?`,
    answer: `Os kits são entregues diretamente na sua porta, com opções de entrega agendada para sua conveniência.
    Certifique-se de fornecer um endereço e horário de entrega precisos durante o processo de compra.`,
  },
  {
    question: `Posso fazer alterações no meu pedido após a compra?`,
    answer: `Dependendo da disponibilidade e do estágio do processamento do seu pedido, podemos tentar acomodar
    alterações. Entre em contato conosco o mais rápido possível se precisar fazer uma alteração no seu pedido.`,
  },
  {
    question: `Qual é a política de devolução dos kits?`,
    answer: `Se houver algum problema com seu pedido ou se não estiver satisfeito com os produtos recebidos,
    entre em contato conosco imediatamente para que possamos resolver o problema. Consulte nossa política de
    devolução para obter mais detalhes.`,
  },
  {
    question: `Posso agendar a entrega do meu kit para um horário específico?`,
    answer: `Sim, oferecemos opções de entrega agendada para que você possa receber seu kit no momento mais conveniente
    para você. Durante o processo de compra, você poderá selecionar o horário de entrega desejado.`,
  },
  {
    question: `Comprando um kit, eu estarei apto a receber algum benefício do BigClub?`,
    answer: `Sim! Ao adquirir qualquer um de nossos kits, você não só desfrutará dos deliciosos produtos
    selecionados, mas também poderá receber cashback com a compra!`,
  },
];
