// @flow
import React from 'react';

type Props = {
  containerStyle: string,
  iconLeftStyle: string,
  iconRightStyle: string,
  disabledClassName: string,
  arrowScrollerClassName: string,
  direction: string,
  className?: string,
  style?: any,
  onClick?: () => void,
};

const SlickSliderArrow = (props: Props) => {
  const containerStyles = `${props.containerStyle}-${props.direction}
  ${props.className}`;
  const iconStyles = `${props.iconLeftStyle}-${props.direction} ${
    props.iconRightStyle
  } ${!props.onClick && `${props.disabledClassName}`} ${
    props.arrowScrollerClassName
  }`;

  return (
    <div
      className={containerStyles}
      style={props.style}
      onClick={props.onClick}
    >
      <i className={iconStyles} aria-hidden="true" />
    </div>
  );
};

export default SlickSliderArrow;
