// @flow
import React from 'react';
import { CNPJ } from 'cpf_cnpj';
import _ from 'lodash';
// Helpers
import { truncateString } from '../../../common';
//Types
import type { UserAddress } from '../../types';
// Analytics
import * as Analytics from '../../../common/analytics';
//Styles
import './MyAccountAddressItem.css';
// Assets
import address_icon from '../../images/address_icon.svg';
import address_icon_alt from '../../images/address_icon_alt.svg';
import trash_icon from '../../images/trash-icon.svg';
import edit_icon from '../../images/edit-icon.svg';

type Props = {
  address: UserAddress,
  isSelected?: boolean,
  deletingLoading: boolean,
  onClickSelect?: () => void,
  onClickEdit: () => void,
  onClickDelete: () => void,
};

const MyAccountAddressItem = (props: Props) => {
  const name = truncateString(_.upperCase(props.address.receiverName), 29);
  const company = truncateString(_.upperCase(props.address.company || ''), 29);
  const addressLine1 = truncateString(
    _.upperCase(props.address.addressLine1 || ''),
    29,
  );
  const addressLine2 = truncateString(
    _.upperCase(props.address.addressLine2 || ''),
    29,
  );

  return (
    <div
      className={`my-account-address-item-main
      ${props.isSelected ? 'selected' : ''}
      `}
      onClick={() => {
        if (props.onClickSelect) {
          Analytics.logSelectedAddress(props.address);
          props.onClickSelect();
        } else console.log('Action no permitted!');
      }}
    >
      <div className="my-account-address-item-main-content">
        <img
          src={props.isSelected ? address_icon : address_icon_alt}
          alt="Endereço"
        />
        <div
          className={`my-account-address-item-main-content-info ${
            props.isSelected ? 'selected' : ''
          }`}
        >
          <p>{company}</p>
          <p>{name}</p>
          <p>{addressLine1}</p>
          <p>{addressLine2}</p>
          <p>
            {_.upperCase(props.address.city)}{' '}
            {_.upperCase(props.address.neighborhood)}{' '}
            {_.upperCase(props.address.zipcode)}
          </p>
          {props.address.cnpj ? (
            <p>CNPJ: {CNPJ.format(props.address.cnpj)}</p>
          ) : null}
          {props.address.ieCompany ? (
            <p>
              IE:{' '}
              {props.address.ieCompany.slice(
                0,
                props.address.ieCompany.length - 2,
              ) +
                '-' +
                props.address.ieCompany.slice(-2)}
            </p>
          ) : null}
        </div>
      </div>
      <div className="my-account-address-item-main-actions">
        <button
          className="my-account-address-item-main-action"
          onClick={e => {
            e.stopPropagation();
            props.onClickDelete();
          }}
        >
          <img src={trash_icon} alt="Apagar endereço"></img>
          <h6>Apagar</h6>
        </button>
        <button
          className="my-account-address-item-main-action"
          onClick={e => {
            e.stopPropagation();
            props.onClickEdit();
          }}
        >
          <img src={edit_icon} alt="Editar endereço"></img>
          <h6>Editar</h6>
        </button>
      </div>
    </div>
  );
};
export default MyAccountAddressItem;
