// @flow
import React, { useState } from 'react';
// Styles
import './LandingProductList.css';
// Components
import Slider from 'react-slick';
import { AddToCartButton } from '../../current-order';
import { ProductPrice, ProgressiveImageLoader } from '../../common';
//Data
import cutData from '../data/cutData';
// Types
import { Product } from '../../products';
// Analytics
import { logLandingSliderSlid } from '../../common/analytics';

type Props = {
  sliderProductsVariants: Product[],
  currentLanding: string,
};

const SlickSliderArrow = props => {
  const containerStyles = `landing-product-list-arrow-container-${props.direction}
  ${props.className}`;
  const iconStyles = `fa fa-caret-${props.direction} fa-4x ${
    !props.onClick && 'landing-product-list-scroller-arrow-disabled'
  } landing-product-list-scroller-arrow  scroller-arrow`;

  return (
    <div
      className={containerStyles}
      style={props.style}
      onClick={props.onClick}
    >
      <i className={iconStyles} aria-hidden="true" />
    </div>
  );
};

const LandingProductList = ({
  sliderProductsVariants = [],
  productsVariants = [],
  currentLanding,
}: Props) => {
  const [selectedOptionValue, setSelectedOptionValue] = useState('inteiro');

  const productVariantsFiltered = selectedOptionValue
    ? selectedOptionValue === 'inteiro'
      ? sliderProductsVariants.filter(
          p =>
            !p.name.toLocaleLowerCase().includes('grelha') &&
            !p.name.toLocaleLowerCase().includes('espeto') &&
            !p.name.toLocaleLowerCase().includes('espetinho'),
        )
      : sliderProductsVariants.filter(p => {
          if (selectedOptionValue === 'espeto') {
            return (
              p.name.toLocaleLowerCase().includes(selectedOptionValue) ||
              p.name.toLocaleLowerCase().includes('espetinho')
            );
          } else {
            return p.name.toLocaleLowerCase().includes(selectedOptionValue);
          }
        })
    : sliderProductsVariants;

  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    vertical: false,
    lazyLoad: true,
    arrows: true,
    nextArrow: <SlickSliderArrow direction="right" />,
    prevArrow: <SlickSliderArrow direction="left" />,
  };

  const onSlideChange = index => {
    logLandingSliderSlid(currentLanding);
  };

  if (sliderProductsVariants.length <= 0) {
    return null;
  }

  return (
    <div className="landing-product-list-container">
      <div className="landing-product-list-categories">
        {cutData.map(c => (
          <div key={c.id} className="landing-product-categories-item">
            <input
              className="landing-product-categories-item"
              type="checkbox"
              checked={selectedOptionValue === c.value}
              value={c.value}
              onChange={ev => setSelectedOptionValue(ev.target.value)}
              name="cut"
            />
            <img
              className="landing-product-categories-item"
              src={c.icon}
              alt={c.title + '-icon'}
            />
            <label className="landing-product-categories-item" htmlFor="cut">
              {c.title}
            </label>
          </div>
        ))}
      </div>
      <div className="landing-product-list-slider">
        {productVariantsFiltered.length > 0 && (
          <Slider {...settings} afterChange={onSlideChange}>
            {productVariantsFiltered.map((sliderProduct, index) => {
              return (
                <div
                  className="landing-product-list-slider-wrapper"
                  key={index}
                >
                  <div className="landing-product-list-slider-meal-card">
                    <ProgressiveImageLoader
                      selectedImage={sliderProduct.images[0]}
                      imageToShow={'large'}
                    />
                    <div className="landing-product-list-slider-meal-card-content">
                      <h4 className="landing-product-list-slider-meal-card-title">
                        {sliderProduct.name}
                      </h4>

                      <div className="landing-product-list-slider-meal-card-actions">
                        <ProductPrice
                          productListPrice={sliderProduct.listPrice}
                          productPrice={sliderProduct.price}
                          productUnit={sliderProduct.unitForQuantity}
                        />
                        <AddToCartButton
                          showNotifyMeButton={false}
                          productId={sliderProduct.id}
                          slug={sliderProduct.slug}
                          unitForQuantity={sliderProduct.unitForQuantity}
                          defaultQuantity={sliderProduct.defaultQuantity}
                          incrementQuantity={sliderProduct.incrementQuantity}
                          inStock={sliderProduct.inStock}
                          discontinued={sliderProduct.discontinued}
                          shouldNotifyUser={sliderProduct.shouldNotifyUser}
                          analyticsEventType={'landingPage'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default LandingProductList;
