import moneyPlant from '../images/money-plant.svg';
import onlineBusiness from '../images/online-business.svg';
import payPerClick from '../images/pay-per-click.svg';
import piggyBank from '../images/piggy-bank.svg';

const infoCardsBigClub = [
  {
    img: moneyPlant,
    alt: 'dinheiro-planta',
    text: (
      <p>
        <b>Economize mais </b>
        <br />
        em cada pedido.
      </p>
    ),
  },
  {
    img: piggyBank,
    alt: 'cofrinho',
    text: (
      <p>
        Utilize seu <br />
        <b>saldo acumulado.</b>
      </p>
    ),
  },
  {
    img: payPerClick,
    alt: 'clique-e-pague',
    text: (
      <p>
        <b>Aproveite descontos</b> <br />
        <span>incríveis com cashback.</span>
      </p>
    ),
  },
  {
    img: onlineBusiness,
    alt: 'comprar-online',
    text: (
      <p>
        <b>Compre mais</b> <br />
        <span>por menos.</span>
      </p>
    ),
  },
];

export default infoCardsBigClub;
