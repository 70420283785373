// @flow
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
// Styles
import './MyAccountNavigator.css';
// Assets
import cube from '../../images/cube-icon.svg';
import data from '../../images/data-icon.svg';
import delivery from '../../images/delivery-icon.svg';
import logout from '../../images/logout-icon.svg';
import evaluation from '../../images/evaluation-icon.svg';

const MyAccountNavigator = () => {
  const [isMyDataOpen, setMyDataOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (isProfileOrCardsPage) setMyDataOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleMyData = () => {
    setMyDataOpen(!isMyDataOpen);
  };

  const isProfileOrCardsPage =
    location.pathname === '/minha-conta/informacoes' ||
    location.pathname === '/minha-conta/cartoes';

  return (
    <div
      className={`my-account-navigator-main  ${
        isMyDataOpen ? 'my-account-navigator-main-expand' : ''
      }`}
    >
      <h3>Minha conta</h3>
      <div className="my-account-navigator">
        <NavLink
          to="/minha-conta/visao-geral"
          className="my-account-navigator-main-link"
          activeClassName="my-account-navigator-main-link-active"
        >
          {' '}
          <img src={cube} alt="Visão geral"></img>
          Visão geral
        </NavLink>
        <div className="my-account-dropdown" onClick={toggleMyData}>
          <button
            className={`my-account-navigator-main-link my-account-navigator-dropdown-button ${
              isProfileOrCardsPage
                ? 'my-account-navigator-main-link-active'
                : ''
            }`}
          >
            <img src={data} alt="Meus dados"></img>
            Meus dados
          </button>
          <ul
            className={`my-account-dropdown-content ${
              isMyDataOpen ? 'open' : ''
            }`}
          >
            <NavLink
              to="/minha-conta/informacoes"
              className="my-account-navigator-main-link"
              activeClassName="my-account-navigator-dropdown-link-active"
            >
              Perfil e Endereços
            </NavLink>
            <NavLink
              to="/minha-conta/cartoes"
              className="my-account-navigator-main-link"
              activeClassName="my-account-navigator-dropdown-link-active"
            >
              Meus Cartões
            </NavLink>
          </ul>
        </div>

        <NavLink
          to="/minha-conta/pedidos"
          className="my-account-navigator-main-link"
          activeClassName="my-account-navigator-main-link-active"
        >
          <img src={delivery} alt="Meus pedidos"></img>
          Meus pedidos
        </NavLink>

        <NavLink
          to="/logout"
          className="my-account-navigator-main-link my-account-navigator-logout"
          activeClassName="my-account-navigator-main-link-active"
        >
          <img src={logout} alt="Logout"></img>
          Logout
        </NavLink>
      </div>
    </div>
  );
};

export default MyAccountNavigator;
