// @flow
/**
 * BLEND MODULE EXPORTS
 * This is the "interface" file for the user module.
 * You should only use exports from this file.
 */

/**
 * Imports
 * =======
 * Import default exports from files inside
 * the module
 */
import reducers from './reducer';
import BlendLadingPage from './containers/BlendLadingPage';
import BlendCalculatorPage from './containers/BlendCalculatorPage';
import BlendsIndexPage from './containers/BlendsTemplateIndexPage';
import BlendList from './containers/BlendList';
import blendsListConnector from './connectors/blendsListConnector';

/**
 * Reducer
 * =======
 * The default export is the reducer to be integrated in the Redux Store.
 * Its state is defined too, and exported as the type UserStoreState.
 */

export default reducers;

/**
 * Widgets
 * =======
 * Widgets are connected components that can be used inside a page.
 */
export { BlendList };

/**
 * Pages
 * =====
 * Pages are components that are used in the router.
 * They are auto-rendered by react-router and thus have
 * to be self-sufficient (no passed props).
 */

export { BlendLadingPage, BlendCalculatorPage, BlendsIndexPage };

/**
 * HOCs and Connectors
 * ===================
 * Higher order components and connectors wrap the children component
 * and add behavior or props that depend on the internal user state.
 */
export { blendsListConnector };
