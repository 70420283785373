// @flow
import { useEffect } from 'react';
// Components
import KitsBanner from '../components/KitsBanner';
import KitsDivisor from '../components/KitsDivisor';
import KitsPresentationCard from '../components/KitsPresentationCard';
import KitsFooterBanner from '../components/KitsFooterBanner';
import Faq from '../components/Faq';
import KitProductBanner from '../components/KitProductBanner';
// styles
import './KitsLandingPage.css';
// Data
import {
  barbecueKitData,
  barbecuePreparationData,
  kitsFaqData,
} from '../data/kitsData';
// Analytics
import * as Analytics from '../../common/analytics';

const KitsLandingPage = () => {
  useEffect(() => {
    // USE ANALYTICS
    Analytics.logUserOpenedKitsLandingPage();
  }, []);

  return (
    <div className="kits-landing-page-main-container">
      <KitsBanner />
      <KitsPresentationCard
        title={barbecueKitData.title}
        text={barbecueKitData.text}
        image={barbecueKitData.image}
      />
      <KitsDivisor />
      <KitsPresentationCard
        title={barbecuePreparationData.title}
        text={barbecuePreparationData.text}
        image={barbecuePreparationData.image}
        isInverted={true}
      />
      <KitProductBanner />
      <Faq data={kitsFaqData} />
      <KitsFooterBanner />
    </div>
  );
};
export default KitsLandingPage;
