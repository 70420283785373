// @flow
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
// Styles
import './RepeatLastPurchase.css';
// Assets
import closeIcon from '../images/repeat-last-purchase-closeBtn.svg';
// Components
import { Link, withRouter } from 'react-router-dom';
import { ItemListImage } from '..';
import Button from '../../common/components/Button';
import { ConfirmationModal } from '../../common';
// Helpers
import { quantityConverter } from '..';
// Analytics
import { logUserTappedRepeatLastPurchase } from '../analytics';
// Types
import { Order } from '../../types';
import { LineItem } from '../../current-order/types';

type Props = {
  orderNumber: string,
  order: Order[],
  orders: Order[],
  prevOrderItems: LineItem[],
  currentOrderItems: LineItem[],
  getPastOrder: (orderNumber: string) => void,
  copyingOrder: (
    currentOrderNumber: string,
    previousOrderNumber: string,
  ) => void,
  currentOrderNumber: string,
  previousOrderNumber: string,
  copyingOrderLoading: boolean,
  pastlineItems: LineItem[],
  showPopup: boolean,
  setShowPopup: (value: boolean) => void,
};

const mix = (blends, mixId) => {
  return _.filter(blends, function (o) {
    return o.id === mixId;
  }).pop();
};

const RepeatLastPurchase = (props: Props) => {
  const canShowPopup = !!props.order && props.showPopup;
  const { getPastOrder } = props;
  const orders = props.orders ? props.orders : [];
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [lastOrderNumber, setLastOrderNumber] = useState(null);
  const [lastOrderItems, setLastOrderItems] = useState(null);

  const handleCopyItemsToCart = async () => {
    await props.copyingOrder(props.currentOrderNumber, lastOrderNumber);
    logUserTappedRepeatLastPurchase(props.currentOrderNumber, lastOrderItems);
    props.setShowPopup(false);
  };

  const handleToggleConfirmationModal = () => {
    setIsConfirmationModalOpen(prevValue => !prevValue);
  };

  const handleModalConfirm = async () => {
    handleToggleConfirmationModal();
    props.setShowPopup(false);
    await props.copyingOrder(props.currentOrderNumber, lastOrderNumber);
    logUserTappedRepeatLastPurchase(props.currentOrderNumber, lastOrderItems);
    props.history.push('/carrinho');
  };

  useEffect(() => {
    if (orders && orders.length > 0) {
      const latestOrderNumber = orders[0].number;
      const latestOrderItems = orders[0].lineItems;
      setLastOrderItems(latestOrderItems);
      setLastOrderNumber(latestOrderNumber);
      getPastOrder(latestOrderNumber);
    } else {
      console.warn('Orders array is empty');
    }
    //eslint-disable-next-line
  }, [orders.length]);

  if (!canShowPopup) return null;

  return (
    <div className="repeat-last-purchase-popup">
      <div className="repeat-last-purchase-popup-header">
        <h2>Gostaria de refazer sua ultima compra?</h2>
        <button
          className="repeat-last-purchase-close-button"
          onClick={() => props.setShowPopup(false)}
        >
          <img src={closeIcon} alt="repeat-last-purchase-closeIcon"></img>
        </button>
      </div>
      <div className="repeat-last-purchase-main">
        {props.prevOrderItems.map((item, i) => (
          <div className="repeat-last-purchase-container" key={i}>
            <div className="repeat-last-purchase-subcontainer">
              <ItemListImage variant={item.variant} />
              <div className="repeat-last-purchase-content">
                <div className="repeat-last-purchase-content-subcontainer">
                  <p>{item.variant.manufacturer}</p>
                  <Link to={'/produtos/' + item.variant.slug}>
                    <h3>
                      {item.variant.name}
                      <span className="repeat-last-purchase-mix-name">
                        {mix(orders, item.mixId)
                          ? ` - ${mix(orders, item.mixId).name}`
                          : ''}
                      </span>
                    </h3>
                  </Link>
                </div>
                <div className="repeat-last-purchase-content-estimated">
                  <h3>
                    {quantityConverter(
                      item.quantity,
                      item.variant.unitForQuantity,
                    )}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <ConfirmationModal
        text={`Itens do pedido anterior serão adicionados ao carrinho!`}
        onConfirm={handleModalConfirm}
        onClose={handleToggleConfirmationModal}
        isOpen={isConfirmationModalOpen}
      />
      <div className="repeat-last-purchase-buttons-container">
        <Button
          text="Adicionar ao carrinho"
          icon=""
          classIcon=""
          buttonType="repeat-last-purchase-add-to-cart-button"
          loading={props.copyingOrderLoading}
          disabled={props.copyingOrderLoading}
          onClick={handleCopyItemsToCart}
        />

        <Button
          text="Refazer compra"
          icon=""
          classIcon=""
          buttonType="repeat-last-purchase-repeat-last-purchase-button"
          loading={props.copyingOrderLoading}
          disabled={props.copyingOrderLoading}
          onClick={() => {
            if (props.currentOrderNumber) handleToggleConfirmationModal();
          }}
        />
      </div>
    </div>
  );
};

export default withRouter(RepeatLastPurchase);
