// @flow
import React, { useEffect } from 'react';
// Components
import OptionValue from '../components/OptionValue';
// Redux related
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';
// Actions and selectors
import * as actions from '../actions';
import * as selectors from '../selectors';
// Styles
import './OptionValueList.css';
// Types
import type BarbecueOptionValuesType from '../types';

type Props = {
  barbecueOptionValues: BarbecueOptionValuesType,
  fetchOptionValues: () => void,
  selectedOptionValue: number,
  setSelectedOptionValue: () => void,
  handleOptionValueClick: () => void,
};
const OptionValueList = ({
  barbecueOptionValues,
  fetchOptionValues,
  isLoading,
  // LocalProps
  handleOptionValueClick,
  setSelectedOptionValue,
  selectedOptionValue,
}: Props) => {
  useEffect(() => {
    fetchOptionValues();
  }, []);

  useEffect(() => {
    if (barbecueOptionValues.length !== 0 && selectedOptionValue === null)
      setSelectedOptionValue(barbecueOptionValues[0].id);
  }, [barbecueOptionValues]);

  if (isLoading) {
    return (
      <div className="accompaniment-list-loading">
        <i
          className="fa fa-spinner fa-pulse fa-4x loading-icon"
          aria-hidden="true"
        />
      </div>
    );
  }

  return (
    <div className="cut-type-container">
      {barbecueOptionValues.map(optionValue => (
        <OptionValue
          key={optionValue.id}
          optionValue={optionValue}
          handleOptionValueClick={handleOptionValueClick}
          isSelected={selectedOptionValue === optionValue.id}
        />
      ))}
    </div>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    barbecueOptionValues: selectors.barbecueOptionValuesSelector(state),
    isLoading: selectors.optionValuesLoadingSelector(state),
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchOptionValues: () => {
      dispatch(actions.fetchOptionValues());
    },
  };
};
const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default connector(OptionValueList);
