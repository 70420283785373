// @flow
import React from 'react';
import { connect } from 'react-redux';
// Selectors
import {
  editableInformationSelector,
  validatingUserLoadingSelector,
  editingUserInformationLoadingSelector,
} from '../selectors';

import { updateInformation } from '../actions';

// Components
import MyAccountPageContainer from '../components/myAccount/MyAccountPageContainer';
import MyAccountInformation from '../components/myAccount/MyAccountInformation';
// Styles
import './MyAccountInformationPage.css';

// Types

type Props = {
  information: {
    firstName: string,
    lastName: string,
    cpf: string,
    email: string,
  },
  loading: boolean,
  editingLoading: boolean,
  onSubmitUpdate: (
    firstName: string,
    lastName: string,
    cpf: string,
    email: string,
  ) => void,
};

const MyAccountInformationPage = (props: Props) => {
  return (
    <MyAccountPageContainer>
      <MyAccountInformation
        information={props.information}
        loading={props.loading}
        editingLoading={props.editingLoading}
        onSubmitUpdate={props.onSubmitUpdate}
      />
    </MyAccountPageContainer>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    information: editableInformationSelector(state),
    loading: validatingUserLoadingSelector(state),
    editingLoading: editingUserInformationLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    onSubmitUpdate: (firstName, lastName, cpf, email) => {
      dispatch(updateInformation(firstName, lastName, cpf, email));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(MyAccountInformationPage);
