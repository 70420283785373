// @flow
import React, { Component } from 'react';
// Redux
import { connect } from 'react-redux';
// Selectors
import { userWishlistSelector, wishlistLoadingSelector } from '../selectors';
// Actions
import { getWishlist } from '../actions';
// Types
import type { Dispatch } from '../../types';
import type { ProductList } from '../types';

type OwnProps = {
  wishlist: ProductList,
  wishlistLoading: boolean,
};

function userWishlistConnector(WrappedComponent: ReactClass<any>) {
  const mapStateToProps = (state, ownProps) => {
    return {
      wishlist: userWishlistSelector(state),
      wishlistLoading: wishlistLoadingSelector(state),
    };
  };

  const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
    return {
      getWishlist: () => {
        dispatch(getWishlist());
      },
    };
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class userWishlistConnector extends Component {
      props: OwnProps;
      render() {
        return (
          <WrappedComponent
            wishlist={this.props.wishlist}
            wishlistLoading={this.props.wishlistLoading}
          />
        );
      }
    },
  );
}

export default userWishlistConnector;
