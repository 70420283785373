import React from "react";
// Components
import ReferralInvitedUserItem from "../containers/ReferralInvitedUserItem";

//Style
import "./ReferralInvitedUsersList.css";

const ReferralInvitedUsersList = ({
    invitedUsers,
}) => {
    return (
        <>
            <div className="invited-user-list-main">
                {!invitedUsers || invitedUsers.length === 0 ? (
                    ''
                ) : (
                    <div className="row">
                        {invitedUsers.map((invitedUser, index) => (
                            <ReferralInvitedUserItem
                                key={invitedUser.id}
                                index={index}
                                invitedUserId={invitedUser.id}
                            />
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};

export default ReferralInvitedUsersList;
