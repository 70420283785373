// @flow
import React, { Component } from 'react';

import './PageLoader.css';
import ChickenStamp from '../images/ChickenInSpace.png';

type Props = {
  text?: string,
};

class PageLoader extends Component {
  props: Props;
  render() {
    return (
      <div className="page-loader">
        <div className="page-sub-loader">
          <div className="page-loader-container">
            <img
              className="page-loader-animate page-loader-image"
              src={ChickenStamp}
              alt=""
            />
          </div>
          <h3 className="page-loader-text">
            {this.props.text ? this.props.text : 'Carregando...'}
          </h3>
        </div>
      </div>
    );
  }
}

export default PageLoader;
