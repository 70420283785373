// @flow
import React, { useEffect } from 'react';
// Components
import LandingIncreasingUserCounter from '../components/LandingIncreasingUserCounter';
import LandingFAQ from '../components/LandingFAQ';
import LandingProductList from '../components/LandingProductList';
// Data
import barbecueData from '../data/barbecueData';
// Connectors
import { displayVariantsByPageAndSectionConnector } from '../../products';
// Styles
import './Barbecue.css';
// Assets
import CheckIcon from '../images/check-icon.svg';
import CalculatorIcon from '../images/calculatorIcon.svg';
import { Link } from 'react-router-dom';

type Props = {
  findDisplayVariantsByPageAndSection: (
    page: string,
    section: string,
  ) => Product[],
  productByPageAndSection: (page: string, section: string) => Product[],
};

const Barbecue = ({
  productByPageAndSection,
  findDisplayVariantsByPageAndSection,
}: Props) => {
  useEffect(() => {
    barbecueData.productSections.forEach(p => {
      findDisplayVariantsByPageAndSection(p.variant);
    });

    //eslint-disable-next-line
  }, []);

  const CommentCard = ({ item: { img, title, text, author } }) => (
    <div className="barbecue-feedback-section-comment-card">
      <div className="barbecue-feedback-section-comment-card-stars">
        {[...Array(5)].map((s, i) => (
          <i key={i} className="fa fa-sharp fa-solid fa-star" />
        ))}
      </div>
      <div className="barbecue-feedback-section-comment-card-text">{text}</div>
      <div className="barbecue-feedback-section-comment-card-author">
        <img
          className="barbecue-feedback-section-comment-card-author-img"
          src={author.img}
          alt="author-img"
        />
        <p className="barbecue-feedback-section-comment-card-author-user">
          {author.name}
          <sub>{author.status}</sub>
        </p>
      </div>
    </div>
  );

  return (
    <div>
      <LandingIncreasingUserCounter />
      <section className="barbecue-banner-section">
        <div className="barbecue-banner-section-wrapper">
          <div className="barbecue-banner-section-text">
            VOCÊ PODE
            <small>
              ESCOLHER AS CARNES <br /> DA MELHOR QUALIDADE PARA O SEU
            </small>
            CHURRASCO
            <span>AGORA.</span>
          </div>
          <div className="barbecue-banner-section-benefits">
            <div className="barbecue-banner-section-benefits-item">
              <img
                src={CheckIcon}
                alt="check-icon"
                className="barbecue-banner-section-benefits-item-icon"
              />
              <p className="barbecue-banner-section-benefits-item-text">
                SEM SAIR DE CASA{' '}
              </p>
            </div>
            <div className="barbecue-banner-section-benefits-item">
              <img
                src={CheckIcon}
                alt="check-icon"
                className="barbecue-banner-section-benefits-item-icon"
              />
              <p className="barbecue-banner-section-benefits-item-text">
                SEM PEGAR FILA
              </p>
            </div>
          </div>
          <p className="barbecue-banner-section-subtitle">
            RECEBA AS CARNES LIMPAS, NA SUA CASA, NO SEU TEMPO
          </p>
          <Link to="/" className="barbecue-button">
            QUERO COMPRAR AGORA!
          </Link>
        </div>
      </section>
      <section className="barbecue-calculator-section">
        <div className="barbecue-calculator-section-info">
          <div className="barbecue-calculator-section-info-text">
            <p>
              <small>NÃO SABE QUANTOS</small> <big> QUILOS PRECISA</big>
            </p>
            <p>
              <span>PARA O SEU</span>
              CHURRASCO?
            </p>
          </div>
          <img
            src={CalculatorIcon}
            className="barbecue-calculator-section-info-icon"
            alt="calculator-icon"
          />
          <Link
            to="/bigchurrasco"
            className="barbecue-calculator-section-info-button"
          >
            ABRIR CALCULADORA <span> DE CHURRASCO</span>
          </Link>
        </div>
      </section>
      <section className="barbecue-products-section">
        <div className="barbecue-products-section-header">
          <h1 className="barbecue-products-section-header-title">
            Prefere montar por conta própria?
          </h1>
          <p className="barbecue-products-section-header-subtitle">
            Estamos aqui para te ajudar a escolher a carne ideal.
          </p>
        </div>
        <div className="barbecue-products-section-main">
          {barbecueData.productSections.map((item, index) => {
            const sliderProductsVariants = productByPageAndSection(
              item.variant,
              'slider',
            );
            return (
              <div key={index} className="barbecue-products-section-info">
                <p className="barbecue-products-section-info-paragraph">
                  {item.paragraph}
                </p>
                <h1 className="barbecue-products-section-info-title">
                  {item.title}
                </h1>
                <p className="barbecue-products-section-info-description">
                  {item.description}
                </p>
                <div className="barbecue-products-section-info-container">
                  <LandingProductList
                    sliderProductsVariants={sliderProductsVariants}
                    currentLanding="barbecue"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <section className="barbecue-feedback-section">
        <div className="barbecue-feedback-section-info">
          <h1 className="barbecue-feedback-section-info-title">
            Ainda na dúvida?
          </h1>
          <p className="barbecue-feedback-section-info-subtitle">
            Veja o que nossos clientes têm a dizer sobre nosso serviço:
          </p>
        </div>
        <div className="barbecue-feedback-section-comment">
          {barbecueData.comments.map((item, index) => (
            <CommentCard key={index} item={item} />
          ))}
        </div>
        <Link to="/" className="barbecue-button">
          QUERO COMPRAR AGORA!
        </Link>
        <div className="barbecue-feedback-section-info">
          <p className="barbecue-feedback-section-info-text">
            Quer comprar no
            <big>BigDelivery</big>
            mas ainda tem dúvidas?
          </p>
          <p className="barbecue-feedback-section-info-paragraph">
            Confira nosso FAQ abaixo:
          </p>
        </div>
        <div className="barbecue-feedback-section-faq">
          <LandingFAQ
            faqQuestions={barbecueData.faqQuestions}
            currentLanding="barbecue"
          />
        </div>
        <div className="barbecue-feedback-section-invite">
          <div className="barbecue-feedback-section-invite-text">
            <h1>
              Chega de <br />
              <small>perder tempo!</small>
            </h1>
            <p>
              <span>
                Compre agora <br />
                <span>
                  no BigDelivery <br />e tenha{' '}
                </span>
              </span>
              <br />
              As melhores carnes
              <br />
              <span>para o seu</span> <big>churrasco.</big>
            </p>
          </div>
        </div>

        <Link to="/" className="barbecue-button">
          QUERO COMPRAR AGORA!
        </Link>
      </section>
      {/*
       */}
    </div>
  );
};
export default displayVariantsByPageAndSectionConnector(Barbecue);
