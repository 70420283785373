// @flow
import React, { useEffect } from 'react';
// Components
import LandingIncreasingUserCounter from '../components/LandingIncreasingUserCounter';
import LandingFAQ from '../components/LandingFAQ';
import LandingProductList from '../components/LandingProductList';
// Data
import homemadeData from '../data/homemadeData';
// Connectors
import { displayVariantsByPageAndSectionConnector } from '../../products';
// Images
import meatBigDelivery from '../images/meatBigDelivery.jpg';
import othersMeat from '../images/othersMeat.jpg';
import versus from '../images/versus.svg';
import logoVertical from '../images/logoVertical.svg';
import clock from '../images/clock.png';
// Styles
import './Homemade.css';

type Props = {
  findDisplayVariantsByPageAndSection: (
    page: string,
    section: string,
  ) => Product[],
  productByPageAndSection: (page: string, section: string) => Product[],
};

const Homemade = ({
  productByPageAndSection,
  findDisplayVariantsByPageAndSection,
}: Props) => {
  const sliderProductsVariants = productByPageAndSection('homemade', 'slider');
  const productsListVariants = productByPageAndSection(
    'homemade',
    'product_list',
  );
  useEffect(() => {
    findDisplayVariantsByPageAndSection('homemade');
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <LandingIncreasingUserCounter />
      <section className="homemade-banner homemade-gap">
        <div className="homemade-banner-content">
          <img
            src={clock}
            alt="Relógio"
            className="homemade-banner-content-img"
          />
          <h1 className="homemade-banner-content-text">
            Economize tempo na sua rotina diária
          </h1>
        </div>
      </section>
      <section className="homemade-section homemade-gap">
        <div className="homemade-section-text-column">
          <span className="homemade-section-text">Com</span>
          <span className="homemade-section-text-alt">BigDelivery</span>
          <span className="homemade-section-text">você tem a</span>
          <span className="homemade-section-text-alt homemade-section-text-yellow">
            Melhor
          </span>
          <span className="homemade-section-text-alt homemade-section-text-yellow">
            Escolha{' '}
          </span>
          <span className="homemade-section-text">pelo</span>
          <span className="homemade-section-text-alt homemade-section-text-yellow">
            Melhor
          </span>
          <span className="homemade-section-text-alt homemade-section-text-yellow">
            Preço!
          </span>
        </div>
      </section>

      <section className="homemade-section-diff homemade-gap">
        <h2 className="homemade-section-diff-title">Compare</h2>
        <i class="fa fa-chevron-down"></i>
        <p className="homemade-section-diff-text">
          Carne limpa e selecionada com a praticidade que só o BigDelivery tem.
        </p>
        <div className="homemade-section-diff-row">
          <div className="homemade-section-diff-item">
            <img
              src={meatBigDelivery}
              alt="Carne do BigDelivery"
              className="homemade-section-diff-item-img"
            />
            <img
              src={logoVertical}
              className="homemade-section-diff-item-text"
              alt="Logo BigDelivery"
            />
          </div>
          <img
            src={versus}
            alt="Versus"
            className="homemade-section-diff-item-img-versus"
          />
          <div className="homemade-section-diff-item">
            <img
              src={othersMeat}
              alt="Outros açougues"
              className="homemade-section-diff-item-img"
            />
            <span className="homemade-section-diff-item-text">
              Outros açougues
            </span>
          </div>
        </div>
      </section>
      <section className="homemade-section-column homemade-gap">
        <h2 className="homemade-section-column-title">
          Tudo para facilitar o seu dia a dia
        </h2>
        {homemadeData.homemadeColumnIcons.map((homemade, index) => {
          return (
            <div key={index} className="homemade-section-column-item">
              <img
                src={homemade.img}
                alt={homemade.alt}
                className="homemade-section-column-item-img"
              />
              <p className="homemade-section-column-item-text">
                {homemade.text}
              </p>
            </div>
          );
        })}
      </section>
      <LandingProductList
        sliderProductsVariants={sliderProductsVariants}
        productsVariants={productsListVariants}
        title="Faça a sua escolha"
        currentLanding="homemade"
      />
      <LandingFAQ
        faqQuestions={homemadeData.faqQuestions}
        currentLanding="homemade"
      />
    </div>
  );
};
export default displayVariantsByPageAndSectionConnector(Homemade);
