//@flow
import React from 'react';
//Styles
import './CookingRecommedationBox.css';
//Types
import type { CookingRecommendation } from '../../types';

type Props = {
  cookingMethods: CookingRecommendation[],
};

const CookingRecommedationBox = ({ cookingMethods }: Props) => {
  return (
    cookingMethods &&
    cookingMethods.length > 0 && (
      <div className="cooking-recomedation-box-container">
        <h4 className="cooking-recomedation-box-title">Ideal Para</h4>
        <div className="cooking-recomedation-box-wrapper">
          {cookingMethods.map((item, i) => (
            <div key={i} className="cooking-recomedation-box-item">
              <img src={item.smallUrl} alt={item.name} />
              <p>{item.name}</p>
            </div>
          ))}
        </div>
      </div>
    )
  );
};

export default CookingRecommedationBox;
