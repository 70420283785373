// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Actions
import { findMultipleProducts } from '../actions';
import { multipleProductsLoadingSelector } from '../selectors';
// Types
import type { Dispatch } from '../../types';

type OwnProps = {
  findMultipleProductsByIds: (productsIds: Array<number>) => void,
  fetchingProductsLoading: boolean,
};

const findMultipleProductsConnector = (WrappedComponent: ReactClass<any>) => {
  const mapStateToProps = (state, ownProps) => {
    return {
      fetchingProductsLoading: multipleProductsLoadingSelector(state),
    };
  };

  const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
    return {
      findMultipleProductsByIds: (productsIds: Array<number>) => {
        dispatch(findMultipleProducts(productsIds));
      },
    };
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class currentOrderNumberConnector extends Component {
      props: OwnProps;
      render() {
        return (
          <WrappedComponent
            {...this.props}
            findMultipleProducts={this.props.findMultipleProductsByIds}
            fetchingProductsLoading={this.props.fetchingProductsLoading}
          />
        );
      }
    },
  );
};

export default findMultipleProductsConnector;
