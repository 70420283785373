// @flow
import React from 'react';
// Styles
import './FavoriteButton.css';
// Assets
import heart from '../images/heart.svg';
import heartOutline from '../images/heartOutline.svg';

type Props = {
  isFavorite: boolean,
  handleClick: () => void,
};

const FavoriteButton = ({ isFavorite = false, handleClick }: Props) => {
  return (
    <button
      className={`heart-button ${isFavorite ? 'is-favorite' : ''}`}
      onClick={handleClick}
    >
      <span role="img" aria-label="heart">
        {isFavorite ? (
          <img src={heart} alt="coração favoritado" />
        ) : (
          <img src={heartOutline} alt="coração não favoritado" />
        )}
      </span>
    </button>
  );
};

export default FavoriteButton;
