// @flow
import React, { useEffect, useState } from 'react';
// Components
import VisibilitySensor from 'react-visibility-sensor';
import { Link } from 'react-router-dom';
import { ProgressiveImageLoader } from '../../../common';
// Analytics
import * as Analytics from '../../../common/analytics';
// Helpers
import {
  scaleConverter,
  priceConverter,
  quantityConverter,
} from '../../../common';
// Styles
import './ResumeItem.css';

type Props = {
  productImage: ?ProductImage,
  productName: string,
  productManufacturer: string,
  productListPrice: number,
  productPrice: number,
  productUnit: string,
  productSlug: string,
  productId: number,
  defaultQuantity: number,
  incrementQuantity: number,
  inStock: boolean,
  discontinued: boolean,
  quantity: number,
};

const ResumeItem = ({
  productSlug,
  productImage,
  productName,
  productPrice,
  productListPrice,
  productManufacturer,
  productUnit,
  productId,
  parentProductId,
  productTotalOnHand,
  defaultQuantity,
  inStock,
  discontinued,
  incrementQuantity,
  analyticsViewSource,
  quantity,
}: Props) => {
  const [isProductVisible, setIsProductVisible] = useState(false);
  const price = scaleConverter(productPrice, productUnit);
  const listPrice = scaleConverter(productListPrice);
  const productOnPromotion = listPrice > price;
  const productDiscount = productOnPromotion
    ? ((listPrice - price) / listPrice) * 100
    : null;

  useEffect(() => {
    if (isProductVisible) {
      Analytics.logProductImpression(
        {
          variantId: productId,
          productId: parentProductId,
          name: productName,
          price: productPrice,
          manufacturer: productManufacturer,
          unitForQuantity: productUnit,
          totalOnHand: productTotalOnHand,
          listPrice: productListPrice,
          inStock: inStock,
          discontinued: discontinued,
          images: productImage ? [productImage] : [],
          incrementQuantity: incrementQuantity,
          defaultQuantity: defaultQuantity,
        },
        window.location.pathname,
        analyticsViewSource,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProductVisible]);

  return (
    <VisibilitySensor
      onChange={isVisible => {
        setIsProductVisible(isVisible);
      }}
    >
      <div className="resume-item-wrapper">
        <section className="resume-item-image-section">
          <div className="resume-item-link">
            {productOnPromotion && productDiscount >= 5 && (
              <div className="resume-item-image-discount">
                <p>{Math.round(productDiscount)}%</p>
                <span>OFF</span>
              </div>
            )}
            <ProgressiveImageLoader
              selectedImage={productImage}
              imageToShow="product"
            />
          </div>
        </section>
        <section className="resume-item-info-section">
          <p className="resume-item-variant">{productManufacturer}</p>
          <Link className="resume-item-link" to={`/produtos/${productSlug}/`}>
            <h3 title={productName} className="resume-item-title">
              {productName}
            </h3>
          </Link>
          <div className="resume-item-info-row">
            <span>
              Preço/
              {productUnit === 'g' || productUnit === 'kg' ? 'Kg' : 'un'}
            </span>
            <span>{priceConverter(price)}</span>
          </div>{' '}
          <div className="resume-item-info-row">
            <span className="resume-item-info-row-quantity">
              {quantityConverter(quantity, productUnit)}
            </span>
          </div>
        </section>
      </div>
    </VisibilitySensor>
  );
};

export default ResumeItem;
