// @flow
import { createSelector } from 'reselect';
import type { StoreState } from '../types';

export const thisStateSelector = (state: any): StoreState => {
  return state.departments;
};

export const currentDepartmentSelector = createSelector(
  thisStateSelector,
  state => state.departments[state.current],
);

export const indexDepartmentsSelector = createSelector(
  thisStateSelector,
  state => state.index.map(id => state.departments[id]),
);

// Loading State related
export const currentDepartmentLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.current,
);

export const currentDepartmentsIndexLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.index,
);
