// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Selectors
import {
  validatingUserLoadingSelector,
  updatePasswordError,
  updatingUserPasswordLoadingSelector,
} from '../selectors';
// Actions
import { updatePassword } from '../actions';
// Components
import MyAccountPassword from '../components/myAccount/MyAccountPassword';
// Styles
import './MyAccountPasswordPage.css';

// Types

type Props = {
  loading: boolean,
  errors: boolean,
  onSubmitUpdate: (
    oldPassword: string,
    newPassword: string,
    newPasswordConfirmation: string,
  ) => void,
  onClose: () => void,
};

class MyAccountPasswordPage extends Component {
  props: Props;
  render() {
    return (
      <MyAccountPassword
        error={this.props.error}
        onSubmit={this.props.onSubmitUpdate}
        updatingLoading={this.props.updatingLoading}
        onClose={this.props.onClose}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    loading: validatingUserLoadingSelector(state),
    error: updatePasswordError(state),
    updatingLoading: updatingUserPasswordLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    onSubmitUpdate: (oldPassword, newPassword, newPasswordConfirmation) => {
      dispatch(
        updatePassword(oldPassword, newPassword, newPasswordConfirmation),
      );
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(MyAccountPasswordPage);
