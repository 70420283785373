// @flow
import React from 'react';
import { connect } from 'react-redux';
// Components
import LoggedInOptions from '../components/LoggedInOptions';
import LoggedOutOptions from '../components/LoggedOutOptions';
// Types
import type { Connector } from 'react-redux';
// Selectors
import { firstNameSelector, loggedInSelector } from '../selectors';

type Props = {
  loggedIn: boolean,
  firstName: string,
};

const CurrentUserIndicator = ({ loggedIn, firstName }: Props) => {
  if (loggedIn) {
    return <LoggedInOptions name={firstName} />;
  }

  return <LoggedOutOptions />;
};

const mapStateToProps = (state, ownProps) => {
  return {
    loggedIn: loggedInSelector(state),
    firstName: firstNameSelector(state),
  };
};

const connector: Connector<{}, Props> = connect(mapStateToProps);
export default connector(CurrentUserIndicator);
