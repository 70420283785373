// @flow
import React, { useEffect } from 'react';
// Components
import LandingFAQ from '../components/LandingFAQ';
import LandingIncreasingUserCounter from '../components/LandingIncreasingUserCounter';
import LandingProductList from '../components/LandingProductList';
// Styles
import './BigCustomer.css';
// Data
import bigCustomerData from '../data/bigCustomerData';
// Connectors
import { displayVariantsByPageAndSectionConnector } from '../../products';
// Types
import { Product } from '../../products';
//Images
import bigCustomerSubtitleBanner from '../images/bigCustomerSubtitleBanner.png';
import logoVertical from '../images/logoVertical.svg';
import meatBigDelivery from '../images/meatBigDelivery.jpg';
import othersMeat from '../images/othersMeat.jpg';
import versus from '../images/versus.svg';

type Props = {
  findDisplayVariantsByPageAndSection: (
    page: string,
    section: string,
  ) => Product[],
  productByPageAndSection: (page: string, section: string) => Product[],
};

const BigCustomer = ({
  productByPageAndSection,
  findDisplayVariantsByPageAndSection,
}: Props) => {
  const sliderProductsVariants = productByPageAndSection(
    'big_customer',
    'slider',
  );
  const productsListVariants = productByPageAndSection(
    'big_customer',
    'product_list',
  );
  useEffect(() => {
    findDisplayVariantsByPageAndSection('big_customer');
    //eslint-disable-next-line
  }, []);

  return (
    <div className="big-customer-main">
      <section className="big-customer-banner-container">
        <div className="big-customer-banner">
          <h3 className="big-customer-banner-text">
            Carne com a qualidade que você já conhece,
          </h3>
          <img
            className="big-customer-banner-image"
            src={bigCustomerSubtitleBanner}
            alt="a um clique de distância."
          />
        </div>
      </section>
      <div className="big-customer-counter-container">
        <LandingIncreasingUserCounter />
      </div>
      <section className="big-customer-enjoy-container  big-customer-gap">
        <p className="big-customer-enjoy-description">
          Bigdelivery está cada vez mais perto de você! Aqui é possível fazer
          seus pedidos com a velocidade e praticidade que já é nossa marca
          registrada.
        </p>
        <h3 className="big-customer-enjoy-subtitle">Aproveite!</h3>
      </section>
      <section className="big-customer-diff-container big-customer-gap">
        <h2 className="big-customer-diff-title">Compare</h2>
        <i className="fa fa-chevron-down"></i>
        <p className="big-customer-diff-text">
          Carne limpa e selecionada com a praticidade que só o BigDelivery tem.
        </p>
        <div className="big-customer-diff-row">
          <div className="big-customer-diff-item">
            <img
              src={meatBigDelivery}
              alt="Carne do BigDelivery"
              className="big-customer-diff-item-img"
            />
            <img
              src={logoVertical}
              className="big-customer-diff-item-text"
              alt="Logo BigDelivery"
            />
          </div>
          <img
            src={versus}
            alt="Versus"
            className="big-customer-diff-item-img-versus"
          />
          <div className="big-customer-diff-item">
            <img
              src={othersMeat}
              alt="Outros açougues"
              className="big-customer-diff-item-img"
            />
            <span className="big-customer-diff-item-text">Outros açougues</span>
          </div>
        </div>
      </section>

      <section className="big-customer-comment-container big-customer-gap">
        <div className="big-customer-comment-wrapper">
          <img
            src={bigCustomerData.comment.img}
            alt="Comentário"
            className="big-customer-comment-img"
          />
          <h4 className="big-customer-comment-title">
            {bigCustomerData.comment.title}
          </h4>
          <p className="big-customer-comment-text">
            {bigCustomerData.comment.text}
          </p>
          <span className="big-customer-comment-author">
            {bigCustomerData.comment.author}
          </span>
        </div>
      </section>
      <section className="big-customer-icon-container big-customer-gap">
        {bigCustomerData.columnIcons.map(item => (
          <div className="big-customer-icon-item" key={item.id}>
            <img
              src={item.img}
              alt={item.alt}
              className="big-customer-icon-item-img"
            />
            <p className="big-customer-icon-item-text">{item.text}</p>
          </div>
        ))}
      </section>
      <LandingProductList
        sliderProductsVariants={sliderProductsVariants}
        productsVariants={productsListVariants}
        currentLanding="big_customer"
        title="Os Mais Pedidos:"
      />
      <LandingFAQ
        faqQuestions={bigCustomerData.faqQuestions}
        currentLanding="big_customer"
      />
    </div>
  );
};
export default displayVariantsByPageAndSectionConnector(BigCustomer);
