//@flow
import React from 'react';
// Types
import { ShippingRate } from '../../types/ShippingRate';
//Utils
import moment from 'moment';
//Styles
import './DeliveryPickerOption.css';

const months = [
  'JAN',
  'FEV',
  'MAR',
  'ABR',
  'MAI',
  'JUN',
  'JUL',
  'AGO',
  'SET',
  'OUT',
  'NOV',
  'DEZ',
];

interface DeliveryPickerOptionProps {
  shippingRate: ShippingRate;
  itemPosition: number;
  selected: boolean;
}

interface DateProps {
  deliveryWindow: Date;
  selected: boolean;
}

interface DeliveriesRemainingProps {
  count: number;
}

const DeliveryPickerOption = ({
  shippingRate,
  itemPosition,
  selected,
}: DeliveryPickerOptionProps) => {
  const deliveryStart = new Date(shippingRate.deliveryWindowStart);
  const deliveryEnd = new Date(shippingRate.deliveryWindowEnd);

  const getTimeWindow = (start: Date, end: Date) => {
    const start_time_text = moment(start).utcOffset('-0300').format('HH:mm');
    const end_time_text = moment(end).utcOffset('-0300').format('HH:mm');
    return `${start_time_text} às ${end_time_text}`;
  };

  const DateBox = ({ deliveryWindow }: DateProps) => {
    if (!deliveryWindow) return null;
    const month = months[deliveryWindow.getMonth()];
    const day = deliveryWindow.getDate();
    let textStyles = selected ? 'delivery-picker-option-selected-text' : '';
    return (
      <>
        <h6 className={`delivery-picker-option-text ${textStyles} `}>
          {month}
        </h6>
        <h6 className={`delivery-picker-option-text ${textStyles} `}>{day}</h6>
      </>
    );
  };

  const DeliveriesRemaining = ({ count }: DeliveriesRemainingProps) => {
    if (count === null) return null;
    let deliveriesRemainingText = null;
    if (count === 0) {
      deliveriesRemainingText = 'Lotado';
    } else if (count < 10) {
      deliveriesRemainingText = `${count} Restantes`;
    } else if (count < 20) {
      deliveriesRemainingText = 'Popular';
    }

    return (
      <h6
        className={`delivery-picker-option-text ${
          deliveriesRemainingText === 'Lotado'
            ? 'delivery-picker-option-text-full'
            : ''
        }`}
      >
        {deliveriesRemainingText}
      </h6>
    );
  };

  return (
    <div
      className={`delivery-picker-option-main ${
        selected && 'delivery-picker-option-selected'
      }`}
    >
      <div className="delivery-picker-option-time-container">
        <h6 className="delivery-picker-option-text">
          {getTimeWindow(deliveryStart, deliveryEnd)}
        </h6>
      </div>
      <div className="delivery-picker-option-date-container">
        <DateBox deliveryWindow={new Date(shippingRate.deliveryWindowStart)} />
      </div>

      <div className="delivery-picker-option-remaining-container">
        <DeliveriesRemaining count={shippingRate.deliveriesRemaining} />
      </div>
      <div className="delivery-picker-option-delivery-info-container">
        <h6 className="delivery-picker-option-text">{shippingRate.name}</h6>
        <h6
          className={`delivery-picker-option-text ${
            !parseFloat(shippingRate.cost) && 'delivery-picker-free'
          } `}
        >
          {parseFloat(shippingRate.cost)
            ? Number(shippingRate.cost).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
            : 'Grátis!'}
        </h6>
      </div>
    </div>
  );
};

export default DeliveryPickerOption;
