// @flow
import React, { useEffect } from 'react';
// Components
import LandingFAQ from '../components/LandingFAQ';
import LandingIncreasingUserCounter from '../components/LandingIncreasingUserCounter';
import LandingProductList from '../components/LandingProductList';
// Styles
import './Healthy.css';
// Data
import healthyData from '../data/healthyData';
// Connectors
import { displayVariantsByPageAndSectionConnector } from '../../products';
// Types
import { Product } from '../../products';

type Props = {
  findDisplayVariantsByPageAndSection: (
    page: string,
    section: string,
  ) => Product[],
  productByPageAndSection: (page: string, section: string) => Product[],
};

const Healthy = ({
  productByPageAndSection,
  findDisplayVariantsByPageAndSection,
}: Props) => {
  const sliderProductsVariants = productByPageAndSection('healthy', 'slider');
  const productsListVariants = productByPageAndSection(
    'healthy',
    'product_list',
  );
  useEffect(() => {
    findDisplayVariantsByPageAndSection('healthy');
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <LandingIncreasingUserCounter />
      <div className="healthy-banner healthy-gap">
        <p className="healthy-banner-text">
          Tenha uma <span>alimentação saudável</span> com o bigdelivery
        </p>
      </div>
      <section className="healthy-flavor healthy-gap">
        <h3 className="healthy-flavor-title">
          Mais sabor nutrição e saúde no seu dia a dia!
        </h3>
        <p className="healthy-flavor-text">
          De tudo um pouco pensando no seu bem-estar:
        </p>
        <div className="healthy-flavor-column">
          {healthyData.flavorItems.map(flavorItem => {
            return (
              <div
                className="healthy-flavor-column-item"
                key={flavorItem.title}
              >
                <img
                  className="healthy-flavor-column-item-img"
                  src={flavorItem.img}
                  alt={flavorItem.alt}
                />
                <p className="healthy-flavor-column-item-text">
                  {flavorItem.title}
                </p>
              </div>
            );
          })}
        </div>
      </section>

      <section className="healthy-banner-alt healthy-gap ">
        <p className="healthy-banner-text-alt">
          Quem deseja implementar<span> uma dieta suadável</span>, conta com o
          bigdelivery todos os dias!
        </p>
      </section>

      <section className="healthy-quality-and-pratice healthy-gap">
        <div className="healthy-quality-and-pratice-item">
          <i class="fa fa-chevron-down"></i>
          <h4 className="healthy-quality-and-pratice-item-title">Qualidade</h4>
          <p className="healthy-quality-and-pratice-item-text">
            Carne fresca, limpa e sem gordura.
          </p>
        </div>
        <div className="healthy-quality-and-pratice-item">
          <i class="fa fa-chevron-down"></i>
          <h4 className="healthy-quality-and-pratice-item-title">
            Praticidade
          </h4>
          <p className="healthy-quality-and-pratice-item-text">
            Porcionamento personalizado e entrega rápida.
          </p>
        </div>
      </section>
      <section className="healthy-care healthy-gap">
        <img
          className="healthy-care-img"
          src={healthyData.healthyCare.img}
          alt={healthyData.healthyCare.alt}
        />
        <h3 className="healthy-care-title">{healthyData.healthyCare.title}</h3>
        <p className="healthy-care-text">{healthyData.healthyCare.text}</p>
      </section>
      <LandingProductList
        sliderProductsVariants={sliderProductsVariants}
        productsVariants={productsListVariants}
        currentLanding="healthy"
      />
      <LandingFAQ
        faqQuestions={healthyData.faqQuestions}
        currentLanding="healthy"
      />
    </div>
  );
};
export default displayVariantsByPageAndSectionConnector(Healthy);
