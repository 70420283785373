// @flow
import React, { useState } from 'react';
// Helpers
// Components
// Images
import fallbackIcon from '../images/fallbackMap.png';
//Types
import type { UserAddress } from '../../user';
//Styles

type Props = {
  address: UserAddress,
  zoom?: number,
  driverLocation?: any,
  // Image parameters
  size: string,
  alt?: string,
  className: ?string,
};

const GoogleStaticMapsImage = ({
  address,
  driverLocation,
  zoom,
  size,
  alt,
  className,
}: Props) => {
  const [failedToLoad, setFailedToLoad] = useState(false);

  // TODO: State and country are hardcoded
  const addressString =
    address.latitude && address.longitude
      ? `${address.latitude},${address.longitude}`
      : encodeURI(
          `${address.addressLine1}, ${address.addressLine2}, ${address.addressLine1}, ${address.neighborhood}, ${address.city}, Bahia, Brazil`,
        );

  const staticMapsURL = `https://maps.googleapis.com/maps/api/staticmap?markers=size:small%7C${addressString}&zoom=${zoom}&size=${size}&maptype=roadmap&scale=2${
    driverLocation
      ? `&markers=anchor:center%7Cicon:https://cdn1.iconfinder.com/data/icons/common-version-3-0/1024/Truck-32.png%7C${driverLocation.latitude},${driverLocation.longitude}`
      : ''
  }
    &key=${process.env.REACT_APP_GCP_API_KEY}`;

  return (
    <img
      className={className || ''}
      src={failedToLoad ? fallbackIcon : staticMapsURL}
      alt={alt || ''}
      onError={() => setFailedToLoad(true)}
    />
  );
};
export default GoogleStaticMapsImage;
