// @flow
import React from 'react';
import { Link } from 'react-router-dom';
// Styles
import './Button.css';

type Props = {
  color: string,
  navigateTo: string,
  icon: string,
  text: string
};

const Button = (props: Props) => {
  return (
    <Link to={props.navigateTo && props.navigateTo || ''}
      onClick={props.onClick}
      onMouseOut={props.onMouseOut}>
      <button className={`button ${props.color}`}>
        {props.icon ? (
          <div className="d-flex button-row">
            <div className="pt-1"><i className={`button-icon ${props.icon}`} aria-hidden="true"></i></div>
            <div>{props.text}</div>
          </div>
        ) : props.text}
      </button>
      {props.children}
    </Link>
  );
};

export default Button;
