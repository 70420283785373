// @flow
import React, { useRef } from 'react';
// Components
import { Link } from 'react-router-dom';
import { CartIndicator } from '../../current-order';
// Styles
import './NavHeader.css';
// Images
import logo from '../images/logoLightHorizontal.svg';

const NavHeader = () => {
  const mobileBtn = useRef(null);
  const navBar = useRef(null);

  const toggleMenu = e => {
    navBar.current.classList.toggle('active');
  };
  return (
    <div className="nav-header">
      <Link to="" className="nav-header-logo-wrapper">
        <img
          alt="Big Delivery Simple Logo"
          src={logo}
          className="nav-header-logo"
        />
      </Link>
      <nav className="nav-header-navbar" ref={navBar}>
        <div
          className="nav-header-btn-mobile"
          ref={mobileBtn}
          onClick={toggleMenu}
        >
          <div className="nav-header-hamburger-bar1"></div>
          <div className="nav-header-hamburger-bar2"></div>
          <div className="nav-header-hamburger-bar3"></div>
        </div>
        <ul className="nav-header-menu">
          <li>
            <Link to="/">Início</Link>
          </li>
          <li>
            <Link to="/sobre">Sobre Nós</Link>
          </li>
          <li>
            <Link to="/contato">Contato</Link>
          </li>
          <li>
            <Link to="/lista-de-desejos">Favoritos</Link>
          </li>
          <li>
            <CartIndicator />
          </li>
        </ul>
      </nav>
    </div>
  );
};
export default NavHeader;
