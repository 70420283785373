// @flow
import { camelizeKeys } from 'humps';
import { normalize } from 'normalizr';
import { authGet, authPut, authPost, authDelete } from '../../api';
// Methods
import { register, login, logout, validateToken } from './session';

// Schemas
import * as schema from './schema';
// Types
import type { Address, ProductList } from '../types';

const User = {
  register: register,
  login: login,
  logout: logout,
  validateToken: validateToken,

  /**
   * Get invited users
   */
  async getInvitedUsers(userId) {
    const response = await authGet(`/v2/users/${userId}/invited_users`);
    const data = await response.json().then(b => camelizeKeys(b));

    const normalized = normalize(data, schema.invitedUsers);
    return normalized;
  },

  /**
   * Get cashbacks
   */
  async getCashbackOrders(userId) {
    const response = await authGet(`/v2/users/${userId}/cashback`);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.cashbacks);
    return normalized;
  },

  /**
   * Update password
   */
  async updatePassword(
    userId: number,
    oldPassword: string,
    newPassword: string,
    newPasswordConfirmation: string,
  ) {
    const body = {
      old_password: oldPassword,
      password: newPassword,
      password_confirmation: newPasswordConfirmation,
    };
    const response = await authPut(`/v2/users/${userId}/password`, body);
    const data = await response.json().then(b => camelizeKeys(b));
    return data;
  },

  /**
   * Update information
   */
  async updateInformation(
    firstName: string,
    lastName: string,
    cpf: string,
    email: string,
  ) {
    // TODO: Add path and finish implementing this API
    const body = {
      first_name: firstName,
      last_name: lastName,
      cpf: cpf,
      email: email,
    };
    const response = await authPut(`/v2/users/me`, body);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.user);
    return normalized;
  },

  /**
   * Get addresses
   */
  async getAddresses() {
    const response = await authGet(`/v2/addresses`);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.addresses);
    return normalized;
  },

  /**
   * Create new address
   */
  async newAddress(address: Address) {
    const body = {
      firstname: address.receiverName,
      address1: address.addressLine1,
      address2: address.addressLine2,
      city: address.city,
      state: 'Bahia',
      zipcode: address.zipcode,
      phone: address.telephone,
      neighborhood: address.neighborhood,
      additional_information: address.additionalInformation,
      reference: address.reference,
      cnpj: address.cnpj,
      ie_company: address.ieCompany,
      company: address.company,
      residence_number: address.residenceNumber,
      residence_type: address.residenceType,
      residence_data: address.residenceData,
    };
    const response = await authPost(`/v2/addresses`, body);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.address);
    return normalized;
  },
  /**
   * Update address
   */
  async updateAddress(address: Address) {
    const body = {
      firstname: address.receiverName,
      address1: address.addressLine1,
      address2: address.addressLine2,
      city: address.city,
      zipcode: address.zipcode,
      phone: address.telephone,
      neighborhood: address.neighborhood,
      additional_information: address.additionalInformation,
      reference: address.reference,
      cnpj: address.cnpj,
      ie_company: address.ieCompany,
      company: address.company,
      residence_number: address.residenceNumber,
      residence_type: address.residenceType,
      residence_data: address.residenceData,
    };
    const response = await authPut(`/v2/addresses/${address.id}`, body);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.address);
    return normalized;
  },
  /**
   * Delete address
   */
  async deleteAddress(addressId: number) {
    await authDelete(`/v2/addresses/${addressId}`);
  },

  /**
   *
   * Get credit cards
   */
  async getCreditCards(id: number) {
    const response = await authGet(`/api/v1/users/${id}/credit_cards`);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data.creditCards, schema.creditCards);
    return normalized;
  },
  /**
   *
   * Delete a Credit Card by ID
   */
  async deleteCreditCard(creditCardId: number) {
    await authDelete(`/v2/credit_cards/${creditCardId}`);
  },

  /**
   * Post to the server that the user forgot the password
   */
  async postForgotPassword(email: string) {
    const body = {
      email,
    };
    const response = await authPost(`/v2/password_reset`, body);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.user);
    return normalized;
  },

  /**
   * Resets the user password
   */
  async putResetPassword(
    password: string,
    passwordConfirmation: string,
    token: string,
    email: string,
  ) {
    const body = {
      password,
      passwordConfirmation,
      email,
    };
    const response = await authPut(`/v2/password_reset/${token}`, body);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.user);
    return normalized;
  },
  /**
   * Get Product Lists
   */
  async getProductLists(page?: number = 1, perPage?: number = 9999) {
    const response = await authGet(
      `/v2/users/me/product_lists?per_page=${perPage}&page=${page}`,
    );
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize({ ...data, id: 'result' }, schema.productList);
    return normalized;
  },
  /**
   * POST Product List
   */
  async postProductList(name: String, items: ProductList['items']) {
    const response = await authPost(`/v2/users/me/product_lists`, {
      name,
      personal_product_list_items_attributes: items,
    });
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.productList);
    return normalized;
  },
  /**
   * Delete Product List
   */
  async deleteProductList(listId: string | number) {
    const data = await authDelete(`/v2/product_lists/${listId}`);
    return data;
  },
  /**
   * Get Product Lists Items
   */
  async getProductListItems(
    listId: number,
    page: number = 1,
    perPage: number = 30,
  ) {
    const response = await authGet(
      `/v2/product_lists/${listId}/product_list_items?per_page=${perPage}&page=${page}`,
    );
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize({ ...data, id: 'result' }, schema.productList);
    return normalized;
  },

  async getWishlistProductItems(page: number = 1, perPage: number = 30) {
    const response = await authGet(
      `/v2/product_lists/wishlist/product_list_items?per_page=${perPage}&page=${page}`,
    );
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize({ ...data, id: 'result' }, schema.productList);
    return normalized;
  },
  /**
   * Post Product List Item
   */
  async postProductListItem(variantId: string | number, listId: number) {
    const body = {
      variant_id: variantId,
    };
    const response = await authPost(
      `/v2/product_lists/${listId}/product_list_items`,
      body,
    );
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.productListItem);
    return normalized;
  },
  /**
   * Delete Product List Item
   */
  async deleteProductListItem(listItemId: number) {
    await authDelete(`/v2/product_list_items/${listItemId}`);
  },
  /**
   * Get Wishlist
   */
  async getWishlist() {
    const response = await authGet(`/v2/users/me/product_lists/wishlist`);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.wishlist);
    return normalized;
  },
  /**
   * Post Item Wishlist
   */
  async postWishlistItem(variantId: number, quantity: number) {
    const body = {
      variant_id: variantId,
      quantity,
    };
    const response = await authPost(
      `/v2/product_lists/wishlist/product_list_items`,
      body,
    );
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.wishlist);
    return normalized;
  },
  /**
   * Put Add  product list to cart
   */
  async putAddProductListToCart(order_number: number, product_list_id: number) {
    const response = await authPut(
      `/api/v1/orders/${order_number}/copy_from_product_list`,
      { product_list_id },
    );

    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.orders);
    return normalized;
  },
};

export default User;
