// @flow
import React from 'react';
// Styles
import './BottomRow.css';
// Components
import { Link } from 'react-router-dom';
import { DepartmentsNavList } from '../../departments';

type Props = {
  userId: number,
  bigClubEnabled: boolean,
};

const BottomRow = ({ userId, bigClubEnabled }: Props) => {
  const linkTo = !!userId ? '/bigclub' : '/lp/bigclub';
  return (
    <div className="bottomrow-main">
      <DepartmentsNavList />
      {bigClubEnabled && (
        <Link to={linkTo} className="bottomrow-bigclub-main">
          <div className="bottomrow-bigclub">
            <p>
              <b>Big</b>Club
            </p>
            <div />
            <span>
              O nosso{' '}
              <b>
                programa
                <br /> de fidelidade
              </b>
            </span>
          </div>
        </Link>
      )}
    </div>
  );
};

export default BottomRow;
