// @flow
import _ from 'lodash';
import { createSelector } from 'reselect';
import type { StoreState } from '../types';

export const thisStateSelector = (state: StoreState): State => {
  return state.recipes;
};

export const recipesMapSelector = createSelector(
  thisStateSelector,
  state => state.recipes,
);

export const recipesByProductIdSelector = (state: any, productId: number) => {
  const recipesMap = recipesMapSelector(state);
  return _.filter(recipesMap, r => r.productId === productId);
};
