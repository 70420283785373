// @flow
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
// Analytics
import * as Analytics from '../../common/analytics';
// Styles
import './WishlistPage.css';
// Components
import UnauthenticatedRedirect from './UnauthenticatedRedirect';
import WishlistContainer from './WishlistContainer';
import EmptyList from '../components/wishlist/EmptyList';
import {
  ProductPromotionScroller,
  ProductRecommendedForUserScroller,
} from '../../products';
import ProductListItemSquare from '../components/wishlist/ProductListItemSquare';
import WishlistModal from './WishlistModal';
// Types
import { Wishlist } from '../types';
// Selectors
import {
  loggedInSelector,
  userWishlistSelector,
  wishlistLoadingProductItemSelector,
  wishlistLoadingSelector,
} from '../selectors';
// Actions
import {
  clearSearch,
  deleteWishListItemByItemId,
  getWishlist,
  getWishlistProducts,
  updateSearch,
} from '../actions';

type Props = {
  wishlist: Wishlist,
  wishlistItems: Wishlist['productListItems'][],
  wishlistLoading: boolean,
  fetchWishlistItems: () => void,
  location: any,
  history: any,
  clearSearchState: () => void,
  updateSearchState: (searchState: { page: number, perPage: number }) => void,
  loggedIn: boolean,
  handleFavoriteItem: (itemId: number) => void,
  wishlistLoadingProductItem: boolean,
  wishlistProductsLoading: boolean,
};

const WishlistPage = ({
  fetchWishlistItems,
  wishlist = {
    count: 0,
    currentPage: 1,
    pages: 1,
    productListItems: [],
    itemsId: [],
  },
  location,
  clearSearchState,
  updateSearchState,
  loggedIn,
  wishlistLoading,
  handleFavoriteItem,
  wishlistLoadingProductItem,
}: Props) => {
  const searchRef = useRef(null);
  const wishlistLoadingRef = useRef(null);
  const wishlistLoadingProductItemRef = useRef(null);
  const searchState = qs.parse(location.search.slice(1));
  const wishlistItems = wishlist.productListItems;
  const currentPage = wishlist.currentPage;
  const pages = wishlist.pages;
  const PAGE_NUMBERS = [...Array(pages + 1).keys()].slice(1);
  const hasItems = wishlistItems.length > 0;
  const [showProductModal, setShowProductModal] = useState(false);

  const handlePage = (value: 'next' | 'previous') => {
    if (value === 'next' && currentPage < pages) {
      const nextPage = currentPage + 1;
      return updateSearchState({ page: nextPage, perPage: 30 });
    }
    const previousPage = currentPage - 1;
    return updateSearchState({ page: previousPage, perPage: 30 });
  };

  const handleClickNumber = (value: number) => {
    if (value > 0 && value <= pages) {
      return updateSearchState({ page: value, perPage: 30 });
    }
  };

  const handleToggleModal = () => {
    setShowProductModal(prevValue => !prevValue);
  };

  useEffect(() => {
    if (loggedIn) {
      Analytics.logUserOpenedWishlistPage();
      updateSearchState({ page: currentPage, perPage: 30 });
      fetchWishlistItems(searchState.page, searchState.perPage);
      searchRef.current = location?.search;
      wishlistLoadingRef.current = wishlistLoading;
      wishlistLoadingProductItemRef.current = wishlistLoadingProductItem;
    }
    return () => {
      clearSearchState();
    };
    // eslint-disable-next-line
  }, [loggedIn]);

  useEffect(() => {
    if (
      loggedIn &&
      (searchRef.current !== location?.search ||
        wishlistLoadingProductItemRef.current !== wishlistLoadingProductItem ||
        wishlistLoading !== wishlistLoadingRef.current)
    ) {
      fetchWishlistItems(searchState.page, searchState.perPage);
      searchRef.current = location?.search;
      wishlistLoadingProductItemRef.current = wishlistLoadingProductItem;
      wishlistLoadingRef.current = wishlistLoading;
    }
    // eslint-disable-next-line
  }, [location?.search, wishlistLoading, wishlistLoadingProductItem]);

  return (
    <UnauthenticatedRedirect>
      <WishlistContainer handleClick={handleToggleModal}>
        <WishlistModal
          isOpen={showProductModal}
          handleClose={handleToggleModal}
        />

        <section className="wishlist-page-items">
          {hasItems &&
            wishlistItems.map((wishlistItem, index) => (
              <ProductListItemSquare
                key={index}
                itemId={wishlistItem.id}
                productId={wishlistItem.variant.id}
                productImage={wishlistItem.variant.images[0]}
                productManufacturer={wishlistItem.variant.manufacturer}
                productPrice={wishlistItem.variant.price}
                productListPrice={wishlistItem.variant.listPrice}
                productSlug={wishlistItem.variant.slug}
                productName={wishlistItem.variant.name}
                productUnit={wishlistItem.variant.unitForQuantity}
                productTotalOnHand={wishlistItem.variant.totalOnHand}
                defaultQuantity={wishlistItem.variant.defaultQuantity}
                inStock={wishlistItem.variant.inStock}
                discontinued={wishlistItem.variant.discontinued}
                incrementQuantity={wishlistItem.variant.incrementQuantity}
                analyticsViewSource="product list square"
                handleFavorite={async itemId => {
                  await handleFavoriteItem(itemId);
                  fetchWishlistItems(searchState.page, searchState.perPage);
                }}
              />
            ))}
        </section>
        {!hasItems && !wishlistLoading && <EmptyList />}
        {hasItems && (
          <div className="wishlist-page-pagination">
            <button
              disabled={currentPage === 1}
              onClick={() => {
                handlePage('previous');
              }}
              className="wishlist-page-pagination-button"
            >
              <i className="fa fa-chevron-left" />
            </button>
            {PAGE_NUMBERS.map(pageNumber => (
              <button
                key={pageNumber}
                onClick={() => handleClickNumber(pageNumber)}
                className={`wishlist-page-pagination-button ${
                  Number(currentPage) === Number(pageNumber) && 'selected'
                }`}
              >
                {pageNumber}
              </button>
            ))}
            <button
              disabled={currentPage === pages}
              onClick={() => {
                handlePage('next');
              }}
              className="wishlist-page-pagination-button"
            >
              <i className="fa fa-chevron-right" />
            </button>
          </div>
        )}
        <ProductRecommendedForUserScroller title="Para você" />
        <ProductPromotionScroller title="Nossa seleção" />
      </WishlistContainer>
    </UnauthenticatedRedirect>
  );
};

const mapStateToProps = state => {
  return {
    wishlistLoadingProductItem: wishlistLoadingProductItemSelector(state),
    wishlistLoading: wishlistLoadingSelector(state),
    wishlist: userWishlistSelector(state),
    loggedIn: loggedInSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, stateProps) => {
  return {
    fetchWishlistItems: (page: number = 1, perPage: 12) =>
      dispatch(getWishlistProducts(page, perPage)),
    clearSearchState: () => dispatch(clearSearch()),
    updateSearchState: (searchState: { page: number, perPage: number }) => {
      dispatch(updateSearch(searchState));
    },
    handleFavoriteItem: async (variantId: number) => {
      await dispatch(deleteWishListItemByItemId(variantId));
      dispatch(getWishlist());
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WishlistPage),
);
