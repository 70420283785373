// @flow
import { authPost, authGet } from '../../api';
import { normalize } from 'normalizr';
import { camelizeKeys } from 'humps';
import * as schema from './schema';
import {
  getLatestReferringParams,
  getFirstReferringParams,
} from '../../common/branch';

/**
 * REGISTER
 * Registers a new user through the api.
 */
export async function register(
  firstName: string,
  lastName: string,
  cpf: string,
  email: string,
  password: string,
  birthdate: string,
  referralCode: string,
  recaptchaToken: string,
) {
  const body = {
    first_name: firstName,
    last_name: lastName,
    cpf,
    email,
    password,
    birthdate,
    recaptcha_token: recaptchaToken,
    latestBranchParams: await getLatestReferringParams(), // params from last open
    firstBranchParams: await getFirstReferringParams(), // params from first open
    referral_code: referralCode,
  };
  const response = await authPost(`/v2/users`, body);
  const data = await response.json().then(b => camelizeKeys(b));
  const normalized = normalize(data, schema.user);
  return normalized;
}

/**
 * LOGIN
 * Logins in a user, returning object with JWT and User info
 */
export async function login(
  email: string,
  password: string,
  remember: boolean,
) {
  const orderToken = localStorage.getItem('order-guest-token');
  const body = { email, password, order_token: orderToken };
  const response = await authPost(`/v2/auth/`, body);
  if (!response.ok) throw new Error(response.status);

  // Delete the local storage order token
  const data = await response.json().then(b => camelizeKeys(b));
  const token = data.token;
  const normalized = normalize(data.user, schema.user);
  localStorage.removeItem('order-guest-token');

  updateToken(token, remember);
  return normalized;
}

/**
 * UPDATE TOKEN
 */
export async function updateToken(token, remember) {
  // We use a try/catch to detect situations where localStorage quota is 0, like safari private browsing mode
  try {
    if (!remember) throw new Error('Should not remember');
    localStorage.setItem('access-token', token);
  } catch (err) {
    // Remove items from localStorage just to be sure
    localStorage.removeItem('access-token');
    // Add items to sessionStorage, not persistent
    sessionStorage.setItem('access-token', token);
  }
}

/**
 * LOGOUT
 */
export async function logout() {
  localStorage.removeItem('access-token');
  sessionStorage.removeItem('access-token');
  return;
}

/**
 * Validate token
 */
export async function validateToken() {
  const response = await authGet('/v2/users/me');
  const data = await response.json().then(b => camelizeKeys(b));
  const normalized = normalize(data, schema.user);
  return normalized;
}
