// @flow
import React from 'react';
// Styles
import './SearchResultHorizontalBottomRow.css';
// Components
import { AddToCartButton } from '../../current-order';
import { ProductPrice } from '../../common';
// Types

type Props = {
  productId: number | string,
  manufacturer: string,
  listPrice: number,
  price: number,
  cookingMethods: any[],
  unit: 'g' | 'un',
  defaultQuantity: number,
  incrementQuantity: number,
  inStock: boolean,
  discontinued: boolean,
};

const SearchResultHorizontalBottomRow = (props: Props) => {
  return (
    <div className="search-result-hit-horizontal-bottom-row-main">
      <div className="search-result-hit-content">
        <section className="search-result-hit-row">
          <div className="search-result-price-value">
            <ProductPrice
              productListPrice={props.listPrice}
              productPrice={props.price}
              productUnit={props.unit}
            />
          </div>
        </section>

        <AddToCartButton
          productId={props.productId}
          unitForQuantity={props.unit}
          defaultQuantity={props.defaultQuantity}
          incrementQuantity={props.incrementQuantity}
          inStock={props.inStock}
          discontinued={props.discontinued}
          analyticsEventType="search result" // USE ANALYTICS
        />
      </div>

      <div className="search-result-hit-details-container">
        <div className="search-result-hit-details-price">
          <ProductPrice
            productListPrice={props.listPrice}
            productPrice={props.price}
            productUnit={props.unit}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchResultHorizontalBottomRow;
