import { createSelector } from 'reselect';
// Lodash
import _ from 'lodash';

export const localStateSelector = state => {
  return state.barbecue;
};

///////////////// BARBECUE RELATED /////////////////////

export const barbecueComplementaryCategoriesSelector = createSelector(
  localStateSelector,
  state =>
    state.currentBarbecueCategoryIds.map(id => state.barbecueCategories[id]),
);

export const barbecueOptionValuesSelector = createSelector(
  localStateSelector,
  state =>
    state.currentOptionValuesIds.map(id => state.barbecueOptionValues[id]),
);

export const barbecueVariantsByIdsSelector = (state: any, ids: number[]) => {
  const productMap = state.products.products;
  return ids.map(id => productMap[id]).filter(p => p !== undefined);
};

export const barbecueSelectedVariantsSelector = createSelector(
  localStateSelector,
  state =>
    state.barbecueCurrentSelectedProductsIds.map(
      id => state.barbecueSelectedProducts[id],
    ),
);

export const barbecueSelectedVariantQuantity = (state: any, id: number) => {
  const variantsMap = state.barbecue.barbecueSelectedVariants;
  const variant = _.find(variantsMap, o => {
    return o.variant === id;
  });
  return variant.desiredQuantity;
};

export const selectedVariantsToArray = (state: any) => {
  const variantsMap = state.barbecue.barbecueSelectedVariants;

  let variants = [];
  _.forEach(variantsMap, e => {
    variants.push(e);
  });
  return variants;
};

export const barbecueTotalQuantitySelector = (state: any, unit: string) => {
  const variantsMap = state.barbecue.barbecueSelectedProducts;
  const variantsToArray = selectedVariantsToArray(state);

  let sum = 0;

  variantsToArray.forEach(e => {
    const selectedVariant = variantsMap[e.variant];
    if (selectedVariant && selectedVariant.unitForQuantity === unit)
      sum += e.desiredQuantity;
  });

  return sum;
};

export const barbecueTotalPriceSelector = (state: any) => {
  const variantsMap = state.barbecue.barbecueSelectedProducts;
  const variantsToArray = selectedVariantsToArray(state);

  let totalPrice = 0;

  variantsToArray.forEach(e => {
    const selectedVariant = variantsMap[e.variant];
    if (selectedVariant)
      totalPrice += e.desiredQuantity * selectedVariant.price;
  });

  return totalPrice;
};

///////////////// LOADING RELATED /////////////////////

export const complementaryCategoriesLoadingSelector = createSelector(
  localStateSelector,
  state => state.loading.barbecueCategoriesLoading,
);
export const optionValuesLoadingSelector = createSelector(
  localStateSelector,
  state => state.loading.barbecueOptionValuesLoading,
);
export const newBarbecueLoadingSelector = createSelector(
  localStateSelector,
  state => state.loading.newBarbecueLoading,
);
export const fetchingProductsLoadingSelector = createSelector(
  localStateSelector,
  state => state.loading.fetchingProductsLoading,
);
