// @flow
import React, { Component } from 'react';
import _ from 'lodash';
import './MyAccountPayment.css';
// Components
import { PageLoader } from '../../../common';
import { CreditCardOption } from '../../../current-order';
// Types
import type { CreditCard, PaymentMethod } from '../../types';

type Props = {
  creditCards: CreditCard[],
  allowedPaymentMethods: PaymentMethod[],
  loading: boolean,
};

class MyAccountPayment extends Component {
  props: Props;

  render() {
    const offlinePaymentMethods = _.remove(this.props.allowedPaymentMethods, {
      restrictable: true,
    });

    if (this.props.loading) {
      // Loading state
      return <PageLoader />;
    }
    return (
      <div className="payments-list-container">
        <h3>Meus cartões</h3>
        <hr></hr>
        <div>
          {this.props.creditCards
            ? this.props.creditCards.map(c => (
                <div>
                  <div className="credit-card-list-main" key={c.id}>
                    <CreditCardOption
                      creditCard={c}
                      active={c.id === this.props.selectedCardId}
                      deleteCreditCard={this.props.deleteCreditCard}
                      deletingLoading={this.props.deletingLoading}
                    />
                  </div>
                </div>
              ))
            : null}
          {this.props.creditCards.length === 0 ? (
            <div className="payments-list-no-card">
              Você ainda não tem cartões cadastrados!
            </div>
          ) : null}
        </div>
        <div className="offline-payments-list-container">
          {offlinePaymentMethods.length === 0 ? (
            ''
          ) : (
            <div className="offline-payments-pane-container">
              <h3 className="offline-payments-pane-title">Outros métodos</h3>
              <div className="offline-payments-picker-main">
                {offlinePaymentMethods.map((item, ind) => (
                  <div key={ind} className="offline-payments-option-main">
                    {item.name}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default MyAccountPayment;
