// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link, withRouter } from 'react-router-dom';
// Components
import { ColorRow, PageLoader } from '../../common';
import { Helmet } from 'react-helmet';
import { UnauthenticatedRedirect } from '../../user';
import BlendTemplateListItem from '../components/BlendTemplateListItem';
// Analytics
import * as Analytics from '../../common/analytics';
// Actions
import * as actions from '../actions';
// Selectors
import * as selectors from '../selectors';
import currentOrderNumberConnector from '../../current-order/connectors/currentOrderNumberConnector';
// Images
import BlendBanner from '../images/blend_banner.png';
//Style
import './BlendsTemplateIndexPage.css';
// Types
import type {
  BlendTemplate,
  BlendTemplateItem as BlendTemplateItemType,
  Blend,
} from '../types';

type Props = {
  blendTemplates: [BlendTemplate],
  loading: boolean,
  blendTemplateItemsByTemplateId: (
    templateId: number,
  ) => BlendTemplateItemType[],
  blendByTemplateIdAndOrderNumber: (
    orderNumber: string,
    templateId: number,
  ) => Blend,
  getBlendTemplates: () => void,
  getBlends: (orderNumber: string) => void,
  // From Connector
  currentOrderNumber: string,
};

/**
 * This page will show all the blend templates that belong to the current user.
 * Blend template that are complete will be able to be added to cart and have their quantity changed.
 * Blend templates that are NOT complete will have the option of navigating to the blend creation page to complete them.
 */

export class BlendsTemplateIndexPage extends Component {
  props: Props;

  /**
   * API requests should be made here.
   */
  componentDidMount() {
    this.props.getBlendTemplates();

    // If we already have an order number we should get all the blends. This is needed to correctly populate the addToCartButtons
    if (this.props.currentOrderNumber) {
      this.props.getBlends(this.props.currentOrderNumber);
    }
  }

  componentDidUpdate(prevProps: Props) {
    // If our orderNumber changes we need to get the blends associated with the new number
    if (this.props.currentOrderNumber !== prevProps.currentOrderNumber) {
      this.props.getBlends(this.props.currentOrderNumber);
    }
  }

  render() {
    // Sort blends by completed at such that newest are on top and incomplete at the bottom
    const sortedBlendTemplates = _.sortBy(
      this.props.blendTemplates,
      ['completedAt', 'updatedAt'],
      ['asc', 'asc'],
    );

    return (
      <UnauthenticatedRedirect>
        <div className="blends-page-main">
          <Helmet>
            <title>Meus Blends - Bigdelivery, Seu açougue virtual</title>
          </Helmet>

          <ColorRow color="orange" text="MEUS BLENDS" />
        </div>
        <div>
          <img className="blends-page-banner-image" src={BlendBanner} alt="" />
        </div>
        {this.props.loading ? (
          <PageLoader />
        ) : (
          <div className="blends-page-container">
            {sortedBlendTemplates.map(template => (
              <BlendTemplateListItem
                key={template.id}
                blendTemplate={template}
                blendTemplateItems={this.props.blendTemplateItemsByTemplateId(
                  template.id,
                )}
                blend={this.props.blendByTemplateIdAndOrderNumber(
                  this.props.currentOrderNumber,
                  template.id,
                )}
                clickable={true}
              />
            ))}
            <Link
              className="blends-page-caculator-button"
              to="/blend/calculadora/new/"
              onClick={() => {
                Analytics.logTappedCreateBlend();
              }}
            >
              <div>ADICIONAR NOVO BLEND</div>
            </Link>
          </div>
        )}
      </UnauthenticatedRedirect>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    blendTemplates: selectors.blendTemplatesSelector(state),
    blendTemplateItemsByTemplateId: (id: number | string) =>
      selectors.getBlendTemplateItemsByBlendTemplateIdSelector(state, id),
    blendByTemplateIdAndOrderNumber: (
      orderNumber: string,
      templateId: number,
    ) =>
      selectors.blendByOrderNumberAndTemplateIdSelector(
        state,
        orderNumber,
        templateId,
      ),
    loading: selectors.gettingBlendTemplatesLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    getBlendTemplates: () => {
      dispatch(actions.getBlendTemplates());
    },
    getBlends: (orderNumber: string) => {
      dispatch(actions.getBlends(orderNumber));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default currentOrderNumberConnector(
  withRouter(connector(BlendsTemplateIndexPage)),
);
