// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { UnauthenticatedRedirect, userAddressConnector } from '../../user';
// Components
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { ColorRow, PageLoader } from '../../common';
import CheckoutPageComponent from '../components/CheckoutPage';
import Lottie from 'lottie-react';
// Styles
import './CheckoutPage.css';
//Helpers
import { useScreenTimeLogger } from '../../common';
// Assets
import loadingCheckout from '../images/loading_checkout.json';
// Action creators
import {
  dismissErrors,
  findCheckoutOrder,
  findPromotionsByUser,
  initiateCheckout,
  submitAddress,
  submitConfirmation,
  submitDelivery,
  submitPayment,
  submitPromotion,
  transitionStepToState,
  transitionToState,
} from '../actions';
// Selectors
import { bigClubSettingsSelector } from '../../preferences/selectors';
import {
  addressErrorsSelector,
  adjustmentTotalSelector,
  allPromotionsSelector,
  appliedStoreCreditsSelector,
  availableStoreCreditsSelector,
  creditCardsSelector,
  currentOrderBigClubInfoSelector,
  currentOrderDeliverySelector,
  currentOrderPaymentSelector,
  currentOrderShippingAddressSelector,
  deliveryErrorsSelector,
  gettingOrderLoadingSelector,
  gettingPromotionsLoadingSelector,
  initiatingCheckoutLoadingSelector,
  itemCountSelector,
  itemSubtotalSelector,
  itemsSelector,
  orderNumberSelector,
  orderStateSelector,
  paymentErrorsSelector,
  paymentMethodsSelector,
  promotionErrorsSelector,
  shippingRatesSelector,
  shippingSubtotalSelector,
  totalSelector,
  transitioningLoadingSelector,
  transitioningStepToSateLoadingSelector,
} from '../selectors';
//Connectors
import blendsListConnector from '../../blend/connectors/blendsListConnector';
// Types
import type { Connector } from 'react-redux';
import { BigClubSettings } from '../../preferences/types';
import type { UserAddress } from '../../user';
import type { Props as ComponentProps } from '../components/CheckoutPage';
import type {
  CreditCard,
  PaymentMethod,
  PaymentSource,
  ShippingRate,
} from '../types';

type Props = ComponentProps & {
  orderNumber: string,
  initialLoading: boolean,
  initiateCheckout: () => void,
  transitionStepToPayment: () => void,
  //Connector
  getBlends: orderNumber => void,
  blendsByOrderNumber: orderNumber => void,
  findPromotionsByUser: string => void,
  bigclubSettings: BigClubSettings,
};

type OwnProps = {
  location: Location,
};

const CheckoutPage = (props: Props & OwnProps) => {
  useScreenTimeLogger(props.location.pathname);
  useEffect(() => {
    if (!props.orderNumber) {
      props.findCheckoutOrder();
      // props.transitionStepToPayment();
      props.getBlends(props.orderNumber);
    } else {
      props.initiateCheckout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.orderNumber]);

  useEffect(() => {
    if (props.userId) {
      props.findPromotionsByUser(props.userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userId]);

  // componentDidUpdate(prevProps) {
  //   if (prevProps.orderNumber == null && props.orderNumber != null) {
  //     console.log('initiating checkout');
  //     props.initiateCheckout();
  //   }
  // }

  if (props.initialLoading) {
    return (
      <div>
        <ColorRow color="red" text="CHECKOUT" />
        <div className="checkout-page-loading-container ">
          <h2 className="checkout-page-loading-title">
            Deixando tudo pronto para você!
          </h2>
          <Lottie
            animationData={loadingCheckout}
            className="checkout-page-loading"
            loop={false}
          />
        </div>
      </div>
    );
  }

  // TODO: Reimplement this protection in a way that does not redirect user to home page if he refreshes the page
  // if (!props.orderNumber) {
  //   return <Redirect to="/" />;
  // }

  if (
    props.orderPayment &&
    props.orderPayment.sourceType === 'PixPayment' &&
    props.orderState === 'complete'
  ) {
    return <Redirect to={`/pix-payment?id=${props.orderNumber}`} />;
  }
  if (props.orderState === 'complete') {
    return <Redirect to={`/confirmacao?id=${props.orderNumber}`} />;
  }

  return (
    <UnauthenticatedRedirect>
      <Helmet>
        <title>Checkout - Bigdelivery, Seu açougue virtual</title>
      </Helmet>
      <CheckoutPageComponent {...props} />
    </UnauthenticatedRedirect>
  );
};

const mapStateToProps = (state, ownProps: {}) => {
  return {
    orderNumber: orderNumberSelector(state),
    // We should trigger this loading if we are either getting an order or transitioning it to checkout
    initialLoading:
      initiatingCheckoutLoadingSelector(state) ||
      gettingOrderLoadingSelector(state) ||
      transitioningStepToSateLoadingSelector(state),
    items: itemsSelector(state),
    totalItemPrice: itemSubtotalSelector(state),
    shippingPrice: shippingSubtotalSelector(state),
    totalPrice: totalSelector(state), // Includes shipping cost
    adjustmentTotal: adjustmentTotalSelector(state),
    availableStoreCredits: availableStoreCreditsSelector(state),
    appliedStoreCredits: appliedStoreCreditsSelector(state),
    numberOfItems: itemCountSelector(state),
    shippingRates: shippingRatesSelector(state),
    orderState: orderStateSelector(state),
    paymentMethods: paymentMethodsSelector(state),
    creditCards: creditCardsSelector(state),
    orderShippingAddress: currentOrderShippingAddressSelector(state),
    orderDelivery: currentOrderDeliverySelector(state),
    bigClubInfo: currentOrderBigClubInfoSelector(state),
    orderPayment: currentOrderPaymentSelector(state),
    promotions: allPromotionsSelector(state),
    promotionsLoading: gettingPromotionsLoadingSelector(state),
    transitioningLoading: transitioningLoadingSelector(state),
    addressErrors: addressErrorsSelector(state),
    deliveryErrors: deliveryErrorsSelector(state),
    promotionErrors: promotionErrorsSelector(state),
    paymentErrors: paymentErrorsSelector(state),
    bigClubSettings: bigClubSettingsSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  findCheckoutOrder() {
    dispatch(findCheckoutOrder());
  },
  initiateCheckout() {
    dispatch(initiateCheckout());
  },
  transitionToState(state: string) {
    dispatch(transitionToState(state));
  },
  transitionStepToPayment() {
    dispatch(transitionStepToState('payment'));
  },
  submitAddress(address: UserAddress) {
    dispatch(submitAddress(address));
  },
  submitShippingRate(shippingRate: ShippingRate) {
    dispatch(submitDelivery(shippingRate));
  },
  submitPayment(
    paymentMethod: PaymentMethod,
    paymentSource: CreditCard | PaymentSource,
    existingCard: boolean,
    bigClubCreditAmount: number,
  ) {
    dispatch(
      submitPayment(
        paymentMethod,
        paymentSource,
        existingCard,
        bigClubCreditAmount,
      ),
    );
  },
  submitConfirmation() {
    dispatch(submitConfirmation());
  },
  submitPromotion(couponCode: string) {
    dispatch(submitPromotion(couponCode));
  },
  dismissErrors() {
    dispatch(dismissErrors());
  },
  findPromotionsByUser(userId: number) {
    dispatch(findPromotionsByUser(userId));
  },
});

const connector: Connector<OwnProps, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default withRouter(
  connector(userAddressConnector(blendsListConnector(CheckoutPage))),
);
