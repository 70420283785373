import { authGet } from '../../api';
import { camelizeKeys } from 'humps';
import { normalize } from 'normalizr';
import * as schema from './schema';

const Recipe = {
  async getRecipesByProduct(id) {
    const response = await authGet(`/v2/recipes?by_product=${id}`);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.recipes);
    return normalized;
  },
};

export default Recipe;
