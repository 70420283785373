// @flow
import React, { Component } from 'react';
import _ from 'lodash';

//smooth-scroll
import { scroller } from 'react-scroll';

// Components
import { Button } from '../../common';
import Slider from 'rc-slider';

import ProductSearch from './ProductSearch';
import BlendTemplateItem from '../containers/BlendTemplateItem';
// Images
import BlendBox from '../images/blend_box.png';
import EmptyCart from '../../current-order/images/empty-cart.png';
// Helpers
import {
  InputFieldErrorMessage,
  validationHelpers,
  priceConverter,
} from '../../common';
// Analytics
import * as Analytics from '../../common/analytics';
// Styles
import './BlendCalculatorPage.css';
// Types
import type {
  BlendTemplate,
  BlendTemplateItem as BlendTemplateItemType,
} from '../types';

type Props = {
  blendTemplate: BlendTemplate,
  blendTemplateItems: BlendTemplateItemType,
  isItemInBlend: (variantId: number | string) => boolean,
  updateBlendTemplate: (
    templateId: number,
    name: ?string,
    observation: ?string,
    complete: ?boolean,
    fatPercentage: ?number,
  ) => void,
  createBlendItem: () => void,
  loading: boolean,
  addingBlendTemplateItemsLoading: boolean,
};

type State = {
  name: string,
  fatPercentage: number,
  observation: string,
  nameError: ?string,
  observationError: ?string,
  blendItemsError: ?string,
};

export class BlendCalculatorPage extends Component {
  state: State;
  constructor(props: Props) {
    super(props);

    this.state = {
      name: this.props.blendTemplate ? this.props.blendTemplate.name : '',
      observation: this.props.blendTemplate
        ? this.props.blendTemplate.observation
        : '',

      nameError: '',
      observationError: '',
      blendItemsError: '',
      fatPercentage:
        this.props.blendTemplate && this.props.blendTemplate.fatPercentage
          ? this.props.blendTemplate.fatPercentage
          : 20,
    };
  }

  // Function for validating the blend before sending to the server
  validateBlendInput = () => {
    let noValidationErrors: boolean = true;

    const nameError = validationHelpers.blend.name(this.state.name);
    this.setState({ nameError });
    if (nameError) {
      noValidationErrors = false;
    }

    const observationError = validationHelpers.blend.observation(
      this.state.observation,
    );
    this.setState({ observationError });
    if (observationError) {
      noValidationErrors = false;
    }

    const blendItemsError = validationHelpers.blend.blendItems(
      this.props.blendTemplateItems,
    );
    this.setState({ blendItemsError });
    if (blendItemsError) {
      noValidationErrors = false;
    }

    if (noValidationErrors) {
      const confirm = window.confirm(
        `Depois de salvo o Blend não pode mais ser editado. Deseja continuar?`,
      );
      if (confirm) {
        this.props.updateBlendTemplate(
          this.props.blendTemplate.id,
          this.state.name,
          this.state.observation,
          true,
          this.state.fatPercentage,
        );
      }
    } else {
      console.error('Validation error on blend form.');
      scroller.scrollTo('blend-calculator-page-container', {
        duration: 1500,
        delay: 100,
        smooth: 'easeInOutQuart',
      });
    }
  };

  render() {
    const totalQuantityOfBlendTemplateItems =
      this.props.blendTemplateItems.reduce(
        (accumulated, current) => current.quantity + accumulated,
        0,
      );

    return (
      <div className="blend-calculator-page-main">
        <div className="blend-calculator-page-container">
          <div className="blend-calculator-page-content">
            <h3>CRIAR NOVO BLEND</h3>
            <div className="blend-calculator-page-header">
              <img className="blend-box-image" src={BlendBox} alt="" />
              <div className="blend-calculator-page-header-info">
                <InputFieldErrorMessage message={this.state.nameError} />
                <input
                  className="blend-name"
                  placeholder="Nome do Blend"
                  value={this.state.name}
                  onChange={e => this.setState({ name: e.target.value })}
                  onBlur={() =>
                    this.props.updateBlendTemplate(
                      this.props.blendTemplate.id,
                      this.state.name,
                      this.state.observation,
                      false,
                      this.state.fatPercentage,
                    )
                  }
                />

                <div className="blend-calculator-page-sub-header-info">
                  <div>
                    <InputFieldErrorMessage
                      message={this.state.observationError}
                    />
                    <textarea
                      rows="2"
                      className="blend-description"
                      placeholder="Teor de gordura, preferências e informações adicionais"
                      value={this.state.observation}
                      onChange={e =>
                        this.setState({ observation: e.target.value })
                      }
                      onBlur={() =>
                        this.props.updateBlendTemplate(
                          this.props.blendTemplate.id,
                          this.state.name,
                          this.state.observation,
                          false,
                          this.state.fatPercentage,
                        )
                      }
                    />
                  </div>

                  <div className="blend-calculator-page-fat-percentage">
                    <div>
                      <p className="blend-calculator-page-fat-percentage-title">
                        Teor de Gordura
                      </p>
                      <Slider
                        value={this.state.fatPercentage}
                        onChange={fatPercentage =>
                          this.setState({ fatPercentage })
                        }
                        trackStyle={{ backgroundColor: '#eb5b1e' }}
                        dotStyle={{ borderColor: 'orange' }}
                        handleStyle={{
                          borderColor: '#eb5b1e',
                          boxShadow: '0 0 5px tint(#eb5b1e, 20%)',
                        }}
                        onAfterChange={() =>
                          this.props.updateBlendTemplate(
                            this.props.blendTemplate.id,
                            this.state.name,
                            this.state.observation,
                            false,
                            this.state.fatPercentage,
                          )
                        }
                      />
                    </div>
                    <p>{`${this.state.fatPercentage}%`}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="blend-calculator-page-subcontrainer">
              <ProductSearch
                blendTemplateId={this.props.blendTemplate.id}
                isItemInBlend={this.props.isItemInBlend}
                AddItemToBlendTemplate={this.props.AddItemToBlendTemplate}
                addingBlendTemplateItemsLoading={
                  this.props.addingBlendTemplateItemsLoading
                }
              />

              <div className="blend-calculator-page-right">
                <InputFieldErrorMessage message={this.state.blendItemsError} />
                <div className="blend-calculator-new-blend">
                  <div className="blend-calculator-new-blend-header">
                    <h3>MEU BLEND</h3>
                  </div>
                  <div className="blend-calculator-new-blend-products">
                    {_.isEmpty(this.props.blendTemplateItems) ? (
                      <div className="blend-calculator-no-items">
                        <img src={EmptyCart} alt="" />
                        <span>
                          Sem itens. Adicione o seu primeiro item no painel
                          anterior :D
                        </span>
                      </div>
                    ) : (
                      this.props.blendTemplateItems.map((item, ind) => (
                        <BlendTemplateItem
                          key={ind}
                          item={item}
                          blendTemplateId={this.props.blendTemplate.id}
                          totalBlendQuantity={totalQuantityOfBlendTemplateItems}
                        />
                      ))
                    )}
                  </div>
                  <hr className="separator-line" />
                  <div className="blend-calculator-total-info">
                    <p>{`Total: ${
                      this.props.blendTemplate.defaultQuantity / 1000
                    }Kg`}</p>
                    <p>
                      R$/Kg:{' '}
                      <span className="blend-calculator-total-info-price">
                        {priceConverter(this.props.blendTemplate.price * 1000)}
                        /Kg
                      </span>
                    </p>
                    <p>Subtotal estimado:</p>
                    <p className="blend-calculator-total-info-amout">
                      {priceConverter(
                        this.props.blendTemplate.amount
                          ? this.props.blendTemplate.amount
                          : 0,
                      )}
                    </p>
                  </div>
                </div>
                <div className="blend-calculator-cart-button">
                  <Button
                    text="SALVAR BLEND"
                    buttonType="button-secondary"
                    icon=""
                    onClick={() => {
                      this.validateBlendInput();
                      Analytics.logSavedBlend(this.props.blendTemplate);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BlendCalculatorPage;
