// Redux related
import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
// Lodash
import _ from 'lodash';
//Types
import type { Reducer } from 'redux';
import type { Action } from '../../types';

import {
  getBarbecueCategories,
  getBarbecueCategoriesRequest,
  getBarbecueOptionValues,
  getBarbecueOptionValuesRequest,
  postBarbecue,
  postBarbecueRequest,
} from '../actions';

//////////////////////// INDEXES RELATED ////////////////////

const currentOptionValuesIds: Reducer<string, Action> = handleActions(
  {
    [getBarbecueOptionValues]: {
      next: (state, action) => action.payload.result,
      throw: (state, action) => state,
    },
  },
  [],
);

const currentBarbecueCategoryIds: Reducer<string, Action> = handleActions(
  {
    [getBarbecueCategories]: {
      next: (state, action) => action.payload.result,
      throw: (state, action) => state,
    },
  },
  [],
);

const barbecueCurrentSelectedProductsIds: Reducer<string, Action> =
  handleActions(
    {
      [postBarbecue]: {
        next: (state, action) => {
          const selectedProductsIds = [];
          _.forEach(action.payload.entities.selectedVariants, (value, key) => {
            selectedProductsIds.push(value.variant);
          });
          return selectedProductsIds;
        },
        throw: (state, action) => state,
      },
    },
    [],
  );
//////////////////////// CONTENT RELATED ////////////////////

const barbecueCategories: Reducer<string, Action> = handleActions(
  {
    [getBarbecueCategories]: {
      next: (state, action) => action.payload.entities.calculatorCategories,
      throw: (state, action) => state,
    },
  },
  {},
);

const barbecueOptionValues: Reducer<string, Action> = handleActions(
  {
    [getBarbecueOptionValues]: {
      next: (state, action) => action.payload.entities.calculatorOptionValues,
      throw: (state, action) => state,
    },
  },
  {},
);

const barbecueSelectedProducts: Reducer<string, Action> = handleActions(
  {
    [postBarbecue]: {
      next: (state, action) => action.payload.entities.products,
      throw: (state, action) => state,
    },
  },
  {},
);
const barbecueSelectedVariants: Reducer<string, Action> = handleActions(
  {
    [postBarbecue]: {
      next: (state, action) => action.payload.entities.selectedVariants,
      throw: (state, action) => state,
    },
  },
  {},
);

//////////////////////// LOADING RELATED ////////////////////

const initialLoadingState = {
  barbecueCategoriesLoading: false,
  barbecueOptionValuesLoading: false,
  newBarbecueLoading: false,
};
const loading = handleActions(
  {
    // Barbecue categories loading
    [getBarbecueCategoriesRequest]: (state, action) => ({
      ...state,
      barbecueCategoriesLoading: true,
    }),
    [getBarbecueCategories]: (state, action) => ({
      ...state,
      barbecueCategoriesLoading: false,
    }),
    // Option values loading
    [getBarbecueOptionValuesRequest]: (state, action) => ({
      ...state,
      barbecueOptionValuesLoading: true,
    }),
    [getBarbecueOptionValues]: (state, action) => ({
      ...state,
      barbecueOptionValuesLoading: false,
    }),
    [postBarbecueRequest]: (state, action) => ({
      ...state,
      newBarbecueLoading: true,
    }),
    [postBarbecue]: (state, action) => ({
      ...state,
      newBarbecueLoading: false,
    }),
  },
  initialLoadingState,
);

const reducer = combineReducers({
  loading,
  barbecueCurrentSelectedProductsIds,
  barbecueSelectedProducts,
  barbecueSelectedVariants,
  barbecueOptionValues,
  currentOptionValuesIds,
  barbecueCategories,
  currentBarbecueCategoryIds,
});

export default reducer;
