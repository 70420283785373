// @flow
import React, { Component } from 'react';
import type { LineItem } from '../types';

//Item
import Item from './Item';

//Style
import './ItemList.css';

type Props = {
  items: LineItem[],
  handleRemoveItem: (lineItemId: number) => void,
};

class ItemList extends Component {
  props: Props;
  render() {
    return (
      <div className="item-list-main">
        {this.props.items.map((item, i) => (
          <Item
            item={item}
            key={item.id}
            productId={item.id}
            handleRemoveItem={this.props.handleRemoveItem}
          />
        ))}
      </div>
    );
  }
}

export default ItemList;
