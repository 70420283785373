// @flow
import React, { useEffect } from 'react';
// Connectors
import { displayVariantsByPageAndSectionConnector } from '../../products';
// Components
import KitsProductCard from './KitsProductCard';
// Types
import type { Product } from '../../products/types/index';

type Props = {
  findDisplayVariantsByPageAndSection: (
    page: string,
    section: string,
  ) => Product[],
  productByPageAndSection: (page: string, section: string) => Product[],
};

const KitProductBanner = ({
  productByPageAndSection,
  findDisplayVariantsByPageAndSection,
}: Props) => {
  const sliderProductsVariants = productByPageAndSection('kits_big', 'slider');
  const productsListVariants = productByPageAndSection(
    'kits_big',
    'product_list',
  );
  useEffect(() => {
    findDisplayVariantsByPageAndSection('kits_big');
    //eslint-disable-next-line
  }, []);

  return (
    <div id="kits-product-banner">
      <KitsProductCard
        title="OS MAIS PEDIDOS :"
        sliderProductsVariants={sliderProductsVariants}
        productsVariants={productsListVariants}
        currentLanding="kits_big"
      />
    </div>
  );
};
export default displayVariantsByPageAndSectionConnector(KitProductBanner);
