// @flow
import React, { Component } from 'react';
// Styles
import './NoOrders.css';
import PorkStamp from '../../static/images/porkOnSkate.png';

type Props = {};

class NoOrders extends Component {
  props: Props;
  render() {
    return (
      <div className="no-orders-main">
        <img className="no-orders-stamp" src={PorkStamp} alt="Porco no skate" />
        <p>Você ainda não tem pedidos fechados.</p>
      </div>
    );
  }
}

export default NoOrders;
