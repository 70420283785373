// @flow
import React, { useState } from 'react';
// Styles
import './LandingFAQ.css';
// Types
import { IFaqQuestion } from '../types/faqQuestion';
// Analytics
import { logLandingPageFaqToggle } from '../../common/analytics';

type Props = {
  faqQuestions: IFaqQuestion[],
  currentLanding: string,
};
const LandingFAQ = ({ faqQuestions, currentLanding }: Props) => {
  const [questions, setQuestions] = useState<IFaqQuestion[]>(
    faqQuestions ? faqQuestions : [],
  );

  const handleToggleFaq = targetIndex => {
    const updatedQuestions = questions.map((q, index) => {
      if (index === targetIndex) return { ...q, isOpen: !q.isOpen };
      return q;
    });
    setQuestions(updatedQuestions);
    logLandingPageFaqToggle(currentLanding);
  };

  return (
    <div className="landing-faq">
      <div className="landing-faq-container">
        {questions.length > 0 &&
          questions.map((q, index) => {
            return (
              <div
                className={`landing-faq-collapse ${
                  q.isOpen ? 'landing-faq-collapse-active' : ''
                }`}
                key={index}
                onClick={() => {
                  handleToggleFaq(index);
                }}
              >
                <div className="landing-faq-collapse-header">
                  <h6
                    className={`landing-faq-collapse-title ${
                      q.isOpen ? 'landing-faq-active' : ''
                    }`}
                  >
                    {q.title}
                  </h6>
                  <button
                    className={`landing-faq-collapse-btn ${
                      q.isOpen ? 'landing-faq-collapse-btn-active' : ''
                    }`}
                    aria-label="faq collapse button"
                  >
                    {q.isOpen ? (
                      <i className="fa fa-chevron-up"></i>
                    ) : (
                      <i className="fa fa-chevron-down"></i>
                    )}
                  </button>
                </div>
                <div
                  className={`landing-faq-collapse-body ${
                    q.isOpen
                      ? 'landing-faq-fadeIn landing-faq-active'
                      : 'landing-faq-collapse-body-alt'
                  } `}
                  dangerouslySetInnerHTML={{ __html: q.body }}
                ></div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default LandingFAQ;
