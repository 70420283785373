// @flow
import React, { Component } from 'react';
// assets
import logo from '../images/LogoVertical.png';
// components
import { Link } from 'react-router-dom';
// styles
import './Logo.css';

class Logo extends Component {
  render() {
    return (
      <Link to="" className="logo-main">
        <img className="logo-image" alt="Big Delivery Logo" src={logo} />
      </Link>
    );
  }
}

export default Logo;
