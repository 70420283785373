// @flow
import React, { useEffect, useState } from 'react';
import { InstantSearch } from 'react-instantsearch-dom';
// Redux
import { connect } from 'react-redux';
// Components
import WishlistCreate from '../components/wishlist/WishlistCreate';
// Actions
import {
  clearSelectedProductListItems,
  getProductLists,
  postProductList,
} from '../actions';
// Types
import type { ProductList } from '../types';
// Selectors
import { userProductListItemsSelector } from '../selectors';

type Props = {
  showModal: boolean,
  handleCloseModal: () => void,
  clearSelectedProductListItems: () => void,
  items: ProductList['items'],
  postProductList: (name: String, items: ProductList['items']) => void,
};

const ProductListModal = ({
  showModal,
  handleCloseModal,
  clearSelectedProductListItems,
  postProductList,
  items,
}: Props) => {
  const [searchState, setSearchState] = useState({ query: '' });

  useEffect(() => {
    return () => {
      clearSelectedProductListItems();
    };
    // eslint-disable-next-line
  }, [showModal]);

  const handleSubmit = (name: string, items: ProductList['items']) => {
    postProductList(name, items);
    handleCloseModal();
  };
  return (
    <InstantSearch
      appId={process.env.REACT_APP_ALGOLIA_APP_ID}
      apiKey={process.env.REACT_APP_ALGOLIA_API_KEY}
      indexName={
        process.env.REACT_APP_ALGOLIA_PRODUCT_INDEX ||
        'product_sales_production'
      }
      searchState={searchState}
      onSearchStateChange={setSearchState}
    >
      <WishlistCreate
        isOpen={showModal}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmit}
        selectedProductListItems={items}
      />
    </InstantSearch>
  );
};

const mapStateToProps = state => {
  return {
    items: userProductListItemsSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: {}) => {
  return {
    clearSelectedProductListItems: () => {
      dispatch(clearSelectedProductListItems());
    },
    postProductList: async (name: String, items: ProductList['items']) => {
      await dispatch(postProductList(name, items));
      dispatch(getProductLists());
    },
  };
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default connector(ProductListModal);
