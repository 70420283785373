// @flow
import React from 'react';
import { connect } from 'react-redux';
// Styles
import './FavoriteButton.css';
// Assets
import heart from '../images/heart.svg';
import heartOutline from '../images/heartOutline.svg';
// Analytics
import * as Analytics from '../../common/analytics';
// Selectors
import {
  loggedInSelector,
  userWishlistItemSelector,
  userWishlistSelector,
  wishlistLoadingSelector,
} from '../selectors';
// Actions
import {
  deleteWishListItemByItemId,
  getWishlist,
  postUserWishListItem,
} from '../actions';

type OwnProps = {
  variantId: string,
};

type Props = {
  isFavorite: boolean,
  handleFavoriteItem: (
    variantId: number,
    quantity: number,
    listId: number,
  ) => void,
  handleUnfavoriteItem: (variantId: number) => void,
  item: string,
  variantId: number,
  defaultQuantity: number,
  listId: number,
  wishlistItemId: number,
  wishlistLoading: boolean,
  loggedIn: boolean,
};

const FavoriteButton = ({
  isFavorite = false,
  handleFavoriteItem,
  handleUnfavoriteItem,
  variantId,
  defaultQuantity,
  wishlistLoading,
  wishlistItemId,
  loggedIn,
  item,
}: Props) => {
  const onFavorite = () => {
    if (isFavorite) {
      Analytics.logUserTappedUnFavoriteProduct(variantId, item);
      return handleUnfavoriteItem(wishlistItemId);
    }
    Analytics.logUserTappedFavoriteProduct(variantId, item);
    handleFavoriteItem(variantId, defaultQuantity);
  };

  if (!loggedIn) return null;
  return (
    <button
      disabled={wishlistLoading}
      className={`heart-button ${isFavorite ? 'is-favorite' : ''}`}
      onClick={onFavorite}
    >
      <span role="img" aria-label="heart">
        {isFavorite ? (
          <img src={heart} alt="coração favoritado" />
        ) : (
          <img src={heartOutline} alt="coração não favoritado" />
        )}
      </span>
    </button>
  );
};

const mapStateToProps = (state, ownProps: OwnProps) => {
  const wishlist = userWishlistSelector(state);
  const listId = wishlist?.id;
  const wishlistItem = userWishlistItemSelector(state, ownProps.variantId);
  const isFavorite = !!wishlistItem;
  const wishlistItemId = wishlistItem?.id;
  return {
    isFavorite,
    listId,
    wishlistLoading: wishlistLoadingSelector(state),
    wishlistItemId,
    loggedIn: loggedInSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  return {
    handleFavoriteItem: async (variantId: number, quantity: number) => {
      await dispatch(postUserWishListItem(variantId, quantity));
      dispatch(getWishlist());
    },
    handleUnfavoriteItem: async (variantId: number) => {
      await dispatch(deleteWishListItemByItemId(variantId));
      dispatch(getWishlist());
    },
  };
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default connector(FavoriteButton);
