// @flow
import { createAction } from 'redux-actions';
// API
import Order from '../api';
// Selectors
import {
  itemSelectorByProductId,
  orderNumberSelector,
  shipmentIdSelector,
} from '../selectors';
// Types
import type { CreditCard, Dispatch, PaymentSource, State } from '../../types';
import type { UserAddress } from '../../user';
// Logger
import { logAPIException } from '../../logHelper';

/**********************
 *** FIND THE ORDER ***
 **********************/

export const fetchOrder = createAction('FETCH_ORDER');
export const fetchOrderRequest = createAction('FETCH_ORDER_REQUEST');
export const newOrderRequest = createAction('NEW_ORDER_REQUEST');

export function findOrCreateOrder() {
  return async (dispatch: Dispatch) => {
    dispatch(fetchOrderRequest());
    // Fallback is to create a new order
    try {
      // First, try to get the most recent order from the authenticated user
      try {
        const activeOrder = await Order.activeCart();
        dispatch(fetchOrder(activeOrder));
      } catch (err) {
        // If auth user does not have order, check if there's a stored guest order
        if (err.message === Order.errors.NOT_LOGGED_IN) {
          const activeGuestOrder = await Order.activeGuestCart();
          dispatch(fetchOrder(activeGuestOrder));
        } else {
          dispatch(fetchOrder(err));
          logAPIException(err);
        }
      }
    } catch (err) {
      if (err.message === Order.errors.NO_ACTIVE_CART) {
        dispatch(newOrderRequest());
        const newOrder = await Order.create();
        dispatch(fetchOrder(newOrder));
      } else {
        dispatch(fetchOrder(err));
        logAPIException(err);
      }
    }
  };
}

export function findCheckoutOrder() {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(fetchOrderRequest());
      const activeOrder = await Order.activeCart();
      dispatch(fetchOrder(activeOrder));
    } catch (err) {
      logAPIException(err);
      dispatch(fetchOrder(err));
    }
  };
}
/**********************
 *** FIND THE COUPON CODES BASED ON USER ***
 **********************/

export const fetchPromotionsByUser = createAction('FETCH_PROMOTIONS_BY_USER');
export const fetchPromotionsByUserRequest = createAction(
  'FETCH_PROMOTIONS_BY_USER_REQUEST',
);

export function findPromotionsByUser(userId: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(fetchPromotionsByUserRequest());
      const response = await Order.getPromotionsByUser(userId);
      dispatch(fetchPromotionsByUser(response));
    } catch (err) {
      logAPIException(err);
      dispatch(fetchPromotionsByUser(err));
    }
  };
}

/******************
 *** CART ITEMS ***
 ******************/
// Add item
export const addItemRequest = createAction('ADD_ITEM_REQUEST');
export const addItem = createAction('ADD_ITEM');

export function addItemToCart(productId: number, quantity: number) {
  return async (dispatch: Dispatch, getState: () => State) => {
    try {
      dispatch(addItemRequest({ productId }));

      const orderNumber = orderNumberSelector(getState());
      const response = await Order.createLineItem(
        orderNumber,
        productId,
        quantity,
      );
      dispatch(addItem({ ...response, productId, orderNumber }));
      // dispatch(findOrCreateOrder());
    } catch (err) {
      logAPIException(err);
      dispatch(addItem(err));
    }
  };
}

// Change item quantity optimistically
export const changeItemQuantityRequest = createAction(
  'CHANGE_ITEM_QUANTITY_REQUEST',
  itemId => ({ itemId }),
);
export const changeItemQuantity = createAction(
  'CHANGE_ITEM_QUANTITY',
  (itemId, quantity) => ({ itemId, quantity }),
  (itemId, quantity, originalQuantity, isOptimistic) => ({
    originalQuantity,
    isOptimistic: isOptimistic || false,
  }),
);
export const removeItemRequest = createAction(
  'REMOVE_ITEM_REQUEST',
  itemId => ({ itemId }),
);
export const removeItem = createAction('REMOVE_ITEM');

// Stores data required for optimistic loading and reverting on error
const optimisticData = {};
export function changeQuantity(
  productId: number,
  increaseBy: number,
  observation: string,
) {
  return (dispatch: Dispatch, getStore: () => State) => {
    // Get the order number, item and its original quantity
    const orderNumber = orderNumberSelector(getStore());
    const item = itemSelectorByProductId(getStore(), productId);
    const minimumQuantity = item.variant.minimumQuantity;
    // Clear debounce for current lineItem
    if (optimisticData[item.id]) {
      clearTimeout(optimisticData[item.id].debouncedIncreaseClick);
    } else {
      // If we don't have optimisticData for this line item create object
      optimisticData[item.id] = {
        debouncedIncreaseClick: null,
        originalQuantity: null,
      };
    }
    dispatch(changeItemQuantityRequest(item.id)); // Trigger loading state
    // Store away original quantity in case we get an error anywhere
    if (optimisticData[item.id].originalQuantity == null) {
      optimisticData[item.id].originalQuantity = item.quantity;
    }
    const nextQuantity = item.quantity + increaseBy;
    // Update reducer optimistically if we are not deleting
    if (nextQuantity >= minimumQuantity) {
      dispatch(
        changeItemQuantity(
          item.id,
          nextQuantity,
          optimisticData[item.id].originalQuantity,
          true,
        ),
      ); // True since this one is optimistic
    }
    // Set timeout to update via api
    optimisticData[item.id].debouncedIncreaseClick = setTimeout(async () => {
      try {
        if (nextQuantity < minimumQuantity) {
          // Remove item if the quantity is below minimum
          await Order.deleteLineItem(orderNumber, item.id);
          dispatch(removeItem({ orderNumber, itemId: item.id }));
        } else {
          const response = await Order.updateLineItem(
            orderNumber,
            item.id,
            nextQuantity,
            observation,
          );
          const nextLineItem = response.entities.lineItems[response.result];
          dispatch(
            changeItemQuantity(
              nextLineItem.id,
              nextLineItem.quantity,
              optimisticData[item.id].originalQuantity,
            ),
          );
        }
      } catch (err) {
        // TODO: This error should set the product quantity back to the original. Implement in reducer
        logAPIException(err, {
          originalQuantity: optimisticData[item.id].originalQuantity,
        });
        dispatch(
          changeItemQuantity(err, optimisticData[item.id].originalQuantity),
        );
      } finally {
        optimisticData[item.id].originalQuantity = null;
        // TODO: Is this the best place to sync the order? Similarly done in deleteItem
        dispatch(findOrCreateOrder()); // Update the entire order to make sure application is up to date
      }
    }, 700);
  };
}

export function deleteItem(lineItemId: number) {
  return async (dispatch: Dispatch, getStore: () => State) => {
    const orderNumber = orderNumberSelector(getStore());
    dispatch(removeItemRequest(lineItemId));
    try {
      await Order.deleteLineItem(orderNumber, lineItemId);
      dispatch(removeItem({ orderNumber, itemId: lineItemId }));
    } catch (err) {
      logAPIException(err);
      dispatch(removeItem(err));
    } finally {
      // Make sure that the order state is synced
      dispatch(findOrCreateOrder()); // Update the entire order to make sure application is up to date
    }
  };
}
//Change Observation
export const updateLineItem = createAction('UPDATE_LINE_ITEM');

export const updateLineItemRequest = createAction('UPDATE_LINE_ITEM_REQUEST');

export function changeLineItem(
  productId: number,
  observation: string,
  portioning: number,
) {
  return async (dispatch: Dispatch, getState: () => State) => {
    try {
      dispatch(updateLineItemRequest({ productId, observation, portioning }));
      const orderNumber = orderNumberSelector(getState());
      const response = await Order.updateLineItem(
        orderNumber,
        productId,
        null,
        observation,
        portioning,
      );
      dispatch(updateLineItem({ ...response, productId, orderNumber }));
    } catch (err) {
      logAPIException(err);
      dispatch(updateLineItem(err));
    }
  };
}

/*************************
 *** TRASITION TO STATE  ***
 *************************/

export const transitionToStateRequest = createAction(
  'TRANSITION_TO_STATE_REQUEST',
);
export const transitionToStateSuccess = createAction(
  'TRANSITION_TO_STATE_SUCCESS',
  response => response,
);
export const transitionToStateFailure = createAction(
  'TRANSITION_TO_STATE_FAILURE',
  err => err,
);

export function transitionToState(nextState: string) {
  return async (dispatch: ThDispatch, getState: () => State) => {
    dispatch(transitionToStateRequest());
    try {
      const orderNumber = orderNumberSelector(getState());

      if (!orderNumber) {
        throw new Error(
          '{"error": "Não foi possível carregar o pedido.", "errors":{"base": "[]"}}',
        );
      }

      const response = await Order.transitionToState(orderNumber, nextState);
      dispatch(transitionToStateSuccess(response));
    } catch (err) {
      dispatch(transitionToStateFailure(err));
    }
  };
}

/*************************
 *** TRASITION STEP TO STATE ***
 *************************/

export const transitionStepToStateRequest = createAction(
  'TRANSITION_STEP_TO_STATE_REQUEST',
);
export const transitionStepToStateSuccess = createAction(
  'TRANSITION_STEP_TO_STATE_SUCCESS',
  response => response,
);
export const transitionStepToStateFailure = createAction(
  'TRANSITION_STEP_TO_STATE_FAILURE',
  err => err,
);

export function transitionStepToState(targetState: string) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(transitionStepToStateRequest());
    try {
      const orderNumber = orderNumberSelector(getState());

      if (!orderNumber) {
        throw new Error(
          '{"error": "Não foi possível carregar o pedido.", "errors":{"base": "[]"}}',
        );
      }

      const response = await Order.transitionStepToState(
        orderNumber,
        targetState,
      );
      dispatch(transitionStepToStateSuccess(response));
    } catch (err) {
      logAPIException(err);
      dispatch(transitionStepToStateFailure(err));
      dispatch(findOrCreateOrder());
    }
  };
}

/*************************
 *** INITIATE CHECKOUT ***
 *************************/

export const transitionToAddressRequest = createAction(
  'TRANSITION_TO_ADDRESS_REQUEST',
);

export const transitionToAddress = createAction('TRANSITION_TO_ADDRESS');

export function initiateCheckout() {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(transitionToAddressRequest());
    try {
      const orderNumber = orderNumberSelector(getState());
      if (orderNumber === '') {
        throw new Error('Invalid order');
      }
      const response = await Order.addressState(orderNumber);
      dispatch(transitionToAddress(response));
    } catch (err) {
      logAPIException(err);
      dispatch(transitionToAddress(err));
    }
  };
}

/*********************
 *** ADDRESS STATE ***
 *********************/
export const transitionToDeliveryRequest = createAction(
  'TRANSITION_TO_DELIVERY_REQUEST',
);

export const transitionToDelivery = createAction('TRANSITION_TO_DELIVERY');

export function submitAddress(shipAddress: UserAddress) {
  return async (dispatch: Dispatch, getState: Function) => {
    dispatch(transitionToDeliveryRequest());
    const orderNumber = orderNumberSelector(getState());
    const billAddress = shipAddress;
    try {
      const response = await Order.addAddresses(
        orderNumber,
        billAddress,
        shipAddress,
      );
      dispatch(transitionToDelivery(response));
    } catch (err) {
      logAPIException(err);
      dispatch(transitionToDelivery(err));
    }
  };
}

/**********************
 *** DELIVERY STATE ***
 **********************/
export const transitionToPaymentRequest = createAction(
  'TRANSITION_TO_PAYMENT_REQUEST',
);
export const transitionToPayment = createAction('TRANSITION_TO_PAYMENT');

export function submitDelivery(shippingRate: ShippingRate) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(transitionToPaymentRequest());
    try {
      const orderNumber = orderNumberSelector(getState());
      const shipmentId = shipmentIdSelector(getState());
      const response = await Order.selectShipping(
        orderNumber,
        shippingRate.id,
        shipmentId,
      );
      dispatch(transitionToPayment(response));
    } catch (err) {
      logAPIException(err);
      dispatch(transitionToPayment(err));
    }
  };
}

/*********************
 *** PAYMENT STATE ***
 *********************/

const fetchCreditCardsRequest = createAction('FETCH_CREDIT_CARDS_REQUEST');
const fetchCreditCards = createAction('FETCH_CREDIT_CARDS');

export function loadCreditCards() {
  return async (dispatch: Dispatch) => {
    dispatch(fetchCreditCardsRequest());
    try {
      const response = await Order.userCreditCards();
      dispatch(fetchCreditCards(response));
    } catch (err) {
      logAPIException(err);
      dispatch(fetchCreditCards(err));
    }
  };
}

export const transitionToConfirmationRequest = createAction(
  'TRANSITION_TO_CONFIRMATION_REQUEST',
);

export const transitionToConfirmation = createAction(
  'TRANSITION_TO_CONFIRMATION',
);

export function submitPayment(
  paymentMethod: PaymentMethod,
  paymentSource: CreditCard | PaymentSource,
  existingCard: boolean,
  bigClubCreditAmount: number,
) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(transitionToConfirmationRequest());
    try {
      const orderNumber = orderNumberSelector(getState());
      let response;
      if (existingCard) {
        response = await Order.existingCard(
          orderNumber,
          paymentSource.id,
          bigClubCreditAmount,
        );
      } else {
        response = await Order.newPayment(
          orderNumber,
          paymentMethod.id,
          paymentSource,
          bigClubCreditAmount,
        );
      }
      dispatch(transitionToConfirmation(response));
    } catch (err) {
      logAPIException(err);
      dispatch(transitionToConfirmation(err));
    }
  };
}

/*********************
 *** CONFIRM STATE ***
 *********************/

export const transitionToCompleteRequest = createAction(
  'TRANSITION_TO_COMPLETE_REQUEST',
);

export const transitionToComplete = createAction('TRANSITION_TO_COMPLETE');

export function submitConfirmation() {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(transitionToConfirmationRequest());
    try {
      const orderNumber = orderNumberSelector(getState());
      const response = await Order.nextState(orderNumber);
      dispatch(transitionToComplete(response));
    } catch (err) {
      logAPIException(err);
      dispatch(transitionToComplete(err));
    }
  };
}

/*********************
 *** PAYMENT/PROMOTION STATE ***
 *********************/

export const transitionFromPromotionToPaymentRequest = createAction(
  'TRANSITION_FROM_PROMOTION_TO_PAYMENT_REQUEST',
);

export const transitionFromPromotionToPayment = createAction(
  'TRANSITION_FROM_PROMOTION_TO_PAYMENT',
);

export function submitPromotion(couponCode: string) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(transitionFromPromotionToPaymentRequest());
    try {
      const orderNumber = orderNumberSelector(getState());
      const response = await Order.newPromotion(orderNumber, couponCode);
      dispatch(transitionFromPromotionToPayment(response));
    } catch (err) {
      logAPIException(err);
      dispatch(transitionFromPromotionToPayment(err));
    }
  };
}

/**
 * ERROR HANDLING
 */
export const dismissErrors = createAction('DISMISS_ERRORS');

/******************
 *** COPY ORDER ***
 ******************/
export const copyFromOrderToOrderRequest = createAction(
  'COPY_FROM_ORDER_TO_ORDER_REQUEST',
);
export const copyFromOrderToOrder = createAction('COPY_FROM_ORDER_TO_ORDER');

export function copyingOrder(orderNumber: string, orderNumberFrom: string) {
  return async (dispatch: Dispatch) => {
    dispatch(copyFromOrderToOrderRequest());
    try {
      const response = await Order.copyOrder(orderNumber, orderNumberFrom);
      dispatch(copyFromOrderToOrder(response));
    } catch (err) {
      logAPIException(err);
      dispatch(copyFromOrderToOrder(err));
    }
  };
}
