//@flow
import React, { useEffect } from 'react';
// Container
import ComplementaryCategoryPicker from '../components/ComplementaryCategoryPicker';
// Actions and Selectors
import * as actions from '../actions';
import * as selectors from '../selectors';
// Redux related
import { connect } from 'react-redux';
// Styles
import './ComplementaryCategoryPickerList.css';
type Props = {
  fetchBarbecueCategories: () => void,
  isLoading: boolean,
  barbecueCategories: any,
  // Local props
  handleClick: () => void,
  selectedComplementaryCategories: Array<number>,
};
const ComplementaryCategoryPickerList = ({
  fetchBarbecueCategories,
  isLoading,
  barbecueCategories,
  // Local props
  selectedComplementaryCategories,
  handleClick,
}: Props) => {
  useEffect(() => {
    fetchBarbecueCategories();
  }, []);

  if (isLoading) {
    return (
      <div className="accompaniment-list-loading">
        <i
          className="fa fa-spinner fa-pulse fa-4x loading-icon"
          aria-hidden="true"
        />
      </div>
    );
  }
  return (
    <div className="accompaniments-list-container">
      {barbecueCategories.map(category => (
        <ComplementaryCategoryPicker
          key={category.id}
          id={category.id}
          name={category.name}
          isSelected={
            selectedComplementaryCategories.indexOf(category.id) !== -1
          }
          handleClick={handleClick}
        />
      ))}
    </div>
  );
};
const mapStateToProps = state => {
  return {
    barbecueCategories:
      selectors.barbecueComplementaryCategoriesSelector(state),
    isLoading: selectors.complementaryCategoriesLoadingSelector(state),
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchBarbecueCategories: () => {
      dispatch(actions.fetchBarbecueCategories());
    },
  };
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default connector(ComplementaryCategoryPickerList);
