// @flow
import React, { useEffect, useState } from 'react';
//Helpers
import { useScreenTimeLogger } from '../../common';
// Styles
import './BigClub.css';
// Components
import { Link } from 'react-router-dom';
import LandingFAQ from '../components/LandingFAQ';
// Data
import bigclubData from '../data/bigClubData';
// images
import bigclubCashback from '../images/bigclubCashback.png';
import bigclubInvite from '../images/bigclubInvite.svg';
import bigclubMoreThanOneMillion from '../images/bigclubMoreThanOneMillion.svg';
import salesApp from '../images/bigclubSalesApp.png';
import bigclubFidelityBannerAlt from '../images/bigclubFidelityBanner.png';
import bigclubMoreThanOneMillionAlt from '../images/bigclubMoreThanOneMillionAlt.png';
import bigclubFidelityBanner from '../images/bigclubFidelityBanner.svg';
import bigclubLine from '../images/bigclubLine.svg';
import bigclubCashbackCard from '../images/bigclubCashbackCard.svg';
// Analytics
import * as Analytics from '../../common/analytics';
// Redux
import { connect } from 'react-redux';
// Selectors
import { bigClubSettingsSelector } from '../../preferences/selectors';
import { userIdSelector } from '../../user/selectors';
// Types
import { BigClubSettings } from '../../preferences/types';

const milisecondsSinceFirstDay =
  new Date().getTime() - new Date('05/01/2023').getTime();
const milisecondsPerDay = 86400000;
const averageUsersPerDay = 50;
const startCountUp =
  20000 + (milisecondsSinceFirstDay / milisecondsPerDay) * averageUsersPerDay;

type Props = {
  bigClubSettings: BigClubSettings,
  userId: number,
};

const BigClub = (props: Props) => {
  useScreenTimeLogger(props.location.pathname);
  const [usersQuantity, setUsersQuantity] = useState(startCountUp);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (usersQuantity < 60000) setUsersQuantity(usersQuantity + 1);
    }, Math.random() * 10000);
    return () => {
      clearTimeout(timeout);
    };
  }, [usersQuantity]);

  useEffect(() => {
    Analytics.logUserOpenedBigClubLandingPage();
  }, []);

  const cashbackMinOrderPriceToEarn = Number(
    props.bigClubSettings?.cashback?.cashbackMinOrderPriceToEarn,
  ).toFixed();

  const cashbackEarnPercentage = Number(
    props.bigClubSettings?.cashback?.cashbackEarnPercentage,
  ).toFixed();

  return (
    <div className="bigclub-wrapper">
      <section className="bigclub-main bigclub-gap">
        <div className="bigclub-main-content">
          <div className="bigclub-main-content-text">
            <div className="bigclub-main-content-text-principal">
              <h1>Economize</h1>
              <img src={bigclubLine} alt="Linha horizontal" />
            </div>
            <h2>ainda mais</h2>
            <h3>
              <b>com o Big</b>Club!
            </h3>
          </div>

          <h2>Comece a aproveitar os benefícios agora!</h2>
          {!props.userId && (
            <div className="bigclub-main-content-info">
              <Link
                className="bigclub-button"
                to={{ pathname: '/login', state: { registering: true } }}
              >
                Cadastre-se gratuitamente
              </Link>

              <p>
                Junte-se às {usersQuantity.toFixed()} pessoas que fazem parte do
                BigDelivery
              </p>
            </div>
          )}
        </div>
        <div className="bigclub-main-content-alt">
          <a href="#bigclub-announcement">
            <img
              src={salesApp}
              className="bigclub-main-content-secondary-img"
              alt="Aplicativo BigDelivery"
            />
          </a>
        </div>
        <div className="bigclub-main-content-secondary">
          <img
            src={salesApp}
            className="bigclub-main-content-secondary-img"
            alt="Aplicativo BigDelivery"
          />
          <a href="#bigclub-announcement">
            <img
              src={bigclubFidelityBannerAlt}
              className="bigclub-main-content-secondary-banner"
              alt="Bigclub o nosso programa de fidelidade"
            />
          </a>
        </div>
      </section>
      <img
        className="bigclub-fidelity-banner"
        src={bigclubFidelityBanner}
        alt="BigClub o nosso programa de fidelidade"
      />
      <section className="bigclub-benefits bigclub-gap">
        {bigclubData.benefits.map((benefit, index) => (
          <div className="bigclub-benefit" key={index}>
            <img src={benefit.img} alt={benefit.title} />
            <div className="bigclub-benefit-content">
              <h3>{benefit.title}</h3>
              <p>{benefit.text}</p>
            </div>
          </div>
        ))}
      </section>
      <div className="bigclub-bg-grey ">
        <section className="bigclub-cashback bigclub-gap">
          <div className="bigclub-cashback-content">
            <h2 className="bigclub-cashback-title">
              Acumule $ com nosso <b>Cashback!</b>
            </h2>

            <p className="bigclub-cashback-text">
              Faça compras acima de R$
              {cashbackMinOrderPriceToEarn}{' '}
              <b>e ganhe {cashbackEarnPercentage}% de cashback</b>. Você decide
              quando usar esse <b>benefício exclusivo</b> em suas próximas
              compras.
            </p>
          </div>
          <a href="#bigclub-announcement">
            <img
              className="bigclub-cashback-image"
              src={bigclubCashback}
              alt="BigClub acumule cashback"
            />
          </a>
        </section>
        <section className="bigclub-invite  bigclub-gap">
          <a href="#bigclub-announcement">
            <img
              className="bigclub-invite-img"
              src={bigclubInvite}
              alt="Convide um amigo e ganhe R$ 10"
            />
          </a>
          <div>
            <h2 className="bigclub-invite-title">
              Convide um amigo e <p>ganhe R$ 10</p>
            </h2>
            <p className="bigclub-invite-text">
              A cada amigo convidado que{' '}
              <b>criar uma conta usando o seu código</b> e receber o{' '}
              <b>primeiro pedido.</b>
            </p>
          </div>
        </section>
        <section className="bigclub-promotion">
          <div className="bigclub-promotion-content">
            <h3 className="bigclub-promotion-title">
              Fique por dentro das <p>promoções semanais</p>
            </h3>
            <p className="bigclub-promotion-text">
              Não perca as nossas <b>promoções semanais</b> repletas de{' '}
              <b>descontos incríveis</b> em muitos produtos.{' '}
              <b>Mantenha-se atualizado e economize</b> como nunca antes!
            </p>
          </div>
          <a href="#bigclub-announcement">
            <img
              src={bigclubCashbackCard}
              alt="Ganhe cashback"
              className="bigclub-promotion-img"
            />
          </a>
        </section>
      </div>
      <a href="#bigclub-announcement">
        <img
          className="bigclub-banner-shipping"
          src={bigclubMoreThanOneMillion}
          alt="Mais de  1 milhão de pedidos entregues"
        />
      </a>
      <a href="#bigclub-announcement">
        <img
          className="bigclub-banner-shipping-alt"
          src={bigclubMoreThanOneMillionAlt}
          alt="Mais de  1 milhão de pedidos entregues"
        />
      </a>
      <h2 className="bigclub-faq-title">
        Dúvidas <span>frequentes</span>
      </h2>
      <LandingFAQ
        faqQuestions={bigclubData.faqQuestions}
        currentLanding="barbecue"
      />
      <section id="bigclub-announcement" className="bigclub-announcement">
        <p className="bigclub-announcement-text">
          Não perca tempo, aproveite o lançamento do BigClub e comece a
          economizar ainda mais!
        </p>
        {!props.userId && (
          <div className="bigclub-announcement-actions">
            <Link
              className="bigclub-button"
              to={{ pathname: '/login', state: { registering: true } }}
            >
              Cadastre-se gratuitamente
            </Link>
            <Link className="bigclub-button-outline" to="/login">
              Entrar na conta
            </Link>
          </div>
        )}
      </section>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    bigClubSettings: bigClubSettingsSelector(state),
    userId: userIdSelector(state),
  };
};

const connector: Connector<RouterProps, ReduxProps> = connect(mapStateToProps);
export default connector(BigClub);
