const selfCareData = {
  bannerRichText: 'COZINHAR É UMA FORMA DE <span>AUTOCUIDADO</span>',
  faqQuestions: [
    {
      title: 'Como o Bigdelivery pode me ajudar com o autocuidado?',
      body: 'Receber em casa e no seu melhor horário as suas carnes limpas e cortadas,  pode evitar o estresse que você, provavelmente, teria ao ir a um açougue, enfrentar filas e ainda ter que limpá-las. Com o Bigdelivery, sobra mais tempo para você dedicar a si.',
      isOpen: false,
    },
    {
      title: 'Vou ter alguma preocupação comprando com o Bigdelivery?',
      body: 'Não mesmo! Todo o nosso processo é otimizado para que a sua experiência de compra seja a mais tranquila possível e a facilidade esteja em cada etapa.',
      isOpen: false,
    },
    {
      title: 'Não sei cozinhar bem! Ainda assim vale fazer um pedido?',
      body: 'Sim, não se cobre tanto! Encare esse momento como algo leve, de aprendizado e conte com as diversas dicas que trazemos para você em nosso Blog.',
      isOpen: false,
    },
    {
      title:
        'Tenho algumas dúvidas mais específicas. Há alguém para me ajudar?',
      body: 'Com certeza! O nosso call center está à sua disposição para te ajudar com qualquer questão que você tiver.',
      isOpen: false,
    },
  ],
};
export default selfCareData;
