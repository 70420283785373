// Styles
import './BlackFridayFrequentlyQuestions.css';
// Components
import BlackFridayQuestionsAndAnswers from './BlackFridayQuestionsAndAnswers.js';
// Data
import {blackFridayData} from '../data/blackFridayData.js';

const BlackFridayFrequentlyQuestions = () => {
  return (
    <div className="black-friday-questions-main-container">
      <h1 className="black-friday-quetions-text">
        Dúvidas{' '}
        <span className="black-friday-questions-orange-text">frequentes</span>
      </h1>

      {blackFridayData.map((faq, index) => (
        <BlackFridayQuestionsAndAnswers
          key={index}
          question={faq.question}
          answer={faq.answer}
        />
      ))}
    </div>
  );
};

export default BlackFridayFrequentlyQuestions;
