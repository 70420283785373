// @flow
import _ from 'lodash';
import { createSelector } from 'reselect';
import type { Order, ShippingRate } from '../../types';
import type { StoreState } from '../types';

export const localStateSelector = (state: StoreState): Order => {
  return state.order;
};

// The current order
export const currentOrderSelector = createSelector(localStateSelector, state =>
  state.current ? state.orders[state.current] : null,
);

// Related to the line items
export const lineItemMapSelector = createSelector(
  localStateSelector,
  state => state.lineItems,
);

export const promotionSelector = createSelector(
  localStateSelector,
  state => state.promotions,
);

////////////////// LINE ITEMS ///////////////////
export const allItemsSelector = createSelector(
  currentOrderSelector,
  lineItemMapSelector,
  (order: Order, lineItemMap) =>
    order
      ? _.without(
          order.lineItems.map(i => lineItemMap[i]),
          undefined,
        )
      : [],
);

export const itemsSelector = createSelector(
  currentOrderSelector,
  lineItemMapSelector,
  (order: Order, lineItemMap) =>
    // TODO: Clean up this so that we no longer need the _.without. It is currently needed because the lineItems are deleted on the removeItem action but the keys in the order are not
    order
      ? _.filter(
          _.without(
            order.lineItems.map(i => lineItemMap[i]),
            undefined,
          ),
          lineItem => !lineItem.mixId,
        )
      : [],
);
export const itemsWithBlendsSelector = createSelector(
  currentOrderSelector,
  lineItemMapSelector,
  (order: Order, lineItemMap) =>
    // TODO: Clean up this so that we no longer need the _.without. It is currently needed because the lineItems are deleted on the removeItem action but the keys in the order are not
    order
      ? _.filter(
          _.without(
            order.lineItems.map(i => lineItemMap[i]),
            undefined,
          ),
          lineItem => lineItem.mixId,
        )
      : [],
);
export const itemSelectorByProductId = (state: any, productId: number) => {
  const item = _.find(itemsSelector(state), { variantId: productId });
  return item;
};

export const itemCountSelector = createSelector(
  itemsSelector,
  itemsWithBlendsSelector,
  (items, itemsWithMixId) => {
    // We should consider only one line item per mix_id
    const uniqItemsByMixId = _.uniqBy(itemsWithMixId, 'mixId');

    return items.length + uniqItemsByMixId.length;
  },
);

export const itemTotalSumSelector = createSelector(
  itemsSelector,
  itemsWithBlendsSelector,
  (items, itemsWithMixId) => {
    return (
      items.reduce((total, item) => total + parseFloat(item.total), 0) +
      itemsWithMixId.reduce(
        (total, blend) => total + parseFloat(blend.total),
        0,
      )
    );
  },
);

export const lineItemTotalByUnitSelector = (state: any, unit: String) => {
  const items = allItemsSelector(state);
  return items
    .filter(item => item.variant.unitForQuantity === unit)
    .reduce((quantity, item) => quantity + parseFloat(item.quantity), 0);
};

// Related to order totals: Item, total, shipping
export const itemSubtotalSelector = createSelector(
  currentOrderSelector,
  order => (order ? order.itemTotal : ''),
);

export const totalSelector = createSelector(currentOrderSelector, order =>
  order ? order.total : '',
);

export const shippingSubtotalSelector = createSelector(
  currentOrderSelector,
  order => (order ? order.shipTotal : ''),
);

export const orderStateSelector = createSelector(currentOrderSelector, order =>
  order ? order.state : '',
);

export const orderNumberSelector = createSelector(
  localStateSelector,
  state => state.current,
);

export const adjustmentTotalSelector = createSelector(
  currentOrderSelector,
  //TODO: Change the Adjustment Total to Sum of all Adjustments
  order => (order ? order.adjustmentTotal : ''),
);

export const availableStoreCreditsSelector = createSelector(
  currentOrderSelector,
  order => (order ? order.availableStoreCredits : ''),
);

export const appliedStoreCreditsSelector = createSelector(
  currentOrderSelector,
  order => (order ? order.appliedStoreCredits : ''),
);

// Related to shipping. Used during checkout
export const shippingRateMapSelector = createSelector(
  localStateSelector,
  state => state.shippingRates,
);

export const shippingMethodMapSelector = createSelector(
  localStateSelector,
  state => state.shippingMethods,
);

export const shipmentIdSelector = createSelector(currentOrderSelector, order =>
  _.head(order.shipments),
);

export const shipmentSelector = createSelector(
  localStateSelector,
  currentOrderSelector,
  (state, order) => {
    return order && state
      ? _.head(order.shipments.map(id => state.shipments[id]))
      : null;
  },
);

export const shippingRatesSelector = createSelector(
  shipmentSelector,
  shippingRateMapSelector,
  shippingMethodMapSelector,
  (shipment, rates, methods) => {
    // Get shipping rates that belong to this order
    if (
      !shipment ||
      !shipment.shippingRates ||
      !Array.isArray(shipment.shippingRates)
    )
      return [];
    const shippingRates = shipment.shippingRates.map(id => rates[id]);
    // Enhance shipping rate objects with shipping method
    const shippingRatesWithMethod: ShippingRate = shippingRates.map(rate => ({
      ...rate,
      shippingMethod: methods[rate.shippingMethodId],
    }));
    return shippingRatesWithMethod;
  },
);

// Related to the current order
export const currentOrderShippingAddressSelector = createSelector(
  currentOrderSelector,
  (order: Order) => (order ? order.shipAddress : null),
);

export const currentOrderBigClubInfoSelector = createSelector(
  currentOrderSelector,
  (order: Order) => (order ? order.bigclub : null),
);

export const currentOrderDeliverySelector = createSelector(
  shipmentSelector,
  shippingRatesSelector,
  (shipment, rates) =>
    shipment ? _.find(rates, { id: shipment.selectedShippingRate }) : null,
);

// TODO: Currently we select the payment with largest ID. Is this reliable?
export const currentOrderPaymentSelector = createSelector(
  currentOrderSelector,
  (order: Order) => (order ? order.payments[order.payments.length - 1] : null),
);

// Related to payments. Used during checkout.
export const paymentMethodsSelector = createSelector(
  localStateSelector,
  currentOrderSelector,
  (state, order) =>
    order && order.paymentMethods
      ? order.paymentMethods.map(id => state.paymentMethods[id])
      : [],
);

export const creditCardsSelector = createSelector(
  currentOrderSelector,
  order => [],
);

// Related to Promotions
export const allPromotionsSelector = createSelector(
  promotionSelector,
  promotions => {
    if (_.isEmpty(promotions)) return [];
    return _.values(promotions);
  },
);

//////////////////////// LOADING ////////////////////
export const changingItemsLoadingSelector = createSelector(
  localStateSelector,
  state => state.changingItemsLoading,
);

// We are allowing this selector to receive null so that it can be used in the mapStateToProps where the item may be undefined
export const changingItemLoadingSelector = (state: any, itemId: ?number) => {
  const index = changingItemsLoadingSelector(state).indexOf(itemId);
  return index > -1;
};

export const editingItemsLoadingSelector = createSelector(
  localStateSelector,
  state => state.editingItemsLoading,
);

export const editingItemLoadingByIdSelector = (
  state: any,
  productId: number,
) => {
  const index = editingItemsLoadingSelector(state).indexOf(productId);
  return index > -1;
};

export const addingItemsLoadingSelector = createSelector(
  localStateSelector,
  state => state.addingItemsLoading,
);

// Note that this selector uses a product ID. Whilst the changing selector uses the itemId.
export const addingItemLoadingSelector = (state: any, productId: number) => {
  const index = addingItemsLoadingSelector(state).indexOf(productId);
  return index > -1;
};

export const gettingOrderLoadingSelector = createSelector(
  localStateSelector,
  state => state.loading.gettingOrder,
);

export const gettingPromotionsLoadingSelector = createSelector(
  localStateSelector,
  state => state.loading.gettingPromotions,
);

export const initiatingCheckoutLoadingSelector = createSelector(
  localStateSelector,
  state => state.loading.initiatingCheckout,
);

export const transitioningStepToSateLoadingSelector = createSelector(
  localStateSelector,
  state => state.loading.transitioningStepToState,
);

export const transitioningLoadingSelector = createSelector(
  localStateSelector,
  state => state.loading.transitioning,
);

export const copyingOrderLoadingSelector = createSelector(
  localStateSelector,
  state => state.loading.copyingOrder,
);

export const removingItemLoadingSelector = createSelector(
  localStateSelector,
  state => state.loading.removingItem,
);

/////////////////// ERRORS ///////////////////////
export const generalErrorsSelector = createSelector(
  localStateSelector,
  localState => localState.errors.general,
);

export const addressErrorsSelector = createSelector(
  localStateSelector,
  localState => localState.errors.address,
);

export const deliveryErrorsSelector = createSelector(
  localStateSelector,
  localState => localState.errors.delivery,
);

export const paymentErrorsSelector = createSelector(
  localStateSelector,
  localState => localState.errors.payment,
);

export const promotionErrorsSelector = createSelector(
  localStateSelector,
  localState => localState.errors.promotion,
);
