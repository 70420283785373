// @flow
import React from 'react';
// Components
import { Link } from 'react-router-dom';
// Styles
import './EmptyList.css';
// Images
import notFound from '../../images/notFound.svg';
import arrowRight from '../../images/arrowRight.svg';
import plusIcon from '../../images/plusIcon.svg';

type Props = {
  title: string,
  navigateUrl: string,
  text: string,
  showAction: boolean,
  handleClick: () => void,
};

const EmptyList = ({
  title = 'Ops! parece que você não adicionou nenhum produto a sua lista.',
  navigateUrl = '/busca',
  text = 'Explore nossos produtos',
  showAction = false,
  handleClick,
}: Props) => {
  return (
    <div className="empty-list-wrapper">
      <img alt={title} src={notFound} className="empty-list-image" />
      <h2 className="empty-list-title">{title}</h2>
      {showAction ? (
        <button
          text="Criar lista"
          icon={plusIcon}
          onClick={handleClick}
          className="empty-list-button"
        >
          Criar lista
          <img src={plusIcon} alt={text} className="empty-list-button-icon" />
        </button>
      ) : (
        <Link to={navigateUrl} className="empty-list-button">
          Explore nossos produtos{' '}
          <img src={arrowRight} alt={text} className="empty-list-button-icon" />
        </Link>
      )}
    </div>
  );
};

export default EmptyList;
