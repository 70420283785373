// Other
import { phone } from '../../common';

const faq = [
  {
    question: 'Qual a relação com o Frigorífico do Mané?',
    answer:
      'O Frigorífico do Mané é uma rede com mais de 30 casas de carnes que atende a clientes em Salvador, Lauro de Freitas, Camaçari, Feira de Santana e Alagoinhas. Somos parceiros deles, permitindo que você desfrute do mesmo padrão de qualidade no conforto de sua casa.',
  },
  {
    question: 'Vocês aceitam cartão de débito?',
    answer: `Aceitamos sim, cartão de crédito e débito no momento da entrega com a maquineta do entregador. Caso prefira, podemos autorizar via transferência bancária ou PIX, basta apenas concluir o seu pedido pela central de vendas, através do WhatsApp ${phone.asStringFull}.`,
  },
  {
    question: 'Vocês aceitam voucher?',
    answer: `Aceitamos os vouchers alimentação da Sodexo e VR como pagamento no momento da entrega.`,
  },
  {
    question: 'Vocês aceitam devoluções?',
    answer: `Sim! Possuímos uma política de troca caso algum produto seja enviado fora do padrão, errado, ou diferente do pedido. Basta nos contatar pela central de atendimento telefônico ou WhatsApp ${phone.asStringFull}.`,
  },
  {
    question: 'Vocês aceitam quais formas de pagamento? Fazem parcelamento?',
    answer:
      'Para garantir a segurança de nossos motoristas, não trabalhamos com dinheiro. Em pagamentos online aceitamos cartões de crédito, entre eles Master, Visa, Hiper e American Express. Para pagamentos no momento da entrega, parcelamos no crédito em até 3 vezes sem juros, cartões de débito e os vouchers alimentação Sodexo e VR.',
  },
  {
    question:
      'Não entendi como funciona o pagamento em quilo. Os pesos são exatos?',
    answer:
      'Faremos o possível para que o peso entregue seja igual ao que você pediu. Porém, variações na carne e cortes podem ocasionar em pesos diferentes do selecionado. Caso isso aconteça, você pagará o preço de acordo com o peso da mercadoria que irá receber, e não pelo valor estimado. Ex: Se o pedido foi de 1,5 Kg mas pesamos 1,35 Kg, cobraremos apenas pelos 1,35 Kg. Para compras online, o valor que aparece na fatura é apenas uma pré-captura do limite,  você sempre irá pagar um valor igual ou menor ao mostrado.',
  },
  {
    question: 'Como funciona o pagamento online?',
    answer:
      'Quando você completar a compra será gerada uma "autorização" no seu cartão no valor do seu pedido. Uma vez que a nota fiscal eletrônica for emitida com os seus produtos, iremos "capturar" o valor exato que será sempre igual ou menor que o valor autorizado. Você paga apenas pelo que você recebe e nunca mais do que o valor informado. Note que seu banco pode lhe enviar um SMS com o valor autorizado mas o valor da fatura poderá ser menor.',
  },
  {
    question:
      'Fiz um pedido, gostaria de saber se posso incluir mais produtos ou mudar a data de entrega.',
    answer: `Com certeza! Entre em contato com nossa central pelo WhatsApp ${phone.asStringFull} e faremos as alterações.`,
  },
  {
    question: 'Pode dividir o mesmo produto em vários sacos?',
    answer:
      'Sim! Por padrão dividimos qualquer compra em sacos a partir de 500g e embalagens a vácuo a partir de 1 Kg. Aumentando a praticidade e durabilidade dos produtos. Ex: Se você pedir 12Kg de carne moída e solicitar porcionamento, enviaremos 12 pacotes de 1Kg.',
  },
  {
    question: 'Quais são os horários de entrega e como funcionam?',
    answer:
      'A disponibilidade dos nossos horários depende apenas da demanda agendada previamente. Funcionamos de segunda-feira a sábado. Nas segundas, temos: manhã (7h-11, 8h-13h e 10h-16h) e tarde (13h-18h), enquanto de terça à quinta-feira entregamos nos horários de (7h-11, 8h-13h e 10h-16h) e tarde (13h-18h e 15h-20h). Sexta-feira e sábado, temos horários de manhã (8h-13h e 10h-16h) e tarde (13h-18h e 15h-20h).',
  },
  {
    question: 'Em quais locais vocês entregam?',
    answer:
      'Efetuamos entregas nas cidades de Salvador, Lauro de Freitas e Vila de Abrantes.',
  },
  {
    question: 'Tem alguma promoção? Estou achando produto X muito caro.',
    answer:
      'Sim! Mantemos uma lista de itens em promoção na nossa página principal, no site e aplicativo Big Delivery. Também buscamos trabalhar com preços bastante competitivos. Nossos produtos possuem valor agregado devido a excelente qualidade nos cortes, seguindo todos os padrões higiênico-sanitários estabelecidos pela legislação, além da excelente limpeza que realizamos no produto escolhido e a personalização dos cortes. Poderão ser recebidos em tiras, bifes, para grelha, cubos de diversos tamanhos e ainda porcionados em sacos à vácuo, próprios para armazenamento, o que mantém o sabor e maciez do produto. Além do delivery, sempre mantendo o conforto e a segurança.',
  },
  {
    question: 'Como faço para vender produtos através de seu site?',
    answer:
      'Estamos sempre aumentando a nossa oferta de produtos. Se você é fornecedor ou produtor, entre em contato através do email compras@bigdelivery.com.br para iniciarmos essa parceria.',
  },
  {
    question: 'Porque pede o CPF para se cadastrar no site?',
    answer:
      'Por obrigações legais todos os nossos pedidos geram notas fiscais de venda. Sendo que emitimos notas fiscais eletrônicas (NFe) para translado de mercadoria, não temos a opção de emitir sem CPF.',
  },
  {
    question: 'Vocês vendem para empresas?',
    answer:
      'Sim! Durante o processo de finalização do pedido, ao preencher o endereço de entrega, terá uma caixinha para selecionar caso a venda seja para empresa. Ao selecionar, você poderá colocar o CNPJ e razão social para emissão da nota fiscal.',
  },
];

export default faq;
