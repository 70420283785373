// Assets
import clock from '../images/clock.svg';
import deliveryDining from '../images/delivery_dining.svg';
import lock from '../images/lock.svg';
import trade from '../images/trade.svg';

export const infoCards = [
  {
    title: 'Frete grátis',
    text: 'Acima de  R$150',
    img: deliveryDining,
  },
  {
    title: 'Entrega agendada',
    text: 'Ou expressa até 15h',
    img: clock,
  },
  {
    title: 'Troca grátis',
    text: 'Em até 7 dias',
    img: trade,
  },
  {
    title: 'Certificado SSL',
    text: 'Mais segurança para você',
    img: lock,
  },
];
