// @flow
import { createAction } from 'redux-actions';
// Types
import type { Dispatch } from '../../types';
// Api
import Preferences from '../api';
// Logger
import { logAPIException } from '../../logHelper';

// Fetch Features Actions

export const getFeaturesRequest = createAction('GET_FEATURES_REQUEST');
export const getFeaturesSuccess = createAction(
  'GET_FEATURES_SUCCESS',
  response => response,
);
export const getFeaturesFailure = createAction(
  'GET_FEATURES_FAILURE',
  err => err,
);

export function fetchFeatures() {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getFeaturesRequest());
      const response = await Preferences.fetchFeatures();
      dispatch(getFeaturesSuccess(response));
    } catch (err) {
      logAPIException(err);
      dispatch(getFeaturesFailure(err));
    }
  };
}

// Fetch BigClub Settings Action

export const getBigClubSettingsRequest = createAction(
  'GET_BIGCLUB_SETTINGS_REQUEST',
);
export const getBigClubSettingsSuccess = createAction(
  'GET_BIGCLUB_SETTINGS_SUCCESS',
  response => response,
);
export const getBigClubSettingsFailure = createAction(
  'GET_BIGCLUB_SETTINGS_FAILURE',
  err => err,
);

export function fetchBigClubSettings() {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getBigClubSettingsRequest());
      const response = await Preferences.fetchBigClubSettings();
      dispatch(getBigClubSettingsSuccess(response));
    } catch (err) {
      logAPIException(err);
      dispatch(getBigClubSettingsFailure(err));
    }
  };
}
