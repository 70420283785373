// Styles
import './CartItemPreview.css';
// Types
import type { LineItem } from '../types';
// Helpers
import {
  quantityConverter,
  priceConverter,
  scaleConverter,
} from '../../common';
// Images
import noImage from '../../common/images/noImageChicken.png';

type Props = {
  item: LineItem,
};

const CartItemPreview = ({ item }: Props) => {
  const price = scaleConverter(item.price, item.variant.unitForQuantity);
  const listPrice = scaleConverter(
    item.variant.listPrice,
    item.variant.unitForQuantity,
  );
  const productOnPromotion = listPrice > price;

  const productImage =
    item.variant.images && item.variant.images.length > 0
      ? item.variant.images[0].smallUrl
      : noImage;

  return (
    <section className="cart-item-preview-main-container">
      <div className="cart-item-preview-product-left-container">
        <div className="cart-item-preview-image-container">
          <div className="cart-item-preview-product-quantity">
            {quantityConverter(item.quantity, item.variant.unitForQuantity)}
          </div>
          <img
            className="cart-item-preview-product-image"
            src={productImage}
            alt={item.variant.name}
          ></img>
        </div>
        <div className="cart-item-preview-product-content">
          <p>{item.variant.manufacturer}</p>
          <h1>{item.variant.name}</h1>
        </div>
      </div>
      <div className="cart-item-preview-product-price">
        <div
          className={`cart-item-preview-product-price-main ${
            productOnPromotion
              ? 'cart-item-preview-product-price-promotion'
              : ''
          }`}
        >
          <h5>{priceConverter(price)}</h5>
          {productOnPromotion && <h6>{priceConverter(listPrice)}</h6>}
        </div>
      </div>
    </section>
  );
};
export default CartItemPreview;
