// @flow
import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
// Style
import './Footer.css';
// Common
import { phone } from '../../common';
// Assets
import certificates from '../images/certificates.svg';
import phoneImg from '../images/phone.svg';
import whatsapp from '../images/whatsapp.svg';
// Data
import { socialIcons } from '../data/socialicons';
import { payments } from '../data/payments';
// Redux
import { connect } from 'react-redux';
// Selectors
import { featuresConfigSelector } from '../../preferences/selectors';
// Types
import { FeaturesConfig } from '../../preferences/types';

type Props = {
  featuresConfig: FeaturesConfig,
};

const Footer = (props: Props) => {
  const { pathname } = props.location;
  const isHomePage = pathname === '/';
  const whatsAppmessage = `Olá. Gostaria de ter mais informações sobre o BigDelivery`;
  const bigClubEnabled = props.featuresConfig?.bigclubFeature;

  return (
    <div className={`footer-content ${!isHomePage && 'footer-content-alt'}`}>
      <div className="footer-container">
        <section className="footer-main  footer-gap">
          <div className="footer-talk-with-us">
            <div className="footer-talk-with-us-title-wrapper">
              <img src={phoneImg} alt="Contato" />
              <h4>Fale conosco</h4>
            </div>
            <div className="footer-talk-with-us-contact">
              <span className="footer-talk-with-us-contact-text">
                Central de relacionamento e vendas{' '}
                <strong>(71) 3500-3100</strong>
              </span>
              <a
                target="_blank"
                href={`https://wa.me/${phone.whatsAppNumber}?text=${whatsAppmessage}`}
                rel="noreferrer"
              >
                <img
                  className="footer-talk-with-use-contact-icon"
                  src={whatsapp}
                  alt="whatsapp"
                />
              </a>
            </div>

            <p className="footer-talk-with-us-paragraph">
              Para qualquer outra dúvida, reclamação ou sugestão
              <strong>
                <a
                  href="mailto:sac@bigdelivery.com.br"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  sac@bigdelivery.com.br
                </a>
              </strong>
            </p>
            <div className="footer-socials">
              <p>Acompanhe nas redes sociais!</p>
              <div className="footer-socials-icons">
                {socialIcons.map(({ src, alt, link }, index) => (
                  <a
                    className="footer-socials-icon-link"
                    href={link}
                    key={index}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={src} alt={alt} />
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="divisor" />
          <div className="footer-sections">
            <div className="footer-section">
              <h4 className="footer-section-title">Você</h4>
              <div className="footer-section-links">
                <Link to="/minha-conta/informacoes">Minha Conta</Link>

                <Link to="/minha-conta/pedidos">Meus Pedidos</Link>

                <Link to="/blend/meus-blends">Meus Blends</Link>

                <Link to="/login">Cadastro</Link>
              </div>
            </div>
            <div className="footer-section">
              <h4 className="footer-section-title">Dúvidas</h4>
              <div className="footer-section-links">
                <Link to="/perguntas">Perguntas Frequentes</Link>
                <Link to="/termos">Termos de Uso</Link>
                <Link to="/frete-e-trocas">Frete e Trocas</Link>
                <Link to="/contato">Entre em contato</Link>
                {bigClubEnabled && (
                  <Link to="/politicas-de-uso-bigclub">
                    Política de uso BigClub
                  </Link>
                )}
              </div>
            </div>
            <div className="footer-section">
              <h4 className="footer-section-title">Sobre</h4>
              <div className="footer-section-links">
                <Link to="/sobre">Quem Somos</Link>

                <Link to="/trabalhe">Trabalhe Conosco</Link>

                <Link to="/blog">Blog</Link>
              </div>
            </div>
            <div className="footer-section-alt">
              <div className="footer-section-alt-content">
                <h4 className="footer-section-alt-title">Pagamentos</h4>
                <div className="footer-section-payments">
                  {payments.map(({ src, alt }, index) => (
                    <img alt={alt} src={src} key={index} />
                  ))}
                </div>
              </div>
              <div className="footer-section-alt-content">
                <h4 className="footer-section-alt-title">Certificados</h4>
                <img src={certificates} alt="Certificados" />
              </div>
            </div>
          </div>
        </section>

        <div className="divisor" />
        <section className="footer-resume footer-gap">
          <div className="divisor" />

          <h4>BigDelivery, o seu açougue virtual</h4>
          <p>
            Somos uma empresa com mais de 35 anos de experiência no mercado de
            açougues convencionais na Bahia e, desde 2017, revolucionamos o modo
            como você compra carne.
          </p>
          <p>
            <strong>O que nos diferência?</strong> Nosso principal objetivo é
            garantir sua comodidade. Ao invés de enfrentar filas em um açougue
            convencional, nós levamos até você!
          </p>
          <p>
            <strong>
              Com cortes e porcionamentos personalizados e carne fresca de
              qualidade
            </strong>
            , você pode ter a certeza de que estará recebendo o melhor serviço.
            E não para por aí! Contamos com distribuidores exclusivos da Linha
            Big Carnes e uma seleção minuciosa de produtos terceirizados,
            garantindo a diversidade e preços competitivos.
          </p>
          <p>
            <strong>
              Além disso, nossa frota de entregadores próprios assegura que seu
              pedido chegue até você com rapidez e comodidade.
            </strong>{' '}
            E o melhor de tudo? Já atendemos mais de 200 empresas e milhares de
            consumidores finais, totalizando quase 1 milhão de pedidos
            entregues.
          </p>
          <strong>
            Não acredita? Então faça seu primeiro pedido e deixe-nos te
            impressionar! Venha experimentar o Bigdelivery, o seu açougue
            virtual!
          </strong>
        </section>
      </div>
      <section className="footer-info">
        <div className="footer-info-wrapper footer-gap">
          <div className="footer-info-address">
            <span className="footer-info-address-text">
              Big Delivery Ecommerce de Alimentos LTDA | Big Delivery | CNPJ:
              40.478.970/0001-11
            </span>
            <span className="footer-info-address-text-alt">
              R. Minas Gerais, 486, Loja Terreo, 41830-020 – Pituba –
              Salvador/BA
            </span>
          </div>
          <div className="footer-info-credits">
            <h5 className="footer-info-credits-title">Desenvolvido Com</h5>
            <h5 className="footer-info-credits-title-alt">
              <span role="img" aria-label="emoji de coração">
                ❤️
              </span>
              Pela
              <a
                href="https://avantsoft.com.br"
                rel="noopener noreferrer"
                target="_blank"
              >
                Avantsoft
              </a>
            </h5>
          </div>
        </div>
      </section>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    featuresConfig: featuresConfigSelector(state),
  };
};

const connector: Connector<RouterProps, ReduxProps> = connect(mapStateToProps);
export default withRouter(connector(Footer));
