// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';

// Components
import PaginatedScroller from '../../components/PaginatedScroller';
// Actions
import {
  clearStaffSelectedProducts,
  findRecommendedForUserProducts,
} from '../../actions';
// Selectors
import {
  recommendedForUserProductsSelector,
  recommendedForUserProductsLoadingSelector,
  recommendedLastPageSelecgtor,
} from '../../selectors';
// Types
import type { Product } from '../../types';

type Props = {
  title: string,
  products: Product[],
  isLoading: boolean,
  isLastPage: boolean,
  findProducts: () => void,
  clearProducts: () => void,
};

const ProductRecommendedforUserScroller = (props: Props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;
  const { findProducts } = props;
  const { clearProducts } = props;

  useEffect(() => {
    // Used to clear outdated cache (due to preloading)
    clearProducts();

    findProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestNewPage = index => {
    if (Math.floor(index / perPage) + 1 === currentPage && !props.isLastPage) {
      props.findProducts(currentPage + 1, 10);
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <PaginatedScroller
      title={props.title}
      products={props.products}
      isLoading={props.isLoading && currentPage === 1}
      requestNewPage={requestNewPage}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    products: recommendedForUserProductsSelector(state),
    isLoading: recommendedForUserProductsLoadingSelector(state),
    isLastPage: recommendedLastPageSelecgtor(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    findProducts: (page, perPage) => {
      dispatch(findRecommendedForUserProducts(page, perPage));
    },
    clearProducts: (page, perPage) => {
      dispatch(clearStaffSelectedProducts(page, perPage));
    },
  };
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default connector(ProductRecommendedforUserScroller);
