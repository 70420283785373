import React from "react";
// Composers
import { connect } from 'react-redux';
// Selectors
import { invitedUserByIdSelector } from '../selectors';
//Style
import "./ReferralInvitedUserItem.css";

const ReferralInvitedUserItem = ({
    invitedUserId,
    invitedUserById,
}) => {
    const referralInvitedUser = invitedUserById(invitedUserId);

    return (
        <div className="col-xl-4 col-md-6 col-sm-12">
            <div className={`row referral-invited-user-item ${referralInvitedUser.referralStatus === 'Concluído' ? 'finished ' : ''}my-2`}>
                <div className="referral-user-date-and-name col-md-8 col-7 my-auto">
                    <div className="creation-date">{referralInvitedUser.creationDateForReferral}</div>
                    <div className="creator-name">
                        {`${referralInvitedUser.firstName} ${(referralInvitedUser.lastName[0] || '').toUpperCase()}.`}
                    </div>
                </div>
                <div className="col-md-4 col-5 my-auto referral-user-status">{referralInvitedUser.referralStatus}</div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        invitedUserById: (id) => invitedUserByIdSelector(state, id),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, stateProps) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferralInvitedUserItem);
