import React from 'react';
// Redux related
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';
// Selectors
import * as selectors from '../selectors';
// Style
import './BarbecueCartList.css';
import BarbecueLineItem from './BarbecueLineItem';
// types
import { Product } from '../../products';

type Props = {
  selectedVariantsByIds: Array<Product>,
  selectedVariantsIds: Array<number>,
};

const BarbecueCartList = ({
  selectedVariantsIds,
  selectedVariantsByIds,
}: Props) => {
  return (
    <div className="barbecue-cart-list-content">
      {selectedVariantsByIds.map(variant => (
        <BarbecueLineItem key={variant.id} variant={variant} />
      ))}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    selectedVariantsByIds: selectors.barbecueVariantsByIdsSelector(
      state,
      ownProps.selectedVariantsIds,
    ),
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};
const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default connector(BarbecueCartList);
