import facebook from '../images/facebook.svg';
import instagram from '../images/instagram.svg';
import linkedin from '../images/linkedin.svg';
import tiktok from '../images/tiktok.svg';

export const socialIcons = [
  {
    src: instagram,
    alt: 'instagram bigdelivery',
    link: ' https://www.instagram.com/bigdeliverybr',
  },
  {
    src: facebook,
    alt: 'facebook bigdelivery',
    link: 'https://www.facebook.com/bigdeliverybr',
  },
  {
    src: tiktok,
    alt: 'tiktok bigdelivery',
    link: 'https://www.tiktok.com/@big.delivery',
  },
  {
    src: linkedin,
    alt: 'linkedin bigdelivery',
    link: 'https://www.linkedin.com/company/bigdelivery',
  },
];
