// @flow
import React from 'react';
// Styles
import './ConfirmationModal.css';
// Components
import Button from './Button';

type Props = {
  onConfirm: () => void,
  onClose: () => void,
  text: string,
  isOpen: boolean,
};

const ConfirmationModal = ({
  text = '',
  onClose,
  onConfirm,
  isOpen,
}: Props) => {
  if (!isOpen) return null;
  return (
    <div className="confirmation-modal-wrapper">
      <div className="confirmation-modal-container">
        <div className="confirmation-modal-content">
          <h3 className="confirmation-modal-content-text">{text}</h3>
          <div className="confirmation-modal-actions">
            <Button
              text="Cancelar"
              onClick={onClose}
              buttonType="button-outline"
            />
            <Button
              text="Confirmar"
              onClick={onConfirm}
              buttonType="button-secondary"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
