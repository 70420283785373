// Takes in a Date object example Wed Nov 08 2017 18:00:00 GMT-0500 (EST)
import moment from 'moment';

export const getTimeWindow = (start: Date, end: Date) => {
  const start_time_text = moment(start)
    .utcOffset('-0300')
    .format('HH:mm');
  const end_time_text = moment(end)
    .utcOffset('-0300')
    .format('HH:mm');
  return `${start_time_text} às ${end_time_text}`;
};

export const getTimeFormat3l = (actual: Date) => {
  const actual_time_text = moment(actual)
    .utcOffset('-0300')
    .format('lll');
  return `${actual_time_text}`;
};

export const getTimeFormat2L = (actual: Date) => {
  const actual_time_text = moment(actual)
    .utcOffset('-0300')
    .format('ll');
  return `${actual_time_text}`;
};

export const getTimeFormatL = (actual: Date) => {
  const actual_time_text = moment(actual)
    .utcOffset('-0300')
    .format('L');
  return `${actual_time_text}`;
};
