// @flow
import React from 'react';
import qs from 'qs';
import _ from 'lodash';
// Components
import PasswordRecovery from '../components/PasswordRecovery';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// Composers
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// Import Actions
import { putResetPassword } from '../actions';
// Selectors
import {
  resetPasswordLoadingSelector,
  resetPasswordErrorsSelector,
} from '../selectors';
// Types
type RouterProps = ContextRouter;

type Props = {
  onSubmitRecoverPassword: (
    password: string,
    passwordConfirmation: string,
  ) => void,
} & RouterProps;

class PasswordRecoveryPage extends React.Component {
  props: Props;

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    // If we have just left the loading state and there are no errors it means we have successfully reset the password.
    if (
      !nextProps.loading &&
      this.props.loading &&
      _.isEmpty(this.props.errors)
    ) {
      this.props.history.push({
        pathname: '/login',
        state: { to: '/', message: 'Entre usando a sua nova senha' },
      });
    }
  }

  render() {
    const params = qs.parse(
      this.props.location.search ? this.props.location.search.substr(1) : '',
    );

    // If we don't have a token redirect the person to the home page
    if (!params.token) return <Redirect to="/" />;

    return (
      <div>
        <Helmet>
          <title>Recuperar Senha - Bigdelivery, Seu açougue virtual</title>
        </Helmet>

        <PasswordRecovery
          isLoading={this.props.loading}
          errors={this.props.errors}
          onSubmitRecoverPassword={(password, passwordConfirmation) =>
            this.props.onSubmitRecoverPassword(
              password,
              passwordConfirmation,
              params.token,
              params.email,
            )
          }
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: resetPasswordLoadingSelector(state),
    errors: resetPasswordErrorsSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, stateProps) => {
  return {
    onSubmitRecoverPassword: (
      password: string,
      passwordConfirmation: string,
      token: string,
      email: string,
    ) => {
      dispatch(putResetPassword(password, passwordConfirmation, token, email));
    },
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PasswordRecoveryPage),
);
