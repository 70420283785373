// Images
import dogCoracaoPequeno from '../images/dogcoraçãopequeno.svg';
import pata from '../images/pata.svg';
import protecao from '../images/proteção.svg';
import dogempesino from '../images/dogempesino.svg';
import poodle from '../images/poodle.svg';
import check from '../images/check.svg';
import schedule from '../images/schedule.svg';
import shoppingCart from '../images/carrinhoclique.svg';
import cuttingBoard from '../images/tabuadecorte.svg';
import dogInHeart from '../images/dogcoração.svg';
import comment from '../images/comments.jpg';

const petData = {
  petBenefits: [
    {
      img: check,
      text: 'Fonte de vitaminas e minerais',
    },
    {
      img: dogempesino,
      text: 'Fortalecimento muscular',
    },
    {
      img: pata,
      text: 'Hormônios regulados',
    },
    {
      img: dogCoracaoPequeno,
      text: 'Melhor funcionamento do coração',
    },

    {
      img: poodle,
      text: 'Pelagem mais saudável',
    },
    {
      img: protecao,
      text: 'Melhoramento do sistema imunológico',
    },
  ],
  petColumnIcons: [
    {
      img: shoppingCart,
      alt: 'Carrinho de compras',
      text: 'Compre de qualquer lugar, receba em casa',
    },
    {
      img: schedule,
      alt: 'Calendário',
      text: 'Entrega agendada',
    },
    {
      img: cuttingBoard,
      alt: 'Tabua de corte',
      text: 'Em cubos ou moída?, Você escolhe',
    },
    {
      img: dogInHeart,
      alt: 'Coração envolvendo cachorro',
      text: 'Peça o porcionamento da carne de acordo com a necessidade do seu pet',
    },
  ],
  faqQuestions: [
    {
      title: 'O ideal é carne crua ou cozida?',
      body: 'O organismo dos pets costuma apresentar defesas contra patógenos comuns em carnes diversas. No entanto, pelo bem-estar não apenas dos pets, como também dos tutores, o ideal é preparar a carne cozida.',
      isOpen: false,
    },
    {
      title: 'Quais os melhores tipos de carnes para pets?',
      body: 'Frangos e aves (desossadas e sem pele), carne de porco, bovina, vísceras (coração, fígado e rins) e também peixes, sem espinhas.',
      isOpen: false,
    },
    {
      title: 'Posso incrementar com legumes e etc?',
      body: 'Claro! Esse é um passo super importante, afinal, da mesma forma que humanos incluem diversos tipos de alimentos em sua dieta, os bichinhos também precisam.',
      isOpen: false,
    },
    {
      title: 'Como fazer essa complementação?',
      body: 'O mais comum é combinar com batata-doce, chuchu, inhame, abobrinha e muito mais. Tudo isso pode incrementar não apenas sabor, como uma dieta ainda mais nutritiva.',
      isOpen: false,
    },
    {
      title: 'Meu pet pode ter a carne adicionada em sua dieta?',
      body: 'Cães e gatos podem contar com carne em sua dieta. No entanto, o ideal é consultar o veterinário que acompanha seu pet e compreender melhores práticas e cuidados especiais.',
      isOpen: false,
    },
  ],
  comment: {
    img: comment,
    title: '"Meu pet adora..."',
    text: '"Qualquer cão ou gato pode optar pela alimentação natural à base de carne, mas é importante o acompanhamento próximo de um Veterinário ou Zootecnista especializado, principalmente para a orientação do tutor sobre melhores práticas."',
    author: 'Cliente BigDelivery',
  },
};

export default petData;
