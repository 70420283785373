import firstFrameImage from '../images/black-friday-banner-first-frame-image.png';
import secondFrameImage from '../images/black-friday-banner-second-frame-image.png';
import thirdFrameImage from '../images/black-friday-banner-third-frame-image.jpeg';
import fourthFrameImage from '../images/black-friday-banner-fourth-frame-image.png';

export const ChristmasData = [
  {
    question: 'Quais são as promoções especiais de Natal do BigDelivery?',
    answer: `Este Natal, estamos oferecendo descontos exclusivos em diversos cortes e
    kits para tornar suas celebrações ainda mais especiais. Confira nossa seção de promoções
    para descobrir as ofertas incríveis.`,
  },
  {
    question: 'Como faço para personalizar meu pedido para o Natal?',
    answer: `No BigDelivery, você tem a opção exclusiva de personalizar seus cortes de carne.
    Basta indicar suas preferências no momento da compra, e nossa equipe cuidará para que você
    receba exatamente o que deseja.`,
  },
  {
    question:
      'Qual é a garantia de qualidade dos produtos do BigDelivery para o Natal?',
    answer: `Garantimos que todos os nossos produtos são de alta qualidade. Nossas carnes são selecionadas com cuidado,
    e oferecemos garantia de satisfação. Se algo não atender às suas expectativas, entre em contato conosco
    para que possamos resolver.`,
  },
  {
    question:
      'Posso agendar a entrega para o período desejado durante as festas de Natal?',
    answer: `Sim, no BigDelivery, oferecemos a conveniência de agendar o horário de entrega que melhor se
    adapta à sua programação festiva. Escolha o momento que desejar e aproveite suas celebrações
    sem preocupações. Porém no dia 25, apenas será possível agendar pedidos para outras datas`,
  },
  {
    question: 'Como funciona o cashback durante as promoções de Natal?',
    answer: `Durante as promoções de Natal, você continua acumulando cashback em suas compras.
    Esteja atento às ofertas especiais que podem aumentar ainda mais seus benefícios.
    Lembre-se de que o cashback estará disponível para uso após 7 dias da compra.`,
  },
  {
    question:
      'Há frete grátis para pedidos de Natal acima de um determinado valor?',
    answer: `Sim, oferecemos frete grátis para pedidos acima de R$150.
    Aproveite para fazer suas compras de Natal com comodidade e economia.`,
  },
  {
    question:
      'Como participar do BigClub e aproveitar os benefícios neste Natal?',
    answer: `Junte-se ao BigClub para desfrutar de vantagens exclusivas,
    incluindo descontos, promoções especiais e acúmulo de pontos.
    Cadastre-se facilmente em nosso site e comece a aproveitar os benefícios neste Natal.`,
  },
  {
    question: 'Posso trocar um produto comprado durante as promoções de Natal?',
    answer: `Sim, você pode solicitar a troca de um produto dentro do prazo de 7
    dias após a compra. Entre em contato conosco para obter assistência e garantir sua satisfação.`,
  },
  {
    question:
      'Como convidar amigos para aproveitar as promoções de Natal no BigDelivery?',
    answer: `Compartilhe seu código de indicação do BigClub com amigos e familiares.
    Quando eles realizarem a primeira compra, você e seu amigo ganharão benefícios especiais.
    Celebre o Natal com presentes e descontos!`,
  },
  {
    question:
      'Quais são os destaques das ofertas semanais de kits para o Natal?',
    answer: `Durante as festas, nossos kits especiais oferecem uma variedade de cortes que são
    perfeitos para suas celebrações natalinas. Fique de olho nas ofertas semanais para descobrir
    novas combinações irresistíveis.`,
  },
];

export const framesData = [
  {
    image: firstFrameImage,
    title: 'Personalize o seu corte como quiser!',
    description:
      'Transforme seu churrasco em algo único! No Big Delivery, personalize seus cortes de carne de acordo com sua preferência.',
  },
  {
    image: secondFrameImage,
    title: 'Como funciona o Cashback no BigClub?',
    description:
      'No BigClub, suas compras valem ainda mais! Com o Cashback, você recebe uma porcentagem do valor gasto de volta.',
  },
  {
    image: thirdFrameImage,
    title: 'Frete grátis em pedidos acima de 150 reais',
    description:
      'Economize com entregas gratuitas! Faça pedidos acima de R$150, tenha a vantagem do frete grátis.',
  },
  {
    image: fourthFrameImage,
    title: 'Receba o pedido no horário que se adapta à sua rotina',
    description:
      'Não se preocupe com o horário da entrega! No Big Delivery, agende a entrega para o momento que melhor se encaixa na sua rotina.',
  },
];
