// @flow
import React from 'react';
import { Helmet } from 'react-helmet';
// Styles
import './BigClubRulesPage.css';
// Components
import { PageTitleWithGoBack } from '../../common';

const BigClubRulesPage = () => {
  return (
    <div className="bigclub-rules-main">
      <Helmet>
        <title>
          {' '}
          Política de uso do BigClub - Bigdelivery, Seu açougue virtual
        </title>
      </Helmet>

      <PageTitleWithGoBack title="Política de uso do BigClub" />
      <div className="bigclub-rules-main-text">
        <p>
          Bem-vindo(a) ao BigClub, o clube de benefícios exclusivo para os
          nossos valorizados clientes. Esta política de uso detalha as regras e
          diretrizes que regem o funcionamento do BigClub, incluindo o programa
          "Indique e Ganhe" e a funcionalidade de cashback. Por favor, leia
          atentamente as informações a seguir.
        </p>

        <p>
          <b>1. Programa "Indique e Ganhe"</b>
        </p>

        <p>
          O programa "Indique e Ganhe" é uma iniciativa que recompensa os
          clientes por indicarem novos membros ao Bigdelivery. Ao fazer uma
          indicação bem-sucedida, o cliente que indicou receberá R$10 de saldo
          BigClub em sua conta.
        </p>

        <p>
          <b>1.1 Como resgatar o "Indique e Ganhe"</b>
        </p>

        <p>
          Para resgatar o saldo do Programa "Convide e Ganhe", compartilhe o seu
          código exclusivo de indicação com amigos ou conhecidos. Seu amigo
          convidado deve criar uma conta no Big Delivery utilizando o código
          fornecido por você e realizar uma compra bem-sucedida. Após a
          conclusão da compra e entrega do pedido, o saldo referente ao "Convide
          e Ganhe" será depositado na sua carteira BigClub e você poderá usar
          quando quiser. Aproveite os benefícios exclusivos do programa e
          convide seus amigos para economizar em suas próximas compras no Big
          Delivery!
        </p>

        <p>
          Lembramos que é essencial que o código seja usado durante o cadastro e
          que a compra seja realizada com sucesso para garantir o saldo BigClub
          na conta de quem indicou. Aproveite os benefícios exclusivos do
          Programa "Convide e Ganhe"!
        </p>

        <p>
          <b>2. Funcionalidade de Cashback</b>
        </p>

        <p>
          O cashback é uma forma de recompensar os clientes por suas compras
          realizadas no Bigdelivery. Para usufruir dessa funcionalidade,
          aplicam-se as seguintes regras:
        </p>

        <div className="bigclub-rules-column">
          <p>
            - Porcentagem de cashback acumulado por compra: O cliente acumulará
            3% do valor total produzido, como cashback, subtraindo-se o desconto
            concedido no pedido. Caso o valor do desconto for maior que o valor
            produzido, o usuário não acumulará cashback.
          </p>
          <p>
            - Valor Mínimo para Acumular: O pedido deve atingir o valor mínimo
            de R$200 para que o cashback seja acumulado.
          </p>
          <p>
            - Participação válida apenas para clientes Pessoa Física: A
            participação no programa de cashback está restrita a usuários Pessoa
            Física. Usuários Pessoa Jurídica não poderão utilizar.
          </p>
          <p>
            - Acúmulo do cashback: O cashback acumulado será disponibilizado na
            conta do cliente após 7 dias da entrega do pedido como saldo
            BigClub.
          </p>
          <p>
            - Disponibilidade no aplicativo e site: A funcionalidade de cashback
            está disponível exclusivamente por meio do aplicativo móvel e do
            site oficial do BigClub.
          </p>
        </div>

        <p>
          <b>3. Uso do saldo do clube</b>
        </p>

        <p>
          O saldo acumulado na conta do clube pode ser utilizado para efetuar
          compras e aproveitar as vantagens oferecidas pelo BigClub. O saldo do
          clube pode ser aplicado para pagar parcialmente o valor das compras,
          observando as seguintes regras:
        </p>

        <div className="bigclub-rules-column">
          <p>
            - Valor máximo de uso do saldo do clube: O cliente poderá utilizar
            até 30% do valor total da compra por meio do saldo BigClub.
          </p>
        </div>

        <p className="bigclub-rules-main-text-alt">
          É importante destacar que as mesmas regras mencionadas anteriormente
          para o cashback também se aplicam ao uso do saldo BigClub. Portanto,
          as diretrizes estabelecidas para o cashback são igualmente válidas
          para a utilização do saldo acumulado no BigClub.
        </p>
      </div>
    </div>
  );
};

export default BigClubRulesPage;
