// @flow
import React, { useEffect, useState } from 'react';
// Composers
import { connect } from 'react-redux';
import { userLoggedInConnector } from '../../user';
// Selectors
import {
  itemSelectorByProductId,
  changingItemLoadingSelector,
  addingItemLoadingSelector,
  removingItemLoadingSelector,
} from '../../current-order/selectors';
// Components
import { NotifyMeWhenStockRenewed } from '../../products';
import AddProductButton from './AddProductButton';
import RemoveProductButton from './RemoveProductButton';
// Analytics
import * as Analytics from '../../common/analytics';
// Types
import type { Connector } from 'react-redux';
import { LineItem } from '../../current-order/types';
// Actions
import { addItemToCart, deleteItem } from '../../current-order/actions';
// Styles
import './GoToCheckoutButton.css';

type OwnProps = {
  showNotifyMeButton: boolean,
  productId: number,
  unitForQuantity: 'g' | 'un',
  incrementQuantity: number,
  defaultQuantity: number,
  inStock: boolean,
  discontinued: boolean,
  observation: string,
};

type Props = {
  /* :: ...OwnProps, */
  addingLoading: boolean,
  changingLoading: boolean,
  item: LineItem,
  disableInsteadOfDeleteOnMinimumQuantity?: boolean,
  handleAddItem: () => void,
  handleRemoveItem: (lineItemId: number) => void,
};
const GoToCheckoutButton = ({
  showNotifyMeButton,
  inStock,
  discontinued,
  loggedIn,
  item,
  productId,
  unitForQuantity,
  changingLoading,
  addingLoading,
  handleAddItem,
  handleIncreaseQuantity,
  handleDecreaseQuantity,
  handleNotifyMeWhenStockRenewed,
  handleUnnotifyMeWhenStockRenewed,
  handleRemoveItem,
  removingItemLoading,
  disableInsteadOfDeleteOnMinimumQuantity,
  // USE ANALYTICS
  analyticsViewSource,
}: Props) => {
  const [addedItem, setAddedItem] = useState(false);
  const [itemCopy, setItemCopy] = useState(null);
  const [itemRemoved, setItemRemoved] = useState(false);

  useEffect(() => {
    if (item && addedItem) {
      Analytics.logLineItemAdd(
        item,
        window.location.pathname,
        analyticsViewSource,
      );
      setAddedItem(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, addedItem]);

  useEffect(() => {
    if (!item && itemRemoved) {
      Analytics.logLineItemRemove(
        itemCopy,
        window.location.pathname,
        analyticsViewSource,
      );
      setItemRemoved(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, itemRemoved]);

  const handleAddItemAction = async event => {
    setAddedItem(true);
    await handleAddItem();
  };

  const handleRemoveItemAction = event => {
    setItemRemoved(true);
    setItemCopy({ ...item });
    handleRemoveItem(item.id);
  };

  // If a product is out of stock
  if (discontinued) {
    return (
      <div className="container">
        <h3 className="out-of-stock-text without-button">Descontinuado</h3>
      </div>
    );
  }
  if (!inStock) {
    return (
      <NotifyMeWhenStockRenewed
        loggedIn={loggedIn}
        showNotifyMeButton={showNotifyMeButton}
        productId={productId}
      />
    );
  }

  // Current product was not found in the lineItems. Show add to cart button
  if (!item) {
    return (
      <AddProductButton addItem={handleAddItemAction} loading={addingLoading} />
    );
  } else {
    return (
      <RemoveProductButton
        handleRemoveItem={handleRemoveItemAction}
        loading={removingItemLoading}
        item={item}
      />
    );
  }
};

const mapStateToProps = (state, ownProps: OwnProps) => {
  const item = itemSelectorByProductId(state, ownProps.productId);
  const itemId = item ? item.id : null;
  return {
    item: item,
    addingLoading: addingItemLoadingSelector(state, ownProps.productId),
    changingLoading: changingItemLoadingSelector(state, itemId),
    removingItemLoading: removingItemLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  return {
    handleAddItem: async () => {
      await dispatch(
        addItemToCart(ownProps.productId, ownProps.defaultQuantity),
      );
    },
    handleRemoveItem: (lineItemId: number) => {
      dispatch(deleteItem(lineItemId));
    },
  };
};

GoToCheckoutButton.defaultProps = {
  disableInsteadOfDeleteOnMinimumQuantity: false,
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default userLoggedInConnector(connector(GoToCheckoutButton));
