// Images
import camarao from '../images/camarão.svg';
import carne from '../images/carne.svg';
import frangoAssado from '../images/frangoassado.svg';
import carnes from '../images/meats.png';

const healthyData = {
  healthyCare: {
    img: carnes,
    alt: 'Carnes',
    title: 'Cuidar da sua alimentação é cuidar de você!',
    text: 'Garanta carnes deliciosas para uma rotina saudável',
  },
  flavorItems: [
    {
      img: frangoAssado,
      title: 'Aves',
      alt: 'Aves',
    },
    {
      img: camarao,
      title: 'Pescados',
      alt: 'Pescados',
    },
    {
      img: carne,
      title: 'Carnes magras',
      alt: 'Carnes magras',
    },
  ],
  faqQuestions: [
    {
      title:
        'Como o Bigdelivery pode me ajudar a ter uma alimentação saudável?',
      body: 'Comprando com a gente, você terá mais facilidade para implementar ou manter uma rotina de alimentação saudável. Além da qualidade da carne, que é fresca, limpa e sem gordura; você pode montar um porcionamento personalizado- de acordo com a sua rotina e preferência- e contar com a nossa entrega.',
      isOpen: false,
    },
    {
      title: 'A carne do Bigdelivery é limpa e de confiança?',
      body: 'O nosso trabalho segue todos os procedimentos necessários para que você receba uma carne de primeira qualidade. Da produção à entrega, cuidamos de tudo para que você tenha a melhor experiência de compra e se delicie com uma carne suculenta e sem gorduras excessivas.',
      isOpen: false,
    },
    {
      title: 'Como é feita a pesagem da carne?',
      body: 'Pesamos a carne após a limpeza e o corte, garantindo assim, um produto de qualidade, com menos gordura e um preço justo',
      isOpen: false,
    },
    {
      title: 'Posso pedir carnes para toda a semana?',
      body: 'Claro! Com o Bigdelivery, você pode organizar a sua rotina e pedir carnes para toda a semana. Temos muitas opções de carnes e cortes disponíveis para que você tenha um cardápio com variedades e muito sabor.',
      isOpen: false,
    },
    {
      title: 'Posso agendar a entrega?',
      body: 'Sim! Ao finalizar o pedido, você poderá agendar o dia e o horário da entrega, conforme a sua disponibilidade.',
      isOpen: false,
    },
  ],
};
export default healthyData;
