// Types
import type { StoreState } from '../types';
// Router
import { matchPath } from 'react-router';

export const thisStateSelector = (state: StoreState): StoreState => {
  return state.advertising;
};

export const popupByPathSelector = (state: StoreState, path: string) => {
  const popups = Object.values(state.advertising.popups);
  const result = popups.find(popup => {
    return matchPath(path, {
      path: popup.path,
    });
  });
  return result ? result : {};
};
