/** @flow
 * CHECKOUT PAGE COMPONENT
 * This is the unconnected component that displays the checkout page,
 * with panes for address, delivery and payment. Also includes a
 * sidebar for order summary and effetuating the purchase.
 */
import React, { Component } from 'react';
// Components
import { ColorRow, RefreshModal } from '../../common';
import BigClubPreview from './BigClubPreview';
import CartPreview from './CartPreview';
import AddressPane from './address/AddressPane';
import DeliveryPane from './delivery/DeliveryPane';
import PaymentMethodPane from './payment/PaymentPane';
import ResumePane from './resume/ResumePane';
// Styles
import './CheckoutPage.css';
// Types
import { BigClubSettings } from '../../preferences/types';
import type { ShippingRate } from '../../types';
import type { UserAddress } from '../../user';
import type {
  BigClubInfo,
  CreditCard,
  LineItem,
  OrderAddress,
  PaymentMethod,
} from '../types';
// Helpers
import { scrollToTop } from '../../common';

/**
 * PROPS AND STATE
 */
export type Props = {
  orderNumber: string,
  getBlends: (orderNumber: string) => void,
  blendsByOrderNumber: orderNumber => void,
  // Order management
  orderState: 'cart' | 'address' | 'delivery' | 'payment' | 'confirm',
  loading: boolean,
  transitioningLoading: boolean,
  // General information
  items: LineItem[],
  totalPrice: string,
  totalDiscount: string,
  totalItemPrice: string,
  shippingPrice: string,
  numberOfItems: number,
  promotions: any,
  // Errors
  addressErrors: string[],
  deliveryErrors: string[],
  promotionErrors: string[],
  paymentErrors: string[],
  dismissErrors: () => void,
  // Address
  orderShippingAddress: ?OrderAddress, // Selected address from the order. Type is different from others.
  addresses: UserAddress[],
  newAddress: (address: UserAddress) => void,
  updateAddress: (address: UserAddress) => void,
  deleteAddress: (addressId: number) => void,
  submitAddress: UserAddress => void,
  addressesDeleteByIdLoading: (id: number) => boolean,
  addressesEditingLoading: boolean,
  addressesAddingLoading: boolean,
  addressesLoading: boolean,
  // Delivery dates
  shippingRates: any,
  submitShippingRate: ShippingRate => void,
  orderDelivery: ShippingRate,
  // Payment
  bigClubInfo: BigClubInfo,
  creditCards: CreditCard[],
  paymentMethods: PaymentMethod[],
  submitPayment: (CreditCard | PaymentMethod) => void,
  orderPayment: PaymentMethod,
  // promotion
  submitPromotion: (string[]) => void,
  // Confirmation
  submitConfirmation: () => void,
  bigClubSettings: BigClubSettings,
  transitionToState: string => void,
};

type State = {
  bigClubCreditAmount: number,
  paymentState: orderPayment,
};
class CheckoutPage extends Component {
  props: Props;
  state: State;

  constructor(props: Props) {
    super(props);
    this.state = {
      bigClubCreditAmount: 0,
      useBigClubCredit: false,
      showResume: false,
      paymentState: null,
    };
  }

  // We should clear all warning when the component mounts in case they are lingering from a previous session
  componentDidMount() {
    this.props.dismissErrors();
  }

  handleClearPaymentState() {
    if (this.state.showResume || !!this.paymentState) {
      this.setState({
        paymentState: null,
        showResume: false,
      });
    }
  }

  handlePayment() {
    const { paymentMethod, paymentSource, existingCard, bigClubCreditAmount } =
      this.state.paymentState;
    this.props.submitPayment(
      paymentMethod,
      paymentSource,
      existingCard,
      bigClubCreditAmount,
    );
  }

  handleTransition(transitionTo: 'address' | 'cart' = '') {
    if (transitionTo) {
      this.props.transitionToState(transitionTo);
    }
    this.handleClearPaymentState();
    scrollToTop();
  }

  render() {
    return (
      <div className="check-out-page-container">
        <RefreshModal />
        <ColorRow color="red" text="CHECKOUT" />
        <div className="check-out-page-main">
          <div className="check-out-page-pane">
            <AddressPane
              transitionToAddress={() => {
                this.handleTransition('cart');
              }}
              errors={this.props.addressErrors}
              active={this.props.orderState === 'address'}
              isCompleted={
                ['delivery', 'confirm', 'payment'].indexOf(
                  this.props.orderState,
                ) > -1
              }
              selected={this.props.orderShippingAddress}
              onSubmit={this.props.submitAddress}
              loading={this.props.transitioningLoading}
              // From address connector
              addresses={this.props.addresses}
              addressesLoading={this.props.addressesLoading}
              editingLoading={this.props.addressesEditingLoading}
              addingLoading={this.props.addressesAddingLoading}
              deletingLoading={this.props.addressesDeleteByIdLoading}
              onNew={this.props.newAddress}
              onEdit={this.props.updateAddress}
              onDelete={this.props.deleteAddress}
              dismissErrors={this.props.dismissErrors}
            />
            <DeliveryPane
              transitionToDelivery={() => {
                this.handleTransition('address');
              }}
              errors={this.props.deliveryErrors}
              selected={
                this.props.orderState !== 'address'
                  ? this.props.orderDelivery
                  : null
              } // Delivery that is in the order from server. Ternary needed not to show when in address.
              shippingRates={this.props.shippingRates}
              onSubmit={this.props.submitShippingRate}
              active={['delivery'].indexOf(this.props.orderState) > -1}
              isCompleted={
                ['payment', 'confirm'].indexOf(this.props.orderState) > -1
              }
              loading={this.props.transitioningLoading}
              dismissErrors={this.props.dismissErrors}
            />
            <PaymentMethodPane
              transitionToPayment={() => {
                this.handleClearPaymentState();
              }}
              orderNumber={this.props.orderNumber}
              adjustmentTotal={this.props.adjustmentTotal}
              promotions={this.props.promotions}
              promotionsLoading={this.props.promotionsLoading}
              promotionErrors={this.props.promotionErrors}
              errors={this.props.paymentErrors}
              selected={this.props.orderPayment}
              bigClubCreditAmount={this.state.bigClubCreditAmount}
              useBigClubCredit={this.state.useBigClubCredit}
              creditCards={this.props.creditCards}
              paymentMethods={this.props.paymentMethods}
              submitPayment={(
                paymentMethod,
                paymentSource,
                existingCard,
                bigClubCreditAmount,
              ) => {
                this.setState(prevState => ({
                  ...prevState,
                  paymentState: {
                    paymentMethod,
                    paymentSource,
                    existingCard,
                    bigClubCreditAmount,
                  },
                  showResume: true,
                }));
              }}
              submitPromotion={this.props.submitPromotion}
              active={
                ['payment', 'confirm'].indexOf(this.props.orderState) > -1 &&
                !this.state.showResume
              }
              isCompleted={this.state.showResume}
              loading={this.props.transitioningLoading}
              dismissErrors={this.props.dismissErrors}
              totalPrice={this.props.totalPrice}
              cashbackMinOrderPriceToEarn={
                this.props.bigClubSettings.cashback?.cashbackMinOrderPriceToEarn
              }
            />
            <ResumePane
              active={
                this.state.showResume &&
                ['payment', 'confirm'].indexOf(this.props.orderState) > -1
              }
              loading={this.props.transitioningLoading}
              submitConfirmation={() => {
                this.handlePayment();
              }}
              errors={this.props.paymentErrors}
              items={this.props.items}
              orderPayment={this.state.paymentState || null}
              orderDelivery={this.props.orderDelivery || null}
              orderAddress={this.props.orderShippingAddress || null}
              adjustmentTotal={this.props.adjustmentTotal}
              totalPrice={this.props.totalPrice}
              shipTotal={this.props.shippingPrice}
              availableStoreCredits={this.props.availableStoreCredits}
              appliedStoreCredits={this.props.appliedStoreCredits}
              useBigClubCredit={this.state.useBigClubCredit}
              bigClubInfo={this.props.bigClubInfo}
              bigClubCreditAmount={this.state.bigClubCreditAmount}
              itemTotal={this.props.totalItemPrice}
              dismissErrors={this.props.dismissErrors}
              transitionToAddress={() => {
                this.handleTransition('cart');
              }}
              transitionToDelivery={() => {
                this.handleTransition('address');
              }}
              transitionToPayment={() => {
                this.handleTransition();
              }}
            />
          </div>
          <div className="check-out-page-column">
            <BigClubPreview
              handleApplyBigClubCredit={(value: number) => {
                this.setState({
                  bigClubCreditAmount: value,
                });
              }}
              bigClubSettings={this.props.bigClubSettings}
              bigClubCreditAmount={this.state.bigClubCreditAmount}
              bigClubInfo={this.props.bigClubInfo}
              orderNumber={this.props.orderNumber}
              setUseBigClubCredit={(state: boolean) => {
                this.setState({
                  useBigClubCredit: state,
                });
              }}
              useBigClubCredit={this.state.useBigClubCredit}
            />
            <CartPreview
              transitionToAddress={() => {
                this.handleTransition('cart');
              }}
              transitionToDelivery={() => {
                this.handleTransition('address');
              }}
              transitionToPayment={() => {
                this.handleTransition();
              }}
              orderPayment={this.state.paymentState || null}
              orderDelivery={this.props.orderDelivery || null}
              orderShippingAddress={this.props.orderShippingAddress || null}
              items={this.props.items}
              useBigClubCredit={this.state.useBigClubCredit}
              bigClubInfo={this.props.bigClubInfo}
              bigClubCreditAmount={this.state.bigClubCreditAmount}
              orderNumber={this.props.orderNumber}
              blendsByOrderNumber={this.props.blendsByOrderNumber}
              totalPrice={this.props.totalPrice}
              itemTotal={this.props.totalItemPrice}
              shipTotal={this.props.shippingPrice}
              adjustmentTotal={this.props.adjustmentTotal}
              availableStoreCredits={this.props.availableStoreCredits}
              appliedStoreCredits={this.props.appliedStoreCredits}
              canSubmit={
                ['payment', 'confirm'].indexOf(this.props.orderState) > -1
              }
              loading={
                this.props.transitioningLoading &&
                this.props.orderState === 'confirm'
              }
              onSubmit={() => this.handlePayment()}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CheckoutPage;
