// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Selectors
import {
  creditCardsSelector,
  userIdSelector,
  gettingUserCreditCardsLoadingSelector,
  deletingdUserCreditCardLoadingSelector,
  allowedPaymentMethodsSelector,
} from '../selectors';
// Actions
import { getCreditCards, deleteCreditCard } from '../actions';
// Components
import MyAccountPageContainer from '../components/myAccount/MyAccountPageContainer';
import MyAccountPayment from '../components/myAccount/MyAccountPayment';
// Styles
import './MyAccountPaymentsPage.css';
// Types
import type { Address } from '../types';

type Props = {
  addresses: Address[],
  getAddresses: () => void,
  onSubmitDeleteAddress: (addressId: number) => void,
};

class MyAccountPaymentsPage extends Component {
  constructor(props: Props) {
    super(props);
    if (props.userId) {
      props.getCreditCards();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.userId && nextProps.userId) {
      this.props.getCreditCards();
    }
  }

  render() {
    return (
      <MyAccountPageContainer>
        <MyAccountPayment
          creditCards={this.props.creditCards}
          allowedPaymentMethods={this.props.allowedPaymentMethods}
          loading={this.props.creditCardsLoading}
          deleteCreditCard={this.props.deleteCreditCard}
          deletingLoading={this.props.deletingLoading}
        />
      </MyAccountPageContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userId: userIdSelector(state),
    creditCards: creditCardsSelector(state),
    creditCardsLoading: gettingUserCreditCardsLoadingSelector(state),
    deletingLoading: deletingdUserCreditCardLoadingSelector(state),
    allowedPaymentMethods: allowedPaymentMethodsSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    getCreditCards: () => {
      dispatch(getCreditCards());
    },
    deleteCreditCard: (creditCardId: number) => {
      dispatch(deleteCreditCard(creditCardId));
    },
  };
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default connector(MyAccountPaymentsPage);
