// @flow
import { useEffect } from 'react';
// components
import ChristmasBanner from '../components/ChristmasBanner';
import Faq from '../components/Faq';
import ChristmasBaseBanner from '../components/ChristmasBaseBanner';
import ChristmasProductSlider from '../components/ChristmasProductSlider';
import {
  ProductPromotionScroller,
  ProductRecommendedForUserScroller,
} from '../../products';
// styles
import './ChristmasLandingPage.css';
// Analytics
import * as Analytics from '../../common/analytics';
// Data
import { ChristmasData } from '../data/ChristmasData.js';

const ChristmasLandingPage = () => {
  useEffect(() => {
    // USE ANALYTICS
    Analytics.logUserOpenedChristmasLandingPage();
  }, []);

  return (
    <div className="christmas-landing-page-main-container">
      <ChristmasBanner />
      <ProductPromotionScroller title="Para um natal incrível" />
      <ChristmasProductSlider />
      <ProductRecommendedForUserScroller title="Você também pode gostar!" />
      <Faq data={ChristmasData} />
      <ChristmasBaseBanner />
    </div>
  );
};
export default ChristmasLandingPage;
