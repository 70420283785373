// @flow
import React from 'react';
// Styles
import './BlogPromotionalFeatures.css';
// Images
import cutIcon from '../images/cutIcon.svg';
import motorBikeIcon from '../images/motorBikeIcon.svg';
import meatIcon from '../images/meatIcon.svg';

const BlogPromotionalFeatures = () => (
  <div className="blog-promotional-features-main">
    <div className="blog-promotional-features-card">
      <img src={cutIcon} alt="ícone de corte da carne"></img>
      <span>O corte é do jeito que você quiser!</span>
      <p>Filés, cubos, tiras, moído</p>
    </div>
    <div className="blog-promotional-features-card">
      <img src={motorBikeIcon} alt="ícone de frete grátis"></img>
      <span>Pedidos acima de R$150 o frete é gratis!</span>
      <p>Entregas de segunda a sábado.</p>
    </div>
    <div className="blog-promotional-features-card">
      <img src={meatIcon} alt="ícone de porcionamento a vácuo"></img>
      <span>Porcione a vácuo para o dia a dia!</span>
      <p>Porções de 500g, 1kg e 1,5kg</p>
    </div>
  </div>
);

export default BlogPromotionalFeatures;
