import './wdyr';
import './localStoragePolyfill';
import './matchMediaPolyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import configureStore from './configureStore';
// Styles
import './index.css';

// Related to pre-render
// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__;

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__;

// Configure our store
const store = configureStore(preloadedState || { user: { loggedIn: false } });

// Tell react-snap how to save Redux state
window.snapSaveState = () => {
  const preloadedReducers = store.getState();
  return {
    __PRELOADED_STATE__: {
      products: preloadedReducers.products,
      departments: preloadedReducers.departments,
      posts: preloadedReducers.posts,
    },
  };
};

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
