// @flow
import React from 'react';
// Styles
import './DownloadApp.css';
// Assets
import downloadIcon from '../images/download.svg';
import iosIcon from '../images/ios.svg';
import androidIcon from '../images/android.svg';
import closeIcon from '../images/close.svg';

type Props = {
  handleClose: () => void,
};

const DownloadApp = ({ handleClose }: Props) => {
  return (
    <div className="download-app-wrapper">
      <button className="download-app-close-button" onClick={handleClose}>
        <img src={closeIcon} alt="Fechar" />
      </button>
      <div className="download-app-content">
        <img src={downloadIcon} alt="download" />
        <div className="download-app-text">
          <h3>Baixe agora nosso app!</h3>
          <p>e não perca nenhuma oferta!</p>
        </div>
      </div>
      <div className="download-app-platforms">
        <a
          href="https://play.google.com/store/apps/details?id=com.bigdelivery.salesapp&hl=pt_BR&gl=US&pli=1"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="download-app-platforms-icon"
            src={androidIcon}
            alt="Android"
          />
        </a>
        <a
          href="https://apps.apple.com/br/app/bigdelivery/id1521057804"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="download-app-platforms-icon"
            src={iosIcon}
            alt="ios"
          />
        </a>
      </div>
    </div>
  );
};

export default DownloadApp;
