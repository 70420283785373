// @flow
import { createSelector } from 'reselect';
// Helpers
import _ from 'lodash'
// Types
import type { StoreState } from '../types';

export const thisStateSelector = (state: StoreState): State => {
  return state.posts;
};
// select post by slug from state
export const getPostBySlugSelector = (state: StoreState, slug: string) => {
  const localState = thisStateSelector(state.posts);
  const post = _.find(localState, { slug: slug })
  return post || null;
}
// select pinned posts from state
export const pinnedPostsSelector = createSelector(
  thisStateSelector,
  state => state.indexPinned.map(id => state.posts[id]),
);
// select blog posts from state
export const postsBlogSelector = createSelector(
  thisStateSelector,
  state => state.indexBlog.map(id => state.posts[id]),
);
// select news posts from state
export const postsNewsSelector = createSelector(
  thisStateSelector,
  state => state.indexNews.map(id => state.posts[id]),
);

// Loading State related
export const currentPostLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.current,
);

export const currentPostsIndexLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.index,
);
