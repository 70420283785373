// @flow
import React from 'react';
import { slice } from 'lodash';
// Styles
import './BlogRecentPosts.css';
// Components
import { Link } from 'react-router-dom';
import HorizontalDivider from './HorizontalDivider';
import BlogCard from './BlogCard';

type Props = {
  posts: Post[],
};

const { innerWidth } = window;

const POSTS_PER_PAGE = innerWidth > 750 ? 4 : 2;

const BlogRecentPosts = (props): Props => {
  const paginatedPosts = slice(props.posts, 0, POSTS_PER_PAGE);

  if (props.loading && props.posts.length === 0) {
    return (
      <div className="d-flex justify-content-center align-items-center p-3">
        <div className="loading ">
          <i
            className="fa fa-spinner fa-pulse fa-4x loading-icon"
            aria-hidden="true"
          />
        </div>
      </div>
    );
  }
  return (
    <div className="blog-recent-posts-main">
      <HorizontalDivider title="Aprenda mais" />
      {props.title ? <h2>Últimas postagens</h2> : null}
      <div className="blog-recent-posts-grid">
        {paginatedPosts.map((post: any) => (
          <BlogCard key={post.slug} {...post} />
        ))}
      </div>

      <Link to="/blog" className="blog-recent-posts-load-more">
        Ver mais
      </Link>
    </div>
  );
};

export default BlogRecentPosts;
