// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
// Helper
import {
  getTimeWindow,
  getTimeFormat2L,
  phone,
  priceConverter,
} from '../../common';
// Composers
import { withRouter } from 'react-router-dom';
// Selectors
import {
  currentOrderSelector,
  currentOrderLineItemsSelector,
  gettingOrderLoadingSelector,
  currentOrderSelectedShipmentSelector,
} from '../selectors';
import { copyingOrderLoadingSelector } from '../../current-order/selectors';
//Conectors
// TODO: FIX THIS IMPORT!! For some reason cannot import from index.js
import currentOrderNumberConnector from '../../current-order/connectors/currentOrderNumberConnector';
//Styles
import './PastOrderPage.css';
// Actions
import { getPastOrder } from '../actions';
import { copyingOrder } from '../../current-order/actions';
// Components
import { PageLoader, ConfirmationModal } from '../../common';
import NoOrderFound from '../components/NoOrderFound';
import LineItemList from '../components/LineItemList';
import OrderTotal from '../components/OrderTotal';
import { MyAccountAddressCard } from '../../user';
import OrderTracker from '../../common/components/OrderTracker';
// Types
import type { Dispatch } from '../../types';
import type { Order } from '../types';
// Images
import phoneIcon from '../images/phone.svg';

type Props = {
  order: Order[],
  loading: boolean,
  lineItems: LineItem[],
  shippingRate: any,
  getPastOrder: (orderNumber: string) => void,
  copyingOrderLoading: boolean,
};

class PastOrderPage extends Component {
  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
    };
    // Get past orders
    this.props.getPastOrder(this.props.match.params.orderNumber);
  }

  handleCopyOrder = (orderNumber, orderNumberFrom) => {
    this.props.copyingOrder(orderNumber, orderNumberFrom);
    this.handleToggleModal();
  };

  handleToggleModal = () => {
    this.setState(prevState => ({
      ...prevState,
      showModal: !prevState.showModal,
    }));
  };

  render() {
    const order: Order = this.props.order;
    const showModal = this.state.showModal;
    if (this.props.loading) {
      return <PageLoader />;
    }
    const whatsAppMessage = `Olá. Gostaria de ter mais informações sobre ${
      this.props.currentOrderNumber
        ? `o meu pedido ${this.props.currentOrderNumber}`
        : 'a BigDelivery'
    }`;
    // If the order does not exist we should return an error page
    if (!order) {
      return <NoOrderFound />;
    }

    const shippingRate = this.props.shippingRate;
    const payment = _.last(order.payments); // Last payment used

    return (
      <div className="past-order-main">
        <ConfirmationModal
          isOpen={showModal}
          onClose={this.handleToggleModal}
          onConfirm={() =>
            this.handleCopyOrder(
              this.props.currentOrderNumber,
              this.props.order.number,
            )
          }
          text={`Itens do pedido ${this.props.currentOrderNumber} serão adicionados ao seu carrinho (pedido ${this.props.order.number}). Deseja continuar?`}
        />
        <section className="past-order-header">
          <h1 className="past-order-title">Detalhes do Pedido</h1>
          <div className="past-order-header-actions">
            <a
              className="past-order-header-action"
              href={`https://wa.me/${phone.whatsAppNumber}?text=${whatsAppMessage}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={phoneIcon} alt="Call center" />
              Ajuda com pedido
            </a>
            <button
              className="past-order-header-action-alt"
              disabled={this.props.copyingOrderLoading}
              onClick={() => this.handleToggleModal()}
            >
              {this.props.copyingOrderLoading ? (
                <i className="fa fa-spinner fa-pulse" />
              ) : (
                'Copiar pedido'
              )}
            </button>
          </div>
        </section>
        <section className="past-order-info">
          <div className="past-order-info-item">
            <span className="past-order-info-item-text">Número do pedido</span>
            <h4 className="past-order-info-item-title">#{order?.number}</h4>
          </div>
          <div className="past-order-info-item">
            <span className="past-order-info-item-text">Status</span>
            <h4 className="past-order-info-item-title">Entregue</h4>
          </div>
          {shippingRate && (
            <div className="past-order-info-item">
              <span className="past-order-info-item-text">Data e horário</span>
              <h4 className="past-order-info-item-title">
                {getTimeFormat2L(shippingRate.deliveryWindowStart)}{' '}
                {shippingRate.name} das{' '}
                {getTimeWindow(
                  shippingRate.deliveryWindowStart,
                  shippingRate.deliveryWindowEnd,
                )}
              </h4>
            </div>
          )}

          {payment && (
            <div className="past-order-info-item">
              <span className="past-order-info-item-text">
                Método de pagamento
              </span>
              {payment.paymentMethod.id === 1 ? (
                <>
                  <h4 className="past-order-info-item-title">
                    {payment.paymentMethod.name}{' '}
                  </h4>
                  <span className="past-order-info-item-text">
                    XXX XXX XXX {payment.source.lastDigits}{' '}
                  </span>
                  <span className="past-order-info-item-text">
                    {payment.source.name}
                  </span>
                </>
              ) : (
                <>
                  <h4 className="past-order-info-item-title">
                    {payment.paymentMethod.name}{' '}
                  </h4>
                  {Number(order.appliedStoreCredits) > 0 &&
                    payment.paymentMethod.name !== 'Crédito' && (
                      <span className="past-order-info-item-text">
                        Crédito {priceConverter(order.appliedStoreCredits)}
                      </span>
                    )}
                </>
              )}
            </div>
          )}
        </section>

        <h3 className="past-order-title">Endereço</h3>
        <div className="past-order-information">
          <MyAccountAddressCard
            address={order.shipAddress}
            showActions={false}
          />
          <div className="past-order-information-container-right">
            {this.props.order.saleReceiptUrl &&
              ((
                <a
                  className="see-nfe-button"
                  href={`${this.props.order.saleReceiptUrl}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i class="fa fa-file-text-o" aria-hidden="true"></i> VER NF-e
                </a>
              ) ||
                null)}
          </div>
        </div>
        <h3 className="past-order-title">Andamento do pedido</h3>
        <OrderTracker order={order} loading={this.props.loading} />
        <LineItemList
          orderNumber={this.props.order.number}
          items={this.props.lineItems}
        />
        <OrderTotal
          itemTotal={order.itemTotal}
          lineItems={this.props.lineItems}
          shipTotal={order.shipTotal}
          adjustmentTotal={order.adjustmentTotal}
          availableStoreCredits={order.availableStoreCredits}
          appliedStoreCredits={order.appliedStoreCredits}
          appliedBigClubCredits={order.bigclub?.applied}
          producedAmount={order.producedAmount}
          fulfillmentState={order.fulfillmentState}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    order: currentOrderSelector(state),
    lineItems: currentOrderLineItemsSelector(state),
    shippingRate: currentOrderSelectedShipmentSelector(state),
    loading: gettingOrderLoadingSelector(state),
    copyingOrderLoading: copyingOrderLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    getPastOrder: (orderNumber: string) => {
      dispatch(getPastOrder(orderNumber));
    },
    copyingOrder: (orderNumber: string, orderNumberFrom: string) => {
      dispatch(copyingOrder(orderNumber, orderNumberFrom));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(
  currentOrderNumberConnector(connector(PastOrderPage)),
);
