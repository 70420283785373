// @flow
import './RefinementMenu.css';
import React, { Component } from 'react';
// Components
import {
  RefinementList,
  ClearRefinements,
  ToggleRefinement,
  Panel,
} from 'react-instantsearch-dom';
import PriceSlider from './PriceSlider';
import MiniColorRow from '../../header/components/MiniColorRow';

type Props = {
  noResults: boolean,
};

class RefinementMenu extends Component {
  props: Props;

  render() {
    return (
      <div>
        <div className="search-page-menu">
          <Panel>
            <MiniColorRow color="grey" text="PREÇO" />
            <PriceSlider attribute="normalized_price" />
          </Panel>
          <Panel>
            <MiniColorRow color="grey" text="FABRICANTE" />
            <RefinementList attribute="manufacturer" />
          </Panel>
          <Panel>
            {/* TODO: Add these after fixing issue with '.' in name */}
            <MiniColorRow color="grey" text="MODO DE PREPARO" />
            <RefinementList attribute="cooking_recommendations.name" />
          </Panel>
          {/*<Panel>
              <MiniColorRow color="grey" text="TIPO DE CORTE" />
              <RefinementList attributeName="option_values.presentation" />
            </Panel>*/}

          <MiniColorRow color="grey" text="PROMOÇÃO" />
          <ToggleRefinement attribute="on_sale" label="Promoção" value={true} />

          <div className="search-page-hits-clear">
            <ClearRefinements
              translations={{
                reset: 'Resetar filtros',
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default RefinementMenu;
