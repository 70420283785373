// @flow
import {useEffect, useRef} from 'react';
// Analytics
import { logUserScreenTime } from '../analytics';

const useScreenTimeLogger = (screenName: string) => {
  const startTime = useRef(Date.now());

  useEffect(() => {
    const startTimeValue = startTime.current;
    return () => {
      const endTime = Date.now();
      const duration = Math.round((endTime - startTimeValue) / 1000);
      logUserScreenTime(screenName, duration);
    };
    //eslint-disable-next-line
  }, []);
};

export default useScreenTimeLogger;