// @flow
import React from 'react';
// Composers
import { connect } from 'react-redux';
// Actions
import {
  notifyMeWhenStockRenewed,
  unnotifyMeWhenStockRenewed,
} from '../actions';
// Selectors
import {
  shouldNotifyUserLoadingByIdSelector,
  shouldNotifyUserSelector,
} from '../selectors';
// Styles
import './NotifyMeWhenStockRenewed.css';

type Props = {
  productId: number,
  loggedIn: boolean,
  showNotifyMeButton: boolean,
  isLoading: boolean,
  shouldNotifyUser: boolean,
  handleNotifyMeWhenStockRenewed: (productId: number) => void,
  handleUnnotifyMeWhenStockRenewed: (productId: number) => void,
};

const NotifyMeWhenStockRenewed = ({
  productId,
  loggedIn,
  showNotifyMeButton,
  // Internal Props
  isLoading,
  shouldNotifyUser,
  handleNotifyMeWhenStockRenewed,
  handleUnnotifyMeWhenStockRenewed,
}: Props) => {
  if (isLoading) {
    return (
      <div className="notify-me-button loading-button">
        <div className="notify-me-button-wrapper">
          <i
            className="fa fa-spinner fa-pulse loading-icon"
            aria-hidden="true"
          />
        </div>
      </div>
    );
  }

  return (
    <button
      className="notify-me-button"
      disabled={!loggedIn || !showNotifyMeButton}
      onClick={() => {
        !shouldNotifyUser
          ? handleNotifyMeWhenStockRenewed(productId)
          : handleUnnotifyMeWhenStockRenewed(productId);
      }}
    >
      <div className="notify-me-button-wrapper">
        <p className="notify-me-button-text">Indisponível</p>
        {loggedIn && showNotifyMeButton && (
          <i
            className={`fa fa-bell-${!shouldNotifyUser ? 'o' : 'slash-o'}`}
            aria-hidden="true"
          ></i>
        )}
      </div>
    </button>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    shouldNotifyUser: shouldNotifyUserSelector(state, ownProps.productId),
    isLoading: shouldNotifyUserLoadingByIdSelector(state, ownProps.productId),
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleNotifyMeWhenStockRenewed: () => {
      dispatch(notifyMeWhenStockRenewed(ownProps.productId));
    },
    handleUnnotifyMeWhenStockRenewed: () => {
      dispatch(unnotifyMeWhenStockRenewed(ownProps.productId));
    },
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(NotifyMeWhenStockRenewed);
