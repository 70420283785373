//@flow
import React, { useState } from 'react';
import { connect } from 'react-redux';
// Helpers
import { priceConverter } from '../../common';
// Styles
import './BigClubIndicator.css';
// Types
import type { Connector } from 'react-redux';
import type { FeaturesConfig } from '../../preferences/types';
// Selectors
import { featuresConfigSelector } from '../../preferences/selectors';
import { bigClubInformationSelector } from '../selectors';
//Asssets
import iconEyeOff from '../images/icon-eye-off.svg';
import iconEye from '../images/icon-eye.svg';

type Props = {
  bigClubInformation: any,
  featuresConfig: FeaturesConfig,
};

const BigClubIndicator = ({ bigClubInformation, featuresConfig }: Props) => {
  const [showBalance, setShowBalance] = useState(true);
  const totalAvailableBigclubCredit =
    bigClubInformation?.totalAvailableBigclubCredit
      ? priceConverter(bigClubInformation?.totalAvailableBigclubCredit)
      : priceConverter(0);

  if (!featuresConfig?.bigclubFeature) {
    return null;
  }

  return (
    <div
      className="big-club-indicator-main"
      title={`Você possui ${totalAvailableBigclubCredit} em de saldo BigClub.`}
    >
      <span className="big-club-indicator-description ">Saldo BigClub</span>
      <div className="big-club-indicator-balance">
        <img
          className="big-club-indicator-balance-show"
          src={showBalance ? iconEye : iconEyeOff}
          alt={showBalance ? 'olho aberto' : 'olho fechado'}
          onClick={() => setShowBalance(!showBalance)}
        />
        <span
          className={`big-club-indicator-balance-value ${
            !showBalance ? 'big-club-indicator-balance-value-hidden' : ''
          }`}
        >
          {totalAvailableBigclubCredit}
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    bigClubInformation: bigClubInformationSelector(state),
    featuresConfig: featuresConfigSelector(state),
  };
};

const connector: Connector<{}, Props> = connect(mapStateToProps);
export default connector(BigClubIndicator);
