// Images
import schedule from '../images/schedule.svg';
import shoppingCart from '../images/carrinhoclique.svg';
import cuttingBoard from '../images/tabuadecorte.svg';
import bag from '../images/bag.svg';
import comment from '../images/comments.jpg';
import meat from '../images/meatAlt.svg';

const BigCustomerData = {
  columnIcons: [
    {
      id: 1,
      img: meat,
      alt: 'Carne',
      text: 'Carne limpa e maciça, basta temperar e cozinhar',
    },
    {
      id: 2,
      img: shoppingCart,
      alt: 'Carrinho de compras',
      text: 'Compre de qualquer lugar, receba em casa',
    },
    {
      id: 3,
      img: bag,
      alt: 'Sacola',
      text: 'Escolha a divisão dos pacotes de acordo com sua necessidade',
    },
    {
      id: 4,
      img: cuttingBoard,
      alt: 'Tábua de corte',
      text: 'Em cubos ou moída?, Você escolhe',
    },
    {
      id: 5,
      img: schedule,
      alt: 'Calendário',
      text: 'Entrega agendada',
    },
  ],
  faqQuestions: [
    {
      id: 1,
      title:
        'Fiz um pedido, gostaria de saber se posso incluir mais produtos ou mudar a data de entrega?',
      body: 'Com certeza! Entre em contato com nossa central pelo WhatsApp (71) 3500-3100 e faremos as alterações.',
      isOpen: false,
    },
    {
      id: 2,
      title: 'Vocês contam com algum cartão-fidelidade?',
      body: 'Ainda não, mas adoraríamos ouvir o seu feedback sobre, sinta-se à vontade para compartilhar conosco.',
      isOpen: false,
    },
    {
      id: 3,
      title: 'Onde eu encontro promoções?',
      body: 'Atualizamos nosso site frequentemente com os melhores preços para oferecer o melhor custo-benefício para nossos clientes. Acompanhe nossas redes sociais e nosso site para não perder nada!',
      isOpen: false,
    },
  ],
  comment: {
    img: comment,
    title: 'O QUE NOSSOS CLIENTES DIZEM',
    text: '"Com o bigdelivery ficou muito mais fácil gerir as coisas por aqui, antes eu tinha dois funcionários só para realizar o processo de compra, moagem e boleamento, agora eu só peço no bigdelivery e já recebo meu blend pronto pra uso!"',
    author: 'Cliente BigDelivery',
  },
};

export default BigCustomerData;
