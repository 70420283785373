// @flow
import { handleActions, combineActions } from 'redux-actions';
import { combineReducers } from 'redux';
import _ from 'lodash';
import {
  loginUserRequest,
  loginUser,
  registerUserRequest,
  registerUser,
  logoutUser,
  fetchInvitedUsersRequest,
  fetchInvitedUsers,
  fetchCashbackOrdersRequest,
  fetchCashbackOrders,
  getUserAddressesRequest,
  getUserAddresses,
  validateUserRequest,
  validateUser,
  updateUserInformationRequest,
  updateUserInformation,
  newUserAddressRequest,
  newUserAddress,
  updateUserAddressRequest,
  updateUserAddress,
  deleteUserAddressRequest,
  deleteUserAddress,
  getUserCreditCardsRequest,
  getUserCreditCards,
  postUserForgotPasswordRequest,
  postUserForgotPassword,
  putUserResetPasswordRequest,
  putUserResetPassword,
  deleteUserCreditCardRequest,
  deleteUserCreditCard,
  updateUserPasswordRequest,
  updateUserPassword,
  getUserProductListsRequest,
  getUserProductLists,
  getUserWishlistRequest,
  getUserWishlist,
  postUserProductListRequest,
  postUserProductList,
  deleteUserProductList,
  deleteUserProductListRequest,
  postUserWishlistRequest,
  postUserWishlist,
  deleteUserWishlistItem,
  getUserWishlistProducts,
  updateSearch,
  clearSearchParams,
  updateSelectedProductListItemsState,
  clearSelectedProductListItemsState,
  addUserProductListToCartRequest,
  addUserProductListToCart,
  getUserProductListItems,
  deleteUserProductListItem,
  deleteUserProductListItemRequest,
  deleteUserWishlistItemRequest,
} from '../actions';

// Types
import type { Reducer } from 'redux';
import type { Action } from '../../types';
import type { Wishlist, ProductList } from '../types';

// User Logged In
const loggedIn: Reducer<string, Action> = handleActions(
  {
    [loginUser]: {
      next: (state, action) => true,
      throw: (state, action) => false,
    },
    [validateUser]: {
      next: (state, action) => true,
      throw: (state, action) => false,
    },
    [logoutUser]: (state, action) => false,
  },
  false,
);

const invitedUsers = handleActions(
  {
    [fetchInvitedUsers]: {
      next: (state, action) => action.payload.entities.invitedUsers,
      throw: (state, action) => state,
    },
    throw: (state, action) => state,
  },
  {},
);

const cashbacks = handleActions(
  {
    [fetchCashbackOrders]: {
      next: (state, action) => action.payload.entities.cashbacks,
      throw: (state, action) => state,
    },
    throw: (state, action) => state,
  },
  {},
);

// ProductList
const initialProductListState: ProductList = {
  result: {
    productLists: [],
    count: 0,
    currentPage: 1,
    pages: 0,
  },
  resultItems: {
    productListItems: [],
    count: 0,
    pages: 0,
    currentPage: 0,
  },
  items: [],
};

const productList = handleActions(
  {
    [getUserProductLists]: {
      next: (state, action) => ({
        ...state,
        result: action.payload.entities.productList[action.payload.result],
      }),
      throw: (state, action) => state,
    },
    [getUserProductListItems]: {
      next: (state, action) => ({
        ...state,
        resultItems: action.payload.entities.productList[action.payload.result],
      }),
    },
    [postUserProductList]: {
      next: (state, action) => {
        return state;
      },
      throw: (state, action) => state,
    },
    [deleteUserProductList]: {
      next: (state, action) => state,
      throw: (state, action) => state,
    },
    [deleteUserProductListItem]: {
      next: (state, action) => state,
      throw: (state, action) => state,
    },
    [updateSelectedProductListItemsState]: {
      next: (state, action) => {
        const items: ProductList['items'] = state.items;
        const result = items.find(item => item.variantId === action.payload);
        if (result)
          return {
            ...state,
            items: items.filter(item => item.variantId !== action.payload),
          };
        items.push({ variantId: action.payload });
        return { ...state, items };
      },
      throw: (state, action) => state,
    },
    [clearSelectedProductListItemsState]: {
      next: (state, action) => {
        return { ...state, items: [] };
      },
      throw: (state, action) => state,
    },
    [addUserProductListToCart]: {
      next: (state, action) => state,
      throw: (state, action) => state,
    },
    throw: (state, action) => state,
  },
  initialProductListState,
);

// Wishlist
const initialWishlistState: Wishlist = {
  id: null,
  itemsId: [],
  productListItems: [],
  count: 0,
  pages: 1,
  currentPage: 1,
};

const wishlist = handleActions(
  {
    [getUserWishlist]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.wishlist[action.payload.result],
      }),
      throw: (state, action) => state,
    },
    [getUserWishlistProducts]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.productList.result,
      }),
      throw: (state, action) => state,
    },
    [postUserWishlist]: {
      next: (state, action) => state,
      throw: (state, action) => state,
    },
    [deleteUserWishlistItem]: {
      next: (state, action) => state,
      throw: (state, action) => state,
    },
    [updateSearch]: {
      next: (state, action) => state,
      throw: (state, action) => state,
    },
    [clearSearchParams]: {
      next: (state, action) => ({
        ...state,
        count: 0,
        pages: 1,
        currentPage: 1,
      }),
      throw: (state, action) => state,
    },
    throw: (state, action) => state,
  },
  initialWishlistState,
);

// Information
const initialUserInformation = {
  firstName: '',
  lastName: '',
  cpf: '',
  email: '',
  totalAvailableStoreCredit: 0.0,
  totalReferralEarnings: 0.0,
  branchLink: '',
  referralCode: '',
  addresses: [],
  creditCards: [],
};
const information = handleActions(
  {
    [combineActions(loginUser, validateUser, updateUserInformation)]: {
      next: (state, action) =>
        action.payload.entities.user[action.payload.result],
      throw: (state, action) => initialUserInformation,
    },
    [logoutUser]: (state, action) => initialUserInformation,
    [getUserAddresses]: {
      next: (state, action) => ({
        ...state,
        addresses: action.payload.result,
      }),
      throw: (state, action) => state,
    },
    [newUserAddress]: {
      next: (state, action) => ({
        ...state,
        addresses: [...state.addresses, action.payload.result],
      }),
      throw: (state, action) => state,
    },
    [deleteUserAddress]: {
      next: (state, action) => ({
        ...state,
        addresses: _.filter(state.addresses, o => o !== action.payload),
      }),
      throw: (state, action) => state,
    },
    [getUserCreditCards]: {
      next: (state, action) => ({
        ...state,
        creditCards: action.payload.result || [],
      }),
      throw: (state, action) => state,
    },
  },
  initialUserInformation,
);

// Loading
const initialLoadingState = {
  loggingIn: false,
  registering: false,
  validating: false,
  gettingAddresses: false,
  addingAddress: false,
  editingAddress: false,
  deletingAddress: [], // IDs of the addresses that are being deleted
  editingInformation: false,
  gettingCreditCards: false,
  gettingInvitedUsers: false,
  gettingCashbacks: false,
  forgotPassword: false,
  resetPassword: false,
  deletingCreditCard: false,
  updatingPassword: false,
  productListing: false,
  wishlistLoading: false,
  wishlistProductsLoading: false,
  wishlistLoadingProductItem: false,
  deletingProductList: false,
  addingProductListToCart: false,
  gettingProductListItems: false,
  deletingProductListItem: false,
};
const loading = handleActions(
  {
    [loginUserRequest]: (state, action) => ({ ...state, loggingIn: true }),
    [loginUser]: (state, action) => ({ ...state, loggingIn: false }),
    [registerUserRequest]: (state, action) => ({ ...state, registering: true }),
    [registerUser]: (state, action) => ({ ...state, registering: false }),
    [validateUserRequest]: (state, action) => ({ ...state, validating: true }),
    [validateUser]: (state, action) => ({ ...state, validating: false }),
    [fetchInvitedUsersRequest]: (state, action) => ({
      ...state,
      gettingInvitedUsers: true,
    }),
    [fetchInvitedUsers]: (state, action) => ({
      ...state,
      gettingInvitedUsers: false,
    }),
    [fetchCashbackOrdersRequest]: (state, action) => ({
      ...state,
      gettingCashbacks: true,
    }),
    [fetchCashbackOrders]: (state, action) => ({
      ...state,
      gettingCashbacks: false,
    }),
    [getUserAddressesRequest]: (state, action) => ({
      ...state,
      gettingAddresses: true,
    }),
    [getUserAddresses]: (state, action) => ({
      ...state,
      gettingAddresses: false,
    }),
    [newUserAddressRequest]: (state, action) => ({
      ...state,
      addingAddress: true,
    }),
    [newUserAddress]: (state, action) => ({ ...state, addingAddress: false }),
    [updateUserAddressRequest]: (state, action) => ({
      ...state,
      editingAddress: true,
    }),
    [updateUserAddress]: (state, action) => ({
      ...state,
      editingAddress: false,
    }),
    [deleteUserAddressRequest]: (state, action) => ({
      ...state,
      deletingAddress: _.union(state.deletingAddress, [action.payload]),
    }),
    [deleteUserAddress]: (state, action) => ({
      ...state,
      deletingAddress: _.filter(
        state.deletingAddress,
        o => o !== action.payload,
      ),
    }),
    [updateUserInformationRequest]: (state, action) => ({
      ...state,
      editingInformation: true,
    }),
    [updateUserInformation]: (state, action) => ({
      ...state,
      editingInformation: false,
    }),
    [getUserCreditCardsRequest]: (state, action) => ({
      ...state,
      gettingCreditCards: true,
    }),
    [getUserCreditCards]: (state, action) => ({
      ...state,
      gettingCreditCards: false,
    }),
    [postUserForgotPasswordRequest]: (state, action) => ({
      ...state,
      forgotPassword: true,
    }),
    [postUserForgotPassword]: (state, action) => ({
      ...state,
      forgotPassword: false,
    }),
    [putUserResetPasswordRequest]: (state, action) => ({
      ...state,
      resetPassword: true,
    }),
    [putUserResetPassword]: (state, action) => ({
      ...state,
      resetPassword: false,
    }),
    [deleteUserCreditCardRequest]: (state, action) => ({
      ...state,
      deletingCreditCard: true,
    }),
    [deleteUserCreditCard]: (state, action) => ({
      ...state,
      deletingCreditCard: false,
    }),
    [updateUserPasswordRequest]: (state, action) => ({
      ...state,
      updatingPassword: true,
    }),
    [updateUserPassword]: (state, action) => ({
      ...state,
      updatingPassword: false,
    }),
    [postUserProductListRequest]: (state, action) => ({
      ...state,
      productListing: true,
    }),
    [postUserProductList]: (state, action) => ({
      ...state,
      productListing: false,
    }),
    [getUserProductListsRequest]: (state, action) => ({
      ...state,
      productListing: true,
    }),
    [getUserProductLists]: (state, action) => ({
      ...state,
      productListing: false,
    }),

    [deleteUserProductListRequest]: (state, action) => ({
      ...state,
      deletingProductList: true,
    }),
    [deleteUserProductList]: (state, action) => ({
      ...state,
      deletingProductList: false,
    }),
    [getUserWishlistRequest]: (state, action) => ({
      ...state,
      wishlistLoading: true,
    }),
    [getUserProductListsRequest]: (state, action) => ({
      ...state,
      wishlistProductsLoading: true,
    }),
    [getUserProductLists]: (state, action) => ({
      ...state,
      wishlistProductsLoading: false,
    }),
    [getUserWishlist]: (state, action) => ({
      ...state,
      wishlistLoading: false,
    }),
    [postUserWishlistRequest]: (state, action) => ({
      ...state,
      wishlistLoadingProductItem: true,
    }),
    [postUserWishlist]: (state, action) => ({
      ...state,
      wishlistLoadingProductItem: false,
    }),
    [deleteUserWishlistItemRequest]: (state, action) => ({
      ...state,
      wishlistLoadingProductItem: true,
    }),
    [deleteUserWishlistItem]: (state, action) => ({
      ...state,
      wishlistLoadingProductItem: false,
    }),
    [addUserProductListToCartRequest]: (state, action) => ({
      ...state,
      addingProductListToCart: true,
    }),
    [addUserProductListToCart]: (state, action) => ({
      ...state,
      addingProductListToCart: false,
    }),
    [deleteUserProductListItemRequest]: (state, action) => ({
      ...state,
      deletingProductListItem: true,
    }),
    [deleteUserProductListItem]: (state, action) => ({
      ...state,
      deletingProductListItem: false,
    }),
  },
  initialLoadingState,
);

// Addresses
const addresses = handleActions(
  {
    [combineActions(validateUser, getUserAddresses)]: {
      next: (state, action) => action.payload.entities.address,
      throw: (state, action) => state,
    },
    [combineActions(updateUserAddress, newUserAddress)]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.address,
      }),
      throw: (state, action) => state,
    },
  },
  {},
);

// Credit Cards
const creditCards = handleActions(
  {
    [combineActions(getUserCreditCards)]: {
      next: (state, action) => action.payload.entities.creditCards || {},
      throw: (state, action) => state,
    },

    [deleteUserCreditCard]: {
      next: (state, action) => _.omit(state, action.payload),
      throw: (state, action) => state,
    },
  },
  {},
);

// Allowed Payment Methods
const allowedPaymentMethods = handleActions(
  {
    [combineActions(validateUser, loginUser)]: {
      next: (state, action) =>
        action.payload.entities.allowedPaymentMethods || {},
      throw: (state, action) => state,
    },
  },
  {},
);

// Error
const initialErrorState = {
  wrongLoginUsername: false,
  wrongLoginPassword: false,
  registration: {},
  forgotPassword: {},
  resetPassword: {},
  wrongUpdatePassword: false,
};
const errors = handleActions(
  {
    [loginUser]: {
      next: (state, action) => ({
        ...state,
        wrongLoginUsername: false,
        wrongLoginPassword: false,
      }),
      throw: (state, action) => ({
        ...state,
        wrongLoginUsername: true,
        wrongLoginPassword: true,
      }),
    },
    [updateUserPassword]: {
      next: (state, action) => ({
        ...state,
        wrongUpdatePassword: initialErrorState.wrongUpdatePassword,
      }),
      throw: (state, action) => ({
        ...state,
        wrongUpdatePassword: action.error,
      }),
    },
    [registerUser]: {
      next: (state, action) => ({
        ...state,
        registration: initialErrorState.registration,
      }),
      throw: (state, action) => ({
        ...state,
        registration: JSON.parse(action.payload.message),
      }),
    },
    [postUserForgotPassword]: {
      next: (state, action) => ({
        ...state,
        forgotPassword: initialErrorState.forgotPassword,
      }),
      throw: (state, action) => ({
        ...state,
        forgotPassword: JSON.parse(action.payload.message),
      }),
    },
    [putUserResetPassword]: {
      next: (state, action) => ({
        ...state,
        resetPassword: initialErrorState.resetPassword,
      }),
      throw: (state, action) => ({
        ...state,
        resetPassword: JSON.parse(action.payload.message),
      }),
    },
  },
  initialErrorState,
);

const reducers = combineReducers({
  loggedIn,
  information,
  addresses,
  creditCards,
  invitedUsers,
  cashbacks,
  allowedPaymentMethods,
  errors,
  loading,
  productList,
  wishlist,
});

export default reducers;
