// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';

// Components
import Scroller from '../../components/Scroller';
// Actions
import { findRecentlyViewedProducts } from '../../actions';
// Selectors
import {
  recentlyViewedProductsSelector,
  recentlyViewedProductsLoadingSelector,
} from '../../selectors';
// Types
import type { Product } from '../../types';

type Props = {
  products: Product[],
  isLoading: boolean,
  findProducts: () => void,
  hideBottomLine: boolean,
};

class ProductPromotionScroller extends Component {
  constructor(props: Props) {
    super(props);

    // Request products to display in scroller
    props.findProducts();
  }

  render() {
    // Do not show this scroller if we have less than 3 recently viewed products
    if (!this.props.isLoading && this.props.products.length < 3) return null;

    return (
      <Scroller
        title="PRODUTOS VISTOS RECENTEMENTE"
        products={this.props.products}
        isLoading={this.props.isLoading}
        hideBottomLine={this.props.hideBottomLine}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    products: recentlyViewedProductsSelector(state),
    isLoading: recentlyViewedProductsLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    findProducts: () => {
      dispatch(findRecentlyViewedProducts());
    },
  };
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default connector(ProductPromotionScroller);
