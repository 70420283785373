// @flow
import React, { useState } from 'react';
// Components
import LoginBox from './LoginBox';
import RegisterBox from './RegisterBox';
// Styles
import './Login.css';

type Props = {
  alertText: string,
  isLoggingIn: boolean,
  isRegistering: boolean,
  loginErrors: {
    badUsername: boolean,
    badPassword: boolean,
  },
  registrationErrors: {},
  submitLogin: () => void,
  submitRegister: () => void,
  handleOnClickForgotMyPassword: () => void,
  isRegisterTab: boolean,
};

const Login = ({
  isLoggingIn,
  isRegistering,
  loginErrors,
  registrationErrors,
  submitLogin,
  submitRegister,
  isRegisterTab,
}: Props) => {
  const [showOption, setShowOption] = useState(
    isRegisterTab ? 'register' : 'login',
  );

  return (
    <section className="login-main">
      <div className="login-tabs">
        <button
          className={`login-tab-button ${
            showOption === 'register' && 'login-tab-button-selected'
          }`}
          onClick={() => setShowOption('register')}
        >
          Quero me cadastrar
        </button>
        <button
          onClick={() => setShowOption('login')}
          className={`login-tab-button ${
            showOption === 'login' && 'login-tab-button-selected'
          }`}
        >
          Já sou cadastrado
        </button>
      </div>
      <div className="login-content">
        {showOption === 'register' ? (
          <RegisterBox
            onSubmitRegister={submitRegister}
            isLoading={isRegistering}
            errors={registrationErrors}
          />
        ) : (
          <LoginBox
            onSubmitLogin={submitLogin}
            isLoading={isLoggingIn}
            errors={loginErrors}
          />
        )}
      </div>
    </section>
  );
};

export default Login;
