// @flow
import React, { useEffect, useState } from 'react';
//Components
import { Helmet } from 'react-helmet';
import { ColorRow } from '../../common';
import ProductFrequentlyPurchasedTogetherScroller from '../containers/scrollers/ProductFrequentlyPurchasedTogetherScroller';
import ProductPromotionScroller from '../containers/scrollers/ProductPromotionScroller';
import MainProductContent from './MainProductContent';
import MainProductDetails from './MainProductDetails';
// Analytics
import * as Analytics from '../../common/analytics';
// import RecipeDisplay from '../components/RecipeDisplay' // TODO: Recipes
import PageLoader from '../../common/components/PageLoader';
import NoProduct from './NoProduct';
// Styles
import './ProductPage.css';
//Helpers
import { useScreenTimeLogger } from '../../common';
// Types
import type { Product } from '../types';

export type Props = {
  product: Product,
  loading: boolean,
  recipes: any,
  copyProductLinkByVariantIdAndUserId: (
    variantId: number,
    userId: number,
    slug: string,
  ) => void,
  userId: number,
  loadingShareableLink: boolean,
};

export type State = {
  showFullRecipes: boolean,
};

const ProductPage = ({
  match,
  product,
  recipes,
  loading,
  copyProductLinkByVariantIdAndUserId,
  userId,
  loadingShareableLink,
}) => {
  // USE ANALYTICS
  const [lastProductId, setLastProductId] = useState(null);
  const [isCopiedProductLink, setIsCopiedProductLink] = useState(false);
  useScreenTimeLogger(window.location.pathname);

  useEffect(() => {
    if (
      product &&
      product.id !== lastProductId &&
      product.slug === match.params.id
    ) {
      setLastProductId(product.id);
      Analytics.logProductView(product, window.location.pathname, 'visit');
      setIsCopiedProductLink(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastProductId, match.params.id, product?.id]);
  // USE ANALYTICS

  const generateProductLink = () => {
    copyProductLinkByVariantIdAndUserId(product.id, userId, product.slug);
    setIsCopiedProductLink(true);
  };

  // The loaders shows when the loading state is true and the product is not in the reducer
  if (!product && loading) {
    return (
      <div>
        <ColorRow color="grey" text={''} />
        <PageLoader />
      </div>
    );
  }

  // TODO: Add updating loader! So that the user knows that info on the page is still being updated.

  if (!product) return <NoProduct />; // TODO: Product does not exist page!

  return (
    <div className="product-page-main">
      {
        // <!-- Open Graph / Facebook -->
      }
      <Helmet>
        <title>{product.name} - Bigdelivery - Seu açougue virtual</title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:title"
          content={`${product.name} - Bigdelivery - Seu açougue virtual`}
        />
        <meta property="og:description" content={product.description} />
        <meta property="og:locale" content="pt_BR" />
        <meta property="og:site_name" content="Bigdelivery.com.br" />
        <meta property="keywords" content={product.metaTags} />
        <meta
          property="og:availability"
          content={`${product.inStock ? 'in stock' : 'out of stock'}`}
        />
      </Helmet>
      <Helmet>
        {
          // <!-- Primary Meta Tags -->
        }
        <meta
          name="title"
          content={`${product.name} - Bigdelivery - Seu açougue virtual`}
        />
        <meta name="description" content={product.description} />
      </Helmet>
      <Helmet>
        {
          // <!-- Twitter -->
        }
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={window.location.href} />
        <meta
          property="twitter:title"
          content={`${product.name} - Bigdelivery - Seu açougue virtual`}
        />
        <meta property="twitter:description" content={product.description} />
      </Helmet>
      {
        // <!-- Images related -->
      }
      {product.images.length > 0 && (
        <Helmet>
          <meta property="og:image" content={product.images[0].largeUrl} />
          <meta property="twitter:image" content={product.images[0].largeUrl} />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:image:width" content="600" />
          <meta property="og:image:height" content="340" />
          <meta property="og:image:alt" content={product.images[0].alt} />
        </Helmet>
      )}

      <MainProductContent
        product={product}
        userId={userId}
        isCopiedProductLink={isCopiedProductLink}
        loadingShareableLink={loadingShareableLink}
        generateProductLink={generateProductLink}
      />
      <MainProductDetails product={product} recipes={recipes} />

      <ProductFrequentlyPurchasedTogetherScroller variantId={product.id} />
      <ProductPromotionScroller title={'Promoções'}/>
    </div>
  );
};

export default ProductPage;
