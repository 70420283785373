// @flow
import { useEffect } from 'react';
import { connect } from 'react-redux';
// Actions
import { getWishlist } from '../actions';
// Types
import type { Connector } from 'react-redux';
import type { Dispatch } from '../../types';
// Selectors
import { loggedInSelector } from '../selectors';

type Props = {
  getWishlist: () => void,
  loggedIn: boolean,
};

const Wishlist = ({ getWishlist, wishlistLoading, loggedIn }: Props) => {
  useEffect(() => {
    if (loggedIn) {
      getWishlist();
    }
    // eslint-disable-next-line
  }, [loggedIn]);

  return null;
};

const mapStateToProps = state => {
  return {
    loggedIn: loggedInSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: {}) => {
  return {
    getWishlist: () => {
      dispatch(getWishlist());
    },
  };
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default connector(Wishlist);
