// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Selectors
import { allowedPaymentMethodsSelector, userIdSelector } from '../selectors';
// Types
import type { PaymentMethods } from '../types';
import type { Dispatch } from '../../types';

type OwnProps = {
  allowedPaymentMethods: PaymentMethods[],
  userId: number,
  allowedPaymentMethodsLoading: boolean,
};

function userAllowedPaymentMethodsConnector(WrappedComponent: ReactClass<any>) {
  const mapStateToProps = (state, ownProps: OwnProps) => {
    return {
      allowedPaymentMethods: allowedPaymentMethodsSelector(state),
      userId: userIdSelector(state),
    };
  };

  const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
    return {};
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class userAllowedPaymentMethodsConnector extends Component {
      render() {
        return (
          <WrappedComponent
            {...this.props}
            allowedPaymentMethods={this.props.allowedPaymentMethods}
          />
        );
      }
    },
  );
}

export default userAllowedPaymentMethodsConnector;
