// @flow
import React, { Component } from 'react';
// Styles
import './NoOrderFound.css';
// Images
import PorkStamp from '../../static/images/porkOnSkate.png';
// Other
import { phone } from '../../common';

type Props = {};

class NoOrderFound extends Component {
  props: Props;
  render() {
    return (
      <div className="no-order-found-main">
        <img
          className="no-order-found-stamp"
          src={PorkStamp}
          alt="Porco no skate"
        />
        <p>
          Não encontramos esse pedido. Entre em contato com nosso suporte no
          telefone {phone.asStringFull}.
        </p>
      </div>
    );
  }
}

export default NoOrderFound;
