// @flow
import React, { useState } from 'react';
// Styles
import './BlackFridayQuestionsAndAnswers.css';
// Assets
import expandIcon from '../images/expand-icon.svg';
import expandLessIcon from '../images/expand-less-icon.svg';

const BlackFridayQuestionsAndAnswers = ({ question, answer }) => {
  const [showAnswer, setShowAnswer] = useState(false);

  const changeShowContainer = () => {
    setShowAnswer(prevState => !prevState);
  };

  return (
    <div
      className={
        showAnswer
          ? 'black-friday-answers-main-container-open'
          : 'black-friday-answers-main-container-closed'
      }
    >
      <div className="black-friday-answers-question-container">
        <p
          className={
            showAnswer
              ? 'black-friday-answers-question-text-open'
              : 'black-friday-answers-question-text-closed'
          }
        >
          {question}
        </p>
        <button onClick={changeShowContainer}>
          <img
            src={showAnswer ? expandIcon : expandLessIcon}
            alt="Seta de expansão de texto"
          />
        </button>
      </div>
      {showAnswer && (
        <div className="black-friday-answers-answer-container">
          <p>{answer}</p>
        </div>
      )}
    </div>
  );
};

export default BlackFridayQuestionsAndAnswers;
