// @flow
import React, { Component } from 'react';
import _ from 'lodash';
import './MyAccountAddress.css';
// Components
import AddressItem from './MyAccountAddressItem';
import AddressAddNewButton from './MyAccountAddressAddNewButton';
import AddressEditor from './MyAccountAddressEditor';
import { PageLoader } from '../../../common';
// Types
import type { Address } from '../../types';

const confirmAction = (text: string, actionToExecute: () => void) => {
  const r = window.confirm(text);

  if (r) {
    actionToExecute();
  }
};

type State = {
  editingId: ?number, // ID of the address that is currently being edited
  isAdding: boolean, // Should the menu for adding a new address be open?
};

type Props = {
  addresses: Address[],
  onAddAddress: (address: Address) => void,
  onEditAddress: (id: number, address: Address) => void,
  onSubmitNew: (address: Address) => void,
  onSubmitUpdate: (address: Address) => void,
  onSubmitDelete: (addressId: number) => void,
  loading: boolean,
  editingLoading: boolean,
  addingLoading: boolean,
  deletingLoading: (id: number) => boolean,
};

class MyAccountAddress extends Component {
  state: State;
  constructor(props: Props) {
    super(props);

    this.state = {
      editingId: null,
      isAdding: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    // If the number of addresses in the list has increased we should close any open editor
    if (
      this.props.addresses &&
      nextProps.addresses.length > this.props.addresses.length
    ) {
      this.setState({ isAdding: false });
    }

    if (
      this.props.editingLoading &&
      !nextProps.editingLoading &&
      !_.isEqual(this.props.addresses, nextProps.addresses)
    ) {
      this.setState({ editingId: null });
    }
  }

  render() {
    if (this.props.loading) {
      // Loading state
      return <PageLoader />;
    }
    return (
      <div className="my-account-address-main">
        <div className="my-account-address-header">
          <h3>Meus endereços</h3>
          <AddressAddNewButton
            isEditing={this.state.isAdding}
            onClick={() => this.setState({ isAdding: !this.state.isAdding })}
          />
        </div>
        <div className="my-account-address-content-container">
          {this.state.isAdding ? (
            <AddressEditor
              address={null}
              onSubmitAddress={this.props.onSubmitNew}
              loading={this.props.addingLoading}
            />
          ) : (
            <div className="my-account-address-content">
              {this.props.addresses.map(address => {
                if (this.state.editingId === address.id) {
                  return (
                    <div
                      key={address.id}
                      className="my-account-address-edit-item"
                    >
                      <AddressEditor
                        address={address}
                        loading={this.props.editingLoading}
                        onSubmitAddress={this.props.onSubmitUpdate}
                        isEditing={true}
                        onClickEdit={() => this.setState({ editingId: null })}
                      />
                    </div>
                  );
                }
                return (
                  <div>
                    <hr></hr>
                    <AddressItem
                      key={address.id}
                      address={address}
                      deletingLoading={this.props.deletingLoading(address.id)}
                      onClickEdit={() =>
                        this.setState({ editingId: address.id })
                      }
                      onClickDelete={() =>
                        confirmAction('Deletar o endereço?', () =>
                          this.props.onSubmitDelete(address.id),
                        )
                      }
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default MyAccountAddress;
