// Components
import { Link } from 'react-router-dom';
// Styles
import './BlogCard.css';

type Props = {
  slug: string,
  title: string,
  description: string,
  thumbnailImageUrl: string,
};

export const BlogCard = (props: Props) => {
  return (
    <Link to={`/blog/${props.slug}`} className="blog-anchor-card-container">
      <img
        className="blog-anchor-card-image"
        src={props.thumbnailImageUrl}
        alt={props.title}
      />
      <h3 className="blog-anchor-card-title">{props.title}</h3>
      <p className="blog-anchor-card-description">{props.description}</p>
    </Link>
  );
};

export default BlogCard;
