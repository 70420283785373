// @flow
import React from 'react';
// Components
import { SearchBox } from 'react-instantsearch-dom';
// Styles
import './UnconnectedWishlistSearchInput.css';

const UnconnectedWishlistSearchInput = () => {
  return (
    <div id="wishlist-search">
      <SearchBox
        className="one"
        searchAsYouType={false}
        translations={{ placeholder: 'O que está procurando?' }}
      />
    </div>
  );
};

export default UnconnectedWishlistSearchInput;
