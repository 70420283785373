// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
// Components
import { Helmet } from 'react-helmet';
import {
  ProductPromotionScroller,
  ProductRecommendedForUserScroller,
} from '../../products';
import BigClubBanner from '../components/BigClubBanner';
import BlogRecentPosts from '../components/BlogRecentPosts';
import DepartmentCards from '../components/DepartmentCards';
import ImageCarousel from '../components/ImageCarousel';
import InfoCards from '../components/InfoCards';
import LastOrderInfo from '../components/LastOrderInfo';
import NewsLetter from '../components/NewsLetter';
import TimedPromotion from '../components/TimedPromotion';
// Selectors
import { postsBlogSelector } from '../../blog/selectors';
import { indexDepartmentsSelector } from '../../departments/selectors';
import { featuresConfigSelector } from '../../preferences/selectors';
import { userIdSelector } from '../../user/selectors';
import * as selectors from '../selectors';
// Actions
import { findPostsBlog } from '../../blog/actions';
import { findDepartmentsIndex } from '../../departments/actions';
import * as actions from '../actions';
// Types
import type { Connector } from 'react-redux';
import type { Department } from '../../departments/types';
import type { FeaturesConfig } from '../../preferences/types';
import type { IBanner, ScheduledDiscount } from '../types';
// Styles
import './HomePage.css';
// Data
import { infoCards } from '../data/infoCardsData';
//Helpers
import { useScreenTimeLogger } from '../../common';

type Props = {
  fetchBanners: () => void,
  getDepartments: () => void,
  getScheduledDiscounts: () => void,
  banners: Array<IBanner>,
  scheduledDiscounts: ScheduledDiscount[],
  scheduledDiscountsLoading: boolean,
  bannersLoading: boolean,
  departments: Array<Department>,
  featuresConfig: FeaturesConfig,
  userId: number,
};

const HomePage = (props: Props) => {
  useScreenTimeLogger(props.location.pathname);
  const {
    banners,
    bannersLoading,
    fetchBanners,
    postsBlog,
    findPostsBlog,
    departments,
    getDepartments,
    loadingNewsLetterEmail,
    postNewsLetterEmail,
    featuresConfig,
    scheduledDiscounts,
    getScheduledDiscounts,
  } = props;

  useEffect(() => {
    fetchBanners();
    getDepartments();
    findPostsBlog();
    getScheduledDiscounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { innerWidth } = window;

  const images = banners.map((banner, index) => ({
    src: innerWidth > 480 ? banner.desktopImageUrl : banner.mobileImageUrl,
    alt: banner.alt,
    link: banner.desktopNavigateToUrl,
  }));

  return (
    <div className="home-container">
      <Helmet>
        <title>Bigdelivery, Seu açougue virtual</title>
        <meta property="og:title" content="Bigdelivery - Seu açougue virtual" />
        <meta
          name="description"
          content="Carne fresca e de qualidade na porta da sua casa, na sua hora e do seu jeito."
        />
        <meta
          name="og:description"
          content="Carne fresca e de qualidade na porta da sua casa, na sua hora e do seu jeito."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/white_logo_on_red_background.jpg`}
        />
        <meta property="og:image:type" content="image/jpg" />
        <meta property="og:image:alt" content="Bigdelivery logo" />
      </Helmet>

      {images.length > 0 || !bannersLoading ? (
        <ImageCarousel images={images} />
      ) : (
        <div className="banners-loading">
          <i className="fa fa-spinner fa-pulse fa-1x" aria-hidden="true"></i>
        </div>
      )}
      <InfoCards cards={infoCards} />
      <DepartmentCards departmentsData={departments} />
      {scheduledDiscounts.map((scheduledDiscount, index) => (
        <TimedPromotion key={index} promotion={scheduledDiscount} />
      ))}
      <LastOrderInfo />
      <ProductRecommendedForUserScroller title="Nossa Seleção" />
      <BigClubBanner
        showBanner={
          featuresConfig?.bigclubFeature && featuresConfig?.cashbackFeature
        }
        userId={props.userId}
      />
      <ProductPromotionScroller title="Promoções" />
      <BlogRecentPosts posts={postsBlog} />
      <NewsLetter
        postNewsLetterEmail={postNewsLetterEmail}
        loadingNewsLetterEmail={loadingNewsLetterEmail}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    departments: indexDepartmentsSelector(state),
    banners: selectors.bannersSelector(state),
    bannersLoading: selectors.bannersLoadingSelector(state),
    featuresConfig: featuresConfigSelector(state),
    loadingNewsLetterEmail: selectors.loadingNewsLetterEmailSelector(state),
    scheduledDiscounts: selectors.scheduledDiscountsSelector(state),
    scheduledDiscountsLoading:
      selectors.scheduledDiscountsLoadingSelector(state),
    postsBlog: postsBlogSelector(state),
    userId: userIdSelector(state),
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchBanners: () => dispatch(actions.fetchAllBanners()),
    postNewsLetterEmail: email => dispatch(actions.storeNewsLetterEmail(email)),
    getDepartments: () => dispatch(findDepartmentsIndex()),
    findPostsBlog: () => dispatch(findPostsBlog()),
    getScheduledDiscounts: () => dispatch(actions.getScheduledDiscounts()),
  };
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default connector(HomePage);

HomePage.whyDidYouRender = true;
