// @flow
import React from 'react';
// Styles
import './KitsBanner.css';
// Assets
import arrow from '../images/black-friday-banner-arrow.svg';
import ArrowRight from '../images/arrow-right.svg';
import kitsImage from '../images/kits-banner-image.png';
// Data
import { framesData } from '../data/kitsData';

const KitsBanner = () => {
  return (
    <div className="kits-banner-main-container">
      <div className="kits-banner-top-content">
        <div className="kits-banner-top-content-text">
          <h1 className="kits-banner-header-title">
            Descubra o nosso <span className="kits-banner-bold-text">KIT</span>
          </h1>
          <p className="kits-banner-header-text">
            Mais de 35 anos de experiência, oferecemos carne de qualidade,
            descontos incríveis e conveniência. Junte-se a milhares de clientes
            e desfrute de uma experiencia incrível!
          </p>
          <a
            id="scrollToSlider"
            href="#kits-product-banner"
            className="kits-banner-home-link"
          >
            Descubra já!
            <img
              className="kits-banner-arrow-icon"
              src={ArrowRight}
              alt="Ir para a página Inicial"
            ></img>
          </a>
        </div>
        <div className="kits-banner-top-content-image">
          <img src={kitsImage} alt="Kit de carnes"></img>
        </div>
      </div>
      <div className="kits-banner-low-container">
        <div className="kits-banner-low-image-container">
          <img src={arrow} alt="Icone de Seta"></img>
        </div>
        <div className="kits-banner-low-content">
          {framesData.map(({ image, title, description }, index) => (
            <div key={index} className="black-friday-banner-frames">
              <img src={image} alt={title} />
              <div>
                <h1>{title}</h1>
                <p>{description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default KitsBanner;
