// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Selectors
import { orderNumberSelector } from '../selectors';
// Actions

// Types
import type { Dispatch } from '../../types';

type OwnProps = {
  currentOrderNumber: ?string,
};

function currentOrderNumberConnector(WrappedComponent: ReactClass<any>) {
  const mapStateToProps = (state, ownProps) => {
    return {
      currentOrderNumber: orderNumberSelector(state),
    };
  };

  const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
    return {};
  };

  return connect(mapStateToProps, mapDispatchToProps)(
    class currentOrderNumberConnector extends Component {
      props: OwnProps;
      render() {
        return (
          <WrappedComponent
            {...this.props}
            currentOrderNumber={this.props.currentOrderNumber}
          />
        );
      }
    },
  );
}

export default currentOrderNumberConnector;
