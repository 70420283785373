// @flow
import _ from 'lodash';
import React, { useEffect } from 'react';
// Components
import { PageLoader, OutOfStockNotice } from '../../common';
import { ProductMostSoldScroller } from '../../products';
import TotalAvailableStoreCredit from '../../user/containers/TotalAvailableStoreCredit';
import EmptyCart from '../images/empty-cart.png';
import ItemList from './ItemList';
import CartPanel from './CartPanel';
import CartBigClubPreview from './CartBigClubPreview';
import CartResumePreview from './CartResumePreview';
// Analytics
import * as Analytics from '../../common/analytics';
// Types
import type {
  FeaturesConfig,
  BigClubCashbackSettings,
} from '../../preferences/types';
import type { BigClubInfo, LineItem } from '../types';
// Styles
import './Cart.css';
//Helpers
import { useScreenTimeLogger } from '../../common';

type Props = {
  items: LineItem[],
  totalPrice: number,
  numberOfItems: number,
  totalKg: number,
  totalUnit: number,
  bigClubInfo?: BigClubInfo,
  handleInitiateCheckout: () => void,
  handleRemoveItem: (lineItemId: number) => void,
  loading: boolean,
  featuresConfig: FeaturesConfig,
  cashbackSettings: BigClubCashbackSettings,
};

const Cart = ({
  loading,
  numberOfItems,
  items,
  totalKg,
  totalUnit,
  totalPrice,
  handleRemoveItem,
  removingItemLoading,
  bigClubInfo,
  featuresConfig,
  cashbackSettings,
}: Props) => {
  const outOfStockItems = _.filter(items, l => l.variant.inStock === false);
  const discontinuedItems = _.filter(
    items,
    l => l.variant.discontinued === true,
  );

  const hasProductsDiscontinuedOrOutOfStock =
    !!outOfStockItems.length || !!discontinuedItems.length;

  const cashbackMinOrderPriceToEarn =
    cashbackSettings?.cashbackMinOrderPriceToEarn;

  // USE ANALYTICS
  useScreenTimeLogger(window.location.pathname);
  useEffect(() => {
    if (items && items.length > 0) {
      items.forEach(item => {
        Analytics.logLineItemView(item, window.location.pathname, 'cart');
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemoveItemAction = lineItemId => {
    const lineItem = _.find(items, item => item.id === lineItemId);
    Analytics.logLineItemRemove(lineItem, window.location.pathname, 'cart');
    handleRemoveItem(lineItemId);
  };

  return (
    <div className="cart-page-content-main-container">
      {loading && numberOfItems === 0 ? (
        <PageLoader />
      ) : numberOfItems === 0 ? (
        <div className="cart-page-empty">
          <h3>Seu carrinho está vazio. Vamos enchê-lo?</h3>
          <img src={EmptyCart} alt="" />
        </div>
      ) : (
        <div>
          <div className="cart-page-out-of-stock-notice">
            <OutOfStockNotice
              shouldDisplay={hasProductsDiscontinuedOrOutOfStock}
            />
          </div>
          <div className="cart-page-main-container">
            <div className="cart-page-left-container">
              <CartPanel
                numberOfItems={numberOfItems}
                totalCartPrice={totalPrice}
                bigClubInfo={bigClubInfo}
                minPriceToEarnCashback={cashbackMinOrderPriceToEarn}
                shouldDisplayFreeShippingNotice={totalPrice < 149}
                shouldDisplayBigClubNotice={featuresConfig?.cashbackFeature}
              />
              <ItemList
                items={items}
                handleRemoveItem={handleRemoveItemAction}
                removingItemLoading={removingItemLoading}
              />
            </div>
            <div className="cart-page-right-container">
              <CartBigClubPreview
                bigClubInfo={bigClubInfo}
                className="cat-page-cart-big-club-preview-small-screen"
              />
              <CartResumePreview
                totalPrice={totalPrice}
                hasProductsDiscontinuedOrOutOfStock={
                  hasProductsDiscontinuedOrOutOfStock
                }
                items={items}
              />
            </div>
          </div>
        </div>
      )}
      <TotalAvailableStoreCredit />
      <ProductMostSoldScroller />
    </div>
  );
};

export default Cart;
