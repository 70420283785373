// @flow
import React from 'react';
// Styles
import './HorizontalDivider.css';
import { Link } from 'react-router-dom';

type Props = {
  title: string,
  link: string,
  id: string | number,
};
const HorizontalDivider = ({ id, title, link = '' }: Props) => {
  return (
    <div className="horizontal-divider-wrapper" id={id}>
      <div className="horizontal-divider-divisor-alt" />
      <Link to={link} className="horizontal-divider-divisor-link">
        <h3 className="horizontal-divider-title">{title}</h3>
      </Link>
      <div className="horizontal-divider-divisor" />
    </div>
  );
};

export default HorizontalDivider;
