// @flow
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import { locationChangeListener } from './history';
// Google Tag Manager Related
import { createMiddleware } from 'redux-beacon';
import GoogleTagManager from '@redux-beacon/google-tag-manager';
import { eventsMapper } from './common/middleware/googleTagManagerEventsMap';
// Sentry
import * as Sentry from '@sentry/react';

export default function configureStore(preloadedState) {
  const gtm = GoogleTagManager();

  // Create middleware that is always used
  let middleware = [thunk, createMiddleware(eventsMapper, gtm)];

  // Add middleware that should only be available during development
  if (process.env.NODE_ENV !== 'production') {
    const createLogger = require('redux-logger').createLogger;
    const logger = createLogger();
    middleware = [...middleware, logger];
  }

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    stateTransformer: state => {
      // Since the entire state can become significantly larger than Sentry's 200 KB limit we will filter only to the current order and user data.
      // This is required to avoid 413 when sending data to Sentry.
      const { order, user } = { ...state };
      return { order, user };
    },
  });

  // Create store
  const store = createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(applyMiddleware(...middleware), sentryReduxEnhancer),
  );

  locationChangeListener(store);

  return store;
}
