// @flow
import React from 'react';
//Styles
import './BigClubBanner.css';
// Assets
import bannerImage from '../images/big_club_white.svg';
// Components
import { Link } from 'react-router-dom';

type Props = {
  showBanner: boolean,
  userId: number,
};

const BigClubBanner = ({ showBanner, userId }: Props) => {
  const bigClubUrl = !!userId ? '/bigclub' : '/lp/bigclub';

  return (
    showBanner && (
      <div className="big-club-banner">
        <Link to={bigClubUrl} className="big-club-banner-container">
          <h4 className="big-club-banner-title">
            Acumule até 3% de cashback <span>em suas compras!</span>
          </h4>
          <div className="big-club-banner-divisor" />
          <img
            src={bannerImage}
            alt="Big club o nosso programa de fidelidade"
            className="big-club-banner-image"
          />
        </Link>
      </div>
    )
  );
};
export default BigClubBanner;
