// @flow
import React from 'react';
// Connector
import { withRouter } from 'react-router-dom';
// Styles
import './WishlistContainer.css';
// Assets
import heartIcon from '../images/heart-icon.svg';

type Props = {
  location: {
    hash: string,
    pathname: string,
    search: string,
    state: any,
  },
  history: {
    push: (pathname: string) => void,
  },
  children: ReactNode,
  title: string,
  handleClick?: () => void,
};

const WishlistContainer = ({
  children,
  location,
  history,
  title = 'Favoritos',
  handleClick,
}: Props) => {
  const isInWishlist = title === 'Favoritos';

  return (
    <main className="wishlist-container">
      <section className="wishlist-header">
        <div className="wishlist-header-content">
          <img
            src={heartIcon}
            className="wishlist-header-icon"
            alt="Ícone de coração"
          />
          <h1 className="wishlist-header-title">{title}</h1>
        </div>
        {isInWishlist && (
          <button className="wishlist-header-button" onClick={handleClick}>
            Favoritar mais produtos
          </button>
        )}
      </section>
      <section className="wishlist-row">
        <button
          className={`wishlist-row-button
         ${isInWishlist ? 'active' : ''}
        `}
          onClick={() => history.push('/lista-de-desejos')}
        >
          Produtos favoritados
        </button>
        <button
          className={`wishlist-row-button ${isInWishlist ? '' : 'active'}`}
          onClick={() => history.push('/listas-personalizadas')}
        >
          Listas personalizadas
        </button>
      </section>
      {children}
    </main>
  );
};

export default withRouter(WishlistContainer);
