// @flow
import React, { Component } from 'react';
import _ from 'lodash';
// Components
import CartChangeNotice from '../components/CartChangeNotice';
import CartIndicatorButton from '../components/CartIndicatorButton';
import { Link } from 'react-router-dom';
// Composers
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// Selectors
import {
  itemCountSelector,
  itemsSelector,
  orderNumberSelector,
  gettingOrderLoadingSelector,
} from '../selectors';
// Actions
import { findOrCreateOrder } from '../actions';
// Types
import type { LineItem, SimpleLineItem } from '../types';
// Styles
import './CartIndicator.css';

type Props = {
  itemCount: number,
  orderNumber: ?string,
  loadingOrder: boolean,
  findOrCreateOrder: () => void,
};

type State = {
  cartChanges: {}[],
  noticeVisible: boolean, // Whether we should display the cart change notice
};

const initialState = {
  cartChanges: [],
  noticeVisible: false,
};

class CartIndicator extends Component {
  state: State;
  timer: any;
  computeProductAdded: (
    oldLineItems: LineItem[],
    newLineItems: LineItem[],
  ) => SimpleLineItem[];

  constructor(props: Props) {
    super(props);
    this.props.findOrCreateOrder();

    this.state = initialState;

    // Timer for the added to cart widget
    this.timer = null;
  }

  computeProductAdded(oldLineItems, newLineItems) {
    // TODO: This should not be triggered when another page causes a change in cart that propages here through persistant storage.
    // There are two situations where the products could have changed: New line item or changed quantity
    // Let us start with the new line item
    if (newLineItems.length > oldLineItems.length) {
      // Determine which line items were added
      const additionalLineItems = _.differenceBy(
        newLineItems,
        oldLineItems,
        'variantId',
      );

      return additionalLineItems.map(l => ({
        name: l.variant.name,
        quantity: l.quantity,
        images: l.variant.images,
        price: l.price,
        unitForQuantity: l.variant.unitForQuantity,
      }));
    }

    // If number of line items remains unchanged
    // TODO: Should ensure that one line item was not added and another removed. Would still result in same length
    if (newLineItems.length === oldLineItems.length) {
      const changedLineItems = [];
      // Compare the quantity of all line items to determine which one changed
      for (let key of newLineItems.keys()) {
        const currentLineItem = newLineItems[key];
        if (!currentLineItem) {
          continue;
        } // If the lineItem is undefined we should quit computation
        const comparableLineItem = _.find(oldLineItems, {
          variantId: currentLineItem.variantId,
        }); // Line Item with matched variant
        if (
          currentLineItem &&
          comparableLineItem &&
          currentLineItem.quantity !== comparableLineItem.quantity
        ) {
          changedLineItems.push(currentLineItem);
        }
      }

      // TODO This conversion code is duplicated. Deduplicate.
      return changedLineItems.map(l => ({
        name: l.variant.name,
        quantity: l.quantity,
        images: l.variant.images,
        price: l.price,
        unitForQuantity: l.variant.unitForQuantity,
      }));
    }

    return [];
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // If the order was just loaded (ex: Data from server just returned from GET) we should not check for changes
    if (this.props.orderNumber === null) {
      return;
    }

    // If we navigate to the cart page the notice should disappear
    if (this.props.location.pathname === '/carrinho') {
      this.setState({ noticeVisible: false });

      return;
    }

    // This protects against the notice being removed due to the final action of an optimistic update.
    if (_.isEqual(this.props.lineItems, nextProps.lineItems)) {
      return;
    }

    const changedLineItems = this.computeProductAdded(
      this.props.lineItems,
      nextProps.lineItems,
    );
    if (changedLineItems.length !== 0) {
      this.setState({
        cartChanges: changedLineItems,
        noticeVisible: true,
      });

      clearTimeout(this.timer); // Make sure we cleared the timer
      this.timer = setTimeout(
        () => this.setState.bind(this)({ ...initialState }),
        10000,
      );
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    return (
      <div className="cart-indicator-main">
        <Link to="/carrinho/" className="cart-indicator-link">
          <CartIndicatorButton
            quantity={this.props.itemCount}
            loading={this.props.loadingOrder}
          />
        </Link>

        {/* Only show the notice if we are not in the cart page and there was a recent item change */}
        {this.state.noticeVisible && (
          <CartChangeNotice
            cartChanges={this.state.cartChanges}
            onDismiss={() =>
              this.setState((props, state) => ({
                ...state,
                noticeVisible: false,
              }))
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    itemCount: itemCountSelector(state),
    lineItems: itemsSelector(state),
    orderNumber: orderNumberSelector(state),
    loadingOrder: gettingOrderLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    findOrCreateOrder() {
      dispatch(findOrCreateOrder());
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(CartIndicator));
