import { authGet } from '../../api';
import * as schema from './schema';
import { camelizeKeys } from 'humps';
import { normalize } from 'normalizr';

const Departments = {
  async getIndex() {
    const response = await authGet(`/v2/departments`);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.departments);
    return normalized;
  },
  async get(slug: string): Promise<Department> {
    const response = await authGet(`/v2/departments/${slug}`);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.department);

    return normalized;
  },
};

export default Departments;
