
import React from 'react'
//Styles
import './Takeout.css'
// Assets
import pituba_distribution_center_location_map from '../../images/pituba_distribution_center_location_map.png'
// Types
import type { ShippingRate } from '../../types';
// Helper functions
import { getTimeWindow } from '../../../common';

type Props = {
  takeoutShippingRate: ShippingRate,
}

const Takeout = ({ takeoutShippingRate }: Props) => {
  return (
    <div className='takeout__container'>
      <p className='takeout__paragraph'>
        Nosso centro de distribuição se situa no bairro da <b>
          Pituba, Rua Minas Gerais, 486
        </b>
      </p>
      <img
        className='takeout__map'
        src={pituba_distribution_center_location_map}
        alt='Mapa mostrando a localização do big delivery'
      />
      <p className='takeout__paragraph'>
        Após o pagamento seu pedido
        entrará em processo de produção
        e você será notificado assim que
        estiver disponível para retirada!
      </p>
      <p className='takeout__paragraph-bold'>
        Horário de retirada  {getTimeWindow(takeoutShippingRate.deliveryWindowStart, takeoutShippingRate.deliveryWindowEnd)} na data de hoje.
      </p>

    </div>
  )
}
export default Takeout