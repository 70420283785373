// @flow
import React from 'react';
import _ from 'lodash';
// Components
import ForgotPassword from '../components/ForgotPassword';
import { Helmet } from 'react-helmet';
// Composers
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// Import Actions
import { postForgotPassword } from '../actions';
// Selectors
import {
  forgotPasswordLoadingSelector,
  forgotPasswordErrorsSelector,
} from '../selectors';
// Types

type Props = {
  onSubmitForgotPassword: (email: string) => void,
};

type State = {
  successMessage: string,
};

class ForgotPasswordPage extends React.Component {
  state: State;
  constructor(props: Props) {
    super(props);

    this.state = {
      successMessage: '',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    // If we have just left the loading state and there are no errors it means we have successfully reset the password.
    if (
      !nextProps.loading &&
      this.props.loading &&
      _.isEmpty(nextProps.errors)
    ) {
      this.setState({
        successMessage:
          'Enviamos um email com as instruções para resetar a sua senha.',
      });
    }
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Esqueci Minha Senha - Bigdelivery, Seu açougue virtual</title>
        </Helmet>

        <ForgotPassword
          isLoading={this.props.loading}
          errors={this.props.errors}
          successMessage={this.state.successMessage}
          onSubmitForgotPassword={email =>
            this.props.onSubmitForgotPassword(email)
          }
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: forgotPasswordLoadingSelector(state),
    errors: forgotPasswordErrorsSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, stateProps) => {
  return {
    onSubmitForgotPassword: (email: string) => {
      dispatch(postForgotPassword(email));
    },
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ForgotPasswordPage),
);
