// @flow
import React, { useState, useEffect } from 'react';
// Styles
import './PriceSlider.css';
import 'rc-slider/assets/index.css';
// Helpers
import { connectRange } from 'react-instantsearch-dom';
// Components
import Slider from 'rc-slider';
// Analytics
import * as Analytics from '../../common/analytics';

type IPriceSliderProps = {
  currentRefinement: object,
  min: number,
  max: number,
  canRefine: Boolean,
  refine: any => void,
};

const PriceSlider = (props: IPriceSliderProps) => {
  const { currentRefinement, min, max, canRefine, refine } = props;
  const [stateMin, setStateMin] = useState(min);
  const [stateMax, setStateMax] = useState(max);

  useEffect(() => {
    if (canRefine) {
      setStateMin(currentRefinement.min);
      setStateMax(currentRefinement.max);
    }
  }, [currentRefinement.min, currentRefinement.max, canRefine]);

  if (min === max) {
    return null;
  }

  const onAfterChange = arr => {
    Analytics.logPriceSliderUsed();
    if (currentRefinement.min !== arr[0] || currentRefinement.max !== arr[1]) {
      refine({ min: arr[0], max: arr[1] });
    }
  };

  const onChange = arr => {
    setStateMin(arr[0]);
    setStateMax(arr[1]);
  };

  return (
    <div className="price-slider-main-div">
      <Slider
        range
        trackStyle={{ backgroundColor: '#eb5b1e' }}
        dotStyle={{ borderColor: 'orange' }}
        handleStyle={{
          borderColor: '#eb5b1e',
          boxShadow: '0 0 5px tint(#eb5b1e, 20%)',
        }}
        defaultValue={[currentRefinement.min, currentRefinement.max]}
        value={[stateMin, stateMax]}
        min={min}
        max={max}
        onChange={onChange}
        onAfterChange={onAfterChange}
      />
      <div className="price-slider-text-div">
        <p>R${stateMin}</p>
        <p>R${stateMax}</p>
      </div>
    </div>
  );
};

export default connectRange(PriceSlider);
