// @flow
import React, { useEffect } from 'react';
import MyAccountPageContainer from '../components/myAccount/MyAccountPageContainer';
import MyAccountOverview from '../components/myAccount/MyAccountOverview';
import './MyAccountAddressesPage.css';
import { connect } from 'react-redux';
import _ from 'lodash';
// Selectors
import {
  editableInformationSelector,
  validatingUserLoadingSelector,
  editingUserInformationLoadingSelector,
  userAddressesSelector,
} from '../selectors';
import { currentOrderBigClubInfoSelector } from '../../current-order/selectors';
import { ordersListSelector } from '../../past-orders/selectors';
// Actions
import { getPastOrders, getPastOrder } from '../../past-orders/actions';
// Types
import { Order } from '../../types';
import type { Address } from '../types';

type Props = {
  information: {
    firstName: string,
    lastName: string,
    cpf: string,
    email: string,
  },
  loading: boolean,
  editingLoading: boolean,
  onSubmitUpdate: (
    firstName: string,
    lastName: string,
    cpf: string,
    email: string,
  ) => void,
  orders: Order[],
  order: Order[],
  addresses: Address[],
  getPastOrders: (page: number, perPage: number) => void,
  getPastOrder: (orderNumber: string) => void,
};

const MyAccountOverviewPage = (props: Props) => {
  useEffect(() => {
    props.getPastOrders(1, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mostRecentValidOrder =
    _.find(props.orders, o => {
      return (
        o.fulfillmentState !== 'ready' &&
        o.fulfillmentState !== 'error' &&
        o.fulfillmentState !== 'canceled'
      );
    }) || [];
  return (
    <MyAccountPageContainer>
      {!!mostRecentValidOrder && mostRecentValidOrder.id && (
        <MyAccountOverview
          information={props.information}
          loading={props.loading}
          editingLoading={props.editingLoading}
          onSubmitUpdate={props.onSubmitUpdate}
          bigClubInfo={props.bigClubInfo}
          order={mostRecentValidOrder}
          addresses={props.addresses}
          getPastOrder={props.getPastOrder}
        />
      )}
    </MyAccountPageContainer>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    information: editableInformationSelector(state),
    loading: validatingUserLoadingSelector(state),
    editingLoading: editingUserInformationLoadingSelector(state),
    bigClubInfo: currentOrderBigClubInfoSelector(state),
    orders: ordersListSelector(state),
    addresses: userAddressesSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    getPastOrders: (page: number, perPage: number) => {
      dispatch(getPastOrders(page, perPage));
    },
    getPastOrder: (orderNumber: string) => {
      dispatch(getPastOrder(orderNumber));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(MyAccountOverviewPage);
