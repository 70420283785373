// @flow
import React from 'react';
// Styles
import './BlogNews.css';

type Props = {
  posts: Post[];
}

const BlogNews = (props): Props => {
  if (props.loading && props.posts.length === 0) {
    return (         
      <div className='d-flex justify-content-center align-items-center p-3'>
        <div className="loading ">
        <i
          className="fa fa-spinner fa-pulse fa-4x loading-icon"
          aria-hidden="true"
        />
        </div>
      </div>
    )
  }

  return (
    <div className="blog-news-main">
      <h2>Notícias</h2>
      
      <div className='blog-news-row'>
        {props.posts.map(post => (
          <a key={post.slug} href={`/blog/${post.slug}`} className='blog-news-anchor-card'>
            <div className='blog-news-card'>
              <img src={post.thumbnailImageUrl} alt={post.title}></img>
              <h3>{post.title}</h3>
              <p>{post.description}</p>
            </div>
          </a>))}
      </div>
    </div>
  );
};

export default BlogNews;

