// @flow
import { camelizeKeys } from 'humps';
import { normalize } from 'normalizr';
// Schema
import * as schema from './schema';
// API
import { authGet } from '../../api';

const Preferences = {
  async fetchFeatures() {
    const response = await authGet('/v2/features');
    const data = await response.json().then(b => {
      return camelizeKeys(b);
    });
    const normalized = normalize({ id: 'features', ...data }, schema.feature);
    return normalized;
  },

  async fetchBigClubSettings() {
    const response = await authGet('/v2/bigclub_settings');
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(
      { ...data, id: 'bigClubSettings' },
      schema.bigClubSetting,
    );

    return normalized;
  },
};

export default Preferences;
