// @flow
import React from 'react';
import { connect } from 'react-redux';
// Selectors
import {
  itemSelectorByProductId,
  changingItemLoadingSelector,
} from '../selectors';
// Types
import type { Connector } from 'react-redux';
import type { LineItem } from '../types';

import './BuyPrice.css';

const scaleAndFormatPrice = (
  price: number,
  unitForQuantity: 'g' | 'un',
  withUnit = true,
) => {
  return (
    <>
      {parseFloat(
        unitForQuantity === 'g' ? price * 1000 : price,
      ).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })}
      {withUnit && <small>/{unitForQuantity === 'g' ? 'Kg' : 'Un'}</small>}
    </>
  );
};

const SinglePrice = ({ price, unit, listPrice, showListPrice }) => (
  <div className={`buy-price-single ${showListPrice}`}>
    <h3>{scaleAndFormatPrice(price, unit)}</h3>
    <h6>{scaleAndFormatPrice(listPrice, unit)}</h6>
  </div>
);

const DoublePrice = ({ unit, price, quantity }) => (
  <div className="buyprice-estimated-content">
    <h3 className="buyprice-estimated-value">
      {scaleAndFormatPrice(price * quantity, unit, false)}
    </h3>

    <h3 className="buyprice-value">{scaleAndFormatPrice(price, unit)}</h3>
  </div>
);

export type Props = {
  productId: number,
  item: LineItem,
  listPrice: number,
  price: number,
  showPromo: boolean,
  unit: 'g' | 'un',
  loading: boolean,
};

const BuyPrice = (props: Props) => {
  return (
    <div className="buyprice-main">
      {props.item ? (
        <DoublePrice
          unit={props.unit}
          price={props.item.price}
          listPrice={props.listPrice}
          quantity={
            props.unit === 'g'
              ? props.item.quantity / 1000
              : props.item.quantity
          }
        />
      ) : (
        <SinglePrice
          price={props.price}
          listPrice={props.listPrice}
          showListPrice={props.showPromo ? 'buyprice-show-list-price' : ''}
          unit={props.unit}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps: Props) => {
  return {
    item: itemSelectorByProductId(state, ownProps.productId),
    loading: changingItemLoadingSelector(state, ownProps.productId),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: Props) => {
  return {};
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default connector(BuyPrice);
