// @flow
import React from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
// Selectors
import '../selectors';
// Actions
import '../actions';
// Components
import UnconnectedConfirmationPage from '../components/ConfirmationPage';
import { ColorRow } from '../../common';
//Connectors
import { userEmailConnector } from '../../user';

type Props = {
  match: any,
  location: any,
};

const ConfirmationPage = (props: Props) => {
  const orderNumber =
    props.match.params.id || qs.parse(props.location.search.substr(1)).id || '';
  return (
    <div>
      <div>
      <Helmet>
        <title>
          Confirmação do Pedido {orderNumber} - Bigdelivery - Seu açougue
          virtual
        </title>
      </Helmet>
      </div>
      <div>
     

     <UnconnectedConfirmationPage
       orderNumber={orderNumber}
       userEmail={props.email}
     />
   </div>
    </div>
   
  );
};

export default userEmailConnector(withRouter(ConfirmationPage));
