// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';

// Components
import Scroller from '../../components/Scroller';
// Actions
import { findRelatedProducts } from '../../actions';
// Selectors
import {
  relatedProductsSelector,
  relatedProductsLoadingSelector,
} from '../../selectors';
// Types
import type { Product } from '../../types';

type Props = {
  productToRelateSlug: string,
  products: Product[],
  isLoading: boolean,
  findProducts: (slug: string) => void,
  hideBottomLine: boolean,
};

class ProductPromotionScroller extends Component {
  constructor(props: Props) {
    super(props);

    // Request products to display in scroller
    props.findProducts(props.productToRelateSlug);
  }

  render() {
    return (
      <Scroller
        title="PRODUTOS RELACIONADOS"
        products={this.props.products}
        isLoading={this.props.isLoading}
        hideBottomLine={this.props.hideBottomLine}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    products: relatedProductsSelector(state),
    isLoading: relatedProductsLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    findProducts: (slug: string) => {
      dispatch(findRelatedProducts(slug));
    },
  };
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default connector(ProductPromotionScroller);
