// @flow
import './SearchPage.css';
import React, { Component } from 'react';
import './SearchNoResult.css'
import chickenMagnify from '../images/chicken_magnify_glass.png'

class SearchNoResult extends Component {
  render() {
    return (
      <div className="search-no-result-main">
        <div className="search-no-result-content">
          <img src={chickenMagnify} alt="" className="search-no-result-image" />
          <p>Sem resultados para essa pesquisa</p>
        </div>
      </div>
    );
  }
}

export default SearchNoResult;
