import React, { useEffect, useState } from 'react';
// Components
import { ProductPrice, ProgressiveImageLoader } from '../../common';
import FlipUnitContainer from '../../common/components/FlipUnitContainer';
import { AddToCartButton } from '../../current-order';
import { ProductSquare } from '../../products';
import { Link } from 'react-router-dom';
// Helpers
import moment from 'moment';
// Types
import type { ScheduledDiscount } from '../types';
// Styles
import './TimedPromotion.css';

type Props = {
  promotion: ScheduledDiscount,
};

const TimedPromotion = ({ promotion }: Props) => {
  const [hours, setHours] = useState({
    value: 0,
    shuffle: true,
  });
  const [minutes, setMinutes] = useState({
    value: 0,
    shuffle: true,
  });
  const [seconds, setSeconds] = useState({
    value: 0,
    shuffle: true,
  });

  useEffect(() => {
    const targetTime = moment(promotion.endAt).utcOffset('-0300');
    const updateTime = () => {
      const now = moment().utcOffset('-0300');
      const diff = targetTime.diff(now, 'seconds');
      if (diff <= 0) {
        setSeconds(prev => ({
          value: 0,
          shuffle: !prev.shuffle,
        }));
        clearInterval(timerInterval);
      } else {
        const remainingHours = Math.floor(diff / 3600);
        const remainingMinutes = Math.floor((diff % 3600) / 60);
        const remainingSeconds = diff % 60;

        setHours(prev => ({
          value: remainingHours,
          shuffle: prev.value !== remainingHours ? !prev.shuffle : prev.shuffle,
        }));

        setMinutes(prev => ({
          value: remainingMinutes,
          shuffle:
            prev.value !== remainingMinutes ? !prev.shuffle : prev.shuffle,
        }));

        setSeconds(prev => ({
          value: remainingSeconds,
          shuffle:
            prev.value !== remainingSeconds ? !prev.shuffle : prev.shuffle,
        }));
      }
    };

    const timerInterval = setInterval(updateTime, 1000);

    return () => clearInterval(timerInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!promotion.endAt) return null;
  if (hours.value === 0 && minutes.value === 0 && seconds.value === 0) {
    return null;
  }

  return (
    <div className="timed-promotion-container">
      <div className="timed-promotion-announcement">
        <h2 className="timed-promotion-announcement-title">
          OFERTA <b>RELÂMPAGO</b>
        </h2>
        <div className="timed-promotion-announcement-timer">
          <span className="timed-promotion-announcement-timer-text">
            Acaba em
          </span>
          <div className="timed-promotion-announcement-timer-countdown">
            <div className="timed-promotion-announcement-timer-countdown-content">
              <FlipUnitContainer
                unit={'hours'}
                digit={hours.value}
                shuffle={hours.shuffle}
              />
              <span>H</span>
            </div>
            <span>:</span>
            <div className="timed-promotion-announcement-timer-countdown-content">
              <FlipUnitContainer
                unit={'minutes'}
                digit={minutes.value}
                shuffle={minutes.shuffle}
              />
              <span>M</span>
            </div>

            <span>:</span>
            <div className="timed-promotion-announcement-timer-countdown-content">
              <FlipUnitContainer
                unit={'seconds'}
                digit={seconds.value}
                shuffle={seconds.shuffle}
              />
              <span>S</span>
            </div>
          </div>
        </div>
      </div>
      <div className="timed-promotion-product">
        {promotion.variants.map(product => (
          <div key={product.id} className="timed-promotion-product-variant">
            <div className="timed-promotion-product-content">
              <Link
                to={`/produtos/${product.slug}`}
                className="timed-promotion-product-link"
              >
                <div className="timed-promotion-product-image">
                  <ProgressiveImageLoader
                    selectedImage={product?.images && product.images[0]}
                    imageToShow={'product'}
                  />
                </div>
                <div className="timed-promotion-product-description">
                  <p className="timed-promotion-product-description-manufacutrer">
                    {product.manufacturer}
                  </p>
                  <p className="timed-promotion-product-description-name">
                    {product.name}
                  </p>
                </div>
              </Link>
              <div className="timed-promotion-product-cost">
                <ProductPrice
                  productListPrice={product.listPrice}
                  productPrice={product.price}
                  productUnit={product.unitForQuantity}
                />
                <AddToCartButton
                  productId={product.id}
                  unitForQuantity={product.unitForQuantity}
                  defaultQuantity={product.defaultQuantity}
                  incrementQuantity={product.incrementQuantity}
                  inStock={product.inStock}
                  discontinued={product.discontinued}
                  // USE ANALYTICS
                  analyticsViewSource={'timed promotion'}
                />
              </div>
            </div>
            <div className="timed-promotion-product-square">
              <ProductSquare
                productImage={product?.images && product.images[0]}
                productName={product.name}
                productManufacturer={product.manufacturer}
                productListPrice={product.listPrice}
                productPrice={product.price}
                productUnit={product.unitForQuantity}
                productSlug={product.slug}
                productId={product.id}
                parentProductId={product.productId}
                productTotalOnHand={product.totalOnHand}
                defaultQuantity={product.defaultQuantity}
                incrementQuantity={product.incrementQuantity}
                inStock={product.inStock}
                discontinued={product.discontinued}
                analyticsViewSource="timed promotion"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TimedPromotion;
