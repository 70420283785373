// Components
import { Link } from 'react-router-dom';
// Styles
import './BlackFridayBaseBanner.css';

const BlackFridayBaseBanner = () => {
  return (
    <div className="black-friday-base-banner-main-container">
      <div className="black-friday-base-banner-top-content">
        <h1>Sinta-se seguro com o BIG</h1>
        <div className="black-friday-base-banner-top-content-first-container">
          <div>
            <h1>+1</h1>
            <span>MILHÃO</span>
            <p>de pedidos entregues</p>
          </div>
          <div>
            <h1>+500</h1>
            <span>MIL</span>
            <p>Clientes atendidos</p>
          </div>
        </div>
        <div className="black-friday-base-banner-top-content-second-container">
          <h1>Sua satisfação é a nossa prioridade</h1>
          <p>
            Com uma base sólida de clientes satisfeitos, servimos a todos com
            excelência.
          </p>
        </div>
      </div>
      <div className="black-friday-base-banner-lower-content">
        <h1>
          Não perca tempo e garanta as ofertas mais quentes da{' '}
          <span>Black Friday agora!</span>
        </h1>
        <div className="black-friday-base-banner-lower-links">
          <Link to={'/login/'} className="black-friday-base-banner-signup-link">
            Criar conta
          </Link>
          <Link to={'/login/'} className="black-friday-base-banner-signin-link">
            Entrar na conta
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlackFridayBaseBanner;
