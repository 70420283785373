// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Components
import { Link } from 'react-router-dom';
import AddBlendToCartButton from './AddBlendToCartButton';
import { ItemListImage, priceConverter, truncateString } from '../../common';
// Actions
import * as actions from '../actions';
// Selectors
import * as selectors from '../selectors';
import currentOrderNumberConnector from '../../current-order/connectors/currentOrderNumberConnector';
// Styles
import './BlendList.css';
// Types
import type { Blend } from '../types';

type Props = {
  blendsByOrderNumber: (orderNumber: string) => Blend[],
  changingLoadingById: (blendId: number) => boolean,
  getBlends: (orderNumber: string) => void,
  handleRemoveBlend: (blendId: number) => void,

  // From connector
  currentOrderNumber: string,
};

/**
 * List of blends that are currently in the cart for the given user and order.
 * This component is likely used in the cart.
 */

class BlendList extends Component {
  props: Props;

  /**
   * API requests should be made here.
   */
  componentDidMount() {
    // If we already have an order number we should get all the blends. This is needed to correctly populate the addToCartButtons
    if (this.props.currentOrderNumber) {
      this.props.getBlends(this.props.currentOrderNumber);
    }
  }

  componentDidUpdate(prevProps: Props) {
    // If our orderNumber changes we need to get the blends associated with the new number
    if (this.props.currentOrderNumber !== prevProps.currentOrderNumber) {
      this.props.getBlends(this.props.currentOrderNumber);
    }
  }

  render() {
    // When we arrive here we are guaranteed to have lineItems but not guaranteed to have blends.

    const blends = this.props.blendsByOrderNumber(
      this.props.currentOrderNumber,
    );

    return (
      <div className="item-list-main">
        {blends.map((blend, i) => (
          <div className="item-list-container" key={i}>
            <hr className="item-list-line" />
            <div className="item-list-subcontainer">
              {/* TODO: Add the correct image! */}
              <ItemListImage variant={this.props.lineItems[0].variant} />
              <div className="line-item-content">
                <Link to="/blend/meus-blends/">
                  <h3>{blend.name}</h3>
                </Link>
                <p>{truncateString(blend.observation, 35)}</p>

                <h3>{priceConverter(parseFloat(blend.price) * 1000)}/Kg</h3>
              </div>

              <div className="line-item-price-container">
                <div className="line-item-price-container-option blend">
                  <button
                    className={'fa fa-pencil item-list-trash'}
                    aria-hidden="true"
                    title="Opções avançadas."
                  />
                  {/*
                  * TODO: handle advanced options click on the blend list
                  * the className "blend" is to temporarily hide this option in css (only in the blend)
                  */}
                  <span>mais opções</span>
                </div>
                <div className="line-item-price-content">
                  <AddBlendToCartButton
                    blendTemplateId={blend.templateId}
                    unitForQuantity={'g'}
                    defaultQuantity={blend.quantity}
                    incrementQuantity={blend.incrementQuantity}
                    blend={blend}
                  />

                  <p>Preço estimado</p>
                  <h3>
                    {priceConverter(parseFloat(blend.price) * blend.quantity)}
                  </h3>
                </div>
                <div className="line-item-price-container-option">
                  <button
                    className="fa fa-trash-o item-list-trash"
                    aria-hidden="true"
                    onClick={() => this.props.handleRemoveBlend(blend.id)}
                    disabled={this.props.changingLoadingById(blend.id)}
                  />
                  <span>remover item</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    blendsByOrderNumber: (orderNumber: string) =>
      selectors.blendsByOrderNumberSelector(state, orderNumber),
    changingLoadingById: (templateId: number) =>
      selectors.changingBlendLoadingSelector(state, templateId),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    getBlends: (orderNumber: string) => {
      dispatch(actions.getBlends(orderNumber));
    },
    handleRemoveBlend: (blendId: number) => {
      dispatch(actions.deleteBlend(blendId));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default currentOrderNumberConnector(connector(BlendList));
