// @flow
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
//Styles
import './AddProductButton.css';

type Props = {
  loading: boolean,
  clickable?: boolean,
  addItem: (productId: number, quantity: number) => void,
};

const AddProductButton = (props: Props) => {
  const history = useHistory();
  const [isClickable, setIsClickable] = useState(
    props.clickable !== undefined ? props.clickable : true,
  );

  const handleAddItem = async () => {
    if (isClickable !== false) {
      await props.addItem();
      history.push('/checkout/');
    }
  };

  return (
    <>
      {props.loading ? (
        <div className="add-button">
          <i className="fa fa-spinner fa-pulse" />
        </div>
      ) : (
        <button className="add-button" onClick={handleAddItem}>
          <div className="add-button-text">
            <p>Comprar agora!</p>
          </div>
        </button>
      )}
    </>
  );
};

export default AddProductButton;
