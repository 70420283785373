/*
 *   Useful constants that are reused throughout the application
 */
export const phone = {
  ddd: 71,
  number: 35003100,
  asStringNumber: '3500-3100',
  asStringFull: '(71) 3500-3100',
  e164: '+557135003100',
  whatsAppNumber: '557135003100',
};
