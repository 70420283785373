// @flow
import React, { Component } from 'react';
// Helpers
import { scaleAndFormatPrice } from '../../common';
// Types
import type { Blend } from '../types';

import './BlendBuyPrice.css';

const SinglePrice = ({ price, unit, listPrice, showListPrice }) => (
  <div className={`blend-buy-price-single ${showListPrice}`}>
    <h4>{scaleAndFormatPrice(price, unit)}</h4>
  </div>
);

const DoublePrice = ({ unit, price, quantity }) => (
  <div className="blend-buyprice-estimated-content">
    <h4 className="blend-buyprice-estimated-value">
      {parseFloat(price * quantity).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })}
    </h4>

    <h3 className="blend-buyprice-value">{scaleAndFormatPrice(price, unit)}</h3>
  </div>
);

export type Props = {
  blend: Blend,
  item: LineItem,
  listPrice: number,
  price: number,
  unit: 'g',
};

class BlendBuyPrice extends Component {
  props: Props;
  render() {
    let showListPrice = '';

    return (
      <div className="blend-buyprice-main">
        {this.props.blend ? (
          <DoublePrice
            unit={this.props.unit}
            price={parseFloat(this.props.price)}
            quantity={this.props.blend.quantity}
          />
        ) : (
          <SinglePrice
            price={this.props.price}
            listPrice={this.props.listPrice}
            showListPrice={showListPrice}
            unit={this.props.unit}
          />
        )}
      </div>
    );
  }
}

export default BlendBuyPrice;
