// @flow
import React, { Component } from 'react';

import './ColorRow.css';

type Props = {
  text: string,
  color: string,
};

class ColorRow extends Component {
  props: Props;
  render() {
    const selectedText =
      this.props.text != null ? this.props.text.toUpperCase() : '';

    let selectedColor = '';
    if (this.props.color === 'red') {
      selectedColor = 'color-row-red';
    } else if (this.props.color === 'black') {
      selectedColor = 'color-row-black';
    } else if (this.props.color === 'grey') {
      selectedColor = 'color-row-grey';
    } else if (this.props.color === 'green') {
      selectedColor = 'color-row-green';
    } else if (this.props.color === 'orange') {
      selectedColor = 'color-row-orange';
    }

    return (
      <div className={`color-row-main ${selectedColor}`}>{selectedText}</div>
    );
  }
}

export default ColorRow;
