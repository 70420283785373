// @flow
import React from 'react';
// Styles
import './ProgressionBar.css';

type Props = {
  absoluteValueToCompare?: number,
  valueToBeCompared?: number,
  barColor?: string,
};

const ProgressionBar = ({
  absoluteValueToCompare,
  valueToBeCompared,
  barColor = 'crimson',
}: Props) => {
  const progressRaw = (valueToBeCompared * 100) / absoluteValueToCompare;
  const progress = Math.max(0, Math.min(100, progressRaw));
  const widthStyle =
    progressRaw < 100 && progressRaw >= 99.5 ? '99.5%' : `${progress}%`;

  return (
    <div className="progression-bar-container">
      <div className="progression-bar-progress-box">
        <div className="progression-bar-progress-box-bar">
          <span
            className="progression-bar-progress-box-progression-span"
            style={{ width: widthStyle, background: barColor }}
          ></span>
        </div>
      </div>
    </div>
  );
};

export default ProgressionBar;
