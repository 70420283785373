// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Actions
import { addItemToCart } from '../actions';
// Types
import type { Dispatch } from '../../types';

type OwnProps = {
  addItemToCart: (productId: number, quantity: number) => void,
};

const addItemToCartConnector = (WrappedComponent: ReactClass<any>) => {
  const mapStateToProps = (state, ownProps) => {
    return {};
  };

  const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
    return {
      addItemToCart: (productId: number, quantity: number) => {
        dispatch(addItemToCart(productId, quantity));
      },
    };
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class addItemToCartConnector extends Component {
      props: OwnProps;
      render() {
        return (
          <WrappedComponent
            {...this.props}
            addItemToCart={this.props.addItemToCart}
          />
        );
      }
    },
  );
};

export default addItemToCartConnector;
