// @flow
import createHistory from 'history/createBrowserHistory';

const history = createHistory();

// Listen for changes to the current location.
// history.listen returns the unlisten function which can be called to stop the listener
export const locationChangeListener = reduxStore =>
  history.listen((location, action) => {
    reduxStore.dispatch({
      type: 'LOCATION_CHANGE',
      payload: {
        location,
        action,
      },
    });
  });

export default history;
