// @flow
import React from 'react';
// Components
import MyAccountPageContainer from '../components/myAccount/MyAccountPageContainer';
import MyAccountEvaluations from '../components/myAccount/MyAccountEvaluations';

const MyAccountEvaluationsPage = () => {
  return (
    <MyAccountPageContainer>
      <MyAccountEvaluations />
    </MyAccountPageContainer>
  );
};

export default MyAccountEvaluationsPage;
