// @flow
import React, { useEffect } from 'react';
// Components
import LandingFAQ from '../components/LandingFAQ';
import LandingIncreasingUserCounter from '../components/LandingIncreasingUserCounter';
import LandingProductList from '../components/LandingProductList';
// Styles
import './Restaurant.css';
// Data
import restaurantData from '../data/restaurantData';
// Connectors
import { displayVariantsByPageAndSectionConnector } from '../../products';
// Types
import { Product } from '../../products';
// Images
import star from '../images/star.svg';
import time from '../images/time.svg';
import meatBigDelivery from '../images/meatBigDelivery.jpg';
import othersMeat from '../images/othersMeat.jpg';
import versus from '../images/versus.svg';
import logoVertical from '../images/logoVertical.svg';
import restaurantBannerImg from '../images/restaurantBannerImg.png';

type Props = {
  findDisplayVariantsByPageAndSection: (
    page: string,
    section: string,
  ) => Product[],
  productByPageAndSection: (page: string, section: string) => Product[],
};

const Restaurant = ({
  productByPageAndSection,
  findDisplayVariantsByPageAndSection,
}: Props) => {
  const sliderProductsVariants = productByPageAndSection(
    'restaurant',
    'slider',
  );
  const productsListVariants = productByPageAndSection(
    'restaurant',
    'product_list',
  );
  useEffect(() => {
    findDisplayVariantsByPageAndSection('restaurant');
    //eslint-disable-next-line
  }, []);

  return (
    <div className="restaurant-main">
      <section className="restaurant-banner-container restaurant-gap">
        <div className="restaurant-banner">
          <img
            src={restaurantBannerImg}
            alt="Conquiste clientes"
            className="restaurant-banner-img"
          />
          <h1 className="restaurant-banner-text">
            Para seu restaurante com carnes de primeira
          </h1>
        </div>
      </section>
      <div className="restaurant-counter-container">
        <LandingIncreasingUserCounter />
      </div>
      <section className="restaurant-section-time restaurant-gap">
        <img src={time} alt="Tempo" className="restaurant-section-time-img" />
        <h2 className="restaurant-section-time-title">
          Tempo otimizado, praticidade e qualidade
        </h2>
        <p className="restaurant-section-time-text">
          são os principais benefícios em contar com o delivery de carnes ideal.
        </p>
      </section>
      <section className="restaurant-diff-section restaurant-gap">
        <h2 className="restaurant-section-diff-title">Quem compara escolhe</h2>
        <div className="restaurant-section-diff-row">
          <div className="restaurant-section-diff-item">
            <img
              src={meatBigDelivery}
              alt="Carne do BigDelivery"
              className="restaurant-section-diff-item-img"
            />
            <img
              src={logoVertical}
              className="restaurant-section-diff-item-text"
              alt="Logo BigDelivery"
            />
          </div>
          <img
            src={versus}
            alt="Versus"
            className="restaurant-section-diff-item-img-versus"
          />
          <div className="restaurant-section-diff-item">
            <img
              src={othersMeat}
              alt="Outros açougues"
              className="restaurant-section-diff-item-img"
            />
            <span className="restaurant-section-diff-item-text">
              Outros açougues
            </span>
          </div>
        </div>
        <div className="restaurant-comment">
          <img
            src={restaurantData.comment.img}
            alt="Comentário"
            className="restaurant-comment-img"
          />
          <h4 className="restaurant-comment-title">
            {restaurantData.comment.title}
          </h4>
          <p className="restaurant-comment-text">
            {restaurantData.comment.text}
          </p>
          <span className="restaurant-comment-author">
            {restaurantData.comment.author}
          </span>
        </div>
        <h2 className="restaurant-section-diff-title">
          Quem compra BigDelivery
        </h2>
        <div className="restaurant-who-buys">
          {restaurantData.restaurantRowImages.map(r => (
            <img
              key={r.alt}
              src={r.img}
              alt={r.alt}
              className="restaurant-who-buys-img"
            />
          ))}
        </div>
      </section>
      <section className="restaurant-banner-container-alt restaurant-gap">
        <div className="restaurant-banner-alt">
          <img className="restaurant-banner-img-alt" src={star} alt="estrela" />
          <h2 className="restaurant-banner-text-alt">
            Veja o seu restaurante recebendo elogios!
          </h2>
        </div>
      </section>
      <section className="restaurant-section-column restaurant-gap">
        {restaurantData.restaurantColumnIcons.map(item => (
          <div className="restaurant-section-column-item" key={item.text}>
            <img
              className="restaurant-section-column-item-img"
              src={item.img}
              alt={item.alt}
            />
            <p className="restaurant-section-column-item-text">{item.text}</p>
          </div>
        ))}
      </section>
      <LandingProductList
        sliderProductsVariants={sliderProductsVariants}
        productsVariants={productsListVariants}
        currentLanding="restaurant"
        title="Os mais pedidos:"
      />
      <LandingFAQ
        faqQuestions={restaurantData.faqQuestions}
        currentLanding="restaurant"
      />
    </div>
  );
};
export default displayVariantsByPageAndSectionConnector(Restaurant);
