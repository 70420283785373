// @flow
import React, { useEffect, useState } from 'react';
//Component
import { ProgressiveImageLoader } from '../../common';
//Types
import type { ProductImage } from '../types/ProductImage';
//Styles
import './ImageGallery.css';
//Assets
import IconShare from '../images/icon-share.svg';

type Props = {
  productName: string,
  productId: number,
  images: ProductImage[],
  isLoading: boolean,
  isCopiedProductLink: boolean,
  loadingShareableLink: boolean,
  generateProductLink: () => void,
};

const ImageGallery = (props: Props) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showShareTooltip, setShowShareTooltip] = useState(false);

  const selectedImage =
    props.images && props.images.length > 0
      ? props.images[currentImageIndex]
      : null;

  useEffect(() => {
    if (props.isCopiedProductLink) {
      setShowShareTooltip(true);
      setTimeout(() => {
        setShowShareTooltip(false);
      }, 2000);
    }
  }, [props.isCopiedProductLink]);

  return (
    <div className="image-gallery">
      <div className="image-gallery-main">
        {props.productDiscount && (
          <div className="image-gallery-discount">
            <p>{Math.round(props.productDiscount)}%</p>
            <span>OFF</span>
          </div>
        )}
        {props.userId && (
          <>
            <button
              className="image-gallery-main-share"
              onClick={props.generateProductLink}
              disabled={props.loadingShareableLink}
            >
              <img src={IconShare} alt="compartilhar" />
            </button>
            {showShareTooltip && (
              <span className="image-gallery-main-share-tooltip">
                Link copiado com sucesso{' '}
                <i className="fa fa-check" aria-hidden="true"></i>
              </span>
            )}
          </>
        )}
        <ProgressiveImageLoader
          selectedImage={selectedImage}
          imageToShow={'large'}
        />
      </div>

      {/* THUMBNAILS */}
      <div className="image-gallery-thumbnails">
        {props.images.map((img, i) => (
          <div
            key={i}
            onClick={() => setCurrentImageIndex(i)}
            className={`p-2 image-gallery-thumb ${
              i === currentImageIndex ? 'image-gallery-thumb-active' : ''
            }`}
          >
            <img src={img.smallUrl} alt={img.alt} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageGallery;
